import {NgIf, NgClass, NgFor, NgForOf, NgStyle, NgOptimizedImage} from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ClientForAssistanceAutocompleteComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/client-for-assistance-autocomplete/client-for-assistance-autocomplete.component';
import { PageComponent } from '@shared/components/page/page.component';
import { LocationService } from '@shared/services/location.service';
import { ButtonComponent } from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { TableComponent } from '@ui/table/table.component';
import { BadgeModule } from 'primeng/badge';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { TabViewModule } from 'primeng/tabview';
import {IAgentAutocompleteResponse} from "@shared/model/atlas.api";
import {AgentListImagesComponent} from "@app/cc-leader/campaigns/agents/agent-list-images/agent-list-images.component";

@Component({
  selector: 'atlas-campaign-card',
  standalone: true,
  imports: [PageComponent,
    TabViewModule,
    TableComponent,
    InputTextModule,
    ReactiveFormsModule,
    InputSearchComponent,
    ClientForAssistanceAutocompleteComponent,
    DropdownComponent,
    InlineWrapperComponent,
    InputTextComponent,
    FormsModule,
    TableComponent,
    ButtonComponent,
    ContextMenuModule,
    PageComponent,
    TabViewModule,
    ConfirmDialogModule,
    InputTextModule,
    InputTextComponent,
    BadgeModule,
    NgIf,
    NgClass, NgFor, NgForOf,
    ProgressBarModule,
    BadgeModule, NgStyle, NgOptimizedImage, AgentListImagesComponent],
  templateUrl: './campaign-card.component.html',
  styleUrl: './campaign-card.component.scss'
})
export class CampaignCardComponent implements OnInit {

  constructor(private _locationService: LocationService){}

  ngOnInit(): void {
  }

  @Input() title: string;
  @Input() flagColor: string;
  @Input() status: string;
  @Input() description: string;
  @Input() date: string;
  @Input() dealTime: string;
  @Input() user: string;
  @Input() info: number;
  @Input() totalLeads: number;
  @Input() agents: IAgentAutocompleteResponse[] = [];
  @Input() showProgressBar: boolean = false;
  @Input() showDealTime: boolean = false;
  @Input() showFlagIcon: boolean = false; 
  @Input() showFooterUserIcon: boolean = false; 
  @Input() campaignId: string;

  getStatusBadgeClass(status: string): string {
    switch (status) {
      case 'Završena':
        return 'badge-success'; 
      case 'U toku':
        return 'badge-info';    
      case 'Prekinuta':
        return 'badge-warning';  
      default:
        return 'badge-default';  
    }
  }

  getProgressBarClass(value: number): string {
    if (value < 30) {
      return 'progress-low';
    } else if (value >= 30 && value <= 50) {
      return 'progress-medium';
    } else if (value > 50 && value <= 80) {
      return 'progress-high';
    } else if (value > 80) {
      return 'progress-full';
    }
    return '';
  }

  moreDetails(){
    this._locationService.routeToDetailsCampaign(this.campaignId)
  }

}
