import {
  NgFor,
  NgIf,
  NgClass,
  NgForOf,
  NgTemplateOutlet,
  NgOptimizedImage,
  DecimalPipe, JsonPipe,
} from '@angular/common';
import { Component, DebugElement, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { PackageCardListComponent } from '@app/cc-leader/package-card-list/package-card-list.component';
import {
  OrderForm,
  OrderFormService,
} from '@app/cc-leader/services/order-form.service';
import { PackageCardListService } from '@app/cc-leader/services/package-card-list.service';
import { BrandAutocompleteComponent } from '@app/features/autocompletes/brand-autocomplete/brand-autocomplete.component';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import {IBrandModelItem, IVehicleBrandItem, VehiclesClient, VehicleTypeItem} from '@shared/model/atlas.api';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { CheckboxModule } from 'primeng/checkbox';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { ModelAutocompleteComponent } from '@app/features/autocompletes/model-autocomplete/model-autocomplete.component';
import { NewVehicleService } from '@app/features/services/new-vehicle-service';
import { DeferBlockFixture } from '@angular/core/testing';
import {BrandDropdownComponent} from "@app/features/dropdowns/brand-dropdown/brand-dropdown.component";
import {ModelDropdownComponent} from "@app/features/dropdowns/model-dropdown/model-dropdown.component";

@Component({
  selector: 'atlas-create-order-form-step-2',
  standalone: true,
    imports: [
        NgFor,
        NgIf,
        NgClass,
        NgForOf,
        CheckboxModule,
        InputTextComponent,
        InputPhoneComponent,
        InputMailComponent,
        InputAddonComponent,
        DropdownComponent,
        IconCheckboxComponent,
        BrandAutocompleteComponent,
        FormsModule,
        NgTemplateOutlet,
        NgOptimizedImage,
        PackageCardListComponent,
        BrandAutocompleteComponent,
        ModelAutocompleteComponent,
        BrandDropdownComponent,
        JsonPipe,
        ModelDropdownComponent,
    ],
  templateUrl: './create-order-form-step-2.component.html',
  styleUrl: './create-order-form-step-2.component.scss',
})
export class CreateOrderFormStep2Component implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  selectedOption: 'Vozilo' | 'Licni' = 'Vozilo';
  vehicleTypes: VehicleTypeItem[];
  secondStepControl = new FormControl('');
  vehicleBrandId: string;
  selectedVehicle: string | null;
  selectedVehicleDropdown: any;
  isPackageDropdownOpen: boolean = false;
  selectedVehicleType: string | undefined;
  allPackages: any[] = [];
  selectedPackage: any;
  orderForm: OrderForm;
  isKombiSelected: boolean;

    showModelTxtInput: boolean = false;

  constructor(
    private _vehiclesClient: VehiclesClient,
    private decimalPipe: DecimalPipe,
    private _pacakageListService: PackageCardListService,
    private _orderFormService: OrderFormService,
    private _newVehicleService: NewVehicleService,
  ) {
    this.loadVehicleTypes();
    this.orderForm = this._orderFormService.getOrderForm;
  }

  ngOnInit(): void {
    this._pacakageListService.setLicniPaketUnselected();

    this._newVehicleService
      .getShowMotorModelInput()
      .pipe(distinctUntilChanged())
      .subscribe((show) => (this.showModelTxtInput = show));

    this.selectedOption = this.orderForm.value.isForVehicle
      ? 'Vozilo'
      : 'Licni';

    this.orderForm.controls.isForVehicle.valueChanges.subscribe(
      (isForVehicle) => {
        if (isForVehicle) {
          this.onSelectVehicle(this.vehicleTypes[1]);
          this._pacakageListService.clearAllPackages();
          this._pacakageListService.setSelectedPackageId(null, 0);
          this._pacakageListService.setLicniPaketUnselected();
        }
      },
    );
  }

  selectVozilo() {
    this.selectedOption = 'Vozilo';
    this.orderForm.controls.isForVehicle.patchValue(true);
  }

  selectLicni() {
    this.selectedOption = 'Licni';
    this.orderForm.controls.isForVehicle.patchValue(false);
    this._pacakageListService.setLicniPaketSelected();
    this._pacakageListService.setVehicleTypeId(null);
  }

  selectPackage(pkg: any) {
    this.selectedPackage = pkg;
    this.isPackageDropdownOpen = false;
  }

  private loadVehicleTypes() {
    this._subs.add(
      this._vehiclesClient.getVehicleTypes().subscribe((res) => {
        this.vehicleTypes = res.result.responseList;

        this.vehicleTypes.forEach((type) => {
          if (type.name === 'Putnička vozila') {
            type.name = 'Putničko';
          }
        });
      }),
    );
  }

  onSelectVehicle(vehicleTypeItem: VehicleTypeItem) {
    this._newVehicleService.setShowMotorModelInput(vehicleTypeItem.name.includes('Mot'));
    
    if (vehicleTypeItem.name.includes('Mot')) {
      this.orderForm.controls.vehicle.controls.brand.patchValue(null);
      this.orderForm.controls.vehicle.controls.model.patchValue(null);
      this.orderForm.controls.vehicle.controls.licencePlate.reset();
      this.orderForm.controls.vehicle.controls.color.reset();
      this.orderForm.controls.vehicle.controls.productionYear.reset();
      this.orderForm.controls.towingLocation.reset();

    } else {
      this._newVehicleService.resetBrandCtrlValues();
      this._newVehicleService.resetModelCtrlValues();
      this.orderForm.controls.vehicle.controls.brand.patchValue(null);
      this.orderForm.controls.vehicle.controls.model.patchValue(null);
      this.orderForm.controls.vehicle.controls.licencePlate.reset()
      this.orderForm.controls.vehicle.controls.color.reset();
      this.orderForm.controls.vehicle.controls.productionYear.reset();
      this.orderForm.controls.towingLocation.reset();
    }  
    this.isKombiSelected = vehicleTypeItem.name.includes('Kombi');
    this.selectedVehicle = vehicleTypeItem.name;
    this.selectedVehicleType = vehicleTypeItem.typeId;
    this._pacakageListService.setVehicleTypeId(vehicleTypeItem.typeId);
    this._pacakageListService.clearAllPackages();
    this._pacakageListService.setSelectedPackageId(null, 0);
    this.orderForm.controls.vehicle.controls.type.patchValue(vehicleTypeItem.name);
  }

  handleBrandChange(event: IVehicleBrandItem) {
    this.vehicleBrandId = event.brandId;
    this.orderForm.controls.vehicle.controls.brand.patchValue(event.name);
    console.log(this.vehicleBrandId);
  }

  handleModelChange(event: IBrandModelItem) {
    this.orderForm.controls.vehicle.controls.model.patchValue(event.name);
    this._newVehicleService.resetModelCtrlValues();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
