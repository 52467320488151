import { NgIf, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CommentItem } from '@shared/model/atlas.api';
import { AtlasDatePipe } from '@shared/pipes/date.pipe';

@Component({
  selector: 'atlas-comment-item',
  standalone: true,
  imports: [
    NgIf,
    NgOptimizedImage
  ],
  providers: [AtlasDatePipe],
  templateUrl: './comment-item.component.html',
  styleUrl: './comment-item.component.scss'
})
export class CommentItemComponent {
  @Input() comment!: CommentItem

  constructor(private _datePipe: AtlasDatePipe) {}

  get formattedDate(): string {
    return this._datePipe.transformDateWithTime(this.comment.created);
  }
}
