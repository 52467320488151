import {
  NgIf,
  NgClass,
  NgFor,
  NgForOf,
  NgStyle,
  DatePipe,
  NgTemplateOutlet,
  NgOptimizedImage,
} from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CampaignFormService } from '@app/cc-leader/services/campaign-form.service';
import { PageComponent } from '@shared/components/page/page.component';
import {
  CampaignsClient,
  GetCampaignAgentsQuery, IAgentAutocompleteResponse,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { LocationService } from '@shared/services/location.service';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { InputSwitchComponent } from '@ui/input-switch/input-switch.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { BadgeModule } from 'primeng/badge';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { TooltipModule } from 'primeng/tooltip';
import { AgentsAutocompleteComponent } from '@app/cc-leader/campaigns/agents/agents-autocomplete/agents-autocomplete.component';
import { Subscription } from 'rxjs';
import { DialogRef } from '@ui/dialog/dialog-ref';
import {
  ClientWithPackagesTableComponent
} from "@app/features/call-center/client-with-packages-table/client-with-packages-table.component";
import {
  AssignedAgentsListComponent
} from "@app/cc-leader/campaigns/agents/assigned-agents-list/assigned-agents-list.component";

@Component({
  selector: 'atlas-change-agents-form',
  standalone: true,
  imports: [
    InputTextModule,
    ReactiveFormsModule,
    InputSearchComponent,
    InlineWrapperComponent,
    InputTextComponent,
    FormsModule,
    ContextMenuModule,
    ConfirmDialogModule,
    InputTextModule,
    InputTextComponent,
    TooltipModule,
    NgIf,
    NgClass,
    NgFor,
    NgForOf,
    ProgressBarModule,
    BadgeModule,
    NgStyle,
    DatePipe,
    OverlayPanelModule,
    NgTemplateOutlet,
    NgOptimizedImage,
    InputSwitchComponent,
    InputSwitchModule,
    ClientWithPackagesTableComponent,
    PageComponent,
    BadgeModule,
    AgentsAutocompleteComponent,
    AssignedAgentsListComponent,
  ],
  templateUrl: './change-agents-form.component.html',
  styleUrl: './change-agents-form.component.scss',
})
export class ChangeAgentsFormComponent implements OnInit {
  private _subs: Subscription = new Subscription();
  selectedAgents: IAgentAutocompleteResponse[] = [];
  campaignId: string;

  constructor(
    private _client: CampaignsClient,
    private service: CampaignFormService,
    private _alertService: AlertService,
    private location: LocationService,
    private _dialogRef: DialogRef,
  ) {}

  ngOnInit(): void {
    this.campaignId = this.service.getCampaignId();
    this.getAgents();
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleSave();
      }),
    );
  }

  getAgents() {
    const query = new GetCampaignAgentsQuery();
    query.campaignId = this.campaignId;
    this._client.getAllCampaignAgents(query).subscribe((response) => {
      this.selectedAgents = response.result.data;
      //this.totalPages = Math.ceil(this.agents.length / this.pageSize);
      //this.updatePaginatedAgents();
    });
  }
  //
  _handleSave() {
    if (this.selectedAgents.length === 0) {
      return;
    }

    this._subs.add(
      this._client
        .updateCampaignAgents(this.campaignId, this.selectedAgents.map(a => a.id))
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this._dialogRef.close();
        }),
    );
  }
  handleAgentSelected(selectedAgent: IAgentAutocompleteResponse) {
    this.selectedAgents.push(selectedAgent);
    //this.service.addAgent(selectedAgent.id);
  }

  handleAgentUnselect(agentId: string) {
    const idx = this.selectedAgents.findIndex((x) => x.id === agentId);
    this.selectedAgents.splice(idx, 1);
  }


  // updatePaginatedAgents() {
  //   const start = this.currentPage * this.pageSize;
  //   const end = start + this.pageSize;
  //   const a: IAgentAutocompleteResponse = {}
  //   this.selectedAgents = this.agents.slice(start, end).map((x: ICampaignAgentResponse) => ({
  //     id: x.id,
  //     name: x.name,
  //     imageUrl: x.imageUrl
  //   }));
  // }
  //
  // routeToTable() {
  //   this.location.routeToCampaignTable();
  // }

}
