<div class="container-main ml-1">
  <div class="form-container">
    <div class="step-title">Korak 3</div>
    <div class="text-3xl font-semibold mt-1">Plaćanje</div>

    <div class="flex flex-column gap-6 mt-5">
      <atlas-payment-type-options
        [deliveryTypeCtrl]="orderForm.controls.deliveryType"
        [paymentTypeCtrl]="orderForm.controls.paymentType"
      ></atlas-payment-type-options>

      <div>
        <div class="title-text">Od kada želite da vam se aktivira paket?</div>
        <div class="mt-3" *ngFor="let type of activationOptions">
          <p-radioButton
            [formControl]="orderForm.controls.isUrgent | formControl"
            [value]="type.value"
            [inputId]="type.name"
          ></p-radioButton>
          <label class="ml-3 cursor-pointer" [for]="type.name">{{
              type.name
            }}</label>
        </div>
        <small *ngIf="!orderForm.controls.isUrgent.valid" class="text-red-500"
        >Obavezno polje</small
        >
      </div>
    </div>
  </div>

  <div class="summary-box">
    <div class="package-text mb-3">Kupovina</div>

    @for (p of packages?.value; let idx = $index; track p.id) {
      <div class="item mt-3">
        <span *ngIf="packagesSum > 0" class="sub-text"
        >{{ idx + 1 + "." }} {{ p.name }}</span
        >
        <span class="sub-texte">{{ p.price | currencyNumber }}</span>
      </div>
    }

    <div class="total mt-3">
      <span class="mt-3 package-text">Ukupno</span>
      <span class="mt-3 package-text">{{
          packagesSum ?? 0 | currencyNumber
        }}</span>
    </div>
  </div>
</div>
