import { inject } from '@angular/core';
import { CanMatchFn, Route, UrlSegment } from '@angular/router';
import { UserService } from '@shared/services/user.service';

export const CanMatchCcAgent: CanMatchFn = (
  route: Route,
  segments: UrlSegment[],
) => {
  return inject(UserService).isCcAgent();
};
