<div class="flex flex-column gap-5 page-header px-5 py-5">
  <div class="main-page-header">
    <div *ngIf="btnIcon" class="text-3xl font-semibold title-size">{{ pageTitle }}</div>
    <div *ngIf="!btnIcon" class="text-3xl font-semibold title-size mb-2">{{ pageTitle }}</div>
    <div class="flex justify-content-between align-items-center">
      <div class="text-base text-gray-600 page-desc">{{ pageDescription }}</div>
      <div class="flex gap-3">
        <ng-container *ngIf="btnIcon; else buttonWithSvg">
          <atlas-button
            *ngIf="secondaryActionLabel"
            (btnClicked)="handleActionSecondary()"
            [btnStyle]="ButtonStyle.DIALOG"
            btnClass="w-full"
            [btnIcon]="secondaryBtnIcon"
            [btnLabel]="secondaryActionLabel"
          >
          </atlas-button>
          <atlas-button
            *ngIf="actionLabel"
            (btnClicked)="handleAction()"
            [btnStyle]="ButtonStyle.BASIC"
            btnClass="w-full"
            [btnIcon]="btnIcon"
            [btnLabel]="actionLabel"
          >
          </atlas-button>
        </ng-container>
      </div>
    </div>
  </div>
  <div>
    <p-scrollPanel [style]="{ width: '100%', height: 'calc(100dvh - 200px)'}">
      <ng-content></ng-content>
    </p-scrollPanel>
  </div>
</div>

<ng-template #buttonWithSvg>
    <atlas-button
            *ngIf="actionLabel"
            (btnClicked)="handleAction()"
            [btnStyle]="ButtonStyle.BASIC"
            btnClass="w-full"
            [btnLabel]="actionLabel">
        <img [ngSrc]="btnSvgUrl" width="16" height="16" alt="add-client" />
    </atlas-button>
</ng-template>
