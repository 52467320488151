<input
  type="file"
  class="file-input"
  accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  (change)="onFileSelected($event)"
  multiple
  #fileUpload
/>

@for (file of selectedFiles; let idx = $index; track file.name) {
  <div
    class="file-box flex justify-content-between border-1 p-3 border-round p-3 mb-3"
  >
    <div class="flex gap-2">
      <img
        ngSrc="assets/images/excel-file.svg"
        width="40"
        height="40"
        alt="excel-image"
      />
      <div class="flex flex-column gap-1">
        <div>
          {{ file.name }}
        </div>
        <div class="text-gray-100">
          {{ file.size }}
        </div>
      </div>
    </div>
    <atlas-button
      btnTooltip="Ukloni izvor"
      btnClass="justify-self-end text-gray-100"
      (btnClicked)="handleFileRemove(file.name)"
      [btnStyle]="ButtonStyle.TEXT"
      btnIcon="pi pi-trash"
    ></atlas-button>
  </div>
}

<atlas-button
  btnIcon="pi pi-plus"
  btnLabel="Dodaj izvor"
  (btnClicked)="fileUpload.click()"
></atlas-button>
