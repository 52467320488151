<div *ngIf="form">
<div class="form-container h-13rem" >
  <div class="dropdown">
    <p class="font-bold mb-3">Pozicija</p>
    <atlas-employee-role-dropdown [roleCtrl]="form.controls.role"></atlas-employee-role-dropdown>
  </div>

  <div class="switch-isActive mt-6">
    <p class="font-bold mb-3">Status</p>
    <div class="mt-2 flex">
      <atlas-input-switch [formGroup]="form"></atlas-input-switch>
      <p class="m-1 ml-2">Aktivan</p>
    </div>
  </div>
</div>
</div>