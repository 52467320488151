import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderTableService {
  showFilterSidebar$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}

  showFilterSidebar() {
    this.showFilterSidebar$.next(true);
  }

  hideFilterSidebar() {
    this.showFilterSidebar$.next(false);
  }

  get getShowFiltersSidebar() {
    return this.showFilterSidebar$.asObservable();
  }
}
