import {Component, Input} from '@angular/core';
import {InputTextModule} from 'primeng/inputtext';
import {NgIf} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'atlas-inline-wrapper',
  standalone: true,
  imports: [
    InputTextModule,
    NgIf,
    ReactiveFormsModule,
  ],
  templateUrl: './inline-wrapper.component.html',
  styleUrl: './inline-wrapper.component.scss',
})
export class InlineWrapperComponent {
  @Input() ctrlLabel: string;
  @Input() showLabel: boolean = true;
  @Input() ctrlDesc: string = '';
  @Input() formCtrlId: string = 'text-input';
}
