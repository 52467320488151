<div class="subtitle-sidebar">Paket</div>
<div class="package-box mt-3">
  <div class="package-content">
    <img
      ngSrc="assets/images/package-border.svg"
      width="40"
      height="40"
      alt="user-image"
    />
    <span class="subtitle-sidebar">{{previewData?.details.packageName ?? 'Bez paketa'}}</span>
  </div>
</div>
<div class="flex flex-column gap-3">
  <div class="mt-3 subtitle-sidebar">Saradnik</div>
  <div class="flex justify-content-between">
    <div class="label-sm">Firma</div>
    <div class="label-sm-info">{{previewData?.details.driverName}}</div>
  </div>
  <div class="flex justify-content-between">
    <div class="label-sm">Saradnik</div>
    <div class="label-sm-info">{{previewData?.details.partnerName}}</div>
  </div>
  <div class="flex justify-content-between">
    <div class="label-sm">Kontakt telefon</div>
    <div class="label-sm-info">{{previewData?.details.driverPhone}}</div>
  </div>
  <div class="flex justify-content-between">
    <div class="label-sm">Vozilo</div>
    <div class="label-sm-info">{{previewData?.details.partnerVehicle}}</div>
  </div>
  <div class="flex justify-content-between">
    <div class="label-sm">Registarske oznake</div>
    <div class="label-sm-info">{{previewData?.details.partnerVehicleLicensePlate}}</div>
  </div>
</div>
