import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ClientClient,
  PhoneItem,
  UpdateClientCommand,
} from '@shared/model/atlas.api';
import { firstValueFrom, Subscription } from 'rxjs';
import { ScrollablePageComponent } from '../../../../../infrastructure/shared/components/scrollable-page/scrollable-page.component';
import { LocationService } from '@shared/services/location.service';
import {
  AgentCcRoleStr,
  B2BAgentRoleStr,
  LeaderCcRoleStr,
  UserService,
} from '@shared/services/user.service';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { NgIf, NgClass, JsonPipe, NgOptimizedImage } from '@angular/common';
import { FormArray, FormControl, FormsModule } from '@angular/forms';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import { MediumsDropdownComponent } from '@shared/components/mediums-dropdown/mediums-dropdown.component';
import { SourcesDropdownComponent } from '@shared/components/sources-dropdown/sources-dropdown.component';
import {
  ErrorRequiredComponent,
  shouldDisplayRequiredError,
} from '@ui/errors/error-required/error-required.component';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchComponent } from '@ui/input-switch/input-switch.component';
import {
  ClientPackageTableService,
  EditClientForm,
} from '@app/sos-agent/packages/services/client-package-table.service';
import { AlertService } from '@shared/services/alert.service';
import {
  formatCityAndCountry,
  getLocationForm,
} from '@shared/forms/forms-common';
import { ContactPhoneListComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/contact-phone-list/contact-phone-list.component';
import { ContactFormService } from '@app/features/partners/services/contact-form.service';

@Component({
  selector: 'atlas-edit-client',
  standalone: true,
  imports: [
    ScrollablePageComponent,
    NgIf,
    CheckboxModule,
    InputTextComponent,
    FormsModule,
    ErrorRequiredComponent,
    NgClass,
    InputSwitchComponent,
    ContactPhoneListComponent,
  ],
  templateUrl: './edit-client.component.html',
  styleUrl: './edit-client.component.scss',
})
export class EditClientComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  private _subs: Subscription = new Subscription();
  @ViewChild('towingLocationRef', { static: false })
  towingLocationRef!: ElementRef;
  clientId: string;
  clientType: string;
  form: EditClientForm | undefined;
  lastValidAdress: string;
  showLastValidAdress: boolean = false;
  private initialized = false;

  constructor(
    private client: ClientClient,
    private route: ActivatedRoute,
    private location: LocationService,
    private userService: UserService,
    private clientService: ClientPackageTableService,
    private _alertService: AlertService,
    private _phonesService: ContactFormService,
  ) {
    this.clientId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this._loadClient(this.clientId);
  }

  ngAfterViewChecked() {
    if (!this.initialized && this.towingLocationRef) {
      this.getTowingAutocomplete();
      this.initialized = true;
    }
  }

  private _patchPhones(res: any) {
    const phones = res.result.phones || [];

    const phonesFormArray = this._phonesService.getPhonesArray(phones);
    this._phonesService.setPhones(phonesFormArray);
  }

  private _addPhones() {
    const phoneValues = this._phonesService.getPhones().value as PhoneItem[];
    phoneValues.forEach((x) => {
      this.form.controls.phones.push(this._phonesService.getPhoneForm(x));
    });

    this._removeEmptyPhones();
  }

  private _removeEmptyPhones() {
    let phonesFormArray = this.form.controls.phones as FormArray;
    let phonesToRemove = [];
    phonesFormArray.controls.forEach((phoneControl, index) => {
      if (phoneControl.value.label === '') {
        phonesToRemove.push(index);
      }
    });
    phonesToRemove.reverse().forEach((index) => {
      phonesFormArray.removeAt(index);
    });

    if (!this.form.valid) {
      return this._alertService.addWarnMsg('Morate uneti sva neophodna polja');
    }
  }

  private getTowingAutocomplete() {
    if (this.form.value.towingLocation.place) {
      this.towingLocationRef.nativeElement.value =
        this.form.value.towingLocation.place;
    }
    const autocomplete = new google.maps.places.Autocomplete(
      this.towingLocationRef.nativeElement,
      {
        types: ['geocode'],
        componentRestrictions: { country: 'rs' },
      },
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.form.controls['towingLocation'].patchValue({
        place: formatCityAndCountry(place),
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      });
    });
  }

  private async _loadClient(id: string): Promise<void> {
    const res = await this._getClient(id);
    this.clientType = res.result.clientType;
    this.lastValidAdress = res.result.towingAddress.place ?? '';
    // Provera za stare klijente bez lat. i lon. podataka
    if (
      res.result.towingAddress.latitude === 0 ||
      res.result.towingAddress.longitude === 0
    ) {
      res.result.towingAddress.place = null;
      this.showLastValidAdress = true;
    }
    this.form = this.clientService.getEditClientForm(res.result);
    this._patchPhones(res);
  }

  private async _getClient(id: string) {
    return await firstValueFrom(this.client.getClientProfile(id));
  }

  handleSubmitForm() {
    this._addPhones();
    this._phonesService.setSubmitted(true);
    this._subs.add(
      this.client
        .updateClient(this.form.value as UpdateClientCommand)
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this.location.routeToClientsTable(
            this.userService.getRoleRoutePrefix(
              AgentCcRoleStr,
              B2BAgentRoleStr,
              LeaderCcRoleStr,
            ),
          );
        }),
    );
  }

  handleCancelForm() {
    this.location.routeBack();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    this._phonesService.setSubmitted(false);
    this.clientService.reset();
    this._phonesService.resetPhones();
  }

  protected readonly shouldDisplayRequiredError = shouldDisplayRequiredError;
}
