<div class="flex align-items-end dropdown-input-button">
  <atlas-dropdown
    [hideErrors]="true"
    [options]="searchOptions"
    [formCtrl]="searchOptionCtrl"
    ctrlStyleClass="group-dropdown w-50rem"
  ></atlas-dropdown>
<atlas-input-button
    [searchCtrl]="searchCtrl"
    (onAction)="handleAction()"
></atlas-input-button>
</div>
