import { Component, Input } from '@angular/core';
import { NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import {SkeletonModule} from "primeng/skeleton";

@Component({
  selector: 'atlas-details-client-info',
  standalone: true,
  imports: [NgOptimizedImage, NgIf, NgFor, SkeletonModule],
  templateUrl: './details-client-info.component.html',
  styleUrl: './details-client-info.component.scss',
})
export class DetailsClientInfoComponent {
  @Input() isLoading = false;
  @Input() nationalNumber: string;
  @Input() address: string;
  @Input() phones: string[] = [];
}
