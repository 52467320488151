import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentStatusDropdownComponent } from '@app/features/dropdowns/payment-status-dropdown/payment-status-dropdown.component';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  ChangePaymentStatusCommand,
  OrdersClient,
  PackagesClient,
  PaymentStatus,
} from '@shared/model/atlas.api';
import { EmployeeRoleDropdownComponent } from '@app/administrator/employees/employee-role-dropdown/employee-role-dropdown.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { AlertService } from '@shared/services/alert.service';
import { finalize, firstValueFrom, Subscription } from 'rxjs';
import { JsonPipe, NgFor, NgIf } from '@angular/common';
import { CalendarComponent } from '@ui/calendar/calendar.component';
import { LoaderService } from '@shared/components/loader/loader.service';
import { RadioButtonModule } from 'primeng/radiobutton';

@Component({
  selector: 'atlas-change-payment-status-form',
  standalone: true,
  imports: [
    PaymentStatusDropdownComponent,
    EmployeeRoleDropdownComponent,
    FormControlPipe,
    JsonPipe,
    CalendarComponent,
    RadioButtonModule,
    FormControlPipe,
    ReactiveFormsModule,
    NgFor,
    NgIf,
  ],
  templateUrl: './change-payment-status-form.component.html',
  styleUrl: './change-payment-status-form.component.scss',
})
export class ChangePaymentStatusFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  statusCtrl: FormControl<PaymentStatusForm>;
  activationDateCtrl: FormControl<Date>;
  isurgentCtrl: FormControl<boolean> = new FormControl<boolean>(false);

  constructor(
    private _dialogConfig: DialogConfig,
    private _dialogRef: DialogRef,
    private _orderClient: OrdersClient,
    private _packagesClient: PackagesClient,
    private _alertService: AlertService,
    private _loader: LoaderService,
  ) {
    this.statusCtrl = new FormControl(
      {
        status: this._dialogConfig.data.paymentStatus,
        label: this._dialogConfig.data.paymentStatusStr,
      },
      {
        validators: [Validators.min(1)],
      },
    );

    this.activationDateCtrl = new FormControl<Date>(new Date(), {
      validators: [Validators.required],
    });
  }

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleSubmit();
      }),
    );

    this._getActivationDate().then((res) => {
      if (res.result.activationDate.getFullYear() >= new Date().getFullYear()) {
        this.activationDateCtrl.patchValue(res.result.activationDate);
      }
      this.isurgentCtrl.patchValue(res.result.isUrgent);
    });
  }

  handleSubmit() {
    if (this.statusCtrl.invalid) {
      this.statusCtrl.markAsTouched();
      return;
    }
    if (this.activationDateCtrl.invalid) {
      this.activationDateCtrl.markAsTouched();
      return;
    }

    if (
      this.statusCtrl.value.status === PaymentStatus.WC_COMPLETED ||
      this.statusCtrl.value.status === PaymentStatus.WC_CANCELLED ||
      this.statusCtrl.value.status === PaymentStatus.WC_REFUNDED
    ) {
      this._loader.setLoadingText =
        'Status se menja i ugovor se šalje na mail...';
    } else {
      this._loader.setLoadingText = 'Status se menja...';
    }
    this._loader.setShowLoader = true;

    this._dialogConfig.styleClass = 'display: hidden';

    this._subs.add(
      this._orderClient
        .changePaymentStatus(
          new ChangePaymentStatusCommand({
            paymentStatus: this.statusCtrl.value.status,
            orderId: this._dialogConfig.data.orderId,
            activationDate: this.activationDateCtrl.value,
            isUrgent: this.isurgentCtrl.value,
          }),
        )
        .pipe(
          finalize(() => {
            this._loader.reset();
            this._dialogConfig.styleClass = 'display: block';
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this._dialogRef.close(this.statusCtrl.value);
        }),
    );
  }

  private async _getActivationDate() {
    return await firstValueFrom(
      this._packagesClient.getStatusData(this._dialogConfig.data.orderId),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  protected readonly Date = Date;
}

export interface PaymentStatusForm {
  label: string;
  status: PaymentStatus;
}
