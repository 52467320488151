<div class="flex flex-column mt-5 gap-3 table-filter" *ngIf="this.showFilters">
    <div class="flex align-items-center gap-3">
        <p-tabView
                class="tabView flex-1"
                [(activeIndex)]="activeIndex!"
                (activeIndexChange)="handleTabChange($event)"
        >
            <p-tabPanel header="Sve preporuke"></p-tabPanel>
            <p-tabPanel header="Nove preporuke"></p-tabPanel>
            <p-tabPanel header="U obradi"></p-tabPanel>
        </p-tabView>
        <div class="search-bar">
            <atlas-dropdown-input-button
                    [searchOptionCtrl]="searchOptionCtrl"
                    [searchCtrl]="searchCtrl"
                    [searchOptions]="searchOptions"
                    (onAction)="load()"
            >
            </atlas-dropdown-input-button>
        </div>
    </div>
</div>

<div class="mt-1 mb-2 w-full">
    <atlas-active-filters></atlas-active-filters>
  </div>

<atlas-table
        [paginatedData]="paginatedData"
        [tableConfig]="tableConfig"
></atlas-table>
