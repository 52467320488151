import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { PageComponent } from '@shared/components/page/page.component';
import {
  CreateRecomendationsCommandBody,
  GetRecommendationsQuery,
  IPaginationResponseOfRecommendationResponse,
  RecommendationsClient,
} from '@shared/model/atlas.api';
import { LocationService } from '@shared/services/location.service';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { ActionMode, TableConfig } from '@ui/table/table-common';
import { TableComponent } from '@ui/table/table.component';
import {  SelectItem } from 'primeng/api';
import {TableLazyLoadEvent} from "primeng/table/table.interface";
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { count, firstValueFrom, Subscription } from 'rxjs';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { ReccomendationFormService } from '@app/cc-leader/services/reccomendation-form.service';
import { AlertService } from '@shared/services/alert.service';
import { AddRecommendationFormComponent } from '@app/features/call-center/add-recommendation-form/add-recommendation-form.component';
import { DialogService } from '@ui/dialog/dialog.service';
import { markFormGroupTouched } from '@shared/forms/forms-common';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { RecommendationCommentsComponent } from '../recommendation-comments/recommendation-comments.component';
import { TableServiceLocal } from '@ui/table/table-service-local.service';
import { InputButtonComponent } from '@shared/components/input-button/input-button.component';
import { DropdownInputButtonComponent } from '@shared/components/dropdown-input-button/dropdown-input-button.component';

@Component({
  selector: 'atlas-recommendation-table',
  standalone: true,
  imports: [
    PageComponent,
    InlineWrapperComponent,
    DropdownComponent,
    InputTextComponent,
    ButtonModule,
    ButtonComponent,
    DropdownModule,
    FormsModule,
    TableComponent,
    InputButtonComponent,
    DropdownInputButtonComponent
  ],
  templateUrl: './recommendation-table.component.html',
  styleUrl: './recommendation-table.component.scss',
})
export class RecommendationTableComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  searchOptions: SelectItem[];
  searchOptionCtrl: FormControl;
  searchCtrl = new FormControl('');

  public paginatedData: IPaginationResponseOfRecommendationResponse;
  protected tableConfig: TableConfig;

  commentContTest: string;

  rowsOptions = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '15', value: 15 },
    { label: '20', value: 20 },
  ];
  selectedRows: number = 5;
  suggestCount: number = 0;

  constructor(
    private _recomendClinent: RecommendationsClient,
    private _dialogService: DialogService,
    private _recommendService: ReccomendationFormService,
    private _alertService: AlertService,
    private _locationService: LocationService,
    private _tableService: TableServiceLocal,
  ) {
    this.tableConfig = new TableConfig({
      columns: [],
      rowsPerPage: 10,
    });

    this.paginatedData = {
      data: [],
      currentPage: 0,
    };

    this.searchOptionCtrl = new FormControl<SearchOptions>(SearchOptions.RECOMMENDATION);

    this.searchOptions = [
      {
        label: SearchOptions.RECOMMENDATION,
        value: SearchOptions.RECOMMENDATION,
      },
      {
        label: SearchOptions.SOURCE,
        value: SearchOptions.SOURCE,
      },
    ];

  }

  ngOnInit(): void {
    this.setTableConfig().then();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez preporuka',
        description: 'Ovde će biti prikazana tabela preporuka',
        btnLabel: 'Dodaj preporuku',
        action: () => this.handleAddNewRecommendation(),
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.load();
      },
      columns: [
        {
          field: 'fullName',
          header: 'Preporuka',
          type: 'text',
        },
        {
          field: 'contact',
          header: 'Kontakt',
          type: 'text',
        },
        {
          field: 'source',
          header: 'Izvor preporuke',
          type: 'text',
        },
        {
          field: 'city',
          header: 'Grad',
          type: 'text',
        },
        {
          field: 'createdOn',
          header: 'Dodata',
          type: 'text',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Kreiraj ugovor',
          label: 'Napravi ugovor',
          svgName: 'recomend-btn',
          actionClass: 'p-2 ml-5 gap-2',
          actionSeverity: ButtonSeverity.SECONDARY_GREY,
          actionStyle: ButtonStyle.OUTLINED,
          shouldDisplayByCondition: () => true,
          callback: (rowIdx: number) => {
            this.handleCreateOrder(rowIdx);
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: this.commentContTest,
          svgName: 'comment-Icon',
          actionSeverity: ButtonSeverity.SECONDARY,
          shouldDisplayByCondition: () => true,
          callback: (rowIdx: number) => {
            const rowData = this._getPaginatedItem(rowIdx);
            this.handleShowComments(rowData.id);
          },
          onHover: (rowIdx: number) => {
            const rowData = this._getPaginatedItem(rowIdx);
            return rowData.commentCount.toString();
          },
        },
      ],
    });
  }

  handleShowComments(reccomendationId: string) {
    const dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);

    dialogConfig.header = 'Komentari';
    dialogConfig.customSubmitButton = {
      label: 'Dodaj komentar',
      icon: '',
    };
    dialogConfig.maximisable = false;
    dialogConfig.hideCancel = true;
    dialogConfig.closable = true;

    dialogConfig.data = { reccomendationId };
    const dialogRef = this._dialogService.open(
      RecommendationCommentsComponent,
      dialogConfig,
    );

    dialogRef.onClose.subscribe((commentNum) => {
      if (commentNum) {
        const idx = this.paginatedData.data.findIndex(
          (x) => x.id === reccomendationId,
        );
        this.paginatedData.data[idx].commentCount = commentNum;
      }
    });
  }

  handleCreateOrder(idx: number) {
    this._setData(idx);
    this._locationService.routeToCreateClientForm().then();
  }

  private _setData(idx: number) {
    const rowData = this._getPaginatedItem(idx);
    const clientname = rowData.fullName.split(' ')[0];
    const clientLastName = rowData.fullName.split(' ')[1];
    this._recommendService.setReccData({
      name: clientname,
      lastName: clientLastName,
      phoneNum: rowData.contact,
    });
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  handleAddNewRecommendation() {
    const dialogConfig: DialogConfig = new DialogConfig(
      DialogSize.MEDIUM_SMALL,
    );

    dialogConfig.header = 'Dodaj preporuku';
    dialogConfig.customSubmitButton = {
      label: 'Dodaj',
      icon: '',
    };
    dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    dialogConfig.maximisable = false;
    dialogConfig.closable = true;
    dialogConfig.dialogSize = DialogSize.MEDIUM_SMALL;

    const dialogRef = this._dialogService.open(
      AddRecommendationFormComponent,
      dialogConfig,
    );

    dialogRef.onClose.subscribe(() => {
      this._recommendService.clearReccomendations();
      this.load();
    });

    dialogRef.onSubmit.subscribe(() => {
      this._handleSubmitReccomendations(dialogRef);
    });
  }

  private _handleSubmitReccomendations(dialogRef: DialogRef) {
    const reccomendationsForm = this._recommendService.getReccomendations();

    if (!reccomendationsForm.valid) {
      reccomendationsForm.controls.forEach((formGroup: FormGroup) => {
        markFormGroupTouched(formGroup);
      });
      return this._alertService.addWarnMsg('Morate uneti sva polja!');
    }

    this._subs.add(
      this._recomendClinent
        .createReccomendations(
          reccomendationsForm.value as CreateRecomendationsCommandBody[],
        )
        .subscribe((x) => {
          this._alertService.addSuccessMsg(x.result);
          dialogRef.close();
        }),
    );
  }

  load() {
    this._tableService.setIsLoading();
    this._getList().then((res) => {
      this.paginatedData = res.result;
      this.suggestCount = res.result.totalCount;
      this._tableService.resetIsLoading();
    });
  }

  private async _getList() {
    return await firstValueFrom(
      this._recomendClinent.getReccomendations(
        new GetRecommendationsQuery({
          fullName: this.searchOptionCtrl.value == SearchOptions.RECOMMENDATION
          ? this.searchCtrl.value
          : undefined,
          source: this.searchOptionCtrl.value == SearchOptions.SOURCE
          ? this.searchCtrl.value
          : undefined,
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize,
        }),
      ),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}

enum SearchOptions {
  RECOMMENDATION = 'Preporuka',
  SOURCE = 'Izvor',
}