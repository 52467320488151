import {
  CommonModule,
  NgClass,
  NgFor,
  NgForOf,
  NgIf,
  NgOptimizedImage,
} from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  AddPackageForm,
  OrderFormService,
} from '@app/cc-leader/services/order-form.service';
import { PackageCardListService } from '@app/cc-leader/services/package-card-list.service';
import { PackageCardListComponent } from '@app/features/packages/package-card-list/package-card-list.component';
import { MediumsDropdownComponent } from '@shared/components/mediums-dropdown/mediums-dropdown.component';
import { ScrollablePageComponent } from '@shared/components/scrollable-page/scrollable-page.component';
import { SourcesDropdownComponent } from '@shared/components/sources-dropdown/sources-dropdown.component';
import { ControlsOf, markFormGroupTouched } from '@shared/forms/forms-common';
import {
  AddPackageToVehicleCommand,
  IPackageCardItem,
  OrdersClient,
} from '@shared/model/atlas.api';
import { CurrencyNumberPipe } from '@shared/pipes/currency-number.pipe';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { AlertService } from '@shared/services/alert.service';
import { LocationService } from '@shared/services/location.service';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { Subscription } from 'rxjs';
import { SkeletonModule } from 'primeng/skeleton';
import { PaymentTypeOptionsComponent } from '../payment-type-options/payment-type-options.component';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'atlas-buy-package-vehicle',
  standalone: true,
  imports: [
    ScrollablePageComponent,
    DividerModule,
    NgIf,
    NgOptimizedImage,
    NgForOf,
    NgFor,
    NgClass,
    RadioButtonModule,
    CommonModule,
    ReactiveFormsModule,
    CheckboxModule,
    FormsModule,
    FormControlPipe,
    SelectButtonModule,
    PackageCardListComponent,
    CurrencyNumberPipe,
    MediumsDropdownComponent,
    SourcesDropdownComponent,
    SkeletonModule,
    PaymentTypeOptionsComponent,
  ],
  templateUrl: './buy-package-vehicle.component.html',
  styleUrl: './buy-package-vehicle.component.scss',
})
export class BuyPackageVehicleComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  clientPackageId: string;
  addPackageForm: AddPackageForm;
  packagesSum: number = 0;
  packages: FormArray<FormGroup<ControlsOf<IPackageCardItem>>>;

  vehicleName: string;
  vehicleId: string;
  clientId: string;
  selectedPackagesCount: string;
  activePackagesCount: string;

  activationOptions: any = [
    {
      name: 'Aktivacija odmah (paket se aktivira odmah nakon evidentiranja uplate)',
      value: true,
    },
    {
      name: 'Standardna aktivacija (7 dana od evidentiranja uplate)',
      value: false,
    },
  ];

  constructor(
    private location: LocationService,
    private _ordersClient: OrdersClient,
    private route: ActivatedRoute,
    private _orderFormService: OrderFormService,
    private _packageCardService: PackageCardListService,
    private _userService: UserService,
    private _alertService: AlertService,
  ) {
    this.vehicleName = decodeURIComponent(this.route.snapshot.paramMap.get('name') || '');

    this.addPackageForm = this._orderFormService.initAddPackageForm();

    this.addPackageForm.controls.clientId.setValue(
      this.route.snapshot.paramMap.get('clientId'),
    );
    this.addPackageForm.controls.vehicleId.setValue(
      this.route.snapshot.paramMap.get('id'),
    );
  }

  ngOnInit(): void {
    this._subs.add(
      this._packageCardService.packages$.subscribe((x) => {
        this.packages = x;
        this.selectedPackagesCount = x.length.toString();
      }),
    );

    this.addPackageForm.controls.deliveryType.disable();
    this.addPackageForm.controls.paymentType.disable();

    this._subs.add(
      this._ordersClient
        .getVehicleActivePackages(this.route.snapshot.paramMap.get('id'))
        .subscribe((res) => {
          this.activePackagesCount = res.result.toString();
        }),
    );

    this.packages.valueChanges.subscribe((x) => {
      this.packagesSum = x.reduce((a, b) => a + b.price, 0);
    });
  }

  get validPackages() {
    return this.packages.value.filter((pkg) => pkg.id !== null);
  }

  handleCancelForm() {
    this.location.routeToClientsTable(this._userService.getRoleRoutePrefix);
  }

  async handleSubmitForm() {
    await this._prepareFormPackages();

    if (this.addPackageForm.value.packageItems.length === 0) {
      this._alertService.addWarnMsg('Nije odabran nijedan paket');
    }

    if (!this.addPackageForm.valid) {
      markFormGroupTouched(this.addPackageForm);
      return this._alertService.addWarnMsg('Morate popuniti sva polja');
    }

    this.addPackageForm.controls.deliveryType.enable();
    this.addPackageForm.controls.paymentType.enable();

    this._subs.add(
      this._ordersClient
        .addPackageToVehicle(
          this.addPackageForm.value as AddPackageToVehicleCommand,
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this.location.routeToClientsTable(
            this._userService.getRoleRoutePrefix,
          );
        }),
    );
  }

  private async _prepareFormPackages() {
    this._packageCardService.getPackages().value.forEach((pkg) => {
      this.addPackageForm.controls.packageItems.push(
        this._packageCardService.initPackageCardForm({
          name: pkg.name,
          id: pkg.id,
          price: pkg.price,
          clientPackageId: this.clientPackageId,
        }),
      );
    });
  }

  ngOnDestroy(): void {
    this._packageCardService.clearAllPackages();
    this._subs.unsubscribe();
  }
}
