import { Component, OnDestroy, OnInit } from '@angular/core';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { JsonPipe, NgClass, NgIf } from '@angular/common';
import { AssistanceVehicleDropdownComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/assistance-vehicle/assistance-vehicle-dropdown/assistance-vehicle-dropdown.component';
import { VehicleTypeDropdownComponent } from '@app/features/dropdowns/vehicle-type-dropdown/vehicle-type-dropdown.component';
import { ModelAutocompleteComponent } from '@app/features/autocompletes/model-autocomplete/model-autocomplete.component';
import { BrandAutocompleteComponent } from '@app/features/autocompletes/brand-autocomplete/brand-autocomplete.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { Subscription } from 'rxjs';
import {
  AssistanceFormService,
  VehicleForm,
} from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import {
  AssistanceService,
  VehicleOption,
} from '@app/sos-agent/assistances/assistance-form/services/assistance.service';
import {
  IBrandAutocompleteItem,
  IModelAutocompleteItem,
  VehiclesClient,
} from '@shared/model/atlas.api';
import { NewVehicleService } from '@app/features/services/new-vehicle-service';
import { LicencePlateRegExp } from '@shared/extensions-common';

@Component({
  selector: 'atlas-assistance-vehicle',
  standalone: true,
  imports: [
    RadioButtonModule,
    ReactiveFormsModule,
    NgIf,
    AssistanceVehicleDropdownComponent,
    NgClass,
    JsonPipe,
    VehicleTypeDropdownComponent,
    ModelAutocompleteComponent,
    BrandAutocompleteComponent,
    InputTextComponent,
  ],
  templateUrl: './assistance-vehicle.component.html',
  styleUrl: './assistance-vehicle.component.scss',
})
export class AssistanceVehicleComponent implements OnInit, OnDestroy {
  vehicleOptionCtrl: FormControl;
  vehicleTypeId: FormControl<string>;
  vehicleBrandId: string;
  showModelTxtInput: boolean = false;

  vehicleForm: VehicleForm | undefined;
  private _subs: Subscription;

  constructor(
    private _formService: AssistanceFormService,
    public service: AssistanceService,
    public newVehicleService: NewVehicleService,
    private _client: VehiclesClient,
  ) {
    this.vehicleOptionCtrl = service.vehicleOptionCtrl;
    this.vehicleForm = this._formService.getVehicleForm;
    this.vehicleTypeId = new FormControl('', {
      nonNullable: true,
    });
    this._subs = new Subscription();
  }

  ngOnInit() {
    this._subs.add(
      this._formService.getClientIdCtrl?.valueChanges.subscribe(() => {
        this.resetVehicle();
      }),
    );
    this._subs.add(
      this.vehicleTypeId.valueChanges.subscribe(() => {
        if (!this.vehicleForm.value.vehicleId) {
          this.resetVehicle();
        }
      }),
    );
    this._subs.add(
      this.newVehicleService.getShowMotorModelInput().subscribe((x) => {
        this.showModelTxtInput = x;
      }),
    );
  }

  handleOptionSelect(option: string) {
    if (this.vehicleOptionCtrl.value !== option) {
      this.vehicleOptionCtrl.patchValue(option);
      this.newVehicleService.resetCtrlValues();
    }
  }

  resetVehicle() {
    this.vehicleBrandId = '';
    this._formService.setVehicle();
    this.newVehicleService.resetCtrlValues();
  }

  handleBrandChanged(brandItem: IBrandAutocompleteItem) {
    this.vehicleBrandId = brandItem.id;
    this.handleMotorModelInput(brandItem.id);
    this.vehicleForm.controls.brand.patchValue(brandItem.label);
    this.newVehicleService.resetModelCtrlValues();
  }

  handleModelChanged(model: IModelAutocompleteItem) {
    this.vehicleForm.controls.modelId.patchValue(model.id);
    this.vehicleForm.controls.model.patchValue(model.label);
  }

  private handleMotorModelInput(brandId: string) {
    this._subs.add(
      this._client.getModelsForBrand(brandId, 'Motor').subscribe((x) => {
        if (x) {
          this.vehicleForm.controls.modelId.patchValue(
            x.result.responseList[0].id,
          );
        }
      }),
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  protected readonly VehicleOption = VehicleOption;
  protected readonly LicencePlateRegExp = LicencePlateRegExp;
}
