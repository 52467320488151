import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { PageComponent } from '@shared/components/page/page.component';
import {
  AgentsClient,
  AgentsOrderBy,
  IPaginationResponseOfAgentResponse,
} from '@shared/model/atlas.api';
import { PageInfoCardComponent } from '@ui/page-info-card/page-info-card.component';
import { ActionMode, SelectMode, TableConfig } from '@ui/table/table-common';
import { TableComponent } from '@ui/table/table.component';
import { SelectItem } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table/table.interface';
import { TabViewModule } from 'primeng/tabview';
import { firstValueFrom, Subscription } from 'rxjs';
import { ButtonStyle } from '@ui/button/button.component';
import { DropdownInputButtonComponent } from '@shared/components/dropdown-input-button/dropdown-input-button.component';
import { TableServiceLocal } from '@ui/table/table-service-local.service';
import { LocationLeaderService } from '@shared/services/location-leader.service';
import { ActiveFiltersComponent } from '@app/features/active-filters/active-filters/active-filters.component';
import { ActiveFiltersService } from '@app/features/active-filters/active-filters-service';

@Component({
  selector: 'atlas-agent-table',
  standalone: true,
  imports: [
    PageComponent,
    PageInfoCardComponent,
    TabViewModule,
    FormsModule,
    TableComponent,
    DropdownInputButtonComponent,
    ActiveFiltersComponent
  ],
  templateUrl: './agent-table.component.html',
  styleUrl: './agent-table.component.scss',
})
export class AgentTableComponent implements OnInit {
  private _subs: Subscription = new Subscription();

  onlineAgents: string = '';
  salesThisMonth: string = '';
  topAgent: string = '';
  topAgentImg: string = '';
  activeIndex: number | undefined;
  statusSearch: boolean | undefined;
  searchCtrl: FormControl;
  searchOptions: SelectItem[];
  searchOptionCtrl: FormControl;
  endDate: Date = new Date();
  private initSearch: boolean = false;
  protected tableConfig: TableConfig;
  paginatedData: IPaginationResponseOfAgentResponse;

  constructor(
    private _agentsClient: AgentsClient,
    private _loacationService: LocationLeaderService,
    private _tableService: TableServiceLocal,
    private _activeFiltersService: ActiveFiltersService
  ) {
    this.tableConfig = {
      selectMode: SelectMode.SINGLE,
      showHeader: true,
      columns: [],
      rowsPerPage: 10,
    };

    this.paginatedData = {
      data: [],
      currentPage: 0,
    };

    this.searchOptionCtrl = new FormControl<SearchOptions>(
      SearchOptions.CLIENT,
    );

    this.searchCtrl = new FormControl<string>('');

    this.searchOptions = [
      {
        label: SearchOptions.CLIENT,
        value: SearchOptions.CLIENT,
      },
      {
        label: SearchOptions.CLIENT_EMAIL,
        value: SearchOptions.CLIENT_EMAIL,
      },
    ];
  }

  ngOnInit(): void {
    this.setTableConfig().then();
    this.loadStatCards();

    this.searchCtrl.valueChanges.subscribe((value) => {
      if (!value) {
        this._activeFiltersService.removeFilter("search");
      }
    });
  
    this._subs = this._activeFiltersService.activeFilters$.subscribe((filters) => {
      const hasSearchFilter = filters.some((f) => f.key === "search");
      if (this.initSearch && !hasSearchFilter) {
        this.searchCtrl.setValue('', { emitEvent: false }); 
        this.loadTable()
      }
      this.initSearch = hasSearchFilter;
    });
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez agenata',
        description: 'Ovde će biti prikazana tabela agenata',
        btnLabel: 'Dodaj agenta',
        action: () => alert('ToDo'),
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first / event.rows + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.loadTable();
      },
      columns: [
        {
          header: 'Agent',
          field: 'name',
          imageField: 'imageUrl',
          type: 'image',
          columns: [
            {
              header: '',
              field: 'email',
              type: 'text',
            },
          ],
        },
        {
          header: 'Pozicija',
          field: 'roles',
          type: 'text',
          styleClass: 'w-10rem',
          tooltipField: 'roles',
        },
        {
          header: 'Status agenta',
          field: 'onlineStatusBadge',
          type: 'badge',
          badgeClass: 'is-active-',
        },
        {
          header: 'Ugovori',
          field: 'totalSales',
          type: 'number',
        },
        {
          header: 'Ovaj Mesec',
          field: 'salesThisMonth',
          type: 'number',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          icon: 'pi pi-eye',
          title: 'Pogledaj agenta detaljnije',
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this._loacationService.routeToAgentDetailPage(rowData.id);
          },
          shouldDisplayByCondition: () => true,
        },
      ],
    });
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  loadTable() {
    this._tableService.setIsLoading();
    if (this.searchCtrl.value) {
      this._activeFiltersService.setFilter('search', `Filter: ${this.searchCtrl.value}`);
    }
    this.loadAgents().then((res) => {
      this.paginatedData = res.result;
      this.paginatedData.data = this.paginatedData.data.map((agent: any) => ({
        ...agent,
        isOnline: agent.isOnline ? 'Online' : 'Offline',
      }));
      this._tableService.resetIsLoading();
    });
  }

  //  advancedSearch_Fields?: string[] | undefined, advancedSearch_Keyword?: string | null | undefined, keyword?: string | null | undefined, api_version?: string | null | undefined

  private async loadAgents() {
    return await firstValueFrom(
      this._agentsClient.getAgents(
        this.searchOptionCtrl.value === SearchOptions.CLIENT
          ? this.searchCtrl.value
          : undefined,
        this.searchOptionCtrl.value === SearchOptions.CLIENT_EMAIL
          ? this.searchCtrl.value
          : undefined,
        this.statusSearch,
        AgentsOrderBy.SALES_MONTH,
        true,
        this.paginatedData.currentPage,
        this.paginatedData.pageSize,
      ),
    );
  }

  loadStatCards() {
    this._subs.add(
      this._agentsClient.getAgentStatistics().subscribe((res) => {
        this.salesThisMonth = res.result.salesThisMonth;
        this.topAgent = res.result.topAgent?.name ?? '-- bez informacije --';
        this.topAgentImg = res.result.topAgent?.imageUrl;
        this.onlineAgents = res.result.onlineAgents;
      }),
    );
  }

  handleTabChange(idx: number) {
    switch (idx) {
      case 1:
        this.statusSearch = true;
        break;
      case 2:
        this.statusSearch = false;
        break;
      default:
        this.statusSearch = undefined;
    }
    this.loadTable();
  }

  protected readonly ButtonStyle = ButtonStyle;
}

enum SearchOptions {
  CLIENT = 'Ime',
  CLIENT_EMAIL = 'E-Mail',
}
