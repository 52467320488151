<atlas-scrollable-page
  pageTitle="Dodavanje promo koda"
  submitBtnLabel="Dodaj promo kod"
  [isGray]="true"
  (onCancelAction)="handleCancelForm()"
  (onSubmitAction)="handleSubmitForm()"
>
  <ng-template #content>
    <div class="flat-card">
      <div class="content flex flex-column gap-3">
        <atlas-inline-wrapper ctrlLabel="Naziv promo koda">
          <div class="input-container">
            <atlas-input-filter
              [formCtrl]="form.controls.name"
              formCtrlId="name"
              formCtrlClass="w-full lg:w-30rem"
              ctrlPlaceholder="Naziv"
              showLabel="false"
            ></atlas-input-filter>
            <div class="text-hint">Bez razmaka i specijalnih karaktera</div>
          </div>
        </atlas-inline-wrapper>
        <atlas-inline-wrapper
          ctrlLabel="Paketi"
          ctrlDesc="Paketi za koje će se moći iskoristiti promo kod"
          class="mt-4"
        >
          <div class="checkbox-grid">
            <div class="mb-4 ml-1" *ngFor="let publicPackage of allPackages">
              <p-checkbox
                [(ngModel)]="selectedActivityTypes"
                [label]="publicPackage.name"
                [value]="publicPackage.priceId"
                [disabled]="isSviSelected && publicPackage.priceId !== 'svi'"
                (onChange)="handlePackageServiceAdd($event)"
              ></p-checkbox>
            </div>
            <atlas-error-required
              [formCtrl]="form.controls.priceIds | formControl"
            ></atlas-error-required>
          </div>
        </atlas-inline-wrapper>

        <atlas-inline-wrapper
          formCtrlId="oldPrice"
          ctrlLabel="Iznos promo koda"
          ctrlDesc="Popust na procenat ili popust na vrednost"
        >
          <div class="flex align-items-end">
            <atlas-dropdown
              [hideErrors]="true"
              [options]="addonOptions"
              [formCtrl]="form.controls.valueType | formControl"
              ctrlStyleClass="input-next-to w-50rem"
            ></atlas-dropdown>
            <atlas-input-phone
              [hideErrors]="true"
              formCtrlClass="dropdown-next-to w-full lg:w-25rem"
              ctrlPlaceholder="Iznos promo koda"
              [formCtrl]="form.controls.value"
            ></atlas-input-phone>
          </div>
        </atlas-inline-wrapper>
        <atlas-inline-wrapper
          formCtrlId="countType"
          ctrlLabel="Broj promo kodova"
          ctrlDesc="Ukupan broj promo kodova koji će biti dostupan"
          class="mb-5 mt-3"
        >
          <div class="flex-column">
            <div class="mb-3">
              <p-radioButton
                name="promoCodeCount"
                [value]="PromoCodeCountType.UNLIMITED"
                [formControl]="form.controls.countType | formControl"
                inputId="unlimited"
                (onClick)="onRadioButtonChange(PromoCodeCountType.UNLIMITED)"
              ></p-radioButton>
              <label for="unlimited" class="ml-3">Neograničeno</label>
            </div>

            <div>
              <p-radioButton
                name="promoCodeCount"
                [value]="PromoCodeCountType.TOTAL_NUMBER"
                [formControl]="form.controls.countType | formControl"
                inputId="totalNumber"
                (onClick)="onRadioButtonChange(PromoCodeCountType.TOTAL_NUMBER)"
              ></p-radioButton>
              <label for="totalNumber" class="ml-3">Dodeli ukupan broj</label>

              <div
                *ngIf="form.controls.countType.value === 1"
                class="mt-2 ml-5"
              >
                <atlas-input-text
                  [formCtrl]="form.controls.count"
                  formCtrlId="count"
                  ctrlPlaceholder="Broj promo kodova"
                  showLabel="false"
                  formCtrlClass="w-full lg:w-30rem"
                ></atlas-input-text>
              </div>
            </div>
          </div>
        </atlas-inline-wrapper>

        <!-- "Važi od" Section -->
        <atlas-inline-wrapper formCtrlId="startDateType" ctrlLabel="Važi od">
          <div class="flex-column">
            <div class="mb-3">
              <p-radioButton
                name="startDate"
                [value]="0"
                [formControl]="form.controls.fromType | formControl"
                inputId="startNow"
              ></p-radioButton>
              <label for="startNow" class="ml-3">Sada</label>
            </div>

            <div>
              <p-radioButton
                name="startDate"
                [value]="1"
                [formControl]="form.controls.fromType | formControl"
                inputId="start-select-date"
              ></p-radioButton>
              <label for="start-select-date" class="ml-3">Izaberi datum</label>

              <div *ngIf="form.controls.fromType.value === 1" class="mt-2 flex">
                <p-calendar
                  [formControl]="form.controls?.from | formControl"
                  showIcon="true"
                  dateFormat="dd MM yy"
                  showTime="true"
                  hourFormat="24"
                  inputStyleClass="w-full lg:w-15rem"
                ></p-calendar>
              </div>
            </div>
          </div>
        </atlas-inline-wrapper>

        <!-- "Važi do" Section -->
        <atlas-inline-wrapper
          formCtrlId="endDateType"
          ctrlLabel="Važi do"
          class="mt-4"
        >
          <div class="flex-column">
            <div class="mb-3">
              <p-radioButton
                name="endDate"
                [value]="0"
                [formControl]="form.controls.toType | formControl"
                inputId="endUnlimited"
              ></p-radioButton>
              <label for="end-unlimited" class="ml-3">Neograničeno</label>
            </div>

            <div class="mb-3">
              <p-radioButton
                name="endDate"
                [value]="1"
                [formControl]="form.controls.toType | formControl"
                inputId="endTotalCount"
                [disabled]="
                  form.controls.countType.value === PromoCodeCountType.UNLIMITED
                "
              ></p-radioButton>
              <label for="endTotalCount" class="ml-3"
                >Dok se ne iskoristi dodeljen broj kupona</label
              >
            </div>

            <div>
              <p-radioButton
                name="endDate"
                [value]="2"
                [formControl]="form.controls.toType | formControl"
                inputId="endSelectDate"
              ></p-radioButton>
              <label for="endSelectDate" class="ml-3">Izaberi datum</label>

              <div *ngIf="form.controls.toType.value === 2" class="mt-2 flex">
                <p-calendar
                  [formControl]="form.controls?.to | formControl"
                  showIcon="true"
                  showTime="true"
                  hourFormat="24"
                  dateFormat="dd MM yy"
                  inputStyleClass="w-full lg:w-15rem"
                ></p-calendar>
              </div>
            </div>
          </div>
        </atlas-inline-wrapper>

        <!-- "Važi na" Section -->
        <atlas-inline-wrapper
          formCtrlId="enviromentType"
          ctrlLabel="Važi na"
          class="mt-4"
        >
          <div class="flex-column">
            <div class="mb-3">
              <p-checkbox
                [formControl]="form.controls.isOnWeb"
                [label]="'Aktivan na Web sajtu'"
                [binary]="true"
                (onChange)="handleOnWebChange($event)"
              ></p-checkbox>
            </div>

            <div>
              <p-checkbox
                [formControl]="form.controls.isOnMobile"
                [label]="'Aktivan na aplikaciji'"
                [binary]="true"
                (onChange)="handleOnMobileChange($event)"
              ></p-checkbox>
            </div>
          </div>
        </atlas-inline-wrapper>
      </div>
    </div>
  </ng-template>
</atlas-scrollable-page>
