import {
  CurrencyPipe,
  JsonPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet,
} from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ServiceAvailabilityComponent } from '@app/sos-agent/packages/client-package-sidebar/service-availability/service-availability.component';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { ClientClient, PackageSidebarResponse } from '@shared/model/atlas.api';
import { LocationService } from '@shared/services/location.service';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DividerModule } from 'primeng/divider';
import { SidebarModule } from 'primeng/sidebar';
import { firstValueFrom, Subscription } from 'rxjs';
import { licencePlatePipe } from '@shared/pipes/licence-plate.pipe';
import { SkeletonModule } from 'primeng/skeleton';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'atlas-package-detail-sidebar',
  standalone: true,
  imports: [
    SidebarModule,
    SidebarModule,
    DividerModule,
    NgIf,
    NgOptimizedImage,
    ButtonComponent,
    ServiceAvailabilityComponent,
    JsonPipe,
    NgForOf,
    NgTemplateOutlet,
    NgClass,
    CurrencyPipe,
    licencePlatePipe,
    SkeletonModule,
  ],
  templateUrl: './package-detail-sidebar.component.html',
  styleUrl: './package-detail-sidebar.component.scss',
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackageDetailSidebarComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  showSidebar: boolean = false;
  packageData: PackageSidebarResponse;
  clientPackageId: any;

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  constructor(
    private service: ClientPackageTableService,
    private clientPackageClient: ClientClient,
    private locationService: LocationService,
    private userService: UserService,
    //private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this.service.getShowPreviewSidebar.subscribe((show) => {
        if (show) {
          this.clientPackageId = this.service.getSidebarId;
          this.showSidebar = show;
          this._load().then();
        }
      }),
    );
  }

  async _load() {
    this._loadPackageData().then((res) => {
      this.packageData = res.result.value;
      //this.changeDetectorRef.detectChanges();
    });
  }

  private async _loadPackageData() {
    return await firstValueFrom(
      this.clientPackageClient.getPackageSidebarInfo(this.clientPackageId),
    );
  }

  handleOpenProfile() {
    alert('Profil korisnika');
  }

  handleObnoviPaket() {
    this.locationService.routeToRenewPackageForm(
      this.userService.getRoleRoutePrefix,
      this.clientPackageId,
    );
  }

  handle() {
    alert('Cekamo detalje');
  }

  ngOnDestroy(): void {
    this.service.hidePreviewSidebar();
    this.packageData = undefined;
    this._subs.unsubscribe();
  }
}
