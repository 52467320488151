import { NgForOf, CommonModule, NgIf, NgOptimizedImage } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageComponent } from '@shared/components/page/page.component';
import {
  CampaignsClient,
  CommentItem,
  IPaginationResponseOfRecommendationResponse,
  LeadResponse,
  RecommendationsClient,
} from '@shared/model/atlas.api';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { TabViewModule } from 'primeng/tabview';
import { Subscription } from 'rxjs';
import { DialogService } from '@ui/dialog/dialog.service';
import { CampaignFormService } from '@app/cc-leader/services/campaign-form.service';
import { ChangeStatusComponent } from '../change-status/change-status.component';
import { LocationService } from '@shared/services/location.service';
import { CommentsPreviewComponent } from './comments-preview/comments-preview.component';
import {
  AgentCcRoleStr,
  B2BAgentRoleStr,
  LeaderCcRoleStr,
  UserService,
} from '@shared/services/user.service';
import { RecommendationFormService } from '@app/features/recommendations/add-recommendation-form/recommendation-form.service';
import { RecommendationTableComponent } from '@app/features/recommendations/recommendation-table/recommendation-table.component';
import { CommentItemComponent } from './comment-item/comment-item.component';
import { LocationCcService } from '@shared/services/location-cc.service';
import { InfoItemComponent } from './info-item/info-item.component';

@Component({
  selector: 'atlas-process-lead',
  standalone: true,
  imports: [
    PageComponent,
    NgOptimizedImage,
    ButtonComponent,
    DividerModule,
    NgIf,
    NgForOf,
    TabViewModule,
    BadgeModule,
    CommentsPreviewComponent,
    RecommendationTableComponent,
    CommonModule,
    CommentItemComponent,
    InfoItemComponent,
  ],
  templateUrl: './process-lead.component.html',
  styleUrl: './process-lead.component.scss',
})
export class ProcessLeadComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  isRecommendation: boolean = false;

  currentId: string;
  previousId: string;
  nextId: string;

  isExpanded = false;
  previewData: LeadResponse;
  recommendationComments: CommentItem[] = [];
  btnStatusLabel: string = 'Promeni status';
  isStatusChanged = false;
  shouldReloadComments: boolean = false;
  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL_MEDIUM);
  paginatedData: IPaginationResponseOfRecommendationResponse;

  constructor(
    private _camapaignClient: CampaignsClient,
    private _recommendationClient: RecommendationsClient,
    private _userService: UserService,
    private route: ActivatedRoute,
    private _dialogService: DialogService,
    private service: CampaignFormService,
    private _locationService: LocationService,
    private _recommService: RecommendationFormService,
    private _cdr: ChangeDetectorRef,
    private _location: LocationCcService,
  ) {
    this._subs = new Subscription();
    this.paginatedData = {
      data: [],
      currentPage: 0,
    };

    this.route.data.subscribe((data) => {
      if (data['isRecommendation']) {
        this.isRecommendation = data['isRecommendation'];
      }
    });
  }

  ngOnInit(): void {
    this.isExpanded = true;
    this.currentId = this.route.snapshot.paramMap.get('id')!;
    this._getPreviewData();
  }

  toggleDetails(): void {
    this.isExpanded = !this.isExpanded;
  }

  addRecommendation() {
    if (this.isRecommendation) {
      this._recommService.setSourceRecommendationId = this.previewData.id;
    }

    this._recommService.setShowAddDialog = true;
  }

  handleAddOrder() {
    this._locationService
      .routeToCreateClient(
        this._userService.getRoleRoutePrefix(
          AgentCcRoleStr,
          B2BAgentRoleStr,
          LeaderCcRoleStr,
        ),
      )
      .then();
  }

  handlePrevious() {
    this._location.routeToProcessRecommendation(this.previousId);
    this.currentId = this.previousId;
    this._recommService.setSourceRecommendationId = this.currentId;
    this._getPreviewData();
    this.shouldReloadComments = true;
  }

  handleNext() {
    this._location.routeToProcessRecommendation(this.nextId);
    this.currentId = this.nextId;
    this._recommService.setSourceRecommendationId = this.currentId;
    this._getPreviewData();
    this.shouldReloadComments = true;
  }

  changeStatus() {
    this.dialogConfig.header = 'Promena statusa';
    this.dialogConfig.customSubmitButton = {
      label: 'Sačuvaj',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
      style: 'max-width: 100px',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;
    this.dialogConfig.data = {
      id: this.currentId,
      isRecommendation: this.isRecommendation,
      currentStatus: this.previewData.statusBadge.value,
    };
    const dialogRef = this._dialogService.open(
      ChangeStatusComponent,
      this.dialogConfig,
    );
    dialogRef.onClose.subscribe((result) => {
      if (result?.success) {
        this.isStatusChanged = true;
        if (this.previewData?.statusBadge) {
          this.previewData.statusBadge.value = result.newStatus.value;
          this.previewData.statusBadge.label = result.newStatus.label;
          this.isExpanded = true;
          this.btnStatusLabel = 'Promenjen status';
          this.shouldReloadComments = true;
        }
      }
    });
  }

  _getPreviewData() {
    if (this.isRecommendation) {
      this._subs.add(
        this._recommendationClient
          .getRecommendationAsProcessLead(this.currentId)
          .subscribe((res) => {
            this.previewData = res.result;
            this.isStatusChanged = false;
            this._setLeadTransferData(res.result);
          }),
      );

      this._subs.add(
        this._recommendationClient
          .getComments(this.currentId)
          .subscribe((res) => {
            this.recommendationComments = res?.result?.commentItems ?? [];

            this._cdr.detectChanges();
          }),
      );

      this._subs.add(
        this._recommendationClient
          .getProcessRecommendation(this.currentId)
          .subscribe({
            next: (res) => {
              this.previousId = res?.result?.previousRecommendationId;
              this.nextId = res?.result?.nextRecommendationId;
            },
            error: (e) => {
              this._locationService.routeBack();
            },
            complete: () => console.info('complete'),
          }),
      );
    } else {
      this._subs.add(
        this._camapaignClient.getLead(this.currentId).subscribe((res) => {
          this.previewData = res.result;
          this._setLeadTransferData(res.result);
        }),
      );
    }
  }

  private _setLeadTransferData(data: LeadResponse) {
    const name = data.client.split(' ')[0];
    const lastName = data.client.split(' ').slice(1).join(' ');

    this.service.setLeadTransferData({
      id: data.id,
      address: data.address,
      leadName: name,
      leadLastName: lastName,
      leadPhone: data.mobileNumber,
      leadSecondaryPhone: data.secondaryMobileNumber,
      leadTelephoneNUmber: data.telephoneNumber,
      leadEmail: data.email,
      vehicleBrand: data.carMake,
      vehicleModel: data.carModel,
      vehicleYear: data.carYear,
      registationNumber: data.registrationNumber,
      chassis: data.carVim,
      isRecommendation: this.isRecommendation,
    });
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
