<div class="mb-3" style="display: flex; gap: 20px; flex-wrap: wrap">
  <div class="main-box">
    <img
      [ngSrc]="'assets/images/users-rounded.svg'"
      width="48"
      height="48"
      alt="signal"
    />
    <div class="text-wrap">
      <div class="title h-2rem">Broj leadova</div>
      <div class="count">{{ totalLeads }}</div>
    </div>
  </div>
  <atlas-add-file-edit></atlas-add-file-edit>
</div>
<atlas-table
  [paginatedData]="paginatedData"
  [tableConfig]="tableConfig"
  [pagination]="false"
></atlas-table>
