import { JsonPipe, NgIf, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  OrderForm,
  OrderFormService,
} from '@app/cc-leader/services/order-form.service';
import { PackageCardListService } from '@app/cc-leader/services/package-card-list.service';
import {
  CreateOrderInboundCommand,
  IPackageCard,
  OrdersClient,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { LocationService } from '@shared/services/location.service';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SkeletonModule } from 'primeng/skeleton';
import { StepperModule } from 'primeng/stepper';
import { Steps, StepsModule } from 'primeng/steps';
import { Subscription } from 'rxjs';
import { markFormGroupTouched } from '@shared/forms/forms-common';
import { NewVehicleService } from '@app/features/services/new-vehicle-service';
import { LoaderService } from '@shared/components/loader/loader.service';
import { ScrollablePageWithStepsComponent } from '@shared/components/scrollable-page-with-steps/scrollable-page-with-steps.component';

@Component({
  selector: 'atlas-create-order-form',
  standalone: true,
  imports: [
    StepsModule,
    ButtonComponent,
    StepperModule,
    ScrollPanelModule,
    JsonPipe,
    ConfirmDialogModule,
    NgIf,
    NgClass,
    SkeletonModule,
    NgTemplateOutlet,
    ScrollablePageWithStepsComponent,
  ],
  templateUrl: './create-order-form.component.html',
  styleUrl: './create-order-form.component.scss',
})
export class CreateOrderFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  menuSteps: MenuItem[] | undefined;
  showLoader: boolean = false;
  shouldDisplayFinish: boolean = false;
  shouldDisplayBack: boolean = false;
  currentStep: number = 1;
  orderForm: OrderForm;

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  @ViewChild('steps') steps!: Steps;

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private location: LocationService,
    private activatedRoute: ActivatedRoute,
    private _orderFormService: OrderFormService,
    private _packageListService: PackageCardListService,
    private _ordersClient: OrdersClient,
    private _alertService: AlertService,
    private _newVehicleService: NewVehicleService,
    private _loaderService: LoaderService,
  ) {
    this.orderForm = this._orderFormService.getOrderForm;
  }

  ngOnInit(): void {
    this.initSteps();

    this._subs.add(
      this._orderFormService.currentStep$.subscribe((x) => {
        this.currentStep = x;
        this.shouldDisplayBack = this.currentStep > 1;
      }),
    );
  }

  private initSteps() {
    this.menuSteps = [
      {
        label: 'Klijent',
        routerLink: 'step-1',
        icon: 'pi pi-check',
      },
      {
        label: 'Paketi i vozilo',
        routerLink: 'step-2',
      },
      {
        label: 'Plaćanje',
        routerLink: 'step-3',
      },
    ];
  }

  handleCancel() {
    this.confirmationService.confirm({
      header: 'Odustani od kreiranja kupovine?',
      message: 'Da li želiš da odustaneš od kreiranja kupovine?',
      acceptButtonStyleClass: ButtonSeverity.DANGER,
      acceptLabel: 'Odustani',
      rejectLabel: 'Ostani',
      icon: 'confirm-cancel.svg',
      accept: () => {
        this.location.routeToClientsTable();
        this._resetOrderForm();
      },
      reject: () => {},
    });
  }

  handleBack() {
    if (this.currentStep > 1) {
      this.currentStep--;
      this._orderFormService.setStep(this.currentStep);
      this.router
        .navigate([`step-${this.currentStep}`], {
          relativeTo: this.activatedRoute,
        })
        .then(() => {
          this.shouldDisplayBack = this.currentStep > 1;
          this.shouldDisplayFinish = this.currentStep === 3;
        });
    }
  }

  private _addPackages() {
    this.orderForm.controls.packages.clear();

    const packages = this._packageListService.getPackages()
      .value as IPackageCard[];

    packages.forEach((x) => {
      this.orderForm.controls.packages.push(
        this._packageListService.initPackageCardForm(x),
      );
    });
  }

  private _nextPageAction() {
    this._addPackages();
    this.currentStep++;
    this._orderFormService.setStep(this.currentStep);
    this.router
      .navigate([`step-${this.currentStep}`], {
        relativeTo: this.activatedRoute,
      })
      .then(() => {
        this.shouldDisplayBack = this.currentStep > 1;
        this.shouldDisplayFinish = this.currentStep === 3;
      });
  }

  handleNext() {
    let form = this._orderFormService.getOrderForm;

    if (this.currentStep < 3) {
      if (this.currentStep === 1 && !form.controls.client.valid) {
        markFormGroupTouched(form.controls.client);
        return this._alertService.addWarnMsg(
          'Niste uneli obavezne podatke o klijentu',
        );
      }
      if (this.currentStep === 2) {
        this._addPackages();
        // Za vozilo
        if (form.value.isForVehicle && !this._orderFormService.isStep2Valid) {
          markFormGroupTouched(form);
          this._newVehicleService.markAsTouched();
          return this._alertService.addWarnMsg(
            'Niste uneli obavezne podatke o vozilu i paketu',
          );
        }
        // licni
        if (
          !form.value.isForVehicle &&
          !this._orderFormService.isStep2ForPersonPackageValid
        ) {
          markFormGroupTouched(form);
          return this._alertService.addWarnMsg(
            'Niste uneli obavezne podatke o vozilu i paketu',
          );
        }
      }
      this._nextPageAction();
    } else {
      this._addPackages();

      this._loaderService.setLoadingText = 'Klijent se kreira...';
      this._loaderService.setShowLoader = true;

      this._subs.add(
        this._ordersClient
          .createOrderInbound(this.orderForm.value as CreateOrderInboundCommand)
          .subscribe((x) => {
            if (x.status == 200) {
              this._loaderService.reset();
              this._alertService.addSuccessMsg(x.result);
              this.location.routeToClientsCCTable();
              this._resetOrderForm();
            }
          }),
      );
    }
  }

  private _resetOrderForm() {
    this._orderFormService.resetOrderForm();
    this._packageListService.clearAllPackages();
  }

  ngOnDestroy(): void {
    this._orderFormService.restartStep();
    this._subs.unsubscribe();
  }
}
