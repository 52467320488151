import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import {
  CampaignPriority,
  IClientWithPackagesFilter,
  ICreateContractCampaignCommand,
  ICreateLeadCampaignCommand,
  IUpdateCampaignCommandBody,
} from '@shared/model/atlas.api';

@Injectable({
  providedIn: 'root',
})
export class ContractCampaignFormService {
  private _form: ContractForm;
  private allPackageIds: string[] = [];
  private campaignId: string | null = null;

  constructor(private _fb: FormBuilder) {}

  getForm() {
    if (!this._form) {
      this.initForm();
    }
    return this._form;
  }

  setCampaignId(id: string): void {
    this.campaignId = id;
  }

  getCampaignId(): string | null {
    return this.campaignId;
  }

  setPackageIds(ids: string[]) {
    this.allPackageIds = ids;
  }

  getPackageIds(): string[] {
    return this.allPackageIds;
  }

  private initForm(model?: ICreateContractCampaignCommand) {
    this._form = this._fb.group<ControlsOf<ICreateContractCampaignCommand>>({
      name: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      description: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.maxLength(100)],
      }),
      endsOnUtc: this._fb.control(model?.endsOnUtc),
      clientPackageIds: this._fb.array<FormControl<string>>([]),
    });
  }

  setContractIds(ids: string[]) {
    console.log(ids);
    this._form.controls.clientPackageIds.clear();
    ids.forEach((id: string) => {
      this._form.controls.clientPackageIds.push(new FormControl(id));
    });

    console.log(this._form.controls.clientPackageIds);
  }

  setDurationLimit(value: Date | null) {
    this._form.controls.endsOnUtc.patchValue(value );
  }

  reset() {
    this._form = undefined;
  }
}

export declare type ContractForm = FormGroup<
  ControlsOf<ICreateContractCampaignCommand>
>;
