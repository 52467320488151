<atlas-page
  pageTitle="Lead info page"
>
<div>
    <p class="h-3">Ukupan broj: {{rowData?.count}}</p>
    <p class="h-3">Datum kreiranja: {{rowData?.createdAtFormatted}}</p>
    <p class="h-3">Kreirao agent: {{rowData?.agentName}}</p>
  </div>
</atlas-page>

