<div class="h-full flex flex-column gap-5 justify-content-between">
  <div class="page-header">
    <p-steps #steps [model]="stepItems" [readonly]="true"> </p-steps>
    <atlas-button
      btnLabel="Odustani"
      (btnClicked)="handleCancelForm()"
      btnIcon="pi pi-times"
      [btnStyle]="ButtonStyle.TEXT"
    ></atlas-button>
  </div>

  <div [ngClass]="isGray ? 'bg-gray' : ''">
    <p-scrollPanel [style]="{ width: '100%', height: 'calc(100dvh - 270px)' }">
      <ng-container *ngTemplateOutlet="content"> </ng-container>
    </p-scrollPanel>
  </div>

  <div
    [ngClass]="
      shouldDisplayBack
        ? 'flex gap-3 align-items-center h-full page-footer justify-content-between'
        : 'flex gap-3 justify-content-end align-items-center h-full page-footer'
    "
  >
    <atlas-button
      *ngIf="shouldDisplayBack"
      btnLabel="Nazad"
      btnIcon="pi pi-arrow-left"
      (btnClicked)="handleBack()"
      [btnStyle]="ButtonStyle.OUTLINED"
      [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
    ></atlas-button>
    <div>
      <atlas-button
        *ngIf="!shouldDisplayFinish"
        btnLabel="Nastavi"
        btnIcon="pi pi-arrow-right"
        btnIconPosition="right"
        (btnClicked)="handleNext()"
        [btnStyle]="ButtonStyle.BASIC"
        [btnSeverity]="ButtonSeverity.SECONDARY"
        [isBtnDisabled]="false"
      ></atlas-button>
      <atlas-button
        *ngIf="shouldDisplayFinish"
        [btnLabel]="submitBtnLabel"
        btnIcon="pi pi-plus"
        btnIconPosition="left"
        (btnClicked)="handleSubmitForm()"
        [btnStyle]="ButtonStyle.BASIC"
        [btnSeverity]="ButtonSeverity.SECONDARY"
        [isBtnDisabled]="false"
      ></atlas-button>
    </div>
  </div>
</div>
