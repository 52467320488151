import {Component, OnDestroy} from '@angular/core';
import {PageComponent} from '@shared/components/page/page.component';
import { AuthService } from '@shared/services/auth.service';
import { ButtonModule } from 'primeng/button';
import {environment} from "../../../environments/environment";
import { TokensClient } from '@shared/model/atlas.api';
import { ignoreElements, Subscription } from 'rxjs';

@Component({
    selector: 'atlas-admin-sos-settings',
    standalone: true,
    imports: [PageComponent, ButtonModule, ],
    templateUrl: './admin-settings.component.html',
    styleUrl: './admin-settings.component.scss',
})
export class AdminSettingsComponent implements OnDestroy {
    private _subs: Subscription = new Subscription();

    constructor(
        private _authService: AuthService,
        private _tokensClient: TokensClient
      ) {}

    handleLogOut() {
        this.handleSignOut().then(() => {
            this._authService.logout();
        });
    }
     
    private handleSignOut(): Promise<void> {
        return new Promise((resolve) => {
            this._subs.add(
                this._tokensClient.logoutWeb()
                .subscribe(() => {
                    resolve();
                })
            )})
    }

    ngOnDestroy(): void {
        this._subs.unsubscribe();
    }

    protected readonly environment = environment;
}
