import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'atlas-error-email',
  standalone: true,
  imports: [NgIf],
  templateUrl: './error-email.component.html',
  styleUrl: './error-email.component.scss',
})
export class ErrorEmailComponent {
  /** Props */
  errorMsg = 'E-mail nije u validnom formatu';

  /** I/O */
  @Input() formCtrl!: FormControl<any>;

  shouldDisplay(): boolean {
    return shouldDisplayEmailError(this.formCtrl);
  }
}

export function shouldDisplayEmailError(formCtrl: FormControl<any>): boolean {
  return (
    formCtrl.value &&
    formCtrl.errors?.['email'] &&
    (formCtrl.dirty || formCtrl.touched)
  );
}
