import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent, ButtonStyle } from '@ui/button/button.component';
import {NgIf, NgOptimizedImage} from '@angular/common';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
  selector: 'atlas-page',
  standalone: true,
  imports: [ButtonComponent, NgIf, ScrollPanelModule, NgOptimizedImage],
  templateUrl: './page.component.html',
  styleUrl: './page.component.scss',
})
export class PageComponent {
  @Input() pageTitle: string = '';
  @Input() pageDescription: string = '';
  @Input() actionLabel: string = '';
  @Input() btnIcon: string | undefined;
  @Input() btnSvgUrl: string | undefined;
  @Input() secondaryActionLabel: string;
  @Input() secondaryBtnIcon: string | undefined;
  @Input() secondaryBtnSvgUrl: string | undefined;
  @Output() onHandleAction = new EventEmitter();
  @Output() onHandleSecondAction = new EventEmitter();


  protected readonly ButtonStyle = ButtonStyle;

  handleAction() {
    this.onHandleAction.emit();
  }

  handleActionSecondary() {
    this.onHandleSecondAction.emit();
  }

}
