import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { DividerModule } from 'primeng/divider';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { JsonPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { PackageForm, PackageService } from '../../services/package-service';
import { InputTextareaComponent } from '@ui/input-textarea/input-textarea.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import {
  CreatePackageCommand,
  EditPackageCommand,
  ICreatePackageCommand,
  PackagePriceItem,
  PackagesClient,
  ServiceItem,
  VehicleTypeItem,
  VehiclesClient,
} from '@shared/model/atlas.api';
import { Subscription } from 'rxjs';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { VehicleTypeCardComponent } from '@ui/vehicle-type-card/vehicle-type-card.component';
import { CheckboxModule } from 'primeng/checkbox';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { FileUploadComponent } from '@ui/file-upload/file-upload.component';
import { PackageDetailAutocompleteComponent } from '../package-detail-autocomplete/package-detail-autocomplete.component';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { DialogService } from '@ui/dialog/dialog.service';
import { PackageActionFormComponent } from '../package-action-form/package-action-form.component';
import { ControlsOf, markFormGroupTouched } from '@shared/forms/forms-common';
import { AlertService } from '@shared/services/alert.service';
import { LocationService } from '@shared/services/location.service';
import { ActionPriceCardComponent } from '../action-price-card/action-price-card.component';
import { Mode } from '../../partners/partner-form/partner-form.component';
import { ActivatedRoute } from '@angular/router';
import {ScrollablePageComponent} from "@shared/components/scrollable-page/scrollable-page.component";
import {CalendarModule} from "primeng/calendar";
import {DropdownComponent} from "@ui/dropdown/dropdown.component";
import {ErrorRequiredComponent} from "@ui/errors/error-required/error-required.component";
import {InputFilterComponent} from "@ui/input-filter/input-filter/input-filter.component";
import {InputPhoneComponent} from "@shared/components/input-phone/input-phone.component";

export enum Moode {
  NEW = 1,
  EDIT = 2,
}

@Component({
  selector: 'atlas-package-form',
  standalone: true,
  imports: [
    DividerModule,
    ScrollPanelModule,
    FormsModule,
    NgClass,
    InlineWrapperComponent,
    InputTextComponent,
    InputTextareaComponent,
    RadioButtonModule,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    FormControlPipe,
    ButtonComponent,
    VehicleTypeCardComponent,
    CheckboxModule,
    InputAddonComponent,
    FileUploadComponent,
    PackageDetailAutocompleteComponent,
    JsonPipe,
    ActionPriceCardComponent,
    ScrollablePageComponent,
    CalendarModule,
    DropdownComponent,
    ErrorRequiredComponent,
    InputFilterComponent,
    InputPhoneComponent,
  ],
  templateUrl: './package-form.component.html',
  styleUrl: './package-form.component.scss',
})
export class PackageFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  vehicleIdRequired: boolean = false;
  jmbgRequired: boolean = false;
  imageUri: string;
  form: PackageForm;
  fileUploadDisabled: boolean;
  additionalServices: string[] = [];
  currentPackageId: string;
  pageLabel: string = 'Dodavanje paketa';
  submitBtnLabel: string = 'Dodaj paket';
  mode: Mode;
  shouldResetId = true;

  services: ServiceItem[];
  selectedPackageServices: string[] = [];

  vehicleTypes: VehicleTypeItem[];
  selectedVehicleTypes: string[] = [];

  loadedPackage: any;

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);

  constructor(
    private _packageService: PackageService,
    private _vehiclesClient: VehiclesClient,
    private _packageClient: PackagesClient,
    private _dialogService: DialogService,
    private _alertService: AlertService,
    private _locationService: LocationService,
    private _fb: FormBuilder,
    private _route: ActivatedRoute
  ) {
    this.loadVehicleTypes();
    this.loadPackageServices();
  }

  ngOnInit(): void {
    this.loadPackageServices();
    this.form = this._packageService.initPackageForm();

    this._subs.add(
      this._packageService.getSelectedPackageId.subscribe((res) => {
        this.currentPackageId = res;
      }),
    );


    this._route.params.subscribe(params => {
      this.currentPackageId = params['id'];
  
      if (this.currentPackageId) {
        this.mode = Mode.EDIT;
        this.pageLabel = 'Izmena paketa';
        this.submitBtnLabel = 'Izmeni paket';
        this.getPackageById(this.currentPackageId);
      } else {
        this.mode = Mode.NEW;
        this.fileUploadDisabled = true;
      }
    });
  }

  servicesUpdated(services: string[]) {
    services.forEach((newService: string) => {
      const alreadyExists = this.additionalServices.find(
        (service: string) => service === newService
      );
      if (!alreadyExists) {
        this.additionalServices.push(newService);
      }
    });
  
    this.form.controls.packageDetails.clear();
    this.additionalServices.forEach((service: string) => {
      this.form.controls.packageDetails.push(new FormControl<string>(service));
    });
  
    this.form.controls.packageDetails.updateValueAndValidity();
  }

  private loadVehicleTypes() {
    this._subs.add(
      this._vehiclesClient.getVehicleTypes().subscribe((res) => {
        this.vehicleTypes = res.result.responseList;
  
        this.vehicleTypes.forEach((type) => {
          if (type.name === 'Putnička vozila') {
            type.name = 'Putničko';
          }
        });
      })
    );
  }

  private loadPackageServices() {
    this._subs.add(
      this._packageClient.getAllServices().subscribe((res) => {
        this.services = res.result.responseList;
      }),
    );
  }

  private getPackageById(id: string) {
    this._subs.add(
      this._packageClient.getPackage(id).subscribe((res) => {

        const loadedPackage = res.result as unknown as ICreatePackageCommand;
        this.form.patchValue(loadedPackage);

        this.imageUri = res.result.imageUri;

        // Tipovi vozila
        res.result.vehicleTypes.forEach((x) => {
          this.form.controls.vehicleTypes.push(new FormControl<string>(x));
          this.selectedVehicleTypes = res.result.vehicleTypes.map((x) => x);
        });

        // Usluge paketa
        res.result.services.forEach((x) => {
          this.form.controls.packageServices.push(new FormControl<string>(x));
          this.selectedPackageServices.push(x);
          this.form.controls.packageServices.updateValueAndValidity();
        });

        // Dodatne usluge
        res.result.details.forEach((x) => {
          this.form.controls.packageDetails.push(new FormControl<string>(x));
          this.additionalServices.push(x);
        });

        // Akcijske cene
        const pricesFormArray = this._fb.array<
          FormGroup<ControlsOf<PackagePriceItem>>
        >([]);

        res.result.prices.forEach((price) => {
          const priceFormGroup = this._packageService.initActionPriceForm(price);
          priceFormGroup.patchValue(price);
          pricesFormArray.push(priceFormGroup);
        });

        this.form.setControl('packagePrices', pricesFormArray);
      }),
    );
  }

  handlePackageServiceAdd(event: any) {
    this.form.controls.packageServices.clear();
    this.form.controls.packageServices.updateValueAndValidity();

    for (const id of event.checked) {
      this.form.controls.packageServices.push(new FormControl<string>(id));
    }
  }

  handleVehicleTypeAdd(event: string) {
    this.form.controls.vehicleTypes.push(new FormControl<string>(event));
  }

  handleRemoveBadge(idx: number) {
    this.additionalServices.splice(idx, 1);
    this.form.controls.packageDetails.removeAt(idx);
  }

  handleAddAction() {
    this.dialogConfig.header = 'Nova akcija';
    this.dialogConfig.customSubmitButton = {
      label: 'Dodaj',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    this.dialogConfig.closable = true;

    this.openDialog(PackageActionFormComponent);
  }

  openDialog(form: any) {
    this._dialogService.open(form, this.dialogConfig);
  }

  handleSubmitForm() {
    this.form.controls.isVehicleRequired.patchValue(this.vehicleIdRequired);
    this.form.controls.isClientIdRequired.patchValue(this.jmbgRequired);

    if (this.form.value.oldPrice === null) {
      this._alertService.addWarnMsg('Nije uneta stara cena');
      return;
    }

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      this._alertService.addWarnMsg('Morate uneti sva obavezna polja');
      return;
    }

    if (this.form.value.price === 0) {
      this._alertService.addWarnMsg('Trenutna cena ne sme biti 0,00');
      return;
    }

    if (this.form.value.packageDetails.length === 0) {
      this._alertService.addWarnMsg(
        'Morate uneti bar jednu dodatnu uslugu paketa',
      );
      return;
    }

    if (this.mode == Mode.NEW) {
      this._subs.add(
        this._packageClient
          .createPackage(this.form.value as CreatePackageCommand)
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.result);
            this.routeToPackages();
          }),
      );
    } else {
      var editForm = this.form.value as EditPackageCommand;
      editForm.id = this.currentPackageId;
      editForm.packagePrices.forEach((price) => {
        price.packageId = this.currentPackageId;
      })
      this._subs.add(
        this._packageClient.editPackage(editForm).subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this.routeToPackages();
        }),
      );
    }
  }

  handlePhotoUpload(event: any) {
    const file = event.files[0];
    if (file instanceof File) {
      const fileParameter = {
        data: file,
        fileName: file.name,
      };

      this._subs.add(
        this._packageClient
          .uploadPackageImage(null, fileParameter, this.currentPackageId)
          .subscribe(() => {
            this._alertService.addSuccessMsg(
              'Fotografija paketa uspešno dodeljena',
            );
          }),
      );
    } else {
      this._alertService.addFailedMsg('Format datoteke nije ispravan');
    }
  }

  routeToPackages() {
    this._locationService.routeToPackageTable();
  }

  clearForm() {
    this.form = undefined;
    this._packageService.reset();
    this._packageService.setPackageId(undefined);
  }

  handleCancelForm() {
    this.form.reset();
    this.routeToPackages();
  }

  ngOnDestroy(): void {
    this.clearForm();
    this._subs.unsubscribe();
  }

  protected readonly Moode = Moode;
  protected readonly Mode = Mode;
}
