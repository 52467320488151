import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AgentsClient, IAgentBaseInfoResponse } from '@shared/model/atlas.api';
import { MultiselectComponent } from '@ui/multiselect/multiselect.component';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'atlas-agent-multiselect',
  standalone: true,
  imports: [MultiselectComponent],
  templateUrl: './agent-multiselect.component.html',
  styleUrl: './agent-multiselect.component.scss'
})
export class AgentMultiselectComponent implements OnInit, OnDestroy {
  private _subs: Subscription;

  agentOptions: IAgentBaseInfoResponse[] = [];
  formGroup: FormGroup<AgentForm>;

  @Input() agentsCtrl: FormArray;

  constructor(private _client: AgentsClient) {
    this.formGroup = new FormGroup({
      agents: new FormControl([]),
    });
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this._load();

    this._subs.add(
      this.formGroup.valueChanges.subscribe((value) => {
        this.agentsCtrl.clear();
        value.agents.forEach((x) => {
          this.agentsCtrl.push(new FormControl(x.id));
        });
      })
    );

    this._subs.add(
      this.agentsCtrl.valueChanges.subscribe((value) => {
        this.formGroup.controls.agents.setValue(value.map((id) =>
          this.agentOptions.find((opt) => opt.id == id) || null
          ), { emitEvent: false });
      })
    );
  }

  private _load() {
    this._getAgents().then((res) => {
      this.agentOptions = res.result;
    })
  }

  private async _getAgents() {
    return await firstValueFrom(this._client.getCcAgents());
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}

export type AgentForm = {
  agents: FormControl<IAgentBaseInfoResponse[]>;
};
