<div class="comment-item fadein animation-linear animation-duration-500">
    <img *ngIf="comment.imgUrl; else defaultImg" [ngSrc]="comment.imgUrl" class="client-icon" width="40" height="40" />
    <ng-template #defaultImg>
      <img [ngSrc]="'assets/images/user-colored.svg'" alt="User" class="client-icon" width="40" height="40" />
    </ng-template>
    <div class="comment-content">
      <div class="comment-meta">
        <span class="comment-name">{{ comment.name }}</span>
        <span class="comment-date">{{ formattedDate }}</span>
      </div>
      <div class="comment-text">{{ comment.text }}</div>
    </div>
  </div>
  