<atlas-scrollable-page
  pageTitle="Dodavanje kampanje"
  submitBtnLabel="Dodaj kampanju"
  (onCancelAction)="handleCancelForm()"
  (onSubmitAction)="handleSubmitForm()"
>
  <ng-template #content>
    <div class="header">
      Detalji
      <div class="header-desc font-normal mt-1">Vrsta i detalji kampanje</div>
    </div>
    <p-divider></p-divider>
    <div class="flex mt-5">
      <!--Tipovi kampanje-->
      <atlas-inline-wrapper
        formCtrlId="vehicleTypes"
        ctrlLabel="Vrsta kampanje"
      >
        <div class="checkbox-pos">
          <atlas-icon-checkbox
            name="Kampanja leadova"
            [imageUrl]="'assets/images/leads.svg'"
            [isSelected]="selectedOption === 'Leadovi'"
            (select)="selectedOption = 'Leadovi'"
          ></atlas-icon-checkbox>
        </div>
        <atlas-icon-checkbox
          name="Kampanja iz ugovora"
          [isSelected]="selectedOption === 'Ugovori'"
          [imageUrl]="'assets/images/contracts.svg'"
          (select)="selectedOption = 'Ugovori'"
        ></atlas-icon-checkbox>
      </atlas-inline-wrapper>
    </div>
    <p-divider></p-divider>
    <atlas-add-campaign-contract
      *ngIf="selectedOption === 'Ugovori'"
    ></atlas-add-campaign-contract>
    <atlas-add-campaign-leads
      *ngIf="selectedOption === 'Leadovi'"
    ></atlas-add-campaign-leads>
  </ng-template>
</atlas-scrollable-page>
