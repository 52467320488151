<div
        class="flex flex-column gap-3"
        *ngIf="isLoading || totalCount > 0; else emptyTable"
>
    <!-- <atlas-table-header
        *ngIf="tableConfig.showHeader"
        [tableConfig]="tableConfig"></atlas-table-header> -->
    <p-table
        #dt
        [(selection)]="selectedItem"
        [autoLayout]="false"
        [columns]="visibleColumns"
        [paginator]="pagination"
        [reorderableColumns]="true"
        [resizableColumns]="false"
        [rowsPerPageOptions]="[5, 10, 25, 50]"
        [showCurrentPageReport]="true"
        [value]="isLoading ? skeletonItems : items"
        [first]="first"
        [rows]="tableConfig.rowsPerPage"
        currentPageReportTemplate="Prikazano {first} - {last} od {totalRecords} entiteta"
        dataKey="id"
        [lazy]="tableConfig.isLazy"
        (onLazyLoad)="handleLazy($event)"
        [lazyLoadOnInit]="false"
        [totalRecords]=" totalCount"
        [scrollable]="true"
    >
        <ng-template pTemplate="header">
            <tr>
                <ng-container *ngFor="let col of visibleColumns">
                    @if (col.isFrozen) {
                        <th
                            [ngClass]="{ 'text-right': col.type === 'currency' || col.type === 'number' }"
                            [ngStyle]="{ width: col.type === 'initials' ? '1rem' : 'initial' }"
                            pFrozenColumn
                            [frozen]="true">
                            {{ col.header }}
                        </th>
                    } @else {
                        <th
                            [ngClass]="{ 'text-right': col.type === 'currency' || col.type === 'number'}"
                            [ngStyle]="{ width: col.type === 'initials' ? '1rem' : 'initial' }">
                            {{ col.header }}
                            <div style="font-size: 12px !important;">{{ col.seconHeader }}</div>
                        </th>
                    }
                </ng-container>
                <th
                        *ngIf="rowActions && rowActions.length > 0"
                        pFrozenColumn
                        alignFrozen="right"
                ></th>
            </tr>
        </ng-template>

        <!-- Rows -->
        <ng-template let-item let-rowIndex="rowIndex" pTemplate="body">
            <tr
                    *ngIf="!isLoading"
                    [pSelectableRowDisabled]="
          !tableConfig.selectMode ||
          (tableConfig.isSelectableRowDisabled &&
            tableConfig.isSelectableRowDisabled(item))
        "
                    [pSelectableRowIndex]="rowIndex"
                    [pSelectableRow]="item"
                    class="fadein"
            >
                <ng-container *ngFor="let col of visibleColumns">
                    @if (col.isFrozen) {
                        <td style="overflow: hidden; text-overflow: ellipsis" pFrozenColumn>
                            <atlas-table-row-data
                                    [column]="col"
                                    [item]="item"
                                    [parent]="dt"
                                    [rowIndex]="rowIndex"
                            >
                            </atlas-table-row-data>
                        </td>
                    } @else {
                        <td style="overflow: hidden; text-overflow: ellipsis">
                            <atlas-table-row-data
                                    [column]="col"
                                    [item]="item"
                                    [parent]="dt"
                                    [rowIndex]="rowIndex"
                            >
                            </atlas-table-row-data>
                        </td>
                    }
                </ng-container>

                <td
                        *ngIf="rowActions && rowActions.length > 0"
                        pFrozenColumn
                        alignFrozen="right"
                >
                    <div class="flex align-items-end justify-content-end gap-2 row-actions">
                        <ng-container
                                *ngIf="rowContainsActions(rowIndex); else rowWithoutActions"
                        >
                            <atlas-button
                                    *ngFor="let action of singleActions"
                                    [btnIcon]="action.icon"
                                    [btnTooltip]="action.title ?? handleHover(action, rowIndex)"
                                    btnTooltipPosition="left"
                                    [btnSeverity]="
                  action.actionSeverity ?? ButtonSeverity.SECONDARY
                "
                                    [btnStyle]="action.actionStyle ?? ButtonStyle.TEXT"
                                    [btnLabel]="action.label"
                                    (btnClicked)="action.callback(rowIndex)"
                                    [btnClass]="'text-sm ' + action.actionClass"
                                    [ngClass]="
                  action.shouldDisplayByCondition(rowIndex) ? 'block' : 'hidden'
                "
                            >
                                <img
                                        *ngIf="action.svgName"
                                        [ngSrc]="'assets/images/buttons/' + action.svgName + '.svg'"
                                        width="20"
                                        height="20"
                                        alt="action-image"
                                />
                            </atlas-button>
                            <div>
                                <i
                                        [ngClass]="
                    rowContainsMultiActions(rowIndex)
                      ? 'pi pi-ellipsis-v cursor-pointer block'
                      : 'hidden'
                  "
                                        (click)="rowPopup($event, rowIndex)"
                                ></i>
                            </div>
                        </ng-container>
                    </div>
                </td>
            </tr>

            <tr *ngIf="isLoading" class="">
                <td *ngFor="let col of visibleColumns">
                    <p-skeleton></p-skeleton>
                </td>

                <td *ngIf="rowActions && rowActions.length > 0">
                    <p-skeleton></p-skeleton>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<ng-template #rowWithoutActions>
    <div
            class="w-2rem h-2rem"
            showDelay="500"
            hideDelay="300"
            pTooltip="Za ovaj red ne postoje akcije"
    ></div>
</ng-template>

<ng-template #emptyTable>
    <div class="table-empty-box" *ngIf="tableConfig.emptyTableInfo">
        <div class="title">{{ tableConfig.emptyTableInfo.header }}</div>
        <div class="desc">{{ tableConfig.emptyTableInfo.description }}</div>
        <atlas-button
                *ngIf="tableConfig.emptyTableInfo.btnLabel"
                (btnClicked)="handleCreate()"
                [btnStyle]="ButtonStyle.BASIC"
                btnClass="w-full"
                [btnIcon]="tableConfig.emptyTableInfo.btnIcon ?? 'pi pi-plus'"
                [btnLabel]="tableConfig.emptyTableInfo.btnLabel"
        >
        </atlas-button>
    </div>
</ng-template>

<p-overlayPanel #rowActionPanel>
    <ng-template pTemplate>
        <div class="flex flex-column gap-2 action-wrapper">
            <ng-container *ngFor="let action of multiActions">
                <atlas-button
                        [btnLabel]="action.label!"
                        [btnTooltip]="action.label"
                        (btnClicked)="handleRowAction(action)"
                        [btnClass]="
            action.shouldDisplayByCondition(actionRowIndex)
              ? 'w-full h-full align-items-baseline ' + action.actionClass
              : 'hidden'
          "
                        [btnIcon]="action.icon"
                ></atlas-button>
            </ng-container>
        </div>
    </ng-template>
</p-overlayPanel>

<!--ToDo: ako nam se ucini da presporo otvara popup za akcije, mozemo dodati ove props u overlayPanel-->
<!--[showTransitionOptions]="'0ms'"-->
<!--[hideTransitionOptions]="'0ms'"-->
