import { NgIf } from '@angular/common';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { ChangeSellerDialogComponent } from '@app/cc-leader/clients/change-seller-dialog/change-seller-dialog.component';
import { DialogService } from '@ui/dialog/dialog.service';
import { AddTempSellerDialogComponent } from '@app/cc-leader/clients/add-temp-seller-dialog/add-temp-seller-dialog.component';
import { ToastMessageComponent } from '@app/cc-leader/toast-message/toast-message/toast-message.component';
import {UserService} from "@shared/services/user.service";
import { PackageCardListService } from '@app/cc-leader/services/package-card-list.service';
import { LocationService } from '@shared/services/location.service';
import {ClientPackageTableService} from "@app/sos-agent/packages/services/client-package-table.service";
import {Subscription} from "rxjs";
import {IClientWithPackagesItem} from "@shared/model/atlas.api";
import {OrderFormService} from "@app/cc-leader/services/order-form.service";

@Component({
  selector: 'atlas-checkbox-action-dialog',
  standalone: true,
  imports: [NgIf, ButtonModule, TooltipModule, ToastMessageComponent],
  templateUrl: './checkbox-action-dialog.component.html',
  styleUrl: './checkbox-action-dialog.component.scss',
})
export class CheckboxActionDialogComponent implements OnInit, OnDestroy {
  _subs: Subscription;
  agentName: string = '';
  showToast: boolean = false;
  packageIdsLength: number = 0;
  selectedPackages: IClientWithPackagesItem[] = [];

  @Input() distinctVehicleCount: number = 0;
  @Input() canRenewPackage!: boolean;
  @Output() sellerChanged = new EventEmitter<any>();

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);

  constructor(
    private _dialogService: DialogService,
    private _packageCardService: PackageCardListService,
    private _locationService: LocationService,
    public userService: UserService,
    private _cpService: ClientPackageTableService,
    private _orderFormService: OrderFormService,
  ) {
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this._subs = this._cpService.getSelectedPackages().subscribe(res => {
      this.selectedPackages = res;
    })
  }


  handleAgentAssignment() {
    this.dialogConfig.header = 'Dodeli privremenom prodavcu';
    this.dialogConfig.customSubmitButton = {
      label: 'Potvrdi',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;

    const dialogRef = this._dialogService.open(
      AddTempSellerDialogComponent,
      this.dialogConfig,
    );
    this._subs.add(dialogRef.onClose.subscribe((result) => {
      if (result?.isTempSellerAssigned) {
          // Reset showToast to true before displaying the toast again
          this.showToast = true;
          this.agentName = result.agentName;
          this.packageIdsLength = result.packageIdsLength;
          this.sellerChanged.emit(result);
      }
    }));
  }

  handleSellerChange() {
    this.dialogConfig.header = 'Promeni prodavca';
    this.dialogConfig.customSubmitButton = {
      label: 'Potvrdi',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;

    const dialogRef = this._dialogService.open(
      ChangeSellerDialogComponent,
      this.dialogConfig,
    );
   this._subs.add(dialogRef.onClose.subscribe((result) => {
      if (result?.isSellerChanged) {
        // Reset showToast to true before displaying the toast again
        this.showToast = true;
        this.agentName = result.agentName;
        this.packageIdsLength = result.packageIdsLength;
        this.sellerChanged.emit(result);
      }
    }));
  }

  handleRenewPackages() {
    this._packageCardService.setSelectedPackagesForRenew(
      this.selectedPackages.map((x) => {
        return {
          clientPackageId: x.clientPackageId,
          packageId: x.packageId
        }
      })
    );
    this._locationService.routeToRenewPackageForm();
  }

    ngOnDestroy() {
    this._subs.unsubscribe();
    }
}
