import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Guid } from 'guid-typescript';
import { ILocationItem } from '@shared/model/atlas.api';

export type ControlsOf<T extends Record<string, any>> = {
  [K in keyof T]: T[K] extends Date
    ? FormControl<T[K]>
    : T[K] extends Record<any, any>
      ? T[K] extends (infer U)[]
        ? U extends Record<any, any>
          ? FormArray<FormGroup<ControlsOf<U>>>
          : UntypedFormArray
        : FormGroup<ControlsOf<T[K]>>
      : FormControl<T[K]>;
};

/**
 * Marks all controls in a form group as touched
 * @param formGroup - The form group to touch
 */
export function markFormGroupTouched(formGroup: FormGroup) {
  (<any>Object).values(formGroup.controls).forEach((control: any) => {
    control.markAsTouched();

    if (control.controls) {
      markFormGroupTouched(control);
    }
  });
}

// export function isValidGuid(formControl: FormControl) {
//   return (
//     formControl.valid &&
//     (!formControl.hasValidator(Validators.required) ||
//       formControl.value !== Guid.createEmpty().toString())
//   );
// }

export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  return control.parent == null
    ? null
    : control.parent!.value.password === control.value
      ? null
      : { PasswordNoMatch: true };
};

export function guidValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    return !value || value === Guid.createEmpty().toString()
      ? { invalidGuid: true }
      : null;
  };
}

export function isEnum(instance: Object): boolean {
  let keys = Object.keys(instance);
  let values = [];

  for (let key of keys) {
    let value = instance[key];

    if (typeof value === 'number') {
      value = value.toString();
    }

    values.push(value);
  }

  for (let key of keys) {
    if (values.indexOf(key) < 0) {
      return false;
    }
  }

  return true;
}

export declare type LocationForm = FormGroup<ControlsOf<ILocationItem>>;

export function getLocationForm(model?: ILocationItem): LocationForm {
  return new FormGroup<ControlsOf<ILocationItem>>({
    latitude: new FormControl(model?.latitude, {
      validators: [Validators.required],
    }),
    longitude: new FormControl(model?.longitude, {
      validators: [Validators.required],
    }),
    place: new FormControl(model?.place, {
      validators: [Validators.required, Validators.maxLength(256)],
    }),
  });
}

export function formatCityAndCountry(
  place: google.maps.places.PlaceResult,
): string | null {
  const components = place.address_components || [];
  const locality = components.find((c) => c.types.includes('locality'));
  const country = components.find((c) => c.types.includes('country'));
  const city = components.find((c) => c.types.includes('route'));
  const streetNum = components.find((c) => c.types.includes('street_number'));

  const parts = [
    locality?.long_name,
    streetNum?.long_name,
    country?.long_name,
    city?.long_name,
  ].filter(Boolean);

  return parts.length ? parts.join(', ') : null;
}
