<div class="flex gap-2 flex-column w-full">
  <label  [for]="formCtrlId" *ngIf="showLabel">{{ ctrlLabel }} </label>
  <label  [for]="formCtrlId" *ngIf="showEmptyLabel"></label>
  <p-dropdown
    (onChange)="handleOnItemChange($event)"
    [disabled]="ctrlDisabled"
    [filterBy]="ctrlFilterBy"
    [filterPlaceholder]="ctrlFilterPlaceholder"
    [filter]="canFilter"
    [formControl]="formCtrl"
    [group]="isGrouped"
    [inputId]="formCtrlId"
    [optionLabel]="optionLabel"
    [options]="options"
    [placeholder]="ctrlPlaceholder"
    [showClear]="canClear"
    [styleClass]="ctrlStyleClass"
    [panelStyleClass]="ctrlItemStyleClass"
    [tooltip]="ctrlTooltip"
    [dataKey]="ctrlDataKey"
    [appendTo]="ctrlAppendTo"
  >
    <ng-template let-outerContext pTemplate="selectedItem" *ngIf="selectedItem">
      <ng-container
        *ngTemplateOutlet="selectedItem; context: { $implicit: outerContext }"
      >
      </ng-container>
    </ng-template>

    <ng-template let-outerContext pTemplate="item" *ngIf="item">
      <ng-container
        *ngTemplateOutlet="item; context: { $implicit: outerContext }"
      >
      </ng-container>
    </ng-template>
  </p-dropdown>

  <atlas-error-required *ngIf="!hideErrors" [formCtrl]="formCtrl"></atlas-error-required>
</div>
