import {NgIf} from '@angular/common';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormsModule, NgModel, ReactiveFormsModule} from '@angular/forms';
import {DeliveryType, PaymentType} from '@shared/model/atlas.api';
import {DropdownComponent} from '@ui/dropdown/dropdown.component';
import {SelectItem} from 'primeng/api';
import {SelectButtonModule} from 'primeng/selectbutton';
import {CalendarComponent} from "@ui/calendar/calendar.component";
import {FormControlPipe} from "@shared/pipes/form-control.pipe";
import {Subscription} from "rxjs";

@Component({
  selector: 'atlas-payment-type-options',
  standalone: true,
  imports: [
    SelectButtonModule,
    DropdownComponent,
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    CalendarComponent
  ],
  templateUrl: './payment-type-options.component.html',
  styleUrl: './payment-type-options.component.scss'
})
export class PaymentTypeOptionsComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  shouldDisplayDeliveryDate: boolean = false;

  stateOptions: SelectItem[] = [
    {
      label: 'Završi kupovinu preko mobilne aplikacije',
      value: 'draft',
      title:
        'Klijent treba da dovriši plaćanje online, kroz mobilnu aplikaciju.',
    },
    {
      label: 'Završi kupovinu na standardan način',
      value: 'new',
      title: 'Klijent treba da plati kuriru ili putem uplatnice',
    },
  ];

  stateValue: 'new' | 'draft' = 'draft';

  deliveryType: any = [
    {label: 'eUgovor', value: DeliveryType.E_CONTRACT},
    {label: 'Lično preuzimanje', value: DeliveryType.PICK_UP},
    {label: 'Bex', value: DeliveryType.BEX},
    {label: 'Bex besplatna', value: DeliveryType.BEX_FREE},
    {label: 'Bex bez otkupa', value: DeliveryType.BEX_WITHOUT_DEPOSIT},
    {label: 'Kurir NS', value: DeliveryType.COURIER_NS},
    {label: 'Promo-sezonac', value: DeliveryType.PROMO_SEASONAC},
  ];

  draftPaymentTypeOptions: any = [
    {label: 'Corvus Pay', value: PaymentType.CORVUS_PAY},
    {label: 'Gotovina', value: PaymentType.CASH},
    {label: 'Uplatnica', value: PaymentType.PAYMENT_SLIP},
  ];
  newPaymentTypeOptions: SelectItem<PaymentType>[] = [
    {label: 'Gotovina', value: PaymentType.CASH},
    {label: 'Uplatnica', value: PaymentType.PAYMENT_SLIP},
  ];

  @Input() deliveryTypeCtrl: FormControl = new FormControl(DeliveryType.E_CONTRACT);
  @Input() deliveryDateCtrl: FormControl = new FormControl(new Date());
  @Input() paymentTypeCtrl: FormControl = new FormControl(PaymentType.CORVUS_PAY);

  ngOnInit() {
    this._subs.add(
      this.deliveryTypeCtrl.valueChanges.subscribe(value => {
        this._handleDeliveryDate(value);
      })
    )
  }

  handleStateChange($event: SelectItem) {
    if ($event.value === 'draft') {
      this._handleDraft();
    } else {
      this._handleNew();
    }
  }

  private _handleDraft() {
    this.deliveryTypeCtrl.patchValue(DeliveryType.E_CONTRACT);
    this.paymentTypeCtrl.patchValue(PaymentType.CORVUS_PAY);
    this.deliveryDateCtrl.disable();
    this.deliveryTypeCtrl.disable();
    this.paymentTypeCtrl.disable();
  }

  private _handleNew() {
    this.deliveryDateCtrl.enable();
    this.deliveryTypeCtrl.enable();
    this.paymentTypeCtrl.enable();
    this.paymentTypeCtrl.patchValue(PaymentType.PAYMENT_SLIP);
  }

  private _handleDeliveryDate(deliveryType: DeliveryType) {
    if (deliveryType === DeliveryType.E_CONTRACT) {
      this.shouldDisplayDeliveryDate = false;
      this.deliveryDateCtrl.patchValue(null);
      return;
    }
    this.shouldDisplayDeliveryDate = true;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  protected readonly DeliveryType = DeliveryType;
}
