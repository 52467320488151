import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { SourcesMultiselectComponent } from '@app/features/multiselects/sources-multiselect/sources-multiselect.component';
import { PageComponent } from '@shared/components/page/page.component';
import {
  GetGlobalPackagesStatisticsQuery,
  GetMonthlyPackageReportQuery, GetMonthlyPackagesStatisticsQuery,
  GetMonthlyReportResponse,
  GlobalPackagesReportFilter,
  IPaginationResponseOfGetMonthlyReportResponse, PackageReportStatsResponse,
  ReportsClient,
} from '@shared/model/atlas.api';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { CalendarComponent } from '@ui/calendar/calendar.component';
import { SelectMode, TableConfig } from '@ui/table/table-common';
import { TableServiceLocal } from '@ui/table/table-service-local.service';
import { TableComponent } from '@ui/table/table.component';
import { TableLazyLoadEvent } from 'primeng/table';
import { firstValueFrom, Subscription } from 'rxjs';
import { ReportYearsDropdownComponent } from '../report-years-dropdown/report-years-dropdown.component';
import { LoaderService } from '@shared/components/loader/loader.service';
import { convertToCsv } from '@shared/functions/functions';
import {CurrencyNumberPipe} from "@shared/pipes/currency-number.pipe";
import {PageInfoCardComponent} from "@ui/page-info-card/page-info-card.component";

@Component({
  selector: 'atlas-monthly-report-table',
  standalone: true,
  imports: [
    TableComponent,
    PageComponent,
    ButtonComponent,
    CalendarComponent,
    SourcesMultiselectComponent,
    ReportYearsDropdownComponent,
    CurrencyNumberPipe,
    PageInfoCardComponent,
  ],
  templateUrl: './monthly-report-table.component.html',
  styleUrl: './monthly-report-table.component.scss',
})
export class MonthlyReportTableComponent implements OnInit {
  private _subs: Subscription = new Subscription();

  tableConfig: TableConfig;
  paginatedData: IPaginationResponseOfGetMonthlyReportResponse;
  activeIndex: number | undefined;
  yearsOptions: number[] = [];
  exportData: GetMonthlyReportResponse[] = [];
  monthlyPackagesStats: PackageReportStatsResponse | undefined;

  createdSinceCtrl: FormControl = new FormControl(null);
  createdToCtrl: FormControl = new FormControl(null);
  selectedSorcesCtrl: FormArray = new FormArray([]);
  yearCtrl: FormControl = new FormControl(null);

  constructor(
    private _tableService: TableServiceLocal,
    private _client: ReportsClient,
    private _loaderService: LoaderService,
  ) {
    this.tableConfig = new TableConfig({
      columns: [],
    });

    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
  }

  ngOnInit() {
    this.setTableConfig().then();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez trenutnog izveštaja',
        description: 'Ovdeće biti prikazana tabela trenutnog izveštaja',
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.load();
      },
      selectMode: SelectMode.MULTI,
      columns: [
        {
          header: 'Mesec',
          field: 'month',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
        },
        {
          header: 'Ukupno prodato',
          field: 'orderCount',
          type: 'number',
        },
        {
          header: 'Ukupna prodaja',
          field: 'orderSum',
          type: 'currency',
          styleClass: 'text-black-alpha-90 font-semibold',
        },
        {
          header: 'Izvor',
          field: 'source',
          type: 'text',
        },
      ],
    });
  }

  load() {
    this._tableService.setIsLoading();
    this._getStatistics().then((res) => {
      if (res.status === 200) {
        this.monthlyPackagesStats = res.result;
      }
    });
    this._getPackages().then((res) => {
      this.paginatedData = res.result;
      this._tableService.resetIsLoading();
    });
  }

  private async _getPackages() {
    return await firstValueFrom(
      this._client.getMonthlyPackagesReport(
        new GetMonthlyPackageReportQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize,
          filter: new GlobalPackagesReportFilter({
            createdFrom: this.createdSinceCtrl.value,
            cratedTo: this.createdToCtrl.value,
            sources: this.selectedSorcesCtrl.value,
            year: this.yearCtrl.value,
          }),
        }),
      ),
    );
  }

  handleExport() {
    this._loaderService.setLoadingText = 'Preuzimanje podataka...';
    this._loaderService.setShowLoader = true;
    this._loadExportData().then(() => {
      this._loaderService.reset();

      const customData = this.exportData.map((item) => {
        return {
          mesec: item.month,
          ukupnoProdato: item.orderCount,
          ukupnaProdaja: item.orderSum,
          izvor: item.source,
        };
      });

      const csvContent = convertToCsv(
        customData,
        'Mesec,Ukupno prodato,Ukupna prodaja,Izvor',
      );
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = 'Mesečni izveštaj prodaje.csv';
      link.click();
    });
  }

  private _loadExportData(): Promise<void> {
    return new Promise((resolve) => {
      this._subs.add(
        this._client
          .getMonthlyReportExportData(
            new GetMonthlyPackageReportQuery({
              filter: new GlobalPackagesReportFilter({
                createdFrom: this.createdSinceCtrl.value,
                cratedTo: this.createdToCtrl.value,
                sources: this.selectedSorcesCtrl.value,
                year: this.yearCtrl.value,
              }),
            }),
          )
          .subscribe((res) => {
            this.exportData = res.result;
            resolve();
          }),
      );
    });
  }

  private async _getStatistics() {
    return await firstValueFrom(
      this._client.getMonthlyPackagesStatistics(
        new GetMonthlyPackagesStatisticsQuery({
          filter: new GlobalPackagesReportFilter({
            createdFrom: this.createdSinceCtrl.value,
            cratedTo: this.createdToCtrl.value,
            sources: this.selectedSorcesCtrl.value,
          }),
        }),
      ),
    );
  }

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;
}
