import { NgOptimizedImage } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'atlas-description-tab-sidebar',
  standalone: true,
  imports: [NgOptimizedImage],
  templateUrl: './description-tab-sidebar.component.html',
  styleUrl: './description-tab-sidebar.component.scss'
})
export class DescriptionTabSidebarComponent implements OnInit {

  @Input() previewData: any;


  ngOnInit(): void {
  }

}
