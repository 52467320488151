import { JsonPipe, NgFor } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChipModule } from 'primeng/chip';
import { Subscription } from 'rxjs';
import { ActiveFiltersService } from '../active-filters-service';

@Component({
  selector: 'atlas-active-filters',
  standalone: true,
  imports: [ChipModule, JsonPipe, NgFor],
  templateUrl: './active-filters.component.html',
  styleUrl: './active-filters.component.scss'
})
export class ActiveFiltersComponent implements OnInit, OnDestroy {
  
  activeFilters: { key: string; label: string }[] = [];
  private _subs: Subscription = new Subscription();

  constructor(private _activeFiltersService: ActiveFiltersService) {}

  ngOnInit() {
    this._subs.add(
      this._activeFiltersService.activeFilters$.subscribe((filters) => {
        this.activeFilters = filters;
      })
    );
  }

  removeFilter(key: string) {
    this._activeFiltersService.removeFilter(key);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
