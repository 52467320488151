<div class="assistance-box" [ngClass]="isAvailable ? 'available' : 'used'">
  <div class="assistance-info">
    <span class="service-name">{{ serviceName }}</span>
    <span class="status-badge" [ngClass]="isAvailable ? 'status-available' : 'status-used'">
      <img *ngIf="isAvailable" ngSrc="assets/images/check.svg" width="12" height="12" alt="check-icon" />
      {{ isAvailable ? 'Dostupna' : 'Nedostupna' }}
    </span>
  </div>
  <div *ngIf="usedDate" class="service-date">
    <i class="pi pi-calendar"></i> {{ usedDate | atlasDate  }}
  </div>
  <div *ngIf="usedDate" class="details-assistance" (click)="openSidebar()">
    Detalji asistencije >
  </div>
</div>
<atlas-assistance-sidebar *ngIf="showSidebar"></atlas-assistance-sidebar>