<atlas-page
  pageTitle="Paketi vozila"
  btnIcon="pi pi-plus"
  pageDescription="Lista svih paketa izabranog vozila"
  actionLabel="Dodaj paket"
  (onHandleAction)="handleAddPackage()"
>
<div class="details-container">
    <div class="details-item">
        <span class="details-title">Svi paketi</span>
        <span class="details-value">{{ totalPackages }}</span>
    </div>
    <div class="details-item">
        <span class="details-title">Aktivni</span>
        <span class="details-value">{{ activePackages }}</span>
    </div>
    <div class="details-item">
        <span class="details-title">Iskorišćeni</span>
        <span class="details-value">{{ usedPackages }}</span>
    </div>
    <div class="details-item">
        <span class="details-title">Istekli</span>
        <span class="details-value">{{ expiredPackages }}</span>
    </div>
</div>
<atlas-table
    [paginatedData]="paginatedData"
    [tableConfig]="tableConfig"
  ></atlas-table>
</atlas-page>
<atlas-package-detail-sidebar></atlas-package-detail-sidebar>