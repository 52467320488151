import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageComponent } from '@shared/components/page/page.component';
import { ScrollablePageComponent } from '@shared/components/scrollable-page/scrollable-page.component';
import { ClientClient } from '@shared/model/atlas.api';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { ActionMode, SelectMode, TableConfig } from '@ui/table/table-common';
import { TableComponent } from '@ui/table/table.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableLazyLoadEvent } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { Subscription } from 'rxjs';
import { PackageTabClientComponent } from "./package-tab-client/package-tab-client.component";

@Component({
  selector: 'atlas-client-info',
  standalone: true,
  imports: [PageComponent,
    OverlayPanelModule,
    TabViewModule,
    ButtonComponent,
    TableComponent, PackageTabClientComponent],
  templateUrl: './client-info.component.html',
  styleUrl: './client-info.component.scss'
})
export class ClientInfoComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  clientName: string;

  public activeIndex: number | undefined;
  protected active: boolean | undefined;


  constructor(
    private route: ActivatedRoute
  ) {
    this.clientName = decodeURIComponent(this.route.snapshot.paramMap.get('name') || '');
  }  


  ngOnInit(): void {
  }


  onEllipsisClick(event: Event, overlayPanel: any) {
    overlayPanel.toggle(event);
  }

  handleTabChange(event: number) {
    if (event === 0) {
      this.active = undefined;
    } else this.active = event == 1;

  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
