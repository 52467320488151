export function addDays(date: Date, days: number): Date {
  date.setDate(date.getDate() + days);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
}

export function addHours(date: Date, hours: number): Date {
  date.setHours(date.getHours() + hours);
  return date;
}

export const LicencePlateRegExp: RegExp = /^[a-zA-Z0-9]*$/;
