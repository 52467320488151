<input
  type="file"
  class="file-input"
  accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  (change)="onFileSelected($event)"
  multiple
  #fileUpload
/>
<atlas-button
              btnIcon="pi pi-plus"
              class="add-leads"
              btnLabel="Dodaj leadove"
              [btnStyle]="ButtonStyle.BASIC"
      [btnSeverity]="ButtonSeverity.PRIMARY"
      (btnClicked)="fileUpload.click()"
            ></atlas-button>

