import { NgOptimizedImage, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageComponent } from '@shared/components/page/page.component';
import { CampaignLeadStatus, CampaignsClient, IPaginationResponseOfRecommendationResponse, LeadResponse, RecommendationsClient } from '@shared/model/atlas.api';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { TabViewModule } from 'primeng/tabview';
import { firstValueFrom, Subscription } from 'rxjs';
import { DialogService } from '@ui/dialog/dialog.service';
import { CampaignFormService } from '@app/cc-leader/services/campaign-form.service';
import { TableComponent } from '@ui/table/table.component';
import { TableConfig } from '@ui/table/table-common';
import { TableServiceLocal } from '@ui/table/table-service-local.service';
import { TableLazyLoadEvent } from 'primeng/table';
import { ChangeStatusComponent } from '../change-status/change-status.component';

@Component({
  selector: 'atlas-process-lead',
  standalone: true,
  imports: [PageComponent,
    NgOptimizedImage,
    ButtonComponent,
    DividerModule,
    NgIf, 
    TabViewModule, 
    BadgeModule,
  NgStyle,
TableComponent,
NgTemplateOutlet],
  templateUrl: './process-lead.component.html',
  styleUrl: './process-lead.component.scss'
})
export class ProcessLeadComponent implements OnInit, OnDestroy {

  private _subs: Subscription = new Subscription();
  protected readonly ButtonStyle = ButtonStyle;
  campaignId: string;
  protected readonly ButtonSeverity = ButtonSeverity;
  isExpanded = false;
  previewData: any;
  label:string = 'Promeni status'
  isStatusChanged = false;
  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL_MEDIUM);
  paginatedData: IPaginationResponseOfRecommendationResponse;
  tableConfig: TableConfig;

  constructor(
    private _camapaignClient: CampaignsClient,
    private recommendationsClient: RecommendationsClient,
    private route: ActivatedRoute,
    private _dialogService: DialogService,
    private service: CampaignFormService,
    private _tableService: TableServiceLocal
  ) {
      this._subs = new Subscription();
      this.paginatedData = {
        data: [],
        currentPage: 0,
      }
    }
 
  ngOnInit(): void {
    this.isStatusChanged = false;
    this.campaignId = this.route.snapshot.paramMap.get('id')!;
    this._getPreviewData();

    this.setTableConfig().then();
    this.load();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.load();
      },
      columns: [
        {
          field: 'fullName',
          header: 'Kontakt',
          type: 'text',
        },
        {
          field: 'contact',
          header: 'Kontakt',
          type: 'text'
        },
        {
          field: 'agent',
          subField: 'name', 
          header: 'Agent',
          type: 'text',
        },
      ],
      emptyTableInfo: {
        header: 'Bez preporuka',
        description: 'Ovde će biti prikazana tabela preporuka',
        btnLabel: 'Dodaj preporuku',
        action: () => this.addRecommendation(),
      },
    })
  }

  toggleDetails(): void {
    this.isExpanded = !this.isExpanded;
  }

  addRecommendation(){
    alert('ToDo')
  }

  getStatusColor(status: number): string {
    return this.service.getColor(status);
  }

  changeStatus(){
    this.dialogConfig.header = 'Promena statusa';
    this.dialogConfig.customSubmitButton = {
      label: 'Sačuvaj',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
      style: 'max-width: 100px',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;
    this.dialogConfig.data = {
      id: this.campaignId,
      currentStatus: this.previewData.status
    };
    const dialogRef = this._dialogService.open(
      ChangeStatusComponent,
      this.dialogConfig,
    );
    dialogRef.onClose.subscribe((result) => {
      if (result?.success) {
        this.isStatusChanged = true;
        if (this.previewData?.statusBadge) {
          this.previewData.statusBadge.value = result.newStatus.value;
          this.previewData.statusBadge.label = result.newStatus.label;
          this.isExpanded = true;
          this.label = 'Promenjen status';
        }
      }
    });
  }
  

     _getPreviewData() {
      this._subs.add(
        this._camapaignClient.getLead(this.campaignId).subscribe((res) => {  
          this.previewData = res.result;
        }),
      );
    }

    private async _getList() {
      return await firstValueFrom(
        this._camapaignClient.getLeadRecommendations(
          this.campaignId, 
          undefined,       
          undefined,       
          this.paginatedData.currentPage, 
          this.paginatedData.pageSize ?? 10 
        )
      )
    }

    load() {
      this._tableService.setIsLoading();
      this._getList().then((res) => {
        this.paginatedData = res.result;
        this._tableService.resetIsLoading();
      })
    }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
