<atlas-page pageTitle="Podešavanje">
  <div>Verzija: {{ environment.version }}</div>
  <div class="mt-3">
    <p-button class="sign-out" (onClick)="handleLogOut()">
      <i class="pi pi-sign-out mr-2"></i> Odjava
    </p-button>
  </div>
  <div class="mt-3" *ngIf="userService.isAdmin()">
      <atlas-button
          btnLabel="Sinhronizacija sertifikata"
          (btnClicked)="handleSyncSertificates()"
          btnIcon="pi pi-sync"
        [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
      ></atlas-button>
  </div>
</atlas-page>
