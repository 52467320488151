import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrderTableFilterService } from '@app/finance-administrator/services/order-table-filter.service';
import { ChipModule } from 'primeng/chip';
import { JsonPipe } from '@angular/common';
import { AtlasDatePipe } from '@shared/pipes/date.pipe';
import { PaymentStatus, PaymentType } from '@shared/model/atlas.api';
import {typedKeys} from "@shared/functions/functions";

@Component({
  selector: 'atlas-active-filters',
  standalone: true,
  imports: [ChipModule, JsonPipe],
  templateUrl: './active-filters.component.html',
  styleUrl: './active-filters.component.scss',
  providers: [AtlasDatePipe],
})
export class ActiveFiltersComponent implements OnInit, OnDestroy {
  private _subs: Subscription;

  activeFilters: { key: string; label: string }[];

  constructor(
    private service: OrderTableFilterService,
    private _datePipe: AtlasDatePipe,
  ) {
    this._subs = new Subscription();
    this.activeFilters = [];
  }

  ngOnInit() {
    this._subs.add(
      this.service.getApply.subscribe((filters) => {
        this.activeFilters = [];
        const formValue = this.service.getForm().value;
        typedKeys(formValue).forEach((key) => {
          if (
            !formValue[key] ||
            (Array.isArray(formValue[key]) &&
              (formValue[key] as Array<any>).length === 0)
          ) {
            return;
          }
          if ((key === 'paymentStatuses' && this.service.getForm().value[key] == PaymentStatus.EMPTY) ||
              (key === 'paymentTypes' && this.service.getForm().value[key] == PaymentType.EMPTY)) {
            return;
          }
          this.activeFilters.push({
            key: key,
            label: `${this.getLabel(key)}: ${this.getValue(key)}`,
          });
        });
      }),
    );

    this._subs.add(
      this.service.getReset.subscribe((filters) => {
        this.activeFilters = [];
      }),
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  handleChipRemove(idx: number) {
    this.service.clearFilter(this.activeFilters[idx].key);
    this.service.reloadTable();
  }

  private getLabel(propertyName: string): string {
    const propSwitch = typedKeys(this.service.getForm().value).find(
      (prop) => prop === propertyName,
    );
    switch (propertyName) {
      case 'client':
        return 'Klijent';
      case 'startFrom':
        return 'Datum aktivacije od';
      case 'start':
        return 'Datum aktivacije';
      case 'websiteNumber':
        return 'Broj narudžbenice';
      case 'phone':
        return 'Telefon';
      case 'promoCode':
        return 'Promo kod';
      case 'createdOn':
        return 'Datum kupovine';
      case 'createdOnFrom':
        return 'Datum kupovine od';
      case 'licencePlate':
        return 'Tablice';
      case 'paymentStatuses':
        return 'Statusi uplate';
      case 'paymentTypes':
        return 'Tipovi uplate';
      case 'sources':
        return 'Izvori';
      case 'mediums':
        return 'Medijumi';
      default:
        return 'Filter';
    }
  }

  private getValue(propertyName: string): string {
    const propSwitch = typedKeys(this.service.getForm().value).find(
      (prop) => prop === propertyName,
    );
    switch (propSwitch) {
      case 'startFrom':
      case 'start':
      case 'createdOn':
      case 'createdOnFrom':
        return this._datePipe.transform(
          this.service.getForm().value[propertyName],
        );
      case 'paymentStatuses':
        const paymentStatuses = this.service
          .getForm()
          .value[propertyName].map((paymentStatus: PaymentStatus) => {
            return this.service.paymentStatuses.find(
              (x) => x.status === paymentStatus,
            ).label;
          });
        return paymentStatuses.join(', ');
      case 'paymentTypes':
        const paymentTypes = this.service
          .getForm()
          .value[propertyName].map((paymentType: PaymentType) => {
            return this.service.paymentTypes.find((x) => x.type === paymentType)
              .label;
          });
        return paymentTypes.join(', ');
      case 'sources':
        const sourceNames = this.service
          .getForm()
          .value[propertyName].map((sourceId: string) => {
            return this.service.sources.find((m) => m.id === sourceId).name;
          });
        return sourceNames.join(', ');
      case 'mediums':
        const mediumNames = this.service
          .getForm()
          .value[propertyName].map((mediumId: string) => {
            return this.service.mediums.find((m) => m.id === mediumId)
              .mediumName;
          });
        return mediumNames.join(', ');
      default:
        return this.service.getForm().value[propertyName];
    }
  }


}
