import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { firstValueFrom, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import {
  IVehicleTypeItem,
  VehiclesClient,
  VehicleTypeItem,
} from '@shared/model/atlas.api';
import { AssistanceVehilceForm } from '@app/sos-agent/assistances/assistance-form/services/assistance-vehilce-form.service';
import { NewVehicleService } from '@app/features/services/new-vehicle-service';

@Component({
  selector: 'atlas-vehicle-type-dropdown',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './vehicle-type-dropdown.component.html',
  styleUrl: './vehicle-type-dropdown.component.scss',
})
export class VehicleTypeDropdownComponent {
  searchOptions: any[] = [];

  private _subs: Subscription;
  @Input() typeCtrl: FormControl<string>;
  @Input() ctrlStyleClass: string = 'w-full';

  types: IVehicleTypeItem[] | undefined;
  clientId: string | undefined = undefined;

  @Output() public onTypeChanged = new EventEmitter<string>();

  constructor(
    private _client: VehiclesClient,
    private _newVehicleService: NewVehicleService,
  ) {
    this._subs = new Subscription();
  }

  ngOnInit() {
    this._load();
  }

  private _load() {
    this._getTypes().then((res) => {
      this.types = [...res.result.responseList];

      this.searchOptions = this.types!.map((v) => ({
        label: v.name,
        value: v.typeId,
      }));
      this.typeCtrl.patchValue(
        this.types.find((x) => x.name.includes('Put')).typeId,
      );
    });
  }

  private async _getTypes() {
    return await firstValueFrom(this._client.getTypes());
  }

  handleTypeChange($event: any) {
    var vehicleType = this.types.find((x) => x.typeId == $event.value);

    if (vehicleType.name.includes('Mot')) {
      this._newVehicleService.setShowMotorModelInput(true);
    }

    this.onTypeChanged.emit($event.value);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
