import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ClientClient, UpdateSellerIdCommand } from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { SelectButtonModule } from 'primeng/selectbutton';
import { finalize, Subscription } from 'rxjs';

@Component({
  selector: 'atlas-set-seller-id',
  standalone: true,
  imports: [FormsModule, SelectButtonModule],
  templateUrl: './set-seller-id.component.html',
  styleUrl: './set-seller-id.component.scss'
})
export class SetSellerIdComponent {
  private _subs: Subscription = new Subscription();
  
  stateOptions: any[] = [
    { label: 'Da', value: true },
    { label: 'Ne', value: false }
  ];
  setSellerId: boolean;

  clientPackageId: string;

  constructor(
    private _dialogConfig: DialogConfig,
    private _dialogRef: DialogRef,
    private _clientClient: ClientClient,
    private _alertService: AlertService,
  ) {
    this.setSellerId = this._dialogConfig.data.setSellerId;
    this.clientPackageId = this._dialogConfig.data.clientPackageId;
  }

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleSubmit();
      }),
    );
  }

  handleSubmit() {
    this._dialogConfig.styleClass = 'display: hidden';

    this._subs.add(
      this._clientClient
        .updateSellerId(
          new UpdateSellerIdCommand({
            clientPackageId: this.clientPackageId,
            setSellerId: this.setSellerId
          }),
        )
        .pipe(
          finalize(() => {
            this._dialogConfig.styleClass = 'display: block';
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg((res.result as any).value);
          this._dialogRef.close(this.setSellerId);
        }),
    );
  }
}
