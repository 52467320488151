import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import {
  AgentsClient,
  ClientPackageTableFilter, GetMediumsQuery,
  IAgentBaseInfoResponse,
  IClientPackageTableFilter,
  IMediumItem,
  InitClient,
  IPaymentStatusDropdownItem,
  IPaymentTypeDropdownItem,
  ISourceItem,
  MediumsClient,
  PaymentStatus,
  PaymentType,
  SourcesClient,
} from '@shared/model/atlas.api';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { typedKeys } from "@shared/functions/functions";
import { SelectItem } from "primeng/api";

@Injectable({
  providedIn: 'root',
})
export class OrderTableFilterService {
  private form: FilterForm;
  private propNames: (keyof IClientPackageTableFilter)[];
  mediums: IMediumItem[] = [];
  sources: ISourceItem[] = [];
  agents: IAgentBaseInfoResponse[] = [];
  paymentTypes: IPaymentTypeDropdownItem[] = [];
  paymentStatuses: IPaymentStatusDropdownItem[] = [];
  mainDropdownOptions: SelectItem[];
  mainDropdownCtrl: FormControl;
  mainSearchCtrl: FormControl;

  constructor(
    private _fb: FormBuilder,
    private _mediumsClient: MediumsClient,
    private _sourcesClient: SourcesClient,
    private _initClient: InitClient,
    private _agentClient: AgentsClient
  ) {
    this._getMediums().then((x) => {
      this.mediums = x.result.data;
    });
    this._getSources().then((x) => {
      this.sources = x.result.responseList;
    });
    this._getAgents().then((x) => {
      this.agents = x.result.data;
    })
    this._getPaymentStatuses().then((x) => {
      this.paymentStatuses = x.result.items;
    });
    this._getPaymentTypes().then((x) => {
      this.paymentTypes = x.result.items;
    });
    this.mainDropdownOptions = [
      {
        label: SearchOptions.NUMBER,
        value: SearchOptions.NUMBER,
      },
      {
        label: SearchOptions.CLIENT,
        value: SearchOptions.CLIENT,
      },
      {
        label: SearchOptions.PHONE,
        value: SearchOptions.PHONE,
      },
      {
        label: SearchOptions.LICENCE_PLATE,
        value: SearchOptions.LICENCE_PLATE,
      },
    ];
    this.mainDropdownCtrl = new FormControl<SearchOptions>(
      SearchOptions.NUMBER,
    );
    this.mainSearchCtrl = new FormControl<string>('');
  }

  getForm(model?: IClientPackageTableFilter): FilterForm {
    if (!this.form) {
      this.form = this._fb.group<ControlsOf<IClientPackageTableFilter>>({
        client: this._fb.control(model?.client ?? ''),
        startFrom: this._fb.control(model?.startFrom ?? null),
        start: this._fb.control<Date>(model?.start ?? null),
        websiteNumber: this._fb.control(model?.websiteNumber ?? ''),
        phone: this._fb.control(model?.phone ?? ''),
        promoCode: this._fb.control(model?.promoCode ?? ''),
        createdOn: this._fb.control<Date>(model?.createdOn ?? null),
        createdOnFrom: this._fb.control<Date>(model?.createdOnFrom ?? null),
        createdOnTo: this._fb.control<Date>(model?.createdOnTo ?? null),
        licencePlate: this._fb.control(model?.licencePlate ?? ''),
        paymentStatuses: this._fb.array(model?.paymentStatuses ?? [PaymentStatus.EMPTY]),
        paymentTypes: this._fb.array(model?.paymentTypes ?? [PaymentType.EMPTY]),
        sources: this._fb.array(model?.sources ?? []),
        mediums: this._fb.array(model?.mediums ?? []),
        agents: this._fb.array(model?.agents ?? []),
        isActive: this._fb.control(model?.isActive ?? null),
        displayDrafts: this._fb.control(model?.displayDrafts ?? null),
        contractNumber: this._fb.control(model?.contractNumber ?? ''),
      }) as FormGroup;

      this.propNames = typedKeys(this.form.value);
    }
    return this.form;
  }

  // ---
  private _apply$ = new BehaviorSubject<null>(null);

  get getApply() {
    return this._apply$.asObservable();
  }

  apply() {
    this._apply$.next(null);
  }

  // ---
  private _reset$ = new BehaviorSubject<null>(null);

  get getReset() {
    return this._reset$.asObservable();
  }

  reset() {
    this._reset$.next(null);
    this._reloadTable.next(false);
    this.clearFilters();
  }

  // ---
  private _reloadTable = new BehaviorSubject<boolean>(false);
  get getReloadTable() {
    return this._reloadTable.asObservable();
  }

  reloadTable() {
    this._reloadTable.next(true);
  }

  //#region get filter options
  private async _getMediums() {
    return await firstValueFrom(this._mediumsClient.getMediums(new GetMediumsQuery({
      pageNumber: 0,
      pageSize: 100
    })));
  }

  private async _getSources() {
    return await firstValueFrom(this._sourcesClient.getSources());
  }

  private async _getAgents() {
    return await firstValueFrom(this._agentClient.getAgents());
  }

  private async _getPaymentTypes() {
    return await firstValueFrom(this._initClient.getPaymentTypeDropdown());
  }

  private async _getPaymentStatuses() {
    return await firstValueFrom(this._initClient.getPaymentStatusDropdown());
  }
  //end#region get filter options

  clearFilter(propertyName: string) {
    const propSwitch = typedKeys(this.form.value).find(
      (prop) => prop === propertyName,
    );
    switch (propSwitch) {
      case 'paymentStatuses':
        this.form.controls[propertyName].patchValue([PaymentStatus.EMPTY]);
        break;
      case 'paymentTypes':
        this.form.controls[propertyName].patchValue([PaymentType.EMPTY]);
        break;
      case 'sources':
      case 'mediums':
      case 'agents':
        const formArray = this.form.controls[propertyName] as FormArray;
        while (formArray.length) {
          formArray.removeAt(0);
        }
        formArray.updateValueAndValidity();
        break;
      case 'websiteNumber':
      case 'client':
      case 'phone':
      case 'licencePlate':
        this.form.controls[propertyName].patchValue(null);
        this.mainSearchCtrl.patchValue('');
        break;
      default:
        this.form.controls[propertyName].patchValue(null);
    }
  }

  clearFilters() {
    typedKeys(this.form.value).forEach(propName => {
      switch (propName) {
        case 'paymentStatuses':
          this.form.controls[propName].patchValue([PaymentStatus.EMPTY]);
          break;
        case 'paymentTypes':
          this.form.controls[propName].patchValue([PaymentType.EMPTY]);
          break;
        case 'sources':
        case 'mediums':
        case 'agents':
          this.form.controls[propName].clear();
          break;
        case 'websiteNumber':
        case 'client':
        case 'phone':
        case 'licencePlate':
          this.form.controls[propName].patchValue(null);
          this.mainSearchCtrl.patchValue('');
          break;
        default:
          (this.form.controls[propName] as any).patchValue(null);
      }
    })
  }

  clearPaymentStatus() {
    this.form.controls.paymentStatuses.clear();
  }

  clearPaymentType() {
    this.form.controls.paymentTypes.clear();
  }

  addPaymentStatus(paymentStatus: PaymentStatus) {
    this.form.controls.paymentStatuses.push(new FormControl(paymentStatus))
  }

  addPaymentType(paymentType: PaymentType) {
    this.form.controls.paymentTypes.push(new FormControl(paymentType))
  }

  // ---
  getOrderTableFilter(): ClientPackageTableFilter {
    if (this.propNames.length > 0) {
      const selectedOption = this.mainDropdownCtrl.value;
      const searchValue = this.mainSearchCtrl.value;

      this.form.controls.websiteNumber.reset();
      this.form.controls.client.reset();
      this.form.controls.licencePlate.reset();
      this.form.controls.phone.reset();

      if (searchValue) {
        if (selectedOption === SearchOptions.NUMBER) {
          this.form.controls.websiteNumber.patchValue(searchValue);
        } else if (selectedOption === SearchOptions.CLIENT) {
          this.form.controls.client.patchValue(searchValue);
        } else if (selectedOption === SearchOptions.LICENCE_PLATE) {
          this.form.controls.licencePlate.patchValue(searchValue);
        } else {
          this.form.controls.phone.patchValue(searchValue);
        }
      }
    }

    return new ClientPackageTableFilter(this.form.value);
  }
}

export declare type FilterForm = FormGroup<ControlsOf<IClientPackageTableFilter>>;

enum SearchOptions {
  NUMBER = 'Broj narudžbenice',
  CLIENT = 'Klijent',
  LICENCE_PLATE = 'Tablice',
  PHONE = 'Telefon',
}
