import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControlsOf, getLocationForm } from '@shared/forms/forms-common';
import {
  IClientProfileResponse,
  IClientWithPackagesItem,
  IClientWithPackagesTableResponse,
  ICommentItem,
  IUpdateClientCommand,
  PhoneItem,
} from '@shared/model/atlas.api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientPackageTableService {
  showPreviewSidebar$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showPreviewPackageSidebar$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private showBackArrow$ = new BehaviorSubject<boolean>(false); 
  showFilterSidebar$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private selectedCpsSubject = new BehaviorSubject<
    IClientWithPackagesTableResponse[]
  >([]);
  private _selectedPackages: BehaviorSubject<IClientWithPackagesItem[]> =
    new BehaviorSubject([]);
  private _clientId: string | null = null;
  private readonly _addForm: AddCommentForm;
  private _editForm: EditClientForm;

  addSelectedPackage(model: IClientWithPackagesItem): void {
    this._selectedPackages.next([...this._selectedPackages.value, model]);
  }

  reset() {
    this._selectedPackages.next([]);
    this.selectedCpsSubject.next([]);
    this._addForm.reset();
    this._editForm = undefined;
  }

  getEditClientForm(model: IClientProfileResponse) {
      if (!this._editForm) {
        this._editForm = this._initEditForm(model);
      }
      return this._editForm;
    }

    private _initEditForm(model: IClientProfileResponse) {
        return this._fb.group<ControlsOf<IUpdateClientCommand>>({
          clientId: this._fb.control(model.id, {
            nonNullable: true,
            validators: [Validators.required],
          }),
          name: this._fb.control(model.firstName, {
            nonNullable: true,
            validators: [Validators.required],
          }),
          lastName: this._fb.control(model.lastName, {
            nonNullable: true,
            validators: [Validators.required],
          }),
          towingLocation: getLocationForm(model?.towingAddress) as FormGroup,
          phones: this._fb.array<FormGroup<ControlsOf<PhoneItem>>>([]),
          companyAddress: this._fb.control(model.companyAddress, {
            nonNullable: true,
          }),
          companyName: this._fb.control(model.companyName, {
            nonNullable: true,
          }),
          nationalNumber:this._fb.control(model.nationalNumber, {
            nonNullable: true,
          }),
          taxId: this._fb.control(model.taxId, {
            nonNullable: true,
          }),
          isActive: this._fb.control(model.isActive, {
            nonNullable: true,
          }),
        });
      }

  initSelectedPackages(model: IClientWithPackagesItem[]): void {
    this._selectedPackages.next([...model]);
  }

  removeSelectedPackage(model: IClientWithPackagesItem): void {
    this._selectedPackages.next([
      ...this._selectedPackages.value.filter(
        (p) => p.clientPackageId !== model.clientPackageId,
      ),
    ]);
  }

  getSelectedPackages(): Observable<IClientWithPackagesItem[]> {
    return this._selectedPackages.asObservable();
  }

  getSelectedPackagesCount() {
    return this._selectedPackages.value.length;
  }

  constructor(private _fb: FormBuilder) {
    this._addForm = this._addComment();
  }

  getAddCommentForm() {
    if (!this._addForm) {
      this._addComment();
    }
    return this._addForm;
  }

  showFilterSidebar() {
    this.showFilterSidebar$.next(true);
  }

  hideFilterSidebar() {
    this.showFilterSidebar$.next(false);
  }

  get getShowFiltersSidebar() {
    return this.showFilterSidebar$.asObservable();
  }

  private _addComment() {
    return this._fb.group<ControlsOf<ICommentItem>>({
      text: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  showPreviewSidebar(itemId: string, showBackArrow: boolean = false) {
    this.setSidebarId = itemId;
    this.showBackArrow$.next(showBackArrow);
    this.showPreviewSidebar$.next(true);
  }

  getShowBackArrow(): Observable<boolean> {
    return this.showBackArrow$.asObservable(); 
}

  hidePreviewSidebar() {
    this.showPreviewSidebar$.next(false);
  }

  showPreviewPackageSidebar(itemId: string) {
    this.setSidebarPackageId = itemId;
    this.showPreviewPackageSidebar$.next(true);
  }

  hidePreviewPackageSidebar() {
    this.showPreviewPackageSidebar$.next(false);
  }

  set clientId(id: string) {
    this._clientId = id;
  }

  get clientId(): string | null {
    return this._clientId;
  }

  addClientPackage(client: IClientWithPackagesTableResponse): void {
    const currentSelection = this.selectedCpsSubject.value;
    if (!currentSelection.some((c) => c.id === client.id)) {
      this.selectedCpsSubject.next([...currentSelection, client]);
    }
  }

  // Remove client from the selection
  removeClientPackage(client: IClientWithPackagesTableResponse): void {
    const currentSelection = this.selectedCpsSubject.value.filter(
      (c) => c.id !== client.id,
    );
    this.selectedCpsSubject.next(currentSelection);
  }

  // Get current selection (can be useful for synchronous operations)
  getSelectedCps(): IClientWithPackagesTableResponse[] {
    return this.selectedCpsSubject.value;
  }

  getSelectedCpsCount(): number {
    return this.selectedCpsSubject.value.length;
  }

  resetSelectedCps() {
    this.selectedCpsSubject.next([]);
  }

  get getShowPreviewSidebar() {
    return this.showPreviewSidebar$.asObservable();
  }

  get getShowPreviewPackageSidebar() {
    return this.showPreviewPackageSidebar$.asObservable();
  }

  private _sidebarItemId: string | undefined;
  private _sidebarPackageItemId: string | undefined;

  set setSidebarId(id: string) {
    this._sidebarItemId = id;
  }

  set setSidebarPackageId(id: string) {
    this._sidebarPackageItemId = id;
  }

  get getSidebarId(): string {
    return this._sidebarItemId;
  }

  get getSidebarPackageId(): string {
    return this._sidebarPackageItemId;
  }

}


export declare type AddCommentForm = FormGroup<ControlsOf<ICommentItem>>;
export declare type EditClientForm = FormGroup<
  ControlsOf<IUpdateClientCommand>
>;
