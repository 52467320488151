import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  InitClient,
  IPaymentStatusDropdownItem,
  PaymentStatus,
} from '@shared/model/atlas.api';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { UserService } from '@shared/services/user.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { MultiselectComponent } from '@ui/multiselect/multiselect.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';

@Component({
  selector: 'atlas-payment-status-multiselect',
  standalone: true,
  imports: [MultiselectComponent, DropdownComponent],
  templateUrl: './payment-status-multiselect.component.html',
  styleUrl: './payment-status-multiselect.component.scss',
})
export class PaymentStatusMultiselectComponent implements OnInit, OnDestroy {
  private _subs: Subscription;

  statusOptions: IPaymentStatusDropdownItem[] = [];

  formArray: FormArray<FormGroup<PaymentStatus>>;

  formGroup: FormGroup<PaymentStatusForm>;

  @Input() statusesCtrl: FormArray;

  constructor(
    private _client: InitClient,
    private _userService: UserService,
  ) {
    this.formArray = new FormArray<FormGroup<PaymentStatus>>([]);
    this.formGroup = new FormGroup({
      paymentStatuses: new FormControl([]),
    });
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this._load();

    this._subs.add(
      this.formGroup.valueChanges.subscribe((value) => {
        this.statusesCtrl.clear();
        value.paymentStatuses.forEach((x) => {
          this.statusesCtrl.push(new FormControl(x.status));
        });
      }),
    );
  }

  private _load() {
    this._getStatuses().then((res) => {
      if (this._userService.isFinanceAdmin()) {
        this.statusOptions = res.result.items.filter((item) => {
          return (
            item.status === PaymentStatus.WC_PROCESSING ||
            item.status === PaymentStatus.WC_ON_HOLD ||
            item.status === PaymentStatus.WC_COMPLETED ||
            item.status === PaymentStatus.WC_REFUNDED ||
            item.status === PaymentStatus.WC_CANCELLED
          );
        });
      } else {
        this.statusOptions = res.result.items;
      }
    });
  }

  private async _getStatuses() {
    return await firstValueFrom(this._client.getPaymentStatusDropdown());
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}

export type PaymentStatusForm = {
  paymentStatuses: FormControl<IPaymentStatusDropdownItem[]>;
};
