import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { finalize, Subscription } from 'rxjs';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import {
  AssistancesClient,
  SetStatusCanceledCommand,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { LoaderService } from '@shared/components/loader/loader.service';
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'atlas-cancel-assistance.dialog',
  standalone: true,
  providers: [DynamicDialogConfig],
  imports: [InputTextareaModule, FormsModule, FloatLabelModule, NgClass, NgIf],
  templateUrl: './cancel-assistance.dialog.component.html',
  styleUrl: './cancel-assistance.dialog.component.scss',
})
export class CancelAssistanceDialogComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  inputValue: string = '';
  assistanceId: string = '';
  showValidation: boolean | undefined;

  constructor(
    private _dialogConfig: DialogConfig,
    private _dialogRef: DialogRef,
    private _assistanceClient: AssistancesClient,
    private alert: AlertService,
    private _loader: LoaderService,
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleSubmit();
      }),
    );

    this.assistanceId = this._dialogConfig.data.id;
  }

  handleSubmit() {
    if (!this.inputValue) {
      this.showValidation = true;
      return;
    }

    this._loader.setShowLoader = true;
    this._dialogConfig.styleClass = 'display: hidden';

    this._subs.add(
      this._assistanceClient
        .setStatusCanceled(
          new SetStatusCanceledCommand({
            id: this.assistanceId,
            reason: this.inputValue,
          }),
        )
        .pipe(
          finalize(() => {
            this._loader.reset();
            this._dialogConfig.styleClass = 'display: block';
          }),
        )
        .subscribe((res) => {
          if (res.result.isSuccess) {
            this.alert.addSuccessMsg('Asistencija je otkazana');
            this._dialogRef.close(res.result.value);
          }
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
