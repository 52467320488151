import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MediumItem, MediumsClient } from '@shared/model/atlas.api';
import { DropdownComponent, DropdownOptions } from '@ui/dropdown/dropdown.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-mediums-dropdown',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './mediums-dropdown.component.html',
  styleUrl: './mediums-dropdown.component.scss',
})
export class MediumsDropdownComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  mediumOptions: DropdownOptions[] = [];

  @Input() mediumCtrl: any;
  @Input() ctrlStyleClass: string;

  constructor(private _mediumsClient: MediumsClient) {
  }
  
  ngOnInit(): void {
    this._subs.add(
      this._mediumsClient.getMediums().subscribe(({ result: { responseList } }) =>
        (this.mediumOptions = responseList.map(
          ({ id, mediumName }) => ({ label: mediumName, value: id })
        ))
      )
    );
  } 

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
