<div class="flex flex-column justify-content-between gap-4">
  <div class="flex flex-column gap-4">
    <div class="flex gap-2">
      <div class="header">Dodeljeni agenti</div>
      <p-badge [value]="agents.length"></p-badge>
    </div>
    <div
      *ngFor="let agent of paginatedAgents"
      class="agent-container flex align-items-center justify-content-between"
    >
      <div class="agent-info flex align-items-center space-x-2 gap-3">
        <img
          [ngSrc]="agent.imageUrl || 'assets/images/client-icon.svg'"
          class="rounded-full"
          height="36"
          width="36"
          alt="Agent Image"
        />
        <div class="text-wrap">
          <span class="font-semibold">{{ agent.name }}</span>
          <span class="text-sm text-gray-500">{{ agent.email }}</span>
        </div>
      </div>
      <span
        class="cursor-pointer warning-text"
        (click)="removeAgent(agent)"
        title="Remove Agent"
        >Ukloni</span
      >
    </div>
  </div>
  <div
    class="align-self-center flex gap-3"
    *ngIf="agents.length > pageSize"
  >
    <atlas-button
      btnIcon="pi pi-chevron-left"
      btnClass="w-full"
      (btnClicked)="previousPage()"
      [btnSeverity]="ButtonSeverity.SECONDARY"
      [btnStyle]="ButtonStyle.TEXT"
    ></atlas-button>

    <atlas-button
      btnIcon="pi pi-chevron-right"
      btnClass="w-full"
      (btnClicked)="nextPage()"
      [btnSeverity]="ButtonSeverity.SECONDARY"
      [btnStyle]="ButtonStyle.TEXT"
    ></atlas-button>
  </div>
</div>
