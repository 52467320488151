import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {ControlsOf} from '@shared/forms/forms-common';
import {CampaignPriority, FileParameter, ICreateLeadCampaignCommand,} from '@shared/model/atlas.api';

@Injectable({
  providedIn: 'root',
})
export class LeadCampaignFormService {
  private _form: LeadsForm;
  private _files: FileParameter[];

  constructor(private _fb: FormBuilder) {
    this._files = [];
  }

  getForm() {
    if (!this._form) {
      this.initForm();
    }
    return this._form;
  }

  private initForm(model?: ICreateLeadCampaignCommand) {
    this._form = this._fb.group<ControlsOf<ICreateLeadCampaignCommand>>({
      name: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      description: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.maxLength(100)],
      }),
      endsOnUtc: this._fb.control(model?.endsOnUtc),
      agentIds: this._fb.array<FormControl<string>>([]),
      priority: this._fb.control(model?.priority ?? CampaignPriority.NONE, {
        validators: Validators.minLength(CampaignPriority.NONE),
      }),
    });
  }

  addAgent(id: string) {
    this._form.controls.agentIds.push(new FormControl(id));
  }

  removeAgent(id: string) {
    const idx = this._form.value.agentIds.findIndex((x: FormControl<string>) => x.value === id);
    this._form.controls.agentIds.removeAt(idx);
  }

  setDurationLimit(value: Date | null) {
    this._form.controls.endsOnUtc.patchValue(value );
  }

  set addFile(file: FileParameter) {
    this._files.push(file);
  }

  removeFile(fileName: string) {
    const idx = this._files.findIndex((x:FileParameter) => x.fileName === fileName);
    this._files.splice(idx, 1);
  }

  get getFiles() {
    return this._files;
  }

  areAgentsValid() {
    return this._form.controls.agentIds.length > 0;
  }

  areFilesValid() {
    return this._files.length > 0;
  }

  reset() {
    this.initForm = undefined;
  }
}

export declare type LeadsForm = FormGroup<
  ControlsOf<ICreateLeadCampaignCommand>
>;
