<div class="flex flex-column">
  <atlas-inline-wrapper formCtrlId="name" ctrlLabel="Naziv kampanje">
    <atlas-input-text
      [formCtrl]="form.controls.name"
      formCtrlId="name"
      formCtrlClass="w-full"
      ctrlPlaceholder="Naziv kampanje"
      [showLabel]="false"
    ></atlas-input-text>
  </atlas-inline-wrapper>
  <p-divider></p-divider>
  <atlas-inline-wrapper formCtrlId="name" ctrlLabel="Opis kampanje">
    <div class="comment-input">
      <atlas-input-description
        [formCtrl]="form.controls.description"
        formCtrlId="Komentar"
        ctrlPlaceholder="Tvoj komentar"
        formCtrlClass="w-full"
        [showInfo]="true"
      ></atlas-input-description>
    </div>
  </atlas-inline-wrapper>
  <p-divider></p-divider>
  <atlas-inline-wrapper
    formCtrlId="condition"
    ctrlLabel="Ugovori koji ističu za"
  >
    <div class="flex align-items-end info-page-width condition-box">
      <atlas-input-text
        [hideErrors]="true"
        formCtrlClass=""
        ctrlPlaceholder="Unesite vrednost"
        [formCtrl]="form.controls.conditionValue | formControl"
      ></atlas-input-text>
      <atlas-duration-type-dropdown
        [typeCtrl]="form.controls.conditionPeriod | formControl"
        [hideErrors]="true"
      ></atlas-duration-type-dropdown>
    </div>
  </atlas-inline-wrapper>
  <atlas-inline-wrapper formCtrlId="" ctrlLabel="">
    <div class="info-page-width">
      <p-messages severity="info">
        <ng-template pTemplate>
          <img
            ngSrc="/assets/images/box-icon.svg"
            width="32"
            height="32"
            alt="info-image"
          />
          <div class="ml-2">
            Svakog dana ovi ugovori će se dodavati i brisati iz kampanje na
            osnovu unešenog uslova.
          </div>
        </ng-template>
      </p-messages>
    </div>
  </atlas-inline-wrapper>
</div>
