<p-dataView
  [value]="requestData"
  [layout]="layout"
  [rows]="6"
  [paginator]="true"
>
  <ng-template let-items pTemplate="grid" let-requestData>
    <div class="flex flex-wrap gap-4 mb-3">
      <div *ngFor="let item of items" class="card-item flex-1">
        <atlas-change-request-card
          [request]="item"
          (onRequestSubmitted)="_loadTableData()"
        ></atlas-change-request-card>
      </div>
    </div>
  </ng-template>
</p-dataView>
