import { NgClass } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  ProcessVehicleUpdateRequestCommand,
  VehicleRequestTableItem,
  VehiclesClient,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { DividerModule } from 'primeng/divider';
import { finalize, Subscription } from 'rxjs';
import { HoverClassDirective } from '@shared/directives/hover-class.directive';
import { LoaderService } from '@shared/components/loader/loader.service';

@Component({
  selector: 'atlas-change-request-card',
  standalone: true,
  imports: [DividerModule, NgClass, HoverClassDirective],
  templateUrl: './change-request-card.component.html',
  styleUrl: './change-request-card.component.scss',
})
export class ChangeRequestCardComponent implements OnDestroy {
  private _subs: Subscription = new Subscription();

  @Input() request: VehicleRequestTableItem;
  @Output() onRequestSubmitted = new EventEmitter();

  constructor(
    private _vehicleClient: VehiclesClient,
    private _alertService: AlertService,
    private _loader: LoaderService,
  ) {}

  submitChangeRequest(isAccepted: boolean, requestId: string) {
    this._loader.setShowLoader = true;

    this._subs.add(
      this._vehicleClient
        .processVehicleUpdateRequest(
          new ProcessVehicleUpdateRequestCommand({
            requestId: requestId,
            isAccepted: isAccepted,
          }),
        )
        .pipe(
          finalize(() => {
            this._loader.setShowLoader = false;
          }),
        )
        .subscribe((res) => {
          if (res.status === 200) {
            this._alertService.addSuccessMsg(res.result);
            this.onRequestSubmitted.emit();
          } else {
            this._alertService.addWarnMsg(res.result);
            this.onRequestSubmitted.emit();
          }
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
