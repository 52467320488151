<atlas-page pageTitle="Izveštaj zarade">
  <div>
    <atlas-page-info-card
      cardTitle="Ukupan iznos paketa"
      [cardNumber]="amountSum ? (amountSum.toString() | currencyNumber) : ''"
      cardIcon="pi-money-bill"
      class="flex-1"
    ></atlas-page-info-card>
  </div>
  <div class="flex flex-wrap flex-column md:flex-row justify-content-between mb-3 mt-3 gap-5">
    <div class="filter-btn">
      <atlas-button
        btnIcon="pi pi-filter-slash"
        class="filters-button"
        btnLabel="Očisti filtere"
        [btnStyle]="ButtonStyle.BASIC"
        [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
        (btnClicked)="resetFilters()"
      ></atlas-button>
    </div>
    <div class="flex gap-3 flex-wrap">
      <atlas-calendar
        formCtrlId="from"
        ctrlLabel="Kreirani od"
        ctrlPlaceholder="Odaberi datum od"
        [formCtrl]="fs.getForm().controls.createdFrom"
        [hideErrors]="true"
        [ctrlShowTime]="false"
      ></atlas-calendar>
      <atlas-calendar
        formCtrlId="to"
        ctrlLabel="Kreirani do"
        ctrlPlaceholder="Odaberi datum do"
        [formCtrl]="fs.getForm().controls.createdTo"
        [hideErrors]="true"
        [ctrlShowTime]="false"
      ></atlas-calendar>
      <atlas-medium-multiselect [mediumsCtrl]="fs.getForm().controls.mediums">
      </atlas-medium-multiselect>
      <atlas-sources-multiselect
        [sourceCtrl]="fs.getForm().controls.sources"
      ></atlas-sources-multiselect>
      <atlas-agent-multiselect
        [agentsCtrl]="fs.getForm().controls.agentIds"
      ></atlas-agent-multiselect>
      <!-- Search -->
      <div class="filter-btn">
        <atlas-button
          btnIcon="pi pi-filter"
          class="filters-button"
          btnLabel="Primeni filtere"
          [btnStyle]="ButtonStyle.BASIC"
          [btnSeverity]="ButtonSeverity.SECONDARY"
          (btnClicked)="load()"
        ></atlas-button>
      </div>
    </div>
  </div>

  <atlas-table
    [paginatedData]="paginatedData"
    [tableConfig]="tableConfig"
  ></atlas-table>
</atlas-page>
