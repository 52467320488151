import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'atlas-toast-message',
  standalone: true,
  imports: [NgIf],
  templateUrl: './toast-message.component.html',
  styleUrl: './toast-message.component.scss'
})
export class ToastMessageComponent {

  @Input() agentName: string;
  @Input() packageIdsLength: number;
  showToast = true;

  closeToast() {
    this.agentName = '';
  }

  undoAction() {
    alert('ToDo: Poništeno')
  }

}
