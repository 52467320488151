<div class="ml-1" style="max-width: 700px">
  <div class="step-title">Korak 2</div>
  <div class="text-3xl font-semibold mt-1">Paket i vozilo</div>
  <div class="mt-6 title-text">Vrsta paketa</div>

  <div class="flex gap-4 box-padding mt-5">
    <atlas-icon-checkbox
      name="Paket za vozilo"
      [isSelected]="selectedOption === 'Vozilo'"
      [iconClass]="'pi pi-car'"
      (select)="selectVozilo()"
    ></atlas-icon-checkbox>
    <atlas-icon-checkbox
      name="Lični paket"
      [isSelected]="selectedOption === 'Licni'"
      [iconClass]="'pi pi-user'"
      (select)="selectLicni()"
    ></atlas-icon-checkbox>
  </div>

  <div *ngIf="selectedOption === 'Vozilo'">
    <!-- Kartice za tip vozila -->
    <div class="mt-6 title-text">Izaberi paket za</div>
    <div class="vehicle-type-container mt-5">
      <div
        class="flex wrapper"
        *ngFor="let vehicle of vehicleTypes"
        [class.selected]="selectedVehicle === vehicle?.name"
        (click)="onSelectVehicle(vehicle)"
      >
        <div class="container-checkbox">
          <div class="label ml-3">{{ vehicle?.name }}</div>
        </div>

        <div class="checkbox-div">
          <div class="checkbox ml-3">
            <p-checkbox
              [binary]="true"
              [ngModel]="selectedVehicle === vehicle.name"
              (onChange)="onSelectVehicle(vehicle)"
            ></p-checkbox>
          </div>
        </div>
      </div>
    </div>

    <atlas-package-card-list [isRenew]="false"></atlas-package-card-list>

    <div class="mt-6 title-text">Detalji vozila</div>

    <div class="Mail-Num mt-3">
      <div class="w-full lg:w-19rem">
        <div class="text-sm font-medium mb-2">Marka</div>
        <atlas-brand-autocomplete
          [typeId]="selectedVehicleType"
          (onBrandChanged)="handleBrandChange($event)"
          [brandName]="orderForm.value.vehicle.brand"
          [isDisabled]="!selectedVehicleType"
        ></atlas-brand-autocomplete>
      </div>

      <div class="w-full lg:w-19rem">
        <div class="text-sm font-medium mb-2">Model</div>
        <atlas-model-autocomplete
          *ngIf="!showModelTxtInput"
          [brandId]="vehicleBrandId"
          (onModelChanged)="handleModelChange($event)"
          [modelName]="orderForm.value.vehicle.model"
          [isDisabled]="!vehicleBrandId"
        ></atlas-model-autocomplete>

        <atlas-input-text
          *ngIf="showModelTxtInput"
          [formCtrl]="orderForm.controls.vehicle.controls.model"
          [showLabel]="false"
          ctrlPlaceholder="Model vozila"
        ></atlas-input-text>
      </div>
    </div>

    <!-- Registracija -->
    <div class="Mail-Num mt-3">
      <atlas-input-text
        [formCtrl]="orderForm.controls.vehicle.controls.licencePlate"
        ctrlLabel="Registracione oznake"
        ctrlPlaceholder="Registracione oznake"
        formCtrlClass="w-full lg:w-19rem"
      ></atlas-input-text>

      <!-- Godiste -->
      <atlas-input-text
        [formCtrl]="orderForm.controls.vehicle.controls.productionYear"
        ctrlLabel="Godište (opciono)"
        ctrlPlaceholder="Godište"
        hideErrors="true"
        formCtrlClass="w-full lg:w-19rem"
      ></atlas-input-text>
    </div>

    <!-- Boja -->
    <div class="Mail-Num mt-3">
      <atlas-input-text
        [formCtrl]="orderForm.controls.vehicle.controls.color"
        ctrlLabel="Boja vozila (opciona)"
        ctrlPlaceholder="Boja vozila"
        hideErrors="true"
        formCtrlClass="w-full lg:w-19rem"
      ></atlas-input-text>
    </div>

    <!-- Adresa -->
    <div class="mt-5 title-text">Šlepanje</div>
    <div class="mt-1 support-text">
      Adresa gde se ovo vozilo šlepa kada je u pitanju usluga asistencije
    </div>
    <div class="mt-2 mb-1">
      <atlas-input-text
        [formCtrl]="orderForm.controls.towingLocation"
        ctrlPlaceholder="Adresa šlepanja"
        formCtrlClass="w-full lg:w-19rem"
      ></atlas-input-text>
    </div>
  </div>
 
  <!-- Licni -->
  <div *ngIf="selectedOption === 'Licni'">
    <div class="mt-6 title-text">Detalji paketa</div>

    <atlas-package-card-list></atlas-package-card-list>

    <div class="mt-6 title-text mb-2">Adresa šlepanja</div>
    <atlas-input-text
      ctrlPlaceholder="Adresa"
      [formCtrl]="this.orderForm.controls.towingLocation"
      formCtrlClass="w-full lg:w-19rem"
    ></atlas-input-text>
  </div>
</div>
