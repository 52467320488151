import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { IAssistanceVehicle } from '@shared/model/atlas.api';
import { ControlsOf } from '@shared/forms/forms-common';
import { VehicleForm } from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssistanceVehilceForm {
  constructor(private _fb: FormBuilder) {}

  hideModelAutocomplete$ = new BehaviorSubject<boolean>(false);

  getHideModelAutocomplete() {
    return this.hideModelAutocomplete$.getValue();
  }

  setHideModelAutocomplete(value: boolean) {
    this.hideModelAutocomplete$.next(value);
  }

  getArray(model?: IAssistanceVehicle[]): FormArray<VehicleForm> {
    const result = this._fb.array<VehicleForm>([]);

    if (!model) {
      return result;
    }

    model.forEach((s) => {
      result.push(this.getForm(s));
    });
    return result;
  }

  getForm(model?: IAssistanceVehicle): VehicleForm {
    return this._fb.group<ControlsOf<IAssistanceVehicle>>({
      vehicleId: this._fb.control(model?.vehicleId, {
        nonNullable: true,
      }),
      licencePlate: this._fb.control(model?.licencePlate, {
        nonNullable: true,
        validators: [Validators.maxLength(16), Validators.required],
      }),
      fullName: this._fb.control(model?.fullName),
      modelId: this._fb.control(model?.modelId, {}),
      model: this._fb.control(model?.model, {
        validators: [Validators.required],
      }),
      brand: this._fb.control(model?.brand, {
        validators: [Validators.required],
      }),
    });
  }
}
