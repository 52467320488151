<div
  class="flex flex-column gap-2 w-full"
  *ngIf="!formCtrlIcon && !formCtrlIconRight"
>
  <label [for]="formCtrlId" *ngIf="showLabel">{{ ctrlLabel }} </label>
  <input
    pKeyFilter="int"
    pInputText
    pAutoFocus
    (blur)="handleBlur($event)"
    (keyup)="handleKeyUp($event)"
    [formControl]="formCtrl"
    [id]="formCtrlId"
    [type]="formCtrlType"
    [placeholder]="ctrlPlaceholder"
    autocomplete="off"
    [ngClass]="
      formCtrlIcon ? 'pl-5 w-full text-base ' + formCtrlClass : formCtrlClass
    "
    [autofocus]="shouldFocus"
  />
  <ng-container *ngIf="!hideErrors">
    <atlas-error-required [formCtrl]="formCtrl"></atlas-error-required>
    <atlas-max-length [formCtrl]="formCtrl"></atlas-max-length>
  </ng-container>
</div>
<div *ngIf="formCtrlIcon" class="flex flex-column gap-2 w-full">
  <label [for]="ctrlLabel" *ngIf="showLabel">{{ ctrlLabel }} </label>
  <div class="p-input-icon-left" *ngIf="formCtrlIcon">
    <i [class]="formCtrlIcon"></i>
    <input
      pKeyFilter="int"
      pAutoFocus
      (blur)="handleBlur($event)"
      (keyup)="handleKeyUp($event)"
      [formControl]="formCtrl"
      [id]="formCtrlId"
      [type]="formCtrlType"
      [placeholder]="ctrlPlaceholder"
      autocomplete="off"
      pInputText
      [ngClass]="formCtrlIcon ? 'pl-5 w-full' + formCtrlClass : formCtrlClass"
      [autofocus]="shouldFocus"
    />
    <ng-container *ngIf="!hideErrors">
      <atlas-error-required [formCtrl]="formCtrl"></atlas-error-required>
      <atlas-max-length [formCtrl]="formCtrl"></atlas-max-length>
    </ng-container>
  </div>
</div>
<div *ngIf="formCtrlIconRight" class="flex flex-column gap-2 w-full">
  <label [for]="ctrlLabel" *ngIf="showLabel">{{ ctrlLabel }} </label>
  <div class="p-input-icon-right" *ngIf="formCtrlIconRight">
    <i
      [class]="formCtrlIconRight"
      [ngClass]="{ 'icon-invalid': formCtrl.invalid && formCtrl.touched }"
      [pTooltip]="tooltip"
      tooltipPosition="top"
    ></i>
    <input
      pAutoFocus
      pKeyFilter="int"
      (blur)="handleBlur($event)"
      (keyup)="handleKeyUp($event)"
      [formControl]="formCtrl"
      [id]="formCtrlId"
      [type]="formCtrlType"
      [placeholder]="ctrlPlaceholder"
      autocomplete="off"
      pInputText
      [ngClass]="formCtrlIconRight ? 'w-full' + formCtrlClass : formCtrlClass"
      [autofocus]="shouldFocus"
    />
    <ng-container *ngIf="!hideErrors">
      <atlas-error-required [formCtrl]="formCtrl"></atlas-error-required>
      <atlas-max-length [formCtrl]="formCtrl"></atlas-max-length>
    </ng-container>
  </div>
</div>
