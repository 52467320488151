import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DividerModule } from 'primeng/divider';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import {
  NgClass,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet,
} from '@angular/common';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { StepsModule } from 'primeng/steps';
import { AddCampaignContractComponent } from '@app/cc-leader/campaigns/add-campaign/add-campaign-contract/add-campaign-contract.component';
import { AddCampaignLeadsComponent } from '@app/cc-leader/campaigns/add-campaign/add-campaign-leads/add-campaign-leads.component';
import { TabViewModule } from 'primeng/tabview';
import { LayoutService } from '@shared/services/layout.service';

@Component({
  selector: 'atlas-scrollable-page',
  standalone: true,
  imports: [
    ButtonComponent,
    DividerModule,
    NgIf,
    ScrollPanelModule,
    NgTemplateOutlet,
    NgClass,
    StepsModule,
    NgOptimizedImage,
    TabViewModule,
  ],
  templateUrl: './scrollable-page.component.html',
  styleUrl: './scrollable-page.component.scss',
})
export class ScrollablePageComponent implements OnInit, OnDestroy {
  @Input() submitBtnLabel: string;
  @Input() submitBtnIcon: 'add' | 'edit' | string = 'add';
  @Input() submitBtnSvgUrl: string | undefined;
  @Input() cancelBtnIcon: 'cancel' | 'back' = 'cancel';
  @Input() pageTitle: string;
  @Input() pageDescription: string | undefined;
  @Input() isGray: boolean;
  @Output() onSubmitAction = new EventEmitter();
  @Output() onCancelAction = new EventEmitter();
  @Input() showToggle: boolean = false;
  @Input() showBadge: boolean = false;
  @Input() badgeText: string;
  @Output() detailsVisibleChange = new EventEmitter<boolean>();
  @Input() showExtraBtn: boolean = false;
  @Input() extraBtnLabel: string;
  @Input() extraBtnIcon: string = 'pi pi-bookmark';
  @Input() extraBtnSeverity: ButtonSeverity = ButtonSeverity.SECONDARY_GREY;
  @Input() extraBtnStyle: ButtonStyle = ButtonStyle.OUTLINED;
  @Output() onExtraBtnClick = new EventEmitter();

  isDetailsVisible = true;
  @Input() titleNeeded: boolean = true;

  @ContentChild('content') content!: TemplateRef<ElementRef>;

  protected readonly ButtonSeverity = ButtonSeverity;

  protected readonly ButtonStyle = ButtonStyle;

  constructor(private layoutService: LayoutService) {}

  ngOnInit() {
    this.layoutService.setIsGray = this.isGray;
  }

  handleCancelForm() {
    this.onCancelAction.emit();
  }

  toggleDetails() {
    this.isDetailsVisible = !this.isDetailsVisible;
    this.detailsVisibleChange.emit(this.isDetailsVisible);
  }

  handleSubmitForm() {
    this.onSubmitAction.emit();
  }

  handleExtraBtnClick() {
    this.onExtraBtnClick.emit();
  }

  ngOnDestroy() {
    this.layoutService.setIsGray = false;
  }
}
