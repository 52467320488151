import { JsonPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  OrderForm,
  OrderFormService,
} from '@app/cc-leader/services/order-form.service';
import { PackageCardListService } from '@app/cc-leader/services/package-card-list.service';
import {
  CreateOrderInboundCommand,
  OrdersClient,
  UsersClient,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { LocationService } from '@shared/services/location.service';
import { ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SkeletonModule } from 'primeng/skeleton';
import { StepperModule } from 'primeng/stepper';
import { Steps, StepsModule } from 'primeng/steps';
import { firstValueFrom, Subscription } from 'rxjs';
import { markFormGroupTouched } from '@shared/forms/forms-common';
import { NewVehicleService } from '@app/features/services/new-vehicle-service';
import { LoaderService } from '@shared/components/loader/loader.service';
import { ScrollablePageWithStepsComponent } from '@shared/components/scrollable-page-with-steps/scrollable-page-with-steps.component';
import {
  CampaignFormService,
  LeadTransferData,
} from '@app/cc-leader/services/campaign-form.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'atlas-create-order-form',
  standalone: true,
  imports: [
    StepsModule,
    StepperModule,
    ScrollPanelModule,
    ConfirmDialogModule,
    NgIf,
    SkeletonModule,
    ScrollablePageWithStepsComponent,
    JsonPipe,
  ],
  templateUrl: './create-order-form.component.html',
  styleUrl: './create-order-form.component.scss',
})
export class CreateOrderFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  menuSteps: MenuItem[] | undefined;
  showLoader: boolean = false;
  shouldDisplayFinish: boolean = false;
  shouldDisplayBack: boolean = false;
  currentStep: number = 1;
  orderForm: OrderForm;
  emailExists: boolean;
  leadTransferData: LeadTransferData;

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  @ViewChild('steps') steps!: Steps;

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private location: LocationService,
    private activatedRoute: ActivatedRoute,
    private _orderFormService: OrderFormService,
    private _packageListService: PackageCardListService,
    private _ordersClient: OrdersClient,
    private _alertService: AlertService,
    private _newVehicleService: NewVehicleService,
    private _loaderService: LoaderService,
    private _userService: UserService,
    private _userClient: UsersClient,
    private _campaignFormService: CampaignFormService,
  ) {
    this.orderForm = this._orderFormService.getOrderForm;
  }

  ngOnInit(): void {
    this.initSteps();
    this._getLeadTransferData();

    this._subs.add(
      this._orderFormService.currentStep$.subscribe((x) => {
        this.currentStep = x;
        this.shouldDisplayBack = this.currentStep > 1;
      }),
    );
  }

  private _getLeadTransferData() {
    this._subs.add(
      this._campaignFormService.leadTransferData$.subscribe((x) => {
        if (!x) {
          return;
        }

        this.leadTransferData = x;

        this.orderForm.controls.client.controls.name.patchValue(
          this.leadTransferData.leadName,
        );
        this.orderForm.controls.client.controls.lastName.patchValue(
          this.leadTransferData.leadLastName,
        );
        this.orderForm.controls.client.controls.phone.patchValue(
          this.leadTransferData.leadPhone,
        );
        this.orderForm.controls.client.controls.email.patchValue(
          this.leadTransferData.leadEmail,
        );
        this.orderForm.controls.towingLocation.patchValue({
          place: this.leadTransferData.address,
          longitude: 1,
          latitude: 1,
        });
      }),
    );
  }

  private initSteps() {
    this.menuSteps = [
      {
        label: 'Klijent',
        routerLink: 'step-1',
        icon: 'pi pi-check',
      },
      {
        label: 'Paketi i vozilo',
        routerLink: 'step-2',
      },
      {
        label: 'Plaćanje',
        routerLink: 'step-3',
      },
    ];
  }

  handleCancel() {
    this.confirmationService.confirm({
      header: 'Odustani od kreiranja kupovine?',
      message: 'Da li želiš da odustaneš od kreiranja kupovine?',
      acceptButtonStyleClass: ButtonSeverity.DANGER,
      acceptLabel: 'Odustani',
      rejectLabel: 'Ostani',
      icon: 'confirm-cancel.svg',
      accept: () => {
        this.location.routeToClientsTable(this._userService.getRoleRoutePrefix);
        this._resetOrderForm();
      },
      reject: () => {},
    });
  }

  handleBack() {
    if (this.currentStep > 1) {
      this.currentStep--;
      this._orderFormService.setStep(this.currentStep);
      this.router
        .navigate([`step-${this.currentStep}`], {
          relativeTo: this.activatedRoute,
        })
        .then(() => {
          this.shouldDisplayBack = this.currentStep > 1;
          this.shouldDisplayFinish = this.currentStep === 3;
        });
    }
  }

  private _nextPageAction() {
    this.currentStep++;
    this._orderFormService.setStep(this.currentStep);
    this.router
      .navigate([`step-${this.currentStep}`], {
        relativeTo: this.activatedRoute,
      })
      .then(() => {
        this.shouldDisplayBack = this.currentStep > 1;
        this.shouldDisplayFinish = this.currentStep === 3;
      });
  }

  async handleNext() {
    console.log('order form: ', this.orderForm);

    if (this.currentStep === 1) {
      await this._handleStep1NextAsync();
      return;
    } else if (this.currentStep === 2) {
      this._handleStep2Next();
      return;
    }

    this._loaderService.setLoadingText = 'Narudžbenica se kreira...';
    this._loaderService.setShowLoader = true;

    this._subs.add(
      this._ordersClient
        .createOrderInbound(this.orderForm.value as CreateOrderInboundCommand)
        .subscribe((x) => {
          this._loaderService.reset();
          this._alertService.addSuccessMsg(x.result);
          this.location.routeToClientsTable(
            this._userService.getRoleRoutePrefix,
          );
        }),
    );
  }

  private async checkEmailExistence() {
    const emailValue = this.orderForm.controls.client.controls.email.value;
    if (!emailValue) {
      this.emailExists = false;
      return;
    }
    return this._loadExistingEmail(emailValue).then((res) => {
      this.emailExists = res.result;
    });
  }

  private async _loadExistingEmail(email: string) {
    return await firstValueFrom(this._userClient.doesEmailExist(email));
  }

  private _resetOrderForm() {
    this._orderFormService.restartStep();
    this._orderFormService.resetOrderForm();
    this._packageListService.clearAllPackages();
  }

  private async _handleStep1NextAsync() {
    await this.checkEmailExistence();
    if (this.emailExists) {
      return this._alertService.addWarnMsg(
        'Već postoji zaposleni sa unetom e-mail adresom',
      );
    }

    if (!this._isStep1Valid) {
      markFormGroupTouched(this.orderForm.controls.client);
      this.orderForm.controls.towingLocation.controls.place.markAsTouched();
      return this._alertService.addWarnMsg(
        'Niste uneli obavezne podatke o klijentu',
      );
    }

    this._nextPageAction();
  }

  private _handleStep2Next() {
    if (!this._isStep2Valid) {
      markFormGroupTouched(this.orderForm);
      this._newVehicleService.markAsTouched();
      return this._alertService.addWarnMsg('Niste uneli obavezne podatke');
    }

    this._nextPageAction();
  }

  private get _isStep1Valid(): boolean {
    return (
      this.orderForm.controls.client.valid &&
      this.orderForm.controls.towingLocation.valid
    );
  }

  private get _isStep2Valid(): boolean {
    return this.orderForm.value.isForVehicle
      ? this._orderFormService.isStep2ForPackageValid
      : this._orderFormService.isStep2ForPersonValid;
  }

  ngOnDestroy(): void {
    this._resetOrderForm();
    this._subs.unsubscribe();
  }
}
