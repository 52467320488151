<atlas-page
  pageTitle="Kampanje"
  btnIcon="pi pi-plus"
  actionLabel="Dodaj kampanju"
  (onHandleAction)="addCampaign()"
>
  <div
    class="header-container flex align-items-center justify-content-between w-full gap-3"
  >
    <p-tabView
      [(activeIndex)]="activeTabIndex"
      (onChange)="onTabChange($event)"
    >
      <p-tabPanel header="Leadovi">
        <ng-template pTemplate="header">
          <div class="flex align-items-center justify-content-between w-full">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0">Leadovi</span>
              <p-badge [value]="this.countLeads"></p-badge>
            </div>
          </div>
        </ng-template>
      </p-tabPanel>

      <p-tabPanel header="Ugovori">
        <ng-template pTemplate="header">
          <div class="flex align-items-center justify-content-between w-full">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0">Ugovori</span>
              <p-badge [value]="this.countContracts"></p-badge>
            </div>
          </div>
        </ng-template>
      </p-tabPanel>

      <p-tabPanel header="Samoobnavljajuće">
        <ng-template pTemplate="header">
          <div class="flex align-items-center justify-content-between w-full">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0"
                >Samoobnavljajuće</span
              >
              <p-badge [value]="this.countSelfRenewing"></p-badge>
            </div>
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>

    <div>
      <div class="search-bar gap-3">
        <atlas-input-button
          [searchCtrl]="campaignTable"
          (onAction)="loadContracts()"
        >
        </atlas-input-button>
      </div>
    </div>
    <div class="filters-button">
      <atlas-button
        btnIcon="pi pi-sliders-h"
        class="filters-button"
        [btnStyle]="ButtonStyle.BASIC"
        [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
        btnLabel="Filteri"
      ></atlas-button>
    </div>
    <div class="flex align-items-center gap-2">
      <p-dropdown
        [options]="rowsOptions"
        [(ngModel)]="selectedRows"
        placeholder="Select rows"
        class="dropdown-number"
      ></p-dropdown>
    </div>
  </div>

  <!-- Leads -->
  <div *ngIf="activeTabIndex === 0">
    <ng-container *ngIf="!isLoading; else skeleton">
      <ng-container *ngIf="leads.length > 0; else emptyLeads">
        <p-dataView [value]="leads" paginator="true" [rows]="selectedRows">
          <ng-template let-items pTemplate="list">
            <div *ngFor="let item of items" class="col-12 p-md-4">
              <atlas-campaign-card
                [campaignId]="item.id"
                [flagColor]="item.flagColor"
                [title]="item.title"
                [showProgressBar]="true"
                [showFlagIcon]="true"
                [showFooterUserIcon]="true"
                [status]="item.status"
                [description]="item.description"
                [date]="item.date | date"
                [user]="item.user"
                [info]="item.info"
                [totalLeads]="item.totalUsers"
                [agents]="item.agents"
              ></atlas-campaign-card>
            </div>
          </ng-template>
        </p-dataView>
      </ng-container>
    </ng-container>
  </div>

  <!-- Contracts -->
  <div *ngIf="activeTabIndex === 1">
    <ng-container *ngIf="contracts.length > 0; else emptyContracts">
      <p-dataView [value]="contracts" paginator="true" [rows]="selectedRows">
        <ng-template let-otherItems pTemplate="list">
          <div *ngFor="let item of otherItems" class="col-12 p-md-4">
            <atlas-campaign-card
              [campaignId]="item.id"
              [title]="item.title"
              [status]="item.status"
              [description]="item.description"
              [date]="item.date | date"
              [showDealTime]="true"
              [dealTime]="item.dealTime | date"
              [user]="item.user"
              [totalLeads]="item.totalUsers"
              [agents]="item.agents"
            ></atlas-campaign-card>
          </div>
        </ng-template>
      </p-dataView>
    </ng-container>
  </div>

  <!-- Self renewing -->
  <div *ngIf="activeTabIndex === 2">
    <ng-container *ngIf="selfRenewing.length > 0; else emptySelfRenewing">
      <p-dataView [value]="selfRenewing" paginator="true" [rows]="selectedRows">
        <ng-template let-otherItems pTemplate="list">
          <div *ngFor="let item of otherItems" class="col-12 p-md-4">
            <atlas-campaign-card
              [campaignId]="item.id"
              [title]="item.title"
              [status]="item.status"
              [description]="item.description"
              [date]="item.date | date"
              [showDealTime]="true"
              [dealTime]="item.dealTime | date"
              [user]="item.user"
              [totalLeads]="item.totalUsers"
              [agents]="item.agents"
              [selfRenewingCondition]="item.selfRenewingCondition"
            ></atlas-campaign-card>
          </div>
        </ng-template>
      </p-dataView>
    </ng-container>
  </div>
</atlas-page>

<ng-template #emptyLeads>
  <div class="table-empty-box">
    <div class="title">Bez kampanja leadova</div>
    <div class="desc">Ovde će biti prikazana lista kampanje leadova</div>
  </div>
</ng-template>

<ng-template #emptyContracts>
  <div class="table-empty-box">
    <div class="title">Bez kampanja ugovora</div>
    <div class="desc">Ovde će biti prikazana lista kampanje ugovora</div>
  </div>
</ng-template>

<ng-template #emptySelfRenewing>
  <div class="table-empty-box">
    <div class="title">Bez samoobnavljajućih kampanja</div>
    <div class="desc">
      Ovde će biti prikazana lista samoobnavljajućih kampanja
    </div>
  </div>
</ng-template>

<ng-template #skeleton>
    <div *ngFor="let item of skeletonItems" class="col-12 p-md-4">
       <p-skeleton height="200px" width="100%"></p-skeleton>
    </div>
</ng-template>
