<atlas-page
  pageTitle="Administracija narudžbenica"
  pageDescription="Prikaz liste aktivnih kupovina"
>
  <div class="flex flex-column md:flex-row gap-3">
    <atlas-page-info-card
      [cardTitle]="'Suma paketa'"
      cardIcon="pi-money-bill"
      [cardNumber]="packageSum | currencyNumber"
      class="flex-1"
      [showExport]="true"
      (exportClicked)="handleCSVExport()"
    ></atlas-page-info-card>
  </div>

  <div class="flex align-items-end gap-3 mt-4">
    <div class="w-full">
      <p-tabView
        class="tabView flex-1"
        [(activeIndex)]="activeIndex!"
        (activeIndexChange)="handleTabChange($event)"
      >
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0"> Sve </span>
            </div>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0">
                Preko kartice
              </span>
            </div>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0">
                Preko uplatnice
              </span>
            </div>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="Neplaćene">
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0"> Neplaćene </span>
              <p-badge [value]="totalNonPaid" />
            </div>
          </ng-template>
        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="search-bar h-full">
      <atlas-dropdown-input-button
        [searchOptionCtrl]="filterService.mainDropdownCtrl"
        [searchCtrl]="filterService.mainSearchCtrl"
        [searchOptions]="filterService.mainDropdownOptions"
        (onAction)="load()"
      >
      </atlas-dropdown-input-button>
    </div>
    <div class="filters-button h-full">
      <atlas-button
        btnIcon="pi pi-sliders-h"
        class="filters-button"
        [btnStyle]="ButtonStyle.BASIC"
        [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
        btnLabel="Filteri"
        (btnClicked)="service.showFilterSidebar()"
      ></atlas-button>
    </div>
  </div>

  <div class="mt-3 mb-3 w-full">
    <atlas-active-filters></atlas-active-filters>
  </div>

  <atlas-table
    [paginatedData]="paginatedData"
    [tableConfig]="tableConfig"
    [resetPagination]="resetPagination"
  ></atlas-table>
</atlas-page>

<atlas-order-table-filter></atlas-order-table-filter>
