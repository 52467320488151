import { NavigationExtras, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationAdminService {
  private readonly _rolePrefix = 'admin';

  constructor(private _router: Router) {}

  private _routeToUrl(url: string, navigationData?: NavigationExtras) {
    this._router.navigate([url], navigationData).then();
  }

  routeToHomePage() {
    this._routeToUrl(`/${this._rolePrefix}`);
  }

  routeToPackageForm() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        this._rolePrefix,
        'packages',
        'create-package',
      ]),
    );
    return this._routeToUrl(url);
  }

  routeToEditPackageForm(packageId: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        this._rolePrefix,
        'packages',
        'edit-package',
        packageId,
      ]),
    );
    return this._routeToUrl(url);
  }

  routeToPackageTable() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', this._rolePrefix, 'packages']),
    );
    return this._routeToUrl(url);
  }

  routeToPromoCodes() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', this._rolePrefix, 'promo-codes']),
    );

    return this._router.navigateByUrl(url);
  }

  routeToCreatePromoCode() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        this._rolePrefix,
        'promo-codes',
        'create-promo-code',
      ]),
    );

    return this._router.navigateByUrl(url);
  }

  routeToEditPromoCode(promoCodeData?: any) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        this._rolePrefix,
        'promo-codes',
        'edit-promo-code',
      ]),
    );

    if (promoCodeData) {
      return this._router.navigateByUrl(url, { state: promoCodeData });
    } else {
      return this._router.navigateByUrl(url);
    }
  }

  routeToReport(reportType: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        this._rolePrefix,
        'reports',
        reportType,
      ]),
    );
    return this._routeToUrl(url);
  }
}
