<div class="flex flex-column gap-3 w-30rem">
  <div>Dostupni paketi</div>
  @if (availablePackages && availablePackages.length > 0) {
    @for (availablePackage of availablePackages;
      let idx = $index;
      track availablePackage.clientPackageId) {
      <div
        class="flex flex-column gap-3 assistance-option cursor-pointer"
        [ngClass]="
          form.value.clientPackageId === availablePackage.clientPackageId
            ? 'option-selected'
            : ''
        "
        (click)="handleClientPackageSelect(availablePackage.clientPackageId)"
      >
        <div class="flex align-items-center">
          <p-radioButton
            name="assistance-packages"
            [value]="availablePackage.clientPackageId"
            [formControl]="form.controls.clientPackageId"
          ></p-radioButton>
          <label class="ml-2">{{ availablePackage.packageName }}</label>
        </div>
        <div
          *ngIf="!availablePackage.packageName.toLowerCase().includes('moj') && !availablePackage.packageName.toLowerCase().includes('guma')"
          [ngClass]="
            form.value.clientPackageId === availablePackage.clientPackageId
              ? 'mt-3 pl-5 block'
              : ' mt-3 pl-5 hidden'
          "
        >
          <atlas-package-service-dropdown></atlas-package-service-dropdown>
        </div>
      </div>
    }
  } @else {
    <div class="empty-info">
      <i class="pi pi-info-circle"></i>
      Klijent nema dostupan paket
    </div>
  }
  <p-divider></p-divider>
  <div>Asistencija bez paketa</div>
  <div
    class="flex flex-column gap-3 assistance-option cursor-pointer"
    [ngClass]="
      form.value.type == AssistanceType.CASH
        ? 'option-selected'
        : ''
    "
    (click)="handleCashSelect()"
  >
    <div class="flex align-items-center">
      <p-radioButton
        name="assistance-packages"
        id="cash-package"
        [value]="AssistanceType.CASH"
        [formControl]="form.controls.type | formControl"
      ></p-radioButton>
      <label for="cash-package" class="ml-2">Za keš</label>
    </div>
  </div>

  <div
    class="flex flex-column gap-3 assistance-option cursor-pointer"
    [ngClass]="
      form.value.type == AssistanceType.INVOICE
        ? 'option-selected'
        : ''
    "
    (click)="handleInvoiceSelect()"
  >
    <div class="flex align-items-center">
      <p-radioButton
        name="assistance-packages"
        id="invoice-package"
        [value]="AssistanceType.INVOICE"
        [formControl]="form.controls.type | formControl"
      ></p-radioButton>
      <label for="invoice-package" class="ml-2">Za fakturu</label>
    </div>
  </div>
  <div
    class="flex flex-column gap-3 assistance-option cursor-pointer"
    [ngClass]="
      form.value.type == AssistanceType.INTERNAL
        ? 'option-selected'
        : ''
    "
    (click)="handleInternalSelect()"
  >
    <div class="flex align-items-center">
      <p-radioButton
        name="assistance-packages"
        id="internal"
        [value]="AssistanceType.INTERNAL"
        [formControl]="form.controls.type | formControl"
      ></p-radioButton>
      <label for="invoice-package" class="ml-2">Interna</label>
    </div>
  </div>
</div>
