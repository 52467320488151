<div>
    <div class="flex gap-2 mb-3">
        <div class="font-medium">Trenutna Emali adresa:</div>
        <div class="font-bold">{{ currentMail }}</div>
    </div> 
    <atlas-input-text
        formCtrlClass="mb-3"
        [formCtrl]="newMailInput"
        ctrlLabel="Nova email adresa:"
        ctrlPlaceholder="Nova adresa"
    ></atlas-input-text>
</div>