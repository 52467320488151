<div class="flex flex-column gap-2">
    <label class="text-sm font-medium">Izvor preporuke</label>
    <atlas-input-search
        (onChange)="onSelectChange($event)"
        (onSearch)="searchClients($event)"
        [suggestions]="clients"
        [shouldReset]="false"
        [isDisabled]="false"
        [ctrlPlaceholder]="'Izvor preporuke'"
        fieldName="name"
        [formCtrlClass]="sourceIdCtrl.touched ? 'w-full border-error' : 'w-full'"
        class="mb-2"
    ></atlas-input-search>
</div>

<atlas-error-required
    [formCtrl]="sourceIdCtrl"
></atlas-error-required>