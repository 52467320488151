import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  currentlyRefreshing: boolean;

  tokenRefreshed$: BehaviorSubject<string | undefined> = new BehaviorSubject<
    string | undefined
  >(undefined);
  get getTokenRefreshed() {
    return this.tokenRefreshed$.asObservable();
  }
  set setTokenRefreshed(value: string) {
    this.tokenRefreshed$.next(value);
  }

  startRefreshing() {
    this.currentlyRefreshing = true;
    this.setTokenRefreshed = undefined;
  }

  reset() {
    this.currentlyRefreshing = false;
    this.setTokenRefreshed = undefined;
  }

  constructor() {}
}
