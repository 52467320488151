<div *ngIf="form" class="form-container">
    <div class="content flex flex-column gap-3">
      <!-- Unos Email-a -->
      
  
      <div class="zone-input">
        <atlas-input-text
          [formCtrl]="form.controls?.name!"
          ctrlLabel="Naziv zone"
          formCtrlId="zona"
          ctrlPlaceholder="Naziv zone"
        ></atlas-input-text>
      </div>
      <atlas-inline-wrapper-zone ctrlLabel="Nacin naplate">
        <div>
          <div class="mb-4" *ngFor="let type of zoneTypes">
            <p-radioButton
              [formControl]="form.controls.type"
              [value]="type.id"
            ></p-radioButton>
            <label class="ml-3" [for]="type.name">{{ type.name }}</label>
          </div>
        </div>
      </atlas-inline-wrapper-zone>
      <div class="description-input">
      <atlas-input-description
        [formCtrl]="form.controls?.description"
        ctrlLabel="Opis"
        formCtrlId="opis"
        [rows]="4"
        ctrlPlaceholder="Opis zone"
        [showInfo]="true"
      ></atlas-input-description>
    </div>
    <atlas-input-text
    [formCtrl]="form.controls?.phoneNumber"
    ctrlLabel="SMS broj"
    formCtrlId="broj"
    [tooltip]="'SMS broj na koji se plaća parking'"
    ctrlPlaceholder="SMS broj"
    [formCtrlIconRight]="'pi pi-info-circle'"
  ></atlas-input-text>
      <div class="option-input">
        <atlas-dropdown-color
        [options]="colors"
        [ctrlLabel]="'Boja'"
        optionLabel="zoneColor"
        [formCtrl]="form.controls.color"
        [ctrlPlaceholder]="colorPlaceHolder ?? 'Boja'"
        (optionChanged)="handleRoleChange($event)"
      >
    </atlas-dropdown-color>
    </div>
    </div>
  </div>
  