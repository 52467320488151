<atlas-page
  pageTitle="Zaposleni"
  btnIcon="pi pi-plus"
  pageDescription="Upravljanje zaposlenima, dodavanje novih i menjanje postojećih"
  actionLabel="Dodaj nalog"
  (onHandleAction)="handleAddNewEmployee()"
>
  <!-- Employee overview cards -->
  <div class="flex flex-column md:flex-row gap-3">
    <atlas-page-info-card
      [cardTitle]="'Aktivni nalozi'"
      cardIcon="pi-user"
      [cardNumber]="activeNum"
      class="flex-1"
    ></atlas-page-info-card>
    <atlas-page-info-card
      [cardTitle]="'Neaktivni nalozi'"
      [cardNumber]="inactiveNum"
      cardIcon="pi-power-off"
      class="flex-1"
    ></atlas-page-info-card>
    <atlas-page-info-card
      [cardTitle]="'Online'"
      [cardNumber]="onlineNum"
      cardIcon="pi-check-circle"
      class="flex-1"
    ></atlas-page-info-card>
  </div>

  <div class="text-2xl font-semibold mt-5">Lista zaposlenih</div>
  <div class="text-base text-gray-600 mt-2">
    Lista aktivnih i deaktiviranih naloga
  </div>

  <div class="flex flex-column gap-3 table-filter mt-3">
    <div class="flex align-items-center gap-3">
      <p-tabView
        class="tabView flex-1"
        [(activeIndex)]="activeIndex!"
        (activeIndexChange)="handleTabChange($event)"
      >
        <p-tabPanel header="Svi zaposleni"></p-tabPanel>
        <p-tabPanel header="Aktivni"></p-tabPanel>
        <p-tabPanel header="Neaktivni"></p-tabPanel>
      </p-tabView>

      <div class="search-bar">
        <atlas-input-button
          [searchCtrl]="searchInputValue"
          (onAction)="load()"
        ></atlas-input-button>
      </div>
    </div>
  </div>

  <p-confirmDialog></p-confirmDialog>

  <atlas-table
    [paginatedData]="paginatedData"
    [tableConfig]="tableConfig"
  ></atlas-table>
</atlas-page>
