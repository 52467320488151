<div class="flex flex-column gap-2 w-full">
  <label>Marka</label>
  <atlas-input-search
    (onChange)="onSelectChange($event)"
    (onSearch)="searchBrand($event)"
    [suggestions]="brands"
    [shouldReset]="shouldResetInput"
    [forceSelection]="false"
    [formCtrlClass]="
        (newVehicleService.getBrandNameCtrl().errors?.['required'] && (newVehicleService.getBrandNameCtrl().dirty || newVehicleService.getBrandNameCtrl().touched))
        ? 'w-full border-error'
        : 'w-full'"
    [ctrlPlaceholder]="
      newVehicleService.getBrandNameCtrl().value ?? newVehicleService.defaultBrandName
    "
    fieldName="label"
  ></atlas-input-search>

  <atlas-error-required
          *ngIf="shouldDisplayRequiredError(newVehicleService.getBrandNameCtrl())"
          [formCtrl]="newVehicleService.getBrandNameCtrl()"
  ></atlas-error-required>
</div>
