import { Component, OnDestroy, OnInit } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { DividerModule } from 'primeng/divider';
import {
  JsonPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet,
} from '@angular/common';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { ServiceAvailabilityComponent } from '@app/sos-agent/packages/client-package-sidebar/service-availability/service-availability.component';
import {
  ClientClient,
  IClientPackageSidebarResponse,
} from '@shared/model/atlas.api';
import { LocationService } from '@shared/services/location.service';
import { ActivatedRoute } from '@angular/router';
import { licencePlatePipe } from '@shared/pipes/licence-plate.pipe';
import { LocationSosService } from '@shared/services/location-sos.service';

@Component({
  selector: 'atlas-client-package-sidebar',
  standalone: true,
  imports: [
    SidebarModule,
    DividerModule,
    NgIf,
    NgOptimizedImage,
    ButtonComponent,
    ServiceAvailabilityComponent,
    JsonPipe,
    NgForOf,
    NgTemplateOutlet,
    NgClass,
    licencePlatePipe,
  ],
  templateUrl: './client-package-sidebar.component.html',
  styleUrl: './client-package-sidebar.component.scss',
})
export class ClientPackageSidebarComponent implements OnInit, OnDestroy {
  private _subs: Subscription;
  showSidebar: boolean;
  clientPackageId: string;
  previewData: IClientPackageSidebarResponse;
  isReadonly: boolean = false;
  statusData: any;

  constructor(
    private service: ClientPackageTableService,
    private client: ClientClient,
    private locationService: LocationService,
    private _locationSosService: LocationSosService,
    private route: ActivatedRoute,
  ) {
    this._subs = new Subscription();
    this.showSidebar = false;
    this.clientPackageId = '';
    this.previewData = {};
    this.isReadonly = route.snapshot.data['readonly'];
  }

  ngOnInit() {
    this._subs.add(
      this.service.getShowPreviewSidebar.subscribe((show) => {
        if (show) {
          this.clientPackageId = this.service.getSidebarId;
          this.showSidebar = show;
          this._load();
        }
      }),
    );
  }

  ngOnDestroy() {
    this.service.hidePreviewSidebar();
    this._subs.unsubscribe();
  }

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  handleCreateForPackage() {
    this._locationSosService.routeToCreateAssitanceForm(this.previewData.id);
  }

  handleCreateForCash() {
    this._locationSosService.routeToCreateAssitanceForm(this.previewData.id);
  }

  private _load() {
    this._getPreviewData().then((res) => {
      this.previewData = res.result;
      if (this.previewData.isActive) {
        this.statusData = { text: 'Završena', class: 'badge-success' };
      } else if (!this.previewData.isActive) {
        this.statusData = { text: 'Iskorišćen', class: 'badge-info' };
      }
    });
  }

  private async _getPreviewData() {
    return await firstValueFrom(
      this.client.getClientPackageSidebar(this.clientPackageId),
    );
  }
}
