import { Component, OnInit, ViewChild } from '@angular/core';
import { AssistanceService } from '../../assistance-form/services/assistance.service';
import { AssistancesClient, AssistanceStatus } from '@shared/model/atlas.api';
import { catchError, map, Observable, Subscription } from 'rxjs';
import {
  AsyncPipe,
  CommonModule,
  NgFor,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet,
} from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { LocationService } from '@shared/services/location.service';
import { PageInfoCardComponent } from '@ui/page-info-card/page-info-card.component';
import { AssistanceBoxComponent } from '@ui/assistance-box/assistance-box/assistance-box.component';
import { GalleriaModule } from 'primeng/galleria';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import {
  GoogleMap,
  MapAdvancedMarker,
  MapDirectionsRenderer,
  MapMarker,
} from '@angular/google-maps';
import { LocationMapComponent } from '../../assistance-form/assistance-form-step-1/location-map/location-map.component';
import { DialogService } from '@ui/dialog/dialog.service';
import { CancelAssistanceDialogComponent } from '../../cancel-assistance.dialog/cancel-assistance.dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { ImageModule } from 'primeng/image';
import { ImagePreviewComponent } from '@shared/components/image-preview/image-preview/image-preview.component';
import { LocationSosService } from '@shared/services/location-sos.service';

@Component({
  selector: 'atlas-assistance-preview',
  standalone: true,
  imports: [
    NgIf,
    DividerModule,
    CommonModule,
    NgTemplateOutlet,
    NgForOf,
    NgOptimizedImage,
    PageInfoCardComponent,
    AssistanceBoxComponent,
    GalleriaModule,
    NgFor,
    ScrollPanelModule,
    GoogleMap,
    MapMarker,
    MapDirectionsRenderer,
    AsyncPipe,
    MapAdvancedMarker,
    LocationMapComponent,
    DialogModule,
    ImageModule,
    ImagePreviewComponent,
  ],
  templateUrl: './assistance-preview.component.html',
  styleUrl: './assistance-preview.component.scss',
})
export class AssistancePreviewComponent implements OnInit {
  id: string | null = null;
  dialogConfig: any = {
    header: '',
    headerDescription: '',
    customSubmitButton: {
      label: '',
      icon: '',
      style: '',
    },
    customCancelButton: {
      label: '',
      icon: '',
      style: '',
    },
    data: {},
    closable: false,
  };
  pageLabel: string = 'Asistencija';
  private _subs: Subscription = new Subscription();
  paginatedData: any;
  displayComment: boolean = false;
  directionsResults$: Observable<google.maps.DirectionsResult | undefined>;
  @ViewChild('gmap') mapElement: GoogleMap;
  mapOptions: google.maps.MapOptions;
  directionOptions: google.maps.DirectionsRendererOptions = {
    polylineOptions: {
      strokeColor: '#C21A1A',
      strokeWeight: 4,
    },
    markerOptions: {},
  };

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];

  constructor(
    private assistanceService: AssistanceService,
    private assistance: AssistancesClient,
    private locationService: LocationService,
    private _locationSosService: LocationSosService,
    private _dialogService: DialogService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      if (params['id']) {
        this.id = params['id'];
      }
    });
    this.mapOptions = {
      mapId: 'DEMO_MAP_ID',
      disableDefaultUI: true,
      zoomControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: true,
      center: {
        // Belgrade
        lat: 44.80401,
        lng: 20.46513,
      },
      zoom: 7,
      styles: [
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ],
    };
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this._subs.add(
      this.assistance.getAssistanceDetails(this.id).subscribe((res) => {
        this.paginatedData = res.result.value;
        console.log(this.paginatedData);
        this.setDirectionFromPaginatedData();
      }),
    );
  }

  editAssistance() {
    this._locationSosService.routeToEditAssitanceForm(this.id);
  }

  showComment() {
    this.displayComment = true;
  }

  cancelAssistance(): void {
    this.id = this.assistanceService.getAssistanceId();
    if (this.id) {
      this.dialogConfig.header = 'Otkazivanje';
      this.dialogConfig.headerDescription = 'Potvrdite otkazivanje asistencije';
      this.dialogConfig.customSubmitButton = {
        label: 'Potvrdi otkazivanje',
        icon: 'pi pi-times',
        style: 'max-width: 100px',
      };
      this.dialogConfig.customCancelButton = {
        label: 'Otkaži',
        icon: '',
        style: 'max-width: 100px',
      };
      this.dialogConfig.data = {
        id: this.id,
      };
      this.dialogConfig.closable = true;

      this.openDialog();
    } else {
      // Handle case where id is null
      console.error('Assistance ID is null');
    }
  }

  openDialog(): void {
    const ref = this._dialogService.open(
      CancelAssistanceDialogComponent,
      this.dialogConfig,
    );
    ref.onClose.subscribe((result) => {
      if (result && result.status === AssistanceStatus.CANCELED) {
        this._locationSosService.routeToAssitancesTable();
      }
    });
  }

  setDirectionFromPaginatedData() {
    if (
      !this.paginatedData?.failureLocation ||
      !this.paginatedData?.destinationLocation
    ) {
      return;
    }

    const startLocation = new google.maps.LatLng(
      this.paginatedData.failureLocation.latitude,
      this.paginatedData.failureLocation.longitude,
    );
    const endLocation = new google.maps.LatLng(
      this.paginatedData.destinationLocation.latitude,
      this.paginatedData.destinationLocation.longitude,
    );
    const driverStartLocation = {
      latitude: 45.23282,
      longitude: 19.8215,
      type: 'TRUCK',
      name: 'Truck Location',
      icon: 'assets/images/truck-type.svg',
    };

    const request: google.maps.DirectionsRequest = {
      origin: startLocation,
      destination: endLocation,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    this.directionsResults$ = this.locationService.route(request).pipe(
      map((result: google.maps.DirectionsResult) => {
        return result;
      }),
      catchError((error) => {
        return new Observable<google.maps.DirectionsResult | undefined>(
          (observer) => {
            observer.next(undefined);
            observer.complete();
          },
        );
      }),
    );

    this._drawMarkers([driverStartLocation]);
  }

  private _drawMarkers(
    markersData: {
      latitude: number;
      longitude: number;
      type: string;
      name: string;
      icon: string;
    }[],
  ) {
    const parser = new DOMParser();

    for (const markerData of markersData) {
      const pinSvg = parser.parseFromString(
        markerData.icon,
        'image/svg+xml',
      ).documentElement;

      const marker = new google.maps.marker.AdvancedMarkerElement({
        map: this.mapElement?.googleMap,
        position: new google.maps.LatLngAltitude({
          lat: markerData.latitude,
          lng: markerData.longitude,
          altitude: 9,
        }),
        title: markerData.name,
        content: pinSvg,
      });
    }
  }
}
