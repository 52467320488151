<div class="campaign-card" [ngClass]="{ 'complete': info === 100 }">
  <div *ngIf="info === 100" class="corner-check">
    <i class="pi pi-check"></i>
  </div>
  <div class="campaign-header">
    <div class="title-section">
      <h3 class="title-header">{{ title }}</h3>
      <span class="status-badge ml-1" [ngClass]="getStatusBadgeClass(status)">
        <span class="status-dot"></span>
        {{ status }}
      </span>
    </div>
    <div class="top-right">
      <atlas-agent-list-images [agents]="agents"></atlas-agent-list-images>

      <div *ngIf="showProgressBar" class="progress-container">
        <p-progressBar
          [value]="info"
          [style]="{ width: '100px', height: '8px' }"
          [ngClass]="getProgressBarClass(info)"
        >
        </p-progressBar>
        <div class="progress-value">{{ info }}%</div>
      </div>
      <i class="pi pi-ellipsis-v"></i>
    </div>
  </div>

  <p class="description">{{ description }}</p>
  <div class="campaign-separator"></div>
  <div class="campaign-footer">
    <div class="campaign-info">
      <span
        *ngIf="showFlagIcon"
        class="pi pi-flag-fill"
        [ngStyle]="{ color: flagColor }"
      ></span>
      <span *ngIf="showFlagIcon" class="separator"></span>
      <ng-container *ngIf="selfRenewingCondition">
        <span class="pi pi-filter"></span> {{ selfRenewingCondition }}
        <span class="separator"></span>
      </ng-container>
      <span *ngIf="date" class="pi pi-calendar"></span> {{ date }}
      <span *ngIf="date" class="separator"></span>
      <span *ngIf="showDealTime" class="pi pi-clock"></span> {{ dealTime }}
      <span *ngIf="showDealTime" class="separator"></span>
      <img
        ngSrc="../../../../assets/images/client-icon.svg"
        alt="User Icon"
        width="20"
        height="20"
      />
      {{ user }}
      <span class="separator"></span>
      <span class="pi pi-users"></span> {{ totalLeads ?? totalContracts }}
    </div>
    <span *ngIf="userService.isCcLeader()" (click)="moreDetails()" class="more-details">Vidi detalje ></span>
    <span *ngIf="userService.isCcAgent()" (click)="startCampaign()" class="more-details">Započni ></span>
  </div>
</div>
