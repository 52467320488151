<p-sidebar [(visible)]="showSidebar" position="right" styleClass="w-27rem left-rounded">
  <ng-template pTemplate="header">
    <div class="text-xl font-semibold">Vozilo</div>
  </ng-template>
  <ng-container *ngIf="previewData">
    <div class="flex flex-column gap-3">
      <div class="flex justify-content-between align-items-center">
        <img [ngSrc]="getImageSrc()" width="48" height="48" alt="vehicle-icon"/>
        <div class="flex align-items-center gap-1 pointer">
          <i class="pi pi-pencil font-semibold smaller-text"></i>
          <div (click)="editVehicle()" class="smaller-text font-semibold">
            Izmeni
          </div>
        </div>
      </div>
      <div class="text-2xl font-semibold">{{ previewData.vehicle }}</div>
      <div class="label-md-medium">{{ previewData.licencePlate | licencePlate }}</div>
    </div>
    <p-divider></p-divider>

    <div class="flex flex-column gap-4">
      <div class="mid-title">Vozač</div>

      <div
        *ngIf="!previewData.driverId"
        class="flex flex-column gap-3 package-box"
      >
        <div class="flex gap-3 align-items-center">
          <img
            ngSrc="assets/images/user.svg"
            width="40"
            height="40"
            alt="user-image"
          />
          <div class="flex flex-column justify-content-between gap-1">
            <div class="label-sm-mid">Nema dodeljenog vozača</div>
            <div (click)="addDriver()" class="add-text">Dodeli vozača ></div>
          </div>
        </div>
      </div>
      <div
        *ngIf="previewData.driverId"
        class="flex flex-column gap-3 package-box-blank"
        (click)="addDriver()"
      >
        <div class="flex gap-3 align-items-center pointer">
          <img
            ngSrc="../../../../assets/images/user-vehicle.svg"
            width="40"
            height="40"
            alt="user-image"
          />
          <div class="flex flex-column justify-content-between gap-1">
            <div class="label-sm">Dodeljen vozač</div>
            <div class="label-md-medium">{{ previewData.driver }}</div>
          </div>

          <div class="pi pi-chevron-right ml-8"></div>
        </div>
      </div>
    </div>

    <p-divider></p-divider>
    <div class="flex flex-column gap-3">
      <div class="mid-title">Vozilo</div>
      <div class="flex justify-content-between">
        <div class="label-sm">Cena</div>
        <div class="label-sm-black">{{ previewData.pricePerKm || "--" }}</div>
      </div>
      <div class="flex justify-content-between">
        <div class="label-sm">Nosivost</div>
        <div class="label-sm-black">{{ previewData.capacity || "--" }}</div>
      </div>
      <div class="flex justify-content-between">
        <div class="label-sm">Broj putnika</div>
        <div class="label-sm-black">{{ previewData.numOfSeats || "--" }}</div>
      </div>
      <div class="flex justify-content-between">
        <div class="label-sm">Lokacija</div>
        <div class="label-sm-black">
          {{ previewData.location.place || "--" }}
        </div>
      </div>
      <google-map [center]="mapOptions.center" [zoom]="mapOptions.zoom">
        <map-marker
          [position]="markerPosition"
          [options]="markerOptions"
        ></map-marker>
      </google-map>
    </div>
    <p-divider></p-divider>
    <div class="flex justify-content-start align-items-center gap-2">
      <img
        [ngSrc]="'assets/images/trash.svg'"
        width="15"
        height="17"
        alt="trash-image"
      />
      <div (click)="deleteVehicle(vehicleId)" class="delete-text pointer">
        Izbriši vozilo
      </div>
    </div>
  </ng-container>
</p-sidebar>
