<div class="container flex">
  <div class="flex surface-0 flex-shrink-1 flex-grow-1">
    <div
      class="m-3 p-2 border-round-lg flex justify-content-center align-items-center card-icon"
    >
      <img
        [ngSrc]="'assets/images/checked-user.svg'"
        alt="Card Icon"
        class="icon-img"
        width="40"
        height="40"
      />
    </div>
    <div class="m-3 ml-1">
      <div class="h-2rem font-medium card-desc">Klijenti</div>
      <div class="card-value-info-page">{{ totalClients }} klijenta</div>
    </div>
  </div>

  <div class="flex surface-0 flex-shrink-1 ml-8 flex-grow-1 p-2">
    <div
      class="m-3 p-2 border-round-lg flex justify-content-center align-items-center card-icon"
    >
      <img
        [ngSrc]="'assets/images/checked-file.svg'"
        alt="Card Icon"
        class="icon-img"
        width="40"
        height="40"
      />
    </div>
    <div class="m-3 ml-1">
      <div class="h-2rem font-medium card-desc">Ugovori</div>
      <div class="card-value-info-page">
        {{ totalPackages }} {{ totalPackages === 1 ? "ugovor" : "ugovora" }}
      </div>
    </div>
  </div>
</div>

<div class="dialog-container">
  <p class="label-txt">Od</p>
  <div class="agent-info">
    <img
      [ngSrc]="agentImageUrl"
      alt="Agent Image"
      class="agent-img"
      width="24"
      height="24"
    />
    <span class="agent-name">{{ agentName }}</span>
  </div>

  <div class="separator-container">
    <hr class="separator-line" />
    <div class="separator-circle">
      <i class="pi pi-chevron-down"></i>
    </div>
  </div>

  <p class="label-txt">Do</p>
  <atlas-agent-autocomplete
    (agentSelected)="onAgentSelected($event)"
  ></atlas-agent-autocomplete>
</div>
