<div class="ml-1" style="max-width: 700px">
  <div class="step-title">Korak 2</div>
  <div class="text-3xl font-semibold mt-1">Paket i vozilo</div>
  <div class="mt-6 title-text">Vrsta paketa</div>

  <div class="flex gap-4 box-padding mt-5">
    <atlas-icon-checkbox
      name="Paket za vozilo"
      [isSelected]="selectedOption === 'Vozilo'"
      imageUrl="assets/images/user-vehicle.svg"
      (handleSelect)="selectPackageForVehicle()"
      class="flex-1"
    ></atlas-icon-checkbox>
    <atlas-icon-checkbox
      name="Lični paket"
      [isSelected]="selectedOption === 'Licni'"
      imageUrl="assets/images/user-moj-package.svg"
      (handleSelect)="selectPackageForPerson()"
      class="flex-1"
    ></atlas-icon-checkbox>
  </div>

  <!-- Paket za vozilo -->
  <div *ngIf="selectedOption === 'Vozilo'">
    <!-- Kartice za tip vozila -->
    <div class="mt-6 title-text">Izaberi paket za</div>
    <!-- skeleton -->
    <div *ngIf="!vehicleTypes" class="flex flex-column mt-5">
      <div class="w-full flex gap-3">
        <div class="w-full">
          <p-skeleton width="100%" height="55px"/>
        </div>
        <div class="w-full">
          <p-skeleton width="100%" height="55px"/>
        </div>
        <div class="w-full">
          <p-skeleton width="100%" height="55px"/>
        </div>
      </div>
    </div>
    <div class="flex gap-4 mt-5">
      <atlas-icon-checkbox
        *ngFor="let vehicleType of vehicleTypes"
        [name]="vehicleType?.name"
        [isSelected]="selectedVehicleType === vehicleType?.name"
        [imageUrl]="vehicleType?.name | vehicleTypeIcon"
        (handleSelect)="handleTypeChange(vehicleType)"
        class="flex-1"
      ></atlas-icon-checkbox>
    </div>

    <ng-container *ngIf="selectedVehicleType">
      <atlas-package-card-list [isRenew]="false"></atlas-package-card-list>

      <div class="mt-6 title-text">Detalji vozila</div>

      <div class="mt-3 flex gap-3">
        <!-- brand -->
        <div class="w-full">
          <atlas-brand-dropdown
            *ngIf="!isKombiSelected"
            [type]="selectedVehicleType"
            (onBrandChanged)="handleBrandChange($event)"
            [brandNameCtrl]="orderForm.controls.vehicle.controls.brand"
            [validateControl]="
              orderForm.controls.vehicle.controls.brand.touched
            "
          ></atlas-brand-dropdown>

          <atlas-brand-autocomplete
            *ngIf="isKombiSelected"
            [type]="selectedVehicleType"
            (onBrandChanged)="handleBrandChange($event)"
            [brandName]="orderForm.value.vehicle.brand"
          ></atlas-brand-autocomplete>
        </div>
        <!-- model -->
        <div class="w-full">
          <atlas-model-dropdown
            *ngIf="!isKombiSelected"
            [brandId]="selectedBrandId"
            [brandName]="orderForm.value.vehicle.brand"
            [modelNameCtrl]="orderForm.controls.vehicle.controls.model"
            [isDisabled]="!orderForm.value.vehicle.brand"
            [validateControl]="
              orderForm.controls.vehicle.controls.model.touched
            "
          ></atlas-model-dropdown>

          <atlas-model-autocomplete
            *ngIf="isKombiSelected"
            [brandId]="selectedBrandId"
            [modelName]="orderForm.value.vehicle.model"
            [isDisabled]="!selectedBrandId"
            (onModelChanged)="handleModelChanged($event)"
          ></atlas-model-autocomplete>
        </div>
      </div>

      <div class="mt-3 flex gap-3">
        <!-- Registracija -->
        <div class="w-full">
          <atlas-input-text
            ctrlPlaceholder="XX000XX"
            [formCtrl]="orderForm.controls.vehicle.controls.licencePlate"
            class="w-full"
            ctrlLabel="Registracione oznake"
            formCtrlClass="w-full uppercase"
            [ctrlKeyFilter]="LicencePlateRegExp"
          ></atlas-input-text>
        </div>
        <!-- Broj sasije -->
        <div class="w-full">
          <atlas-input-text
            [formCtrl]="
              orderForm.controls.vehicle.controls.chassis | formControl
            "
            ctrlLabel="Broj šasije"
            formCtrlId="broj"
            [tooltip]="'Broj šasije vozila nedostaje za ovaj paket'"
            ctrlPlaceholder="Broj šasije"
            [formCtrlIconRight]="'pi pi-info-circle'"
            formCtrlClass="w-full"
          ></atlas-input-text>
        </div>
      </div>

      <div class="mt-3 flex gap-3">
        <!-- Boja -->
        <div class="w-full">
          <atlas-input-text
            [formCtrl]="orderForm.controls.vehicle.controls.color"
            ctrlLabel="Boja vozila (opciona)"
            ctrlPlaceholder="Boja vozila"
            hideErrors="true"
            formCtrlClass="w-full"
          ></atlas-input-text>
        </div>
        <!-- Godiste -->
        <div class="w-full">
          <atlas-input-text
            [formCtrl]="orderForm.controls.vehicle.controls.productionYear"
            ctrlLabel="Godište (opciono)"
            ctrlPlaceholder="Godište"
            hideErrors="true"
            formCtrlClass="w-full"
          ></atlas-input-text>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Licni paket -->
  <div *ngIf="selectedOption === 'Licni'">
    <div class="mt-6 title-text">Detalji paketa</div>

    <atlas-package-card-list></atlas-package-card-list>

    <div class="mt-3 flex gap-3">
      <div class="w-full">
        <atlas-input-text
          [formCtrl]="orderForm.controls.client.controls.nationalNumber"
          ctrlLabel="JMBG"
          ctrlPlaceholder="Jedinstveni matični broj građana"
          formCtrlClass="w-full"
        ></atlas-input-text>
      </div>
    </div>
  </div>
</div>
