<atlas-page
  pageTitle="{{ partnerData?.name }}"
  pageDescription="Osnovni podaci o saradniku i njegovim vozilima"
  btnIcon="pi pi-pencil"
  actionLabel="izmeni"
  (onHandleAction)="editPartner(currentPartnerId)"
>
  <hr />

  <div class="wraper flex flex-row mt-4">
    <!-- LEVA STRANA -->
    <div class="left-side">
      <!-- Detalji firme -->
      <div>
        <div class="font-semibold text-lg mb-5">Detalji</div>
        <div class="font-medium text-base mb-3">Detalji firme</div>

        <!-- Partner overview cards -->
        <div class="flex flex-column md:flex-row gap-3">
          <atlas-page-info-card
            [cardTitle]="'Pib'"
            cardIcon="pi-briefcase"
            [cardNumber]="partnerData?.taxId ?? '--'"
            [infoPage]="true"
            class="flex-1"
          ></atlas-page-info-card>
          <atlas-page-info-card
            [cardTitle]="'Matični broj'"
            [cardNumber]="partnerData?.nationalNumber ?? '--'"
            cardIcon="pi-briefcase"
            class="flex-1"
            [infoPage]="true"
          ></atlas-page-info-card>
          <atlas-page-info-card
            [cardTitle]="'Žiro račun'"
            [cardNumber]="partnerData?.bankAccount ?? '--'"
            cardIcon="pi-building"
            class="flex-1"
            [infoPage]="true"
          ></atlas-page-info-card>
        </div>
      </div>

      <!-- Glavne usluge -->
      <div class="mt-6 font-medium text-base">Glavne usluge paketa</div>
      <div class="flex mt-4">
        <div *ngFor="let activity of partnerData?.activityTypes">
          <div class="badge flex align-items-baseline mr-2">
            <div class="pi pi-check badge-icon mr-2"></div>
            <div class="badge-txt">{{ activity }}</div>
          </div>
        </div>
      </div>

      <!-- Tabela vozila -->
      <div class="flex mt-4 justify-content-between table-header">
        <div class="flex gap-3 mt-2">
          <div class="font-semibold text-xl">Vozila</div>
          <div class="table-num">{{ partnerData?.numOfVehicles }}</div>
        </div>
        <div>
          <atlas-button
            btnIcon="pi pi-plus"
            btnLabel="Dodaj vozilo"
            btnClass="pl-0"
            (btnClicked)="handleAddVehicle()"
          ></atlas-button>
        </div>
      </div>

      <div class="table-container">
        <atlas-table
          [paginatedData]="vehiclePagData"
          [tableConfig]="vehicleTableConfig"
        ></atlas-table>
      </div>

      <!-- Tabela vozaca -->
      <div class="flex mt-4 justify-content-between table-header mt-7">
        <div class="flex gap-3 mt-2">
          <div class="font-semibold text-xl">Vozači</div>
          <div class="table-num">{{ driversCount }}</div>
        </div>
        <div>
          <atlas-button
            btnIcon="pi pi-plus"
            btnLabel="Dodaj vozača"
            btnClass="pl-0"
            (btnClicked)="handleAddDriver()"
          ></atlas-button>
        </div>
      </div>

      <div class="table-container">
        <atlas-table
          [paginatedData]="driversPaginatedData"
          [tableConfig]="driverTableConfig"
        ></atlas-table>
      </div>
    </div>

    <!-- DESNA STRANA -->
    <div class="right-side ml-5">
      <div class="contact-card border-round-xl p-5">
        <div class="contact-header mb-4">
          <div class="font-semibold text-xl line-height-4">Kontakt osoba</div>
          <div class="font-normal text-base header-desc">
            Zadužena i odgovorna osoba saradnika
          </div>
        </div>

        <hr />

        <div class="contact-details">
          <div class="icon-box mb-3 mt-3">
            <div class="pi pi-user icon"></div>
          </div>
          <div class="font-semibold text-2xl mb-4">
            {{ partnerData?.contactName }}
          </div>
          <div class="flex mb-3 phone">
            <div class="pi pi-phone mr-3 phone-num-icon"></div>
            <div class="phone-num">{{ partnerData?.contactNumber }}</div>
          </div>
          <div class="flex mail">
            <div class="pi pi-envelope mr-3"></div>
            <div class="mail">{{ partnerData?.contactEmail }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</atlas-page>

<atlas-vehicle-sidebar></atlas-vehicle-sidebar>