import { NgFor } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MediumsDropdownComponent } from '@shared/components/mediums-dropdown/mediums-dropdown.component';
import { SourcesDropdownComponent } from '@shared/components/sources-dropdown/sources-dropdown.component';
import { markFormGroupTouched } from '@shared/forms/forms-common';
import {
  ClientClient,
  DeliveryType,
  GetPackageForRenewQuery,
  IPackageCardItem,
  OrdersClient,
  PackagesForRenewResponse,
  PaymentType,
  RenewOrderInboundCommand,
  SwaggerResult,
} from '@shared/model/atlas.api';
import { CurrencyNumberPipe } from '@shared/pipes/currency-number.pipe';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { AlertService } from '@shared/services/alert.service';
import { LocationService } from '@shared/services/location.service';
import {
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  OrderFormService,
  RenewPackageForm,
} from '@app/cc-leader/services/order-form.service';
import {
  PackageCardListService,
  PackageRenewData,
} from '@app/cc-leader/services/package-card-list.service';
import { PackageCardListComponent } from '@app/features/packages/package-card-list/package-card-list.component';
import { ScrollablePageComponent } from '@shared/components/scrollable-page/scrollable-page.component';
import { LoaderService } from '@shared/components/loader/loader.service';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PaymentTypeOptionsComponent } from '../payment-type-options/payment-type-options.component';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'atlas-renew-package-form',
  standalone: true,
  imports: [
    ScrollPanelModule,
    DividerModule,
    PackageCardListComponent,
    NgFor,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    FormControlPipe,
    CurrencyNumberPipe,
    MediumsDropdownComponent,
    SourcesDropdownComponent,
    ScrollablePageComponent,
    SelectButtonModule,
    PaymentTypeOptionsComponent,
  ],
  templateUrl: './renew-package-form.component.html',
  styleUrl: './renew-package-form.component.scss',
})
export class RenewPackageFormComponent implements OnDestroy {
  private _subs: Subscription = new Subscription();

  renewForm: RenewPackageForm;
  packages: any[] = [];
  packagesSum: number = 0;
  clientPackageId: string = '';

  responsePackages: any[] = [];
  packagesForRenew: PackageRenewData[] = [];

  activationType: any = [
    {
      label:
        'Aktivacija odmah (paket se aktivira odmah nakon evidentiranja uplate)',
      value: true,
    },
    {
      label: 'Standardna aktivacija (7 dana od evidentiranja uplate)',
      value: false,
    },
  ];

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  constructor(
    private _client: OrdersClient,
    private _orderFormService: OrderFormService,
    private _packageCardService: PackageCardListService,
    private _locationService: LocationService,
    private _alertService: AlertService,
    private acivatedRoute: ActivatedRoute,
    private _loader: LoaderService,
    private _clientClient: ClientClient,
    private _userService: UserService,
  ) {
    this.renewForm = this._orderFormService.initRenewPackageForm();

    this._subs.add(
      this._packageCardService.packages$.subscribe((x) => {
        this.packages = x.value.map((p) => ({
          packageName: p.name,
          packagePrice: p.price,
        }));
        this.packagesSum = x.value.reduce((a, b) => a + b.price, 0);
      }),
    );

    // Single
    this.acivatedRoute.params.subscribe(async (params) => {
      debugger;
      this.clientPackageId = params['id'];
      if (this.clientPackageId !== 'undefined') {
        // single
        await this._loadPackagesData([this.clientPackageId], false);
      } else {
        // Multiple
        debugger;
        this.packagesForRenew =
          this._packageCardService.getSelectedPackagesForRenewValue();
        if (this.packagesForRenew.length > 0) {
          await this._loadPackagesData(
            this.packagesForRenew.map((x) => x.clientPackageId),
            true,
          );
        }
      }
    });
  }

  private async _loadPackagesData(cpIds: string[], isMultiple: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this._subs.add(
        this._clientClient
          .getForRenew(
            new GetPackageForRenewQuery({
              cpIds: cpIds,
            }),
          )
          .subscribe(async (res: SwaggerResult<PackagesForRenewResponse>) => {
            await this.setRenewForm(res, isMultiple);
            resolve(res);
          }, reject),
      );
    });
  }


  private async setRenewForm(
    res: SwaggerResult<PackagesForRenewResponse>,
    isMultiple: boolean = false,
  ) {
    this.renewForm.patchValue(res.result);

    if (this.renewForm.value.deliveryType !== DeliveryType.E_CONTRACT) {
      this.renewForm.controls.deliveryType.patchValue(DeliveryType.E_CONTRACT);
    }

    if (this.renewForm.value.paymentType !== PaymentType.CORVUS_PAY) {
      this.renewForm.controls.paymentType.patchValue(PaymentType.CORVUS_PAY);
    }

    this.renewForm.controls.deliveryType.disable();
    this.renewForm.controls.paymentType.disable();

    if (!isMultiple) {
      this._packageCardService.setSelectedPackageIdForRenew(
        res.result.clientPackages[0].id,
      );
      this._packageCardService.clearAllPackages();
    } else {
      this._packageCardService.setSelectedPackagesForRenew(
        res.result.clientPackages.map((x) => {
          return {
            clientPackageId: x.clientPackageId,
            packageId: x.id,
          };
        }),
      );
    }

    res.result.clientPackages.map((packageCard: IPackageCardItem) => {
      this._packageCardService.addPackage({
        name: packageCard.name,
        id: packageCard.id,
        price: packageCard.price,
        originalPrice: packageCard.price,
        clientPackageId: packageCard.clientPackageId,
      });
    });
  }

  handleCancelForm() {
    this._locationService.routeToClientsTable(
      this._userService.getRoleRoutePrefix,
    );
  }

  handleSubmitForm() {
    this._prepareFormPackages();
    if (!this.renewForm.valid) {
      markFormGroupTouched(this.renewForm);
      return this._alertService.addWarnMsg('Morate popuniti sva polja');
    }

    if (this.renewForm.value.packageItems.length === 0) {
      return;
    }

    this.renewForm.controls.deliveryType.enable();
    this.renewForm.controls.paymentType.enable();

    this._loader.setShowLoader = true;

    this._subs.add(
      this._client
        .renewOrder(this.renewForm.value as RenewOrderInboundCommand)
        .subscribe((x) => {
          this._loader.setShowLoader = false;
          this._alertService.addSuccessMsg(x.result);
          this._locationService.routeToClientsTable(
            this._userService.getRoleRoutePrefix,
          );
          this._loader.reset();
        }),
    );
  }

  private _prepareFormPackages() {
    this.renewForm.controls.clientId.patchValue(
      this._orderFormService.getRenewClientId(),
    );

    if (!this.renewForm.valid) {
      return;
    }

    this._packageCardService.getPackages().value.forEach((x) => {
      this.renewForm.controls.packageItems.push(
        this._packageCardService.initPackageCardForm({
          name: x.name,
          id: x.id,
          price: x.price,
          clientPackageId: this.clientPackageId,
        }),
      );
    });
  }

  ngOnDestroy(): void {
    this._packageCardService.clearAllPackages();
    this._packageCardService.clearSelectedPackagesForRenew();
    this._subs.unsubscribe();
  }
}
