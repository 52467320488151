import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import {
  ICreateMediumCommand,
  IGetSalaryReportQuery,
  ISalaryReportFilter,
} from '@shared/model/atlas.api';

@Injectable({
  providedIn: 'root',
})
export class SalaryReportService {
  private _form: SalaryReportFilterForm;

  constructor(private _fb: FormBuilder) {}

  getForm(): SalaryReportFilterForm {
    if (!this._form) {
      this._form = this._initForm();
    }
    return this._form;
  }

  resetFilters() {
    this._form.controls.createdFrom.patchValue(undefined);
    this._form.controls.createdTo.patchValue(undefined);
    this._form.controls.sources.clear();
    this._form.controls.mediums.clear();
    this._form.controls.agentIds.clear();
  }

  private _initForm() {
    return this._fb.group<ControlsOf<ISalaryReportFilter>>({
      createdFrom: this._fb.control(undefined),
      createdTo: this._fb.control(undefined),
      sources: this._fb.array([]),
      mediums: this._fb.array([]),
      agentIds: this._fb.array([]),
    });
  }

  reset() {
    this._form = undefined;
  }
}

export declare type SalaryReportFilterForm = FormGroup<
  ControlsOf<ISalaryReportFilter>
>;
