import {Injectable} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {ControlsOf} from '@shared/forms/forms-common';
import {
  IAddPartnerDriverCommand,
  ICreatePartnerCommand, IPartnerVehicleDto,
  IPhoneItem,
  PartnerType,
  PartnerVehicleDto,
  PhoneItem,
} from '@shared/model/atlas.api';
import {BehaviorSubject} from 'rxjs';
import { strictEmailValidator } from './emailValidator';
import { Guid } from 'guid-typescript';
import { ContactFormService } from './contact-form.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerFormService {
  constructor(
    private _fb: FormBuilder, 
    private phonesService: ContactFormService) {}

  private partnerForm: CreatePartnerForm;
  private vehicleForm: CreatePartnerVehicleForm;
  private dirverForm: CreateDriverForm;

  getVehicleForm(model?: IPartnerVehicleDto): CreatePartnerVehicleForm {
    if (!this.vehicleForm) {
      this._initPartnerVehicleForm(model);
    }
    return this.vehicleForm;
  }

  resetVehicleForm() {
    this.vehicleForm = undefined;
  }

  getPartnerForm(model?: ICreatePartnerCommand): CreatePartnerForm {
    if (!this.partnerForm) {
      this._initPartnerForm(model);
    }
    return this.partnerForm;
  }

  getDriverFrom(model?: IAddPartnerDriverCommand): CreateDriverForm {
    if (!this.dirverForm) {
      this._initDriverForm(model);
    }
    return this.dirverForm;
  }

  isClientBoxValid() {
    if (!this.partnerForm) {
      return false;
    }
    return (
      this.partnerForm.controls.name.valid &&
      this.partnerForm.controls.alias.valid &&
      this.partnerForm.controls.address.valid &&
      this.partnerForm.controls.taxId.valid &&
      this.partnerForm.controls.nationalNumber.valid &&
      this.partnerForm.controls.contactEmail.valid &&
      this.partnerForm.controls.contactPerson.valid &&
      this.partnerForm.controls.contactNumber.valid &&
      this.partnerForm.controls.activityTypeIds.valid &&
      this.partnerForm.controls.partnerType.valid
    );
  }

  isVehicleBoxValid() {
    if (!this.partnerForm) {
      return false;
    }
    return this.partnerForm.controls.vehicles.valid;
  }

  private _initPartnerForm(model?: ICreatePartnerCommand) {
      this.partnerForm = this._fb.group<ControlsOf<ICreatePartnerCommand>>({
        partnerId: this._fb.control(null),
        name: this._fb.control(model?.name, {
          nonNullable: true,
          validators: [Validators.maxLength(64), Validators.required],
        }),
        address: this._fb.control(model?.address, {
          nonNullable: true,
          validators: [Validators.maxLength(64), Validators.required],
        }),
        alias: this._fb.control(model?.alias, {
          nonNullable: true,
          validators: [Validators.maxLength(64), Validators.required],
        }),
        taxId: this._fb.control(model?.taxId, {
          nonNullable: true,
          validators: [Validators.maxLength(9), Validators.required],
        }),
        nationalNumber: this._fb.control(model?.nationalNumber, {
          nonNullable: true,
          validators: [Validators.maxLength(13), Validators.required],
        }),
        bankAccountNum: this._fb.control(model?.bankAccountNum, {
          nonNullable: true,
          validators: [Validators.maxLength(18)],
        }),
        contactPerson: this._fb.control(model?.contactPerson, {
          nonNullable: true,
          validators: [Validators.maxLength(32), Validators.required],
        }),
        contactEmail: this._fb.control(model?.contactEmail, {
          nonNullable: true,
          validators: [Validators.maxLength(64), Validators.required, Validators.email, strictEmailValidator()],
        }),
        contactNumber: this._fb.control(model?.contactNumber, {
          nonNullable: true,
          validators: [Validators.maxLength(32), Validators.required],
        }),
        partnerType: this._fb.control(model?.partnerType ?? PartnerType.EMPTY, {
          validators: [Validators.pattern(`^(${PartnerType.AUTH_SERVICE}|${PartnerType.BOSCH_CAR}|${PartnerType.REGULAR}|${PartnerType.RESERVE_PARTNER})+$`)],
        }),
        activityTypeIds: this._fb.array<FormControl<string>>([], {
          validators: [Validators.required]
        }),
        vehicles: this._fb.array<FormGroup<ControlsOf<PartnerVehicleDto>>>([]),
      });
  }

  private _initPartnerVehicleForm(model?: IPartnerVehicleDto) {
    this.vehicleForm = this._fb.group<ControlsOf<IPartnerVehicleDto>>({
      id: this._fb.control(model?.id, {
        nonNullable: false,
      }),
      brand: this._fb.control(model?.brand, {
        nonNullable: true,
        validators: [Validators.maxLength(32), Validators.required],
      }),
      type: this._fb.control(model?.type, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(32)],
      }),
      model: this._fb.control(model?.model, {
        nonNullable: true,
        validators: [Validators.maxLength(32), Validators.required],
      }),
      licencePlate: this._fb.control(model?.licencePlate, {
        nonNullable: true,
        validators: [Validators.maxLength(8), Validators.required],
      }),
      maxWeight: this._fb.control(model?.maxWeight, {
        nonNullable: true,
        validators: [Validators.max(99999), Validators.required],
      }),
      seatsNumber: this._fb.control(model?.seatsNumber, {
        nonNullable: true,
        validators: [Validators.max(40), Validators.required],
      }),
      pricePerKm: this._fb.control(model?.pricePerKm, {
        nonNullable: true,
        validators: [Validators.max(99999), Validators.required],
      }),
      longitude: this._fb.control(model?.longitude, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      latitude: this._fb.control(model?.latitude, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      place: this._fb.control(model?.place, {
        nonNullable: true,
        validators: [Validators.maxLength(256), Validators.required],
      }),
    });
  }

  private _initDriverForm(model?: IAddPartnerDriverCommand) {
    this.dirverForm = this._fb.group<ControlsOf<IAddPartnerDriverCommand>>({
      name: this._fb.control(model?.name, {
        nonNullable: false,
        validators: [Validators.maxLength(64), Validators.required],
      }),
      lastName: this._fb.control(model?.lastName, {
        nonNullable: false,
        validators: [Validators.maxLength(64), Validators.required],
      }),
      phones: this._fb.array<FormGroup<ControlsOf<PhoneItem>>>([]),
      email: this._fb.control(model?.email, {
        nonNullable: false,
        validators: [
          Validators.maxLength(64),
          Validators.required,
          Validators.email,
          strictEmailValidator()
        ],
      }),
      sendInvite: this._fb.control(false, {
        nonNullable: false,
        validators: [Validators.required],
      }),
    });
    return this.dirverForm;
  }

  reset() {
    this.partnerForm = undefined;
    this.vehicleForm = undefined;
  }

  addVehicle(model: IPartnerVehicleDto) {
    this.partnerForm.controls.vehicles.push(new FormGroup<ControlsOf<IPartnerVehicleDto>>({
      id: this._fb.control(model?.id, {
        nonNullable: false,
      }),
      brand: this._fb.control(model?.brand, {
        nonNullable: true,
        validators: [Validators.maxLength(32), Validators.required],
      }),
      type: this._fb.control(model?.type, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(32)],
      }),
      model: this._fb.control(model?.model, {
        nonNullable: true,
        validators: [Validators.maxLength(32), Validators.required],
      }),
      licencePlate: this._fb.control(model?.licencePlate, {
        nonNullable: true,
        validators: [Validators.maxLength(8), Validators.required],
      }),
      maxWeight: this._fb.control(model?.maxWeight, {
        nonNullable: true,
        validators: [Validators.max(99999), Validators.required],
      }),
      seatsNumber: this._fb.control(model?.seatsNumber, {
        nonNullable: true,
        validators: [Validators.max(40), Validators.required],
      }),
      pricePerKm: this._fb.control(model?.pricePerKm, {
        nonNullable: true,
        validators: [Validators.max(99999), Validators.required],
      }),
      longitude: this._fb.control(model?.longitude, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      latitude: this._fb.control(model?.latitude, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      place: this._fb.control(model?.place, {
        nonNullable: true,
        validators: [Validators.maxLength(256), Validators.required],
      }),
    }) as any);
  }

  updateVehicle(value: IPartnerVehicleDto) {
    const idx = this.partnerForm.controls.vehicles.value.findIndex(x => x.id === value.id);
    this.partnerForm.controls.vehicles.at(idx).patchValue(value);
  }
}

export declare type CreatePartnerForm = FormGroup<
  ControlsOf<ICreatePartnerCommand>
>;

export declare type CreatePartnerVehicleForm = FormGroup<
  ControlsOf<IPartnerVehicleDto>
>;

export declare type CreateDriverForm = FormGroup<
  ControlsOf<IAddPartnerDriverCommand>
>;
