import {Injectable} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ControlsOf, getLocationForm} from '@shared/forms/forms-common';
import {
  DeliveryType,
  IAddPackageToVehicleCommand,
  ICreateOrderInboundCommand,
  IOrderClient,
  IOrderVehicle,
  IPackageCardItem,
  IRenewOrderInboundCommand,
  PaymentType,
  PhoneItem,
} from '@shared/model/atlas.api';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderFormService {
  renewPackageForm: RenewPackageForm;
  addPackageForm: AddPackageForm;

  constructor(private _fb: FormBuilder) {
  }

  //#region addPackage

  initAddPackageForm(model?: IAddPackageToVehicleCommand) {
    this.addPackageForm = this._fb.group<
      ControlsOf<IAddPackageToVehicleCommand>
    >({
      packageItems: this._fb.array<FormGroup<ControlsOf<IPackageCardItem>>>(
        [],
      ) as FormArray,
      deliveryDate: this._fb.control(model?.deliveryDate),
      deliveryType: this._fb.control(DeliveryType.E_CONTRACT, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      paymentType: this._fb.control(PaymentType.CORVUS_PAY, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      sourceId: this._fb.control(model?.sourceId, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      mediumId: this._fb.control(model?.mediumId, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      isUrgent: this._fb.control(true, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      clientId: this._fb.control(model?.clientId),
      vehicleId: this._fb.control(model?.vehicleId, {
        nonNullable: true,
      }),
      jmbg: this._fb.control(model?.jmbg, {
        nonNullable: true,
        validators: [Validators.maxLength(24)],
      }),
    });
    return this.addPackageForm;
  }

  //#endregion addPackage

  //#region renewPackage

  initRenewPackageForm(model?: IRenewOrderInboundCommand) {
    this.renewPackageForm = this._fb.group<
      ControlsOf<IRenewOrderInboundCommand>
    >({
      packageItems: this._fb.array<FormGroup<ControlsOf<IPackageCardItem>>>(
        [],
      ) as FormArray,
      deliveryDate: this._fb.control(model?.deliveryDate),
      deliveryType: this._fb.control(DeliveryType.E_CONTRACT, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      paymentType: this._fb.control(PaymentType.CORVUS_PAY, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      isUrgent: this._fb.control(true, {
        nonNullable: false,
        validators: [Validators.required],
      }),
      clientId: this._fb.control(model?.clientId),
      mediumId: this._fb.control(model?.mediumId, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      sourceId: this._fb.control(model?.sourceId, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
    return this.renewPackageForm;
  }

  private _renewClientId: string;

  setRenewClientId(clientId: string) {
    this._renewClientId = clientId;
  }

  getRenewClientId(): string {
    return this._renewClientId;
  }

  //#endregion renewPackage

  //#region Order

  private readonly STEP_KEY = 'currentStep';
  private stepSubject = new BehaviorSubject<number>(this.getInitialStep());

  currentStep$ = this.stepSubject.asObservable();

  private getInitialStep(): number {
    const storedStep = localStorage.getItem(this.STEP_KEY);
    return storedStep ? +storedStep : 1;
  }

  setStep(step: number): void {
    localStorage.setItem(this.STEP_KEY, step.toString());
    this.stepSubject.next(step);
  }

  restartStep() {
    localStorage.removeItem(this.STEP_KEY);
    this.stepSubject.next(1);
  }

  resetOrderForm() {
    this._orderForm = undefined;
    this._orderForm?.markAsUntouched()
  }

  setFizickoSelected() {
    this.getClientForm().controls.companyName.removeValidators([
      Validators.required,
    ]);
    this.getClientForm().controls.companyName.reset();
    this.getClientForm().controls.companyName.updateValueAndValidity();

    this.getClientForm().controls.companyAddress.removeValidators([
      Validators.required,
    ]);
    this.getClientForm().controls.companyAddress.reset();
    this.getClientForm().controls.companyAddress.updateValueAndValidity();

    this.getClientForm().controls.nationalNumber.removeValidators([
      Validators.required,
    ]);
    this.getClientForm().controls.nationalNumber.reset();
    this.getClientForm().controls.nationalNumber.updateValueAndValidity();

    this.getClientForm().controls.taxId.removeValidators([Validators.required]);
    this.getClientForm().controls.taxId.reset();
    this.getClientForm().controls.taxId.updateValueAndValidity();

    this.getClientForm().controls.isForCompany.patchValue(false);
  }

  setPravnoSelected() {
    this.getClientForm().controls.companyName.addValidators([
      Validators.required,
    ]);
    this.getClientForm().controls.companyName.updateValueAndValidity();

    this.getClientForm().controls.companyAddress.addValidators([
      Validators.required,
    ]);
    this.getClientForm().controls.companyAddress.updateValueAndValidity();

    this.getClientForm().controls.nationalNumber.addValidators([
      Validators.required,
    ]);
    this.getClientForm().controls.nationalNumber.updateValueAndValidity();

    this.getClientForm().controls.taxId.addValidators([Validators.required]);
    this.getClientForm().controls.taxId.updateValueAndValidity();

    this.getClientForm().controls.isForCompany.patchValue(true);
  }

  get isStep2ForPackageValid(): boolean {
    return (
      this._orderForm.controls.vehicle.valid &&
      this._orderForm.controls.packages.valid
    );
  }

  get isStep2ForPersonValid(): boolean {
    return (
      this._orderForm.controls.packages.valid &&
      this._orderForm.controls.client.controls.nationalNumber.valid
    );
  }

  private _orderForm: OrderForm | undefined;

  get getOrderForm(): OrderForm {
    if (!this._orderForm) {
      this._initOrderForm();
    }
    return this._orderForm;
  }

  private _initOrderForm(model?: ICreateOrderInboundCommand) {
    this._orderForm = this._fb.group<ControlsOf<ICreateOrderInboundCommand>>({
      id: this._fb.control(model?.id, {
        nonNullable: true,
      }),
      client: this.getClientForm(model?.client) as FormGroup,
      isForVehicle: this._fb.control(true, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      vehicle: this.getVehicleForm(model?.vehicle) as FormGroup,
      packages: this._fb.array<FormGroup<ControlsOf<IPackageCardItem>>>([], {
        validators: [Validators.required],
      }) as FormArray,
      towingLocation: getLocationForm(model?.towingLocation) as FormGroup,
      deliveryDate: this._fb.control(model?.deliveryDate),
      deliveryType: this._fb.control(DeliveryType.E_CONTRACT, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      paymentType: this._fb.control(PaymentType.CORVUS_PAY, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      isUrgent: this._fb.control(true, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      setSellerId: this._fb.control(true, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      packageActiveFrom: null,
      packageActiveTo: null
    });
  }

  //#region OrderClientForm
  getClientForm(model?: IOrderClient): OrderClientForm {
    if (!this._orderForm) {
      return this._fb.group<ControlsOf<IOrderClient>>({
        clientId: this._fb.control(model?.clientId),
        isForCompany: this._fb.control(model?.isForCompany),
        name: this._fb.control(model?.name, {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(64)],
        }),
        lastName: this._fb.control(model?.lastName, {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(64)],
        }),
        phones: this._fb.array<FormGroup<ControlsOf<PhoneItem>>>([]),
        email: this._fb.control(model?.email, {
          validators: [Validators.email, Validators.maxLength(64)],
        }),
        companyName: this._fb.control(model?.companyName, {
          nonNullable: true,
          validators: [Validators.maxLength(64), Validators.required],
        }),
        companyAddress: this._fb.control(model?.companyAddress, {
          nonNullable: true,
          validators: [Validators.maxLength(256), Validators.required],
        }),
        nationalNumber: this._fb.control(model?.nationalNumber, {
          nonNullable: true,
          validators: [Validators.maxLength(24), Validators.required],
        }),
        taxId: this._fb.control(model?.taxId, {
          nonNullable: true,
          validators: [Validators.maxLength(32), Validators.required],
        }),
        mediumId: this._fb.control(model?.mediumId, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        sourceId: this._fb.control(model?.sourceId, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        leadId: this._fb.control(model?.leadId),
        recommendationId: this._fb.control(model?.recommendationId),
      });
    }

    return this._orderForm.controls.client as any;
  }

  //#endregion OrderClientForm

  //#region OrderVehicleForm
  getVehicleForm(model?: IOrderVehicle): OrderVehicleForm {
    if (!this._orderForm) {
      return this._fb.group<ControlsOf<IOrderVehicle>>({
        id: this._fb.control(model?.id),
        brand: this._fb.control(model?.brand, {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(32)],
        }),
        type: this._fb.control(model?.type, {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(32)],
        }),
        model: this._fb.control(model?.model, {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(32)],
        }),
        licencePlate: this._fb.control(model?.licencePlate, {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(8)],
        }),
        chassis: this._fb.control(model?.chassis, {
          validators: [Validators.maxLength(24)],
        }),
        productionYear: this._fb.control(model?.productionYear, {
          validators: [Validators.maxLength(4)],
        }),
        color: this._fb.control(model?.color, {
          validators: [Validators.maxLength(64)],
        }),
      });
    }
    return this._orderForm.controls.vehicle as any;
  }

  //#endregion OrderVehicleForm

  //#endregion Order
  setToDraft() {
    this._orderForm.controls.paymentType.patchValue(PaymentType.CORVUS_PAY);
    this._orderForm.controls.paymentType.updateValueAndValidity();
    this._orderForm.controls.deliveryType.patchValue(DeliveryType.E_CONTRACT);
    this._orderForm.controls.deliveryType.updateValueAndValidity();
    this._orderForm.controls.isUrgent.patchValue(true);
    this._orderForm.controls.isUrgent.updateValueAndValidity();
  }

  setToNew() {
    this._orderForm.controls.paymentType.enable();
    this._orderForm.controls.deliveryType.enable();
    this._orderForm.controls.isUrgent.enable();
    this._orderForm.controls.paymentType.patchValue(PaymentType.PAYMENT_SLIP);
  }
}

export declare type RenewPackageForm = FormGroup<
  ControlsOf<IRenewOrderInboundCommand>
>;
export declare type OrderForm = FormGroup<
  ControlsOf<ICreateOrderInboundCommand>
>;
export declare type OrderClientForm = FormGroup<ControlsOf<IOrderClient>>;
export declare type OrderVehicleForm = FormGroup<ControlsOf<IOrderVehicle>>;
export declare type AddPackageForm = FormGroup<
  ControlsOf<IAddPackageToVehicleCommand>
>;
