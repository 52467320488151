import {
  JsonPipe,
  NgIf,
  NgClass,
  NgTemplateOutlet,
  NgForOf,
  NgOptimizedImage,
} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { PackageDetailSidebarComponent } from '@app/features/call-center/package-detail-sidebar/package-detail-sidebar.component';
import { PackageCardListService } from '@app/cc-leader/services/package-card-list.service';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { PageComponent } from '@shared/components/page/page.component';
import {
  CampaignsClient,
  ClientClient,
  ClientWithPackagesFilter,
  ClientWithPackagesItem,
  GetCampaigPackagesTableQuery,
  GetClientWithPackagesStatsQuery,
  GetClientWithPackagesTableQuery,
  IClientWithPackagesStatsResponse,
  IPaginationResponseOfClientWithPackagesTableResponse,
} from '@shared/model/atlas.api';
import { LocationService } from '@shared/services/location.service';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { TableConfig } from '@ui/table/table-common';
import { TableComponent } from '@ui/table/table.component';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SkeletonModule } from 'primeng/skeleton';
import {
  TableModule,
  TableRowCollapseEvent,
  TableRowExpandEvent,
} from 'primeng/table';
import { firstValueFrom, Subscription } from 'rxjs';
import { CheckboxActionDialogComponent } from '@app/features/call-center/checkbox-action-dialog/checkbox-action-dialog.component';
import { licencePlatePipe } from '@shared/pipes/licence-plate.pipe';
import { ToastMessageComponent } from '../../../cc-leader/toast-message/toast-message/toast-message.component';
import { ClientCommentsComponent } from '@app/features/call-center/client-comments/client-comments.component';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { DialogService } from '@ui/dialog/dialog.service';
import { CampaignFormService } from '@app/cc-leader/services/campaign-form.service';
import { OrderFormService } from '@app/cc-leader/services/order-form.service';
import { DropdownInputButtonComponent } from '@shared/components/dropdown-input-button/dropdown-input-button.component';

@Component({
  selector: 'atlas-client-with-packages-table',
  standalone: true,
  imports: [
    PageComponent,
    TableComponent,
    TableModule,
    ButtonComponent,
    ScrollPanelModule,
    JsonPipe,
    NgIf,
    NgClass,
    SkeletonModule,
    ButtonModule,
    NgForOf,
    BadgeModule,
    ButtonModule,
    InlineWrapperComponent,
    DropdownComponent,
    InputAddonComponent,
    InputTextComponent,
    PackageDetailSidebarComponent,
    CheckboxModule,
    FormsModule,
    CheckboxActionDialogComponent,
    ToastMessageComponent,
    NgTemplateOutlet,
    licencePlatePipe,
    NgOptimizedImage,
    DropdownInputButtonComponent,
  ],
  templateUrl: './client-with-packages-table.component.html',
  styleUrl: './client-with-packages-table.component.scss',
})
export class ClientWithPackagesTableComponent implements OnInit, OnDestroy {
  paginatedData: IPaginationResponseOfClientWithPackagesTableResponse;
  stats: IClientWithPackagesStatsResponse;
  private _subs: Subscription = new Subscription();
  protected tableConfig: TableConfig;
  searchOptions: SelectItem[];
  searchOptionCtrl: FormControl;
  searchCtrl: FormControl;
  selectedPackages: ClientWithPackagesItem[] = [];
  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);
  showTable: boolean = true;
  allExpanded = false;
  selectedVehicles: Set<string> = new Set();
  selectedCount: number = 0;
  expandedRows: { [key: string]: boolean } = {};
  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  @Input() showSearchBar: boolean = true;
  @Input() showHeader: boolean = false;
  @Input() pageTitle: string | undefined;
  @Input() pageDescription: string | undefined;
  @Input() actionLabel: string = '';
  @Input() userId: string;
  @Input() displayStatistics: boolean = false;
  @Input() campaignId: string | undefined;
  @Input() canRenewPackage: boolean = true;
  @Input() canMultiselect: boolean = true;

  @Output() clientSelected = new EventEmitter<any>();
  @Output() contractIds = new EventEmitter<string[]>();
  isLoading: boolean = true;

  constructor(
    private client: ClientClient,
    private clientCampaign: CampaignsClient,
    private _locationService: LocationService,
    private service: ClientPackageTableService,
    private _packageCardSerice: PackageCardListService,
    private _dialogService: DialogService,
    private _orderFormService: OrderFormService,
  ) {
    this._subs = new Subscription();
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.paginatedData = {
      data: [],
      currentPage: 0,
      pageSize: 10,
    };
    this.searchOptionCtrl = new FormControl<SearchOptions>(
      SearchOptions.CLIENT_NAME,
    );
    this.stats = {
      numOfPackages: 0,
      numOfClients: 0,
      numOfAgents: 0,
    };
    this.searchCtrl = new FormControl<string>('');
    this.searchOptions = [
      {
        label: SearchOptions.CLIENT_NAME,
        value: SearchOptions.CLIENT_NAME,
      },
      {
        label: SearchOptions.CLIENT_PHONE,
        value: SearchOptions.CLIENT_PHONE,
      },
      {
        label: SearchOptions.LICENCE_PLATE,
        value: SearchOptions.LICENCE_PLATE,
      },
      {
        label: SearchOptions.IS_ACTIVE,
        value: SearchOptions.IS_ACTIVE,
      },
    ];
  }
  ngOnInit(): void {}

  toggleTable() {
    this.showTable = !this.showTable;
  }

  handleCreateClient() {
    this._locationService.routeToCreateClientForm().then();
  }

  onRowExpand(event: TableRowExpandEvent) {
    this.expandedRows[event.data.id] = true;
  }

  onRowCollapse(event: TableRowCollapseEvent) {
    this.expandedRows[event.data.id] = false;
  }

  toggleRow(client: any): void {
    this._orderFormService.setClientId(client.id);
    if (this.expandedRows[client.id]) {
      delete this.expandedRows[client.id];
      this.service.removeClient(client);
    } else {
      this.expandedRows[client.id] = true;
      this.service.addClient(client);
    }
  }

  expandAll(): void {
    
    if (this.allExpanded) {
      this.expandedRows = {};
    } else {
      this.paginatedData.data.forEach((item) => {
        this.expandedRows[item.id] = true;
      });
    }
    this.allExpanded = Object.keys(this.expandedRows).length > 0;
  }

  openOptionsDialog(packageInfo: ClientWithPackagesItem) {
    this._packageCardSerice.setSelectedPackageIdForRenew(packageInfo.packageId);
    this._packageCardSerice.setSelectedClientPackageId(
      packageInfo.clientPackageId,
    );
    this.service.showPreviewSidebar(packageInfo.clientPackageId);
  }

  handleCheckboxChange(event: any, packageData: any) {
    if (event.checked) {
      this.selectedPackages.push(packageData);
      if (packageData.vehicle) {
        this.selectedVehicles.add(packageData.vehicle);
      }
    } else {
      const index = this.selectedPackages.indexOf(packageData);
      if (index > -1) {
        this.selectedPackages.splice(index, 1);
        if (packageData.vehicle) {
          this.selectedVehicles.delete(packageData.vehicle);
        }
      }
    }
    this.selectedCount = this.selectedPackages.length;
  }

  showComments(clientId: string) {
    this.service.clientId = clientId;
    this.dialogConfig.header = 'Komentari';
    this.dialogConfig.customSubmitButton = {
      label: 'Dodaj komentar',
      icon: '',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.hideCancel = true;
    this.dialogConfig.closable = true;

    this.dialogConfig.data = { clientId };
    const dialogRef = this._dialogService.open(
      ClientCommentsComponent,
      this.dialogConfig,
    );

    dialogRef.onClose.subscribe((commentNum: string | undefined) => {
      if (commentNum) {
        const idx = this.paginatedData.data.findIndex((x) => x.id === clientId);
        this.paginatedData.data[idx].commentsNum = commentNum;
      }
    });
  }

  onHover(client: any): void {
    if (this.expandedRows[client.id]) {
      client.hovered = true;
    }
  }

  onLeave(client: any): void {
    client.hovered = false;
  }

  selectAllPackages(client: any) {
    const newSelectedState = !client.selected;

    client.selected = newSelectedState;

    if (!newSelectedState) {
      client.packages.forEach((pkg: any) => {
        pkg.selected = false;
      });

      this.selectedPackages = this.selectedPackages.filter(
        (pkg) => pkg.clientPackageId !== client.id,
      );
      this.selectedPackages = [];

      client.packages.forEach((pkg: any) => {
        if (pkg.vehicle) {
          this.selectedVehicles.delete(pkg.vehicle);
        }
      });
    } else {
      client.packages.forEach((pkg: any) => {
        pkg.selected = true;

        if (!this.selectedPackages.includes(pkg)) {
          this.selectedPackages.push(pkg);
        }

        if (pkg.vehicle) {
          this.selectedVehicles.add(pkg.vehicle);
        }
      });
    }

    this.selectedCount = this.selectedPackages.length;
  }

  get distinctVehicleCount(): number {
    return this.selectedVehicles.size;
  }

  loadLazy(event: any = { first: 0, rows: 10 }) {
    if (event.first === 0) {
      this.paginatedData.currentPage = 0;
    } else {
      this.paginatedData.currentPage = event.first! / event.rows! + 1;
    }
    this.paginatedData.pageSize = event.rows!;
    this.load();
    if (this.displayStatistics) {
      this.loadStats();
    }
  }

  private load() {
    this.isLoading = true;
    this._loadTable().then((res) => {
      this.paginatedData = res.result;
      console.log(this.paginatedData);
      this.isLoading = false;
      this.contractIds.emit(
        res.result.data
          .flatMap((x) => x.packages)
          .map((x) => x.clientPackageId),
      );
    });
  }

  loadStats() {
    this._loadStats().then((res) => {
      this.stats = res.result.value;
      console.log(this.stats);
    });
  }

  private async _loadTable() {
    if (this.campaignId) {
      return await firstValueFrom(
        this.clientCampaign.getCampaigPackagesTable(
          new GetCampaigPackagesTableQuery({
            pageNumber: this.paginatedData.currentPage,
            pageSize: this.paginatedData.pageSize ?? 10,
            campaignId: this.campaignId,
            filters: new ClientWithPackagesFilter({
              clientName:
                this.searchOptionCtrl.value === SearchOptions.CLIENT_NAME
                  ? this.searchCtrl.value
                  : undefined,
              clientPhone:
                this.searchOptionCtrl.value === SearchOptions.CLIENT_PHONE
                  ? this.searchCtrl.value
                  : undefined,
              licencePlate:
                this.searchOptionCtrl.value === SearchOptions.LICENCE_PLATE
                  ? this.searchCtrl.value
                  : undefined,
              isActive:
                this.searchOptionCtrl.value === SearchOptions.IS_ACTIVE
                  ? this.searchCtrl.value
                  : undefined,
              agentId: this.userId,
            }),
          }),
        ),
      );
    }
    return await firstValueFrom(
      this.client.getClientWithPackagesTable(
        new GetClientWithPackagesTableQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize ?? 10,
          filters: new ClientWithPackagesFilter({
            clientName:
              this.searchOptionCtrl.value === SearchOptions.CLIENT_NAME
                ? this.searchCtrl.value
                : undefined,
            clientPhone:
              this.searchOptionCtrl.value === SearchOptions.CLIENT_PHONE
                ? this.searchCtrl.value
                : undefined,
            licencePlate:
              this.searchOptionCtrl.value === SearchOptions.LICENCE_PLATE
                ? this.searchCtrl.value
                : undefined,
            isActive:
              this.searchOptionCtrl.value === SearchOptions.IS_ACTIVE
                ? this.searchCtrl.value
                : undefined,
            agentId: this.userId,
          }),
        }),
      ),
    );
  }

  private async _loadStats() {
    return await firstValueFrom(
      this.client.getClientWithPackagesStats(
        new GetClientWithPackagesStatsQuery({
          filters: new ClientWithPackagesFilter({
            clientName:
              this.searchOptionCtrl.value === SearchOptions.CLIENT_NAME
                ? this.searchCtrl.value
                : undefined,
            clientPhone:
              this.searchOptionCtrl.value === SearchOptions.CLIENT_PHONE
                ? this.searchCtrl.value
                : undefined,
            licencePlate:
              this.searchOptionCtrl.value === SearchOptions.LICENCE_PLATE
                ? this.searchCtrl.value
                : undefined,
            isActive:
              this.searchOptionCtrl.value === SearchOptions.IS_ACTIVE
                ? this.searchCtrl.value
                : undefined,
            agentId: this.userId,
          }),
        }),
      ),
    );
  }

  handleRenewPackage(packageInfo: ClientWithPackagesItem) {
    this._packageCardSerice.setSelectedPackageIdForRenew(packageInfo.packageId);
    this._packageCardSerice.setSelectedClientPackageId(
      packageInfo.clientPackageId,
    );
    this._locationService.routeToRenewPackageForm(packageInfo.clientPackageId);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}

enum SearchOptions {
  CLIENT_NAME = 'Klijent',
  CLIENT_PHONE = 'Telefon',
  LICENCE_PLATE = 'Tablice',
  IS_ACTIVE = 'Aktivnost',
}
