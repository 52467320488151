import { Routes } from '@angular/router';
import { ClientPackageTableComponent } from '@app/sos-agent/packages/client-package-table/client-package-table.component';
import { AuthGuard } from '@shared/services/auth.guard';

export const guestRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'contracts',
        pathMatch: 'full',
      },
      {
        path: 'contracts',
        canActivate: [AuthGuard],
        component: ClientPackageTableComponent,
        pathMatch: 'full',
        data: {
          readonly: true
        }
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'contracts',
  },
];
