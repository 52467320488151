import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PackageLanguage, PackagesClient } from '@shared/model/atlas.api';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { Subscription } from 'rxjs';
import { FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'atlas-package-detail-autocomplete',
  standalone: true,
  imports: [InputSearchComponent, ButtonComponent],
  templateUrl: './package-detail-autocomplete.component.html',
  styleUrl: './package-detail-autocomplete.component.scss',
})
export class PackageDetailAutocompleteComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  newDetail: string;
  shouldResetInput: boolean = false;
  inputValue: string;
  detailSuggesions: string[] = [];

  @Input() detailsFormArray: FormArray<FormControl<string>>;
  @Input() isInEnglish: boolean = false;
  @Input() ctrlPlaceholder = '';

  constructor(private _packageClient: PackagesClient) {}

  ngOnInit() {}

  searchDetails(searchValue?: string) {
    this.inputValue = searchValue;

    this._subs.add(
      this._packageClient
        .getPackageDetails(
          searchValue,
          this.isInEnglish ? PackageLanguage.ENGLISH : PackageLanguage.SERBIAN,
        )
        .subscribe((res) => {
          // Filter out services already included in additionalServices and additionalServicesFromPackage
          this.detailSuggesions = [
            ...res.result.packageDetails.filter(
              (detail) =>
                !this.detailsFormArray.value
                  .map((x) => x.trim())
                  .includes(detail.trim()),
            ),
          ];
        }),
    );
  }

  onSelectChange(item: any) {
    if (!item || !item.value) {
      return;
    }

    this.newDetail = item.value;
  }

  handleAddService() {
    const valueToAdd = this.newDetail ?? this.inputValue;
    if (!valueToAdd || this.detailsFormArray.value.includes(valueToAdd)) {
      return;
    }

    this.detailsFormArray.push(new FormControl<string>(valueToAdd));
    this.detailsFormArray.updateValueAndValidity();
    this.newDetail = undefined;
    this.inputValue = undefined;
    this.shouldResetInput = !this.shouldResetInput;
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
