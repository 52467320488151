@for (package of packages?.controls; let idx = $index; track package.Id) {
  <div class="package-container mt-5">
    <!-- Package Header -->
    <div class="header-container mt-2">
      <span class="title-text">Paket {{ idx + 1 }}</span>
      <i
        *ngIf="package"
        (click)="clearSelectedPackage($event, idx)"
        class="pi pi-trash pointer"
      ></i>
    </div>

    <div
      class="package-header mt-3"
      [ngClass]="{ disabled: dropdownDisabled }"
      (click)="!dropdownDisabled ? togglePackageDropdown(idx) : null"
    >
      <div class="flex align-items-center">
        <img
          ngSrc="assets/images/package.svg"
          width="40"
          height="40"
          alt="user-image"
        />
        <span class="package-text">{{
          package?.value.packageName || "Izaberi paket"
        }}</span>

        <i
          [ngClass]="
            selectedIdx === idx ? 'pi pi-angle-up' : 'pi pi-angle-down'
          "
          class="ml-1"
        ></i>
      </div>

      <div class="package-price">
        {{ package?.value.packagePrice | currencyNumber }}
      </div>
    </div>

    <!-- ======================================================================================================================== -->
    <div 
      *ngIf="selectedIdx === idx"
      class="package-dropdown mt-2">
      <div
        *ngFor="let pkg of allPackages"
        (click)="selectPackage(pkg, idx)"
        class="package-item"
      >
        {{ pkg?.name }} - {{ decimalPipe.transform(pkg?.price, "1.2-2") }} RSD
      </div>
    </div>
    <!-- ======================================================================================================================== -->

    <!-- Broj sasije -->
    <div class="mt-3" *ngIf="package">
      <atlas-input-text
        [formCtrl]="package.controls.chassisNum | formControl"
        ctrlLabel="Broj šasije"
        formCtrlId="broj"
        [tooltip]="'Broj šasije vozila nedostaje za ovaj paket'"
        ctrlPlaceholder="Broj šasije"
        [formCtrlIconRight]="'pi pi-info-circle'"
        (inputLeft)="handleChassisInputChange($event, idx)"
      ></atlas-input-text>
    </div>

    <!-- Cena input -->
    <div class="mt-3" *ngIf="package && showPriceInput">
      <atlas-input-addon
        [formCtrl]="package.controls.packagePrice"
        addonText="RSD"
        ctrlPlaceholder="Cena"
        [isPriceFormat]="true"
      ></atlas-input-addon>
    </div>

    <!-- ActioinPriceDropdown -->
    <div class="dropdown-div mt-3" *ngIf="package">
      <p-dropdown
        [options]="actionPricesDropdown[idx]"
        [optionLabel]="actionPricesDropdown.label"
        [placeholder]="'Izaberite akcijsku cenu'"
        (onChange)="handleActionPriceChange($event, idx, package.value)"
      >
        <ng-template let-selected pTemplate="selectedItem">
          <div class="flex align-items-center gap-2">
            <span class="font-bold text-sm">{{ selected.label }}</span>
            <span *ngIf="!showPriceInput" class="dot">•</span>
            <span class="font-normal text-sm">{{
              decimalPipe.transform(selected.price, "1.2-2")
            }}</span>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="flex align-items-center gap-2">
            <span class="font-bold text-sm">{{ item.label }}</span>
            <span *ngIf="!showPriceInput" class="dot">•</span>
            <span class="font-normal text-sm">{{
              decimalPipe.transform(item.price, "1.2-2")
            }}</span>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
}

<atlas-button
  *ngIf="showAddNewPackageBtn"
  btnIcon="pi pi-plus"
  btnLabel="Dodatni paket"
  btnClass="pl-0"
  (btnClicked)="addEmptyPackageCard()"
></atlas-button>
