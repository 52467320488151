import {Component, OnInit} from '@angular/core';
import {CalendarComponent} from "@ui/calendar/calendar.component";
import {
    ClientWithPackagesTableComponent
} from "@app/features/call-center/client-with-packages-table/client-with-packages-table.component";
import {DividerModule} from "primeng/divider";
import {FormControlPipe} from "@shared/pipes/form-control.pipe";
import {InlineWrapperComponent} from "@ui/inline-wrapper/inline-wrapper.component";
import {InputDescriptionComponent} from "@ui/input-description/input-description/input-description.component";
import {InputTextComponent} from "@ui/input-text/input-text.component";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {RadioButtonModule} from "primeng/radiobutton";
import {ContractCampaignFormService, ContractForm} from "@app/cc-leader/services/constract-campaign-form.service";
import {FormControl} from "@angular/forms";
import {DropdownInputButtonComponent} from "@shared/components/dropdown-input-button/dropdown-input-button.component";
import {DropdownComponent} from "@ui/dropdown/dropdown.component";
import {InputButtonComponent} from "@shared/components/input-button/input-button.component";
import {
    DurationTypeDropdownComponent
} from "@app/features/dropdowns/duration-type-dropdown/duration-type-dropdown.component";
import {MessagesModule} from "primeng/messages";
import {
    SelfRenewingCampaignFormService,
    SelfRenewingForm
} from "@app/cc-leader/services/self-renewing-campaign-form.service";

@Component({
  selector: 'atlas-add-campaign-self-renewing',
  standalone: true,
    imports: [
        CalendarComponent,
        ClientWithPackagesTableComponent,
        DividerModule,
        FormControlPipe,
        InlineWrapperComponent,
        InputDescriptionComponent,
        InputTextComponent,
        NgIf,
        RadioButtonModule,
        DropdownInputButtonComponent,
        DropdownComponent,
        InputButtonComponent,
        DurationTypeDropdownComponent,
        MessagesModule,
        NgOptimizedImage
    ],
  templateUrl: './add-campaign-self-renewing.component.html',
  styleUrl: './add-campaign-self-renewing.component.scss'
})
export class AddCampaignSelfRenewingComponent implements OnInit {
    form: SelfRenewingForm;
    durationLimitCtrl: FormControl<number | null>;

    constructor(
        private _service: SelfRenewingCampaignFormService,
    ) {
        this.form = this._service.getForm();
        this.durationLimitCtrl = new FormControl<number | null>(null);
    }

    ngOnInit(): void {}
}