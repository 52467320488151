<atlas-scrollable-page
  [pageTitle]="clientName"
  (onCancelAction)="routeBack()"
  cancelBtnIcon="back"
  (onSubmitAction)="routeToEditClient()"
  submitBtnIcon="edit"
  submitBtnLabel="Izmeni"
  [showBadge]="true"
  [badgeText]="clientType"
>
<ng-template #content>
    <p-tabView
      [(activeIndex)]="activeIndex!"
      (activeIndexChange)="handleTabChange($event)"
    >
      <p-tabPanel header="Paketi">
        <atlas-package-tab-client></atlas-package-tab-client>
      </p-tabPanel>
      <p-tabPanel header="Asistencije">
        <atlas-assistance-table
          [clientId]="clientId"
        ></atlas-assistance-table>
      </p-tabPanel>
      <p-tabPanel header="Detalji">
        <atlas-details-tab-client></atlas-details-tab-client>
      </p-tabPanel>
      <p-tabPanel header="Statistika">
        <atlas-statistics-tab-client></atlas-statistics-tab-client>
      </p-tabPanel>
    </p-tabView>
</ng-template>
</atlas-scrollable-page>
