import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { LeadsStatusDropdownComponent } from '@app/features/dropdowns/leads-status-dropdown/leads-status-dropdown/leads-status-dropdown.component';
import { InputDescriptionComponent } from '@ui/input-description/input-description/input-description.component';
import { FormControl } from '@angular/forms';
import { DialogConfig } from '@ui/dialog/dialog-config';
import {
  CampaignLeadStatus,
  CampaignsClient,
  RecommendationsClient,
  UpdateLeadStatusCommandBody,
  UpdateStatusCommandBody,
} from '@shared/model/atlas.api';
import { Subscription } from 'rxjs';
import { DialogRef } from '@ui/dialog/dialog-ref';
import {
  CampaignFormService,
  ChangeStatusForm,
} from '@app/cc-leader/services/campaign-form.service';
import { AlertService } from '@shared/services/alert.service';
import { NgIf } from '@angular/common';
import { CalendarComponent } from '@ui/calendar/calendar.component';
import { UserService } from '@shared/services/user.service';
import { AgentAutocompleteCampaignComponent } from '@app/cc-leader/campaigns/agent-autocomplete-campaign/agent-autocomplete-campaign.component';
import { markFormGroupTouched } from '@shared/forms/forms-common';

@Component({
  selector: 'atlas-change-status',
  standalone: true,
  imports: [
    LeadsStatusDropdownComponent,
    InputDescriptionComponent,
    NgIf,
    CalendarComponent,
    AgentAutocompleteCampaignComponent,
  ],
  templateUrl: './change-status.component.html',
  styleUrl: './change-status.component.scss',
})
export class ChangeStatusComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('newStatusDropdown') newStatusDropdown: any;
  private _subs: Subscription = new Subscription();
  defaultControl = new FormControl('');
  id: string;
  isRecommendation: boolean = false;
  previewData: any;
  selectedAgentId: string;
  currentStatus: number;
  isCallAgainStatus: boolean = false;
  userName: string;
  userId: string;

  constructor(
    private _dialogConfig: DialogConfig,
    private _client: CampaignsClient,
    private _dialogRef: DialogRef,
    protected service: CampaignFormService,
    private _alertService: AlertService,
    private _recommendationClient: RecommendationsClient,
    private userService: UserService,
  ) {
    if (!this._dialogConfig.data?.id) {
      alert('No data id');
    }

    this.userName = this.userService.getUserData.fullName;
    this.userId = this.userService.getUserData.id;
    this._subs = new Subscription();

    this.id = this._dialogConfig.data.id;
    this.isRecommendation = this._dialogConfig.data.isRecommendation;
    this.currentStatus = this._dialogConfig.data.currentStatus;
  }

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleSubmit();
      }),
    );
  }

  onAgentSelected(agentId: any) {
    this.selectedAgentId = agentId.id;
  }

  ngAfterViewInit(): void {
    if (this.newStatusDropdown?.statusCtrl) {
      this._subs.add(
        this.newStatusDropdown?.statusCtrl?.valueChanges.subscribe(
          (selectedStatus: any) => {
            this.checkIfCallAgainStatus(selectedStatus?.status);
          },
        ),
      );
    }
  }

  checkIfCallAgainStatus(status: number) {
    const callAgainStatuses = [
      CampaignLeadStatus.CALL_AGAIN_1,
      CampaignLeadStatus.CALL_AGAIN_2,
      CampaignLeadStatus.CALL_AGAIN_3,
    ];
    this.isCallAgainStatus = callAgainStatuses.includes(status);
    if (this.isCallAgainStatus) {
      this.service.setCallAgainValidators();
    } else {
      this.service.removeCallAgainValidators();
    }
  }

  handleSubmit() {
    if (this.service.getStatusForm().invalid) {
      markFormGroupTouched(this.service.getStatusForm());
      return;
    }

    const request = new UpdateLeadStatusCommandBody({
      status: this.newStatusDropdown.statusCtrl.value.status,
      agentId: this.selectedAgentId ?? null,
      dateTime: this.service.getStatusForm().value.dateTime ?? null,
      comment: this.service.getStatusForm().value.comment ?? null,
    });

    if (this.isRecommendation) {
      this._handleRecommendationStatus(request);
    } else {
      this._handleLeadStatus(request);
    }
  }

  _handleLeadStatus(request: UpdateLeadStatusCommandBody) {
    this._subs.add(
      this._client.updateLeadStatus(this.id, request).subscribe((res) => {
        this._alertService.addSuccessMsg(res.result);
        this._dialogRef.close({
          success: true,
          newStatus: {
            value: this.newStatusDropdown.statusCtrl.value.status,
            label: this.newStatusDropdown.statusCtrl.value.label,
          },
        });
      }),
    );
  }

  _handleRecommendationStatus(request: UpdateLeadStatusCommandBody) {
    this._subs.add(
      this._recommendationClient
        .updateStatus(this.id, request)
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this._dialogRef.close({
            success: true,
            newStatus: {
              value: this.newStatusDropdown.statusCtrl.value.status,
              label: this.newStatusDropdown.statusCtrl.value.label,
            },
          });
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    this.service.reset();
  }
}
