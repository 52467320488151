import { NavigationExtras, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationCcService {
  private readonly _rolePrefix = 'cc';

  constructor(private _router: Router) {}

  private _routeToUrl(url: string, navigationData?: NavigationExtras) {
    this._router.navigate([url], navigationData).then();
  }

  routeToHomePage() {
    this._routeToUrl(`/${this._rolePrefix}/clients`);
  }

  routeToScheduledCalls() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        this._rolePrefix,
        'campaigns',
        'scheduled-calls',
      ]),
    );

    return this._router.navigateByUrl(url);
  }

  routeToStartCampaign(id: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        this._rolePrefix,
        'campaigns',
        'start-campaign',
        id,
      ]),
    );
    return this._routeToUrl(url);
  }
}
