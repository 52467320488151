<div class="flex flex-column gap-3">
  <atlas-input-text
    ctrlLabel="Naziv"
    [formCtrl]="fs.getForm().controls.name"
    formCtrlId="name"
  >
  </atlas-input-text>

  <atlas-input-text
    ctrlLabel="Provizija prodaje %"
    [formCtrl]="fs.getForm().controls.feePercent"
    isPriceFormat="true"
    formCtrlId="feePercent"
  >
  </atlas-input-text>
</div>
