import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import {
  CampaignLeadStatus,
  GetRecommendationsQuery,
  IPaginationResponseOfRecommendationResponse,
  RecommendationsClient,
} from '@shared/model/atlas.api';
import { LocationService } from '@shared/services/location.service';
import { ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { ActionMode, TableConfig } from '@ui/table/table-common';
import { TableComponent } from '@ui/table/table.component';
import { SelectItem } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table/table.interface';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { firstValueFrom, Subscription } from 'rxjs';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { RecommendationFormService } from '@app/features/recommendations/add-recommendation-form/recommendation-form.service';
import { AlertService } from '@shared/services/alert.service';
import { AddRecommendationFormComponent } from '@app/features/recommendations/add-recommendation-form/add-recommendation-form.component';
import { DialogService } from '@ui/dialog/dialog.service';
import { RecommendationCommentsComponent } from '@app/features/recommendations/recommendation-comments/recommendation-comments.component';
import { TableServiceLocal } from '@ui/table/table-service-local.service';
import { DropdownInputButtonComponent } from '@shared/components/dropdown-input-button/dropdown-input-button.component';
import { NgIf } from '@angular/common';
import {
  AgentCcRoleStr,
  B2BAgentRoleStr,
  LeaderCcRoleStr,
  UserService,
} from '@shared/services/user.service';
import { ChangeStatusComponent } from '@app/cc-agent/campaigns/change-status/change-status.component';
import { TabViewModule } from 'primeng/tabview';
import { ActiveFiltersComponent } from '@app/features/active-filters/active-filters/active-filters.component';
import { ActiveFiltersService } from '@app/features/active-filters/active-filters-service';

@Component({
  selector: 'atlas-recommendation-table',
  standalone: true,
  imports: [
    ButtonModule,
    DropdownModule,
    FormsModule,
    TableComponent,
    DropdownInputButtonComponent,
    NgIf,
    TabViewModule,
    ActiveFiltersComponent,
  ],
  templateUrl: './recommendation-table.component.html',
  styleUrl: './recommendation-table.component.scss',
})
export class RecommendationTableComponent
  implements OnInit, OnDestroy, OnChanges
{
  private _subs: Subscription = new Subscription();

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  searchOptions: SelectItem[];
  searchOptionCtrl: FormControl;
  searchCtrl = new FormControl('');
  activeIndex: number = 0;
  statusSearch: CampaignLeadStatus[] = [];
  private initSearch: boolean = false;
  public paginatedData: IPaginationResponseOfRecommendationResponse;
  protected tableConfig: TableConfig;

  @Input() sourceId: string | undefined;
  @Input() leadSourceId: string | undefined;
  @Input() sourceRecommendationId: string | undefined;
  @Input() showFilters: boolean = true;
  @Input() showOnlyOwnded: boolean = false;

  commentContTest: string;
  suggestCount: number = 0;

  constructor(
    private _recomendClinent: RecommendationsClient,
    private _dialogService: DialogService,
    private _formService: RecommendationFormService,
    private _locationService: LocationService,
    private _tableService: TableServiceLocal,
    private _userService: UserService,
    private _activeFiltersService: ActiveFiltersService,
  ) {
    this.tableConfig = new TableConfig({
      columns: [],
      rowsPerPage: 10,
    });

    this.paginatedData = {
      data: [],
      currentPage: 0,
    };

    this.searchOptionCtrl = new FormControl<SearchOptions>(
      SearchOptions.RECOMMENDATION,
    );

    this.searchOptions = [
      {
        label: SearchOptions.RECOMMENDATION,
        value: SearchOptions.RECOMMENDATION,
      },
      {
        label: SearchOptions.PHONE,
        value: SearchOptions.PHONE,
      },
      {
        label: SearchOptions.SOURCE,
        value: SearchOptions.SOURCE,
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sourceRecommendationId']) {
      this.sourceRecommendationId =
        changes['sourceRecommendationId'].currentValue;
      this.load();
    }
  }

  ngOnInit(): void {
    this.setTableConfig().then();

    this._subs.add(
      this._formService.getShowAddDialog.subscribe((showDialog) => {
        if (showDialog) {
          this._handleAddNew();
        }
      }),
    );
    this.searchCtrl.valueChanges.subscribe((value) => {
      if (!value) {
        this._activeFiltersService.removeFilter('search');
      }
    });

    this._subs = this._activeFiltersService.activeFilters$.subscribe((filters) => {
      const hasSearchFilter = filters.some((f) => f.key === 'search');
      if (this.initSearch && !hasSearchFilter) {
        this.searchCtrl.setValue('', { emitEvent: false });
        this.load();
      }
      this.initSearch = hasSearchFilter;
    });
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez preporuka',
        description: 'Ovde će biti prikazana tabela preporuka',
        btnLabel: 'Dodaj preporuku',
        action: () => this._handleAddNew(),
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.load();
      },
      columns: [
        {
          field: 'fullName',
          header: 'Kontakt',
          type: 'text',
          columns: [
            {
              field: 'contact',
              type: 'text',
            },
          ],
        },
        {
          field: 'source',
          header: 'Izvor preporuke',
          type: 'text',
          isVisible: !this.sourceId && !this.leadSourceId,
        },
        {
          field: 'city',
          header: 'Grad',
          type: 'text',
        },
        {
          field: 'createdOn',
          header: 'Dodata',
          type: 'text',
          isVisible: !this.sourceId && !this.leadSourceId,
        },
        {
          header: 'Status',
          field: 'status',
          type: 'badge',
          badgeClass: 'lead-status-',
          styleClass: 'w-12rem',
          isVisible: !this.sourceId && !this.leadSourceId,
        },
        {
          header: 'Komentar',
          field: 'comment',
          type: 'text',
          tooltipField: 'comment',
          styleClass: 'max-w-10rem',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Kreiraj ugovor',
          label: 'Napravi ugovor',
          svgName: 'recomend-btn',
          actionClass: 'p-2 gap-2 w-10rem',
          actionSeverity: ButtonSeverity.SECONDARY_GREY,
          actionStyle: ButtonStyle.OUTLINED,
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData = this._getPaginatedItem(rowIdx);
            return rowData.status.value !== CampaignLeadStatus.PURCHASED;
          },
          callback: (rowIdx: number) => {
            this.handleCreateOrder(rowIdx);
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Promena statusa',
          label: 'Promena statusa',
          svgName: 'loading-02',
          actionClass: 'p-2 gap-2 w-11rem',
          actionSeverity: ButtonSeverity.SECONDARY_GREY,
          shouldDisplayByCondition: () =>
            !this.sourceId &&
            !this.leadSourceId &&
            !this.sourceRecommendationId,
          callback: (rowIdx: number) => {
            const rowData = this._getPaginatedItem(rowIdx);
            this._changeStatus(rowData.id, rowData.status);
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: this.commentContTest,
          actionClass: 'p-2 gap-2',

          svgName: 'comment-Icon',
          actionSeverity: ButtonSeverity.SECONDARY_GREY,
          shouldDisplayByCondition: () => true,
          callback: (rowIdx: number) => {
            const rowData = this._getPaginatedItem(rowIdx);
            this.handleShowComments(rowData.id);
          },
          onHover: (rowIdx: number) => {
            const rowData = this._getPaginatedItem(rowIdx);
            return rowData.commentCount.toString();
          },
        },
      ],
    });
  }

  handleShowComments(reccomendationId: string) {
    const dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);

    dialogConfig.header = 'Komentari';
    dialogConfig.customSubmitButton = {
      label: 'Dodaj komentar',
      icon: '',
    };
    dialogConfig.maximisable = false;
    dialogConfig.hideCancel = true;
    dialogConfig.closable = true;

    dialogConfig.data = { reccomendationId };

    const dialogRef = this._dialogService.open(
      RecommendationCommentsComponent,
      dialogConfig,
    );

    this._subs.add(
      dialogRef.onClose.subscribe((commentNum) => {
        if (commentNum) {
          const idx = this.paginatedData.data.findIndex(
            (x) => x.id === reccomendationId,
          );
          this.paginatedData.data[idx].commentCount = commentNum;
        }
      }),
    );
  }

  handleTabChange(idx: number) {
    switch (idx) {
      case 0:
        this.statusSearch = [];
        break;
      case 1:
        this.statusSearch = [CampaignLeadStatus.DEFAULT];
        break;
      case 2:
        this.statusSearch = [
          CampaignLeadStatus.DEFAULT,
          CampaignLeadStatus.CALL_AGAIN_1,
          CampaignLeadStatus.CALL_AGAIN_2,
          CampaignLeadStatus.NOT_ANSWERING_1,
          CampaignLeadStatus.NOT_ANSWERING_2,
        ];
        break;
    }
    this.load();
  }

  handleCreateOrder(idx: number) {
    this._setData(idx);
    this._locationService
      .routeToCreateClient(
        this._userService.getRoleRoutePrefix(
          AgentCcRoleStr,
          B2BAgentRoleStr,
          LeaderCcRoleStr,
        ),
      )
      .then();
  }

  private _changeStatus(rowId: any, status: any) {
    const id = rowId.toString();
    const dialogConfig: DialogConfig = new DialogConfig(
      DialogSize.SMALL_MEDIUM,
    );

    dialogConfig.header = 'Promena statusa';
    dialogConfig.customSubmitButton = {
      label: 'Sačuvaj',
      icon: '',
    };
    dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
      style: 'max-width: 100px',
    };
    dialogConfig.maximisable = false;
    dialogConfig.closable = true;
    dialogConfig.data = {
      id: id,
      currentStatus: status.value,
      isRecommendation: true,
    };
    const dialogRef = this._dialogService.open(
      ChangeStatusComponent,
      dialogConfig,
    );

    this._subs.add(
      dialogRef.onClose.subscribe((result) => {
        if (result?.success) {
          this.load();
        }
      }),
    );
  }

  private _setData(idx: number) {
    const rowData = this._getPaginatedItem(idx);
    const clientname = rowData.fullName.split(' ')[0];
    const clientLastName = rowData.fullName.split(' ')[1];

    this._formService.setDataTransfer = {
      name: clientname,
      lastName: clientLastName,
      phoneNum: rowData.contact,
      id: rowData.id,
    };
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  private _handleAddNew() {
    const dialogConfig: DialogConfig = new DialogConfig(
      DialogSize.MEDIUM_SMALL,
    );

    dialogConfig.header = 'Dodaj preporuku';
    dialogConfig.customSubmitButton = {
      label: 'Dodaj',
      icon: '',
    };
    dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    dialogConfig.maximisable = false;
    dialogConfig.closable = true;

    if (this.sourceId) {
      this._formService.setSourceId = this.sourceId;
    }

    if (this.leadSourceId) {
      this._formService.setSourceId = this.leadSourceId;
      this._formService.setIsForLead = true;
    }

    if (this.sourceRecommendationId) {
      this._formService.setSourceRecommendationId = this.sourceRecommendationId;
    }

    const dialogRef = this._dialogService.open(
      AddRecommendationFormComponent,
      dialogConfig,
    );

    this._subs.add(
      dialogRef.onClose.subscribe((res) => {
        this._formService.setShowAddDialog = false;
        if (res) {
          this.load();
        }
      }),
    );
  }

  load() {
    this._tableService.setIsLoading();
    if (this.searchCtrl.value) {
      this._activeFiltersService.setFilter(
        'search',
        `Filter: ${this.searchCtrl.value}`,
      );
    }
    this._getList().then((res) => {
      this.paginatedData = res.result;
      this.suggestCount = res.result.totalCount;
      this._tableService.resetIsLoading();
    });
  }

  private async _getList() {
    return await firstValueFrom(
      this._recomendClinent.getRecommendations(
        new GetRecommendationsQuery({
          contactName:
            this.searchOptionCtrl.value == SearchOptions.RECOMMENDATION
              ? this.searchCtrl.value
              : undefined,
          contactPhone:
            this.searchOptionCtrl.value == SearchOptions.PHONE
              ? this.searchCtrl.value
              : undefined,
          source:
            this.searchOptionCtrl.value == SearchOptions.SOURCE
              ? this.searchCtrl.value
              : undefined,
          statuses: this.statusSearch,
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize,
          sourceId: this.sourceId,
          leadSourceId: this.leadSourceId,
          recommendationSourceId: this.sourceRecommendationId,
          onlyOwned: this.showOnlyOwnded,
        }),
      ),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}

enum SearchOptions {
  RECOMMENDATION = 'Ime kontakta',
  PHONE = 'Telefon',
  SOURCE = 'Izvor',
}
