import { NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MediumsDropdownComponent } from '@shared/components/mediums-dropdown/mediums-dropdown.component';
import { SourcesDropdownComponent } from '@shared/components/sources-dropdown/sources-dropdown.component';
import { ControlsOf, markFormGroupTouched } from '@shared/forms/forms-common';
import {
  ClientClient,
  DeliveryType,
  GetPackageForRenewQuery,
  IPackageCard,
  OrdersClient,
  PackagesForRenewResponse,
  PaymentType,
  RenewOrderInboundCommand,
  SwaggerResult,
} from '@shared/model/atlas.api';
import { CurrencyNumberPipe } from '@shared/pipes/currency-number.pipe';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { AlertService } from '@shared/services/alert.service';
import { LocationService } from '@shared/services/location.service';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {OrderFormService, RenewPackageForm} from "@app/cc-leader/services/order-form.service";
import {PackageCardListService} from "@app/cc-leader/services/package-card-list.service";
import {PackageCardListComponent} from "@app/cc-leader/package-card-list/package-card-list.component";
import {ScrollablePageComponent} from "@shared/components/scrollable-page/scrollable-page.component";

@Component({
  selector: 'atlas-renew-package-form',
  standalone: true,
  imports: [
    ScrollPanelModule,
    DividerModule,
    NgStyle,
    ButtonComponent,
    PackageCardListComponent,
    DropdownComponent,
    NgFor,
    NgIf,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    FormControlPipe,
    CurrencyNumberPipe,
    MediumsDropdownComponent,
    SourcesDropdownComponent,
    ScrollablePageComponent
],
  templateUrl: './renew-package-form.component.html',
  styleUrl: './renew-package-form.component.scss',
})
export class RenewPackageFormComponent implements OnDestroy {
  private _subs: Subscription = new Subscription();

  renewForm: RenewPackageForm;
  packages: FormArray<FormGroup<ControlsOf<IPackageCard>>>;
  packagesSum: number = 0;
  clientPackageId: string = '';

  responsePackages: any[] = [];

  paymentType: any = [
    { label: 'Corvus Pay', value: PaymentType.CORVUS_PAY },
    { label: 'Gotovina', value: PaymentType.CASH },
    { label: 'Uplatnica', value: PaymentType.PAYMENT_SLIP },
  ];
  deliveryType: any = [
    { label: 'eUgovor', value: DeliveryType.E_CONTRACT },
    { label: 'Lično preuzimanje', value: DeliveryType.PICK_UP },
    { label: 'Bex', value: DeliveryType.BEX },
    { label: 'Bex besplatna', value: DeliveryType.BEX_FREE },
    { label: 'Bex bez otkupa', value: DeliveryType.BEX_WITHOUT_DEPOSIT },
    { label: 'Kurir NS', value: DeliveryType.COURIER_NS },
    { label: 'Promo-sezonac', value: DeliveryType.PROMO_SEASONAC },
  ];
  activationType: any = [
    {
      label:
        'Aktivacija odmah (paket se aktivira odmah nakon evidentiranja uplate)',
      value: true,
    },
    {
      label: 'Standardna aktivacija (7 dana od evidentiranja uplate)',
      value: false,
    },
  ];

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  constructor(
    private _client: OrdersClient,
    private _orderFormService: OrderFormService,
    private _packageCardService: PackageCardListService,
    private _locationService: LocationService,
    private _alertService: AlertService,
    private acivatedRoute: ActivatedRoute,
    private _clientClient: ClientClient
  ) {
    this.renewForm = this._orderFormService.initRenewPackageForm();

    this._subs.add(
      this._packageCardService.packages$.subscribe((x) => {
        this.packages = x;
      })
    )

    this.acivatedRoute.params.subscribe(params => {
      this.clientPackageId = params['id'];
    })

    if (this.clientPackageId) {
      this._loadSinglePackage()
    }
  }

  private _loadSinglePackage() {
    this._subs.add(
        this._clientClient.getForRenew(
          new GetPackageForRenewQuery({
            cpIds: [this.clientPackageId],
          })
        ).subscribe((res: SwaggerResult<PackagesForRenewResponse>) => {
          this.setRenewForm(res);
        }))
  }

  private setRenewForm(res: SwaggerResult<PackagesForRenewResponse>) {
    this.renewForm.patchValue(res.result)

    if (this.renewForm.value.deliveryType === DeliveryType.EMPTY) {
      this.renewForm.controls.deliveryType.patchValue(DeliveryType.BEX)
    }

    if (this.renewForm.value.paymentType === PaymentType.EMPTY) {
      this.renewForm.controls.paymentType.patchValue(PaymentType.CASH)
    }

    // For setting action prices dropdown
    this._packageCardService.setSelectedPackageIdForRenew(res.result.clientPackages[0].packageId)
    this._packageCardService.clearAllPackages();

    res.result.clientPackages.map((packageItem) => {
      this._packageCardService.addPackage(packageItem);
    });
  }

  handleCancelForm() {
    this._locationService.routeToClientsCCTable();
  }

  handleSubmitForm() {
    this._prepareFormForSingle();

    if (!this.renewForm.valid) {
      markFormGroupTouched(this.renewForm);
      return this._alertService.addWarnMsg('Morate popuniti sva polja');
    }

    this._subs.add(
      this._client
        .renewOrder(this.renewForm.value as RenewOrderInboundCommand)
        .subscribe((x) => {
          this._alertService.addSuccessMsg(x.result);
          this._locationService.routeToClientsCCTable();
        }),
    );
  }

  private _prepareFormForSingle() {
    let clientId = this._orderFormService.selectedClientId$.value;
    this.renewForm.controls.clientId?.patchValue(clientId);
    this.packages.value[0].clientPackageId = this._packageCardService.getSelectedClientPackageId();

    if (!this.renewForm.valid) {
      return
    }

    this._packageCardService.getPackages().value
      .forEach((x: IPackageCard) => {
        this.renewForm.controls.packageItems.push(
        this._packageCardService.initPackageCardForm(x));
      })
  }

  ngOnDestroy(): void {
    this._packageCardService.clearAllPackages()
    this._subs.unsubscribe();
  }
}
