<p-sidebar
  [(visible)]="showSidebar"
  position="right"
  styleClass="w-27rem right-rounded"
>
  <ng-template pTemplate="header">
    <div class="text-2xl font-semibold">Filteri</div>
  </ng-template>

  <div class="flex flex-column justify-content-between h-full">
    <div>
      <div class="flex flex-column gap-3">
        <atlas-calendar
          formCtrlId="start"
          ctrlLabel="Datum aktivacije"
          [formCtrl]="form.controls.start | formControl"
          [hideErrors]="true"
        ></atlas-calendar>
        <atlas-calendar
          formCtrlId="start-from"
          ctrlLabel="Datum aktivacije počev od"
          [formCtrl]="form.controls.startFrom | formControl"
          [hideErrors]="true"
        ></atlas-calendar>
        <atlas-calendar
          formCtrlId="created"
          ctrlLabel="Datum kupovine"
          [formCtrl]="form.controls.createdOn | formControl"
          [hideErrors]="true"
        ></atlas-calendar>
        <atlas-calendar
          formCtrlId="created-from"
          ctrlLabel="Datum kupovine počev od"
          [formCtrl]="form.controls.createdOnFrom | formControl"
          [hideErrors]="true"
        ></atlas-calendar>
        <atlas-calendar
          formCtrlId="created-to"
          ctrlLabel="Datum kupovine završno sa"
          [formCtrl]="form.controls.createdOnTo | formControl"
          [hideErrors]="true"
        ></atlas-calendar>
        <atlas-input-text
          ctrlLabel="Promo kod"
          formCtrlId="promo-code"
          [formCtrl]="form.controls.promoCode"
          [hideErrors]="true"
        ></atlas-input-text>
        <atlas-payment-status-multiselect
          [statusesCtrl]="form.controls.paymentStatuses"
        >
        </atlas-payment-status-multiselect>
        <atlas-payment-type-multiselect
          [typesCtrl]="form.controls.paymentTypes"
        >
        </atlas-payment-type-multiselect>
        <atlas-medium-multiselect [mediumsCtrl]="form.controls.mediums">
        </atlas-medium-multiselect>
        <atlas-sources-multiselect
          [sourceCtrl]="form.controls.sources"
        ></atlas-sources-multiselect>
        <atlas-agent-multiselect
          [agentsCtrl]="form.controls.agents"
        ></atlas-agent-multiselect>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex gap-3 border-top-1 border-gray-200 pt-3 w-full">
      <div class="flex-1">
        <atlas-button
          btnLabel="Poništi"
          btnClass="w-full"
          btnIcon="pi pi-times-circle"
          [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
          [btnStyle]="ButtonStyle.BASIC"
          (btnClicked)="handleResetFilters()"
        >
        </atlas-button>
      </div>
      <div class="flex-1">
        <atlas-button
          btnLabel="Primeni"
          btnClass="w-full flex-1"
          btnIcon="pi pi-check-circle"
          [btnSeverity]="ButtonSeverity.SECONDARY"
          [btnStyle]="ButtonStyle.BASIC"
          (btnClicked)="handleApplyFilters()"
        >
        </atlas-button>
      </div>
    </div>
  </ng-template>
</p-sidebar>
