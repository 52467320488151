<ng-container *ngIf="agents.length > 0">
  <ng-container *ngIf="agents.length > 5; else simpleAgentList">
    <div class="agent-images cursor-help">
      <img
        *ngFor="let agent of agents.slice(0, 5); let idx = index"
        [ngSrc]="agent.imageUrl ?? '../../../../assets/images/client-icon.svg'"
        alt="User Icon"
        width="24"
        height="24"
        class="border-1 border-round-2xl border-gray-300"
        [pTooltip]="agent.name + ' (' + agent.email + ')'"
      />
      <div class="agent-badge border-1 border-round-2xl border-gray-300">
        +{{ agents.length - 4 }}
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="agents.length === 0">
    Bez agenata
</ng-container>
<ng-template #simpleAgentList>
  <div class="agent-images">
    <div *ngFor="let agent of agents; let idx = index" class="cursor-help">
      <img
        [ngSrc]="agent.imageUrl ?? '../../../../assets/images/client-icon.svg'"
        alt="User Icon"
        width="24"
        height="24"
        class="border-1 border-round-2xl border-gray-300"
        [pTooltip]="agent.name + ' (' + agent.email + ')'"
      />
    </div>
  </div>
</ng-template>
