import { Injectable } from '@angular/core';
import {BehaviorSubject, delay} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TableServiceLocal {

  private isLoading$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  setIsLoading() {
    this.isLoading$.next(true);
  }

  resetIsLoading() {
    setTimeout(() => {
      this.isLoading$.next(false);
    }, 300);
  }

  getIsLoading() {
    return this.isLoading$.asObservable();
  }
}
