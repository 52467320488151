import { Routes } from '@angular/router';
import { administratorRoutes } from '@app/administrator/administrator.routes';
import { sosAgentRoutes } from '@app/sos-agent/sos-agent.routes';
import { CanMatchAdmin } from '@app/can-match/can-match-admin';
import { CanMatchSosAgent } from '@app/can-match/can-match-sos-agent';
import { financeAdminRoutes } from '@app/finance-administrator/finance-admin.routes';
import { CanMatchFinanceAdmin } from '@app/can-match/can-match-finance-admin';
import { ccLeaderRoutes } from '@app/cc-leader/cc-leader.routes';
import { CanMatchCcAgent } from '@app/can-match/can-match-cc-agent';
import { CanMatchGuest } from '@app/can-match/can-match-guest';
import { guestRoutes } from '@app/guest/guest.routes';
import { CanMatchCcLeader } from '@app/can-match/can-match-cc-leader';
import { ccAgentRoutes } from '@app/cc-agent/cc-agent.routes';
import { b2bAgentRoutes } from '@app/b2b-agent/b2b-agent.routes';
import { CanMatchB2bAgent } from '@app/can-match/can-match-b2b-agent';

export const appLayoutRoutes: Routes = [
  {
    /** Administrator role */
    path: 'admin',
    canMatch: [CanMatchAdmin],
    children: [...administratorRoutes],
  },
  {
    /** SOS Agent role */
    path: 'sos',
    data: {
      breadcrumb: 'sos',
    },
    canMatch: [CanMatchSosAgent],
    children: [...sosAgentRoutes],
  },
  {
    /** Finance Admin role */
    path: 'finance',
    data: {
      breadcrumb: 'finance',
    },
    canMatch: [CanMatchFinanceAdmin],
    children: [...financeAdminRoutes],
  },
  {
    /** CC Leader role */
    path: 'leader',
    data: {
      breadcrumb: 'cc leader',
      isLeader: true,
    },
    canMatch: [CanMatchCcLeader],
    children: [...ccLeaderRoutes],
  },
  {
    /** CC agent role */
    path: 'cc',
    data: {
      breadcrumb: 'cc agent',
    },
    canMatch: [CanMatchCcAgent],
    children: [...ccAgentRoutes],
  },
  {
    /** B2B agent role */
    path: 'b2b',
    data: {
      breadcrumb: 'b2b agent',
    },
    canMatch: [CanMatchB2bAgent],
    children: [...b2bAgentRoutes],
  },
  {
    /** Guest role */
    path: 'guest',
    data: {
      breadcrumb: 'guest',
    },
    canMatch: [CanMatchGuest],
    children: [...guestRoutes],
  },
];
