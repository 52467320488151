import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import { ICreateMediumCommand } from '@shared/model/atlas.api';

@Injectable({
  providedIn: 'root',
})
export class MediumService {
  private _form: MediumForm;

  constructor(private _fb: FormBuilder) {}

  getForm(model?: ICreateMediumCommand): MediumForm {
    if (!this._form) {
      this._form = this._initForm(model);
    }
    return this._form;
  }

  private _initForm(model?: ICreateMediumCommand) {
    return this._fb.group<ControlsOf<ICreateMediumCommand>>({
      id: this._fb.control(model?.id),
      name: this._fb.control(model?.name, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(32),
        ],
      }),
      feePercent: this._fb.control(model?.feePercent ?? 0, {
        validators: [Validators.required, Validators.min(0), Validators.max(100)],
      }),
    });
  }

  reset() {
    this._form = undefined;
  }
}

export declare type MediumForm = FormGroup<ControlsOf<ICreateMediumCommand>>;
