import { Component, Input } from '@angular/core';

@Component({
  selector: 'atlas-info-item',
  standalone: true,
  imports: [],
  templateUrl: './info-item.component.html',
  styleUrl: './info-item.component.scss'
})
export class InfoItemComponent {
  @Input() icon!: string;
  @Input() title!: string;
  @Input() value: string | null;
}
