<div class="h-full flex flex-column gap-5 justify-content-between">
  <div class="page-header">
    <div class="text-3xl font-semibold">{{ pageTitle }}</div>
      <atlas-button
              btnLabel="Odustani"
              (btnClicked)="handleCancelForm()"
              btnIcon="pi pi-times"
              [btnStyle]="ButtonStyle.TEXT"
      ></atlas-button>
  </div>
  <div [ngClass]="isGray ? 'bg-gray' : ''">
    <p-scrollPanel [style]="{ width: '100%', height: 'calc(100dvh - 270px)' }">
      <ng-container *ngTemplateOutlet="content"> </ng-container>
    </p-scrollPanel>
  </div>
  <div
    class="flex gap-3 justify-content-end align-items-center h-full page-footer"
  >
<!--    <atlas-button
      btnLabel="Odustani"
      btnIcon="pi pi-times"
      [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
      (btnClicked)="handleCancelForm()"
    ></atlas-button>-->
    <atlas-button
      [btnIcon]="submitBtnIcon == 'add' ? 'pi pi-plus' : submitBtnIcon == 'edit' ? 'pi pi-pencil' : submitBtnIcon"
      [btnLabel]="submitBtnLabel"
      [btnStyle]="ButtonStyle.BASIC"
      [btnSeverity]="ButtonSeverity.SECONDARY"
      (btnClicked)="handleSubmitForm()"
    ></atlas-button>
  </div>
</div>
