import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { DialogRef } from '@ui/dialog/dialog-ref';
import {
  CreateMediumCommand,
  EditMediumCommand,
  MediumsClient,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { LoaderService } from '@shared/components/loader/loader.service';
import { finalize, Subscription } from 'rxjs';
import {
  MediumForm,
  MediumService,
} from '@app/administrator/mediums/medium-service';
import { markFormGroupTouched } from '@shared/forms/forms-common';
import {JsonPipe, NgIf} from "@angular/common";
import {SkeletonModule} from "primeng/skeleton";
import {InlineWrapperComponent} from "@ui/inline-wrapper/inline-wrapper.component";
import {InputTextComponent} from "@ui/input-text/input-text.component";

@Component({
  selector: 'atlas-medium-form',
  standalone: true,
  imports: [
    NgIf,
    SkeletonModule,
    JsonPipe,
    InlineWrapperComponent,
    InputTextComponent,
  ],
  templateUrl: './medium-form.component.html',
  styleUrl: './medium-form.component.scss',
})
export class MediumFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  constructor(
    private _dialogConfig: DialogConfig,
    private _dialogRef: DialogRef,
    private _client: MediumsClient,
    private _loaderService: LoaderService,
    private _alertService: AlertService,
    protected fs: MediumService,
  ) {
    this.fs.getForm(this._dialogConfig.data);
  }

  ngOnInit() {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleSubmit();
      }),
    );
  }

  private _handleSubmit() {
    if (this.fs.getForm().invalid) {
      markFormGroupTouched(this.fs.getForm());
      return;
    }

    this._loaderService.setShowLoader = true;
    this._dialogConfig.styleClass = 'display: hidden';

    if (this.fs.getForm().value.id) {
      this._handleEdit();
    } else {
      this._handleCreate();
    }
  }

  private _handleEdit() {
    this._subs.add(
      this._client
        .editMedium(this.fs.getForm().value as EditMediumCommand)
        .pipe(
          finalize(() => {
            this._loaderService.reset();
            this._dialogConfig.styleClass = 'display: block';
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this._dialogRef.close(true);
        }),
    );
  }

  private _handleCreate() {
    this._subs.add(
      this._client
        .createMedium(this.fs.getForm().value as CreateMediumCommand)
        .pipe(
          finalize(() => {
            this._loaderService.reset();
            this._dialogConfig.styleClass = 'display: block';
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this._dialogRef.close(true);
        }),
    );
  }

  ngOnDestroy() {
    this.fs.reset();
    this._dialogConfig.data = undefined;
    this._subs.unsubscribe();
  }
}
