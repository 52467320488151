import {Component} from '@angular/core';
import {PageComponent} from '@shared/components/page/page.component';
import { AuthService } from '@shared/services/auth.service';
import { ButtonModule } from 'primeng/button';
import {environment} from "../../../environments/environment";

@Component({
    selector: 'atlas-admin-sos-settings',
    standalone: true,
    imports: [PageComponent, ButtonModule, ],
    templateUrl: './admin-settings.component.html',
    styleUrl: './admin-settings.component.scss',
})
export class AdminSettingsComponent {

    constructor(
        private _authService: AuthService
      ) {      }

    handleLogOut(){
            this._authService.logout();


    }

    protected readonly environment = environment;
}
