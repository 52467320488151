<p-dropdown
  [options]="models"
  [formControl]="selectedModelCtrl"
  placeholder="Odaberite model"
  [editable]="true"
  optionLabel="name"
  [filter]="true"
  filterBy="name"
  [showClear]="true"
  styleClass="w-full"
/>
