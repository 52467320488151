import { Injectable } from '@angular/core';
import {
  ValidatorFn,
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import {
  CreateRecomendationsCommandBody,
  ICreateRecomendationsCommandBody,
  ICreateRecommendationsCommand,
} from '@shared/model/atlas.api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecommendationFormService {
  constructor(private _fb: FormBuilder) {}

  form: RecomendationForm;
  private _showAddDialog: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private _isForLead: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  private _sourceRecommendationId: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string>(undefined);

  get getShowAddDialog(): Observable<boolean> {
    return this._showAddDialog.asObservable();
  }

  set setShowAddDialog(value: boolean) {
    this._showAddDialog.next(value);
  }

  public _sourceId: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string>(undefined);

  set setSourceId(value: string | undefined) {
    this._sourceId.next(value);
  }

  get hasSourceId(): boolean {
    return (
      this._sourceId.value !== undefined ||
      this._sourceRecommendationId.value !== undefined
    );
  }

  set setIsForLead(value: boolean) {
    this._isForLead.next(value);
  }

  set setSourceRecommendationId(value: string | undefined) {
    this._sourceRecommendationId.next(value);
  }

  get getForm(): RecomendationForm {
    if (!this.form) {
      this.form = this._fb.group<ControlsOf<ICreateRecommendationsCommand>>({
        reccomendations: this._getItemArray(),
      });
    }
    return this.form;
  }

  addItem(model?: ICreateRecomendationsCommandBody) {
    this.getItems.push(this.getItemForm(model));
  }

  removeItem(itemIdx: number) {
    this.getItems.removeAt(itemIdx);
  }

  resetForm() {
    this.form = undefined;
    this.setIsForLead = false;
    this.setSourceId = undefined;
    this.setSourceRecommendationId = undefined;
  }

  get getItems(): RecommedationArray {
    return this.form.controls.reccomendations;
  }

  private _dataTransfer: IRecommedationDataTransfer | undefined;

  get getDataTransfer(): IRecommedationDataTransfer {
    return this._dataTransfer;
  }

  set setDataTransfer(value: IRecommedationDataTransfer) {
    this._dataTransfer = value;
  }

  clearDataTransfer() {
    this._dataTransfer = undefined!;
  }

  assignSourceId(id: string, idx: number) {
    this.getItems.at(idx).controls.sourceId.patchValue(id);
  }

  clearSourceId() {
    this.getItems.controls.map((item) => item.controls.sourceId.patchValue(''));
  }

  private _getItemArray(
    model?: ICreateRecomendationsCommandBody[],
  ): RecommedationArray {
    if (!model || model.length === 0) {
      return this._fb.array<RecomendationItemForm>([]);
    }

    const res = this._fb.array<RecomendationItemForm>([]);
    model.forEach((p: ICreateRecomendationsCommandBody) => {
      res.push(this.getItemForm(p));
    });
    return res;
  }

  getItemForm(model?: ICreateRecomendationsCommandBody): RecomendationItemForm {
    return this._fb.group<ControlsOf<ICreateRecomendationsCommandBody>>(
      {
        fullName: this._fb.control(model?.fullName, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        contact: this._fb.control(model?.contact, {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(64)],
        }),
        city: this._fb.control(model?.city, {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(64)],
        }),
        sourceId: this._fb.control(model?.sourceId ?? this._sourceId?.value, {
          nonNullable: false,
          validators: [],
        }),
        sourceRecommendationId: this._fb.control(
          model?.sourceRecommendationId ?? this._sourceRecommendationId?.value,
          {
            nonNullable: false,
            validators: [],
          },
        ),
        isForLead: this._fb.control(this._isForLead.value),
        comment: this._fb.control(model?.comment, {
          nonNullable: true,
          validators: [Validators.maxLength(200)],
        }),
      },
      { validators: validateSourceFields },
    ) as any;
  }
}

export declare type RecomendationForm = FormGroup<
  ControlsOf<ICreateRecommendationsCommand>
>;
export declare type RecomendationItemForm = FormGroup<
  ControlsOf<CreateRecomendationsCommandBody>
>;
export declare type RecommedationArray = FormArray<RecomendationItemForm>;

export interface IRecommedationDataTransfer {
  id: string;
  name: string;
  lastName: string;
  phoneNum: string;
}

function validateSourceFields(
  formGroup: AbstractControl,
): ValidationErrors | null {
  const sourceId = formGroup.get('sourceId')?.value;
  const sourceRecommendationId = formGroup.get('sourceRecommendationId')?.value;

  if (
    (!!sourceId && !!sourceRecommendationId) ||
    (!sourceId && !sourceRecommendationId)
  ) {
    return { invalidSource: true };
  }
  return null;
}
