<div class="subtitle-sidebar">Opis asistencije</div>

<div class="info-group">
    <div class="label-sm">Usluga</div>
    <div class="label-sm-info">{{previewData?.description.serviceName}}</div>
</div>

<div class="info-group">
    <div class="label-sm">Vrsta kvara</div>
    <div class="label-sm-info">{{previewData?.description.failureType}}</div>
</div>

<div class="info-group">
    <div class="label-sm">Opis kvara</div>
    <div class="label-sm-info">
        {{previewData?.description.failureDescription}}
    </div>
</div>

<div class="info-group">
    <div class="label-sm">Broj putnika</div>
    <div class="label-sm-info">{{previewData?.description.passangersNum}}</div>
</div>

<div class="info-group">
    <div class="label-sm">SOS Agent</div>
    <div class="label-sm-info">
        {{previewData?.description.agnetName}}
        <img
      ngSrc="assets/images/user-profile.svg"
      width="32"
      height="32"
      alt="user-image"
    />
    </div>
</div>