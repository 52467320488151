import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AssistanceUrgency } from '@shared/model/atlas.api';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { SelectItem } from 'primeng/api';
import { AssistanceFormService } from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';

@Component({
  selector: 'atlas-urgency-dropdown',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './urgency-dropdown.component.html',
  styleUrl: './urgency-dropdown.component.scss'
})
export class UrgencyDropdownComponent {
  urgencyOptions: SelectItem<AssistanceUrgency>[];
  urgencyCtrl: FormControl<AssistanceUrgency | null>;

  constructor(private formService: AssistanceFormService) {
    this.urgencyCtrl = this.formService.getForm.controls.urgency;
    this.urgencyOptions = [
      {label: "Hitno, ne može čekati", value: AssistanceUrgency.URGENT},
      {label: "Može da sačeka", value: AssistanceUrgency.CAN_WAIT},
      {label: "Zakazano", value: AssistanceUrgency.SCHEDULED}
    ]
  }
}
