import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  IModelAutocompleteItem,
  VehiclesClient,
} from '@shared/model/atlas.api';
import { NewVehicleService } from '@app/features/services/new-vehicle-service';
import { ErrorRequiredComponent } from '@ui/errors/error-required/error-required.component';

@Component({
  selector: 'atlas-model-autocomplete',
  standalone: true,
  imports: [InputSearchComponent, ErrorRequiredComponent],
  templateUrl: './model-autocomplete.component.html',
  styleUrl: './model-autocomplete.component.scss',
})
export class ModelAutocompleteComponent
  implements OnInit, OnChanges, OnDestroy
{
  private _subs: Subscription;
  models: IModelAutocompleteItem[] = [];
  shouldResetInput: boolean = false;

  @Input() brandId: string | undefined = undefined;
  @Input() isDisabled: boolean = true;
  @Input() modelName: string | undefined;

  @Output() public onModelChanged = new EventEmitter<string>();

  constructor(
    private _client: VehiclesClient,
    public newVehicleService: NewVehicleService,
  ) {
    this._subs = new Subscription();
    this.newVehicleService.setModelNameCtrl(this.modelName);
    this.newVehicleService.setModelNameRequired();
  }

  ngOnInit() {
    this._subs.add(
      this.newVehicleService.shouldResetModelInput$.subscribe((res) => {
        this.shouldResetInput = !this.shouldResetInput;
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['brandId']?.currentValue !== changes['brandId']?.previousValue
    ) {
      this.models = [];
    }
    if (
      changes['modelName']?.currentValue !== changes['modelName']?.previousValue
    ) {
      this.newVehicleService.setModelNameCtrl(this.modelName);
    }
  }

  private _load(keyword: string) {
    this._getModels(keyword).then((res) => {
      this.models = [...res.result.responseList];
    });
  }

  private async _getModels(keyword: string) {
    return await firstValueFrom(
      this._client.getModelsForBrand(this.brandId, keyword),
    );
  }

  searchModels(searchValue: string) {
    this._load(searchValue);
  }

  onSelectChange(item: any) {
    if (!item || !item.value) {
      return;
    }
    this.newVehicleService.setModelNameCtrl(item.value.label);
    this.onModelChanged.emit(item.value.label);
  }

  ngOnDestroy() {
    this.shouldResetInput = true;
    this.newVehicleService.shouldResetModelInput$.next(false);
    this._subs.unsubscribe();
  }
}
