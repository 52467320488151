import { NgIf } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ClientsAutocompleteComponent } from '@shared/components/clients-autocomplete/clients-autocomplete.component';
import { ControlsOf } from '@shared/forms/forms-common';
import { ICreateRecomendationsCommandBody } from '@shared/model/atlas.api';
import { ButtonComponent } from '@ui/button/button.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import {ReccomendationFormService} from "@app/cc-leader/services/reccomendation-form.service";
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';

@Component({
  selector: 'atlas-add-recommendation-form',
  standalone: true,
  imports: [
    NgIf,
    InputTextComponent,
    InputTextModule,
    ClientsAutocompleteComponent,
    DividerModule,
    ButtonComponent,
    InputPhoneComponent
  ],
  templateUrl: './add-recommendation-form.component.html',
  styleUrl: './add-recommendation-form.component.scss'
})
export class AddRecommendationFormComponent implements OnDestroy {

  recomendations: FormArray<FormGroup<ControlsOf<ICreateRecomendationsCommandBody>>>;

  constructor(
    private _reccomendationFS: ReccomendationFormService,
  ) {
    this.recomendations = this._reccomendationFS.getReccomendations();

    if (this.recomendations.length === 0) {
      this._reccomendationFS.addEmptyCard()
    }
  }

  clearSelectedRecc( idx: number) {
    this._reccomendationFS.clearByIdx(idx)
  }

  handleClientChanged(event: any, idx: number) {
    this._reccomendationFS.addClientToRecc(event.id, idx)
  }

  handleAddReccCard() {
    this._reccomendationFS.addEmptyCard();
  }

  ngOnDestroy(): void {
    this._reccomendationFS.clearReccomendations()
  }

}
