import { CommonModule, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PackageService } from '@app/administrator/services/package-service';
import {
  IPackagePreviewResponse,
  PackagesClient,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { LocationService } from '@shared/services/location.service';
import { InputSwitchComponent } from '@ui/input-switch/input-switch.component';
import { DividerModule } from 'primeng/divider';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabViewModule } from 'primeng/tabview';
import { firstValueFrom, Subscription } from 'rxjs';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { ConfirmationService } from 'primeng/api';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { LocationAdminService } from '@shared/services/location-admin.service';

@Component({
  selector: 'atlas-package-preview',
  standalone: true,
  imports: [
    NgOptimizedImage,
    InputSwitchComponent,
    InputSwitchModule,
    TabViewModule,
    NgIf,
    CommonModule,
    DividerModule,
    FormsModule,
    ConfirmPopupModule,
  ],
  templateUrl: './package-preview.component.html',
  styleUrl: './package-preview.component.scss',
})
export class PackagePreviewComponent implements OnInit, OnDestroy {
  packageId: any;
  previewData: IPackagePreviewResponse;
  public activeIndex: number | undefined;
  private _subs: Subscription = new Subscription();

  constructor(
    public config: DialogConfig,
    public packageClient: PackagesClient,
    private confirmationService: ConfirmationService,
    private dialogRef: DialogRef,
    private _alertService: AlertService,
    private _packageService: PackageService,
    private _locationService: LocationService,
    private _locationAdminService: LocationAdminService,
  ) {}

  ngOnInit() {
    this._subs.add(
      this._packageService.getSelectedPackageId.subscribe((id) => {
        this.packageId = id;
      }),
    );

    this._getPreviewData().then((res) => {
      this.previewData = res.result;
    });

    this._subs.add(
      this.dialogRef.onSubmit.subscribe(() => {
        this.editPackage();
      }),
    );
    this._subs.add(
      this.dialogRef.onCustom.subscribe(() => {
        this.deletePackage();
        // this.confirmationService.confirm({
        //   header: 'Brisanje paketa',
        //   message: 'Da li želiš da obrišeš paket?',
        //   acceptButtonStyleClass: ButtonSeverity.DANGER,
        //   rejectLabel: 'Odustani',
        //   acceptLabel: 'Obriši',
        //   icon: 'pi pi-play',
        //   accept: () => {
        //     this.deletePackage();
        //   },
        //   reject: () => {},
        // });
      }),
    );
  }

  deletePackage() {
    this._subs.add(
      this.packageClient.deletePackage(this.previewData.id).subscribe((x) => {
        this._alertService.addSuccessMsg(x.result);
        this.dialogRef.close(true);
      }),
    );
  }

  changePackageStatus() {
    this._subs.add(
      this.packageClient
        .enableDisablePackage(this.packageId)
        .subscribe((res) => {
          this._alertService.addSuccessMsg('Status paketa promenjen');
          this.dialogRef.close(true);
        }),
    );
  }

  editPackage() {
    this._locationAdminService.routeToEditPackageForm(this.packageId);
    this.dialogRef.close();
  }

  private async _getPreviewData() {
    return await firstValueFrom(
      this.packageClient.getPackagePreview(this.packageId),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
