import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DetailsClientInfoComponent } from './details-client-info/details-client-info.component';
import { CommentsClientInfoComponent } from "./comments-client-info/comments-client-info.component";
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ClientClient, ClientDetailsResponse } from '@shared/model/atlas.api';
import {
  RecommendationTableComponent
} from "@app/features/recommendations/recommendation-table/recommendation-table.component";
import {ButtonComponent, ButtonSeverity, ButtonStyle} from "@ui/button/button.component";
import {
  RecommendationFormService
} from "@app/features/recommendations/add-recommendation-form/recommendation-form.service";
import { DialogService } from '@ui/dialog/dialog.service';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { PasswordChangeComponent } from '@app/features/password-change/password-change.component';

@Component({
  selector: 'atlas-details-tab-client',
  standalone: true,
  imports: [
    DetailsClientInfoComponent,
    CommentsClientInfoComponent,
    NgIf,
    RecommendationTableComponent,
    ButtonComponent,
  ],
  templateUrl: './details-tab-client.component.html',
  styleUrl: './details-tab-client.component.scss',
})
export class DetailsTabClientComponent implements OnInit {
  private _subs: Subscription = new Subscription();
  clientId: string;
  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL_MEDIUM);
  isLoading: boolean = true;
  clientDetails: ClientDetailsResponse;

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  constructor(
    private route: ActivatedRoute,
    private client: ClientClient,
    private _recommendationService: RecommendationFormService,
    private _dialogService: DialogService,
  ) {
    this.clientId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getClientDetails();
  }

  private getClientDetails() {
    this._subs.add(
      this.client.getClientInfo(this.clientId).subscribe((res) => {
        this.clientDetails = res.result?.value;
        this.isLoading = false;
      }),
    );
  }
  
  handleShowDialog() {
    this._recommendationService.setShowAddDialog = true;
    this._recommendationService.setSourceId = this.clientId;
  }

  handleChangeEmail() {
    this.dialogConfig.header = 'Promena e-mail adrese';
    this.dialogConfig.headerDescription = 'Dijalog za izmenu trenutne e-mail adrese korisnika';
    this.dialogConfig.customSubmitButton = {
      label: 'Potvrdi',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
      style: 'max-width: 100px',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;
    this.dialogConfig.data = { 
      clientMail: this.clientDetails.email,
      clientId: this.clientId };

    this.openDialog(PasswordChangeComponent);
  }

  openDialog(formType: any): void {
    this._dialogService.open(formType, this.dialogConfig);
  }

}
