<div class="flex flex-column gap-2 w-full">
  <atlas-input-search
    (onChange)="onSelectChange($event)"
    (onSearch)="searchModels($event)"
    [suggestions]="models"
    [shouldReset]="shouldResetInput"
    [formCtrlClass]="
        (newVehicleService.getModelNameCtrl().errors?.['required'] && (newVehicleService.getModelNameCtrl().dirty || newVehicleService.getModelNameCtrl().touched))
        ? 'w-full border-error'
        : 'w-full'"
    [ctrlPlaceholder]="
      newVehicleService.getModelNameCtrl().value ?? newVehicleService.defaultModelName
    "
    [forceSelection]="false"
    fieldName="label"
    [isDisabled]="isDisabled"
  ></atlas-input-search>
  <atlas-error-required
    [formCtrl]="newVehicleService.getModelNameCtrl()"
  ></atlas-error-required>
</div>
