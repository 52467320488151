import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import {
  IPackageCard,
  VehicleTypeItem,
} from '@shared/model/atlas.api';
import { Guid } from 'guid-typescript';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PackageCardListService {
  constructor(private _fb: FormBuilder) {}

  // #region BS

  selectedVehicleType = new BehaviorSubject<VehicleTypeItem>(null);

  setSelectedVehicleType(vehicleType: VehicleTypeItem) {
    this.selectedVehicleType.next(vehicleType);
  }

  getSelectedVehicleType() {
    return this.selectedVehicleType.asObservable();
  }

  vehicleTypeId = new BehaviorSubject<string>(null);

  setVehicleTypeId(vehicleTypeId: string) {
    this.vehicleTypeId.next(vehicleTypeId);
  }

  getVehicleTypeId() {
    return this.vehicleTypeId.asObservable();
  }

  selectedPackageId = new BehaviorSubject<string>(null);

  setSelectedPackageId(packageId: string, idx?: number, isRenew?: boolean) {
    isRenew ? null : this.selectedPackageId.next(packageId); 
    // this.selectedPackageId.next(packageId);
    this._packagesSubject.value.controls[idx].controls.packageId.patchValue(
      packageId,
    );
  }

  setSelectedPackageIdForRenew(packageId: string) {
    this.selectedPackageId.next(packageId);
  }

  getSelectedPackageId() {
    return this.selectedPackageId.asObservable();
  }

  selectedPackageName = new BehaviorSubject<string>(null);

  setSelectedPackageName(name: string, idx?: number) {
    this._packagesSubject.value.controls[idx]
      .get('packageName')
      .patchValue(name);
  }

  getSelectedPackageName() {
    return this.selectedPackageName.asObservable();
  }

  selectedPackagePrice = new BehaviorSubject<number>(null);

  setSelectedPackagePrice(price: number, idx?: number) {
    this._packagesSubject.value.controls[idx]
      .get('packagePrice')
      .patchValue(price);
  }

  getSelectedPackagePrice() {
    return this.selectedPackagePrice.asObservable();
  }

  selectedPacakgePriceId = new BehaviorSubject<string>(null);

  setSelectedPacakgePriceId(priceId: string, idx: number) {
    this._packagesSubject.value.controls[
      idx
    ].controls.packagePriceId.patchValue(priceId);
  }

  getSelectedPacakgePriceId() {
    return this.selectedPacakgePriceId.asObservable();
  }

  selectedChassisNum = new BehaviorSubject<string>(null);

  setSelectedChassisNum(chassisNum: string, idx?: number) {
    debugger
    // this._packagesSubject.value.value[idx].chassisNum = chassisNum;
    this._packagesSubject.value.controls
      .at(idx)
      .get('chassisNum')
      .patchValue(chassisNum)
  }

  getSelectedChassisNum() {
    return this.selectedChassisNum.asObservable();
  }

  selectedClientPackageId = new BehaviorSubject<string>(null);

  setSelectedClientPackageId(selected: string) {
    this.selectedClientPackageId.next(selected);
  }

  getSelectedClientPackageId() {
    return this.selectedClientPackageId.value;
  }

  licniPaketSelected = new BehaviorSubject<boolean>(false);

  setLicniPaketSelected() {
    this.licniPaketSelected = new BehaviorSubject<boolean>(true);
    this.licniPaketSelected.next(true);
  }

  setLicniPaketUnselected() {
    this.licniPaketSelected = new BehaviorSubject<boolean>(true);
    this.licniPaketSelected.next(false);
  }

  getLicniPaketSelectedValue() {
    return this.licniPaketSelected.value;
  }

  // #endregion BS

  private _packagesSubject = new BehaviorSubject<
    FormArray<FormGroup<ControlsOf<IPackageCard>>>
  >(new FormArray<FormGroup<ControlsOf<IPackageCard>>>([]));

  packages$ = this._packagesSubject.asObservable();

  getPackages(): FormArray<FormGroup<ControlsOf<IPackageCard>>> {
    return this._packagesSubject.value;
  }

  addPackage(packageItem: IPackageCard) {
    this._packagesSubject.value.push(this.initPackageCardForm(packageItem));
    this._packagesSubject.next(this._packagesSubject.value);
  }

  addPackagePriceId(priceId: string, priceValue: number, idx: number) {
    debugger
    this._packagesSubject.value
      .at(idx) 
      .get('packagePriceId')
      .patchValue(priceId);
    this._packagesSubject.value.controls
      .at(idx)
      .get('packagePrice')
      .patchValue(priceValue);
  }

  clearAllPackages() {
    this._packagesSubject.next(
      new FormArray<FormGroup<ControlsOf<IPackageCard>>>([]),
    );
  }

  clearPackageById(idx: number) {
    this._packagesSubject.value.removeAt(idx);
    this._packagesSubject.next(this._packagesSubject.value);
  }

  initPackageCardForm(model?: IPackageCard): FormGroup {
    return this._fb.group<ControlsOf<IPackageCard>>({
      packageId: this._fb.control(model?.packageId, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      packageName: this._fb.control(model?.packageName),
      chassisNum: this._fb.control(model?.chassisNum),
      packagePriceId: this._fb.control(Guid.EMPTY),
      packagePrice: this._fb.control(model?.packagePrice),
      clientPackageId: this._fb.control(model?.clientPackageId),
      clientJmbg: this._fb.control(model?.clientJmbg),
    });
  }
}

export declare type PackageCardForm = FormGroup<ControlsOf<IPackageCard>>;


