import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChangeSellerDialogComponent } from "../change-seller-dialog/change-seller-dialog.component";
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { AgentAutocompleteComponent } from "@app/cc-leader/clients/agent-autocomplete/agent-autocomplete.component";
import { Subscription } from 'rxjs';
import { AlertService } from '@shared/services/alert.service';
import {AssignPackagesToTempSellerCommand, IClientWithPackagesItem, OrdersClient} from '@shared/model/atlas.api';
import { DialogRef } from '@ui/dialog/dialog-ref';
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'atlas-add-temp-seller-dialog',
  standalone: true,
  imports: [ChangeSellerDialogComponent, AgentAutocompleteComponent, NgOptimizedImage],
  templateUrl: './add-temp-seller-dialog.component.html',
  styleUrl: './add-temp-seller-dialog.component.scss'
})
export class AddTempSellerDialogComponent implements OnInit,  OnDestroy {
  totalClients: any;
  totalPackages: any;
  agentImageUrl: string;
  agentName: string;
  cpIds: string[] = [];
  private _subs: Subscription = new Subscription();

  client: any[];
  selectedAgentId: string;

  constructor(private clientService: ClientPackageTableService,
    private _alertService: AlertService,
    private _client: OrdersClient,
    private _dialogRef: DialogRef
  ) { }

  ngOnInit(): void {
    this._loadClientData();
    this._setAgentInfo();
    this._listenForSubmit();
  }

  private _loadClientData(): void {
    this.cpIds = [];
    this._subs.add(this.clientService.getSelectedPackages().subscribe(res => {
          res.forEach((pkg) => {
            this.cpIds.push(pkg.clientPackageId);
          })
        }
    ));
  
    this.totalClients = this.clientService.getSelectedClientsCount();
    this.totalPackages = this.clientService.getSelectedPackagesCount();
  }

  private _setAgentInfo(): void {
    const clients = this.clientService.getSelectedClients();
    if (clients.length > 1) {
      this.agentName = `Ukupno ${clients.length} `;
      this.agentImageUrl = '../../../../assets/images/client-icon.svg';
    } else {
      this.agentName = clients[0]?.name;
      this.agentImageUrl = clients[0]?.imageUrl
          ? clients[0].imageUrl
          : '../../../../assets/images/client-icon.svg';
    }
  }

  private _listenForSubmit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleSave();
      }),
    );
  }

  onAgentSelected(agentId: any) {
    this.selectedAgentId = agentId.id;
  }

  public _handleSave() {
    let isTempSellerAssigned = false;
    this._subs.add(
      this._client.assignPackagesToTempSeller(
        new AssignPackagesToTempSellerCommand
          ({
            clientPackageIds: this.cpIds,
            toTempSellerId: this.selectedAgentId
          })
      )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          isTempSellerAssigned = true;
          this.clientService.initSelectedPackages([]);
          this.clientService.resetSelectedClients();
          this.exit(isTempSellerAssigned);
        }),
    );
  }

  public exit(isTempSellerAssigned: boolean) {
    this._dialogRef.close({
      isTempSellerAssigned: isTempSellerAssigned,
      packageIdsLength: this.cpIds.length,
      agentName: this.agentName
    });}

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
