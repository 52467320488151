<atlas-scrollable-page
  pageTitle="{{ partnerData?.name }}"
  pageDescription="Osnovni podaci o saradniku, njegovim vozilima i vozačima"
  submitBtnLabel="Izmeni"
  submitBtnIcon="edit"
  cancelBtnIcon="back"
  (onCancelAction)="backToTable()"
  (onSubmitAction)="editPartner(currentPartnerId)"
>
  <ng-template #content>
    <div class="flex gap-3">
      <!-- LEVA STRANA -->
      <div class="flex-70">
        <!-- Detalji firme -->
        <div *ngIf="partnerData">
          <div class="font-semibold text-lg mb-5">Detalji</div>
          <div class="font-medium text-base mb-3">Detalji firme</div>

          <!-- Partner overview cards -->
          <div class="flex flex-column md:flex-row gap-3">
            <atlas-page-info-card
              [cardTitle]="'Pib'"
              cardIcon="pi-briefcase"
              [cardNumber]="partnerData?.taxId ?? '--'"
              [infoPage]="true"
              class="flex-1"
            ></atlas-page-info-card>
            <atlas-page-info-card
              [cardTitle]="'Matični broj'"
              [cardNumber]="partnerData?.nationalNumber ?? '--'"
              cardIcon="pi-briefcase"
              class="flex-1"
              [infoPage]="true"
            ></atlas-page-info-card>
            <atlas-page-info-card
              [cardTitle]="'Žiro račun'"
              [cardNumber]="partnerData?.bankAccount ?? '--'"
              cardIcon="pi-building"
              class="flex-1"
              [infoPage]="true"
            ></atlas-page-info-card>
          </div>
        </div>
        <div *ngIf="!partnerData" class="flex flex-row gap-3">
          <div *ngFor="let item of skeletonItems" class="skeleton-package-item">
            <p-skeleton class="skeleton-city-item">
            </p-skeleton>
          </div>
        </div>
        <!-- Glavne usluge -->
        <div class="mt-6 font-medium text-base">Glavne usluge saradnika</div>
        <div class="flex mt-4">
          <div *ngFor="let activity of partnerData?.activityTypes">
            <div class="badge flex align-items-baseline mr-2">
              <div class="pi pi-check badge-icon mr-2"></div>
              <div class="badge-txt">{{ activity }}</div>
            </div>
          </div>
        </div>

        <!-- Tabela vozila -->
        <div class="mt-4">
          <div
            class="flex mt-4 justify-content-between"
            *ngIf="partnerData?.numOfVehicles > 0"
          >
            <div class="flex gap-3 mt-2">
              <div class="font-semibold text-xl">Vozila</div>
              <div class="table-num">{{ partnerData?.numOfVehicles }}</div>
            </div>
            <div>
              <atlas-button
                btnIcon="pi pi-plus"
                btnLabel="Dodaj vozilo"
                btnClass="pl-0"
                (btnClicked)="handleAddVehicle()"
              ></atlas-button>
            </div>
          </div>

          <div class="table-container mt-2">
            <atlas-table
              [paginatedData]="vehiclePagData"
              [tableConfig]="vehicleTableConfig"
            ></atlas-table>
          </div>
        </div>

        <!-- Tabela vozaca -->
        <div class="mt-6">
          <div
            class="flex mt-4 justify-content-between mt-7"
            *ngIf="driversCount > 0"
          >
            <div class="flex gap-3 mt-2">
              <div class="font-semibold text-xl">Vozači</div>
              <div class="table-num">{{ driversCount }}</div>
            </div>
            <div>
              <atlas-button
                btnIcon="pi pi-plus"
                btnLabel="Dodaj vozača"
                btnClass="pl-0"
                (btnClicked)="handleAddDriver()"
              ></atlas-button>
            </div>
          </div>

          <div class="table-container mt-2">
            <atlas-table
              [paginatedData]="driversPaginatedData"
              [tableConfig]="driverTableConfig"
            ></atlas-table>
          </div>
        </div>
      </div>

      <!-- DESNA STRANA -->
      <div class="flex-30">
        <div class="contact-card border-round-xl p-5">
          <div class="contact-header mb-4">
            <div class="font-semibold text-xl line-height-4">Kontakt osoba</div>
            <div class="font-normal text-base description-text">
              Zadužena i odgovorna osoba saradnika
            </div>
          </div>

          <p-divider></p-divider>

          <div class="flex flex-column gap-2">
            <img ngSrc="./assets/images/user.svg" width="48" height="48" alt="user-img"/>
            <div class="font-semibold text-2xl mt-2">
              {{ partnerData?.contactName }}
            </div>
            <div>
              <p-skeleton *ngIf="!partnerData?.contactName" class="skeleton-city-item">
              </p-skeleton>
            </div>
            <div class="flex gap-3 align-items-center description-text" *ngIf="partnerData?.contactNumber">
              <img ngSrc="./assets/images/phone.svg" width="20" height="20" alt="phone-img"/>
              <div class="text-base">{{ partnerData?.contactNumber }}</div>
            </div>
            <div>
              <p-skeleton *ngIf="!partnerData?.contactNumber" class="skeleton-city-item mb-3"></p-skeleton>
            </div>
            <div *ngIf="partnerData?.contactEmail" class="flex align-items-center gap-3 description-text">
              <img ngSrc="./assets/images/email.svg" width="20" height="20" alt="mail-img"/>
              <div class="text-base ">{{ partnerData?.contactEmail }}</div>
            </div>
            <div>
              <p-skeleton *ngIf="!partnerData?.contactEmail" class="skeleton-city-item mb-3"></p-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

</atlas-scrollable-page>

<atlas-vehicle-sidebar></atlas-vehicle-sidebar>
