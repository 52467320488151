<div class="ml-1" style="max-width: 700px">
  <div class="step-title">Korak 1</div>
  <div class="text-3xl font-semibold mt-1">Klijent</div>

  <div class="mt-5 title-text">Postavi me kao prodavca</div>

  <div class="flex box-padding mt-3">
    <p-selectButton 
        [options]="stateOptions" 
        [ngModel]="setSellerId" 
        optionLabel="label" 
        optionValue="value" 
        allowEmpty="false"
        (onChange)="updateSetSellerId($event)"/>
  </div>

  <div class="mt-5 title-text">Vrsta klijenta</div>

  <div class="flex gap-4 box-padding mt-5">
    <atlas-icon-checkbox
      name="Fizičko lice"
      imageUrl="assets/images/user-moj-package.svg"
      [isSelected]="selectedOption === 'Fizicko'"
      (handleSelect)="selectFizicko()"
      class="w-full"
    ></atlas-icon-checkbox>
    <atlas-icon-checkbox
      name="Pravno lice"
      [isSelected]="selectedOption === 'Pravno'"
      imageUrl="assets/images/suitcase.svg"
      (handleSelect)="selectPravno()"
      class="w-full"
    ></atlas-icon-checkbox>
  </div>

  <div class="mt-5 title-text">Detalji klijenta</div>

  <div class="mt-3 flex gap-3">
    <div class="w-full">
      <!-- Ime / Prezime -->
      <atlas-input-text
        [formCtrlId]="'ime-id'"
        [formCtrl]="orderForm.controls.client.controls.name"
        ctrlLabel="Ime"
        ctrlPlaceholder="Ime"
        formCtrlClass="w-full"
      ></atlas-input-text>
    </div>
    <div class="w-full">
      <atlas-input-text
        [formCtrlId]="'prezime-id'"
        [formCtrl]="orderForm.controls.client.controls.lastName"
        ctrlLabel="Prezime"
        ctrlPlaceholder="Prezime"
        formCtrlClass="w-full"
      ></atlas-input-text>
    </div>
  </div>
  <!-- Broj / Adresa -->
  <div class="mt-3 flex gap-3">
    <div class="w-full">
      <div>
        <label class="mb-2">Broj telefona</label>
        <atlas-contact-phone-list></atlas-contact-phone-list>
      </div>
    </div>
    <div class="w-full">
      <atlas-input-mail
        [formCtrlId]="'mail-id'"
        [formCtrl]="orderForm.controls.client.controls.email"
        ctrlLabel="E-mail adresa"
        ctrlPlaceholder="E-mail adresa"
        formCtrlClass="w-full"
      ></atlas-input-mail>
    </div>
  </div>
  <!-- Adresa slepanja -->
  <div class="mt-3 flex gap-3">
    <div class="w-full">
      <label [for]="'towing-address'">Adresa šlepanja</label>
      <div class="flex flex-column gap-2 mt-2">
        <span class="p-input-icon-left google-autocomplete">
          <i class="pi pi-map-marker"></i>
          <input
            #towingLocationRef
            id="start-id"
            placeholder="Adresa šlepanja"
            [ngClass]="
              orderForm.controls.towingLocation.controls.place.invalid &&
              (orderForm.controls.towingLocation.controls.place.dirty ||
                orderForm.controls.towingLocation.controls.place.touched)
                ? 'p-inputtext p-component invalid'
                : 'p-inputtext p-component'
            "
          />
        </span>
        <atlas-error-required
                *ngIf="shouldDisplayRequiredError(orderForm.controls.towingLocation.controls.place)"
                [formCtrl]="orderForm.controls.towingLocation.controls.place"
        ></atlas-error-required>
      </div>
    </div>
  </div>

  <!-- Izvor / Medium-->
  <div class="mt-3 flex gap-3">
    <div class="w-full">
      <label [for]="'medium-id'">Medij</label>
      <atlas-mediums-dropdown
        [mediumCtrl]="orderForm.controls.client.controls.mediumId"
        ctrlStyleClass="w-full"
        [id]="'medium-id'"
      >
      </atlas-mediums-dropdown>
    </div>
    <div class="w-full">
      <label [for]="'source-id'">Izvor</label>
      <atlas-sources-dropdown
        [sourceCtrl]="orderForm.controls.client.controls.sourceId"
        ctrlStyleClass="w-full"
        [id]="'source-id'"
      ></atlas-sources-dropdown>
    </div>
  </div>

  <div *ngIf="selectedOption === 'Pravno'" class="mt-6">
    <div class="mt-3 mb-3 title-text">Detalji firme</div>
    <div class="mt-3 flex gap-3">
      <div class="w-full">
        <atlas-input-text
          [formCtrl]="orderForm.controls.client.controls.companyName"
          ctrlLabel="Naziv firme"
          ctrlPlaceholder="Naziv firme"
          formCtrlClass="w-full"
        ></atlas-input-text>
      </div>
      <div class="w-full">
        <atlas-input-text
          [formCtrl]="orderForm.controls.client.controls.companyAddress"
          ctrlLabel="Adresa firme"
          ctrlPlaceholder="Adresa firme"
          formCtrlClass="w-full"
        ></atlas-input-text>
      </div>
    </div>
    <div class="mt-3 flex gap-3">
      <div class="w-full">
        <atlas-input-text
          [formCtrl]="orderForm.controls.client.controls.nationalNumber"
          ctrlLabel="PIB"
          ctrlPlaceholder="Poreski identifikacioni broj"
          formCtrlClass="w-full"
        ></atlas-input-text>
      </div>
      <div class="w-full">
        <atlas-input-text
          [formCtrl]="orderForm.controls.client.controls.taxId"
          ctrlLabel="MB"
          ctrlPlaceholder="Matični broj"
          formCtrlClass="w-full"
        ></atlas-input-text>
      </div>
    </div>
  </div>
</div>
