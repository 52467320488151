import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ErrorRequiredComponent } from '../errors/error-required/error-required.component';
import { ErrorEmailComponent } from '../errors/error-email/error-email.component';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { AutoFocusModule } from 'primeng/autofocus';
import { MaxLengthComponent } from '@ui/errors/max-length/max-length.component';
import { TooltipModule } from 'primeng/tooltip';
import { InputNumberModule } from 'primeng/inputnumber';
import { KeyFilterModule, KeyFilterPattern } from 'primeng/keyfilter';

@Component({
  selector: 'atlas-input-text',
  standalone: true,
  imports: [
    ErrorRequiredComponent,
    ErrorEmailComponent,
    InputTextModule,
    NgIf,
    NgClass,
    ReactiveFormsModule,
    AutoFocusModule,
    MaxLengthComponent,
    TooltipModule,
    InputNumberModule,
    KeyFilterModule,
    CommonModule,
  ],
  templateUrl: './input-text.component.html',
  styleUrl: './input-text.component.scss',
})
export class InputTextComponent {
  /** I/O */
  @Input() formCtrl!: FormControl;
  @Input() ctrlPlaceholder: string = '';
  @Input() ctrlLabel: string = '';
  @Input() formCtrlId: string = 'text-input';
  @Input() formCtrlType: string = 'text';
  @Input() formCtrlClass: string = '';
  @Input() formCtrlIcon?: string;
  @Input() tooltip?: string;
  @Input() formCtrlIconRight?: string;
  @Input() isDisabled: boolean;
  @Input() ctrlKeyFilter: RegExp | KeyFilterPattern | null | undefined;
  @Input() isPriceFormat: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() shouldFocus: boolean = false;
  @Output() keyReleased = new EventEmitter<boolean>();
  @Output() inputLeft: EventEmitter<string | number> | null = new EventEmitter<
    string | number
  >();
  @Output() onEnterClicked = new EventEmitter<string>();
  @Input() hideErrors: boolean = false;

  constructor() {
    var test = this.isDisabled;
    this.isDisabled = false;
  }

  handleKeyUp(value: any) {
    this.keyReleased.emit(value);
    if (value.keyCode === 13) {
      this.onEnterClicked.emit();
    }
  }

  handleBlur(event: any) {
    if (this.inputLeft) {
      this.inputLeft.emit(event.target.value);
    }
  }
}
