<div class="flex gap-3">
  <div class="w-full">
    <atlas-input-search
      (onChange)="onSelectChange($event)"
      (onSearch)="searchDetails($event)"
      (enterClicked)="handleAddService()"
      [suggestions]="detailSuggesions"
      [ctrlPlaceholder]="ctrlPlaceholder"
      class="search-input w-full"
      formCtrlClass="w-full"
      [isDisabled]="false"
      [shouldReset]="shouldResetInput"
      [forceSelection]="false"
    ></atlas-input-search>
  </div>

  <div>
    <atlas-button
      (btnClicked)="handleAddService()"
      class="action-button"
      [btnStyle]="ButtonStyle.BASIC"
      [btnSeverity]="ButtonSeverity.SECONDARY"
      btnLabel="Dodaj"
      btnClass="w-full"
    ></atlas-button>
  </div>
</div>
