<atlas-page
  pageTitle="Mediji"
  btnIcon="pi pi-plus"
  pageDescription="Upravljanje medijima, dodavanje novih i menjanje postojećih"
  actionLabel="Dodaj medij"
  (onHandleAction)="showCreateForm()"
>
  <atlas-table
    [paginatedData]="paginatedData"
    [tableConfig]="tableConfig"
  ></atlas-table>
</atlas-page>
