import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { EmailCheckComponent } from './email-check/email-check.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegisterComponent } from './register/register.component';
import { ValidationCodeComponent } from './register/validation-code/validation-code.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';

export const authRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'register/:isDriver/:email',
        component: RegisterComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'register/validation-code',
        component: ValidationCodeComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'forgot-password/reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'email-check',
        component: EmailCheckComponent,
      },
      {
        path: 'new-password',
        component: NewPasswordComponent,
      },
      { path: '**', redirectTo: 'login' },
    ],
  },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: 'login' },
];
