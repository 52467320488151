import { CommonModule, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PackageService } from '@app/administrator/services/package-service';
import { AdminService } from '@app/administrator/services/admin-service';
import {
  IPackagePreviewResponse,
  PackagesClient,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { LocationService } from '@shared/services/location.service';
import { InputSwitchComponent } from '@ui/input-switch/input-switch.component';
import { DividerModule } from 'primeng/divider';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabViewModule } from 'primeng/tabview';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'atlas-package-preview',
  standalone: true,
  imports: [
    NgOptimizedImage,
    InputSwitchComponent,
    InputSwitchModule,
    TabViewModule,
    NgIf,
    CommonModule,
    DividerModule,
    FormsModule,
  ],
  templateUrl: './package-preview.component.html',
  styleUrl: './package-preview.component.scss',
})
export class PackagePreviewComponent implements OnInit, OnDestroy {
  packageId: any;
  previewData: IPackagePreviewResponse;
  public activeIndex: number | undefined;
  private _subs: Subscription = new Subscription();

  constructor(
    public config: DynamicDialogConfig,
    public packageClient: PackagesClient,
    private dialogRef: DynamicDialogRef,
    public adminService: AdminService,
    private _alertService: AlertService,
    private _packageService: PackageService,
    private _locationService: LocationService,
  ) {
    this._packageService.getSelectedPackageId.subscribe((id) => {
      this.packageId = id;
    });
  }

  ngOnInit() {
    this._getPreviewData().then((res) => {
      this.previewData = res.result;
    });
  }

  deletePackage() {
    this._subs.add(
      this.packageClient.deletePackage(this.previewData.id).subscribe((x) => {
        this._alertService.addSuccessMsg(x.result);
        this.dialogRef.close(true);
      }),
    );
  }

  deleteActionPrice(actionPriceId: any) {
    const actionPrice = this.previewData.actionPrices.find(
      (price) => price.id === actionPriceId,
    );
    if (actionPrice) {
      actionPrice.isActive = false;

      this._subs.add(
        this.packageClient
          .deletePackagePrice(actionPriceId)
          .subscribe((response) => {
            this._alertService.addSuccessMsg(response.result);
          }),
      );
    }
  }

  changePackageStatus() {
    this._subs.add(
      this.packageClient
        .enableDisablePackage(this.packageId)
        .subscribe((res) => {
          this._alertService.addSuccessMsg('Status paketa promenjen');
          this.dialogRef.close(true);
        }),
    );
  }

  changePriceStatus(actionPriceId: any) {
    this._subs.add(
      this.packageClient
        .enableDisablePackagePrice(actionPriceId)
        .subscribe((res) => {
          this._alertService.addSuccessMsg('Status akcijske cene promenjen');
        }),
    );
  }

  editPackage() {
    var test: any;
    this._subs.add(
      this._packageService.getSelectedPackageId.subscribe((x) => {
        test = x;
      }),
    );
    this._locationService.routeToEditPackageForm(this.packageId);
    this.dialogRef.close();
  }

  private async _getPreviewData() {
    return await firstValueFrom(
      this.packageClient.getPackagePreview(this.packageId),
    );
  }
  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
