import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { AgentAutocompleteComponent } from '@app/cc-leader/clients/agent-autocomplete/agent-autocomplete.component';
import { AssignPackagesToSellerCommand, OrdersClient } from '@shared/model/atlas.api';
import { Subscription } from 'rxjs';
import { AlertService } from '@shared/services/alert.service';
import { DialogRef } from '@ui/dialog/dialog-ref';
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'atlas-change-seller-dialog',
  standalone: true,
    imports: [AgentAutocompleteComponent, NgOptimizedImage],
  templateUrl: './change-seller-dialog.component.html',
  styleUrl: './change-seller-dialog.component.scss'
})
export class ChangeSellerDialogComponent implements OnInit, OnDestroy {
  client: any[];
  agentName: string;
  clientName: string;
  selectedAgentId: string;
  cpIds: string[] = [];
  agentImageUrl: string;
  private _subs: Subscription = new Subscription();
  @Output() dataEmitter = new EventEmitter<{ packageIdsLength: number, clientNames: string[] }>();

  constructor(private clientService: ClientPackageTableService,
    private _client: OrdersClient,
    private _alertService: AlertService,
    private _dialogRef: DialogRef

  ) { }



  ngOnInit(): void {
    this._loadSelectedClients();
    this._setAgentInfo();
    this._listenForSubmit();
  }

  private _loadSelectedClients(): void {
    this.cpIds = [];
    this._subs.add(this.clientService.getSelectedPackages().subscribe(res => {
      res.forEach((pkg) => {
        this.cpIds.push(pkg.clientPackageId);
      })
        }
    ));
  }

  private _setAgentInfo(): void {
    const clients = this.clientService.getSelectedClients();
    if (clients.length > 1) {
      this.agentName = `Ukupno ${clients.length} `;
      this.agentImageUrl = '../../../../assets/images/client-icon.svg';
    } else {
      this.agentName = clients[0]?.name;
      this.agentImageUrl = clients[0]?.imageUrl
          ? clients[0].imageUrl
          : '../../../../assets/images/client-icon.svg';
    }
  }

  private _listenForSubmit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleSave();
      }),
    );
  }

  onAgentSelected(agent: { id: string, name: string }): void {
    this.selectedAgentId = agent.id;
    this.clientName = agent.name;
  }

  public _handleSave() {
    let isSellerChanged = false;

    const selectedClients = this.clientService.getSelectedClients();

    const clientNames = this.cpIds.map(cpId => {
        const client = selectedClients.find(client =>
            client.packages.some(pkg => pkg.clientPackageId === cpId)
        );
        return client ? client.name : 'Unknown Client';
    });

    this._subs.add(
      this._client.assignPackagesToSeller(
        new AssignPackagesToSellerCommand
          ({
            clientPackageIds: this.cpIds,
            toSellerId: this.selectedAgentId
          })
      )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          isSellerChanged = true;

          this.dataEmitter.emit({ packageIdsLength: this.cpIds.length, clientNames: clientNames });
          this.clientService.initSelectedPackages([]);
          this.clientService.resetSelectedClients();
          this.exit(isSellerChanged);
        }),
    );
}


exit(isSellerChanged: boolean) {
  this._dialogRef.close({
    isSellerChanged: isSellerChanged,
    packageIdsLength: this.cpIds.length,
    agentName: this.agentName
  });
}

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
