import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ActivateEmployeeAsyncCommand,
  DeactivateEmployeesCommand,
  EmployeesClient,
  GetEmployeesTableQuery,
  IPaginationResponseOfEmployeeItem,
} from '@shared/model/atlas.api';
import { LoaderService } from '@shared/components/loader/loader.service';
import { finalize, firstValueFrom, Subscription } from 'rxjs';
import { TableComponent } from '@ui/table/table.component';
import { ButtonComponent, ButtonStyle } from '@ui/button/button.component';
import { ActionMode, SelectMode, TableConfig } from '@ui/table/table-common';
import { ContextMenu, ContextMenuModule } from 'primeng/contextmenu';
import { InviteEmployeeComponent } from './invite-employee/invite-employee.component';
import { PageComponent } from '@shared/components/page/page.component';
import {
  DialogConfig,
  DialogSize,
  OnDialogTypedInit,
} from '@ui/dialog/dialog-config';
import { DialogService } from '@ui/dialog/dialog.service';
import { TabViewModule } from 'primeng/tabview';
import { PageInfoCardComponent } from '@ui/page-info-card/page-info-card.component';
import { EditEmployeeComponent } from './edit-employee/edit-employee.component';
import { ConfirmationService } from 'primeng/api';
import {TableLazyLoadEvent} from "primeng/table/table.interface";
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AlertService } from '@shared/services/alert.service';
import { FormControl, FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { DropdownInputButtonComponent } from '@shared/components/dropdown-input-button/dropdown-input-button.component';
import { InputButtonComponent } from '@shared/components/input-button/input-button.component';
import {TableServiceLocal} from "@ui/table/table-service-local.service";

@Component({
  selector: 'atlas-employee-table',
  standalone: true,
  templateUrl: './employee-table.component.html',
  styleUrl: './employee-table.component.scss',
  imports: [
    FormsModule,
    TableComponent,
    ButtonComponent,
    ContextMenuModule,
    PageComponent,
    TabViewModule,
    PageInfoCardComponent,
    ConfirmDialogModule,
    InputTextModule,
    InputTextComponent,
    DropdownInputButtonComponent,
    InputButtonComponent,
  ],
})
export class EmployeeTableComponent
  implements OnInit, OnDestroy, OnDialogTypedInit<InviteEmployeeComponent>, OnDialogTypedInit<EditEmployeeComponent>
{
  private _subs: Subscription = new Subscription();
  public paginatedData: IPaginationResponseOfEmployeeItem;
  protected readonly ButtonStyle = ButtonStyle;
  protected tableConfig: TableConfig;
  searchInputValue: FormControl;

  @ViewChild('cm') cm: ContextMenu | undefined;

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL_MEDIUM);

  protected active: boolean | undefined;
  public activeIndex: number | undefined;

  activeNum: string = '0';
  inactiveNum: string = '0';
  onlineNum: string = '0';

  constructor(
    private _client: EmployeesClient,
    private _loaderService: LoaderService,
    private _dialogService: DialogService,
    private _confirmationService: ConfirmationService,
    private _alertService: AlertService,
    private _tableService: TableServiceLocal
  ) {
    this.tableConfig = {
      selectMode: SelectMode.SINGLE,
      showHeader: true,
      columns: [],
      rowsPerPage: 10,
    };
    this.searchInputValue = new FormControl<string>('');

    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
  }

  ngOnInit(): void {
    this.setTableConfig().then();
    this._loadActiveCount();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez zaposlenih',
        description: 'Ovde će biti prikazana tabela zaposlenih',
        btnLabel: 'Dodaj zaposlenog',
        action: () => this.handleAddNewEmployee(),
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.load();
      },
      columns: [
        {
          header: 'Status',
          field: 'statusBadge',
          type: 'badge',
          badgeClass: 'is-active-',
        },
        {
          header: 'Ime i prezime',
          field: 'name',
          imageField: 'imageUrl',
          type: 'image',
          styleClass: 'text-black-alpha-90 font-semibold',
          columns: [
            {
              header: '',
              field: 'email',
              type: 'text',
            },
          ],
        },
        {
          header: 'Pozicija',
          field: 'roles',
          type: 'text',
        },
        {
          header: 'Aktivnost',
          field: 'onlineStatusBadge',
          type: 'badge',
          badgeClass: 'is-active-'
        },
        // {
        //   header: 'Datum kreiranja',
        //   field: 'createdOn',
        //   type: 'text',
        // },
      ],
      rowActions: [
        {
          mode: ActionMode.MULTI,
          label: 'Izmeni',
          icon: 'pi pi-pencil',
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this.handleEditEmployee(rowData);
          },
          shouldDisplayByCondition: () => true,
        },
        {
          mode: ActionMode.MULTI,
          label: 'Deaktiviraj',
          icon: 'pi pi-power-off',
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this.handleDeactivateEmployee(rowData);
          },
          shouldDisplayByCondition: (rowId: number) => {
            const rowData: any = this._getPaginatedItem(rowId);
            return rowData.isActive;
          },
        },
        {
          mode: ActionMode.MULTI,
          label: 'Aktiviraj',
          icon: 'pi pi-refresh',
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this.handleActivateEmployee(rowData);
          },
          shouldDisplayByCondition: (rowId: number) => {
            const rowData: any = this._getPaginatedItem(rowId);
            return !rowData.isActive;
          },
        },
      ],
    });
  }

  handleAddNewEmployee() {
    this.dialogConfig.header = 'Dodavanje novog naloga';
    this.dialogConfig.headerDescription =
      'Upišite e-mail adresu, dodajte novi nalog i dodelite poziciju';
    this.dialogConfig.customSubmitButton = {
      label: 'Pošalji pozivnicu',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
      style: 'max-width: 100px',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;
    this.openDialog(InviteEmployeeComponent);
  }

  handleDeactivateEmployee(employee: any) {
    this._confirmationService.confirm({
      message: 'Da li ste sigurni da želite da deaktivirate zaposlenog?',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      header: 'Potvrdite',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._subs.add(
          this._client
            .deactivateEmployee(
              new DeactivateEmployeesCommand({
                employeeId: employee.id,
                employeeEmail: employee.email,
              }),
            )
            .subscribe((res) => {
              this._alertService.addSuccessMsg(res.result.value);
              this.load();
            }),
        );
      },
    });
  }

  handleActivateEmployee(employee: any) {
    this._confirmationService.confirm({
      message: 'Da li ste sigurni da želite da aktivirate zaposlenog?',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      header: 'Potvrdite',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._subs.add(
          this._client
            .activateEmployee(
              new ActivateEmployeeAsyncCommand({
                employeeId: employee.id,
              }),
            )
            .subscribe((res) => {
              this._alertService.addSuccessMsg(res.result);
              this.load();
            }),
        );
      },
    });
  }

  openDialog(formType: any): void {
    this._dialogService.open(formType, this.dialogConfig);
  }

  handleTabChange(event: number) {
    if (event === 0) {
      this.active = undefined;
    } else this.active = event == 1;

    this.load();
  }

  handleEditEmployee(employee: any) {
    this.dialogConfig.header = employee.name;
    this.dialogConfig.headerDescription = employee.email;
    this.dialogConfig.customSubmitButton = {
      label: 'Sačuvaj',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
      style: 'max-width: 100px',
    };
  
    this.dialogConfig.closable = true;
    this.dialogConfig.data = {
      id: employee.id,
    };
  
    const dialogRef = this._dialogService.open(EditEmployeeComponent, this.dialogConfig);
  
    dialogRef.onClose.subscribe((result) => {
      if (result?.success) {
        this.load();
      }
    });
  }

  load() {
    this._tableService.setIsLoading();
    this._loadEmployees().then((res) => {
      console.log(res)
      this.paginatedData = res.result;
      this._tableService.resetIsLoading();
    });
  }

  private async _loadEmployees() {
    return await firstValueFrom(
      this._client.getAllEmployees(
        new GetEmployeesTableQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize,
          isActive: this.active,
          employeeName: this.searchInputValue.value
            ? this.searchInputValue.value
            : undefined,
        }),
      ),
    );
  }

  private _loadActiveCount() {
    this._subs.add(
      this._client.getActiveInactiveCount().subscribe((x) => {
        this.activeNum = x.result.activeCount.toString();
        this.inactiveNum = x.result.inactiveCount.toString();
        if ('onlineCount' in x.result && x.result.onlineCount != null) {
          this.onlineNum = x.result.onlineCount.toString();
        }
      }),
    );
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  searchEmployees(active: boolean) {
    this.active = active;
    this._loaderService.setLoadingText = 'Dobavljanje zaposlenih...';
    this._loaderService.setShowLoader = true;
    this._subs.add(
      this._client
        .getAllEmployees(
          new GetEmployeesTableQuery({
            isActive: this.active,
          }),
        )
        .pipe(
          finalize(() => {
            this._loaderService.reset();
          }),
        )
        .subscribe({
          next: (res) => {
            res.result.data!.forEach((element) => {
              // this.items.data = [];
              // this.items.data.push({
              //   imeIprezime: element.name + ' ' + element.lastName,
              //   mail: element.email,
              //   status: this.active,
              //   dodati: element.createdOn,
              //   pozicija: element.role,
              //   id: element.id,
              // });
            });
          },
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
