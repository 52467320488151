import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  InitClient,
  IPaymentTypeDropdownItem,
  PaymentStatus,
  PaymentType,
} from '@shared/model/atlas.api';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MultiselectComponent } from '@ui/multiselect/multiselect.component';

@Component({
  selector: 'atlas-payment-type-multiselect',
  standalone: true,
  imports: [MultiselectComponent],
  templateUrl: './payment-type-multiselect.component.html',
  styleUrl: './payment-type-multiselect.component.scss',
})
export class PaymentTypeMultiselectComponent implements OnInit, OnDestroy {
  private _subs: Subscription;

  typeOptions: IPaymentTypeDropdownItem[] = [];
  formGroup: FormGroup<PaymentTypeForm>;

  @Input() typesCtrl: FormArray;

  constructor(private _client: InitClient) {
    this._subs = new Subscription();
    this.formGroup = new FormGroup({
      paymentTypes: new FormControl([]),
    });
  }

  ngOnInit(): void {
    this._load();
  
    this._subs.add(
      this.formGroup.valueChanges.subscribe((value) => {
        this.typesCtrl.clear();
        value.paymentTypes.forEach((x) => {
          if (x?.type) { 
            this.typesCtrl.push(new FormControl(x.type));
          }
        });
      }),
    );
  
    this._subs.add(
      this.typesCtrl.valueChanges.subscribe((value) => {
        const validItems = value
          .map((type) => this.typeOptions.find((opt) => opt.type === type))
          .filter((item): item is IPaymentTypeDropdownItem => !!item); 
        this.formGroup.controls.paymentTypes.setValue(validItems, {
          emitEvent: false,
        });
      }),
    );
  }

  private _load() {
    this._getTypes().then((res) => {
      this.typeOptions = res.result.items.filter(
        (item) => item.type !== PaymentType.EMPTY,
      );
    });
  }

  private async _getTypes() {
    return await firstValueFrom(this._client.getPaymentTypeDropdown());
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}

export type PaymentTypeForm = {
  paymentTypes: FormControl<IPaymentTypeDropdownItem[]>;
};
