import { NgClass, NgFor, NgForOf, NgIf, NgStyle } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import {
  CampaignsClient,
  CreateContractCampaignCommand,
  CreateLeadCampaignCommand, CreateSelfRenewingCampaignCommand,
} from '@shared/model/atlas.api';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputDescriptionComponent } from '@ui/input-description/input-description/input-description.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { VehicleTypeCardComponent } from '@ui/vehicle-type-card/vehicle-type-card.component';
import { DividerModule } from 'primeng/divider';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { LocationService } from '@shared/services/location.service';
import { AlertService } from '@shared/services/alert.service';
import { AddCampaignContractComponent } from './add-campaign-contract/add-campaign-contract.component';
import { AddCampaignLeadsComponent } from './add-campaign-leads/add-campaign-leads.component';
import {
  LeadCampaignFormService,
  LeadsForm,
} from '@app/cc-leader/services/lead-campaign-form.service';
import { markFormGroupTouched } from '@shared/forms/forms-common';
import { LoaderService } from '@shared/components/loader/loader.service';
import { ScrollablePageComponent } from '@shared/components/scrollable-page/scrollable-page.component';
import {
  ContractCampaignFormService,
  ContractForm,
} from '@app/cc-leader/services/constract-campaign-form.service';
import {
  ClientWithPackagesTableComponent
} from "@app/features/call-center/client-with-packages-table/client-with-packages-table.component";
import {
  AddCampaignSelfRenewingComponent
} from "@app/cc-leader/campaigns/add-campaign/add-campaign-self-renewing/add-campaign-self-renewing.component";
import {
  SelfRenewingCampaignFormService,
  SelfRenewingForm
} from "@app/cc-leader/services/self-renewing-campaign-form.service";

@Component({
  selector: 'atlas-add-campaign',
  standalone: true,
  imports: [
    DividerModule,
    NgIf,
    NgClass,
    NgFor,
    NgForOf,
    NgStyle,
    ScrollPanelModule,
    InlineWrapperComponent,
    VehicleTypeCardComponent,
    IconCheckboxComponent,
    InputTextComponent,
    InputDescriptionComponent,
    ClientWithPackagesTableComponent,
    ButtonComponent,
    AddCampaignContractComponent,
    AddCampaignLeadsComponent,
    ScrollablePageComponent,
    AddCampaignSelfRenewingComponent,
  ],
  templateUrl: './add-campaign.component.html',
  styleUrl: './add-campaign.component.scss',
})
export class AddCampaignComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  form: LeadsForm | ContractForm | SelfRenewingForm;

  //  packageIds: string[] = [];
  // endsOnSelectionValue: any = null;
  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  selectedOption: 'Leads' | 'Contracts' | 'SelfRenewing' = 'Leads';

  constructor(
    private _campaignClient: CampaignsClient,
    private _location: LocationService,
    private _leadFormService: LeadCampaignFormService,
    private _contractFormService: ContractCampaignFormService,
    private _selfRenewingFormService: SelfRenewingCampaignFormService,
    private _loaderService: LoaderService,
    private _alertService: AlertService,
  ) {}
  ngOnInit(): void {
    // this.packageIds = this._service.getPackageIds();
  }

  handleSubmitForm() {
    this.selectedOption === 'Leads'
      ? this._handleLeadSubmit()
      : this.selectedOption === 'Contracts'
        ? this._handleContractSubmit()
        : this._handleSelfRenewinglSubmit();
  }

  private _handleLeadSubmit() {
    this.form = this._leadFormService.getForm();
    console.log(this.form);

    if (!this.form?.valid || !this._leadFormService.areFilesValid()) {
      markFormGroupTouched(this.form);
      this._alertService.addWarnMsg('Potrebno je uneti sva obavezna polja');
      return;
    }
    this._loaderService.setShowLoader = true;
    this._loaderService.setLoadingText = 'Kampanja se kreira...';

    if (!this._leadFormService.areFilesValid()) {
      markFormGroupTouched(this.form);
      this._alertService.addWarnMsg('Potrebno je uneti sva obavezna polja');
      return;
    }

    this._submitLeads().then((createRes) => {
      console.log(createRes);

      this._submitLeadFiles(createRes.result).then((fileRes) => {
        console.log(fileRes);
        this._loaderService.reset();
        this._alertService.addSuccessMsg(fileRes.result);
        this.routeToTable();
      });
    });
  }

  private _handleContractSubmit() {
    this.form = this._contractFormService.getForm();
    console.log(this.form);
    if (!this.form?.valid) {
      markFormGroupTouched(this.form);
      this._alertService.addWarnMsg('Potrebno je uneti sva obavezna polja');
      return;
    }
    this._loaderService.setShowLoader = true;
    this._loaderService.setLoadingText = 'Kampanja se kreira...';
    this._submitContracts().then((createRes) => {
      console.log(createRes);
      this._loaderService.reset();
      this._alertService.addSuccessMsg(createRes.result);
      this.routeToTable();
    });
  }

  private _handleSelfRenewinglSubmit() {
    this.form = this._selfRenewingFormService.getForm();
    console.log(this.form);
    if (!this.form?.valid) {
      markFormGroupTouched(this.form);
      this._alertService.addWarnMsg('Potrebno je uneti sva obavezna polja');
      return;
    }
    this._loaderService.setShowLoader = true;
    this._loaderService.setLoadingText = 'Kampanja se kreira...';
    this._submitSelfRenewing().then((createRes) => {
      console.log(createRes);
      this._loaderService.reset();
      this._alertService.addSuccessMsg(createRes.result);
      this.routeToTable();
    });
  }

  private async _submitContracts() {
    return await firstValueFrom(
      this._campaignClient.createCampaignFromContracts(
        new CreateContractCampaignCommand({
          name: this.form.value.name,
          description: this.form.value.description,
          endsOnUtc: (this.form as ContractForm).value.endsOnUtc,
          clientPackageIds: (this.form as ContractForm).value.clientPackageIds,
        }),
      ),
    );
  }

  private async _submitLeads() {
    return await firstValueFrom(
      this._campaignClient.createLeadCampaign(
        new CreateLeadCampaignCommand({
          name: this.form.value.name,
          description: this.form.value.description,
          endsOnUtc: (this.form as LeadsForm).value.endsOnUtc,
          agentIds: (this.form as LeadsForm).value.agentIds,
          priority: (this.form as LeadsForm).value.priority,
        }),
      ),
    );
  }

  private async _submitSelfRenewing() {
    return await firstValueFrom(
        this._campaignClient.createSelfRenewingCampaign(
            new CreateSelfRenewingCampaignCommand({
              name: this.form.value.name,
              description: this.form.value.description,
              conditionPeriod: (this.form as SelfRenewingForm).value.conditionPeriod,
              conditionValue: (this.form as SelfRenewingForm).value.conditionValue,
            }),
        ),
    );
  }

  private async _submitLeadFiles(campaignId: string) {
    return await firstValueFrom(
      this._campaignClient.createLeads(
        campaignId,
        undefined,
        this._leadFormService.getFiles,
      ),
    );
  }

  handleCancelForm() {
    this.routeToTable();
  }

  routeToTable() {
    this._location.routeToCampaignTable();
  }

  ngOnDestroy(): void {
    this.form = undefined;
    this._leadFormService.reset();
    this._contractFormService.reset();
    this._selfRenewingFormService.reset();
    this._subs.unsubscribe();
  }
}
