import { Component, Input, input } from '@angular/core';
import {NgClass, NgIf, NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'atlas-page-info-card',
  standalone: true,
  imports: [NgClass,
    NgIf, NgOptimizedImage
  ],
  templateUrl: './page-info-card.component.html',
  styleUrl: './page-info-card.component.scss',
})
export class PageInfoCardComponent {
  @Input() cardIcon: string | undefined;
  @Input() cardTitle: string | undefined;
  @Input() cardNumber: string | undefined;
  @Input() infoPage: boolean | undefined;
  @Input() cardImageSrc: string | undefined;
}
