import {
  JsonPipe,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet,
} from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  GoogleMap,
  MapDirectionsRenderer,
  MapMarker,
} from '@angular/google-maps';
import { ServiceAvailabilityComponent } from '@app/sos-agent/packages/client-package-sidebar/service-availability/service-availability.component';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import {
  IVehicleSidebarResponse,
  PartnersClient,
  VehiclesClient,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { ConfirmationService } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { SidebarModule } from 'primeng/sidebar';
import { firstValueFrom, Subscription } from 'rxjs';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { DialogService } from '@ui/dialog/dialog.service';
import { PartnerDriverInfoComponent } from '../partner-driver-info/partner-driver-info.component';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { licencePlatePipe } from '@shared/pipes/licence-plate.pipe';

@Component({
  selector: 'atlas-vehicle-sidebar',
  standalone: true,
  imports: [
    SidebarModule,
    NgIf,
    SidebarModule,
    DividerModule,
    NgOptimizedImage,
    ButtonComponent,
    ServiceAvailabilityComponent,
    JsonPipe,
    NgForOf,
    NgTemplateOutlet,
    GoogleMap,
    MapDirectionsRenderer,
    MapMarker,
    licencePlatePipe,
  ],
  templateUrl: './vehicle-sidebar.component.html',
  styleUrl: './vehicle-sidebar.component.scss',
})
export class VehicleSidebarComponent implements OnInit, OnDestroy {
  showSidebar: boolean;
  private _subs: Subscription;
  vehicleId: string;
  previewData: IVehicleSidebarResponse;
  display: any;
  markerPosition: google.maps.LatLngLiteral;
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  geocoder: google.maps.Geocoder;
  driverRegistered: boolean;

  dialogConfig: DialogConfig = new DialogConfig();

  mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: true,
    center: {
      // Belgrade
      lat: 44.80401,
      lng: 20.46513,
    },
    zoom: 10,
    styles: [
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  };

  constructor(
    private service: ClientPackageTableService,
    private vehicle: VehiclesClient,
    private _confirmationService: ConfirmationService,
    private _alertService: AlertService,
    private _partnersClient: PartnersClient,
    private _dialogService: DialogService,
    private cdr: ChangeDetectorRef,
  ) {
    this.showSidebar = false;
    this._subs = new Subscription();
    this.geocoder = new google.maps.Geocoder();
  }

  ngOnInit() {
    this._subs.add(
      this.service.getShowPreviewSidebar.subscribe((show) => {
        if (show) {
          this.vehicleId = this.service.getSidebarId;
          this.showSidebar = show;
          this._load().then();
        } else {
          this._reset();
        }
      }),
    );
  }

  private async _load() {
    const res = await this._getPreviewData();
    this.previewData = res.result.value;

    if (
      this.previewData &&
      this.previewData.location &&
      this.previewData.location.place
    ) {
      this.markerPosition = {
        lat: this.previewData.location.latitude,
        lng: this.previewData.location.longitude,
      };

      this.mapOptions = {
        ...this.mapOptions,
        center: {
          lat: this.previewData.location.latitude,
          lng: this.previewData.location.longitude,
        },
      };
    } else {
      this._defaultMap();
    }
  }

  handleDriverClick() {
    this.dialogConfig.header = 'Informacije vozača';
    this.dialogConfig.customSubmitButton = {
      label: this.driverRegistered ? 'Poslato' : 'Pošalji ponovo',
      icon: this.driverRegistered ? 'pi pi-check' : 'pi pi-user-plus',
    };
    this.dialogConfig.customCancelButton = {
      label: '',
      icon: 'pi pi-clone',
      class: 'max-w-3rem',
    };
    this.dialogConfig.closable = true;
    this.dialogConfig.dialogSize = DialogSize.SMALL;

    this.dialogConfig.data = {
      driverId: this.previewData.driverId,
    };

    this.openDialog(PartnerDriverInfoComponent);
  }

  openDialog(form: any) {
    this._dialogService.open(form, this.dialogConfig);
  }

  editVehicle() {
    alert('Edit vozila');
  }

  getImageSrc(): string {
    switch (this.previewData.vehicleType) {
      case 'Putnička vozila':
        return 'assets/images/car.svg';
      case 'Kombi':
        return 'assets/images/truck.svg';
      default:
        return 'assets/images/moto.svg';
    }
  }

  private _reset() {
    this.previewData = null;
    this.markerPosition = null;
    this.mapOptions = {
      ...this.mapOptions,
      center: {
        lat: 44.80401,
        lng: 20.46513,
      },
    };
  }

  private _defaultMap() {
    this.mapOptions = {
      ...this.mapOptions,
      center: {
        lat: 44.80401,
        lng: 20.46513,
      },
    };
  }

  deleteVehicle(vehicle: any) {
    this._confirmationService.confirm({
      message: 'Da li ste sigurni da želite da obrišete vozilo?',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      header: 'Potvrdite',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._subs.add(
          this._partnersClient
            .removePartnerVehicle(this.vehicleId)
            .subscribe((x) => {
              this._alertService.addSuccessMsg(x.result);
              this.service.hidePreviewSidebar();
            }),
        );
      },
    });
  }

  private async _getPreviewData() {
    return await firstValueFrom(this.vehicle.getVehicleSidebar(this.vehicleId));
  }

  ngOnDestroy() {
    this.service.hidePreviewSidebar();
    this._subs.unsubscribe();
  }

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;
}
