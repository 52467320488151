import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LeadCampaignFormService } from '@app/cc-leader/services/lead-campaign-form.service';
import { PageComponent } from '@shared/components/page/page.component';
import { ScrollablePageComponent } from '@shared/components/scrollable-page/scrollable-page.component';

@Component({
  selector: 'atlas-view-leads',
  standalone: true,
  imports: [PageComponent],
  templateUrl: './view-leads.component.html',
  styleUrl: './view-leads.component.scss'
})
export class ViewLeadsComponent implements OnInit {

  rowData: any;

  constructor() {}

  ngOnInit() {
    const storedData = sessionStorage.getItem('rowData');
    this.rowData = JSON.parse(storedData);
  }
  
}
