<div *ngIf="agentName" class="toast-container">
  <div class="toast-icon">
    <i class="pi pi-check-circle"></i>
  </div>
  <div class="toast-content">
    <span class="toast-title">Prebačeni ugovori</span>
    <p class="toast-description"> <span class="info-user">{{ packageIdsLength }}</span>
      {{ packageIdsLength === 1 ? 'ugovor je uspešno prebačen' : 'ugovora su uspešno prebačena' }}
      <span class="info-user">{{ agentName }}</span> agentu</p>
    <div class="toast-actions">
      <a (click)="undoAction()" class="action-link-undo">
        <i class="pi pi-undo"></i> Poništi akciju
      </a>
      <a (click)="closeToast()" class="action-link">
        U redu
      </a>
    </div>
  </div>
  <i class="pi pi-times" (click)="closeToast()"></i>
</div>