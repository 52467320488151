import { JsonPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ClientsAutocompleteComponent } from '@shared/components/clients-autocomplete/clients-autocomplete.component';
import { ControlsOf, markFormGroupTouched } from '@shared/forms/forms-common';
import {
  CreateRecomendationsCommandBody,
  CreateRecommendationsCommand,
  ICreateRecomendationsCommandBody,
  RecommendationsClient,
} from '@shared/model/atlas.api';
import { ButtonComponent, ButtonStyle } from '@ui/button/button.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import {
  RecomendationForm,
  RecommedationArray,
  RecommendationFormService,
} from '@app/features/recommendations/add-recommendation-form/recommendation-form.service';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import { finalize, Subscription } from 'rxjs';
import { DialogService } from '@ui/dialog/dialog.service';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { AlertService } from '@shared/services/alert.service';
import { LoaderService } from '@shared/components/loader/loader.service';

@Component({
  selector: 'atlas-add-recommendation-form',
  standalone: true,
  imports: [
    NgIf,
    InputTextComponent,
    InputTextModule,
    ClientsAutocompleteComponent,
    DividerModule,
    ButtonComponent,
    InputPhoneComponent,
  ],
  templateUrl: './add-recommendation-form.component.html',
  styleUrl: './add-recommendation-form.component.scss',
})
export class AddRecommendationFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription;

  form: RecomendationForm;

  constructor(
    protected formFs: RecommendationFormService,
    private _dialogRef: DialogRef,
    private _loaderService: LoaderService,
    private _dialogConfig: DialogConfig,
    private _alertService: AlertService,
    private _client: RecommendationsClient,
  ) {
    this.form = this.formFs.getForm;
    this._subs = new Subscription();
    if (this.formFs.getItems.length === 0) {
      this.formFs.addItem();
    }
  }

  ngOnInit() {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleSubmit();
      }),
    );
  }

  handleSubmit() {
    if (this.form.invalid) {
      this._alertService.addWarnMsg('Morate uneti obavezna polja');
      markFormGroupTouched(this.form);
      return;
    }

    this._dialogConfig.styleClass = 'display: hidden';

    this._loaderService.setLoadingText = 'Preporuke se kreiraju...';
    this._loaderService.setShowLoader = true;

    this._subs.add(
      this._client
        .createRecommendations(this.form.value as CreateRecommendationsCommand)
        .pipe(
          finalize(() => {
            this._loaderService.reset();
            this._dialogConfig.styleClass = 'display: block';
          }),
        )
        .subscribe((x) => {
          this._alertService.addSuccessMsg(x.result);
          this._dialogRef.close(true);
        }),
    );
  }

  ngOnDestroy(): void {
    this.formFs.resetForm();
    this._subs.unsubscribe();
  }

  protected readonly ButtonStyle = ButtonStyle;
}
