import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AssistancesClient } from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { ToastModule } from 'primeng/toast';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import { LocationService } from '@shared/services/location.service';
import { UserService } from '@shared/services/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { LocationSosService } from '@shared/services/location-sos.service';

@Component({
  selector: 'atlas-assistance-toast',
  standalone: true,
  imports: [ToastModule, NgOptimizedImage, NgIf, NgClass],
  templateUrl: './assistance-toast.component.html',
  styleUrl: './assistance-toast.component.scss',
})
export class AssistanceToastComponent implements OnInit, OnDestroy {
  @Input() totalRequested: number | undefined;
  assistanceLabel: string;
  hideToast = false;
  showToggleButton = false;
  private routerSubscription: Subscription;

  constructor(
    private client: AssistancesClient,
    private alertService: AlertService,
    private locationService: LocationService,
    private _locationSosService: LocationSosService,
    public userService: UserService,
    private router: Router,
  ) {
    this.totalRequested = 0;
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this._updateTotal();

    this.alertService.requestCountChanged$.subscribe(() => {
      this._updateTotal();
    });

    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.showToggleButton = event.url.includes('/assistance-preview');
        this.hideToast = false;
      });
  }

  toggleToast() {
    this.hideToast = !this.hideToast;
  }

  navigateToAssistances() {
    this._locationSosService.routeToAssitancesTable('zatrazene');
  }

  private async _countRequested() {
    return await firstValueFrom(this.client.getRequestedCount());
  }

  private _updateTotal() {
    this._countRequested().then((res) => {
      this.totalRequested = res.result;
      this._updateAssistanceLabel();
    });
  }

  private _updateAssistanceLabel() {
    if (this.totalRequested === 1) {
      this.assistanceLabel = 'zatraženu asistenciju';
    } else if (this.totalRequested >= 5) {
      this.assistanceLabel = 'zatraženih asistencija';
    } else {
      this.assistanceLabel = 'zatražene asistencije';
    }
  }
}
