import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientProfileService } from '@app/cc-leader/services/client-profile.service';
import { OrderFormService } from '@app/cc-leader/services/order-form.service';
import { PackageCardListService } from '@app/cc-leader/services/package-card-list.service';
import { PackageDetailSidebarComponent } from '@app/features/packages/package-detail-sidebar/package-detail-sidebar.component';
import { ClientPackageSidebarComponent } from '@app/sos-agent/packages/client-package-sidebar/client-package-sidebar.component';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { PageComponent } from '@shared/components/page/page.component';
import {
  ClientClient,
  ClientPackageStatus,
  GetClientVehiclePackagesTableQuery,
  GetVehicleQueryResponse,
  IClientPackagesTableResponse,
  IPaginationResponseOfVehiclePackageTableItem,
  VehiclesClient,
  VehicleTypeItem,
} from '@shared/model/atlas.api';
import { LocationService } from '@shared/services/location.service';
import {
  AgentCcRoleStr,
  B2BAgentRoleStr,
  LeaderCcRoleStr,
  UserService,
} from '@shared/services/user.service';
import { ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { ActionMode, TableConfig } from '@ui/table/table-common';
import { TableServiceLocal } from '@ui/table/table-service-local.service';
import { TableComponent } from '@ui/table/table.component';
import { TableLazyLoadEvent } from 'primeng/table';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'atlas-vehicle-packages-list',
  standalone: true,
  imports: [TableComponent, PageComponent, PackageDetailSidebarComponent],
  templateUrl: './vehicle-packages-list.component.html',
  styleUrl: './vehicle-packages-list.component.scss',
})
export class VehiclePackagesListComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  clientId: string;
  vehicleId: string;
  vehicleTypes: VehicleTypeItem[] = [];
  vehicle: GetVehicleQueryResponse;
  paginatedData: IPaginationResponseOfVehiclePackageTableItem;
  tableConfig: TableConfig;

  constructor(
    private locationService: LocationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private clientsClient: ClientClient,
    private vehicleClient: VehiclesClient,
    private _tableService: TableServiceLocal,
    private _packageCardSerice: PackageCardListService,
    private _orderFormService: OrderFormService,
    private serviceSidebar: ClientPackageTableService,
  ) {
    this.clientId = this.route.snapshot.paramMap.get('id');
    this.vehicleId = this.route.snapshot.paramMap.get('vehicleId');
    this._subs = new Subscription();
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
  }

  ngOnInit(): void {
    this.setTableConfig().then();
    this.getVehicleInfo();
  }

  handleAddPackage() {
    this._setVehicleType(this.vehicle.type).then(() => {
      this.locationService.routeToBuyPackageForVehicle(
        this.userService.getRoleRoutePrefix(
          AgentCcRoleStr,
          B2BAgentRoleStr,
          LeaderCcRoleStr,
        ),
        this.clientId,
        this.vehicle.id,
        this.vehicle.type,
        this.vehicle.vehicle,
      );
    });
  }

  private async _setVehicleType(typeStr: string) {
    await this._loadTypes().then((res) => {
      this.vehicleTypes = res.result.responseList;
    });

    let selectedType = this.vehicleTypes.find((type) => type.name === typeStr);
    if (selectedType) {
      this._packageCardSerice.setVehicleTypeId(selectedType.typeId);
    }
  }

  private async _loadTypes() {
    return await firstValueFrom(this.vehicleClient.getTypes());
  }

  getVehicleInfo() {
    this._subs.add(
      this.vehicleClient.getVehicle(this.vehicleId).subscribe((res) => {
        this.vehicle = res.result;
      }),
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez paketa',
        description: 'Ovde će biti prikazana tabela paketa vozila',
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows + 1;
        }
        this.paginatedData.pageSize = event.rows;
        this._load();
      },
      columns: [
        {
          header: 'Paket',
          field: 'packageName',
          type: 'text',
        },
        {
          header: 'Status',
          field: 'statusBadge',
          type: 'badge',
          badgeClass: 'packages-',
        },
        {
          header: 'Kupljen',
          field: 'buyDate',
          type: 'text',
        },
        {
          header: 'ISTIČE',
          field: 'expireDate',
          type: 'text',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Obnovi paket',
          label: 'Obnovi paket',
          icon: 'pi pi-refresh',
          actionClass: 'p-2 ml-5 gap-2',
          actionSeverity: ButtonSeverity.SECONDARY_GREY,
          actionStyle: ButtonStyle.OUTLINED,
          shouldDisplayByCondition: () => true,
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this.handleRenewPackage(rowData);
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Pregledaj',
          icon: 'pi pi-eye',
          shouldDisplayByCondition: (rowIdx: number) => {
            return true;
          },
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this.serviceSidebar.showPreviewPackageSidebar(rowData.clientPackageId);
          },
        },
      ],
    });
  }

  handleRenewPackage(rowIdx: any) {
    this._packageCardSerice.setSelectedPackageIdForRenew(rowIdx.packageId);
    this._orderFormService.setRenewClientId(rowIdx.clientId);
    this._packageCardSerice.setSelectedClientPackageId(rowIdx.clientPackageId);
    this.locationService.routeToRenewPackageForm(
      this.userService.getRoleRoutePrefix(
        AgentCcRoleStr,
        B2BAgentRoleStr,
        LeaderCcRoleStr,
      ),
      rowIdx.clientPackageId,
    );
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  _load() {
    this._tableService.setIsLoading();
    this._getList().then((res) => {
      this.paginatedData = res.result;
      this._tableService.resetIsLoading();
    });
  }

  private async _getList() {
    return await firstValueFrom(
      this.clientsClient.getClientVehiclePackagesTable(
        new GetClientVehiclePackagesTableQuery({
          vehicleId: this.vehicleId,
        }),
      ),
    );
  }

  get totalPackages(): number {
    return this.paginatedData?.data?.length || 0;
  }

  get activePackages(): number {
    return this.paginatedData.data.filter(
      (p) => p.statusBadge.value === ClientPackageStatus.ACTIVE,
    ).length;
  }

  get usedPackages(): number {
    return this.paginatedData.data.filter(
      (p) => p.statusBadge.value === ClientPackageStatus.USED,
    ).length;
  }

  get expiredPackages(): number {
    return this.paginatedData.data.filter(
      (p) => p.statusBadge.value === ClientPackageStatus.EXPIRED,
    ).length;
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
