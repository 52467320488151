<atlas-page>
    <!-- Header -->
    <div class="header-container p-5 mb-3">
        <!-- Header-title / Action -->
        <div class="flex justify-content-between mb-5 header-actions">
            <div class="txt-container">
                <span class="header-txt">Moje preporuke</span>
            </div>
            <div>
                <atlas-button
                    [btnStyle]="ButtonStyle.OUTLINED"
                    [btnSeverity]="ButtonSeverity.PRIMARY"
                    btnLabel="Dodaj preporuku"
                    btnIcon="pi pi-plus"
                    btnClass="border-none"
                    (btnClicked)="handleAddRecommendation()"
                ></atlas-button>
            </div>
        </div>
    
        <div class="flex justify-content-between stats-container">
            <!-- Broj preporuka -->
            <div class="flex">
                <div class="m-2 p-1 border-round-lg flex justify-content-center align-items-center card-icon">
                    <i class="pi pi-users icon" [ngClass]="'text-3xl m-2'"></i>
                </div>
                <div class="m-2 p-1">
                    <div class="stats-title">Broj preporuka</div>
                    <div class="stats-num">{{ reccomendsNum }}</div>
                </div>
            </div>

            <!-- Tekuci mesec -->
            <div class="flex">
                <div class="m-2 p-1 border-round-lg flex justify-content-center align-items-center card-icon">
                    <i class="pi pi-calendar icon" [ngClass]="'text-3xl m-2'"></i>
                </div>
                <div class="m-2 p-1">
                    <div class="stats-title">Tekući mesec</div>
                    <div class="flex">
                        <div class="stats-num">{{ tableStats?.thisMonth }}</div>
                        <div *ngIf="tableStats?.lastMonthPrecent"class="pi pi-arrow-up text-sm font-semibold align-content-center ml-2 mt-2 arrow"></div>
                        <div class="align-content-center text-sm font-semibold ml-1 mt-2 arrow">{{ tableStats?.lastMonthPrecent }}</div>
                    </div>
                </div>
            </div>

            <!-- Poslednja dodata -->
            <div class="flex">
                <div class="m-2 p-1 border-round-lg flex justify-content-center align-items-center card-icon">
                    <i class="pi pi-user-plus icon" [ngClass]="'text-3xl m-2'"></i>
                </div>
                <div class="m-2 p-1">
                    <div class="stats-title">Poslednje dodata</div>
                    <div class="stats-num">{{ tableStats?.lastAdded }}</div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Tabs -->
    <div class="flex flex-column mt-5 gap-3 table-filter">
    <div class="flex align-items-center gap-3">
      <p-tabView
        class="tabView flex-1"
        [(activeIndex)]="activeIndex!"
        (activeIndexChange)="handleTabChange($event)"
      >
        <p-tabPanel header="Nove preporuke"></p-tabPanel>
        <p-tabPanel header="U obradi"></p-tabPanel>
      </p-tabView>
      <div class="search-bar">
        <atlas-dropdown-input-button
          [searchOptionCtrl]="searchOptionCtrl"
          [searchCtrl]="searchCtrl"
          [searchOptions]="searchOptions"
          (onAction)="load()"
        >
        </atlas-dropdown-input-button>
      </div>
    </div>
  </div>

  <!-- Table -->
  <atlas-table
    [paginatedData]="paginatedData"
    [tableConfig]="tableConfig"
  ></atlas-table>
    
    
</atlas-page>



