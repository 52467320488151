<atlas-dropdown
  [options]="failureTypeOptions"
  [formCtrl]="failureTypeCtrl"
  ctrlPlaceholder="Unesite vrstu kvara"
  ctrlStyleClass="w-30rem"
  [showLabel]="false"
  formCtrlId="failure-type"
>
</atlas-dropdown>

