<div
  class="card"
  hover-class="card-hovered"
>
  <div>
    <div class="flex gap-3 justify-content-center align-items-center">
      <label>Klijent:</label>
      <div>{{ request.clientName }}</div>
    </div>

    <p-divider></p-divider>

    <table class="w-full">
      <thead>
        <tr>
          <th class="w-10rem"></th>
          <th>Trenutni podaci</th>
          <th>Zahtevana izmena</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><label>Vozilo</label></td>
          <td>{{ request.currentName }}</td>
          <td
            [ngClass]="{
              'data-changed': request.currentName !== request.newName
            }"
          >
            {{ request.newName }}
          </td>
        </tr>
        <tr>
          <td><label>Registracija</label></td>
          <td>{{ request.currentPlateNum }}</td>
          <td
            [ngClass]="{
              'data-changed': request.currentPlateNum !== request.newPlateNum
            }"
          >
            {{ request.newPlateNum }}
          </td>
        </tr>
        <tr>
          <td><label>Šasija</label></td>
          <td>{{ request.currentChassisNum }}</td>
          <td
            [ngClass]="{
              'data-changed':
                request.currentChassisNum !== request.newChassisNum
            }"
          >
            {{ request.newChassisNum }}
          </td>
        </tr>

        <tr>
          <td><label>Godište</label></td>
          <td>{{ request.currentProdYear }}</td>
          <td
            [ngClass]="{
              'data-changed': request.currentProdYear !== request.newProdYear
            }"
          >
            {{ request.newProdYear }}
          </td>
        </tr>
        <tr>
          <td><label>Istek registracije</label></td>
          <td>{{ request.currentRegistrationExpiry }}</td>
          <td
            [ngClass]="{
              'data-changed':
                request.currentRegistrationExpiry !==
                request.newRegistrationExpiry
            }"
          >
            {{ request.newRegistrationExpiry }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div>
    <p-divider styleClass="m-0"></p-divider>
    <div
      class="flex justify-content-center align-items-baseline mt-2"
    >
      <div
        (click)="submitChangeRequest(true, request.requestId)"
        class="w-full card-btn py-4 flex justify-content-center items-center"
      >
        <div class="pi pi-check"></div>
      </div>
      <p-divider layout="vertical"></p-divider>
      <div
        (click)="submitChangeRequest(false, request.requestId)"
        class="w-full card-btn py-4  flex justify-content-center items-center"
      >
        <div class="pi pi-times"></div>
      </div>
    </div>
  </div>
</div>
