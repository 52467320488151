import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonComponent, ButtonSeverity, ButtonStyle} from "@ui/button/button.component";
import {DropdownComponent} from "@ui/dropdown/dropdown.component";
import {InputTextComponent} from "@ui/input-text/input-text.component";
import {FormControl} from "@angular/forms";
import {NgIf} from "@angular/common";
import {InputButtonComponent} from "@shared/components/input-button/input-button.component";

@Component({
  selector: 'atlas-dropdown-input-button',
  standalone: true,
  imports: [
    ButtonComponent,
    DropdownComponent,
    InputTextComponent,
    NgIf,
    InputButtonComponent
  ],
  templateUrl: './dropdown-input-button.component.html',
  styleUrl: './dropdown-input-button.component.scss'
})
export class DropdownInputButtonComponent {
  @Input() searchOptions: any[] = [];
  @Input() searchOptionCtrl: FormControl;
  @Input() searchCtrl: FormControl;
  @Output() onAction = new EventEmitter();


    protected readonly ButtonStyle = ButtonStyle;

  handleAction() {
    this.onAction.emit();
  }

  protected readonly ButtonSeverity = ButtonSeverity;
}
