<div class="flex flex-column gap-3 package-box">
  <div class="flex align-items-center">
    <div>
      <img
        src="../../../../assets/images/promo-code.svg"
        width="65"
        height="55"
        alt="package-image"
      />
    </div>
    <div class="flex-grow-1 ml-2">
      <div class="package-title">{{ previewData?.name }}</div>
      <div class="flex align-items-center" style="margin-top: 5px">
        <div class="text-small">{{ previewData?.discount }}</div>
      </div>
    </div>
    <div>
      <p-inputSwitch
        *ngIf="previewData"
        [disabled]="true"
        [(ngModel)]="previewData.isActive"
      ></p-inputSwitch>
    </div>
  </div>
</div>
<div class="flex flex-column mt-5 gap-2 flex-1 package-content">
  <div class="flex align-items-center">
    <span class="text-md font-semibod">Detalji</span>
  </div>

  <div class="flex align-items-center">
    <span class="text-small mt-3">Promo kod se odnosi na pakete</span>
  </div>

  <div class="type-services-container">
    <div *ngFor="let type of previewData?.packages" class="type-badge mt-2">
      <span class="mr-2 text-sm">{{ type }}</span>
    </div>
  </div>

  <div class="flex align-items-center">
    <span class="text-small mt-3">Broj promo kodova</span>
  </div>
  <div class="flex align-items-center">
    <i class="pi pi-tag text-small"></i
    ><span class="text-sm mt-2">{{ previewData?.count }}</span>
  </div>

  <div class="flex align-items-center">
    <span class="text-small mt-3">Važi od</span>
  </div>
  <div class="flex align-items-center">
    <i class="pi pi-calendar text-small"></i
    ><span class="text-sm mt-2">{{ previewData?.from }}</span>
  </div>

  <div class="flex align-items-center">
    <span class="text-small mt-3">Važi do</span>
  </div>
  <div class="flex align-items-center">
    <i class="pi pi-calendar text-small"></i
    ><span class="text-sm mt-2">{{ previewData?.to }}</span>
  </div>

    <div class="flex align-items-center">
        <span class="text-small mt-3">Aktivan na sajtu</span>
    </div>
    <div class="flex align-items-center">
        <i class="pi pi-calendar text-small"></i
        ><span class="text-sm mt-2">{{ previewData?.availableOnWebsite }}</span>
    </div>

    <div class="flex align-items-center">
        <span class="text-small mt-3">Aktivan na aplikaciji</span>
    </div>
    <div class="flex align-items-center">
        <i class="pi pi-calendar text-small"></i
        ><span class="text-sm mt-2">{{ previewData?.availableOnMobile }}</span>
    </div>

</div>
<div class="flex-grow-1"></div>
<p-divider></p-divider>

<div class="actions mb-2">
  <span (click)="deletePromoCode(previewData)" class="delete-package">
    <i class="pi pi-trash"></i> Izbriši promo kod
  </span>
  <div class="spacer"></div>
  <span (click)="editPromoCode()" class="edit-package">
    <i class="pi pi-pencil"></i>Izmeni
  </span>
</div>
