import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import {
  CampaignLeadStatus,
  IUpdateCampaignCommandBody,
  IUpdateLeadStatusCommandBody,
} from '@shared/model/atlas.api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CampaignFormService {
  private _editCampaignBody: EditCampaignForm;
  private campaignId: string | null = null;
  private _statusForm: ChangeStatusForm;

  constructor(private _fb: FormBuilder) {
    this._editCampaignBody = this._initEditForm();
    this._statusForm = this._initStatusForm();
  }

  private leadTransferData = new BehaviorSubject<LeadTransferData>(null);
  leadTransferData$ = this.leadTransferData.asObservable();

  setLeadTransferData(data: LeadTransferData) {
    this.leadTransferData.next(data);
  }

  clearLeadTransferData() {
    this.leadTransferData.next(null);
  }

  setCampaignId(id: string): void {
    this.campaignId = id;
  }

  getCampaignId(): string | null {
    return this.campaignId;
  }

  getEditForm() {
    if (!this._editCampaignBody) {
      this._editCampaignBody = this._initEditForm();
    }
    return this._editCampaignBody;
  }

  getStatusForm() {
    if (!this._statusForm) {
      this._statusForm = this._initStatusForm();
    }
    return this._statusForm;
  }

  private _initStatusForm(model?: IUpdateLeadStatusCommandBody) {
    return this._fb.group<ControlsOf<IUpdateLeadStatusCommandBody>>({
      agentId: this._fb.control(model?.agentId ?? null),
      comment: this._fb.control(model?.comment ?? null, {
        validators: [Validators.maxLength(200)],
      }),
      dateTime: this._fb.control<Date>(model?.dateTime ?? null),
      status: this._fb.control<CampaignLeadStatus>(model?.status, {
        validators: [Validators.min(0)],
      }),
    });
  }

  setCallAgainValidators() {
    this._statusForm.controls.comment.addValidators([Validators.required]);
    this._statusForm.controls.dateTime.addValidators([Validators.required]);
    this._statusForm.controls.comment.updateValueAndValidity();
    this._statusForm.controls.dateTime.updateValueAndValidity();
  }

  removeCallAgainValidators() {
    this._statusForm.controls.comment.removeValidators([Validators.required]);
    this._statusForm.controls.dateTime.removeValidators([Validators.required]);
    this._statusForm.controls.comment.updateValueAndValidity();
    this._statusForm.controls.dateTime.updateValueAndValidity();
  }

  private _initEditForm(model?: IUpdateCampaignCommandBody) {
    return this._fb.group<ControlsOf<IUpdateCampaignCommandBody>>({
      name: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      description: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.maxLength(100)],
      }),
    });
  }

  reset() {
    this._statusForm = undefined;
    this._editCampaignBody = undefined;
    this.leadTransferData.next(null);
  }
}

export declare type EditCampaignForm = FormGroup<
  ControlsOf<IUpdateCampaignCommandBody>
>;
export declare type ChangeStatusForm = FormGroup<
  ControlsOf<IUpdateLeadStatusCommandBody>
>;

export declare type LeadTransferData = {
  id: string;
  address: string;
  leadName: string;
  leadLastName: string;
  leadPhone: string;
  leadSecondaryPhone: string | undefined;
  leadTelephoneNUmber: string | undefined;
  leadEmail: string;
  vehicleBrand: string;
  vehicleModel: string;
  vehicleYear: string;
  registationNumber: string;
  chassis: string;
  isRecommendation: boolean;
};
