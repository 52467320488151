import { NgClass, NgFor, NgForOf, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import { MediumItem, MediumsClient } from '@shared/model/atlas.api';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { CheckboxModule } from 'primeng/checkbox';
import { Subscription } from 'rxjs';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { FormControl, FormsModule } from '@angular/forms';
import {
  OrderForm,
  OrderFormService,
} from '@app/cc-leader/services/order-form.service';

@Component({
  selector: 'atlas-create-order-form-step-1',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgClass,
    NgForOf,
    CheckboxModule,
    InputTextComponent,
    InputPhoneComponent,
    InputMailComponent,
    DropdownComponent,
    IconCheckboxComponent,
    FormsModule,
  ],
  templateUrl: './create-order-form-step-1.component.html',
  styleUrl: './create-order-form-step-1.component.scss',
})
export class CreateOrderFormStep1Component implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  roles: MediumItem[] = [];
  mediumDropdown: any;
  selectedOption: 'Fizicko' | 'Pravno' = 'Fizicko';

  orderForm: OrderForm;

  constructor(
    private _mediumsClient: MediumsClient,
    private _orderFormService: OrderFormService,
  ) {
    this.orderForm = _orderFormService.getOrderForm;
  }

  ngOnInit(): void {
    this._getRoles();
  }

  private _getRoles() {
    this._subs.add(
      this._mediumsClient.getMediums().subscribe((x) => {
        const result =
          typeof x.result === 'string' ? JSON.parse(x.result) : x.result;

        this.roles = result.responseList;
      }),
    );
  }

  selectFizicko() {
    this.selectedOption = 'Fizicko';
  }

  selectPravno() {
    this.selectedOption = 'Pravno';
  }

  handleRoleChange(event: any) {
    this.mediumDropdown = event.value.name;
    this.orderForm.value.client.mediumId = event.value.id;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
