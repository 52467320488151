@for (
  itemCtrl of formFs.getItems.controls;
  let idx = $index;
  track itemCtrl.controls.contact
) {
  <div>
    <!-- Header -->
    <div class="flex justify-content-between align-items-baseline">
      <span class="text-base font-bold mb-3">{{ idx + 1 }}.</span>
      <atlas-button
        *ngIf="idx !== 0"
        (btnClicked)="formFs.removeItem(idx)"
        [btnStyle]="ButtonStyle.TEXT"
        btnIcon="pi pi-trash"
      ></atlas-button>
    </div>

    <!-- Inputs -->
    <div class="flex flex-column gap-2">
      <div class="flex gap-2 flex-wrap multiple-inputs">
        <div class="flex-1">
          <atlas-input-text
            [formCtrl]="itemCtrl.controls.fullName"
            ctrlLabel="Ime i prezime"
            ctrlPlaceholder="Ime i prezime"
            [shouldFocus]="false"
          ></atlas-input-text>
        </div>
        <div class="flex-1">
          <atlas-input-phone
            [formCtrl]="itemCtrl.controls.contact"
            ctrlLabel="Kontakt telefon"
            ctrlPlaceholder="Kontakt telefon"
          ></atlas-input-phone>
        </div>
        <div class="flex-1">
          <atlas-input-text
            [formCtrl]="itemCtrl.controls.city"
            ctrlLabel="Grad"
            ctrlPlaceholder="Grad"
          ></atlas-input-text>
        </div>
        <div class="flex-1" *ngIf="!formFs.hasSourceId">
          <atlas-clients-autocomplete
            [formCtrl]="itemCtrl.controls.sourceId"
            (onClientChanged)="formFs.assignSourceId($event.id, idx)"
          ></atlas-clients-autocomplete>
        </div>
      </div>
      <div class="1-input">
        <atlas-input-text
          [formCtrl]="itemCtrl.controls.comment"
          ctrlPlaceholder="Komentar"
        ></atlas-input-text>
      </div>
    </div>
    <p-divider></p-divider>
  </div>
}
<div class="mb-3 max-w-5rem">
  <atlas-button
    btnIcon="pi pi-plus"
    btnLabel="Dodatni"
    btnClass="pl-0"
    (btnClicked)="formFs.addItem()"
  ></atlas-button>
</div>
