import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';
import { IBrandModelItem, VehiclesClient } from '@shared/model/atlas.api';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { NewVehicleService } from '@app/features/services/new-vehicle-service';
import { DropdownModule } from 'primeng/dropdown';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';

@Component({
  selector: 'atlas-model-dropdown',
  standalone: true,
  imports: [ReactiveFormsModule, DropdownModule, DropdownComponent],
  templateUrl: './model-dropdown.component.html',
  styleUrl: './model-dropdown.component.scss',
})
export class ModelDropdownComponent implements OnInit, OnChanges, OnDestroy {
  private _subs: Subscription;
  models: IBrandModelItem[] = [];
  modelNames: string[] = [];
  shouldResetInput: boolean = false;
  selectedModelCtrl: FormControl<string> = new FormControl('', {
    validators: [Validators.required],
  });

  @Input() brandId: string | undefined = undefined;
  @Input() brandName: string | undefined = undefined;
  @Input() isDisabled: boolean = false;
  @Input() modelNameCtrl: FormControl<string>;
  @Input() validateControl: boolean;

  @Output() public onModelChanged = new EventEmitter<string>();

  constructor(
    private _client: VehiclesClient,
    public newVehicleService: NewVehicleService,
  ) {
    this._subs = new Subscription();
  }

  ngOnInit() {
    this._subs.add(
      this.newVehicleService.shouldResetModelInput$.subscribe((shouldReset) => {
        if (shouldReset && !this.modelNameCtrl.value) {
          this.selectedModelCtrl.reset();
        }
      }),
    );
    this._subs.add(
      this.selectedModelCtrl.valueChanges.subscribe((value: string) => {
        if (!value) {
          this.modelNames = [...this.models.map((x) => x.name)];
          if (!this.brandName) {
            this.modelNameCtrl.reset();
          }
          return;
        } else if (value === this.modelNameCtrl.value) {
          return;
        }

        this.modelNameCtrl.patchValue(value as string);
        /*this.modelNames = [
          ...this.models
            .filter((x) => x.name.toLowerCase().includes(value.toLowerCase()))
            .map((x) => x.name),
        ];*/
        this.newVehicleService.setModelNameCtrl(value as string);
        this.onModelChanged.emit(value);
      }),
    );

    this.newVehicleService.setModelNameRequired();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['brandId']?.currentValue !== changes['brandId']?.previousValue
    ) {
      this.models = [];
      this.modelNames = [];
      this.selectedModelCtrl.reset();
      if (changes['brandId']?.currentValue) {
        this._load();
      }
    }
    if (
      changes['brandName']?.currentValue !== changes['brandName']?.previousValue
    ) {
      this.models = [];
      this.selectedModelCtrl.reset();
    }
    if (
      changes['modelNameCtrl']?.currentValue !==
      changes['modelNameCtrl']?.previousValue
    ) {
      this.newVehicleService.setModelNameCtrl(this.modelNameCtrl.value);
      this.selectedModelCtrl.patchValue(
        changes['modelNameCtrl'].currentValue.value,
      );
    }

    if (
      changes['isDisabled']?.currentValue !==
      changes['isDisabled']?.previousValue
    ) {
      if (changes['isDisabled'].currentValue) {
        this.selectedModelCtrl.disable();
      } else {
        this.selectedModelCtrl.enable();
      }
    }
    if (
      changes['validateControl']?.currentValue !=
      changes['validateControl']?.previousValue
    ) {
      this.selectedModelCtrl.markAsTouched();
    }
  }

  private _load(keyword?: string) {
    this._getModels(keyword).then((res) => {
      this.models = [...res.result.responseList];
      this.modelNames = [...this.models.map((x) => x.name)];
    });
  }

  private async _getModels(keyword: string) {
    return await firstValueFrom(this._client.getBrandModels(this.brandId));
  }

  ngOnDestroy() {
    this.newVehicleService.resetCtrl();
    this.shouldResetInput = true;
    this.newVehicleService.shouldResetModelInput$.next(false);
    this._subs.unsubscribe();
  }
}
