import { Component, Input } from '@angular/core';
import { TableColumn } from '../table-common';
import {
  CurrencyPipe,
  JsonPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgStyle,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { CurrencyNumberPipe } from '@shared/pipes/currency-number.pipe';

@Component({
  selector: 'atlas-table-row-data',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgSwitchCase,
    NgSwitch,
    NgForOf,
    NgStyle,
    CurrencyPipe,
    TooltipModule,
    InputTextModule,
    NgOptimizedImage,
    CurrencyNumberPipe,
    JsonPipe,
  ],
  templateUrl: './table-row-data.component.html',
  styleUrl: './table-row-data.component.scss',
})
export class TableRowDataComponent {
  /** I/O */
  @Input() column!: TableColumn;
  @Input() item: any;
  @Input() parent: any;
  @Input() rowIndex: number;
  protected readonly Object = Object;

  constructor() {
    this.rowIndex = 0;
  }
}
