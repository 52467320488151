import { NavigationExtras, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ASSISTANCE_TABS } from '@app/sos-agent/assistances/assistance-form/services/assistance.service';

@Injectable({
  providedIn: 'root',
})
export class LocationSosService {
  private readonly _rolePrefix = 'sos';

  constructor(private _router: Router) {}

  private _routeToUrl(url: string, navigationData?: NavigationExtras) {
    this._router.navigate([url], navigationData).then();
  }

  routeToHomePage() {
    this._routeToUrl(`/${this._rolePrefix}`);
  }

  routeToCreateAssitanceForm(clientPackageId?: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        this._rolePrefix,
        'assistances',
        'create',
        'step-1',
      ]),
    );
    return this._routeToUrl(url, {
      state: {
        clientPackageId: clientPackageId,
      },
    });
  }

  routeToPreviewAssistence(id: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        this._rolePrefix,
        'assistances',
        'assistance-preview',
        id,
      ]),
    );
    return this._routeToUrl(url);
  }

  routeToEditAssitanceForm(id: string) {
    this._router
      .navigate(['/', this._rolePrefix, 'assistances', 'edit', id, 'step-1'])
      .then();
  }

  routeToAssitancesTable(tabName: ASSISTANCE_TABS = 'sve') {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', this._rolePrefix, 'assistances']),
    );
    return this._routeToUrl(url, {
      queryParams: { tab: tabName },
      queryParamsHandling: 'merge',
    });
  }
}
