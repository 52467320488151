    <div class="header-container">
      <div class="subtitle-text">Paketi vozila</div>
      <div class="arrow-buttons">
        <div (click)="scrollLeft()" class="arrow left">
          <i class="pi pi-chevron-left"></i>
        </div>
        <div (click)="scrollRight()" class="arrow right">
          <i class="pi pi-chevron-right"></i>
        </div>
        <atlas-button
          class="client-button w-full"
          [btnStyle]="ButtonStyle.BASIC"
          [btnSeverity]="ButtonSeverity.DANGER"
          btnLabel="Dodaj vozilo"
        ></atlas-button>
      </div>
    </div>
    <div class="mt-2 mb-3 box-info">
      Ukupno vozila:
      <span class="number-info">{{ vehicles.length }} {{ vehicles.length === 1 ? 'vozilo' : 'vozila' }}</span>
    </div>
    <div *ngIf="showSkeleton">
      <div>
        <p-skeleton class="skeleton-box" width="100%" height="190px"/>
      </div>
</div>
    <div *ngIf="!showSkeleton && vehicles.length < 1" class="package-box">
      <div class="table-title">Bez vozila</div>
      <div class="table-info">
        Klijent nema ni jedno dodato vozilo niti pakete za vozila
      </div>
      <div class="center">
        <atlas-button
          class="client-button w-full"
          [btnStyle]="ButtonStyle.BASIC"
          [btnSeverity]="ButtonSeverity.DANGER"
          btnLabel="Dodaj vozilo"
        ></atlas-button>
      </div>
    </div>
    <div *ngIf="!showSkeleton && vehicles.length > 0" class="carousel-container">
      <div class="vehicle-cards" #vehicleCarousel>
        <div *ngFor="let vehicle of vehicles" class="vehicle-card">
          <div class="vehicle-header">
            <div class="vehicle-icon">
              <i [ngClass]="getVehicleIcon(vehicle.type)"></i>
            </div>
            <div class="vehicle-details">
              <span class="vehicle-type">{{ vehicle.type }}</span>
              <div class="vehicle-name">{{ vehicle.vehicle }}</div>
            </div>
          </div>

          <div
            class="flex justify-content-between mt-2"
            *ngIf="vehicle.stats.activePackagesCount > 0"
          >
            <span>Aktivni paketi</span>
            <span class="package-count">
              {{ vehicle.stats.activePackagesCount }} ({{
                vehicle.stats.allPackagesCount
              }})
            </span>
          </div>

          <div
            class="flex justify-content-between mt-2"
            *ngIf="vehicle.stats.activePackagesCount < 1"
          >
            <span class="no-active-package"
              ><i class="pi pi-info-circle mr-1"></i>Bez aktivnog paketa</span
            >
            <span class="package-count">
              {{ vehicle.stats.activePackagesCount }} ({{
                vehicle.stats.allPackagesCount
              }})
            </span>
          </div>
          <div class="mt-1">
          <p-progressBar 
          [value]="getProgressValue(vehicle)" 
          [style]="{ height: '6px' }" 
          [ngStyle]="{ 'background-color': getProgressColor(vehicle) }">
        </p-progressBar>
      </div>
          <div class="vehicle-footer">
            <div (click)="handleMoreDetails()" class="see-details mt-3">Vidi detalje</div>
            <i class="pi pi-chevron-right mt-3"></i>
          </div>
        </div>
      </div>
    </div>
  
  