<atlas-dropdown
  [options]="statusOptions"
  optionLabel="label"
  ctrlDataKey="status"
  [formCtrl]="statusCtrl"
  ctrlStyleClass="w-full"
  [ctrlPlaceholder]="statusCtrl.value"
>
<!--  <ng-template #selectedItem let-obj>-->
<!--    <div class="p-text-left item-label">{{ obj.label }}</div>-->
<!--  </ng-template>-->

<!--  <ng-template #item let-obj>-->
<!--    <div [ngClass]="'dropdown-badge payment-status-' + obj.status">-->
<!--      <span class="dot"></span>-->
<!--      <span>{{ obj.label }}</span>-->
<!--    </div>-->
<!--  </ng-template>-->
</atlas-dropdown>
