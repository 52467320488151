<div
  [ngClass]="
    vehicleForm.invalid && (vehicleForm.dirty || vehicleForm.touched)
      ? 'flex flex-column gap-5 w-30rem invalid-card'
      : 'flex flex-column gap-5 w-30rem'
  "
>
  <div
    *ngIf="vehicleForm"
    class="flex flex-column gap-3 vehicle-option cursor-pointer"
    [ngClass]="
      vehicleOptionCtrl.value === VehicleOption.EXISTING
        ? 'option-selected'
        : ''
    "
    (click)="handleOptionSelect(VehicleOption.EXISTING)"
  >
    <div class="flex align-items-center">
      <p-radioButton
        id="existing-vehicle"
        name="existing-vehicle"
        [value]="VehicleOption.EXISTING"
        [formControl]="vehicleOptionCtrl"
        inputId="ingredient1"
      ></p-radioButton>
      <label for="existing-vehicle" class="ml-2">Postojeće vozilo</label>
    </div>
    <div
      [ngClass]="
        vehicleOptionCtrl.value === VehicleOption.EXISTING
          ? 'mt-3 pl-5 block'
          : ' mt-3 pl-5 hidden'
      "
    >
      <atlas-assistance-vehicle-dropdown></atlas-assistance-vehicle-dropdown>
    </div>
  </div>

  <div
    class="flex flex-column gap-3 vehicle-option cursor-pointer"
    [ngClass]="
      vehicleOptionCtrl.value === VehicleOption.NEW ? 'option-selected' : ''
    "
    (click)="handleOptionSelect(VehicleOption.NEW)"
  >
    <div class="flex align-items-center">
      <p-radioButton
        id="new-vehicle"
        name="existing-vehicle"
        [value]="VehicleOption.NEW"
        [formControl]="vehicleOptionCtrl"
        inputId="ingredient2"
      ></p-radioButton>
      <label for="new-vehicle" class="ml-2">Novo vozilo</label>
    </div>
    <div
      [ngClass]="
        vehicleOptionCtrl.value === VehicleOption.NEW
          ? 'mt-3 pl-5 flex flex-column gap-3'
          : ' mt-3 pl-5 hidden'
      "
    >
      <atlas-vehicle-type-dropdown
        [typeCtrl]="vehicleTypeId"
        (onTypeChanged)="handleTypeChanged($event)"
        ctrlStyleClass="w-18rem"
      ></atlas-vehicle-type-dropdown>
      <atlas-brand-autocomplete
        [typeId]="vehicleTypeId.value"
        (onBrandChanged)="handleBrandChanged($event)"
        [isDisabled]="!vehicleTypeId.value"
      ></atlas-brand-autocomplete>
      <atlas-model-autocomplete
        *ngIf="!showModelTxtInput"
        [brandId]="vehicleBrandId"
        (onModelChanged)="handleModelChanged($event)"
        [isDisabled]="!vehicleBrandId"
      ></atlas-model-autocomplete>
      <atlas-input-text
        *ngIf="showModelTxtInput"
        [formCtrl]="vehicleForm.controls.model"
        ctrlPlaceholder="Model vozila"
        [showLabel]="false"
      ></atlas-input-text>
      <atlas-input-text
        [formCtrl]="vehicleForm.controls.licencePlate"
        ctrlPlaceholder="Registarske oznake"
        [showLabel]="false"
        [ctrlKeyFilter]="LicencePlateRegExp"
      ></atlas-input-text>
    </div>
  </div>
  <small
    *ngIf="vehicleForm.invalid && (vehicleForm.dirty || vehicleForm.touched)"
    class="error-info text-red-500"
    >Morate odabrati postojeće ili uneti novo vozilo</small
  >
</div>
