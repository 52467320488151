import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { ChangeSellerDialogComponent } from '../../../cc-leader/clients/change-seller-dialog/change-seller-dialog.component';
import { DialogService } from '@ui/dialog/dialog.service';
import { AddSellerDialogComponent } from '../../../cc-leader/clients/add-seller-dialog/add-seller-dialog.component';
import { ToastMessageComponent } from '@app/cc-leader/toast-message/toast-message/toast-message.component';
import {AuthService} from "@shared/services/auth.service";
import {UserService} from "@shared/services/user.service";

@Component({
  selector: 'atlas-checkbox-action-dialog',
  standalone: true,
  imports: [NgIf, ButtonModule, TooltipModule, ToastMessageComponent],
  templateUrl: './checkbox-action-dialog.component.html',
  styleUrl: './checkbox-action-dialog.component.scss',
})
export class CheckboxActionDialogComponent {
  @Input() selectedPackages: any[] = [];
  @Output() sellerChanged = new EventEmitter<any>();
  selectedClient: any;
  agentName: string = '';
  showToast: boolean = false;
  packageIdsLength: number = 0;
  @Input() distinctVehicleCount: number = 0;
  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);
  @Input() canRenewPackage!: boolean;

  constructor(private _dialogService: DialogService, public userService: UserService) {}

  get hasSelectedPackages(): boolean {
    return this.selectedPackages.length > 0;
  }

  onClientSelected(client: any) {
    this.selectedClient = client;
  }

  handleAgentAssignment() {
    this.dialogConfig.header = 'Dodeli privremenom prodavcu';
    this.dialogConfig.customSubmitButton = {
      label: 'Potvrdi',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;

    const dialogRef = this._dialogService.open(
      AddSellerDialogComponent,
      this.dialogConfig,
    );
    dialogRef.onClose.subscribe((isAgentAssigned) => {
      if (isAgentAssigned) {
        window.location.reload();
      }
    });
  }

  handleSellerChange() {
    this.dialogConfig.header = 'Promeni prodavca';
    this.dialogConfig.customSubmitButton = {
      label: 'Potvrdi',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;

    const dialogRef = this._dialogService.open(
      ChangeSellerDialogComponent,
      this.dialogConfig,
    );
    dialogRef.onClose.subscribe((result) => {
      if (result.isSellerChanged) {
        // Reset showToast to true before displaying the toast again
        this.showToast = true;
        this.agentName = result.agentName;
        this.packageIdsLength = result.packageIdsLength;
        this.sellerChanged.emit(result);
        this.selectedPackages = [];
      }
    });
  }

  handleRenewPackages() {
    alert('Obnova paketa');
  }
}
