import { JsonPipe, NgClass, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import {
  IMediumItem,
  ISourceItem,
  MediumsClient,
  SourcesClient,
} from '@shared/model/atlas.api';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { CheckboxModule } from 'primeng/checkbox';
import { Subscription } from 'rxjs';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { FormsModule } from '@angular/forms';
import {
  OrderForm,
  OrderFormService,
} from '@app/cc-leader/services/order-form.service';
import { ReccomendationFormService } from '@app/cc-leader/services/reccomendation-form.service';
import { MediumsDropdownComponent } from '@shared/components/mediums-dropdown/mediums-dropdown.component';
import { SourcesDropdownComponent } from '@shared/components/sources-dropdown/sources-dropdown.component';
import { ErrorRequiredComponent } from '@ui/errors/error-required/error-required.component';
import { formatCityAndCountry } from '@shared/forms/forms-common';

@Component({
  selector: 'atlas-create-order-form-step-1',
  standalone: true,
  imports: [
    NgIf,
    CheckboxModule,
    InputTextComponent,
    InputPhoneComponent,
    InputMailComponent,
    IconCheckboxComponent,
    FormsModule,
    MediumsDropdownComponent,
    SourcesDropdownComponent,
    ErrorRequiredComponent,
    NgClass,
    JsonPipe,
  ],
  templateUrl: './create-order-form-step-1.component.html',
  styleUrl: './create-order-form-step-1.component.scss',
})
export class CreateOrderFormStep1Component
  implements AfterViewInit, OnInit, OnDestroy
{
  private _subs: Subscription = new Subscription();
  mediumItems: IMediumItem[] = [];
  sourceItems: ISourceItem[] = [];
  selectedOption: 'Fizicko' | 'Pravno' = 'Fizicko';

  orderForm: OrderForm;

  @ViewChild('towingLocationRef') towingLocationRef: any;

  constructor(
    private _mediumsClient: MediumsClient,
    private _sourcesClient: SourcesClient,
    private _orderFormService: OrderFormService,
    private _reccomendService: ReccomendationFormService,
  ) {
    this.orderForm = _orderFormService.getOrderForm;
  }

  ngOnInit(): void {
    this._getMediums();
    this._getSources();

    const reccData = this._reccomendService.getReccData();
    if (reccData) {
      this.orderForm.controls.client.controls.name.patchValue(reccData.name);
      this.orderForm.controls.client.controls.lastName.patchValue(
        reccData.lastName,
      );
      this.orderForm.controls.client.controls.phone.patchValue(
        reccData.phoneNum,
      );
    }
  }

  ngAfterViewInit() {
    this.getTowingAutocomplete();
  }

  private getTowingAutocomplete() {
    if (this.orderForm.value.towingLocation.place) {
      this.towingLocationRef.nativeElement.value =
        this.orderForm.value.towingLocation.place;
    }
    const autocomplete = new google.maps.places.Autocomplete(
      this.towingLocationRef.nativeElement,
      {
        types: ['geocode'],
        componentRestrictions: { country: 'rs' },
      },
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      console.log(place);
      this.orderForm.controls.towingLocation.patchValue({
        place: formatCityAndCountry(place),
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      });
    });
  }

  private _getMediums() {
    this._subs.add(
      this._mediumsClient.getMediums().subscribe((res) => {
        this.mediumItems = res.result.responseList;
      }),
    );
  }

  private _getSources() {
    this._subs.add(
      this._sourcesClient.getSources().subscribe((res) => {
        this.sourceItems = res.result.responseList;
      }),
    );
  }

  selectFizicko() {
    this.selectedOption = 'Fizicko';
  }

  selectPravno() {
    this.selectedOption = 'Pravno';
  }

  handleMediumChange(event: any) {
    this.orderForm.value.client.mediumId = event.value.id;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  handleSourceChange($event: any) {
    this.orderForm.value.client.mediumId = $event.value.id;
  }
}
