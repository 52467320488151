<atlas-scrollable-page
  pageTitle="Dodaj paket"
  submitBtnLabel="Potvrdi kupovinu"
  submitBtnIcon="pi pi-box"
  (onCancelAction)="handleCancelForm()"
  (onSubmitAction)="handleSubmitForm()"
>
  <ng-template #content>
    <div class="main-container">
      <div class="left-section">
        <div class="flex align-items-center justify-content-between">
          <!-- Vehicle Icon and Name -->
          <div *ngIf="!isMojPaket" class="flex gap-3 align-items-center">
            <div
              class="border-round-md flex justify-content-center align-items-center card-icon"
            >
              <i
                *ngIf="vehicleType === 'Putnička vozila'"
                [ngClass]="'text-2xl m-2 icon pi pi-car'"
              ></i>
              <i
                *ngIf="vehicleType === 'Kombi'"
                [ngClass]="'text-2xl m-2 icon pi pi-truck'"
              ></i>
            </div>
            <div
              *ngIf="vehicleType === 'Motori'"
              class="p-2 border-round-md flex justify-content-center align-items-center card-icon"
            >
              <img
                src="assets/images/moto-type.svg"
                class="vehicle-icon"
                alt="Motori"
              />
            </div>
            <div class="flex flex-column justify-content-between gap-1 ml-3">
              <div *ngIf="!vehicleName">
                <p-skeleton width="300px" height="55px"/>
              </div>
              <div class="font-semibold text-base">
                {{ vehicleName }}
              </div>
            </div>
          </div>

          <!-- Active Packages -->
          <div *ngIf="!isMojPaket" class="font-semibold text-base active-packages">
            <div class="active-packages">
              Aktivni paketi:
              <span style="color: var(--text-color)">{{
                  selectedPackagesCount
                }}</span>
              ({{ activePackagesCount }})
            </div>
          </div>
        </div>
        <p-divider></p-divider>
        <div class="package-card">
          <atlas-package-card-list [isForClientLicniPaket]="isMojPaket"></atlas-package-card-list>

          <div *ngIf="isMojPaket" class="mt-3 flex gap-3">
            <div class="w-full">
              <atlas-input-text
                [formCtrl]="addPackageForm.controls.jmbg"
                ctrlLabel="JMBG"
                ctrlPlaceholder="Jedinstveni matični broj građana"
                formCtrlClass="w-full"
              ></atlas-input-text>
            </div>
          </div>
        </div>

        <p-divider></p-divider>

        <atlas-payment-type-options
          [deliveryTypeCtrl]="addPackageForm.controls.deliveryType"
          [paymentTypeCtrl]="addPackageForm.controls.paymentType"
          [deliveryDateCtrl]="addPackageForm.controls.deliveryDate"
        ></atlas-payment-type-options>

        <div class="mt-3">
          <atlas-mediums-dropdown
            [mediumCtrl]="addPackageForm.controls.mediumId"
            [ctrlStyleClass]="'w-17rem'"
            [ctrlLabel]="'Medij'"
          ></atlas-mediums-dropdown>
        </div>

        <div class="mt-3">
          <atlas-sources-dropdown
            [sourceCtrl]="addPackageForm.controls.sourceId"
            [ctrlStyleClass]="'w-17rem'"
            [ctrlLabel]="'Izvor'"
          ></atlas-sources-dropdown>
        </div>

        <p-divider></p-divider>

        <div class="mt-3">
          <div class="title-text">Od kada želite da vam se aktivira paket?</div>
          <div class="mt-3" *ngFor="let type of activationOptions">
            <p-radioButton
              [formControl]="addPackageForm.controls.isUrgent | formControl"
              [value]="type.value"
              [inputId]="type.name"
            ></p-radioButton>
            <label class="ml-3 cursor-pointer" [for]="type.name">{{
                type.name
              }}</label>
          </div>
          <small
            *ngIf="!addPackageForm.controls.isUrgent.valid"
            class="text-red-500"
          >Obavezno polje</small
          >
        </div>
      </div>

      <div class="right-section">
        <div class="purchase-card">
          <div
            class="flex flex-column mt-5"
            *ngIf="!validPackages.length === 0"
          >
            <p-skeleton width="500px" height="300px"/>
          </div>
          <h4 class="purchase-title">Kupovina</h4>
          <div class="purchase-content">
            <div
              *ngIf="validPackages.length === 0"
              class="flex column center-content"
            >
              <img
                [ngSrc]="'assets/images/package-buy-icon.svg'"
                width="64"
                height="64"
                alt="user-image"
                class="mb-2"
              />
              <span class="description-title mb-2">Bez izabranog paketa</span>
              <span class="description-text"
              >Nije izabran jos ni jedan paket. Ovde ce biti prikazana ukupna
                cena narudzbenice.</span
              >
            </div>
            <div *ngIf="validPackages.length > 0">
              <div
                *ngFor="let package of packages.value; let idx = index"
                class="item mt-3"
              >
                <span class="item-txt"
                >{{ idx + 1 + "." }} {{ package.name }}</span
                >
                <span class="item-txt ml-2">{{
                    package.price | currencyNumber
                  }}</span>
              </div>
            </div>
          </div>
          <div *ngIf="validPackages.length > 0" class="purchase-total">
            <span class="total-amount">Ukupno</span>
            <span *ngIf="!isMojPaket" class="total-count">
              {{ packagesSum ?? 0 | currencyNumber }}
            </span>
            <span *ngIf="isMojPaket" class="total-count">
              {{ packages.value[0].price ?? 0 | currencyNumber }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</atlas-scrollable-page>
