<div class="flex flex-column gap-2 preview-wrapper">
  <div>{{ previewPartner.partnerTypeStr }}</div>

  <div class="text-md">{{ previewPartner.name }}</div>
  <div class="flex align-items-center gap-2 w-full">
    <p-avatar icon="pi pi-user" shape="circle"></p-avatar>
    <div class="flex flex-column gap-1">
      <span class="text-sm-medium bolder">{{
        previewPartner.contactPerson
      }}</span>
      <span class="text-sm-medium">{{ previewPartner.contactPhone }}</span>
    </div>
  </div>

  <div
    class="flex flex-column justfy-content-start md:flex-row md:justify-content-between"
  >
    <div class="text-sm-medium">Nosivost</div>
    <div class="text-sm-medium bolder">{{ previewPartner.capacity }}</div>
  </div>
  <div
    class="flex flex-column justfy-content-start md:flex-row md:justify-content-between"
  >
    <div class="text-sm-medium">Broj putnika</div>
    <div class="text-sm-medium bolder">
      {{ previewPartner.numOfPassengers }}
    </div>
  </div>

  <div>
    <div
    class="flex flex-column justfy-content-start md:flex-row md:justify-content-between"
    >
      <div class="text-sm-medium">Vozač</div>
      <div class="text-sm-medium bolder">{{ formService.getForm.value.driverPhone }}</div>
    </div>

    <atlas-driver-dropdown
      [partnerId]="previewPartner.partnerId"
    ></atlas-driver-dropdown>
  </div>
  

  <div class="choose-box">
    <div>
      <span class="text-md">{{ previewPartner.pricePerKm }}</span> rsd/km
    </div>
    <atlas-button
      btnIcon="pi pi-check"
      btnLabel="Izaberi"
      [btnStyle]="ButtonStyle.DIALOG"
      (btnClicked)="handleSelect()"
    ></atlas-button>
  </div>
</div>
