import { Component, OnInit } from '@angular/core';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import {
  AssistanceStatus,
  ClientSearchParam,
  PhoneItem,
} from '@shared/model/atlas.api';
import { NgClass, NgIf } from '@angular/common';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { DividerModule } from 'primeng/divider';
import { InputTextareaComponent } from '@ui/input-textarea/input-textarea.component';
import { ClientForAssistanceAutocompleteComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/client-for-assistance-autocomplete/client-for-assistance-autocomplete.component';
import { AssistanceVehicleComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/assistance-vehicle/assistance-vehicle.component';
import { FailureDescDropdownComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/failure-desc-dropdown/failure-desc-dropdown.component';
import { LocationMapComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/location-map/location-map.component';
import { NumOfPassengersDropdownComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/num-of-passengers-dropdown/num-of-passengers-dropdown.component';
import { PackageForAssistanceComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/package-for-assistance/package-for-assistance.component';
import {
  AssistanceForm,
  AssistanceFormService,
} from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import { AssistanceService } from '@app/sos-agent/assistances/assistance-form/services/assistance.service';
import { ContactPhoneListComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/contact-phone-list/contact-phone-list.component';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { Subscription } from 'rxjs';
import { ContactFormService } from '@app/features/partners/services/contact-form.service';
import { FailureTypeDropdownComponent } from './failure-type-dropdown/failure-type-dropdown.component';
import { UrgencyDropdownComponent } from './urgency-dropdown/urgency-dropdown.component';
import { AssistanceFailureType } from '@shared/model/atlas.api';

@Component({
  selector: 'atlas-assistance-form-step-1',
  standalone: true,
  imports: [
    InlineWrapperComponent,
    InputTextComponent,
    DropdownComponent,
    ClientForAssistanceAutocompleteComponent,
    FormControlPipe,
    ContactPhoneListComponent,
    AssistanceVehicleComponent,
    DividerModule,
    FailureTypeDropdownComponent,
    FailureDescDropdownComponent,
    InputTextareaComponent,
    LocationMapComponent,
    NumOfPassengersDropdownComponent,
    ReactiveFormsModule,
    PackageForAssistanceComponent,
    NgClass,
    InputMailComponent,
    UrgencyDropdownComponent,
    NgIf
  ],
  templateUrl: './assistance-form-step-1.component.html',
  styleUrl: './assistance-form-step-1.component.scss',
})
export class AssistanceFormStep1Component implements OnInit {
  private _subs: Subscription = new Subscription();

  searchOptionCtrl: FormControl;
  form: AssistanceForm;
  status: number | null = null;
  isDisabled: boolean = false;

  searchOptions: any = [
    { label: 'Telefon', value: ClientSearchParam.PHONE },
    { label: 'Klijent', value: ClientSearchParam.NAME },
    { label: 'Tablice', value: ClientSearchParam.LICENCE_PLATE },
  ];

  showAccidentCulprit: boolean;

  constructor(
    private service: AssistanceService,
    private formService: AssistanceFormService,
    private _phonesService: ContactFormService,
  ) {
    this.searchOptionCtrl = this.service.clientSearchParamCtrl;
    this.form = formService.getForm;
    this.service.currentStep = 0;
    this.showAccidentCulprit = formService.getForm.controls.failureType.value === AssistanceFailureType.TRAFFIC_ACCIDENT;
  }

  ngOnInit() {
    this._subs.add(
      this._phonesService.phones$.subscribe((phones) => {
        this.form.controls.client;
        this.form.controls.client.controls.phones.patchValue(
          phones.value.map((phone) => ({
            id: phone.id,
            label: phone.label,
          })),
        );
      })
    );

    this._subs.add(
      this.form.controls.failureType.valueChanges.subscribe(value => {
        if (value === AssistanceFailureType.TRAFFIC_ACCIDENT) {
          this.showAccidentCulprit = true;
        } else {
          this.showAccidentCulprit = false;
          this.form.controls.accidentCulprit.reset();
        }
      })
    );

    this.checkStatus();
    if (this.form.value.client.phones.length > 0) {
      this._phonesService.resetPhones();
      this.addPhones();
    } else {
      this._phonesService.resetPhones();
      this.handleAddEmptyContact();
    }
  }

  handleAddEmptyContact() {
    const newEmptyPhone = this._phonesService.getPhoneForm();
    this._phonesService.pushPhone(newEmptyPhone.value as PhoneItem);
  }

  private addPhones() {
    const phoneValues = this.formService.getForm.value.client
      .phones as PhoneItem[];
    phoneValues.forEach((element) => {
      this._phonesService.pushPhone(element);
    });
  }

  updatePhoneItems(event: any) {
    this.form.controls.client.controls.phones.patchValue(event);
  }

  checkStatus() {
    this.isDisabled = this.formService.getStatus() === AssistanceStatus.REQUEST;
  }
}
