<atlas-scrollable-page-with-steps
        submitBtnLabel="Dodaj asistenciju"
        [stepItems]="menuSteps"
        [shouldDisplayBack]="shouldDisplayBack"
        [shouldDisplayFinish]="shouldDisplayFinish"
        (onCancelAction)="handleCancel()"
        (onBackAction)="handleBack()"
        (onNextAction)="handleNext()"
>
    <ng-template #content>
        <ng-container *ngIf="!showLoader; else skeleton">
            <router-outlet></router-outlet>
        </ng-container>
    </ng-template>
</atlas-scrollable-page-with-steps>


<ng-template #skeleton>
  <div class="flex flex-column gap-4 mt-3">
    <ng-container *ngTemplateOutlet="clientSkeleton"></ng-container>
    <ng-container *ngTemplateOutlet="vehicleSkeleton"></ng-container>
    <ng-container *ngTemplateOutlet="mapSkeleton"></ng-container>
  </div>
</ng-template>

<ng-template #clientSkeleton>
  <div class="border-round border-1 surface-border p-4 surface-card">
    <ul class="m-0 p-0 list-none">
      <li class="mb-3">
        <div class="flex gap-7">
          <div class="w-20rem">
            <p-skeleton width="100%" height="40px" />
          </div>
          <div class="w-full">
            <p-skeleton width="100%" styleClass="mb-2" height="40px" />
          </div>
        </div>
      </li>
      <li class="mb-3">
        <div class="flex gap-7">
          <div class="w-20rem">
            <p-skeleton width="100%" height="40px" />
          </div>
          <div class="w-full">
            <p-skeleton width="100%" styleClass="mb-2" height="40px" />
          </div>
        </div>
      </li>
      <li class="mb-3">
        <div class="flex gap-7">
          <div class="w-20rem">
            <p-skeleton width="100%" height="40px" />
          </div>
          <div class="w-full">
            <p-skeleton width="100%" styleClass="mb-2" height="40px" />
          </div>
        </div>
      </li>
      <li class="mb-3">
        <div class="flex gap-7">
          <div class="w-20rem">
            <p-skeleton width="100%" height="60px" />
          </div>
          <div class="w-full">
            <p-skeleton width="100%" styleClass="mb-2" />
            <p-skeleton width="100%" styleClass="mb-2" />
            <p-skeleton width="100%" />
          </div>
        </div>
      </li>
      <li>
        <div class="flex gap-7">
          <div class="w-20rem">
            <p-skeleton width="100%" height="40px" />
          </div>
          <div class="w-full">
            <p-skeleton width="100%" styleClass="mb-2" height="40px" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #vehicleSkeleton>
  <div class="border-round border-1 surface-border p-4 surface-card">
    <div class="flex gap-7">
      <div class="w-20rem">
        <p-skeleton width="100%" height="60px" />
      </div>
      <div class="w-full">
        <p-skeleton width="100%" styleClass="mb-5" height="200px" />
        <p-skeleton width="100%" styleClass="mb-2" height="200px" />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mapSkeleton>
    <div class="border-round border-1 surface-border p-4 surface-card">
        <div class="flex gap-7">
            <div class="w-20rem">
                <p-skeleton width="100%" height="60px" />
            </div>
            <div class="w-full">
                <p-skeleton width="100%" styleClass="mb-2" height="30px" />
                <p-skeleton width="100%" styleClass="mb-2" height="30px" />
                <p-skeleton width="500px" height="500px" />
            </div>
        </div>
        <div class="flex gap-7 mt-3">
            <div class="w-20rem">
                <p-skeleton width="100%" height="60px" />
            </div>
            <div class="w-full mb-3">
                <p-skeleton width="100%" styleClass="mb-2" height="30px" />
                <p-skeleton width="100%" styleClass="mb-2" height="30px" />
            </div>
        </div>
    </div>
</ng-template>