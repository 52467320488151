import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageComponent } from '@shared/components/page/page.component';
import { CampaignsClient, IPaginationResponseOfLeadCallResponse } from '@shared/model/atlas.api';
import { LocationCcService } from '@shared/services/location-cc.service';
import { LocationService } from '@shared/services/location.service';
import { ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { ActionMode, SelectMode, TableConfig } from '@ui/table/table-common';
import { TableServiceLocal } from '@ui/table/table-service-local.service';
import { TableComponent } from '@ui/table/table.component';
import { TableLazyLoadEvent, TableService } from 'primeng/table';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'atlas-scheduled-calls-table',
  standalone: true,
  imports: [PageComponent, TableComponent],
  templateUrl: './scheduled-calls-table.component.html',
  styleUrl: './scheduled-calls-table.component.scss'
})
export class ScheduledCallsTableComponent implements OnInit {

  protected tableConfig: TableConfig;
  public paginatedData: any;

  constructor(private _camapaignClient: CampaignsClient,
    private _tableService: TableServiceLocal,
    private location: LocationCcService
  ) {
    this.tableConfig = {
      selectMode: SelectMode.SINGLE,
      showHeader: true,
      columns: [],
      rowsPerPage: 10,
    };

    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
  }

  ngOnInit(): void {
    this.setTableConfig().then();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez zakazanih poziva',
        description: 'Ovde će biti prikazani zakazani pozivi',
        btnLabel: 'Dodaj poziv',
        action: () => alert('ToDo')
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first / event.rows + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.loadTable();
      },
      columns: [
        {
          header: 'Ugovor',
          field: 'client',
          type: 'text',
        },
        {
          header: 'Vreme',
          field: 'timeOfCall',
          type: 'text',
        },
        {
          header: 'Kampanja',
          field: 'campaign',
          type: 'text',
        },
        {
          header: 'Komentar',
          field: 'comment',
          type: 'text',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Preuzmi poziv',
          label: 'Preuzmi poziv',
          svgName: 'phone-icon',
          actionClass: 'p-2 ml-5 gap-2',
          actionSeverity: ButtonSeverity.SECONDARY,
          actionStyle: ButtonStyle.OUTLINED,
          shouldDisplayByCondition: () => true,
          callback: (rowIdx: number) => {
            const rowData = this._getPaginatedItem(rowIdx);
            this.handleCall(rowData);
          },
        },
        {
          mode: ActionMode.SINGLE,
          svgName: 'comment-Icon',
          actionSeverity: ButtonSeverity.SECONDARY,
          shouldDisplayByCondition: () => true,
          callback: (rowIdx: number) => {
            const rowData = this._getPaginatedItem(rowIdx);
            this.commentPreview(rowIdx)
          },
        },
        {
          mode: ActionMode.MULTI,
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
          },
          shouldDisplayByCondition: () => true,
        },
      ]
    });
  }



  loadTable() {
  this._tableService.setIsLoading();
  this._loadCalls().then((res) => {
    console.log(res)
    this.paginatedData = {
      ...res.result,
      data: res.result.data.map((item: any) => ({
        client: item.lead.client,
        id: item.lead.leadId,
        timeOfCall: formatDate(
          item.callAt,
          'dd MMM yyyy • HH:mm',
          'en-US',
        ),
        campaign: item.campaignName,
        comment: item.comment
      })),
    };
    this._tableService.resetIsLoading();
  });
}

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  handleCall(rowData: any) {
    this.location.routeToStartCampaign(rowData.id)
  }

  commentPreview(rowIdx: number) {
    alert('Komentari');
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  private async _loadCalls() {
    return await firstValueFrom(
      this._camapaignClient.getScheduledCalls(),
    );
  }

}
