<atlas-dropdown
  [options]="searchOptions"
  [formCtrl]="typeCtrl"
  [ctrlStyleClass]="ctrlStyleClass"
  ctrlPlaceholder="Izaberi tip"
  (optionChanged)="handleTypeChange($event)"
  [showLabel]="false"
  [hideErrors]="hideErrors"
>
</atlas-dropdown>
