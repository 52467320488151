import { NavigationExtras, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationB2bService {
  private readonly _rolePrefix = 'b2b';

  constructor(private _router: Router) {}

  private _routeToUrl(url: string, navigationData?: NavigationExtras) {
    this._router.navigate([url], navigationData).then();
  }

  routeToHomePage() {
    this._routeToUrl(`/${this._rolePrefix}/clients`);
  }
}
