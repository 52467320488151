import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  ClientAutocompleteItem,
  ClientClient,
  IClientAutocompleteItem,
} from '@shared/model/atlas.api';
import {
  ErrorRequiredComponent,
  shouldDisplayRequiredError,
} from '@ui/errors/error-required/error-required.component';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { firstValueFrom, retry, Subscription } from 'rxjs';
import { NgIf } from '@angular/common';

@Component({
  selector: 'atlas-clients-autocomplete',
  standalone: true,
  imports: [InputSearchComponent, ErrorRequiredComponent, NgIf],
  templateUrl: './clients-autocomplete.component.html',
  styleUrl: './clients-autocomplete.component.scss',
})
export class ClientsAutocompleteComponent {
  clients: IClientAutocompleteItem[] = [];

  @Input() formCtrl: FormControl;
  @Output() public onClientChanged =
    new EventEmitter<IClientAutocompleteItem>();

  constructor(private _clientClient: ClientClient) {}

  searchClients(searchValue: string) {
    this._load(searchValue);
  }

  private _load(keyword: string) {
    this.getClients(keyword).then((res) => {
      this.clients = [...res.result.clients];
    });
  }

  private async getClients(keyword: string) {
    return await firstValueFrom(
      this._clientClient.getClientsAutocomplete(keyword),
    );
  }

  onSelectChange(item: any) {
    if (!item || !item.value) {
      return;
    }

    this.onClientChanged.emit(new ClientAutocompleteItem(item.value));
  }

  protected readonly shouldDisplayRequiredError = shouldDisplayRequiredError;
}
