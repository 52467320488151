import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';
import { ISourceItem, SourcesClient } from '@shared/model/atlas.api';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MultiselectComponent } from '@ui/multiselect/multiselect.component';

@Component({
  selector: 'atlas-sources-multiselect',
  standalone: true,
  imports: [MultiselectComponent],
  templateUrl: './sources-multiselect.component.html',
  styleUrl: './sources-multiselect.component.scss',
})
export class SourcesMultiselectComponent implements OnInit, OnDestroy {
  private _subs: Subscription;

  sourceOptions: ISourceItem[] = [];
  formGroup: FormGroup<SourceForm>;

  @Input() sourceCtrl: FormArray;

  constructor(private _client: SourcesClient) {
    this.formGroup = new FormGroup({
      sources: new FormControl([]),
    });
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this._load();
  
    this._subs.add(
      this.formGroup.valueChanges.subscribe((value) => {
        this.sourceCtrl.clear();
        value.sources.forEach((x) => {
          this.sourceCtrl.push(new FormControl(x.id));
        });
      }),
    );
  
    this._subs.add(
      this.sourceCtrl.valueChanges.subscribe((value) => {
        this.formGroup.controls.sources.setValue(value.map((id) =>
          this.sourceOptions.find((opt) => opt.id === id) || null
        ), { emitEvent: false });
      }),
    );
  }

  private _load() {
    this._getStatuses().then((res) => {
      this.sourceOptions = res.result.responseList;
    });
  }

  private async _getStatuses() {
    return await firstValueFrom(this._client.getSources());
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}

export type SourceForm = {
  sources: FormControl<ISourceItem[]>;
};
