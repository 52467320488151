import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import {
  DurationType,
  DurationTypeDropdownItem,
  InitClient,
} from '@shared/model/atlas.api';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'atlas-duration-type-dropdown',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './duration-type-dropdown.component.html',
  styleUrl: './duration-type-dropdown.component.scss',
})
export class DurationTypeDropdownComponent implements OnInit, OnDestroy {
  searchOptions: SelectItem[] = [];

  private _subs: Subscription;
  @Input() typeCtrl: FormControl<string>;
  @Input() hideErrors: boolean = false;
  @Input() ctrlStyleClass: string = 'w-full';

  types: DurationTypeDropdownItem[] | undefined;
  clientId: string | undefined = undefined;

  @Output() public onTypeChanged = new EventEmitter<string>();

  constructor(private _client: InitClient) {
    this._subs = new Subscription();
  }

  ngOnInit() {
    this._load();
  }

  private _load() {
    this._getTypes().then((res) => {
      this.types = [...res.result.items];

      this.searchOptions = this.types!.map((v) => ({
        label: v.label,
        value: v.type,
      }));

      // this.typeCtrl.patchValue(
      //   this.searchOptions.find((x) => x.value === DurationType.MINUTE).value,
      // );
    });
  }

  private async _getTypes() {
    return await firstValueFrom(this._client.getDurationTypeDropdown());
  }

  handleTypeChange($event: any) {
    this.onTypeChanged.emit($event.value);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
