<div class="subtitle-sidebar mb-4">Putanja</div>
<!-- SARADNIK -->
<div class="flex align-items-top gap-2 location-item">
    <span class="dot mr-2">
      <i class="pi pi-check" style="color: green"></i>
    </span>
    <div class="location">
      <div class="title-info">Saradnik</div>
      <span class="box-desc mt-2">
        {{ previewData?.route.driverStartLocation?.place || "--" }}
      </span>
      <div class="mt-2 flex gap-1">
        <i class="pi pi-clock"></i>
        <span class="box-desc">{{
          previewData?.route.driverAssignedTime || "--:--"
        }}</span>
      </div>
    </div>
  </div>
  <!-- MESTO KVARA -->
  <div class="flex align-items-top gap-2 location-item no-dot-border">
    <span class="dot mr-2">
      <i class="pi pi-check" style="color: green"></i>
    </span>
    <div class="location">
      <div class="title-info">Mesto kvara (A)</div>
      <span class="box-desc mt-2">
        {{ previewData?.route.failureLocation?.place || "--" }}
      </span>
      <div class="mt-2 flex gap-1">
        <i class="pi pi-clock"></i>
        <span class="box-desc">{{
          previewData?.route.driverImagesFailureTime || "--:--"
        }}</span>
      </div>
      <atlas-image-preview
        [images]="previewData?.route.failureImages"
        imageClass="mt-2"
      ></atlas-image-preview>
      <div
                  (click)="showComment()"
                  *ngIf="previewData?.route.failureComment"
                  class="comments pointer"
                >
                  <i class="pi pi-comment"></i> 1 komentar
                </div>
    </div>
  </div>
  <!-- ODREDISTE B -->
  <div
  class="flex align-items-top gap-2 location-item no-dot-border"
>
  <span *ngIf="!previewData?.route.destiantionTime" class="dot mr-2 margin-top-s">
    <img [src]="'assets/images/circle.svg'" />
  </span>
  <div *ngIf="!previewData?.route.destiantionTime" class="location">
    <div
      class="title-info"
    >
      Odredište (B)
    </div>
    <span class="box-desc mt-2">
      {{ previewData?.route.destinationLocation?.place || "--" }}
    </span>
    <div class="mt-2 flex gap-1">
      <i class="pi pi-clock"></i>
      <span class="box-desc">{{
        previewData?.route.destiantionTime || "--:--"
      }}</span>
    </div>
    <atlas-image-preview
      [images]="previewData?.route.destinationImages"
      imageClass="mt-2"
    ></atlas-image-preview>
  </div>
</div>
<div class="flex align-items-top gap-2 location-item no-dot-border">
    <span *ngIf="previewData?.route.destiantionTime" class="dot mr-2">
      <i class="pi pi-check" style="color: green"></i>
    </span>
    <div *ngIf="previewData?.route.destiantionTime" class="location">
      <div class="title-info">Odredište (B)</div>
      <span class="box-desc mt-2">
        {{ previewData?.route.destinationLocation?.place }}
      </span>
      <div class="mt-2">
        <i class="pi pi-clock"></i>
        <span class="box-desc">{{
          previewData?.route.destiantionTime || "--:--"
        }}</span>
      </div>
      <atlas-image-preview
        [images]="previewData?.route.destinationImages"
        imageClass="mt-2"
      ></atlas-image-preview>
    </div>
  </div>
  <p-dialog
  header="Failure Comment"
  [(visible)]="displayComment"
  [modal]="true"
  [closable]="true"
  [dismissableMask]="true"
  [draggable]="false"
>
  <ng-template pTemplate="content">
    <div class="dialog-content">
      {{ previewData?.route.failureComment }}
    </div>
  </ng-template>
</p-dialog>