<atlas-multiselect
  [formGroup]="formGroup"
  formCtrlName="paymentStatuses"
  [options]="statusOptions"
  optionLabel="label"
  ctrlLabel="Statusi uplate"
  ctrlDataKey="status"
  ctrlPlaceholder="Odaberi statuse uplate"
  ctrlStyleClass="w-full"
/>
