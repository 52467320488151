<p-scrollPanel [style]="{ height: '100%' }">
<div class="title-info mt-5">
  Komentari <p-badge [value]="paginatedData?.data.length"></p-badge>
</div>
<div class="comment-card" *ngFor="let item of paginatedData?.data">
  <div class="profile">
    <img
      class="profile-picture"
      ngSrc="assets/images/user-colored.svg"
      alt="User"
      width="32"
      height="32"
    />
    <div class="profile-info">
      <div class="user-details">
        <span class="user-name">{{ item.user.name }}</span>
        <span class="created-at">{{ formatDate(item.createdAt) }}</span>
      </div>
      <div class="comment-content">
        <div class="comment-text">{{ item.comment }}</div>
        <div class="badges" *ngIf="item.oldStatusBadge || item.statusBadge">
          <span
            class="badge"
            *ngIf="item.oldStatusBadge"
            [ngClass]="'border-1 lead-status-' + item.oldStatusBadge.value"
          >
            {{ item.oldStatusBadge.label }}
          </span>
          <span class="arrow" *ngIf="item.oldStatusBadge && item.statusBadge">→</span>
          <span
            class="badge"
            *ngIf="item.statusBadge"
            [ngClass]="'border-1 lead-status-' + item.statusBadge.value"
          >
            {{ item.statusBadge.label }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
</p-scrollPanel>
