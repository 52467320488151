<p-sidebar
  [(visible)]="showSidebar"
  position="right"
  styleClass="w-27rem left-rounded"
>
  <ng-container *ngIf="packageData; else skeleton">
    <ng-template pTemplate="header">
      <div class="text-2xl font-semibold">Detalji Paketa</div>
    </ng-template>
    <ng-container>
      <div class="flex flex-column gap-3">
        <div class="flex justify-content-between align-items-center">
          <img
            ngSrc="assets/images/bag-icon.svg"
            width="48"
            height="48"
            alt="user-image"
          />
          <div class="flex flex-column gap-2">
            <div class="label-sm">Broj ugovora</div>
            <div class="label-sm-info">#{{ packageData.number }}</div>
          </div>
        </div>

        <div class="status-column">
          <span
          class="table-badge"
          [ngClass]="'packages-' + packageData.statusBadge.value"
        >
          <span class="dot"></span>
          {{ packageData.statusBadge.label }}
        </span>
        </div>

        <div class="main-title-sidebar mb-3">
          {{ packageData.package }}
        </div>
      </div>

      <div class="flex flex-column gap-4">
        <div class="flex justify-content-between align-items-center">
          <div class="flex flex-column justify-content-between gap-2">
            <div class="label-sm">Početak ugovora</div>
            <div *ngIf="packageData.start" class="label-sm-info">{{ packageData.start }}</div>
            <div *ngIf="!packageData.start" class="label-sm-info">Nije aktivan</div>
          </div>
          <div>
            <i class="pi pi-arrow-right"></i>
          </div>
          <div class="flex flex-column justify-content-between gap-2">
            <div class="label-sm">Kraj ugovora</div>
            <div *ngIf="packageData.end" class="label-sm-info">{{ packageData.end }}</div>
            <div *ngIf="!packageData.end" class="label-sm-info">--</div>
          </div>
        </div>
      </div>
      

      <div class="flex flex-column gap-4">
        <div class="flex justify-content-between">
          <div class="subtitle-sidebar mt-4">Asistencije</div>
        </div>
        <div class="flex-service-box">
          <atlas-service-availability
            *ngFor="let service of packageData.availableServices"
            [serviceName]="service.name"
            [isAvailable]="service.isAvailable"
          ></atlas-service-availability>
        
          <atlas-service-availability
            *ngFor="let service of packageData.usedServices"
            [serviceName]="service.name"
            [isAvailable]="service.isAvailable"
            [usedDate]="service.usedDate"
            [assistanceId]="service.assistanceId"
          ></atlas-service-availability>
        </div>
      </div>
      <div class="flex flex-column gap-3 package-box mt-3">
        <div class="flex gap-3 align-items-center">
          <div
            class="border-round-md flex justify-content-center align-items-center card-icon"
          >
            <i [ngClass]="'text-2xl m-2 icon pi pi-car'"></i>
          </div>
          <div class="flex flex-column justify-content-between gap-1 ml-5">
            <div class="font-semibold text-base">
              {{ packageData.vehicle }}
            </div>
            <div class="font-medium text-sm licence-plate">
              {{ packageData.licencePlate | licencePlate }}
            </div>
          </div>
        </div>
        <div class="flex flex-column">
          <div class="flex align-items-center justify-content-between mb-3">
            <div class="flex align-items-center">
              <i class="pi pi-user icon pl-2"></i>
              <div class="font-medium text-base user-txt ml-2">
                {{ packageData.client }}
              </div>
            </div>
            <div class="flex align-items-center gap-2 pointer">
              <div
                (click)="handleOpenProfile()"
                class="font-semibold text-base profile-txt"
              >
                Vidi profil
              </div>
              <i class="pi pi-angle-right"></i>
            </div>
          </div>
          <div class="flex justify-content-between gap-2">
            <div class="renew-btn">
              <atlas-button
                btnLabel="Obnovi paket"
                btnIcon="pi pi-refresh"
                btnClass="w-full h-full"
                [btnSeverity]="ButtonSeverity.PRIMARY"
                [btnStyle]="ButtonStyle.BASIC"
                (btnClicked)="handleObnoviPaket()"
              >
              </atlas-button>
            </div>
          </div>
        </div>
      </div>
      <p-divider></p-divider>

      <div class="flex flex-column gap-3">
        <div class="text-2xl font-semibold">Detalji</div>
        <div class="flex justify-content-between">
          <div class="label-sm">Prodavac</div>
          <div class="label-sm-info">{{ packageData.seller }}</div>
        </div>
        <div class="flex justify-content-between">
          <div class="label-sm">Način plaćanja</div>
          <div class="label-sm-info">{{ packageData.paymentType }}</div>
        </div>
        <div class="flex justify-content-between">
          <div class="label-sm">Cena</div>
          <div class="label-sm-info">{{ packageData.price }}</div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #skeleton>
    <ng-template pTemplate="header">
      <div class="text-2xl font-semibold">Detalji Paketa</div>
    </ng-template>
    <ng-container>
      <div class="flex flex-column gap-3">
        <div class="flex justify-content-between align-items-center">
          <img
            ngSrc="../../../../assets/images/suitcase.svg"
            width="48"
            height="48"
            alt="user-image"
          />
          <div class="flex flex-column gap-2">
            <div class="label-sm">Broj ugovora</div>
            <div class="w-10rem">
              <p-skeleton width="100%" height="33px"></p-skeleton>
            </div>
          </div>
        </div>

        <div class="mt-2">
          <span class="custom-badge">
            <span class="status-dot"></span>
            <div class="w-6rem">
              <p-skeleton
                width="100%"
                height="33px"
                borderRadius="16px"
              ></p-skeleton>
            </div>
          </span>
        </div>

        <div class="w-10rem">
          <p-skeleton width="100%" height="50px"></p-skeleton>
        </div>
      </div>

      <div class="flex flex-column gap-4 mt-2">
        <div class="flex justify-content-between align-items-center">
          <div class="flex flex-column justify-content-between gap-2">
            <div class="label-sm">Početak ugovora</div>
            <div class="w-10rem">
              <p-skeleton width="100%" height="33px"></p-skeleton>
            </div>
          </div>
          <div>
            <i class="pi pi-arrow-right"></i>
          </div>
          <div class="flex flex-column justify-content-between gap-2">
            <div class="label-sm">Kraj ugovora</div>
            <div class="w-10rem">
              <p-skeleton width="100%" height="33px"></p-skeleton>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-column gap-3 package-box mt-3">
        <div class="flex gap-3 align-items-center">
          <div
            class="border-round-md flex justify-content-center align-items-center card-icon"
          >
            <i [ngClass]="'text-2xl m-2 icon pi pi-car'"></i>
          </div>
          <div class="flex flex-column justify-content-between gap-1 ml-5">
            <div class="font-semibold text-base">
              <div class="w-10rem">
                <p-skeleton width="100%" height="33px"></p-skeleton>
              </div>
            </div>
            <div class="font-medium text-sm licence-plate">
              <div class="w-10rem">
                <p-skeleton width="100%" height="33px"></p-skeleton>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-column">
          <div class="flex align-items-center justify-content-between mb-3">
            <div class="flex align-items-center">
              <i class="pi pi-user icon pl-2"></i>
              <div class="font-medium text-base user-txt ml-2">
                <div class="w-10rem">
                  <p-skeleton width="100%" height="33px"></p-skeleton>
                </div>
              </div>
            </div>
            <div class="flex align-items-center gap-2 pointer">
              <div
                (click)="handleOpenProfile()"
                class="font-semibold text-base profile-txt"
              >
                Vidi profil
              </div>
              <i class="pi pi-angle-right"></i>
            </div>
          </div>
          <div class="flex justify-content-between gap-2">
            <div class="renew-btn">
              <atlas-button
                btnLabel="Obnovi paket"
                btnIcon="pi pi-refresh"
                btnClass="w-full h-full"
                [btnSeverity]="ButtonSeverity.PRIMARY"
                [btnStyle]="ButtonStyle.BASIC"
                (btnClicked)="handleObnoviPaket()"
              >
              </atlas-button>
            </div>
            <div>
              <atlas-button
                btnIcon="pi pi-ellipsis-v"
                btnClass="w-full px-2 h-full"
                [btnSeverity]="ButtonSeverity.SECONDARY"
                [btnStyle]="ButtonStyle.TEXT"
                (btnClicked)="handle()"
              >
              </atlas-button>
            </div>
          </div>
        </div>
      </div>

      <p-divider></p-divider>

      <div class="flex flex-column gap-3">
        <div class="text-2xl font-semibold">Detalji</div>
        <div class="flex justify-content-between">
          <div class="label-sm">Prodavac</div>
          <div class="w-10rem">
            <p-skeleton width="100%" height="33px"></p-skeleton>
          </div>
        </div>
        <div class="flex justify-content-between">
          <div class="label-sm">Izvor</div>
          <div class="w-10rem">
            <p-skeleton width="100%" height="33px"></p-skeleton>
          </div>
        </div>
        <div class="flex justify-content-between">
          <div class="label-sm">Medijum</div>
          <div class="w-10rem">
            <p-skeleton width="100%" height="33px"></p-skeleton>
          </div>
        </div>
        <div class="flex justify-content-between">
          <div class="label-sm">Način plaćanja</div>
          <div class="w-10rem">
            <p-skeleton width="100%" height="33px"></p-skeleton>
          </div>
        </div>
        <div class="flex justify-content-between">
          <div class="label-sm">Cena</div>
          <div class="w-10rem">
            <p-skeleton width="100%" height="33px"></p-skeleton>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>
</p-sidebar>
