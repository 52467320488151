import {
  NgFor,
  NgIf,
  NgClass,
  NgForOf,
  JsonPipe,
  CommonModule,
  CurrencyPipe,
} from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrandAutocompleteComponent } from '@app/features/autocompletes/brand-autocomplete/brand-autocomplete.component';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { CheckboxComponent } from '@ui/checkbox/checkbox.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { CurrencyNumberPipe } from '@shared/pipes/currency-number.pipe';
import { DeliveryType, PaymentType } from '@shared/model/atlas.api';
import { OrderFormService } from '@app/cc-leader/services/order-form.service';
import { PackageCardListService } from '@app/cc-leader/services/package-card-list.service';

registerLocaleData(localeDe);

@Component({
  selector: 'atlas-create-order-form-step-3',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgClass,
    NgForOf,
    CheckboxModule,
    InputTextComponent,
    InputPhoneComponent,
    InputMailComponent,
    InputAddonComponent,
    DropdownComponent,
    IconCheckboxComponent,
    BrandAutocompleteComponent,
    FormsModule,
    FormControlPipe,
    JsonPipe,
    RadioButtonModule,
    CheckboxComponent,
    ReactiveFormsModule,
    CommonModule,
    CurrencyPipe,
    CurrencyNumberPipe,
  ],
  templateUrl: './create-order-form-step-3.component.html',
  styleUrl: './create-order-form-step-3.component.scss',
})
export class CreateOrderFormStep3Component implements OnInit {
  orderForm: any;
  packages: any;
  packagesSum: number = 0;

  paymentType: any = [
    { label: 'Corvus Pay', value: PaymentType.CORVUS_PAY },
    { label: 'Gotovina', value: PaymentType.CASH },
    { label: 'Uplatnica', value: PaymentType.PAYMENT_SLIP },
  ];
  deliveryType: any = [
    { label: 'eUgovor', value: DeliveryType.E_CONTRACT },
    { label: 'Lično preuzimanje', value: DeliveryType.PICK_UP },
    { label: 'Bex', value: DeliveryType.BEX },
    { label: 'Bex besplatna', value: DeliveryType.BEX_FREE },
    { label: 'Bex bez otkupa', value: DeliveryType.BEX_WITHOUT_DEPOSIT },
    { label: 'Kurir NS', value: DeliveryType.COURIER_NS },
    { label: 'Promo-sezonac', value: DeliveryType.PROMO_SEASONAC },
  ];
  partnerTypes: any = [
    {
      name: 'Aktivacija odmah (paket se aktivira odmah nakon evidentiranja uplate)',
      value: true,
    },
    {
      name: 'Standardna aktivacija (7 dana od evidentiranja uplate)',
      value: false,
    },
  ];

  constructor(
    private _orderFormService: OrderFormService,
    private _packageListService: PackageCardListService,
  ) {
    this.orderForm = this._orderFormService.getOrderForm;
    this.packages = this._packageListService.getPackages();
    this.packagesSum = this.packages?.value.reduce(
      (a, b) => a + b.packagePrice,
      0,
    );
  }

  ngOnInit(): void {}
}
