<div class="packages-section">
  <!-- 1st Section: Paketi vozila -->
  <div class="vehicle-packages">
    <atlas-vehicle-list-client-info></atlas-vehicle-list-client-info>
  </div>
  <!-- 2nd Section: Lični paket -->
  <div class="personal-package">
    <atlas-own-package-client-info></atlas-own-package-client-info>
  </div>
</div>
<!-- 3rd Section: Istorija kupovine -->
<div class="purchase-history">
  <div class="subtitle-text">Istorija kupovine</div>
  <div class="mt-3">
    <atlas-table
      [paginatedData]="paginatedData"
      [tableConfig]="tableConfig"
    ></atlas-table>
  </div>
</div>
