import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import {
  IUpdateCampaignCommandBody,
} from '@shared/model/atlas.api';

@Injectable({
  providedIn: 'root',
})
export class CampaignFormService {
  private _editCampaignBody: EditCampaignForm;
  private campaignId: string | null = null;

  constructor(private _fb: FormBuilder) {
    this._editCampaignBody = this._initEditForm();
  }


  setCampaignId(id: string): void {
    this.campaignId = id;
  }

  getCampaignId(): string | null {
    return this.campaignId;
  }

  getEditForm() {
    if (!this._editCampaignBody) {
      this._editCampaignBody = this._initEditForm();
    }
    return this._editCampaignBody;
  }

  private _initEditForm(model?: IUpdateCampaignCommandBody) {
    return this._fb.group<ControlsOf<IUpdateCampaignCommandBody>>({
      name: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      description: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.maxLength(100)],
      }),
    });
  }

  reset() {
    this._editCampaignBody = undefined;
  }
}

export declare type EditCampaignForm = FormGroup<
  ControlsOf<IUpdateCampaignCommandBody>
>;
