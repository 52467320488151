<atlas-dropdown
  [options]="statusOptions"
  optionLabel="label"
  ctrlDataKey="status"
  [formCtrl]="statusCtrl"
  ctrlStyleClass="w-full"
  [hideErrors]="true"
  [ctrlPlaceholder]="placeholder"
>
<!-- Template for the selected item -->
<ng-template #selectedItem let-obj>
    <div class="selected-item">
      <span class="color-circle dot" [ngClass]="'lead-status-' + obj.status"></span>
      {{ obj.label }}
    </div>
  </ng-template>

  <!-- Template for each item in the dropdown list -->
  <ng-template #item let-obj>
    <div class="dropdown-item">
      <span class="color-circle dot" [ngClass]="'lead-status-' + obj.status"></span>
      {{ obj.label }}
    </div>
  </ng-template>
</atlas-dropdown>

