<atlas-dropdown
  [options]="urgencyOptions"
  [formCtrl]="urgencyCtrl"
  ctrlPlaceholder="Unesite hitnost"
  ctrlStyleClass="w-30rem"
  [showLabel]="false"
  formCtrlId="urgency"
>
</atlas-dropdown>

