<div class="flex flex-column md:flex-row gap-3">
    <atlas-page-info-card 
        [cardTitle]="'Iznos aktivnih ugovora'"
        cardIcon="pi pi-file-edit"
        cardNumber= "{{ activeContracts }}"
        class="flex-1"
    ></atlas-page-info-card>
    <atlas-page-info-card
        [cardTitle]="'Iznos neaktivnih ugovora'"
        cardIcon="pi pi-file-excel"
        cardNumber="{{ inactiveContracts }}"
        class="flex-1"
    ></atlas-page-info-card>
    <atlas-page-info-card
        [cardTitle]="'Trošak za firmu'"
        cardIcon="pi pi-calculator"
        cardNumber="{{ companyCost | currencyNumber }}"
        class="flex-1"
    ></atlas-page-info-card>
</div>


