<atlas-dropdown
  [options]="failureNames"
  [formCtrl]="failureTypeCtrl"
  [ctrlPlaceholder]="failureTypeCtrl.value || 'Unesite opis kvara'"
  [isEditable]="true"
  [canClear]="true"
  ctrlStyleClass="w-30rem"
  [pressEnterOnBlur]="true"
  [showLabel]="false"
  formCtrlId="failure-desc"
>
</atlas-dropdown>
