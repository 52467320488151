import { Component, OnDestroy } from '@angular/core';
import { PageComponent } from '@shared/components/page/page.component';
import { AuthService } from '@shared/services/auth.service';
import { ButtonModule } from 'primeng/button';
import { environment } from '../../../environments/environment';
import { CertificatesClient, TokensClient } from '@shared/model/atlas.api';
import { Subscription } from 'rxjs';
import { UserService } from '@shared/services/user.service';
import { ButtonComponent, ButtonSeverity } from '@ui/button/button.component';
import { AlertService } from '@shared/services/alert.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'atlas-admin-sos-settings',
  standalone: true,
  imports: [PageComponent, ButtonModule, ButtonComponent, NgIf],
  templateUrl: './admin-settings.component.html',
  styleUrl: './admin-settings.component.scss',
})
export class AdminSettingsComponent implements OnDestroy {
  private _subs: Subscription = new Subscription();

  constructor(
    private _authService: AuthService,
    private _tokensClient: TokensClient,
    protected userService: UserService,
    private _certificatesClient: CertificatesClient,
    private _alertService: AlertService,
  ) {}

  handleLogOut() {
    this.handleSignOut().then(() => {
      this._authService.logout();
    });
  }

  private handleSignOut(): Promise<void> {
    return new Promise((resolve) => {
      this._subs.add(
        this._tokensClient
          .logoutWeb(this.userService.getUserData.email)
          .subscribe(() => {
            resolve();
          }),
      );
    });
  }

  handleSyncSertificates() {
    this._subs.add(
      this._certificatesClient.syncUidCertificates().subscribe((res) => {
        this._alertService.addSuccessMsg(res.result.value);
      }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  protected readonly environment = environment;
  protected readonly ButtonSeverity = ButtonSeverity;
}
