<atlas-page
    pageTitle="Mesečni izveštaj"
    pageDescription="Ukupni izveštaj svih prodaja paketa po mesecima"
>
  <!--  Overview cards-->
  <div class="flex flex-column md:flex-row gap-3">
    <atlas-page-info-card
      cardTitle="Broj paketa"
      cardIcon="pi-box"
      [cardNumber]="monthlyPackagesStats ? monthlyPackagesStats.totalPackagesSold.toString() : ''"
      class="flex-1"
    ></atlas-page-info-card>
    <atlas-page-info-card
      cardTitle="Ukupan iznos paketa"
      [cardNumber]="monthlyPackagesStats ? (monthlyPackagesStats.totalPackagesSoldAmount.toString() | currencyNumber) : ''"
      cardIcon="pi-money-bill"
      class="flex-1"
    ></atlas-page-info-card>
  </div>
    <!-- Export Btn -->
      <div class="flex mt-4 filters justify-content-between">
        <div class="h-full mt-3 ml-1 flex">
          <atlas-button
            btnIcon="pi pi-download"
            class="filters-button"
            btnLabel="Preuzmi izveštaj"
            [btnStyle]="ButtonStyle.BASIC"
            [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
            (btnClicked)="handleExport()"
          ></atlas-button>
      </div>
      <div class="flex gap-3 h-full mb-3">
        <!-- From -->
        <atlas-calendar
          formCtrlId="from"
          ctrlLabel="Kreirani od"
          ctrlPlaceholder="Kreirani od datuma:"
          [formCtrl]="createdSinceCtrl"
          [hideErrors]="true"
        ></atlas-calendar>
        <!-- To -->
        <atlas-calendar
          formCtrlId="to"
          ctrlLabel="Kreirani do"
          ctrlPlaceholder="Kreirani do datuma:"
          [formCtrl]="createdToCtrl"
          [hideErrors]="true"
        ></atlas-calendar>
        <!-- Source -->
        <atlas-sources-multiselect
          [sourceCtrl]="selectedSorcesCtrl"
        ></atlas-sources-multiselect>
        <!-- Year -->
        <atlas-report-years-dropdown
            [yearCtrl]="yearCtrl"
        ></atlas-report-years-dropdown>
        <!-- Search -->
        <div class="search-btn">
          <atlas-button
            btnIcon="pi pi-search"
            class="filters-button"
            btnLabel="Primeni filtere"
            [btnStyle]="ButtonStyle.BASIC"
            [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
            (btnClicked)="load()"
          ></atlas-button>
        </div>
      </div>
    </div>

    <atlas-table
        [paginatedData]="paginatedData"
        [tableConfig]="tableConfig"
    ></atlas-table>
</atlas-page>



