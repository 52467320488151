import { NgFor, NgForOf, NgIf, NgClass } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabViewModule } from 'primeng/tabview';
import { PackageTabClientComponent } from '../package-tab-client.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ClientClient, GetVehicleQueryResponse } from '@shared/model/atlas.api';
import { SkeletonModule } from 'primeng/skeleton';
import { ProgressBarModule } from 'primeng/progressbar';
import { LocationService } from '@shared/services/location.service';
import {AgentCcRoleStr, B2BAgentRoleStr, LeaderCcRoleStr, UserService} from '@shared/services/user.service';
import { ClientProfileService } from '@app/cc-leader/services/client-profile.service';

@Component({
  selector: 'atlas-vehicle-list-client-info',
  standalone: true,
  imports: [
    OverlayPanelModule,
    TabViewModule,
    ButtonComponent,
    NgFor,
    NgForOf,
    NgIf,
    NgClass,
    SkeletonModule,
    ProgressBarModule],
  templateUrl: './vehicle-list-client-info.component.html',
  styleUrl: './vehicle-list-client-info.component.scss'
})
export class VehicleListClientInfoComponent implements OnInit, OnDestroy {

  private _subs: Subscription = new Subscription();

  @ViewChild('vehicleCarousel', { static: false }) vehicleCarousel!: ElementRef;

  clientName: string;
  clientId: string;
  showSkeleton: boolean = true;
  vehicles: GetVehicleQueryResponse[] = [];

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  constructor(
    private route: ActivatedRoute,
    private client: ClientClient,
    private locationService: LocationService,
    public userService: UserService,
    private clientProfileService: ClientProfileService
  ) {
    this.clientId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getClientInfo();
  }

  scrollLeft(): void {
    if (this.vehicleCarousel) {
      this.vehicleCarousel.nativeElement.scrollBy({ left: -300, behavior: 'smooth' });
    }
  }

  scrollRight(): void {
    if (this.vehicleCarousel) {
      this.vehicleCarousel.nativeElement.scrollBy({ left: 300, behavior: 'smooth' });
    }
  }

  handleMoreDetails(vehicle: GetVehicleQueryResponse) {
    this.locationService.routeToVehicleInfo(this.clientId, vehicle.id, this.userService.getRoleRoutePrefix(
      AgentCcRoleStr,
      B2BAgentRoleStr,
      LeaderCcRoleStr));
    this.clientProfileService.setVehicleQueryResponse(vehicle);
  }

  addVehicle() {
    this.locationService.routeToAddVehicleToClient(
      this.clientId, this.userService.getRoleRoutePrefix(
        AgentCcRoleStr,
        B2BAgentRoleStr,
        LeaderCcRoleStr),
    );
  }

  private getClientInfo() {
    this._subs.add(
      this.client.getProfilePackages(this.clientId).subscribe((res) => {
        this.vehicles = res.result?.vehicles || [];
        this.showSkeleton = false;
      })
    )
  }

  getVehicleIcon(type: string): string {
    return type === 'Putnička vozila'
      ? 'pi pi-car'
      : type === 'Kombi'
      ? 'pi pi-truck'
      : 'pi pi-question';
  }

  hasPackages(): boolean {
    return this.vehicles.some(vehicle => vehicle.packages && vehicle.packages.length > 0);
  }

  getProgressValue(vehicle: any): number {
    if (!vehicle.stats || vehicle.stats.allPackagesCount === 0) return 0;
    return parseFloat(((vehicle.stats.activePackagesCount / vehicle.stats.allPackagesCount) * 100).toFixed(2));
  }

  getProgressColor(vehicle: any): string {
    const percentage = this.getProgressValue(vehicle);
    if (percentage < 30) return '#F04438';
    if (percentage < 50) return '#EB9229';
    if (percentage < 80) return '#1E7EC3';
    return '#1EC360';
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
