<atlas-page
    pageTitle="Vozila"
    pageDescription="Upravljanje vozilima, pregled i izmena postojećih"
>
    <div class="flex flex-column gap-3 table-filter mt-3">
        <div class="flex align-items-center gap-3">
          <p-tabView
            class="tabView flex-1"
            [(activeIndex)]="activeIndex!"
            (activeIndexChange)="handleTabChange($event)"
          >
            <p-tabPanel header="Sva vozila"></p-tabPanel>
            <p-tabPanel header="Zahtevi za izmenu"></p-tabPanel>
          </p-tabView>
        </div>
    </div>

    <div *ngIf="activeIndex === 0">
        <atlas-brand-with-model-table></atlas-brand-with-model-table>
    </div>
    <div *ngIf="activeIndex !== 0">
        <atlas-vehicle-change-request-table></atlas-vehicle-change-request-table>
    </div>

    <!-- <ng-container [ngSwitch]="activeIndex">
        <atlas-brand-with-model-table *ngSwitchCase="0"></atlas-brand-with-model-table>
        <atlas-vehicle-change-request-table *ngSwitchCase="1"></atlas-vehicle-change-request-table>
    </ng-container> -->

</atlas-page>


  


