<div class="menu-hover-area" (mouseenter)="handleMouseEnter()"></div>
<div class="flex flex-row container flex-wrap md:flex-nowrap">
  <i
    class="pi pi-eye menu-icon"
    (click)="handleMenuToggle()"
    [ngStyle]="{
      visibility: !isMenuVisible && !isHovered ? 'visible' : 'hidden'
    }"
    pTooltip="Prikaži meni"
    tooltipPosition="bottom"
  >
  </i>
  <div
    class="menu-size"
    *ngIf="menuDisplayed"
    (mouseenter)="handleMouseEnter()"
    (mouseleave)="handleMouseLeave()"
    [ngClass]="{
      'menu-visible': isMenuVisible || isHovered,
      'menu-hidden': !isMenuVisible && !isHovered
    }"
  >
    <atlas-menu
      [isMenuVisible]="isMenuVisible"
      (menuVisibilityChange)="onMenuVisibilityChange($event)"
    ></atlas-menu>
  </div>
  <div
    class="layout-container w-full"
    [ngClass]="{ 'container-expanded': !isMenuVisible && !isHovered }"
    [ngStyle]="{
      'max-width': isMenuVisible || isHovered ? 'calc(100% - 280px)' : '100%',
      'background-color': isGray ? 'var(--gray-50)' : 'var(--surface-a)'
    }"
  >
    <!--    <div class="">-->
    <!--      <div>-->
    <!--        <p-breadcrumb-->
    <!--          *ngIf="false"-->
    <!--          [home]="home"-->
    <!--          [model]="menuItems!"-->
    <!--        ></p-breadcrumb>-->
    <!--      </div>-->

    <router-outlet></router-outlet>
    <!--    </div>-->
  </div>
</div>
<atlas-assistance-toast
  *ngIf="shouldShowAssistancesToast"
></atlas-assistance-toast>
<atlas-confirm-dialog></atlas-confirm-dialog>
