import { NgTemplateOutlet, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IAssistanceSidebarDetails } from '@shared/model/atlas.api';

@Component({
  selector: 'atlas-details-tab-sidebar',
  standalone: true,
  imports: [NgOptimizedImage],
  templateUrl: './details-tab-sidebar.component.html',
  styleUrl: './details-tab-sidebar.component.scss',
})
export class DetailsTabSidebarComponent implements OnInit {
  @Input() previewData: any;

  ngOnInit(): void {}
}
