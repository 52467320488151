import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { NgClass, NgIf } from '@angular/common';
import { PageComponent } from '@shared/components/page/page.component';
import { TabViewModule } from 'primeng/tabview';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  AgentStatsResponse,
  RecommendationsClient,
} from '@shared/model/atlas.api';
import { RecommendationTableComponent } from '@app/features/recommendations/recommendation-table/recommendation-table.component';
import { RecommendationFormService } from '@app/features/recommendations/add-recommendation-form/recommendation-form.service';

@Component({
  selector: 'atlas-recommendation-page-agent',
  standalone: true,
  imports: [
    NgIf,
    PageComponent,
    TabViewModule,
    NgClass,
    RecommendationTableComponent,
  ],
  templateUrl: './recommendation-page-agent.component.html',
  styleUrl: './recommendation-page-agent.component.scss',
})
export class RecommendationPageAgentComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;
  agentStats: AgentStatsResponse;

  constructor(
    private _client: RecommendationsClient,
    private _formService: RecommendationFormService,
  ) {}

  ngOnInit() {
    this.load();
  }

  handleAction() {
    this._formService.setShowAddDialog = true;
  }

  load() {
    this._getStats().then((res) => {
      this.agentStats = res.result;
    });
  }

  private async _getStats() {
    return await firstValueFrom(this._client.getAgentStats());
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
