import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { CampaignPriority } from '@shared/model/atlas.api';
import { DropdownModule } from 'primeng/dropdown';
import { JsonPipe, NgIf } from '@angular/common';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'atlas-campaign-priority-dropdown',
  standalone: true,
  imports: [
    DropdownModule,
    NgIf,
    DropdownComponent,
    JsonPipe],
  templateUrl: './campaign-priority-dropdown.component.html',
  styleUrl: './campaign-priority-dropdown.component.scss',
})
export class CampaignPriorityDropdownComponent implements OnInit, OnDestroy {
  private _subs: Subscription;
  @Input() ctrlFormGroup: any;
  formCtrl: FormControl<CampaignPriority>;
  flags: SelectItem<CampaignPriority>[];

  constructor(_fb: FormBuilder) {
    this.formCtrl = _fb.control<CampaignPriority>(CampaignPriority.NONE);
    this._subs = new Subscription();
  }

  ngOnInit() {
    this._initFlags();

    this._subs = this.formCtrl.valueChanges.subscribe((priority) => {
      this.ctrlFormGroup.patchValue(priority);
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  private _initFlags() {
    this.flags = [
      {
        label: 'Bez prioriteta',
        value: CampaignPriority.NONE,
        icon: '#999999',
      },
      {
        label: 'Nizak prioritet',
        value: CampaignPriority.LOW,
        icon: '#80e0a0',
      },
      {
        label: 'Srednji prioritet',
        value: CampaignPriority.MEDIUM,
        icon: '#1E7EC3',
      },
      {
        label: 'Visok prioritet',
        value: CampaignPriority.HIGH,
        icon: '#EB9229',
      },
      { label: 'Hitno', value: CampaignPriority.URGENT, icon: '#F04438' },
    ];
  }
}
