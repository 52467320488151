import { Component, Input } from '@angular/core';
import {
  InitClient,
  IPaymentStatusDropdownItem,
  PaymentStatus,
} from '@shared/model/atlas.api';
import { FormControl } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { UserService } from '@shared/services/user.service';
import { JsonPipe, NgClass } from '@angular/common';

@Component({
  selector: 'atlas-payment-status-dropdown',
  standalone: true,
  imports: [DropdownComponent, JsonPipe, NgClass],
  templateUrl: './payment-status-dropdown.component.html',
  styleUrl: './payment-status-dropdown.component.scss',
})
export class PaymentStatusDropdownComponent {
  statusOptions: IPaymentStatusDropdownItem[] = [];

  @Input() statusCtrl: FormControl<string>;

  constructor(
    private _client: InitClient,
    private _userService: UserService,
  ) {}

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._getStatuses().then((res) => {
      if (this._userService.isFinanceAdmin()) {
        this.statusOptions = res.result.items.filter((item) => {
          return (
            item.status === PaymentStatus.WC_PROCESSING ||
            item.status === PaymentStatus.WC_ON_HOLD ||
            item.status === PaymentStatus.WC_COMPLETED ||
            item.status === PaymentStatus.WC_REFUNDED ||
            item.status === PaymentStatus.WC_CANCELLED
          );
        });
      } else {
        this.statusOptions = res.result.items;
      }
    });
  }

  private async _getStatuses() {
    return await firstValueFrom(this._client.getPaymentStatusDropdown());
  }
}
