import { Component, OnDestroy, OnInit } from '@angular/core';
import {FormControl, FormGroup, FormsModule} from '@angular/forms';
import { PageComponent } from '@shared/components/page/page.component';
import {
  CreateRecomendationsCommandBody,
  GetRecommendationsQuery,
  IPaginationResponseOfRecommendationResponse,
  RecommendationsClient,
} from '@shared/model/atlas.api';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { ActionMode, TableConfig } from '@ui/table/table-common';
import { TableComponent } from '@ui/table/table.component';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import {firstValueFrom, Subscription} from 'rxjs';
import {DialogConfig, DialogSize} from "@ui/dialog/dialog-config";
import {ReccomendationFormService} from "@app/cc-leader/services/reccomendation-form.service";
import {AlertService} from "@shared/services/alert.service";
import {
  AddRecommendationFormComponent
} from "@app/features/call-center/add-recommendation-form/add-recommendation-form.component";
import {DialogService} from "@ui/dialog/dialog.service";
import {markFormGroupTouched} from "@shared/forms/forms-common";
import {DialogRef} from "@ui/dialog/dialog-ref";

@Component({
  selector: 'atlas-recommendation-table',
  standalone: true,
  imports: [
    PageComponent,
    InlineWrapperComponent,
    DropdownComponent,
    InputTextComponent,
    ButtonModule,
    ButtonComponent,
    DropdownModule,
    FormsModule,
    TableComponent,
  ],
  templateUrl: './recommendation-table.component.html',
  styleUrl: './recommendation-table.component.scss',
})
export class RecommendationTableComponent implements OnInit {
  private _subs: Subscription = new Subscription();

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  searchOptions: SelectItem[];
  searchOptionCtrl: FormControl;
  searchCtrl = new FormControl('');

  public paginatedData: IPaginationResponseOfRecommendationResponse;
  protected tableConfig: TableConfig;

  rowsOptions = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '15', value: 15 },
    { label: '20', value: 20 }
  ];
  selectedRows: number = 5;
  suggestCount: number = 0;

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.MEDIUM_SMALL);

  constructor(
      private _recomendClinent: RecommendationsClient,
      private _dialogService: DialogService,
      private _recommendService: ReccomendationFormService,
      private _alertService: AlertService
  ) {
    this.tableConfig = new TableConfig({
      columns: [],
    })

    this.paginatedData = {
      data: [],
      currentPage: 0
    }
  }

  ngOnInit(): void {
    this.setTableConfig().then()
    this.load();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event: LazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 0;
        } else {
          this.paginatedData.currentPage = event.first / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.load();
      },
      columns: [
        {
          field: 'fullName',
          header: 'Preporuka',
          type: 'text'
        },
        {
          field: 'contact',
          header: 'Kontakt',
          type: 'text'
        },
        {
          field: 'source',
          header: 'Izvor preporuke',
          type: 'text'
        },
        {
          field: 'city',
          header: 'Grad',
          type: 'text'
        },
        {
          field: 'createdOn',
          header: 'Dodata',
          type: 'text'
        }
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Kreiraj ugovor',
          label: 'Napravi ugovor',
          svgName: 'recomend-btn',
          actionClass: 'p-2 ml-5 gap-2',
          actionSeverity: ButtonSeverity.SECONDARY_GREY,
          actionStyle: ButtonStyle.OUTLINED,
          shouldDisplayByCondition: () => true,
          callback: () => {
            alert("Napravi ugovor")
          }
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Komentari',
          svgName: 'comment-Icon',
          actionSeverity: ButtonSeverity.SECONDARY,
          shouldDisplayByCondition: () => true,
          callback: () => {
            alert('Komentari')
          }
        },
        {
          mode: ActionMode.MULTI,
          icon: 'pi pi-pencil',
          label: 'Detaljnije',
          actionSeverity: ButtonSeverity.SECONDARY_GREY,
          callback: (rowIdx: number) => {
            alert('ToDo')
          },
          shouldDisplayByCondition: () => true
        }
      ]
    })
  }

  handleAddNewRecommendation() {
    this.dialogConfig.header = 'Dodaj preporuku';
    this.dialogConfig.customSubmitButton = {
      label: 'Dodaj',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;

    const dialogRef = this._dialogService.open(
        AddRecommendationFormComponent,
        this.dialogConfig
    )

    dialogRef.onClose.subscribe(() => {
      this._recommendService.clearReccomendations()
      this.load();
    })

    dialogRef.onSubmit.subscribe(() => {
      this._handleSubmitReccomendations(dialogRef)
    })
  }

  private _handleSubmitReccomendations(dialogRef: DialogRef) {
    const reccomendationsForm = this._recommendService.getReccomendations();

    if (!reccomendationsForm.valid) {
      reccomendationsForm.controls.forEach((formGroup: FormGroup) => {
        markFormGroupTouched(formGroup);
      });
      return this._alertService.addWarnMsg("Morate uneti sva polja!")
    }

    this._subs.add(
        this._recomendClinent.createReccomendations(reccomendationsForm.value as CreateRecomendationsCommandBody[])
            .subscribe((x) => {
              this._alertService.addSuccessMsg(x.result);
              dialogRef.close()
            })
    )
  }

  load() {
    this._getList().then((res) => {
      this.paginatedData = res.result;
      this.suggestCount = res.result.totalCount
    })
  }

  private async _getList() {
    return await firstValueFrom(
        this._recomendClinent.getReccomendations(
            new GetRecommendationsQuery({
              pageNumber: this.paginatedData.currentPage,
              pageSize: this.paginatedData.pageSize
            })
        )
    )
  }
}
