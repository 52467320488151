import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { Steps, StepsModule } from 'primeng/steps';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { StepperModule } from 'primeng/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { firstValueFrom } from 'rxjs';
import {
  AssistancesClient,
  CreateAssistanceCommand,
  PhoneItem,
  UpdateAssistanceCommand,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { markFormGroupTouched } from '@shared/forms/forms-common';
import {
  AssistanceForm,
  AssistanceFormService,
} from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import { AssistanceClientForm } from '@app/sos-agent/assistances/assistance-form/services/assistance-client-form.service';
import { AssistanceService } from '@app/sos-agent/assistances/assistance-form/services/assistance.service';
import { SkeletonModule } from 'primeng/skeleton';
import { NewVehicleService } from '@app/features/services/new-vehicle-service';
import { LoaderService } from '@shared/components/loader/loader.service';
import { ScrollablePageWithStepsComponent } from '@shared/components/scrollable-page-with-steps/scrollable-page-with-steps.component';
import { ContactFormService } from '@app/features/partners/services/contact-form.service';
import { LocationSosService } from '@shared/services/location-sos.service';

@Component({
  selector: 'atlas-assistance-form',
  standalone: true,
  imports: [
    StepsModule,
    StepperModule,
    ScrollPanelModule,
    ConfirmDialogModule,
    NgIf,
    SkeletonModule,
    NgTemplateOutlet,
    ScrollablePageWithStepsComponent,
  ],
  templateUrl: './assistance-form.component.html',
  styleUrl: './assistance-form.component.scss',
})
export class AssistanceFormComponent implements OnInit, OnDestroy {
  menuSteps: MenuItem[] | undefined;
  assistanceForm: AssistanceForm;
  @ViewChild('steps') steps!: Steps;
  showLoader: boolean = false;

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;
  shouldDisplayBack: boolean = false;
  shouldDisplayFinish: boolean = false;

  constructor(
    private router: Router,
    private formService: AssistanceFormService,
    private clientFS: AssistanceClientForm,
    public assistanceService: AssistanceService,
    private confirmationService: ConfirmationService,
    private client: AssistancesClient,
    private alert: AlertService,
    private _locationSosService: LocationSosService,
    private activatedRoute: ActivatedRoute,
    public newVehicleService: NewVehicleService,
    private _loaderService: LoaderService,
    private _phonesService: ContactFormService,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      const assistanceId = params['id'];

      if (assistanceId) {
        this._initForEdit(assistanceId);
      }
    });

    if (router.getCurrentNavigation()?.extras.state?.['clientPackageId']) {
      this._initForClientPackage(
        router.getCurrentNavigation()?.extras.state?.['clientPackageId'],
      );
    }

    this.assistanceForm = this.formService.getForm;
  }

  ngOnInit() {
    this.menuSteps = [
      {
        label: 'Detalji',
        routerLink: 'step-1',
        icon: 'pi pi-check',
      },
      {
        label: 'Saradnik',
        routerLink: 'step-2',
      },
    ];
  }

  private addPhones() {
    this.assistanceForm.controls.client.controls.phones.clear();
    const phoneValues = this._phonesService.getPhones().value as PhoneItem[];
    phoneValues.forEach((x) => {
      this.assistanceForm.controls.client.controls.phones.push(
        this._phonesService.getPhoneForm(x),
      );
    });
  }

  handleNext() {
    console.log(this.assistanceForm);
    const vehicle = this.formService.getVehicle()
    if (this.assistanceService.isFirstStep) {
      this.assistanceForm.controls.vehicle.patchValue(vehicle);
      this.addPhones();
      this._phonesService.setSubmitted(true);

      if (!this.formService.isStep1CreateValid) {
        markFormGroupTouched(this.assistanceForm);
        this.assistanceForm.controls.client.controls.phones.clear();
        this.newVehicleService.markAsTouched();
        this.clientFS.setValidatePhones = true;
        this.alert.addWarnMsg('Morate uneti sva obavezna polja');
        return;
      }

      this.router
        .navigate(['step-2'], {
          relativeTo: this.activatedRoute,
        })
        .then();
      this.shouldDisplayBack = true;
      this.shouldDisplayFinish = true;
      this.menuSteps[0].styleClass = 'completed';
    } else {
      if (
        !this.formService.isStep1CreateValid ||
        !this.formService.isStep2Valid
      ) {
        markFormGroupTouched(this.assistanceForm);
        this.alert.addWarnMsg('Morate uneti sva obavezna polja');
        return;
      }
      this.confirmationService.confirm({
        header: 'Potvrdi i kreiraj asistenciju?',
        message:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
        icon: 'confirm-assistance.svg',
        accept: () => {
          if (this.assistanceForm.invalid) {
            this.assistanceForm.markAsTouched();
            this.alert.addWarnMsg('Morate uneti sva obavezna polja');
            return;
          }

          this._loaderService.setShowLoader = true;

          if (this.assistanceService.isUpdate) {
            this._updateAsync().then((msg) => {
              this.alert.addSuccessMsg(msg.result);
              this._locationSosService.routeToAssitancesTable();
              this._phonesService.resetPhones();
              this._loaderService.reset();
            });
          } else {
            this._createAsync().then((msg) => {
              this.alert.addSuccessMsg(msg.result);
              this._locationSosService.routeToAssitancesTable();
              this._phonesService.resetPhones();
              this._loaderService.reset();
            });
          }
        },
        reject: () => {},
      });
    }
  }

  handleBack() {
    this.router
      .navigate(['step-1'], {
        relativeTo: this.activatedRoute,
      })
      .then();
    this.shouldDisplayBack = false;
    this.shouldDisplayFinish = false;
    this.menuSteps[0].styleClass = '';
  }

  handleCancel() {
    this.confirmationService.confirm({
      header: 'Odustani od kreiranja asistencije?',
      message: 'Da li želiš da odustaneš od kreiranja asistencije?',
      acceptButtonStyleClass: ButtonSeverity.DANGER,
      acceptLabel: 'Odustani',
      rejectLabel: 'Ostani',
      icon: 'confirm-cancel.svg',
      accept: () => {
        this._locationSosService.routeToAssitancesTable();
      },
      reject: () => {},
    });
  }

  private async _createAsync() {
    return await firstValueFrom(
      this.client.createAssistance(
        this.assistanceForm.value as CreateAssistanceCommand,
      ),
    );
  }

  private async _updateAsync() {
    return await firstValueFrom(
      this.client.updateAssistance(
        this.assistanceForm.value as UpdateAssistanceCommand,
      ),
    );
  }

  // private async _saveDraftAsync() {
  //   return await firstValueFrom(
  //     this.client.saveAssistanceDraft(
  //       this.assistanceForm.value as SaveAssistanceDraftCommand,
  //     ),
  //   );
  // }

  // saveDraft() {
  //   if (!this.formService.isStep1DraftValid) {
  //     this.markFormGroupTouched(this.assistanceForm);
  //     this.clientFS.setValidatePhones = true;
  //     this.alert.addWarnMsg('Morate uneti sva obavezna polja');
  //     return;
  //   }
  //   this._saveDraftAsync().then((msg) => {
  //     this.alert.addSuccessMsg(msg.result);
  //     this.location.routeToAssitancesTable();
  //   });
  // }

  private _initForClientPackage(id: string) {
    this.showLoader = true;

    this._getForClientPackage(id).then((res) => {
      res.result.value.client.phones.forEach((x) => {
        this._phonesService.pushPhone(x);
        this.assistanceForm.controls.client.controls.phones.push(
          this._phonesService.getPhoneForm(x),
        );
      });
      this.assistanceForm.controls.client.controls.phones.removeAt(0);
      this.formService.setForm(res.result.value);
      this.assistanceForm = this.formService.getForm;
      this.showLoader = false;
    });
  }

  private _initForEdit(id: string) {
    this.showLoader = true;
    this.assistanceService.setIsUpdate();
    this._getForEdit(id).then((res) => {
      res.result.value.client.phones.forEach((x) => {
        this._phonesService.pushPhone(x);
        this.assistanceForm.controls.client.controls.phones.push(
          this._phonesService.getPhoneForm(x),
        );
      });
      this.assistanceForm.controls.client.controls.phones.removeAt(0);
      this.formService.setForm(res.result.value);
      this.assistanceForm = this.formService.getForm;
      this.showLoader = false;
      this.formService.setSelecedServiceId(res.result.value.serviceId);
    });
  }

  private async _getForClientPackage(id: string) {
    return await firstValueFrom(this.client.getAssistanceForClientPackage(id));
  }

  private async _getForEdit(id: string) {
    return await firstValueFrom(this.client.getAssistanceForEdit(id));
  }

  ngOnDestroy() {
    this.assistanceService.directionsResults$ = null;
    this._phonesService.setSubmitted(false);
    this.newVehicleService.resetCtrl();
    this.assistanceService.reset();
  }
}
