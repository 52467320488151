import {
  CurrencyPipe,
  DecimalPipe,
  JsonPipe,
  NgClass,
  NgFor,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet,
} from '@angular/common';
import {
  Component,
  DebugElement,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { BrandAutocompleteComponent } from '@app/features/autocompletes/brand-autocomplete/brand-autocomplete.component';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { CheckboxModule } from 'primeng/checkbox';
import { Subscription } from 'rxjs';
import { PackageCardListService } from '../services/package-card-list.service';
import {
  IPackageCard,
  PackageCardItem,
  PackageItem,
  PackagePriceDropdownItem,
  PackagesClient,
} from '@shared/model/atlas.api';
import { DropdownModule } from 'primeng/dropdown';
import { CurrencyNumberPipe } from '@shared/pipes/currency-number.pipe';
import { ControlsOf } from '@shared/forms/forms-common';
import { ButtonComponent } from '@ui/button/button.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { VehicleTypeDropdownComponent } from '@app/features/dropdowns/vehicle-type-dropdown/vehicle-type-dropdown.component';
import { OrderFormService } from '../services/order-form.service';

@Component({
  selector: 'atlas-package-card-list',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgClass,
    NgForOf,
    CheckboxModule,
    InputTextComponent,
    InputPhoneComponent,
    InputMailComponent,
    InputAddonComponent,
    DropdownComponent,
    DropdownModule,
    IconCheckboxComponent,
    BrandAutocompleteComponent,
    FormsModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
    NgOptimizedImage,
    PackageCardListComponent,
    CurrencyPipe,
    CurrencyNumberPipe,
    JsonPipe,
    ButtonComponent,
    FormControlPipe,
    VehicleTypeDropdownComponent,
  ],
  templateUrl: './package-card-list.component.html',
  styleUrl: './package-card-list.component.scss',
})
export class PackageCardListComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  @Input() isRenew: boolean;

  selectedIdx: number;
  allPackages: PackageCardItem[] = [];
  licniPaketItem: PackageCardItem;
  showAddNewPackageBtn: boolean = true;
  actionPricesDropdown: PackagePriceDropdownItem[] = [];
  selectedPackageId: string = null;
  showPriceInput: boolean = false;
  vehicleType: string;
  dropdownDisabled: boolean = true;
  packages: FormArray<FormGroup<ControlsOf<IPackageCard>>>;
  isForVehicle: boolean;

  constructor(
    private _packagesService: PackageCardListService,
    private _packagesClent: PackagesClient,
    private decimalPipe: DecimalPipe,
    private _orderForm: OrderFormService,
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._packagesService.getVehicleTypeId().subscribe((x) => {
        if (x) {
          this.vehicleType = x;
          this.dropdownDisabled = false;
          this._loadPackages(x);
        }
      }),
    );

    this._subs.add(
      this._packagesService.getSelectedPackageId().subscribe(async (x) => {
        if (x) {
          this.showAddNewPackageBtn = false;
          await this._loadPackages()
          this.selectedPackageId = x;
          this._setPackageActionPrices(this.selectedPackageId)
          // this._loadPackage(x);
        }
      }),
    );

    this._subs.add(
      this._packagesService.packages$.subscribe((packages) => {
        this.packages = packages;

        if ((!this.packages || this.packages.length === 0) && !this.isRenew) {
          this.addEmptyPackageCard();
        }
      }),
    );

    this._subs.add(
      this._packagesService.licniPaketSelected.subscribe((x) => {
        if (x) {
          this.addLicniPaket().then();
        } else {
          if (this.vehicleType) {
            this.dropdownDisabled = false;
          }
        }
      }),
    );
  }

  private async _loadPackage(packageId: string) {
    await this._loadPackages(this.vehicleType);
    const selectedPackage: PackageCardItem = this.allPackages.find(
      (x) => x.id === packageId,
    );
    this._setPackageActionPrices(selectedPackage.id);

    if (this.isRenew) {
      this._packagesService.clearAllPackages();
      this._packagesService.setSelectedPackageId(
        selectedPackage.id,
        0,
        this.isRenew,
      );
      this._packagesService.setSelectedPackageName(selectedPackage.name, 0);
      this._packagesService.setSelectedPackagePrice(selectedPackage.price, 0);
      this._packagesService.setSelectedPacakgePriceId(
        selectedPackage.priceId,
        0,
      );
      this.showAddNewPackageBtn = false;
      this.dropdownDisabled = true;
    }
  }

  addEmptyPackageCard() {
    const newEmptyPackage = this._packagesService.initPackageCardForm();
    this._packagesService.addPackage(newEmptyPackage.value as IPackageCard);
    this.packages = this._packagesService.getPackages();
  }

  async addLicniPaket() {
    this._packagesService.clearAllPackages();
    await this._loadPackages();
    this.licniPaketItem = this.allPackages.find((x) => x.name === 'Moj Paket');
    this._setPackageActionPrices(this.licniPaketItem.id);
    this._packagesService.setSelectedPackageId(this.licniPaketItem.id, 0);
    this._packagesService.setSelectedPackageName(this.licniPaketItem.name, 0);
    this._packagesService.setSelectedPackagePrice(this.licniPaketItem.price, 0);
    this._packagesService.setSelectedPacakgePriceId(
      this.licniPaketItem.priceId,
      0,
    );
    this.showAddNewPackageBtn = false;
    this.dropdownDisabled = true;
    this._packagesService.setLicniPaketSelected();
  }

  togglePackageDropdown(idx: number) {
    this.selectedIdx = idx;
  }

  clearSelectedPackage(event: MouseEvent, idx: number) {
    event.stopPropagation();
    this._packagesService.clearPackageById(idx);
  }

  private _loadPackages(vehicleTypeId?: string): Promise<void> {
    return new Promise((resolve) => {
      this._subs.add(
        this._packagesClent
          .getAllForPackageCard(vehicleTypeId)
          .subscribe((x) => {
            this.allPackages = x.result.cardPackages || [];
            resolve();
          }),
      );
    });
  }

  private _setPackageActionPrices(packageId: string) {
    this.actionPricesDropdown = [];
    this.allPackages
      .find((x) => x.id === packageId)
      .actionPrices.forEach((x) => {
        const action = new PackagePriceDropdownItem({
          id: x.id,
          label: x.label,
          price: x.price,
        });
        this.actionPricesDropdown.push(action);
      });

    const firstAction = new PackagePriceDropdownItem({
      id: '0',
      label: 'Upisi cenu',
    });

    this.actionPricesDropdown.unshift(firstAction);
  }

  selectPackage(pkg: PackageItem, idx: number) {
    this.selectedIdx = null;
    this._setPackageActionPrices(pkg.id);

    this._packagesService.setSelectedPackageName(pkg.name, idx);
    this._packagesService.setSelectedPackagePrice(pkg.price, idx);
    this._packagesService.setSelectedPackageId(pkg.id, idx);
    this._packagesService.setSelectedPacakgePriceId(pkg.priceId, idx);
  }

  handleActionPriceChange(event: any, idx: number, packageItem: any) {
    if (event.value.id === '0') {
      this.showPriceInput = true;
    }

    this._packagesService.addPackagePriceId(
      event.value.id,
      event.value.price,
      idx,
    );
  }

  handleChassisInputChange(event: any, idx: number) {
    this._packagesService.setSelectedChassisNum(event, idx);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
