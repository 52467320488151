<atlas-page
  pageTitle="Asistencije"
  pageDescription="Prikaz liste i detalja svih asistencija"
>
  <div class="flex flex-column gap-3 table-filter flex-wrap">
    <div class="flex align-items-center gap-3 flex-wrap">
      <p-tabView
        class="tabView flex-1"
        [(activeIndex)]="activeIndex!"
        (activeIndexChange)="handleTabChange($event)"
      >
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0">
                Sve asistencije
              </span>
            </div>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="U toku">
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0"> U toku </span>
            </div>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Završene">
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0"> Završene </span>
            </div>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Otkazane">
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0"> Otkazane </span>
            </div>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Zatražene">
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0"> Zatražene </span>
              <p-badge [value]="totalRequested" />
            </div>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Nove">
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0"> Nove </span>
            </div>
          </ng-template>
        </p-tabPanel>
      </p-tabView>
      <div class="search-bar">
        <atlas-dropdown-input-button
          [searchOptionCtrl]="searchOptionCtrl"
          [searchCtrl]="searchCtrl"
          [searchOptions]="searchOptions"
          (onAction)="load()"
        >
        </atlas-dropdown-input-button>
      </div>
    </div>
  </div>
  <div class="mt-1 mb-2 w-full">
    <atlas-active-filters></atlas-active-filters>
  </div>
  <atlas-table
    [paginatedData]="paginatedData"
    [tableConfig]="tableConfig"
    [resetPagination]="resetPagination"
  ></atlas-table>
</atlas-page>
<atlas-assistance-sidebar></atlas-assistance-sidebar>
