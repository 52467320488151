<div class="flex flex-column gap-3">
  <!-- <atlas-table-header
      *ngIf="tableConfig.showHeader"
      [tableConfig]="tableConfig"></atlas-table-header> -->

  <p-table
    #dt
    [(selection)]="selectedItem"
    [autoLayout]="false"
    [columns]="tableConfig.columns"
    [paginator]="true"
    [reorderableColumns]="true"
    [resizableColumns]="false"
    [rowsPerPageOptions]="[5, 10, 25, 50]"
    [rows]="tableConfig.rowsPerPage"
    [showCurrentPageReport]="true"
    [value]="items"
    [first]="first"
    currentPageReportTemplate="Prikazano {first} - {last} od {totalRecords} entiteta"
    dataKey="id"
    [lazy]="tableConfig.isLazy"
    (onLazyLoad)="handleLazy($event)"
    [totalRecords]="totalCount"
    [loading]="loading"
    (onPage)="pageChange($event)"
    [scrollable]="true"
  >
    <!-- Columns -->
    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let col of tableConfig.columns">
          @if (col.isFrozen) {
            <th
              [ngClass]="{
                'text-right': col.type === 'currency' || col.type === 'number'
              }"
              [ngStyle]="{
                width: col.type === 'initials' ? '1rem' : 'initial'
              }"
              pFrozenColumn
              [frozen]="true"
            >
              {{ col.header }}
            </th>
          } @else {
            <th
              [ngClass]="{
                'text-right': col.type === 'currency' || col.type === 'number'
              }"
              [ngStyle]="{
                width: col.type === 'initials' ? '1rem' : 'initial'
              }"
            >
              {{ col.header }}
            </th>
          }
        </ng-container>
        <th
          *ngIf="rowActions && rowActions.length > 0"
          pFrozenColumn
          alignFrozen="right"
        ></th>
      </tr>
    </ng-template>

    <!-- Rows -->
    <ng-template let-item let-rowIndex="rowIndex" pTemplate="body">
      <tr
        [pSelectableRowDisabled]="
          !tableConfig.selectMode ||
          (tableConfig.isSelectableRowDisabled &&
            tableConfig.isSelectableRowDisabled(item))
        "
        [pSelectableRowIndex]="rowIndex"
        [pSelectableRow]="item"
      >
        <ng-container *ngFor="let col of tableConfig.columns">
          @if (col.isFrozen) {
            <td style="overflow: hidden; text-overflow: ellipsis" pFrozenColumn>
              <atlas-table-row-data
                [column]="col"
                [item]="item"
                [parent]="dt"
                [rowIndex]="rowIndex"
              >
              </atlas-table-row-data>
            </td>
          } @else {
            <td style="overflow: hidden; text-overflow: ellipsis">
              <atlas-table-row-data
                [column]="col"
                [item]="item"
                [parent]="dt"
                [rowIndex]="rowIndex"
              >
              </atlas-table-row-data>
            </td>
          }
        </ng-container>

        <td
          *ngIf="rowActions && rowActions.length > 0"
          pFrozenColumn
          alignFrozen="right"
        >
          <div class="flex justify-content-end gap-2 row-actions">
            <ng-container
              *ngIf="rowContainsActions(rowIndex); else rowWithoutActions"
            >
              <atlas-button
                *ngFor="let action of singleActions"
                [btnIcon]="action.icon"
                [btnTooltip]="action.title"
                btnTooltipPosition="left"
                [btnSeverity]="
                  action.actionSeverity ?? ButtonSeverity.SECONDARY
                "
                [btnStyle]="action.actionStyle ?? ButtonStyle.TEXT"
                [btnLabel]="action.label"
                (btnClicked)="action.callback(rowIndex)"
                [btnClass]="'text-base ' + action.actionClass"
                [ngClass]="
                  action.shouldDisplayByCondition(rowIndex) ? 'block' : 'hidden'
                "
              >
                <img
                  *ngIf="action.svgName"
                  [ngSrc]="'assets/images/buttons/' + action.svgName + '.svg'"
                  width="20"
                  height="20"
                  alt="action-image"
                />
              </atlas-button>
              <div>
                <i
                  [ngClass]="
                    rowContainsMultiActions(rowIndex)
                      ? 'pi pi-ellipsis-v cursor-pointer block'
                      : 'hidden'
                  "
                  (click)="rowPopup($event, rowIndex)"
                ></i>
              </div>
            </ng-container>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-overlayPanel #rowActionPanel>
  <ng-template pTemplate>
    <div class="flex flex-column gap-2 action-wrapper">
      <ng-container *ngFor="let action of multiActions">
        <atlas-button
          [btnLabel]="action.label!"
          [btnTooltip]="action.label"
          (btnClicked)="handleRowAction(action)"
          [btnClass]="
            action.shouldDisplayByCondition(actionRowIndex)
              ? 'w-full h-full align-items-baseline ' + action.actionClass
              : 'hidden'
          "
          [btnIcon]="action.icon"
        ></atlas-button>
      </ng-container>
    </div>
  </ng-template>
</p-overlayPanel>

<ng-template #rowWithoutActions>
  <div
    class="w-2rem h-2rem"
    showDelay="500"
    hideDelay="300"
    pTooltip="Za ovaj red ne postoje akcije"
  ></div>
</ng-template>

<!--ToDo: ako nam se ucini da presporo otvara popup za akcije, mozemo dodati ove props u overlayPanel-->
<!--[showTransitionOptions]="'0ms'"-->
<!--[hideTransitionOptions]="'0ms'"-->
