<!-- <atlas-page pageTitle="Vozila" btnIcon="pi pi-user"> -->
  <div class="search-bar">
    <atlas-dropdown-input-button
      [searchOptionCtrl]="searchOptionCtrl"
      [searchCtrl]="searchCtrl"
      [searchOptions]="searchOptions"
      (onAction)="load()"
    >
    </atlas-dropdown-input-button>
  </div>
  <div class="mt-1 mb-2 w-full">
    <atlas-active-filters></atlas-active-filters>
  </div>


  <p-table
    dataKey="id"
    [value]="paginatedData.data"
    [tableStyle]="{ 'min-width': '60rem' }"
    [expandedRowKeys]="expandedRows"
    (onRowExpand)="onRowExpand($event)"
    [reorderableColumns]="true"
    [resizableColumns]="false"
    [rowsPerPageOptions]="[5, 10, 25, 50]"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Prikazano {first} - {last} od {totalRecords} entiteta"
    (onRowCollapse)="onRowCollapse($event)"
    [paginator]="true"
    [rows]="paginatedData.pageSize"
    [totalRecords]="paginatedData.totalCount"
    [lazy]="true"
    (onLazyLoad)="loadLazy($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem; padding: 16px">
          <!--          <i class="pi pi-chevron-right icon-color"></i>-->
        </th>
        <th style="width: 12rem">MARKA</th>
        <th style="width: 12rem">TIP</th>
        <th style="width: 5rem; text-align: center"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-brand let-expanded="expanded">
      <tr>
        <td>
          <i
            [ngClass]="
              expanded
                ? 'pi pi-chevron-down icon-color'
                : 'pi pi-chevron-right icon-color'
            "
            class="expand-icon"
            (click)="toggleRow(brand)"
          >
          </i>
        </td>
        <td>
          {{ brand.name }}
        </td>
        <td>
          {{ brand.typeName }}
        </td>
        <td class="action-cell">
          <!--          <atlas-button-->
          <!--            (click)="deleteBrand(brand.id)"-->
          <!--            btnTooltip="Ukloni marku"-->
          <!--            btnLabel="Obriši marku"-->
          <!--            btnIcon="pi pi-trash"-->
          <!--          >-->
          <!--          </atlas-button>-->
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-brand>
      <tr>
        <td colspan="4">
          <div class="expanded-content p-3">
            <div
              *ngFor="let model of brand.models"
              class="flex justify-content-between align-items-center"
            >
              <div>
                <span class="client-name">{{ model.name }}</span>
              </div>

              <div class="action-column">
                <atlas-button
                  (click)="deleteModel(model.id)"
                  btnTooltip="Ukloni model"
                  btnIcon="pi pi-trash"
                >
                </atlas-button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
<!-- </atlas-page> -->
