import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ControlsOf} from '@shared/forms/forms-common';
import {typedKeys} from '@shared/functions/functions';
import {
  ClientWithPackagesFilter,
  GetMediumsQuery,
  IClientWithPackagesFilter,
  IMediumItem,
  ISourceItem,
  MediumsClient,
  SourcesClient
} from '@shared/model/atlas.api';
import {SelectItem} from 'primeng/api';
import {BehaviorSubject, firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientWithPackageTableFilterService {

  private form: ClientFilterForm;
  private propNames: (keyof IClientWithPackagesFilter)[];
  mediums: IMediumItem[] = [];
  sources: ISourceItem[] = [];
  mainDropdownOptions: SelectItem[];
  mainDropdownCtrl: FormControl;
  campaignDropdownCtrl: FormControl;
  mainSearchCtrl: FormControl;
  private isActiveSubject = new BehaviorSubject<boolean | null>(null);
  isActive$ = this.isActiveSubject.asObservable();

  constructor(private _fb: FormBuilder,
              private _mediumsClient: MediumsClient,
              private _sourcesClient: SourcesClient,) {
    this._getMediums().then((x) => {
      this.mediums = x.result.data;
    });
    this._getSources().then((x) => {
      this.sources = x.result.responseList;
    });
    this.mainDropdownOptions = [
      {
        label: SearchOptions.CLIENT_NAME,
        value: SearchOptions.CLIENT_NAME,
      },
      {
        label: SearchOptions.CLIENT_PHONE,
        value: SearchOptions.CLIENT_PHONE,
      },
      {
        label: SearchOptions.LICENCE_PLATE,
        value: SearchOptions.LICENCE_PLATE,
      },
    ];
    this.mainDropdownCtrl = new FormControl<SearchOptions>(
      SearchOptions.CLIENT_NAME,
    );
    this.mainSearchCtrl = new FormControl<string>('');

    this.campaignDropdownCtrl = new FormControl<string | undefined>(undefined);
  }


  private _apply$ = new BehaviorSubject<null>(null);

  get getApply() {
    return this._apply$.asObservable();
  }


  apply() {
    this._apply$.next(null);
  }

  updateIsActive(isActive: boolean | null): void {
    this.isActiveSubject.next(isActive);
  }


  // ---
  private _reset$ = new BehaviorSubject<null>(null);

  get getReset() {
    return this._reset$.asObservable();
  }

  reset() {
    this._reset$.next(null);
    this._reloadTable.next(false);
    this.clearFilters();
  }

  // ---
  private _reloadTable = new BehaviorSubject<boolean>(false);

  get getReloadTable() {
    return this._reloadTable.asObservable();
  }

  reloadTable() {
    this._reloadTable.next(true);
  }

  getForm(model?: IClientWithPackagesFilter): ClientFilterForm {
    if (!this.form) {
      this.form = this._fb.group<ControlsOf<IClientWithPackagesFilter>>({
        isActive: this._fb.control<boolean | undefined>(model?.isActive ?? null),
        endFrom: this._fb.control<Date>(model?.endFrom ?? null),
        endTo: this._fb.control<Date>(model?.endTo ?? null),
        start: this._fb.control<Date>(model?.start ?? null),
        startFrom: this._fb.control<Date>(model?.startFrom ?? null),
        createdOn: this._fb.control<Date>(model?.createdOn ?? null),
        createdOnFrom: this._fb.control<Date>(model?.createdOnFrom ?? null),
        sources: this._fb.array(model?.sources ?? []),
        mediums: this._fb.array(model?.mediums ?? []),
        clientName: this._fb.control(model?.clientName ?? ''),
        clientPhone: this._fb.control(model?.clientPhone ?? ''),
        licencePlate: this._fb.control(model?.licencePlate ?? ''),
        agentId: this._fb.control(model?.agentId ?? ''),
        campaignId: this._fb.control(model?.campaignId ?? ''),
      }) as FormGroup;

      this.propNames = typedKeys(this.form.value);
    }
    console.log(this.form)
    return this.form;
  }

  getClientTableFilter(userId: string): ClientWithPackagesFilter {
    if (this.propNames.length > 0) {
      const selectedOption = this.mainDropdownCtrl.value;
      const searchValue = this.mainSearchCtrl.value;

      this.form.controls.clientName.reset();
      this.form.controls.clientPhone.reset();
      this.form.controls.licencePlate.reset();

      if (searchValue) {
        if (selectedOption === SearchOptions.CLIENT_NAME) {
          this.form.controls.clientName.patchValue(searchValue);
        } else if (selectedOption === SearchOptions.CLIENT_PHONE) {
          this.form.controls.clientPhone.patchValue(searchValue);
        } else if (selectedOption === SearchOptions.LICENCE_PLATE) {
          this.form.controls.licencePlate.patchValue(searchValue);
        }
      }
    }

    this.form.controls.agentId.patchValue(userId);
    this.form.controls.campaignId.patchValue(this.campaignDropdownCtrl.value);

    // debugger;

    return new ClientWithPackagesFilter(this.form.value);
  }

  clearFilter(propertyName: string) {
    const propSwitch = typedKeys(this.form.value).find(
      (prop) => prop === propertyName,
    );

    switch (propSwitch) {
      case 'sources':
      case 'mediums':
        this.form.controls[propertyName].clear();
        break;
      case 'clientName':
      case 'clientPhone':
      case 'licencePlate':
        console.log(propertyName)
        console.log('aaa')
        this.form.controls[propertyName].patchValue(null);
        this.mainSearchCtrl.patchValue('');
        break;
      default:
        this.form.controls[propertyName].patchValue(null);
    }
  }

  clearFilters() {
    typedKeys(this.form.value).forEach((propName) => {
      switch (propName) {
        case 'sources':
        case 'mediums':
          this.form.controls[propName].clear();
          break;
        case 'clientName':
        case 'clientPhone':
        case 'licencePlate':
          this.form.controls[propName].patchValue(null);
          this.mainSearchCtrl.patchValue('');
          break;
        default:
          if (this.form.controls[propName] instanceof FormControl) {
            const control = this.form.controls[propName] as FormControl;
            control.patchValue(null);
          }
      }
    });
  }

  private async _getMediums() {
    return await firstValueFrom(this._mediumsClient.getMediums(new GetMediumsQuery({
      pageNumber: 0,
      pageSize: 100
    })));
  }

  private async _getSources() {
    return await firstValueFrom(this._sourcesClient.getSources());
  }
}

export declare type ClientFilterForm = FormGroup<ControlsOf<IClientWithPackagesFilter>>;

enum SearchOptions {
  CLIENT_NAME = 'Klijent',
  CLIENT_PHONE = 'Telefon',
  LICENCE_PLATE = 'Tablice',
}
