import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { DropdownInputButtonComponent } from '@shared/components/dropdown-input-button/dropdown-input-button.component';
import { PageComponent } from '@shared/components/page/page.component';
import { AgentStatsResponse, CreateRecomendationsCommandBody, GetTableForAgentQuery, IPaginationResponseOfRecommendationResponse, RecommendationsClient } from '@shared/model/atlas.api';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { ActionMode, TableConfig } from '@ui/table/table-common';
import { TableComponent } from '@ui/table/table.component';
import { SelectItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import {TableLazyLoadEvent} from "primeng/table/table.interface";
import { TabViewModule } from 'primeng/tabview';
import { firstValueFrom, Subscription } from 'rxjs';
import {TableServiceLocal} from "@ui/table/table-service-local.service";
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { DialogService } from '@ui/dialog/dialog.service';
import { AddRecommendationFormComponent } from '@app/features/call-center/add-recommendation-form/add-recommendation-form.component';
import { ReccomendationFormService } from '@app/cc-leader/services/reccomendation-form.service';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { markFormGroupTouched } from '@shared/forms/forms-common';
import { AlertService } from '@shared/services/alert.service';
import { ChangeStatusComponent } from '@app/cc-agent/campaigns/change-status/change-status.component';

@Component({
  selector: 'atlas-recommendation-table',
  standalone: true,
  imports: [
    PageComponent,
    InlineWrapperComponent,
    DropdownComponent,
    InputTextComponent,
    ButtonModule,
    ButtonComponent,
    DropdownModule,
    FormsModule,
    TableComponent,
    NgClass,
    TabViewModule,
    DropdownInputButtonComponent,
    NgIf
  ],
  templateUrl: './recommendation-table.component.html',
  styleUrl: './recommendation-table.component.scss'
})
export class RecommendationTableComponent implements OnInit {
  private _subs: Subscription = new Subscription();

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  reccomendsNum: number;

  activeIndex: number = 0;
  searchOptionCtrl: FormControl;
  searchCtrl: FormControl;
  searchOptions: SelectItem[];
  statusSearch: boolean = true;

  paginatedData: IPaginationResponseOfRecommendationResponse;
  tableConfig: TableConfig;
  tableStats: AgentStatsResponse;

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL_MEDIUM);

  constructor(
      private _tableService: TableServiceLocal,
      private _reccomendationClient: RecommendationsClient,
      private _dialogService: DialogService,
      private _recommendService: ReccomendationFormService,
      private _alertService: AlertService,
  ) {
    this.searchOptionCtrl = new FormControl<SearchOptions>(SearchOptions.NAME);
    this.searchCtrl = new FormControl<string>('');
    this.searchOptions = [
      {
        label: SearchOptions.NAME,
        value: SearchOptions.NAME,
      },
      {
        label: SearchOptions.PHONE,
        value: SearchOptions.PHONE,
      },
    ];

    this.paginatedData = {
      data: [],
      currentPage: 0,
    }
  }

  ngOnInit() {
    this.setTableConfig().then();
    this.load();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.load();
      },
      columns: [
        {
          field: 'fullName',
          header: 'Kontakt',
          type: 'text',
          columns: [
            {
              field: 'contact',
              header: '',
              type: 'text'
            }
          ]
        },
        {
          field: 'source',
          header: 'Izvor preporuke',
          type: 'text'
        },
        {
          field: 'city',
          header: 'Grad',
          type: 'text'
        },
        {
          field: 'status',
          header: 'status',
          type: 'badge',
        }
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Kreiraj ugovor',
          label: 'Napravi ugovor',
          svgName: 'file-check-03',
          actionClass: 'p-2 ml-5 gap-2',
          actionSeverity: ButtonSeverity.SECONDARY,
          actionStyle: ButtonStyle.OUTLINED,
          shouldDisplayByCondition: () => true,
          callback: (rowIdx: number) => {
            this.handleCreateOrder(rowIdx);
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Promena statusa',
          label: 'Promeni status',
          svgName: 'loading-02',
          actionClass: 'p-2 ml-5 gap-2',
          actionSeverity: ButtonSeverity.SECONDARY_GREY,
          actionStyle: ButtonStyle.OUTLINED,
          shouldDisplayByCondition: () => true,
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this.changeStatus(rowData.id, rowData.status);
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Komentari',
          svgName: 'comment-Icon',
          actionSeverity: ButtonSeverity.SECONDARY,
          shouldDisplayByCondition: () => true,
          callback: () => {
            this.handleShowComments('');
          }
        },
        {
          mode: ActionMode.SINGLE,
          icon: 'pi pi-ellipsis-v',
          actionSeverity: ButtonSeverity.SECONDARY,
          shouldDisplayByCondition: () => true,
          callback: () => {
            alert('Detalji')
          }
        }
      ],
      emptyTableInfo: {
        header: 'Bez preporuka',
        description: 'Ovde će biti prikazana tabela preporuka',
        btnLabel: 'Dodaj asistenciju',
        action: () => this.handleAddRecommendation(),
      },
    })
  }

  handleShowComments(clientId: string) {
    alert('Komentari handle')
  }

  changeStatus(rowId: any, status: any){
    const id = rowId.toString();

    this.dialogConfig.header = 'Promena statusa';
    this.dialogConfig.customSubmitButton = {
      label: 'Sačuvaj',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
      style: 'max-width: 100px',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;
    this.dialogConfig.data = {
      recommendationId: id,
      currentStatus: status.value

    };
    const dialogRef = this._dialogService.open(
      ChangeStatusComponent,
      this.dialogConfig,
    );

    dialogRef.onClose.subscribe((result) => {
      if (result?.success) {
        this.load();
      }
    })

  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  handleCreateOrder(rowIdx: number) {
    alert('Kreiraj ugovor')
  }

  handleAddRecommendation() {
    const dialogConfig: DialogConfig = new DialogConfig(DialogSize.MEDIUM_SMALL);

    dialogConfig.header = 'Dodaj preporuku';
    dialogConfig.customSubmitButton = {
      label: 'Dodaj',
      icon: '',
    };
    dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    dialogConfig.maximisable = false;
    dialogConfig.closable = true;
    dialogConfig.dialogSize = DialogSize.MEDIUM_SMALL;

    const dialogRef = this._dialogService.open(
        AddRecommendationFormComponent,
        dialogConfig
    )

    dialogRef.onClose.subscribe(() => {
      this._recommendService.clearReccomendations()
      this.load();
    })

    dialogRef.onSubmit.subscribe(() => {
      this._handleSubmitReccomendations(dialogRef)
    })
  }

  private _handleSubmitReccomendations(dialogRef: DialogRef) {
    const reccomendationsForm = this._recommendService.getReccomendations();

    if (!reccomendationsForm.valid) {
      reccomendationsForm.controls.forEach((formGroup: FormGroup) => {
        markFormGroupTouched(formGroup);
      });
      return this._alertService.addWarnMsg("Morate uneti sva polja!")
    }

    this._subs.add(
        this._reccomendationClient.createReccomendations(reccomendationsForm.value as CreateRecomendationsCommandBody[])
            .subscribe((x) => {
              this._alertService.addSuccessMsg(x.result);
              dialogRef.close()
            })
    )
  }

  load() {
    this._getList().then((res) => {
      this.paginatedData = res.result
      this.reccomendsNum = res.result.totalCount
      this._tableService.resetIsLoading();
    })
    this._getStats().then((res) => {
      this.tableStats = res.result
    })
  }

  private async _getList() {
    return await firstValueFrom(
      this._reccomendationClient.getReccomendationsForAgent(
        new GetTableForAgentQuery({ 
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize ?? 10,
          agentName: this.searchOptionCtrl.value === SearchOptions.NAME
            ? this.searchCtrl.value
            : undefined,
          phoneNum: this.searchOptionCtrl.value === SearchOptions.PHONE
            ? this.searchCtrl.value
            : undefined,
          newReccomendations: this.statusSearch,
        })
      )
    )
  }

  private async _getStats() {
    return await firstValueFrom(
      this._reccomendationClient.getAgentStats()
    )
  }

  handleTabChange(idx: number) {
    switch (idx) {
      case 0:
        this.statusSearch = true;
        break;
      case 1:
        this.statusSearch = false;
        break;
    }
    this.load();
  }
}


enum SearchOptions {
  NAME = 'Ime kontakta',
  PHONE = 'Telefon',
}