import { DatePipe, NgClass, NgComponentOutlet, NgFor, NgForOf, NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {  CreateCommentCommand, RecommendationsClient } from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { InputDescriptionComponent } from '@ui/input-description/input-description/input-description.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { SkeletonModule } from 'primeng/skeleton';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-recommendation-comments',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgComponentOutlet,
    NgFor,
    NgForOf,
    InputTextComponent,
    InputDescriptionComponent,
    NgTemplateOutlet,
    DatePipe,
    SkeletonModule,
    NgOptimizedImage,
  ],
  templateUrl: './recommendation-comments.component.html',
  styleUrl: './recommendation-comments.component.scss'
})
export class RecommendationCommentsComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  comments: any[] = [];
  totalNumberOfComments: number;
  isLoading: boolean = true;
  commentInput: FormControl;
  reccomendationId: string;

  constructor(
    private _reccClient: RecommendationsClient,
    private _dialogfRef: DialogRef,
    private _dialogConfig: DialogConfig,
    private _alertService: AlertService
  ) {
    this.commentInput = new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
    ]);
   }

  ngOnInit(): void {
    this.reccomendationId = this._dialogConfig.data.reccomendationId || '';

    this._loadComments();

    this._subs.add(
      this._dialogfRef.onSubmit.subscribe(() => {
        this.handleSave();
      })
    )
  }

  private handleSave() {
    if (!this.commentInput.valid || !this.commentInput.value) {
      this._alertService.addWarnMsg('Potrebno je uneti tekst komentara!');
      return;
    }

    this._subs.add(
      this._reccClient.addComment(
        new CreateCommentCommand({
          recommendationId: this.reccomendationId,
          comment: this.commentInput.value
        })
      ).subscribe((x) => {
        this._alertService.addSuccessMsg(x.result.message);
        this._dialogfRef.close(this.totalNumberOfComments+1);
      })
    )
  }

  private _loadComments() {
    this._subs.add(
      this._reccClient.getComments(this.reccomendationId).subscribe((x) => {
        this.comments = x.result.commentItems;
        this.totalNumberOfComments = x.result.commentItems.length;
        this.isLoading = false;
      })
    )
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
