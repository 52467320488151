import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { PageComponent } from '@shared/components/page/page.component';
import {
  AgentsClient,
  AgentsOrderBy,
  IPaginationResponseOfAgentResponse,
} from '@shared/model/atlas.api';
import { LocationService } from '@shared/services/location.service';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { PageInfoCardComponent } from '@ui/page-info-card/page-info-card.component';
import { ActionMode, SelectMode, TableConfig } from '@ui/table/table-common';
import { TableComponent } from '@ui/table/table.component';
import {SelectItem} from 'primeng/api';
import {TableLazyLoadEvent} from "primeng/table/table.interface";
import { TabViewModule } from 'primeng/tabview';
import { firstValueFrom, Subscription } from 'rxjs';
import { ButtonComponent, ButtonStyle } from '@ui/button/button.component';
import { DropdownInputButtonComponent } from '@shared/components/dropdown-input-button/dropdown-input-button.component';
import { TableServiceLocal } from '@ui/table/table-service-local.service';

@Component({
  selector: 'atlas-agent-table',
  standalone: true,
  imports: [
    PageComponent,
    PageInfoCardComponent,
    TabViewModule,
    InlineWrapperComponent,
    DropdownComponent,
    InputTextComponent,
    FormsModule,
    TableComponent,
    ButtonComponent,
    DropdownInputButtonComponent,
  ],
  templateUrl: './agent-table.component.html',
  styleUrl: './agent-table.component.scss',
})
export class AgentTableComponent implements OnInit {
  private _subs: Subscription = new Subscription();

  onlineAgnetsCount: number = 0;
  numOfAgents: number = 0;
  salesThisMonth: string = '';
  topAgent: string = '';
  topAgentImg: string = '';
  activeIndex: number | undefined;
  statusSearch: boolean | undefined;
  searchCtrl: FormControl;
  searchOptions: SelectItem[];
  searchOptionCtrl: FormControl;
  startDate: Date = new Date();
  endDate: Date = new Date();

  protected tableConfig: TableConfig;
  paginatedData: IPaginationResponseOfAgentResponse;

  constructor(
    private _agentsClient: AgentsClient,
    private _loacationService: LocationService,
    private _tableService: TableServiceLocal,
  ) {
    this.tableConfig = {
      selectMode: SelectMode.SINGLE,
      showHeader: true,
      columns: [],
      rowsPerPage: 10,
    };

    this.paginatedData = {
      data: [],
      currentPage: 0,
    };

    this.searchOptionCtrl = new FormControl<SearchOptions>(
      SearchOptions.CLIENT,
    );

    this.searchCtrl = new FormControl<string>('');

    this.searchOptions = [
      {
        label: SearchOptions.CLIENT,
        value: SearchOptions.CLIENT,
      },
      {
        label: SearchOptions.CLIENT_EMAIL,
        value: SearchOptions.CLIENT_EMAIL,
      },
    ];
  }

  ngOnInit(): void {
    this.setTableConfig().then();
    this.loadStatCards();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez agenata',
        description: 'Ovde će biti prikazana tabela agenata',
        btnLabel: 'Dodaj agenta',
        action: () => alert('ToDo')
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first / event.rows + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.loadTable();
      },
      columns: [
        {
          header: 'Agent',
          field: 'name',
          type: 'text',
          columns: [
            {
              header: '',
              field: 'email',
              type: 'text',
            },
          ],
        },
        {
          header: 'Pozicija',
          field: 'roles',
          type: 'text',
          styleClass: 'w-10rem',
          tooltipField: 'roles',
        },
        {
          header: 'Ugovori',
          field: 'totalSales',
          type: 'number',
        },
        {
          header: 'Ovaj Mesec',
          field: 'salesThisMonth',
          type: 'number',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          icon: 'pi pi-pencil',
          title: 'Pogledaj agenta detaljnije',
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this._loacationService.routeToAgentDetailPage(rowData.id);
          },
          shouldDisplayByCondition: () => true,
        },
      ],
    });
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  loadTable() {
    this._tableService.setIsLoading();
    this.loadAgents().then((res) => {
      this.paginatedData = res.result;
      this.numOfAgents = res.result.totalCount;
      this._tableService.resetIsLoading();
    });
  }

  //  advancedSearch_Fields?: string[] | undefined, advancedSearch_Keyword?: string | null | undefined, keyword?: string | null | undefined, api_version?: string | null | undefined

  private async loadAgents() {
    return await firstValueFrom(
      this._agentsClient.getAgents(
        this.searchOptionCtrl.value === SearchOptions.CLIENT
          ? this.searchCtrl.value
          : undefined,
        this.searchOptionCtrl.value === SearchOptions.CLIENT_EMAIL
          ? this.searchCtrl.value
          : undefined,
        this.statusSearch,
        AgentsOrderBy.NAME,
        false,
        this.paginatedData.currentPage,
        this.paginatedData.pageSize,
      ),
    );
  }

  loadStatCards() {
    this._subs.add(
      this._agentsClient.getAgentStatistics().subscribe((res) => {
        this.salesThisMonth = res.result.totalSales.toString();
        this.topAgent = res.result.topAgent?.name ?? '-- bez informacije --';
        this.topAgentImg = res.result.topAgent?.imageUrl;
      }),
    );
  }

  handleTabChange(idx: number) {
    switch (idx) {
      case 1:
        this.statusSearch = true;
        break;
      case 2:
        this.statusSearch = false;
        break;
      default:
        this.statusSearch = undefined;
    }
    this.loadTable();
  }

  protected readonly ButtonStyle = ButtonStyle;
}

enum SearchOptions {
  CLIENT = 'Ime',
  CLIENT_EMAIL = 'E-Mail',
}
