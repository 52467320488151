<div class="pb-5">
  <!--    Naziv -->
  <atlas-inline-wrapper formCtrlId="name" ctrlLabel="Naziv kampanje">
    <atlas-input-text
      [formCtrl]="form.controls.name"
      formCtrlId="name"
      formCtrlClass="w-full"
      ctrlPlaceholder="Naziv kampanje"
      [showLabel]="false"
    ></atlas-input-text>
  </atlas-inline-wrapper>
  <p-divider></p-divider>
  <!--    Opis -->
  <atlas-inline-wrapper formCtrlId="name" ctrlLabel="Opis kampanje">
    <div class="comment-input">
      <atlas-input-description
        [formCtrl]="form.controls.description"
        formCtrlId="Komentar"
        ctrlPlaceholder="Tvoj komentar"
        formCtrlClass="w-full3-"
        [showInfo]="true"
      ></atlas-input-description>
    </div>
  </atlas-inline-wrapper>
  <p-divider></p-divider>
  <!--    Kraj -->
  <atlas-inline-wrapper formCtrlId="endsOnUtc" ctrlLabel="Trajanje kampanje">
    <div class="flex-column">
      <div class="mb-3">
        <p-radioButton
          name="endsOn"
          [value]="null"
          [formControl]="durationLimitCtrl"
          (onClick)="setNoLimitDuration()"
          inputId="end-on-utc"
        ></p-radioButton>
        <label for="end-on-utc" class="ml-3">Neograničeno</label>
      </div>

      <div>
        <p-radioButton
          name="endsOn"
          [value]="1"
          [formControl]="durationLimitCtrl"
          inputId="ends-select-date"
        ></p-radioButton>
        <label for="ends-select-date" class="ml-3">Izaberi datum</label>

        <div *ngIf="durationLimitCtrl.value === 1" class="mt-2 flex">
          <atlas-calendar [formCtrl]="form.controls.endsOnUtc | formControl">
          </atlas-calendar>
        </div>
      </div>
    </div>
  </atlas-inline-wrapper>
  <p-divider></p-divider>
  <!--  Izvor leads -->
  <atlas-inline-wrapper ctrlLabel="Izvor leadova">
    <div class="flex flex-column gap-3">
      <atlas-lead-file-upload
        [ngClass]="
          !service.areFilesValid() && (form.dirty || form.touched)
            ? 'invalid-card'
            : ''
        "
      ></atlas-lead-file-upload>
      <small
        *ngIf="!service.areFilesValid() && (form.dirty || form.touched)"
        class="error-info text-red-500"
      >
        Obavezan je barem jedan izvor
      </small>
    </div>
  </atlas-inline-wrapper>

  <p-divider></p-divider>
  <!-- Prioret -->
  <atlas-inline-wrapper formCtrlId="name" ctrlLabel="Prioritet kampanje"
    ><atlas-campaign-priority-dropdown [ctrlFormGroup]="form.controls.priority">
    </atlas-campaign-priority-dropdown>
  </atlas-inline-wrapper>
  <!-- Agenti -->
  <div class="header mt-4">
    Agenti
    <div class="header-desc font-normal mb-2">
      Agenti kojima će se dodeliti kampanja
    </div>
  </div>
  <p-divider></p-divider>
  <atlas-inline-wrapper ctrlLabel="Dodeli agentima">
    <atlas-agents-autocomplete
      (agentSelected)="handleAgentSelected($event)"
      [exceptAgents]="selectedAgents"
    ></atlas-agents-autocomplete>
  </atlas-inline-wrapper>
  <div class="mt-3"></div>
  <atlas-inline-wrapper>
    <div class="flex flex-column gap-3">
      <atlas-assigned-agents-list
        [agents]="selectedAgents"
        (agentRemoved)="handleAgentUnselect($event)"
        [ngClass]="
          !service.areAgentsValid() && (form.dirty || form.touched)
            ? 'invalid-card info-page-width'
            : 'info-page-width'
        "
      ></atlas-assigned-agents-list>
      <small
        *ngIf="!service.areAgentsValid() && (form.dirty || form.touched)"
        class="error-info text-red-500"
      >
        Obavezan je barem jedan agent
      </small>
    </div>
  </atlas-inline-wrapper>
</div>
