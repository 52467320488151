import { DatePipe, DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'atlasDate',
  standalone: true,
})
export class AtlasDatePipe implements PipeTransform {
  constructor(private _datePipe: DatePipe) {}

  transform(value: string | Date, ...args: any[]): any {
    return this._datePipe.transform(value, 'dd.MM.yyyy.');
  }
}
