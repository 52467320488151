import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor() {}

  private _isGray = new Subject<boolean | null>();

  get getIsGray() {
    return this._isGray.asObservable();
  }

  set setIsGray(value: boolean) {
    this._isGray.next(value);
  }
}
