import {ChangeDetectorRef, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import { ButtonComponent } from '@ui/button/button.component';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { SharedModule } from 'primeng/api';
import { IPartnerVehicleDto, PartnersClient } from '@shared/model/atlas.api';
import { Subscription } from 'rxjs';
import { AlertService } from '@shared/services/alert.service';
import { PartnerFormService } from '@app/administrator/partners/services/partner-form.service';
import {
  DialogResponse,
  VehicleDialogService,
} from '@app/administrator/partners/services/vehilce-dialog.service';
import { licencePlatePipe } from '@shared/pipes/licence-plate.pipe';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'atlas-partner-vehicle-card',
  standalone: true,
  imports: [
    ButtonComponent,
    OverlayPanelModule,
    SharedModule,
    licencePlatePipe,
  ],
  templateUrl: './partner-vehicle-card.component.html',
  styleUrl: './partner-vehicle-card.component.scss',
})
export class PartnerVehicleCardComponent implements OnDestroy {
  @Input() vehicle: IPartnerVehicleDto | any;
  @Input() vehicleIdx: number;

  @ViewChild('cardActionPanel') cardActionPanel!: OverlayPanel;

  private _subs: Subscription;
  private _isInfoPage: boolean;
  @Input() partnerId!: string;

  constructor(
    private client: PartnersClient,
    private alertService: AlertService,
    public formService: PartnerFormService,
    private vehicleDialogService: VehicleDialogService,
    private activatedRoute: ActivatedRoute,
  ) {
    this._subs = new Subscription();
    this._isInfoPage = this.activatedRoute.snapshot.data['isInfoPage'];
  }

  vehicleCardPop(event: any) {
    this.cardActionPanel.toggle(event);
  }

  handleRemoveVehicle() {
    const vehicleId = this.formService.getPartnerForm().controls.vehicles.at(
      this.vehicleIdx,
    ).value.id;
    this._subs.add(
      this.client.removePartnerVehicle(vehicleId).subscribe((res) => {
        this.alertService.addSuccessMsg(res.result);
        this.formService.getPartnerForm().controls.vehicles.removeAt(
          this.vehicleIdx,
        );
      }),
    );
  }

  handleEditVehicle() {
    const dialogRef = this.vehicleDialogService.show(
      this.partnerId,
      this.vehicle.id,
      this._isInfoPage,
    );

    this._subs.add(
      dialogRef.onClose.subscribe((res: DialogResponse) => {
        if (res) {
          this.vehicle = {
            ...this.vehicle,
            brand: res.vehicleForm.brand,
            model: res.vehicleForm.model,
            place: res.vehicleForm.place,
            pricePerKm: res.vehicleForm.pricePerKm,
            licencePlate: res.vehicleForm.licencePlate,
          };
        }
      }),
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
