<atlas-multiselect
  [formGroup]="formGroup"
  formCtrlName="sources"
  [options]="sourceOptions"
  optionLabel="name"
  ctrlLabel="Izvori"
  ctrlDataKey="id"
  ctrlPlaceholder="Odaberi izvore"
  ctrlStyleClass="w-full"
  [canFilter]="true"
/>
