<atlas-multiselect
  [formGroup]="formGroup"
  formCtrlName="mediums"
  [options]="mediumOptions"
  optionLabel="mediumName"
  ctrlLabel="Medijumi"
  ctrlDataKey="id"
  ctrlPlaceholder="Odaberi medijume"
  ctrlStyleClass="w-full"
  [canFilter]="true"
/>
