import { DatePipe, NgClass, NgFor } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { CampaignCardComponent } from '@app/cc-leader/campaigns/campaign-card/campaign-card.component';
import { InputButtonComponent } from '@shared/components/input-button/input-button.component';
import { PageComponent } from '@shared/components/page/page.component';
import {
  CampaignOrderBy,
  CampaignsClient,
  CampaignStatus,
  CampaignType,
  GetAgentRecommendationsResponse,
  ICampaignResponse,
  RecommendationsClient,
} from '@shared/model/atlas.api';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { firstValueFrom, Subscription } from 'rxjs';
import { UserService } from '@shared/services/user.service';
import { LocationCcService } from '@shared/services/location-cc.service';
import { ActiveFiltersComponent } from '@app/features/active-filters/active-filters/active-filters.component';
import { ActiveFiltersService } from '@app/features/active-filters/active-filters-service';

@Component({
  selector: 'atlas-campaign-table',
  standalone: true,
  imports: [
    PageComponent,
    NgClass,
    NgFor,
    DataViewModule,
    CampaignCardComponent,
    DatePipe,
    ButtonComponent,
    FormsModule,
    DropdownModule,
    InputButtonComponent,
    ActiveFiltersComponent
  ],
  templateUrl: './campaign-table.component.html',
  styleUrl: './campaign-table.component.scss',
})
export class CampaignTableComponent implements OnInit, OnDestroy {
  leads = [];
  nextCall: any;
  private _subs: Subscription;
  selectedRows: number;
  countLeads: number;
  private initSearch: boolean = false;
  campaignTable = new FormControl('');
  preporukeCardItem: GetAgentRecommendationsResponse;

  rowsOptions = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '15', value: 15 },
    { label: '20', value: 20 },
  ];

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  constructor(
    private _camapaignClient: CampaignsClient,
    private _recommendationClient: RecommendationsClient,
    private _location: LocationCcService,
    private _activeFiltersService: ActiveFiltersService
  ) {
    this.selectedRows = 5;
  }

  ngOnInit(): void {
    this.loadLeads().then(() => {
      this.loadNextCall().then(() => {
        this.loadRecommendationCard().then();
      });
    });
    this.campaignTable.valueChanges.subscribe((value) => {
      if (!value) {
        this._activeFiltersService.removeFilter("search");
      }
    });
  
    this._subs = this._activeFiltersService.activeFilters$.subscribe((filters) => {
      const hasSearchFilter = filters.some((f) => f.key === "search");
      if (this.initSearch && !hasSearchFilter) {
        this.campaignTable.setValue('', { emitEvent: false }); 
        this.loadLeads()
      }
      this.initSearch = hasSearchFilter;
    });
  }

  handleMoreDetails() {
    this._location.routeToScheduledCalls().then();
  }
  
  getCampaignStatus(status: number): string {
    switch (status) {
      case CampaignStatus.IN_PROGRESS:
        return 'U toku';
      case CampaignStatus.DONE:
        return 'Završena';
      case CampaignStatus.CANCELLED:
        return 'Prekinuta';
      default:
        return 'Nepoznato';
    }
  }

  private getFlagColor(priority: number): string {
    switch (priority) {
      case 4:
        return '#F04438';
      case 3:
        return '#EB9229';
      case 2:
        return '#1E7EC3';
      case 1:
        return '#1EC360';
      case 0:
        return '#999999';
      default:
        return '#999999';
    }
  }

  async loadLeads() {
    if (this.campaignTable.value) {
      this._activeFiltersService.setFilter('search', `Filter: ${this.campaignTable.value}`);
    }
    await this._fetchLeads().then((res) => {
        this.leads = res.result.data.map((item: ICampaignResponse) => ({
        id: item.id,
        title: item.title,
        finishedPercentage: +(item.finishedPercentage?.replace(',', '.') || 0),
        user: item.createdBy.name,
        status: this.getCampaignStatus(item.status),
        description: item.description || 'Bez opisa',
        date: item.endsOn,
        flagColor: this.getFlagColor(item.priority),
        info: item.type,
        totalUsers: item.leadsCount,
        agents: [...item.sellers, ...item.tempSellers]
      }));
      this.countLeads = res.result.totalCount;
    });
  }

  async loadNextCall() {
    await this._fetchNextCall().then((res) => {
      this.nextCall = res.result;
    });
  }

  async startRecommendationCampaign() {
    const res: any = await firstValueFrom(
      this._recommendationClient.getProcessRecommendation(null),
    );
    this._location.routeToProcessRecommendation(res.result.recommendationId);
  }

  async loadRecommendationCard() {
    await this._fetchAgentRecommendations().then((res) => {
      this.preporukeCardItem = res.result;
    });
  }

  private async _fetchLeads() {
    return await firstValueFrom(
      this._camapaignClient.getCampaigns(
        CampaignType.LEADS,
        this.campaignTable.value,
        CampaignStatus.IN_PROGRESS,
        CampaignOrderBy.NAME,
        false,
        0,
        100
      ),
    );
  }

  private async _fetchNextCall() {
    return await firstValueFrom(this._camapaignClient.getNextCall());
  }

  private async _fetchAgentRecommendations() {
    return await firstValueFrom(
      this._camapaignClient.getAgentRecommendations(),
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  protected readonly alert = alert;
}
