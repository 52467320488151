import { NgClass, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import {
  AssistancesClient,
  IAssistanceSidebarDetailsResponse,
} from '@shared/model/atlas.api';
import { DividerModule } from 'primeng/divider';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { firstValueFrom, Subscription } from 'rxjs';
import { DetailsTabSidebarComponent } from './details-tab-sidebar/details-tab-sidebar.component';
import { DescriptionTabSidebarComponent } from './description-tab-sidebar/description-tab-sidebar.component';
import { PathTabSidebarComponent } from "./path-tab-sidebar/path-tab-sidebar.component";

@Component({
  selector: 'atlas-assistance-sidebar',
  standalone: true,
  imports: [
    SidebarModule,
    NgIf,
    NgClass,
    DividerModule,
    TabViewModule,
    DetailsTabSidebarComponent,
    DescriptionTabSidebarComponent,
    PathTabSidebarComponent
],
  templateUrl: './assistance-sidebar.component.html',
  styleUrl: './assistance-sidebar.component.scss',
})
export class AssistanceSidebarComponent implements OnInit, OnDestroy {
  showSidebar: boolean;
  showBackArrow: boolean = true;
  private _subs: Subscription;
  clientId: string;
  assistanceId: string;
  previewData: IAssistanceSidebarDetailsResponse;
  public activeIndex: number | undefined;

  constructor(
    private service: ClientPackageTableService,
    private route: ActivatedRoute,
    private client: AssistancesClient,
  ) {
    this.showSidebar = false;
    this._subs = new Subscription();
    this.route.paramMap.subscribe((params) => {
      this.clientId = params.get('id');
    });
  }

  ngOnInit() {
    this._subs.add(
      this.service.getShowPreviewSidebar.subscribe((show) => {
        this.showSidebar = show;
        if (show) {
          this.assistanceId = this.service.getSidebarId;
          this._load().then();
        } else {
          this._reset();
        }
      }),
    );

    this._subs.add(
      this.service.getShowBackArrow().subscribe((showBackArrow) => {
        this.showBackArrow = showBackArrow; 
      })
    );
  }

  private async _load() {
    const res = await this._getPreviewData();
    this.previewData = res.result;
  }

  private async _getPreviewData() {
    return await firstValueFrom(
      this.client.getDetailsSidebarInfo(this.assistanceId),
    );
  }

  private _reset() {
    this.previewData = null;
  }

  goBack() {
    this.service.hidePreviewSidebar();
    this.showSidebar = false;
    this._reset();
  }

  ngOnDestroy() {
    this.service.hidePreviewSidebar();
    this._subs.unsubscribe();
  }
}
