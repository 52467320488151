import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import { IPackageCardItem, VehicleTypeItem } from '@shared/model/atlas.api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PackageCardListService {
  constructor(private _fb: FormBuilder) {}

  // #region BS

  selectedVehicleType = new BehaviorSubject<VehicleTypeItem>(null);

  setSelectedVehicleType(vehicleType: VehicleTypeItem) {
    this.selectedVehicleType.next(vehicleType);
  }

  getSelectedVehicleType() {
    return this.selectedVehicleType.asObservable();
  }

  vehicleTypeId = new BehaviorSubject<string>(null);

  setVehicleTypeId(vehicleTypeId: string) {
    this.vehicleTypeId.next(vehicleTypeId);
  }

  getVehicleTypeId() {
    return this.vehicleTypeId.asObservable();
  }

  selectedPackageId = new BehaviorSubject<string>(null);

  setSelectedPackageId(packageId: string, idx?: number, isRenew?: boolean) {
    if (!isRenew) {
      this.selectedPackageId.next(packageId);
    }
    this._packagesSubject.value.controls[idx].controls.id.patchValue(packageId);
  }

  setSelectedPackageIdForRenew(packageId: string) {
    this.selectedPackageId.next(packageId);
  }

  getSelectedPackageId() {
    return this.selectedPackageId.asObservable();
  }

  getSelectedPackageIdValue() {
    return this.selectedPackageId.value;
  }

  // -----------------------------------------------------------------------------
  // Renew multiple packages
  selectedPackagesForRenew = new BehaviorSubject<PackageRenewData[]>([]);

  setSelectedPackagesForRenew(packages: PackageRenewData[]) {
    this.selectedPackagesForRenew.next(packages);
  }

  getSelectedPackagesForRenew() {
    return this.selectedPackagesForRenew.asObservable();
  }

  getSelectedPackagesForRenewValue() {
    return this.selectedPackagesForRenew.value;
  }

  clearSelectedPackagesForRenew() {
    this.selectedPackagesForRenew.next([]);
  }

  // -----------------------------------------------------------------------------
  selectedPackageName = new BehaviorSubject<string>(null);

  setSelectedPackageName(name: string, idx?: number) {
    this._packagesSubject.value.controls[idx].controls.name.patchValue(name);
  }

  getSelectedPackageName() {
    return this.selectedPackageName.asObservable();
  }

  selectedPackagePrice$ = new BehaviorSubject<number>(null);

  setSelectedPackagePrice(price: number, idx?: number) {
    this._packagesSubject.value.controls[idx].controls.price.patchValue(price);
    this._packagesSubject.value.controls[idx].controls.originalPrice.patchValue(
      price,
    );
  }

  selectedClientPackageId = new BehaviorSubject<string>(null);

  setSelectedClientPackageId(selected: string) {
    this.selectedClientPackageId.next(selected);
  }

  getSelectedClientPackageId() {
    return this.selectedClientPackageId.value;
  }

  licniPaketSelected = new BehaviorSubject<boolean>(false);

  setLicniPaketSelected() {
    this.licniPaketSelected = new BehaviorSubject<boolean>(true);
    this.licniPaketSelected.next(true);
  }

  setLicniPaketUnselected() {
    this.licniPaketSelected = new BehaviorSubject<boolean>(true);
    this.licniPaketSelected.next(false);
  }

  getLicniPaketSelectedValue() {
    return this.licniPaketSelected.value;
  }

  // #endregion BS

  private _packagesSubject = new BehaviorSubject<
    FormArray<FormGroup<ControlsOf<IPackageCardItem>>>
  >(new FormArray<FormGroup<ControlsOf<IPackageCardItem>>>([]));

  packages$ = this._packagesSubject.asObservable();

  getPackages(): FormArray<FormGroup<ControlsOf<IPackageCardItem>>> {
    return this._packagesSubject.value;
  }

  setPackagePrice(idx: number, price: number) {
    this._packagesSubject.value.controls[idx].controls.price.patchValue(price);
    this._packagesSubject.next(this._packagesSubject.value);
  }

  addPackage(packageItem: IPackageCardItem) {
    this._packagesSubject.value.push(this.initPackageCardForm(packageItem));
    this._packagesSubject.next(this._packagesSubject.value);
  }

  clearAllPackages() {
    this._packagesSubject.next(
      new FormArray<FormGroup<ControlsOf<IPackageCardItem>>>([]),
    );
  }

  clearPackageById(idx: number) {
    this._packagesSubject.value.removeAt(idx);
    this._packagesSubject.next(this._packagesSubject.value);
  }

  initPackageCardForm(model?: IPackageCardItem): FormGroup {
    return this._fb.group<ControlsOf<IPackageCardItem>>({
      id: this._fb.control(model?.id, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      name: this._fb.control(model?.name),
      price: this._fb.control(model?.price),
      originalPrice: this._fb.control(model?.originalPrice),
      isChassisRequired: this._fb.control(model?.isChassisRequired),
      isJmbgRequired: this._fb.control(model?.isJmbgRequired),
      promoCodeId: this._fb.control(model?.promoCodeId),
      clientPackageId: this._fb.control(model?.clientPackageId),
    });
  }
}

export declare type PackageCardForm = FormGroup<ControlsOf<IPackageCardItem>>;

export interface PackageRenewData {
  packageId: string;
  clientPackageId: string;
}
