<div class="flex flex-column gap-2 w-full">
  <label [for]="formCtrlId" *ngIf="showLabel">{{ ctrlLabel }} </label>
  <p-calendar
    appendTo="body"
    [formControl]="formCtrl"
    [id]="formCtrlId"
    [placeholder]="ctrlPlaceholder"
    [disabled]="isDisabled"
    inputStyleClass="w-full"
    dateFormat="dd.mm.yy"
    [minDate]="minDateCtrl"
    [maxDate]="maxDateCtrl"
    hourFormat="24"
    [showTime]="ctrlShowTime"
  />
  <ng-container *ngIf="!hideErrors">
    <atlas-error-required [formCtrl]="formCtrl"></atlas-error-required>
    <atlas-error-email [formCtrl]="formCtrl"></atlas-error-email>
    <atlas-max-length [formCtrl]="formCtrl"></atlas-max-length>
  </ng-container>
</div>