<atlas-page
    pageTitle="Izvori"
    btnIcon="pi pi-plus"
    pageDescription="Upravljanje izvorima, dodavanje novih i menjanje postojećih"
    actionLabel="Dodaj izvor"
    (onHandleAction)="showCreateForm()"
>
<atlas-table
    [paginatedData]="paginatedData"
    [tableConfig]="tableConfig"
></atlas-table>
</atlas-page>