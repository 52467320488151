import { Component, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  AssistancesClient,
  SetAssistanceCostCommand,
} from '@shared/model/atlas.api';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { AlertService } from '@shared/services/alert.service';
import { LoaderService } from '@shared/components/loader/loader.service';
import {
  CostForm,
  CostFormService,
} from '@app/sos-agent/assistances/cost-form/cost-form.service';
import { DividerModule } from 'primeng/divider';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { markFormGroupTouched } from '@shared/forms/forms-common';

@Component({
  selector: 'atlas-cost-form',
  standalone: true,
  imports: [
    DividerModule,
    FormControlPipe,
    InputTextComponent,
    SelectButtonModule,
  ],
  templateUrl: './cost-form.component.html',
  styleUrl: './cost-form.component.scss',
})
export class CostFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  form: CostForm;

  constructor(
    private _dialogConfig: DialogConfig,
    private _dialogRef: DialogRef,
    private client: AssistancesClient,
    private _formService: CostFormService,
    private alert: AlertService,
    private _loaderService: LoaderService,
  ) {
    this.form = this._formService.getForm(this._dialogConfig.data['id']);
  }

  ngOnInit() {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleSubmit();
      }),
    );
  }

  private handleSubmit() {
    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      return;
    }

    this._dialogConfig.styleClass = 'display: hidden';
    this._loaderService.setShowLoader = true;

    this._requestForm().then((res) => {
      this.alert.addSuccessMsg(res.result.value);
      this._dialogRef.close();
      this._dialogConfig.styleClass = 'display: block';
      this._loaderService.reset();
    });
  }

  private async _requestForm() {
    return await firstValueFrom(
      this.client.setAssistanceCost(
        this.form.value as SetAssistanceCostCommand,
      ),
    );
  }

  ngOnDestroy(): void {
    this._formService.resetForm();
    this._subs.unsubscribe();
  }
}
