import { Component, OnDestroy, OnInit } from '@angular/core';
import { BrandAutocompleteComponent } from '@app/features/autocompletes/brand-autocomplete/brand-autocomplete.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { ModelAutocompleteComponent } from '@app/features/autocompletes/model-autocomplete/model-autocomplete.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { VehicleTypeDropdownComponent } from '@app/features/dropdowns/vehicle-type-dropdown/vehicle-type-dropdown.component';
import { ReactiveFormsModule } from '@angular/forms';
import { JsonPipe, NgClass, NgIf } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  AssistancesClient,
  AssistanceType,
  IPackageForAsssitanceItem,
} from '@shared/model/atlas.api';
import { MessagesModule } from 'primeng/messages';
import { SharedModule } from 'primeng/api';
import { SkeletonModule } from 'primeng/skeleton';
import {
  AssistanceForm,
  AssistanceFormService,
} from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import { AssistanceService } from '@app/sos-agent/assistances/assistance-form/services/assistance.service';
import { AssistanceVehicleDropdownComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/assistance-vehicle/assistance-vehicle-dropdown/assistance-vehicle-dropdown.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { PackageServiceDropdownComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/package-for-assistance/package-service-dropdown/package-service-dropdown.component';

@Component({
  selector: 'atlas-package-for-assistance',
  standalone: true,
  imports: [
    AssistanceVehicleDropdownComponent,
    BrandAutocompleteComponent,
    InputTextComponent,
    ModelAutocompleteComponent,
    RadioButtonModule,
    VehicleTypeDropdownComponent,
    NgClass,
    ReactiveFormsModule,
    DividerModule,
    MessagesModule,
    SharedModule,
    JsonPipe,
    PackageServiceDropdownComponent,
    NgIf,
    SkeletonModule,
    FormControlPipe,
  ],
  templateUrl: './package-for-assistance.component.html',
  styleUrl: './package-for-assistance.component.scss',
})
export class PackageForAssistanceComponent implements OnInit, OnDestroy {
  availablePackages: IPackageForAsssitanceItem[];
  nonAvailablePackages: IPackageForAsssitanceItem[];
  //cashPackage: IPackageForAsssitanceItem | undefined;
  _subs: Subscription;
  form: AssistanceForm;

  constructor(
    private formService: AssistanceFormService,
    private service: AssistanceService,
    private client: AssistancesClient,
  ) {
    this._subs = new Subscription();
    this.form = formService.getForm;
    this.availablePackages = [];
    this.nonAvailablePackages = [];

    if (this.form.value.clientPackageId || this.form.value.vehicle.vehicleId) {
      this._getClientPackages(
        this.form.value.client.clientId,
        this.form.value.vehicle.vehicleId,
      ).then((res) => {
        this._updatePackages(res.result.packages);
        const selectedPackage = this.availablePackages.find(
          (x) => x.clientPackageId === this.form.value.clientPackageId,
        );
        if (selectedPackage) {
          this.service.updateServices(selectedPackage.services);
          this.formService.updateSelectedService(
            null,
            selectedPackage.packageName,
          );
        }
        // else {
        //   this.handleCashPackageSelect();
        // }
      });
    }
  }

  ngOnInit() {
    // client maybe has moj paket
    this._subs.add(
      this.formService.getClientForm.controls.clientId.valueChanges.subscribe(
        (clientId) => {
          if (clientId) {
            this._loadClientPackages({ clientId: clientId });
          } else {
            this.availablePackages = [];
          }
        },
      ),
    );

    // for other vehicle packages
    this._subs.add(
      this.formService.getVehicleForm.valueChanges.subscribe((vehicle) => {
        if (vehicle.vehicleId) {
          this._loadClientPackages({
            clientId: this.form.value.client.clientId,
            vehicleId: vehicle.vehicleId,
          });
        } else {
          this._resetComponent();
        }
      }),
    );

    /* this._getCashPackage().then((cashPackage) => {
       this.cashPackage = cashPackage.result;
     });*/
  }

  handleClientPackageSelect(clientPackageId: string) {
    if (this.form.value.clientPackageId === clientPackageId) {
      return;
    }
    const selectedPackage = this.availablePackages.find(
      (x) => x.clientPackageId === clientPackageId,
    );
    this.form.controls.type.patchValue(AssistanceType.PACKAGE);
    this.form.controls.clientPackageId.patchValue(clientPackageId);
    this.service.updateServices(selectedPackage.services);
    this.formService.updateSelectedService(null, selectedPackage.packageName);
  }

  handleCashSelect() {
    this.form.controls.type.patchValue(AssistanceType.CASH);
    this.form.controls.clientPackageId.patchValue(null);
    this.formService.updateSelectedService();
  }

  handleInvoiceSelect() {
    this.form.controls.type.patchValue(AssistanceType.INVOICE);
    this.form.controls.clientPackageId.patchValue(null);
    // this.formService.updateSelectedService(); // ?
  }

  handleInternalSelect() {
    this.form.controls.type.patchValue(AssistanceType.INTERNAL);
    this.form.controls.clientPackageId.patchValue(null);
    // this.formService.updateSelectedService(); // ?
  }

  /*private async _getCashPackage() {
    return await firstValueFrom(this.client.getCashPackageForAssistance());
  }*/

  private async _getClientPackages(clientId: string, vehicleId: string) {
    return await firstValueFrom(
      this.client.getPackagesForAssistance(
        clientId,
        vehicleId,
        this.formService.getForm.value.distanceMeters ?? 1,
      ),
    );
  }

  private _loadClientPackages(args: { clientId: string; vehicleId?: string }) {
    this._getClientPackages(args.clientId, args.vehicleId).then((res) => {
      this._updatePackages(res.result.packages);
    });
  }

  private _updatePackages(packages: IPackageForAsssitanceItem[]) {
    this.availablePackages = packages.filter((x) => x.isActive);
    this.nonAvailablePackages = packages.filter((x) => !x.isActive);
  }

  protected readonly AssistanceType = AssistanceType;

  private _resetComponent() {
    this._updatePackages([]);
    this.handleCashSelect();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
