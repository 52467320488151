import {
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  IterableDiffers,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BadgeModule } from 'primeng/badge';
import {
  JsonPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
} from '@angular/common';
import { IAgentAutocompleteResponse } from '@shared/model/atlas.api';
import {ButtonComponent, ButtonSeverity, ButtonStyle} from "@ui/button/button.component";

@Component({
  selector: 'atlas-assigned-agents-list',
  standalone: true,
  imports: [BadgeModule, NgForOf, NgOptimizedImage, NgClass, JsonPipe, NgIf, ButtonComponent],
  templateUrl: './assigned-agents-list.component.html',
  styleUrl: './assigned-agents-list.component.scss',
})
export class AssignedAgentsListComponent implements DoCheck {
  currentPage: number = 0;
  totalPages: number = 0;
  paginatedAgents: IAgentAutocompleteResponse[] = [];
  iterableDiffer: any;

  @Input() pageSize = 7;
  @Input() agents: IAgentAutocompleteResponse[];
  @Output() agentRemoved = new EventEmitter<string>();

  constructor(private iterableDiffers: IterableDiffers) {
    this.agents = [];
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  nextPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
      this.updatePaginatedAgents();
    }
  }

  previousPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.updatePaginatedAgents();
    }
  }

  updatePaginatedAgents() {
    this.totalPages = Math.ceil(this.agents.length / this.pageSize);
    const start = this.currentPage * this.pageSize;
    const end = start + this.pageSize;
    this.paginatedAgents = this.agents.slice(start, end);
  }

  removeAgent(agentToRemove: IAgentAutocompleteResponse) {
    this.agentRemoved.emit(agentToRemove.id);
  }

  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.agents);
    if (changes) {
      this.updatePaginatedAgents();
    }
  }

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;
}
