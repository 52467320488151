<p-sidebar [(visible)]="showSidebar" position="right" styleClass="w-27rem left-rounded">
  <ng-template pTemplate="header">
    <div class="text-2xl font-semibold">Detalji ugovora</div>
  </ng-template>
  <ng-container *ngIf="previewData">
    <div class="flex flex-column gap-3">
      <div class="flex justify-content-between align-items-center">
        <img
          ngSrc="assets/images/user-profile.svg"
          width="48"
          height="48"
          alt="user-image"
        />
        <div class="flex flex-column gap-2">
          <div class="label-sm">Broj ugovora</div>
          <div class="label-sm-info">{{ previewData.number }}</div>
        </div>
      </div>
      <!-- <div *ngIf="statusData" [ngClass]="statusData.class">
        {{ statusData.text }}
      </div> -->
      <div class="main-title-sidebar">{{ previewData.client }}</div>
      <div class="label-md-medium">{{ previewData.address }}</div>
      <div class="flex justify-content-between">
        <div class="flex align-items-center gap-2">
          <img
            ngSrc="assets/images/phone.svg"
            width="16"
            height="16"
            alt="user-image"
          />
          <div class="label-sm-medium">{{ previewData.phone }}</div>
        </div>
        <div class="flex align-items-center gap-2">
          <img
            ngSrc="assets/images/email.svg"
            width="16"
            height="16"
            alt="user-image"
          />
          <div class="label-sm-medium">{{ previewData.email }}</div>
        </div>
      </div>
      <p-divider></p-divider>
    </div>
    <div class="flex justify-content-between">
    <div class="subtitle-sidebar">Paket</div>
        <div class="table-badge" [ngClass]="'packages-' + previewData.statusBadge?.value">
          <span class="dot"></span>
          <span> {{ previewData.statusBadge?.label }}</span>
        </div>
      </div>

      <div class="flex flex-column gap-3 package-box mt-3">
        <div class="flex gap-3 align-items-center">
          <img
            ngSrc="assets/images/package.svg"
            width="40"
            height="40"
            alt="user-image"
          />
          <div class="flex flex-column justify-content-between gap-1">
            <div class="label-sm">Vrsta paketa</div>
            <div class="label-md-medium">{{ previewData.package }}</div>
          </div>
        </div>

        <ng-container
          *ngTemplateOutlet="previewData.isActive ? packageButton : cashButton"
        ></ng-container>
      </div>
    <div class="flex flex-column gap-3 mt-3">
      <div class="flex justify-content-between">
        <div class="subtitle-sidebar">Dostupne asistencije</div>
      </div>
      <div class="flex-service-box">
        <atlas-service-availability
          *ngFor="let service of previewData.availableServices"
          [serviceName]="service.name"
          [isAvailable]="service.isAvailable"
        ></atlas-service-availability>
      
        <atlas-service-availability
          *ngFor="let service of previewData.usedServices"
          [serviceName]="service.name"
          [isAvailable]="service.isAvailable"
          [usedDate]="service.usedDate"
        ></atlas-service-availability>
      </div>
    </div>
    <div class="flex justify-content-between align-items-center mb-3 mt-4">
      <div class="flex flex-column justify-content-between gap-2">
        <div class="label-sm">Početak ugovora</div>
        <div class="label-sm-info">
          {{ previewData.start }}
        </div>
      </div>
      <div>
        <i class="pi pi-arrow-right"></i>
      </div>
      <div class="flex flex-column justify-content-between gap-2">
        <div class="label-sm">Ističe</div>
        <div class="label-sm-info">
          {{ previewData.end }}
        </div>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="flex flex-column gap-3">
      <div class="subtitle-sidebar">Vozilo</div>
      <div class="flex justify-content-between">
        <div class="label-sm">Vozilo</div>
        <div class="label-sm-info">{{ previewData.vehicle ?? '--' }}</div>
      </div>
      <div class="flex justify-content-between">
        <div class="label-sm">Tablice</div>
        <div class="label-sm-info">{{ previewData.licencePlate ?? '--'}}</div>
      </div>
      <div class="flex justify-content-between">
        <div class="label-sm">Šasija</div>
        <div class="label-sm-info">{{ previewData.chassis ?? '--'}}</div>
      </div>
    </div>
  </ng-container>
</p-sidebar>

<ng-template #packageButton>
  <atlas-button
    btnLabel="Započni asistenciju"
    btnClass="w-full"
    [btnSeverity]="ButtonSeverity.PRIMARY"
    [btnStyle]="ButtonStyle.BASIC"
    (btnClicked)="handleCreateForPackage()"
    [isBtnDisabled]="isReadonly"
    [btnTooltip]="isReadonly ? 'Nemate prava pristupa' : undefined"
  >
    <img
      ngSrc="assets/images/buttons/assistance.svg"
      width="20"
      height="20"
      alt="action-image"
    />
  </atlas-button>
</ng-template>

<ng-template #cashButton>
  <atlas-button
    btnLabel="Započni asistenciju za keš"
    btnClass="w-full"
    [btnSeverity]="ButtonSeverity.SECONDARY"
    [btnStyle]="ButtonStyle.BASIC"
    (btnClicked)="handleCreateForCash()"
    [isBtnDisabled]="isReadonly"
    [btnTooltip]="isReadonly ? 'Nemate prava pristupa' : undefined"
  >
    <img
      ngSrc="assets/images/buttons/assistance.svg"
      width="20"
      height="20"
      alt="action-image"
    />
  </atlas-button>
</ng-template>
