import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators,} from '@angular/forms';
import {ControlsOf} from '@shared/forms/forms-common';
import {DurationType, ICreateSelfRenewingCampaignCommand,} from '@shared/model/atlas.api';

@Injectable({
  providedIn: 'root',
})
export class SelfRenewingCampaignFormService {
  private _form: SelfRenewingForm;

  constructor(private _fb: FormBuilder) {}

  getForm() {
    if (!this._form) {
      this.initForm();
    }
    return this._form;
  }

  private initForm(model?: ICreateSelfRenewingCampaignCommand) {
    this._form = this._fb.group<ControlsOf<ICreateSelfRenewingCampaignCommand>>({
      name: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      description: this._fb.control('', {
        nonNullable: true,
        validators: [Validators.maxLength(100)],
      }),
      conditionPeriod: this._fb.control(model?.conditionPeriod ?? DurationType.MONTH),
      conditionValue:  this._fb.control(model?.conditionValue ?? 1),
    });
  }

  reset() {
    this._form = undefined;
  }
}

export declare type SelfRenewingForm = FormGroup<
  ControlsOf<ICreateSelfRenewingCampaignCommand>
>;
