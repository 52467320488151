<atlas-scrollable-page
  [pageTitle]="mode == Mode.EDIT ? 'Izmena paketa' : 'Dodavanje paketa'"
  [submitBtnLabel]="mode == Mode.EDIT ? 'Izmeni paket' : 'Dodaj paket'"
  [submitBtnIcon]="mode == Mode.EDIT ? 'edit' : 'add'"
  [isGray]="true"
  (onCancelAction)="handleCancelForm()"
  (onSubmitAction)="handleSubmitForm()"
>
  <ng-template #content>
    <!-- Osnovni podaci paketa -->
    <!--ToDo: dodati validaciju za okvir sekcije -->
    <div [ngClass]="'flat-card'">
      <!-- Naslov -->
      <div class="header">
        Osnovno
        <div class="header-desc font-normal">Osnovni podaci paketa</div>
      </div>

      <div class="content flex flex-column gap-3">
        <!-- Naziv -->
        <atlas-inline-wrapper
          formCtrlId="name"
          ctrlLabel="Naziv"
          ctrlDesc="Naziv paketa na oba jezika"
        >
          <div class="flex flex-column align-items-end gap-1">
            <atlas-input-text
              [formCtrl]="form?.controls.name"
              formCtrlId="name"
              formCtrlClass="w-full lg:w-30rem"
              ctrlPlaceholder="Naziv"
              showLabel="false"
            ></atlas-input-text>
            <small class="text-xs">Srpski</small>
          </div>
        </atlas-inline-wrapper>

        <!-- Naziv na engleskom -->
        <atlas-inline-wrapper formCtrlId="name">
          <div class="flex flex-column align-items-end gap-1">
            <atlas-input-text
              [formCtrl]="form?.controls.nameEnglish"
              formCtrlId="name"
              formCtrlClass="w-full lg:w-30rem"
              ctrlPlaceholder="Naziv na engleskom"
              showLabel="false"
            ></atlas-input-text>
            <small class="text-xs">Engleski</small>
          </div>
        </atlas-inline-wrapper>

        <p-divider></p-divider>

        <!-- Opis -->
        <atlas-inline-wrapper
          formCtrlId="description"
          ctrlLabel="Opis paketa"
          ctrlDesc="Opis paketa na oba jezika"
        >
          <div class="flex flex-column align-items-end">
            <atlas-input-textarea
              [formCtrl]="form?.controls.description"
              ctrlPlaceholder="Opis"
              formCtrlId="description"
            ></atlas-input-textarea>
            <small class="text-xs">Srpski</small>
          </div>
        </atlas-inline-wrapper>

        <atlas-inline-wrapper formCtrlId="descriptionEng">
          <div class="flex flex-column align-items-end">
            <atlas-input-textarea
              [formCtrl]="form?.controls.descriptionEnglish"
              ctrlPlaceholder="Opis na engleskom"
              formCtrlId="descriptionEng"
            ></atlas-input-textarea>
            <small class="text-xs">Engleski</small>
          </div>
        </atlas-inline-wrapper>

        <p-divider></p-divider>

        <!-- Slika -->
        <atlas-inline-wrapper ctrlLabel="Slika">
          <div>
            <atlas-file-upload
              [imageUri]="imageUri"
              (onUpload)="handlePhotoUpload($event)"
            ></atlas-file-upload>
          </div>
        </atlas-inline-wrapper>
      </div>
    </div>

    <!-- Detalji paketa -->
    <div [ngClass]="'flat-card mt-4'">
      <!-- Naslov -->
      <div class="header">
        Detalji
        <div class="header-desc font-normal">
          Vozila i karakteristike paketa
        </div>
      </div>

      <div class="content flex flex-column gap-3">
        <!-- Obavezan broj sasije -->
        <atlas-inline-wrapper
          formCtrlId="isVehicleRequired"
          ctrlLabel="Broj šasije"
          ctrlDesc="Da li je broj šasije obavezan pri kupovini paketa?"
          class="mb-5"
        >
          <div class="flex-column">
            <div class="mb-3">
              <p-radioButton
                [formControl]="form?.controls.isVehicleRequired"
                [value]="true"
                label="Da"
                name="chassis"
              ></p-radioButton>
            </div>

            <div>
              <p-radioButton
                [formControl]="form?.controls.isVehicleRequired"
                [value]="false"
                name="chassis"
                label="Ne"
              ></p-radioButton>
            </div>
          </div>
          <div></div>
        </atlas-inline-wrapper>

        <!-- Obavezan JMBG -->
        <atlas-inline-wrapper
          formCtrlId="isClientIdRequired"
          ctrlLabel="JMBG"
          ctrlDesc="Da li je JMBG obavezan pri kupovini paketa?"
        >
          <div class="flex-column">
            <div class="mb-3">
              <p-radioButton
                [formControl]="form?.controls.isClientIdRequired"
                [value]="true"
                label="Da"
                name="jmbg"
              ></p-radioButton>
            </div>
            <div class="mb-3">
              <p-radioButton
                [formControl]="form?.controls.isClientIdRequired"
                [value]="false"
                name="jmbg"
                label="Ne"
              ></p-radioButton>
            </div>
          </div>
        </atlas-inline-wrapper>

        <!-- Tipovi vozila -->
        <atlas-inline-wrapper
          formCtrlId="vehicleTypes"
          ctrlLabel="Vozila"
          ctrlDesc="Vozila kojima će paket biti omogućen"
        >
          <div class="flex gap-4 box-padding">
            <div class="vehicle-box" *ngFor="let type of vehicleTypes">
              <atlas-vehicle-type-card
                [type]="type"
                [selectedTypes]="form.value.vehicleTypes"
                (typeId)="handleVehicleTypeClicked($event)"
              ></atlas-vehicle-type-card>
            </div>
          </div>
        </atlas-inline-wrapper>

        <!-- Pokrivena teritorija -->
        <atlas-inline-wrapper
          formCtrlId="coverTeritory"
          ctrlLabel="Pokriva teritoriju"
          ctrlDesc="Teritorija koja je pokrivena paketom"
        >
          <atlas-input-text
            [formCtrl]="form.controls.coverTeritory"
            formCtrlId="coverTeritory"
            formCtrlClass="w-full lg:w-30rem"
            ctrlPlaceholder="Pokrivena teritorija"
            showLabel="false"
          ></atlas-input-text>
        </atlas-inline-wrapper>

          <p-divider></p-divider>

          <!-- Total limit -->
        <atlas-inline-wrapper
          formCtrlId="totalServicesLimit"
          ctrlLabel="Ukupan dozvoljeni broj usluga u paketu"
          ctrlDesc="Broj usluga različitog tipa koje klijent može iskoristiti u okviru paketa"
        >
          <atlas-input-text
            [isPriceFormat]="true"
            [ctrlUseGrouping]="false"
            [ctrlNumberMode]="undefined"
            formCtrlClass="w-full lg:w-30rem"
            ctrlPlaceholder="Ukupni broj usluga"
            [formCtrl]="form.controls.totalServicesLimit"
          ></atlas-input-text>
        </atlas-inline-wrapper>

        <!-- Glavne usluge -->
        <atlas-inline-wrapper
          formCtrlId="packageServices"
          ctrlLabel="Glavne usluge paketa"
          class="mt-4"
          *ngIf="services?.length > 0"
        >
          <div class="flex flex-column gap-2 w-full lg:w-30rem">
            @for (serviceItem of services; track serviceItem.id) {
              <div
                class="flex gap-3 align-items-center justify-content-between"
              >
                <div>
                  <p-checkbox
                    [(ngModel)]="
                      selectedPackageServices[serviceItem.id].isSelected
                    "
                    (onChange)="
                      handleServiceCheckboxChanged(
                        serviceItem.id,
                        $event.checked
                      )
                    "
                    [label]="serviceItem.name"
                    [binary]="true"
                  ></p-checkbox>
                  <div
                    *ngIf="serviceItem.description"
                    class="service-description"
                  >
                    {{ serviceItem.description }}
                  </div>
                </div>
                <p-inputNumber
                  inputId="integeronly"
                  placeholder="Broj usluga"
                  inputStyleClass="w-full"
                  [useGrouping]="false"
                  [autofocus]="
                    mode !== Mode.EDIT &&
                    selectedPackageServices[serviceItem.id].isSelected
                  "
                  [(ngModel)]="
                    selectedPackageServices[serviceItem.id].serviceLimit
                  "
                  (onBlur)="handleLimitInput(serviceItem.id, $event)"
                  [required]="
                    selectedPackageServices[serviceItem.id].isSelected
                  "
                ></p-inputNumber>
              </div>
            }
          </div>
        </atlas-inline-wrapper>

        <p-divider></p-divider>

        <!-- Dodatne usluge -->
        <atlas-inline-wrapper
          formCtrlId="packageDetails"
          ctrlLabel="Opis usluga paketa"
          ctrlDesc="Srpski"
          class="mt-4"
        >
          <div class="w-full lg:w-30rem">
            <atlas-package-detail-autocomplete
              [detailsFormArray]="form.controls.packageDetails"
              ctrlPlaceholder="Opis usluge na srpskom"
            ></atlas-package-detail-autocomplete>
          </div>
        </atlas-inline-wrapper>

        <atlas-inline-wrapper>
          <div *ngIf="form.controls.packageDetails.valid" class="services-container flex box-padding w-full lg:w-30rem">
            <div
              *ngFor="let detail of form.value.packageDetails; let idx = index"
              class="service-badge-container"
            >
              <div class="service-badge flex">
                <div class="badge-txt">
                  {{ detail }}
                </div>
                <button
                  (click)="handleRemoveBadge(idx, false)"
                  class="pi pi-times badge-btn"
                ></button>
              </div>
            </div>
          </div>

            <div *ngIf="shouldDisplayRequiredError(form.controls.packageDetails | formControl)" class="error-info text-red-500">
                Morate uneti barem jedan opis za uslugu
            </div>
        </atlas-inline-wrapper>

        <!-- Dodatne usluge na engleskom -->
        <atlas-inline-wrapper
          formCtrlId="packageDetailsEnglish"
          ctrlLabel="Opis usluga paketa"
          ctrlDesc="Engleski"
          class="mt-6"
        >
          <div class="w-full lg:w-30rem">
            <atlas-package-detail-autocomplete
                    [detailsFormArray]="form.controls.packageDetailsEnglish"
                    [isInEnglish]="true"
              ctrlPlaceholder="Opis usluge na engleskom"
            ></atlas-package-detail-autocomplete>
          </div>
        </atlas-inline-wrapper>

        <atlas-inline-wrapper>
          <div *ngIf="form.controls.packageDetailsEnglish.valid" class="services-container flex box-padding w-full lg:w-30rem">
            <div
              *ngFor="let detail of form.value.packageDetailsEnglish; let idx = index"
              class="service-badge-container"
            >
              <div class="service-badge flex">
                <div class="badge-txt">
                  {{ detail }}
                </div>
                <button
                  (click)="handleRemoveBadge(idx, true)"
                  class="pi pi-times badge-btn"
                ></button>
              </div>
            </div>
          </div>

            <div *ngIf="shouldDisplayRequiredError(form.controls.packageDetailsEnglish | formControl)" class="error-info text-red-500">
                Morate uneti barem jedan opis za uslugu
            </div>
        </atlas-inline-wrapper>
      </div>
    </div>

    <!-- Cene -->
    <div [ngClass]="'flat-card mt-4'">
      <!-- Naslov -->
      <div class="header">
        Cena
        <div class="header-desc font-normal">Formiranje cene paketa</div>
      </div>

      <div class="content flex flex-column gap-3">
        <!-- Stara cena -->
        <atlas-inline-wrapper
          formCtrlId="oldPrice"
          ctrlLabel="Stara cena"
          ctrlDesc="Stara cena paketa"
        >
          <atlas-input-addon
            ctrlPlaceholder="Cena"
            [formCtrl]="form?.controls.oldPrice"
            addonText="RSD"
            formCtrlClass="w-full lg:w-26rem"
            [isPriceFormat]="true"
          ></atlas-input-addon>
        </atlas-inline-wrapper>
        <!-- Osnovna cena -->
        <atlas-inline-wrapper
          formCtrlId="price"
          ctrlLabel="Trenutna cena"
          ctrlDesc="Cena paketa na godisnjem nivou"
        >
          <atlas-input-addon
            ctrlPlaceholder="Cena"
            [formCtrl]="form?.controls.price"
            addonText="RSD"
            formCtrlClass="w-full lg:w-26rem"
            [isPriceFormat]="true"
          ></atlas-input-addon>
        </atlas-inline-wrapper>
      </div>
    </div>

    <!-- Website podaci -->
    <div [ngClass]="'flat-card mt-4'">
      <!-- Naslov -->
      <div class="header">
        Website podaci
        <div class="header-desc font-normal">
          Pomoćne informacije vezane sa website
        </div>
      </div>

      <div class="content flex flex-column gap-3">
        <!-- MoreDetails file -->
        <atlas-inline-wrapper
          formCtrlId="moreDetailsFileName"
          ctrlLabel="Naziv fajla dodatnih detalja"
          ctrlDesc="Naziv fajla sa dodatnim podacima"
        >
          <atlas-input-text
            [formCtrl]="form.controls.moreDetailsFileName"
            formCtrlId="moreDetailsFileName"
            formCtrlClass="w-full lg:w-30rem"
            ctrlPlaceholder="Naziv fajla"
            showLabel="false"
          ></atlas-input-text>
        </atlas-inline-wrapper>

        <!-- TermsAndConditionos uri -->
        <atlas-inline-wrapper
          formCtrlId="termsAndConditionsUri"
          ctrlLabel="Uslovi koriscenja URL"
          ctrlDesc="Link za uslove korišćenja"
        >
          <atlas-input-text
            [formCtrl]="form.controls.termsAndConditionsUri"
            formCtrlId="termsAndConditionsUri"
            formCtrlClass="w-full lg:w-30rem"
            ctrlPlaceholder="URL za terms and conditions"
            showLabel="false"
          ></atlas-input-text>
        </atlas-inline-wrapper>

        <!-- TermsAndConditionos uri -->
        <atlas-inline-wrapper
          formCtrlId="termsOfUseFileName"
          ctrlLabel="Uslovi koriscenja naziv"
          ctrlDesc="Naziv fajla za uslove korišćenja"
        >
          <atlas-input-text
            [formCtrl]="form.controls.termsOfUseFileName"
            formCtrlId="termsOfUseFileName"
            formCtrlClass="w-full lg:w-30rem"
            ctrlPlaceholder="Uslovi korišćenja"
            showLabel="false"
          ></atlas-input-text>
        </atlas-inline-wrapper>
      </div>
    </div>
  </ng-template>
</atlas-scrollable-page>
