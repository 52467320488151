<div
  class="flex flex-column md:flex-row align-items-start gap-5"
  id="wraper-component"
>
  <label *ngIf="showLabel" [for]="formCtrlId"
    >{{ ctrlLabel }}
    <div *ngIf="ctrlDesc" class="label-description">{{ ctrlDesc }}</div></label
  >
  <ng-content></ng-content>
</div>
