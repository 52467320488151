import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AlertService } from '@shared/services/alert.service';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { ButtonModule } from 'primeng/button';
import { Subscription } from 'rxjs';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from "../../../infrastructure/ui/button/button.component";
import { ClientClient, EmailChangeRequestCommand } from '@shared/model/atlas.api';

@Component({
  selector: 'atlas-password-change',
  standalone: true,
  imports: [
    InputTextComponent,
    ButtonModule,
],
  templateUrl: './password-change.component.html',
  styleUrl: './password-change.component.scss'
})
export class PasswordChangeComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription(); 

  newMailInput = new FormControl('');
  showValidationCodeInput: boolean = true;
  currentMail: string;
  clientId: string;

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  constructor(
    private _dialogConfig: DialogConfig,
    private _dialogRef: DialogRef,
    private _clientClient: ClientClient,
    private _alertService: AlertService
  ) {
    this.currentMail = this._dialogConfig.data.clientMail;
    this.clientId = this._dialogConfig.data.clientId;
  }

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._clientClient.changeClientEmail(new EmailChangeRequestCommand({
          email: this.newMailInput.value,
          clientId: this.clientId
        })).subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this.showValidationCodeInput = true;
          this._dialogRef.close();
        })
      })
    )
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
