<p-sidebar
  [(visible)]="showSidebar"
  position="right"
  styleClass="w-27rem left-rounded"
>
<ng-template pTemplate="header">
    <div class="text-2xl font-semibold">Filteri</div>
  </ng-template>
  <div class="flex flex-column justify-content-between h-full">
    <div>
      <div class="flex flex-column gap-3">
        <div class="mb-1">
          <label class="font-semibold mb-2">Status</label>
          <div class="mt-3">
            <p-checkbox
              [(ngModel)]="selectedActive"
              [binary]="true"
              [value]="true"
              label="Aktivan"
              (onChange)="handleStatusChange()"
              [disabled]="selectedInactive"
            ></p-checkbox>
            <p-checkbox
              class="ml-3"
              [(ngModel)]="selectedInactive"
              [binary]="true"
              [value]="false"
              label="Neaktivan"
              (onChange)="handleStatusChange()"
              [disabled]="selectedActive"
            ></p-checkbox>
          </div>
        </div>
        <atlas-calendar
        formCtrlId="start"
        ctrlLabel="Počinje"
        [formCtrl]="form.controls.start| formControl"
        [hideErrors]="true"
      ></atlas-calendar>
        <atlas-calendar
        formCtrlId="start"
        ctrlLabel="Počinje od"
        [formCtrl]="form.controls.startFrom| formControl"
        [hideErrors]="true"
      ></atlas-calendar>
        <atlas-calendar
          formCtrlId="createdOn"
          ctrlLabel="Kreiran"
          [formCtrl]="form.controls.createdOn| formControl"
          [hideErrors]="true"
        ></atlas-calendar>
        <atlas-calendar
          formCtrlId="start"
          ctrlLabel="Kreiran od"
          [formCtrl]="form.controls.createdOnFrom| formControl"
          [hideErrors]="true"
        ></atlas-calendar>
        <atlas-calendar
          formCtrlId="start"
          ctrlLabel="Završava od"
          [formCtrl]="form.controls.endFrom| formControl"
          [hideErrors]="true"
        ></atlas-calendar>
        <atlas-calendar
          formCtrlId="start-from"
          ctrlLabel="Završava do"
          [formCtrl]="form.controls.endTo | formControl"
          [hideErrors]="true"
        ></atlas-calendar>
        <atlas-medium-multiselect [mediumsCtrl]="form.controls.mediums">
        </atlas-medium-multiselect>
        <atlas-sources-multiselect
          [sourceCtrl]="form.controls.sources"
        ></atlas-sources-multiselect>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex gap-3 border-top-1 border-gray-200 pt-3 w-full">
      <div class="flex-1">
        <atlas-button
          btnLabel="Poništi"
          btnClass="w-full"
          btnIcon="pi pi-times-circle"
          [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
          [btnStyle]="ButtonStyle.BASIC"
          (btnClicked)="handleResetFilters()"
        >
        </atlas-button>
      </div>
      <div class="flex-1">
        <atlas-button
          btnLabel="Primeni"
          btnClass="w-full flex-1"
          btnIcon="pi pi-check-circle"
          [btnSeverity]="ButtonSeverity.SECONDARY"
          [btnStyle]="ButtonStyle.BASIC"
          (btnClicked)="handleApplyFilters()"
        >
        </atlas-button>
      </div>
    </div>
  </ng-template>
</p-sidebar>
