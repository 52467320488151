<atlas-dropdown
  [showLabel]="false"
  [options]="flags"
  ctrlPlaceholder="Odaberite prioritet"
  [formCtrl]="formCtrl"
>
  <ng-template let-priority #selectedItem>
    <div class="selected-item">
      <i
        class="pi pi-flag-fill"
        [style.color]="priority.icon"
      ></i>
      {{ priority.label }}
    </div>
  </ng-template>

  <ng-template let-priority #item>
    <div class="dropdown-item">
      <i class="pi pi-flag-fill" [style.color]="priority.icon"></i>
      {{ priority.label }}
    </div>
  </ng-template>
</atlas-dropdown>