<div class="flex">
  <atlas-input-text
    ctrlPlaceholder="Cena asistencije..."
    ctrlLabel="Cena"
    formCtrlId="cost-fee"
    [formCtrl]="form.controls.cost"
    class="w-full"
    [isPriceFormat]="true"
  ></atlas-input-text>
</div>
