import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {
  ClientClient, ClientStatisticsResponse, GetClientStatisticsQuery,
  GetClientWithPackagesTableQuery,
  IPaginationResponseOfClientWithPackagesTableResponse
} from '@shared/model/atlas.api';
import {LocationService} from '@shared/services/location.service';
import {ButtonSeverity, ButtonStyle} from '@ui/button/button.component';
import {DialogConfig, DialogSize} from '@ui/dialog/dialog-config';
import {TableConfig} from '@ui/table/table-common';
import {SelectItem} from 'primeng/api';
import {firstValueFrom, Subscription} from 'rxjs';
import {
  ClientWithPackagesTableComponent
} from '@app/features/orders/client-with-packages-table/client-with-packages-table.component';
import {PageComponent} from '@shared/components/page/page.component';
import {ActivatedRoute} from '@angular/router';
import {AgentCcRoleStr, B2BAgentRoleStr, LeaderCcRoleStr, UserService} from '@shared/services/user.service';
import {
  ClientWithPackageTableFilterService
} from "@app/features/orders/services/client-with-package-table-filter.service";
import {ActiveFiltersService} from "@app/features/active-filters/active-filters-service";
import {PageInfoCardComponent} from "@ui/page-info-card/page-info-card.component";
import {CurrencyNumberPipe} from "@shared/pipes/currency-number.pipe";

@Component({
  selector: 'atlas-client-with-packages-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    ClientWithPackagesTableComponent,
    ClientWithPackagesTableComponent,
    PageInfoCardComponent,
    CurrencyNumberPipe,
  ],
  templateUrl: './client-with-packages-page.component.html',
  styleUrl: './client-with-packages-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientWithPackagesPageComponent implements OnInit, OnDestroy {
  paginatedData: IPaginationResponseOfClientWithPackagesTableResponse;
  private _subs: Subscription = new Subscription();
  protected tableConfig: TableConfig;
  searchOptions: SelectItem[];
  searchOptionCtrl: FormControl;
  searchCtrl: FormControl;
  private initSearch: boolean = false;
  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);
  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;
  clientPackagesStats: ClientStatisticsResponse | undefined;

  @Input() showSearchBar: boolean = true;
  @Input() showHeader: boolean = false;
  @Input() pageTitle: string = 'Klijenti';
  @Input() pageDescription: string = '';
  @Input() actionLabel: string = '';

  @Output() clientSelected = new EventEmitter<any>();

  constructor(
    private _locationService: LocationService,
    private acivatedRoute: ActivatedRoute,
    private _userService: UserService,
    private _client: ClientClient,
    public filterService: ClientWithPackageTableFilterService,
    private _activeFiltersService: ActiveFiltersService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.acivatedRoute.data.subscribe((data) => {
      console.log(data);
    });
  }

  ngOnInit(): void {
    this._subs.add(
      this.filterService.getReloadTable.subscribe((reloadTable) => {
        if (reloadTable) {
          this.load();
        }
      }),
    );

    this._subs.add(
      this.filterService.mainSearchCtrl.valueChanges.subscribe((value) => {
        if (!value) {
          this._activeFiltersService.removeFilter("search");
        }
      })
    );

    this._subs.add(
      this._activeFiltersService.activeFilters$.subscribe((filters) => {
        const hasSearchFilter = filters.some((f) => f.key === "search");
        if (this.initSearch && !hasSearchFilter) {
          this.filterService.mainSearchCtrl.setValue('', {emitEvent: false});
          this.load();
        }
        this.initSearch = hasSearchFilter;
      })
    );

  }

  handleCreateClient() {
    this._locationService
      .routeToCreateClient(this._userService.getRoleRoutePrefix(
        AgentCcRoleStr,
        B2BAgentRoleStr,
        LeaderCcRoleStr))
      .then();
  }

  private load() {
    this._loadStatistics().then((res) => {
      if (res.status === 200) {
        this.clientPackagesStats = res.result;
        this.changeDetectorRef.detectChanges();
      }
    })
  }

  private async _loadStatistics() {
    return await firstValueFrom(
      this._client.getClientPackageStatistics(
        new GetClientStatisticsQuery({
          filter: this.filterService.getClientTableFilter(undefined)
        }),
      ),
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
