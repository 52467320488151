<div class="flex justify-content-between box-wrapper" [class.selected]="isSelected" [class.disabled]="isDisabled" (click)="onSelect()"
     hover-class="hovered">
  <div class="flex gap-3">
    <!-- Conditionally render either an img or an i element without wrapping the img in icon-wrap -->
    <ng-container *ngIf="imageUrl; else iconTemplate">
      <div class="icon-wrap" [class.icon-selected]="isSelected">
        <img [ngSrc]="imageUrl" alt="Icon" class="custom-icon" width="24" height="24">
      </div>
    </ng-container>
    <ng-template #iconTemplate>
      <div class="icon-wrap" [class.icon-selected]="isSelected">
        <i [ngClass]="[iconClass, 'icon']"></i>
      </div>
    </ng-template>

    <div class="container-checkbox">
      <div class="label ml-3">{{ name }}</div>
    </div>
  </div>

  <div class="checkbox-div">
    <div class="checkbox">
      <img *ngIf="isSelected" ngSrc="/assets/images/checkbox-selected-icon.svg" alt="Icon" class="custom-icon"
           width="20" height="20">
      <img *ngIf="!isSelected" ngSrc="/assets/images/checkbox-unselected-icon.svg" alt="Icon" class="custom-icon"
           width="20" height="20">
    </div>
  </div>
</div>
