    <div class="subtitle-text">Lični paket</div>
    <div class="mt-2 mb-3 box-info">Lični paket klijenta</div>
    <div *ngIf="showSkeleton">
          <div >
            <p-skeleton class="skeleton-box" width="100%" height="190px"/>
          </div>
          
    </div>
    <div *ngIf="!showSkeleton && !ownPackage.isActive" class="package-box">
      <div class="table-title">Bez ličnog paketa</div>
      <div class="table-info">Klijent nema lični paket</div>
      <atlas-button
        class="client-button w-full"
        [btnStyle]="ButtonStyle.BASIC"
        [btnSeverity]="ButtonSeverity.DANGER"
        btnLabel="Kupi moj paket"
      ></atlas-button>
    </div>
    <div *ngIf="!showSkeleton && ownPackage.isActive" class="package-card">
      <div class="vehicle-header">
        <div class="vehicle-icon">
          <i class="pi pi-user"></i>
        </div>
        <div class="vehicle-details">
          <span class="vehicle-type">Lični paket</span>
          <div class="vehicle-name">Moj paket</div>
        </div>
      </div>
      <div class="info-package">
        <div class="sub-title">Ističe</div>
        <div class="date-time">{{ownPackage.dueToStr}}</div>
      </div>
    </div>