import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import {
  ICreatePackageCommand,
  PackagePriceItem,
  PriceValueType,
} from '@shared/model/atlas.api';
import { BehaviorSubject } from 'rxjs';
import { addHours } from '@shared/extensions-common';

@Injectable({
  providedIn: 'root',
})
export class PackageService {
  constructor(private _fb: FormBuilder) {}

  packageForm: PackageForm;
  actionPriceForm: ActionPriceForm;

  initPackageForm(model?: ICreatePackageCommand) {
    if (!this.packageForm) {
      this.packageForm = this._fb.group<ControlsOf<ICreatePackageCommand>>({
        name: this._fb.control(model?.name, {
          nonNullable: true,
          validators: [Validators.maxLength(64), Validators.required],
        }),
        description: this._fb.control(model?.description, {
          nonNullable: true,
          validators: [Validators.maxLength(128), Validators.required],
        }),
        isVehicleRequired: this._fb.control(model?.isVehicleRequired ?? false, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        isClientIdRequired: this._fb.control(
          model?.isClientIdRequired ?? false,
          {
            nonNullable: true,
            validators: [Validators.required],
          },
        ),
        vehicleTypes: this._fb.array<FormControl<string>>([]),
        packageServices: this._fb.array<FormControl<string>>([]),
        packageDetails: this._fb.array<FormControl<string>>([]),
        price: this._fb.control(model?.price, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        oldPrice: this._fb.control(model?.oldPrice, {
          nonNullable: true,
          validators: [Validators.maxLength(64)],
        }),
        packagePrices: this._fb.array<FormGroup<ControlsOf<PackagePriceItem>>>(
          [],
        ),
        packageFee: this._fb.control(model?.packageFee, {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(64)],
        }),
      });
    }
    return this.packageForm;
  }

  initActionPriceForm(model?: PackagePriceItem) {
    this.actionPriceForm = this._fb.group<ControlsOf<PackagePriceItem>>({
      id: this._fb.control(model?.id),
      price: this._fb.control(model?.price, {
        nonNullable: true,
        validators: [Validators.maxLength(64)],
      }),
      description: this._fb.control(model?.description, {
        nonNullable: true,
        validators: [Validators.maxLength(128)],
      }),
      isPublic: this._fb.control(false, {}), // Gde cemo ovo definisati?
      packageId: this._fb.control(model?.packageId, {
        nonNullable: true,
      }),
      init: undefined,
      toJSON: undefined,
    });
    return this.actionPriceForm;
  }

  reset() {
    this.packageForm = undefined;
    this.actionPriceForm = undefined;
  }

  setPackageId(id: string) {
    this.selectedPackageId$.next(id);
  }

  selectedPackageId$ = new BehaviorSubject<string>(undefined);

  set setSelectedPackageId(value: string) {
    this.selectedPackageId$.next(value);
  }

  get getSelectedPackageId() {
    return this.selectedPackageId$.asObservable();
  }
}

export declare type PackageForm = FormGroup<ControlsOf<ICreatePackageCommand>>;
export declare type ActionPriceForm = FormGroup<ControlsOf<PackagePriceItem>>;
