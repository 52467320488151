import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import { ICreateRecomendationsCommandBody } from '@shared/model/atlas.api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReccomendationFormService {

  constructor(
    private _fb: FormBuilder
  ) { }

  private reccomendationData: ReccDataTransfer;

  setReccData(data: ReccDataTransfer) {
    this.reccomendationData = data;
  }

  getReccData() {
    return this.reccomendationData
  }

  private _reccomendationForm = new BehaviorSubject<FormArray<FormGroup<ControlsOf<ICreateRecomendationsCommandBody>>>>(
    new FormArray<FormGroup<ControlsOf<ICreateRecomendationsCommandBody>>>([]),
  )

  reccomendationForm$ = this._reccomendationForm.asObservable()

  addEmptyCard() {
    this._reccomendationForm.value.push(this.initReccomendationItemForm())
    this._reccomendationForm.next(this._reccomendationForm.value)
  }

  addClientToRecc(client: string, idx: number) {
    this._reccomendationForm.value.controls
      .at(idx)
      .get('sourceId')
      .patchValue(client)
  }

  getReccomendations(): FormArray<FormGroup<ControlsOf<ICreateRecomendationsCommandBody>>> {
    return this._reccomendationForm.value;
  }

  clearReccomendations() {
    this._reccomendationForm.next(new FormArray<FormGroup<ControlsOf<ICreateRecomendationsCommandBody>>>([]))
  }

  clearByIdx(idx: number) {
    this._reccomendationForm.value.removeAt(idx);
    this._reccomendationForm.next(this._reccomendationForm.value);
  }

  initReccomendationItemForm(model?: ICreateRecomendationsCommandBody): FormGroup {
    return this._fb.group<ControlsOf<ICreateRecomendationsCommandBody>>({
      fullName: this._fb.control(model?.fullName, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      contact: this._fb.control(model?.contact, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(64)]
      }),
      city: this._fb.control(model?.city, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(64)]
      }),
      sourceId: this._fb.control(model?.sourceId, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      comment: this._fb.control(model?.comment, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(200)]
      })
    })
  } 


}

export declare type ReccomendationCard = FormGroup<ControlsOf<ICreateRecomendationsCommandBody>>

export interface ReccDataTransfer {
  name: string;
  lastName: string;
  phoneNum: string;
}