import { NgFor, NgForOf, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import {
  OrderForm,
  OrderFormService,
} from '@app/cc-leader/services/order-form.service';
import { BrandAutocompleteComponent } from '@app/features/autocompletes/brand-autocomplete/brand-autocomplete.component';
import { ModelAutocompleteComponent } from '@app/features/autocompletes/model-autocomplete/model-autocomplete.component';
import { BrandDropdownComponent } from '@app/features/dropdowns/brand-dropdown/brand-dropdown.component';
import { ModelDropdownComponent } from '@app/features/dropdowns/model-dropdown/model-dropdown.component';
import { PackageCardListComponent } from '@app/features/packages/package-card-list/package-card-list.component';
import { NewVehicleService } from '@app/features/services/new-vehicle-service';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { ScrollablePageComponent } from '@shared/components/scrollable-page/scrollable-page.component';
import { AddVehicleCommand, ClientClient, IVehicleBrandItem, UsersClient, VehiclesClient, VehicleTypeItem } from '@shared/model/atlas.api';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { VehicleTypeIconPipe } from '@shared/pipes/vehicle-type-icon.pipe';
import { LocationService } from '@shared/services/location.service';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { CheckboxModule } from 'primeng/checkbox';
import { SkeletonModule } from 'primeng/skeleton';
import { Subscription } from 'rxjs';
import { UserService } from '@shared/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '@shared/services/alert.service';

@Component({
  selector: 'atlas-add-vehicle-client',
  standalone: true,
  imports: [
    ScrollablePageComponent,
    IconCheckboxComponent,
    NgFor,
    NgForOf,
    NgIf,
    CheckboxModule,
    InputTextComponent,
    IconCheckboxComponent,
    BrandAutocompleteComponent,
    FormsModule,
    PackageCardListComponent,
    BrandAutocompleteComponent,
    ModelAutocompleteComponent,
    BrandDropdownComponent,
    ModelDropdownComponent,
    FormControlPipe,
    SkeletonModule,
    VehicleTypeIconPipe,
  ],
  templateUrl: './add-vehicle-client.component.html',
  styleUrl: './add-vehicle-client.component.scss',
})
export class AddVehicleClientComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  isKombiSelected: boolean;
  orderForm: OrderForm;
  selectedVehicleType: string | null;
  selectedBrandId: string | null;
  vehicleTypes: VehicleTypeItem[];
  clientId: string;
  vehicleName: string;

  constructor(
    private location: LocationService,
    private _vehiclesClient: VehiclesClient,
    private _orderFormService: OrderFormService,
    private _newVehicleService: NewVehicleService,
    private _userService: UserService,
    private _client: ClientClient,
    private route: ActivatedRoute,
    private _alertService: AlertService
  ) {
    this.clientId = this.route.snapshot.paramMap.get('id');
    this.loadVehicleTypes();
    this.orderForm = this._orderFormService.getOrderForm;
  }

  ngOnInit(): void {
    this._subs.add(
      this._vehiclesClient.getVehicleTypes().subscribe((res) => {
        this.vehicleTypes = res.result.responseList;

        const kombiVehicleType = this.vehicleTypes.find((type) =>
          type.name.includes('Putnička vozila'),
        );
        if (kombiVehicleType) {
          this.handleTypeChange(kombiVehicleType);
        }
      }),
    );
  }

  handleCancelForm() {
    this.location.routeToClientsTable(this._userService.getRoleRoutePrefix);
  }

  handleBrandChange(brandItem: IVehicleBrandItem) {
      this.selectedBrandId = brandItem.brandId;
      this.orderForm.controls.vehicle.controls.brand.patchValue(brandItem.name);
      this.orderForm.controls.vehicle.controls.model.patchValue(null);
    }

  handleTypeChange(vehicleTypeItem: VehicleTypeItem) {
    this.vehicleName = vehicleTypeItem.name;
    this._newVehicleService.setShowMotorModelInput(
      vehicleTypeItem.name.includes('Mot'),
    );
    this._newVehicleService.resetCtrlValues();

    const vehicleGroup = this.orderForm.controls.vehicle as FormGroup;
    vehicleGroup.reset({
      type: vehicleTypeItem.name,
      id: null,
      brand: null,
      model: null,
      licencePlate: null,
      productionYear: null,
      color: null,
    });

    this.isKombiSelected = vehicleTypeItem.name.includes('Kombi');
    this.selectedVehicleType = vehicleTypeItem.name;
    this.selectedBrandId = null;
  }

  private loadVehicleTypes() {
    this._subs.add(
      this._vehiclesClient.getVehicleTypes().subscribe((res) => {
        this.vehicleTypes = res.result.responseList;
      }),
    );
  }

  handleSubmitForm() {
    if (this.orderForm.invalid) {
      this.orderForm.markAllAsTouched(); 
      return;
    }
  
    const formValue = this.orderForm.controls.vehicle.value;
  
    const request = new AddVehicleCommand();
    request.clientId = this.clientId; 
    request.vehicleType = this.vehicleName; 
    request.vehicleBrand = formValue.brand;
    request.vehicleModel = formValue.model;
    request.productionYear = formValue.productionYear; 
    request.color = formValue.color; 
    request.licencePlate = formValue.licencePlate;
    request.chassisNumber = formValue.chassis;
    
  
    this._subs.add(
      this._client.addVehicle(request).subscribe((res) => {
        this._alertService.addSuccessMsg(res.result);
        this.location.routeToClientsTable(
          this._userService.getRoleRoutePrefix,
        );
      }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    this.orderForm.reset();
  }
}
