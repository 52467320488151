import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DividerModule } from 'primeng/divider';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import {
  ActivityTypeItem,
  CreatePartnerCommand,
  ICreatePartnerCommand,
  PartnersClient,
  PartnerType,
  UpdatePartnerCommand,
} from '@shared/model/atlas.api';
import { Subscription } from 'rxjs';
import { CheckboxModule } from 'primeng/checkbox';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { DialogService } from '@ui/dialog/dialog.service';
import { PartnerVehicleFormComponent } from '@app/administrator/partners/partner-vehicle-form/partner-vehicle-form.component';
import { AlertService } from '@shared/services/alert.service';
import { LocationService } from '@shared/services/location.service';
import { markFormGroupTouched } from '@shared/forms/forms-common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { PartnerVehicleCardComponent } from '@app/administrator/partners/partner-form/partner-vehicle-card/partner-vehicle-card.component';
import {
  CreatePartnerForm,
  PartnerFormService,
} from '@app/administrator/partners/services/partner-form.service';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import { ErrorRequiredComponent } from '@ui/errors/error-required/error-required.component';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { ScrollablePageComponent } from '@shared/components/scrollable-page/scrollable-page.component';
import {LoaderService} from "@shared/components/loader/loader.service";
import {SkeletonModule} from "primeng/skeleton";

export enum Mode {
  NEW = 1,
  EDIT = 2,
}

@Component({
  selector: 'atlas-partner-form',
  standalone: true,
  templateUrl: './partner-form.component.html',
  styleUrl: './partner-form.component.scss',
  imports: [
    ScrollPanelModule,
    InlineWrapperComponent,
    InputTextComponent,
    DividerModule,
    FormsModule,
    FormControlPipe,
    InputTextModule,
    ReactiveFormsModule,
    RadioButtonModule,
    NgFor,
    NgIf,
    CheckboxModule,
    ButtonComponent,
    PartnerVehicleCardComponent,
    OverlayPanelModule,
    NgClass,
    PartnerVehicleCardComponent,
    InputPhoneComponent,
    ErrorRequiredComponent,
    InputMailComponent,
    ScrollablePageComponent,
    SkeletonModule,
  ],
})
export class PartnerFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  form: CreatePartnerForm;
  partnerTypes: any[];
  currentPartnerId: string;
  submitBtnLabel: string = 'Dodaj saradnika';
  pageLabel: string = 'Dodavanje saradnika';
  mode: Mode;
  previousSegments: any[] = [];
  shouldResetId = true;

  activityTypes: ActivityTypeItem[];
  selectedActivityTypes: string[] = [];

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.LARGE);

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  constructor(
    public formService: PartnerFormService,
    private _partnerClient: PartnersClient,
    private _dialogService: DialogService,
    private _alertService: AlertService,
    private _locationService: LocationService,
    private readonly router: Router,
    private _confirmationService: ConfirmationService,
    private _loaderService: LoaderService,
    private route: ActivatedRoute,
  ) {
    this._loadActvityTypes();
    this._initCategories();
    if (this.router.getCurrentNavigation()) {
      let { primary: b } =
        this.router.getCurrentNavigation().previousNavigation.extractedUrl.root
          .children;
      this.previousSegments = b.segments;
    }
  }

  ngOnInit(): void {
    this._loadActvityTypes();
    this._initCategories();

    this.form = this.formService.getPartnerForm();

    this.route.paramMap.subscribe((params) => {
      const partnerId = params.get('id');

      if (partnerId) {
        this.currentPartnerId = partnerId;
        this.mode = Mode.EDIT;
        this.submitBtnLabel = 'Izmeni saradnika';
        this.pageLabel = 'Izmena saradnika';
        this._getPartnerById();
      } else {
        this.mode = Mode.NEW;
        this.submitBtnLabel = 'Dodaj saradnika';
        this.pageLabel = 'Dodavanje novog saradnika';
      }
    });
  }

  private _loadActvityTypes() {
    this._subs.add(
      this._partnerClient.getActivityTypes().subscribe((res) => {
        this.activityTypes = res.result.activityItems;
      }),
    );
  }

  private _getPartnerById() {
    this._subs.add(
      this._partnerClient
        .getPartnerById(this.currentPartnerId)
        .subscribe((res) => {
          // Dodavanje vozila
          // ToDo: move to service
          if (res.result.vehicles != null) {
            // const vehiclesFormArray = this._fb.array<
            //   FormGroup<ControlsOf<PartnerVehicleDto>>
            // >([]);
            // res.result.vehicles.forEach((vehicle) => {
            //   const vehicleFormGroup = this.formService.getVehicleForm(vehicle);
            //   vehicleFormGroup.patchValue(vehicle);
            //   vehiclesFormArray.push(vehicleFormGroup as any);
            // });
            //
            // this.form.setControl('vehicles', vehiclesFormArray);
          }

          //  Dodavanje usluga
          res.result.activityTypeIds.forEach((x) => {
            this.form.controls.activityTypeIds.push(new FormControl<string>(x));
            this.selectedActivityTypes.push(x);
          });

          //  Dodavanje vozila
          res.result.vehicles.forEach((x) => {
            this.formService.addVehicle(x);
          });

          this.form.patchValue(res.result as ICreatePartnerCommand);
        }),
    );
  }

  handleAddVehicle() {
    this.dialogConfig.header = 'Dodavanje vozila';
    this.dialogConfig.customSubmitButton = {
      label: 'Dodaj',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;

    this.dialogConfig.data = {
      fromInfoPage: false,
      partnerId: this.currentPartnerId,
    };

    this.openDialog(PartnerVehicleFormComponent);
  }

  openDialog(form: Type<PartnerVehicleFormComponent>): void {
    this._dialogService.open(form, this.dialogConfig);
  }

  handleSubmitForm() {
    console.log(this.form);
    if (this.form.invalid || this.form.value.vehicles.length === 0) {
      markFormGroupTouched(this.form);
      this._alertService.addWarnMsg('Morate uneti sva obavezna polja');
      return;
    }

    this._loaderService.setShowLoader = true;

    if (this.mode == Mode.NEW) {
      this._subs.add(
        this._partnerClient
          .create(this.form.value as CreatePartnerCommand)
          .subscribe((res) => {
            this._loaderService.reset();
            this._alertService.addSuccessMsg(res.result);
            this.routeToPartnerTable();
          }),
      );
    } else {
      this.form.controls.partnerId.patchValue(this.currentPartnerId);
      this._subs.add(
        this._partnerClient
          .updatePartner(this.form.value as UpdatePartnerCommand)
          .subscribe((res) => {
            this._loaderService.reset();
            this._alertService.addSuccessMsg(res.result);
            this.routeToPartnerTable();
          }),
      );
    }
  }

  handleCancelForm() {
    this._confirmationService.confirm({
      header: 'Odustani od kreiranja saradnika',
      message: 'Da li želite da odustanete od kreiranja saradnika?',
      acceptButtonStyleClass: ButtonSeverity.DANGER,
      acceptLabel: 'Odustani',
      rejectLabel: 'Ostani',
      icon: 'confirm-cancel.svg',
      accept: () => {
        this.shouldResetId = !this.previousSegments.some((segment) =>
          segment.path.includes('info'),
        );
        this._locationService.routeBack();
      },
      reject: () => {},
    });
  }

  handleCheckboxAdd(event: any) {
    this.form.controls.activityTypeIds.clear();
    this.form.controls.activityTypeIds.updateValueAndValidity();

    event.checked.forEach((x: string) => {
      this.form.controls.activityTypeIds.push(new FormControl<string>(x));
    });
  }

  private _initCategories() {
    this.partnerTypes = [
      { name: 'Aktivan saradnik', id: PartnerType.REGULAR },
      { name: 'Bosh servis', id: PartnerType.BOSCH_CAR },
      { name: 'Ovlašćeni servis', id: PartnerType.AUTH_SERVICE },
      { name: 'Rezervni saradnik', id: PartnerType.RESERVE_PARTNER },
    ];
  }

  private clearForm() {
    this.form = undefined;
    this.formService.reset();
  }

  private routeToPartnerTable() {
    this._locationService.routeToPartnerTable();
  }

  ngOnDestroy(): void {
    this.clearForm();
    this._subs.unsubscribe();
  }

  protected readonly Mode = Mode;
}
