<div class="flex surface-0 flex-shrink-1 flex-grow-1 border-round border-1 p-2">
  <div
    class="m-3 p-2 border-round-lg flex justify-content-center align-items-center card-icon"
  >
  <img *ngIf="cardImageSrc" [ngSrc]="cardImageSrc" alt="Card Icon" class="icon-img m-2" width="28" height="28" />
    <i *ngIf="!cardImageSrc" [ngClass]="'pi text-3xl m-2 icon ' + cardIcon"></i>
  </div>
  <div class="m-3">
    <div class="h-2rem font-medium card-desc">{{ cardTitle }}</div>
    <div
      [ngClass]="{
        'card-value-info-page': infoPage,
        'card-value-table text-2xl': !infoPage
      }"
    >
      {{ cardNumber }}
    </div>
  </div>
</div>
