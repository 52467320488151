<div class="h-13rem">
  <div class="text-sm-medium mb-2">Razlog</div>
  <textarea
    id="cancel-reason"
    rows="7"
    cols="30"
    pInputTextarea
    [(ngModel)]="inputValue"
    [ngClass]="
      showValidation && !inputValue ? 'w-full ng-invalid ng-dirty' : 'w-full'
    "
    placeholder="Unesite razlog..."
  ></textarea>

  <small *ngIf="showValidation && !inputValue" class="error-info text-red-500"
    >Obavezno polje</small
  >
</div>
