import {Component, Input} from '@angular/core';
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {IAgentBaseInfoResponse} from "@shared/model/atlas.api";
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'atlas-agent-list-images',
  standalone: true,
    imports: [
        NgForOf,
        NgOptimizedImage,
        NgIf,
        TooltipModule
    ],
  templateUrl: './agent-list-images.component.html',
  styleUrl: './agent-list-images.component.scss'
})
export class AgentListImagesComponent {
    @Input() agents: IAgentBaseInfoResponse[] = [];
}
