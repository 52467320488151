import { Routes } from '@angular/router';
import { AuthGuard } from '@shared/services/auth.guard';
import { CampaignTableComponent } from '@app/cc-agent/campaigns/campaign-table/campaign-table.component';
import { RenewPackageFormComponent } from '@app/features/orders/renew-package-form/renew-package-form.component';
import { ClientWithPackagesPageComponent } from '@app/features/orders/client-with-packages-page/client-with-packages-page.component';
import { CreateOrderFormComponent } from '@app/features/orders/create-order-form/create-order-form.component';
import { CreateOrderFormStep1Component } from '@app/features/orders/create-order-form/create-order-form-step-1/create-order-form-step-1.component';
import { CreateOrderFormStep2Component } from '@app/features/orders/create-order-form/create-order-form-step-2/create-order-form-step-2.component';
import { CreateOrderFormStep3Component } from '@app/features/orders/create-order-form/create-order-form-step-3/create-order-form-step-3.component';
import { ProcessLeadComponent } from './campaigns/process-lead/process-lead.component';
import { ScheduledCallsTableComponent } from './campaigns/scheduled-calls-table/scheduled-calls-table.component';
import { BuyPackageVehicleComponent } from '@app/features/orders/buy-package-vehicle/buy-package-vehicle.component';
import { AddVehicleClientComponent } from '@app/features/orders/add-vehicle-client/add-vehicle-client.component';
import { ClientInfoComponent } from '@app/features/orders/client-info/client-info.component';
import { EditClientComponent } from '@app/features/orders/client-info/edit-client/edit-client.component';
import { VehicleDetailsComponent } from '@app/features/orders/vehicle-details/vehicle-details.component';
import { RecommendationPageAgentComponent } from '@app/features/recommendations/recommendation-page-agent/recommendation-page-agent.component';

export const ccAgentRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'clients',
        pathMatch: 'full',
      },
      {
        path: 'clients',
        children: [
          {
            path: '',
            canActivate: [AuthGuard],
            component: ClientWithPackagesPageComponent,
          },
          {
            path: 'create',
            canActivate: [AuthGuard],
            component: CreateOrderFormComponent,
            children: [
              {
                path: 'step-1',
                canActivate: [AuthGuard],
                component: CreateOrderFormStep1Component,
              },
              {
                path: 'step-2',
                canActivate: [AuthGuard],
                component: CreateOrderFormStep2Component,
              },
              {
                path: 'step-3',
                canActivate: [AuthGuard],
                component: CreateOrderFormStep3Component,
              },
            ],
          },
          {
            path: 'renew-package',
            canActivate: [AuthGuard],
            component: RenewPackageFormComponent,
          },
          {
            path: 'renew-package/:id',
            canActivate: [AuthGuard],
            component: RenewPackageFormComponent,
          },
          {
            path: 'buy-package-vehicle/:id/:name/:clientId/:type',
            canActivate: [AuthGuard],
            component: BuyPackageVehicleComponent,
          },
          {
            path: 'buy-own-package/:clientId',
            canActivate: [AuthGuard],
            component: BuyPackageVehicleComponent,
          },
          {
            path: 'add-vehicle/:id',
            canActivate: [AuthGuard],
            component: AddVehicleClientComponent,
          },
          {
            path: 'update-vehicle/:id',
            canActivate: [AuthGuard],
            component: AddVehicleClientComponent,
          },
          {
            path: 'client-info/:id/:name',
            canActivate: [AuthGuard],
            component: ClientInfoComponent,
          },
          {
            path: 'edit-client/:id',
            canActivate: [AuthGuard],
            component: EditClientComponent,
          },
          {
            path: 'vehicle-details/:id/:vehicleId',
            canActivate: [AuthGuard],
            component: VehicleDetailsComponent,
          },
        ],
      },
      {
        path: 'campaigns',
        children: [
          {
            path: '',
            component: CampaignTableComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'process-lead/:id',
            component: ProcessLeadComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'scheduled-calls',
            component: ScheduledCallsTableComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'process-recommendation/:id',
            component: ProcessLeadComponent,
            canActivate: [AuthGuard],
            data: {
              isRecommendation: true,
            },
          },
        ],
      },
      {
        path: 'recommendations',
        canActivate: [AuthGuard],
        component: RecommendationPageAgentComponent,
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'clients',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'clients',
  },
];
