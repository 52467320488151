import {Component, OnDestroy, OnInit} from '@angular/core';
import {PageComponent} from '@shared/components/page/page.component';
import {
  ClientStatisticsResponse,
  GetGlobalDataForExportQuery,
  GetGlobalPackagesReportQuery, GetGlobalPackagesStatisticsQuery,
  GlobalPackagesReportFilter,
  GlobalPackagesReportResponse, PackageReportStatsResponse,
  IPaginationResponseOfGlobalPackagesReportResponse,
  ReportsClient,
} from '@shared/model/atlas.api';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import {SelectMode, TableConfig} from '@ui/table/table-common';
import {TableServiceLocal} from '@ui/table/table-service-local.service';
import {TableComponent} from '@ui/table/table.component';
import {TableLazyLoadEvent} from 'primeng/table';
import {firstValueFrom, Subscription} from 'rxjs';
import {CalendarComponent} from '@ui/calendar/calendar.component';
import {
  SourcesMultiselectComponent
} from '@app/features/multiselects/sources-multiselect/sources-multiselect.component';
import {FormArray, FormControl} from '@angular/forms';
import {LoaderService} from '@shared/components/loader/loader.service';
import {convertToCsv} from '@shared/functions/functions';
import {CurrencyNumberPipe} from "@shared/pipes/currency-number.pipe";
import {PageInfoCardComponent} from "@ui/page-info-card/page-info-card.component";

@Component({
  selector: 'atlas-overall-report-table',
  standalone: true,
  imports: [
    TableComponent,
    PageComponent,
    ButtonComponent,
    CalendarComponent,
    SourcesMultiselectComponent,
    CurrencyNumberPipe,
    PageInfoCardComponent,
  ],
  templateUrl: './overall-report-table.component.html',
  styleUrl: './overall-report-table.component.scss',
})
export class OverallReportTableComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  tableConfig: TableConfig;
  paginatedData: IPaginationResponseOfGlobalPackagesReportResponse;
  activeIndex: number | undefined;
  exportData: GlobalPackagesReportResponse[] = [];
  globalPackagesStats: PackageReportStatsResponse | undefined;

  createdSinceCtrl: FormControl = new FormControl(null);
  createdToCtrl: FormControl = new FormControl(null);
  selectedSorcesCtrl: FormArray = new FormArray([]);

  constructor(
    private _tableService: TableServiceLocal,
    private _client: ReportsClient,
    private _loaderService: LoaderService,
  ) {
    this.tableConfig = new TableConfig({
      columns: [],
    });

    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
  }

  ngOnInit(): void {
    this.setTableConfig();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez trenutnog izveštaja',
        description: 'Ovdeće biti prikazana tabela trenutnog izveštaja',
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.load();
      },
      selectMode: SelectMode.MULTI,
      columns: [
        {
          header: 'Naziv paketa',
          field: 'packageName',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
        },
        {
          header: 'Ukupno prodato',
          field: 'packageCount',
          type: 'number',
        },
        {
          header: 'Ukupan iznos',
          field: 'amountSum',
          type: 'currency',
          styleClass: 'text-black-alpha-90 font-semibold',
        },
        {
          header: 'Izvor',
          field: 'source',
          type: 'text',
        },
      ],
    });
  }

  load() {
    this._tableService.setIsLoading();
    this._getStatistics().then((res) => {
      if (res.status === 200) {
        this.globalPackagesStats = res.result;
      }
    });
    this._getPackages().then((res) => {
      this.paginatedData = res.result;
      this._tableService.resetIsLoading();
    });
  }

  private async _getPackages() {
    return await firstValueFrom(
      this._client.getGlobalPackagesReport(
        new GetGlobalPackagesReportQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize,
          filter: new GlobalPackagesReportFilter({
            createdFrom: this.createdSinceCtrl.value,
            cratedTo: this.createdToCtrl.value,
            sources: this.selectedSorcesCtrl.value,
          }),
        }),
      ),
    );
  }

  private async _getStatistics() {
    return await firstValueFrom(
      this._client.getGlobalPackagesStatistics(
        new GetGlobalPackagesStatisticsQuery({
          filter: new GlobalPackagesReportFilter({
            createdFrom: this.createdSinceCtrl.value,
            cratedTo: this.createdToCtrl.value,
            sources: this.selectedSorcesCtrl.value,
          }),
        }),
      ),
    );
  }

  handleExport() {
    this._loaderService.setLoadingText = 'Preuzimanje podataka...';
    this._loaderService.setShowLoader = true;
    this._loadExportData().then(() => {
      this._loaderService.reset();

      const customData = this.exportData.map((item) => {
        return {
          packageName: item.packageName,
          packageCount: item.packageCount,
          amountSum: item.amountSum,
          source: item.source,
        };
      });

      const csvContent = convertToCsv(
        customData,
        'Naziv paketa,Ukupno prodato,Ukupan iznos,Izvor',
      );

      const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = 'Izveštaj prodaje.csv';
      link.click();
    });
  }

  private _loadExportData(): Promise<void> {
    return new Promise((resolve) => {
      this._subs.add(
        this._client
          .getGlobalReportExportData(
            new GetGlobalDataForExportQuery({
              filter: new GlobalPackagesReportFilter({
                createdFrom: this.createdSinceCtrl.value,
                cratedTo: this.createdToCtrl.value,
                sources: this.selectedSorcesCtrl.value,
              }),
            }),
          )
          .subscribe((res) => {
            this.exportData = res.result;
            resolve();
          }),
      );
    });
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;
}
