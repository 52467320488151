import { Component, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  GetGlobalPackagesReportQuery, GetGlobalPackagesStatisticsQuery,
  GetSalaryReportQuery, GlobalPackagesReportFilter,
  IPaginationResponseOfSalaryReportResponse,
  ReportsClient,
  SalaryReportFilter,
} from '@shared/model/atlas.api';
import { SelectMode, TableConfig } from '@ui/table/table-common';
import { TableLazyLoadEvent } from 'primeng/table/table.interface';
import { PageComponent } from '@shared/components/page/page.component';
import { TableComponent } from '@ui/table/table.component';
import { SalaryReportService } from '@app/administrator/reports/salary-report/salary-report-service';
import { AgentMultiselectComponent } from '@app/features/multiselects/agent-multiselect/agent-multiselect.component';
import { MediumMultiselectComponent } from '@app/features/multiselects/medium-multiselect/medium-multiselect.component';
import { SourcesMultiselectComponent } from '@app/features/multiselects/sources-multiselect/sources-multiselect.component';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { JsonPipe } from '@angular/common';
import { CalendarComponent } from '@ui/calendar/calendar.component';
import { TableServiceLocal } from '@ui/table/table-service-local.service';
import {CurrencyNumberPipe} from "@shared/pipes/currency-number.pipe";
import {PageInfoCardComponent} from "@ui/page-info-card/page-info-card.component";

@Component({
  selector: 'atlas-salary-report',
  standalone: true,
  imports: [
    PageComponent,
    TableComponent,
    AgentMultiselectComponent,
    MediumMultiselectComponent,
    SourcesMultiselectComponent,
    ButtonComponent,
    JsonPipe,
    CalendarComponent,
    CurrencyNumberPipe,
    PageInfoCardComponent,
  ],
  templateUrl: './salary-report.component.html',
  styleUrl: './salary-report.component.scss',
})
export class SalaryReportComponent implements OnInit, OnDestroy {
  private _subs: Subscription;

  public paginatedData: IPaginationResponseOfSalaryReportResponse;
  public tableConfig: TableConfig;
  showLoader = true;
  amountSum: number | undefined;

  constructor(
    private _client: ReportsClient,
    protected fs: SalaryReportService,
    private tableService: TableServiceLocal,
  ) {
    this.tableConfig = {
      selectMode: SelectMode.SINGLE,
      showHeader: true,
      columns: [],
      rowsPerPage: 10,
    };

    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
    this._subs = new Subscription();
  }

  ngOnInit() {
    this.setTableConfig().then();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez podataka',
        description: 'Ovde će biti prikazana tabela izveštaja zarade',
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.load();
      },
      columns: [
        {
          header: 'Agent',
          field: 'agent',
          subField: 'name',
          type: 'text',
        },
        {
          header: 'Mediji',
          field: 'mediums',
          type: 'text',
          tooltipField: 'mediums',
          styleClass: 'w-15rem',
        },
        {
          header: 'Izvori',
          field: 'sources',
          type: 'text',
          tooltipField: 'sources',
          styleClass: 'w-15rem',
        },
        {
          header: 'Zarada',
          field: 'amount',
          type: 'currency',
        },
      ],
    });
  }

  load() {
    this.tableService.setIsLoading();
    this._getStatistics().then((res) => {
      if (res.status === 200) {
        this.amountSum = res.result;
      }
    });
    this._fetchAll().then((res) => {
      this.paginatedData = res.result;
      this.tableService.resetIsLoading();
    });
  }

  private async _fetchAll() {
    return await firstValueFrom(
      this._client.getSalaryReport(
        new GetSalaryReportQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize,
          filter: new SalaryReportFilter(this.fs.getForm().value),
        }),
      ),
    );
  }

  private async _getStatistics() {
    return await firstValueFrom(
      this._client.getSalaryStatistics(
        new GetGlobalPackagesStatisticsQuery({
          filter: new GetSalaryReportQuery({
            pageNumber: this.paginatedData.currentPage,
            pageSize: this.paginatedData.pageSize,
            filter: new SalaryReportFilter(this.fs.getForm().value),
          }),
        }),
      ),
    );
  }

  ngOnDestroy() {
    this.fs.reset();
    this._subs.unsubscribe();
  }

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  resetFilters() {
    this.fs.resetFilters();
    this.load();
  }
}
