import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  Input,
  IterableDiffers,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ActionMode,
  IPaginatedResultTableDto,
  TableAction,
  TableConfig,
} from './table-common';
import { TableRowDataComponent } from './table-row-data/table-row-data.component';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgStyle,
} from '@angular/common';
import { TableModule } from 'primeng/table';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { SkeletonModule } from 'primeng/skeleton';
import { Subscription } from 'rxjs';
import { TableServiceLocal } from '@ui/table/table-service-local.service';
import {TableLazyLoadEvent} from "primeng/table/table.interface";

@Component({
  selector: 'atlas-table',
  standalone: true,
  imports: [
    TableRowDataComponent,
    NgForOf,
    TableModule,
    NgStyle,
    NgClass,
    NgIf,
    ButtonComponent,
    OverlayPanelModule,
    ButtonModule,
    NgOptimizedImage,
    TooltipModule,
    SkeletonModule,
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnChanges, DoCheck, OnDestroy {
  private _subs: Subscription;
  totalCount: number;
  selectedItem: IPaginatedResultTableDto | any;
  rowActions: TableAction[];
  singleActions: TableAction[];
  multiActions: TableAction[];
  actionRowIndex: number = -1;
  isLoading: boolean = true;
  first = 0;
  iterableDiffer: any;
  skeletonItems: any[] = [];

  @Input() public tableConfig: TableConfig;
  @Input() public paginatedData: IPaginatedResultTableDto[] | any;
  @Input() public pagination: boolean = true;
  @Input() public items: any[];
  @Input() public resetPagination: boolean | undefined;

  @ViewChild('rowActionPanel') rowActionPanel!: OverlayPanel;

  constructor(
    private _tableService: TableServiceLocal,
    private iterableDiffers: IterableDiffers,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.rowActions = [];
    this.singleActions = [];
    this.multiActions = [];
    this.tableConfig = new TableConfig({
      columns: [],
      emptyTableInfo: {
        header: 'Nema podataka',
        description: 'Ovde će biti prikazana tabela',
      },
    });
    this.totalCount = 0;
    this.paginatedData = {
      data: [],
    };
    this.iterableDiffer = iterableDiffers.find([]).create(null);
    this.items = [];
    this.skeletonItems = Array.from({ length: 9 }).map((_, i) => i);
    this._subs = new Subscription();
  }

  ngOnInit() {
    this._subs.add(
      this._tableService.getIsLoading().subscribe((isLoading) => {
        this.isLoading = isLoading;
      }),
    );
    this.handleLazy({
      first: 0,
      rows: 10,
    });
    this.tableConfig.rowActions?.forEach((action) => {
      this.rowActions.push(action);
      this.singleActions = this.rowActions.filter(
        (x) =>
          x.mode === ActionMode.SINGLE &&
          (x.isVisible == undefined || x.isVisible),
      );
      this.multiActions = this.rowActions.filter(
        (x) =>
          x.mode === ActionMode.MULTI &&
          (x.isVisible == undefined || x.isVisible),
      );
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //this.loading = true;
    if (
      changes['resetPagination'] &&
      !changes['resetPagination'].isFirstChange() &&
      changes['resetPagination'].currentValue !=
        changes['resetPagination'].previousValue
    ) {
      this.first = 0;
    }
    //this._handleItemsUpdated();
  }

  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.paginatedData.data);
    if (changes) {
      //this.loading = true;
      this._handleItemsUpdated();
    }
  }

  private _handleItemsUpdated() {
    if (!this.paginatedData) {
      return;
    }
    this.items = [...(this.paginatedData.data ?? [])];
    this.totalCount = this.paginatedData.totalCount ?? 0;
    //this.loading = false;
    //this.changeDetectorRef.detectChanges();
  }

  rowPopup(event: any, rowIndex: number) {
    this.actionRowIndex = rowIndex;
    this.rowActionPanel.toggle(event);
  }

  handleRowAction(action: TableAction) {
    action.callback(this.actionRowIndex);
    this.rowActionPanel.hide();
  }

  rowContainsActions(rowIdx: number) {
    return this.rowActions.some(
      (r) => r.shouldDisplayByCondition && r.shouldDisplayByCondition(rowIdx),
    );
  }

  rowContainsMultiActions(rowIdx: number) {
    return this.multiActions.some(
      (x) => x.shouldDisplayByCondition && x.shouldDisplayByCondition(rowIdx),
    );
  }

  protected readonly ButtonStyle = ButtonStyle;

  handleLazy($event: TableLazyLoadEvent) {
    //this.skeletonItems = Array.from({ length: this.items.length ?? 9 }).map((_, i) => i);
    this.tableConfig.lazyCallbackFunction($event);
  }

  protected readonly ButtonSeverity = ButtonSeverity;

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  protected readonly location = location;

  handleCreate() {
    this.tableConfig.emptyTableInfo.action();
  }

  handleHover(action: TableAction, rowIndex: any) {
    return action.onHover
      ? action.onHover(rowIndex) : "";
  }
}
