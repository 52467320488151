import { NgFor, NgForOf, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { BrandAutocompleteComponent } from '@app/features/autocompletes/brand-autocomplete/brand-autocomplete.component';
import { ModelAutocompleteComponent } from '@app/features/autocompletes/model-autocomplete/model-autocomplete.component';
import { BrandDropdownComponent } from '@app/features/dropdowns/brand-dropdown/brand-dropdown.component';
import { ModelDropdownComponent } from '@app/features/dropdowns/model-dropdown/model-dropdown.component';
import { NewVehicleService } from '@app/features/services/new-vehicle-service';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { ScrollablePageComponent } from '@shared/components/scrollable-page/scrollable-page.component';
import {
  AddVehicleCommand,
  ClientClient,
  IClientWithPackagesItem,
  IVehicleBrandItem,
  UpdateClientVehicleCommand,
  UpdateVehicleCommand,
  VehiclesClient,
  VehicleTypeItem,
} from '@shared/model/atlas.api';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { VehicleTypeIconPipe } from '@shared/pipes/vehicle-type-icon.pipe';
import { LocationService } from '@shared/services/location.service';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { CheckboxModule } from 'primeng/checkbox';
import { SkeletonModule } from 'primeng/skeleton';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  AgentCcRoleStr,
  B2BAgentRoleStr,
  LeaderCcRoleStr,
  UserService,
} from '@shared/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '@shared/services/alert.service';
import {
  ClientProfileService,
  ClientVehicleForm,
} from '@app/cc-leader/services/client-profile.service';
import { PackageCardListService } from '@app/cc-leader/services/package-card-list.service';
import { LicencePlateRegExp } from '@shared/extensions-common';

export enum MODE {
  NEW = 1,
  EDIT = 2,
}

@Component({
  selector: 'atlas-add-vehicle-client',
  standalone: true,
  imports: [
    ScrollablePageComponent,
    IconCheckboxComponent,
    NgFor,
    NgForOf,
    NgIf,
    CheckboxModule,
    InputTextComponent,
    IconCheckboxComponent,
    BrandAutocompleteComponent,
    FormsModule,
    BrandAutocompleteComponent,
    ModelAutocompleteComponent,
    BrandDropdownComponent,
    ModelDropdownComponent,
    FormControlPipe,
    SkeletonModule,
    VehicleTypeIconPipe,
  ],
  templateUrl: './add-vehicle-client.component.html',
  styleUrl: './add-vehicle-client.component.scss',
})
export class AddVehicleClientComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  isKombiSelected: boolean;
  clientVehicleForm: ClientVehicleForm;
  selectedVehicleType: string | null;
  selectedBrandId: string | null;
  vehicleTypes: VehicleTypeItem[];
  clientId: string;
  vehicleName: string;
  mode: MODE;

  pageTitle: string = 'Dodaj vozilo';
  pageSubmitBtnText: string = 'Sačuvaj vozilo';

  updateCommand: UpdateVehicleCommand;

  constructor(
    private location: LocationService,
    private _vehiclesClient: VehiclesClient,
    private _newVehicleService: NewVehicleService,
    private _userService: UserService,
    private _client: ClientClient,
    private route: ActivatedRoute,
    private _alertService: AlertService,
    private _clientProfileService: ClientProfileService,
    private _packageCardSerice: PackageCardListService,
  ) {
    this.clientId = this.route.snapshot.paramMap.get('id');
    this.loadVehicleTypes();
    this.clientVehicleForm = this._clientProfileService.getClientVehicleForm();
  }

  async ngOnInit() {
    await this.getVehicleTypes();

    if (this.route.snapshot.url[0].path === 'update-vehicle') {
      this.mode = MODE.EDIT;
      this.pageTitle = 'Izmeni vozilo';
      this.pageSubmitBtnText = 'Sačuvaj izmene';
      this._selectedVehicleData();
    } else {
      this.mode = MODE.NEW;
    }
  }

  private _selectedVehicleData() {
    this._subs.add(
      this._clientProfileService.getVehicleQueryResponse().subscribe((res) => {
        this.clientVehicleForm.controls.vehicleId.patchValue(res?.id);
        this.clientVehicleForm.controls.vehicleType.patchValue(res?.type);
        this.clientVehicleForm.controls.vehicleBrand.patchValue(res?.brand);
        this.clientVehicleForm.controls.vehicleModel.patchValue(res?.model);
        this.clientVehicleForm.controls.licencePlate.patchValue(res?.plates);
        this.clientVehicleForm.controls.chassisNumber.patchValue(res?.chassis);
        this.clientVehicleForm.controls.productionYear.patchValue(res?.year);
        this.clientVehicleForm.controls.color.patchValue(res?.color);
        this.selectedVehicleType = res?.type;
      }),
    );
  }

  async getVehicleTypes() {
    await this._loadTypes().then((res) => {
      this.vehicleTypes = res.result.responseList;

      const putnickoVoziloType = this.vehicleTypes.find((type) =>
        type.name.includes('Putnička vozila'),
      );
      if (putnickoVoziloType) {
        this.handleTypeChange(putnickoVoziloType);
      }
    });
  }

  private async _loadTypes() {
    return await firstValueFrom(this._vehiclesClient.getVehicleTypes());
  }

  handleCancelForm() {
    this.location.routeBack();
  }

  handleBrandChange(brandItem: IVehicleBrandItem) {
    this.selectedBrandId = brandItem.brandId;
    this.clientVehicleForm.controls.vehicleBrand.patchValue(brandItem.name);
    this.clientVehicleForm.controls.vehicleModel.patchValue(null);
  }

  handleTypeChange(vehicleTypeItem: VehicleTypeItem) {
    this.vehicleName = vehicleTypeItem.name;
    this._newVehicleService.setShowMotorModelInput(
      vehicleTypeItem.name.includes('Mot'),
    );
    this._newVehicleService.resetCtrlValues();

    const vehicleGroup = this.clientVehicleForm as FormGroup;
    vehicleGroup.reset({
      type: vehicleTypeItem.name,
      id: null,
      brand: null,
      model: null,
      licencePlate: null,
      productionYear: null,
      color: null,
    });

    this.isKombiSelected = vehicleTypeItem.name.includes('Kombi');
    this.selectedVehicleType = vehicleTypeItem.name;
    this.clientVehicleForm.controls.vehicleType.patchValue(
      this.selectedVehicleType,
    );
    this.selectedBrandId = null;
  }

  private loadVehicleTypes() {
    this._subs.add(
      this._vehiclesClient.getVehicleTypes().subscribe((res) => {
        this.vehicleTypes = res.result.responseList;
      }),
    );
  }

  handleModelChanged(model: string) {
    this.clientVehicleForm.controls.vehicleModel.patchValue(model);
  }

  handleSubmitForm() {
    this.clientVehicleForm.controls.clientId.patchValue(this.clientId);

    if (this.clientVehicleForm.invalid) {
      this.clientVehicleForm.markAllAsTouched();
      return;
    }

    if (this.mode === MODE.NEW) {
      this._subs.add(
        this._client
          .addVehicle(this.clientVehicleForm.value as AddVehicleCommand)
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.result.message);
            this.location.routeBack();
          }),
      );
    } else {
      this._subs.add(
        this._client
          .updateClientVehicle(
            this.clientVehicleForm.value as UpdateClientVehicleCommand,
          )
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.result.message);
            this.location.routeToVehicleInfo(
              this.clientId,
              res.result.value,
              this._userService.getRoleRoutePrefix(
                AgentCcRoleStr,
                B2BAgentRoleStr,
                LeaderCcRoleStr,
              ),
            );
          }),
      );
    }
  }

  async getVehicle(vehicleId: string) {
    return firstValueFrom(this._vehiclesClient.getVehicle(vehicleId));
  }

  async handleExtraBtnClick() {
    this.clientVehicleForm.controls.clientId.patchValue(this.clientId);

    if (this.clientVehicleForm.invalid) {
      this.clientVehicleForm.markAllAsTouched();
      return;
    }

    let vehicleResponse;
    if (this.mode === MODE.NEW) {
      vehicleResponse = await firstValueFrom(
        this._client.addVehicle(
          this.clientVehicleForm.value as AddVehicleCommand,
        ),
      );
      this._alertService.addSuccessMsg(vehicleResponse.result.message);
      const newVehicle = vehicleResponse.result.value;
      await this.buyPackageForVehicle(newVehicle);
    } else {
      vehicleResponse = await firstValueFrom(
        this._client.updateClientVehicle(
          this.clientVehicleForm.value as unknown as UpdateClientVehicleCommand,
        ),
      );
      await this.buyPackageForVehicle(this.clientVehicleForm.value.vehicleId);
    }
  }

  async buyPackageForVehicle(newVehicle: any) {
    const vehicleForRoute = await this.getVehicle(newVehicle);
    await this._setVehicleType(vehicleForRoute.result.type);
    this.location.routeToBuyPackageForVehicle(
      this._userService.getRoleRoutePrefix(
        AgentCcRoleStr,
        B2BAgentRoleStr,
        LeaderCcRoleStr,
      ),
      this.clientId,
      newVehicle,
      vehicleForRoute.result.type,
      vehicleForRoute.result.vehicle,
    );
  }

  private async _setVehicleType(typeStr: string) {
    await this._loadTypes().then((res) => {
      this.vehicleTypes = res.result.responseList;
    });

    let selectedType = this.vehicleTypes.find((type) => type.name === typeStr);
    if (selectedType) {
      this._packageCardSerice.setVehicleTypeId(selectedType.typeId);
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    this.clientVehicleForm.reset();
  }

  protected readonly LicencePlateRegExp = LicencePlateRegExp;
}
