import { Component, EventEmitter, Input, input, Output } from '@angular/core';
import {NgClass, NgIf, NgOptimizedImage} from '@angular/common';
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'atlas-page-info-card',
  standalone: true,
  imports: [NgClass,
    NgIf, NgOptimizedImage, TooltipModule
  ],
  templateUrl: './page-info-card.component.html',
  styleUrl: './page-info-card.component.scss',
})
export class PageInfoCardComponent {
  @Input() cardIcon: string | undefined;
  @Input() cardTitle: string | undefined;
  @Input() cardNumber: string | undefined;
  @Input() infoPage: boolean | undefined;
  @Input() cardImageSrc: string | undefined;
  @Input() showExport: boolean = false;

  @Output() exportClicked = new EventEmitter<void>();


  handleCSVExport() {
    this.exportClicked.emit();
  }


}
