import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  GetChangesRequestTableQuery,
  IVehicleRequestTableItem,
  VehiclesClient,
} from '@shared/model/atlas.api';
import { TableConfig } from '@ui/table/table-common';
import { firstValueFrom, Subscription } from 'rxjs';
import { NgFor } from '@angular/common';
import { ChangeRequestCardComponent } from '@app/administrator/vehicles/vehicle-change-request-table/change-request-card/change-request-card.component';
import { DataViewModule } from 'primeng/dataview';

@Component({
  selector: 'atlas-vehicle-change-request-table',
  standalone: true,
  imports: [NgFor, ChangeRequestCardComponent, DataViewModule],
  templateUrl: './vehicle-change-request-table.component.html',
  styleUrl: './vehicle-change-request-table.component.scss',
})
export class VehicleChangeRequestTableComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  layout: 'list' | 'grid' = 'grid';
  tableConfig: TableConfig;
  requestData: IVehicleRequestTableItem[];

  constructor(private _vehicleClient: VehiclesClient) {}

  ngOnInit(): void {
    this._loadTableData();
  }

  _loadTableData() {
    this._getChangeRequests().then((res) => {
      this.requestData = res.result.data;
    });
  }

  private async _getChangeRequests() {
    return await firstValueFrom(
      this._vehicleClient.getAllChangeRequests(
        new GetChangesRequestTableQuery(),
      ),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
