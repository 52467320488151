<atlas-page
  [pageTitle]="pageTitle"
  [pageDescription]="pageDescription"
  btnSvgUrl="/assets/images/buttons/add-client.svg"
  actionLabel="Dodaj klijenta"
  (onHandleAction)="handleCreateClient()"
>
  <!--  Overview cards-->
  <div class="flex flex-column md:flex-row gap-3 mb-3">
    <atlas-page-info-card
      cardTitle="Broj paketa"
      cardIcon="pi-box"
      [cardNumber]="clientPackagesStats ? clientPackagesStats.totalPackages.toString() : ''"
      class="flex-1"
    ></atlas-page-info-card>
    <atlas-page-info-card
      cardTitle="Ukupan iznos paketa"
      [cardNumber]="clientPackagesStats ? (clientPackagesStats.totalPackagesAmount.toString() | currencyNumber) : ''"
      cardIcon="pi-money-bill"
      class="flex-1"
    ></atlas-page-info-card>
  </div>

  <atlas-client-with-packages-table
    [canMultiselect]="true"
  ></atlas-client-with-packages-table>

</atlas-page>
