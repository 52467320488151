import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageComponent } from '@shared/components/page/page.component';
import { TabViewModule } from 'primeng/tabview';
import { TableComponent } from '@ui/table/table.component';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  DeleteMediumCommand,
  GetMediumsQuery,
  IMediumItem,
  IPaginationResponseOfMediumItem,
  MediumsClient,
} from '@shared/model/atlas.api';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { DialogService } from '@ui/dialog/dialog.service';
import { ConfirmationService } from 'primeng/api';
import { AlertService } from '@shared/services/alert.service';
import { ActionMode, SelectMode, TableConfig } from '@ui/table/table-common';
import { TableLazyLoadEvent } from 'primeng/table/table.interface';
import { MediumFormComponent } from '@app/administrator/mediums/medium-form/medium-form.component';

@Component({
  selector: 'atlas-medium-table',
  standalone: true,
  imports: [PageComponent, TabViewModule, TableComponent],
  templateUrl: './medium-table.component.html',
  styleUrl: './medium-table.component.scss',
})
export class MediumTableComponent implements OnInit, OnDestroy {
  private _subs: Subscription;
  private readonly _dialogConfig: DialogConfig;

  public paginatedData: IPaginationResponseOfMediumItem;
  public tableConfig: TableConfig;

  constructor(
    private _client: MediumsClient,
    private _dialogService: DialogService,
    private _confirmationService: ConfirmationService,
    private _alertService: AlertService,
  ) {
    this.tableConfig = {
      selectMode: SelectMode.SINGLE,
      showHeader: true,
      columns: [],
      rowsPerPage: 10,
    };

    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
    this._subs = new Subscription();
    this._dialogConfig = new DialogConfig(DialogSize.SMALL_MEDIUM);
    this._dialogConfig.closable = true;
    this._dialogConfig.customSubmitButton = {
      label: 'Sačuvaj',
      icon: 'pi pi-check',
    };
    this._dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: 'pi pi-times',
    };
  }

  ngOnInit() {
    this.setTableConfig().then();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez medija',
        description: 'Ovde će biti prikazana tabela medija',
        btnLabel: 'Dodaj zaposlenog',
        action: () => this.showCreateForm(),
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this._load();
      },
      columns: [
        {
          header: 'Naziv',
          field: 'name',
          type: 'text',
        },
        {
          header: 'Procenat od prodaje',
          field: 'feePercentStr',
          type: 'text',
        },
        {
          header: 'Sistemski',
          field: 'isSystemDefault',
          type: 'text',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          label: 'Izmeni',
          icon: 'pi pi-pencil',
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this.showEditForm(rowData);
          },
          shouldDisplayByCondition: () => true,
        },
        {
          mode: ActionMode.SINGLE,
          label: 'Obriši',
          icon: 'pi pi-trash',
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this._confirmationService.confirm({
              message: 'Da li ste sigurni da želite da obrišete medij?',
              acceptLabel: 'Da',
              rejectLabel: 'Ne',
              header: 'Potvrdite',
              rejectButtonStyleClass: 'p-button-outlined',
              accept: () => {
                this._subs.add(
                  this._client
                    .deleteMedium(
                      new DeleteMediumCommand({
                        mediumId: rowData.id,
                      }),
                    )
                    .subscribe((res) => {
                      this._alertService.addSuccessMsg(res.result);
                      this._load();
                    }),
                );
              },
            });
          },
          shouldDisplayByCondition: () => true,
        },
      ],
    });
  }

  showCreateForm() {
    this._dialogConfig.header = 'Unos medija';

    const ref = this._dialogService.open(
      MediumFormComponent,
      this._dialogConfig,
    );

    this._subs.add(
      ref.onClose.subscribe((res) => {
        if (res) {
          this._load();
        }
      }),
    );
  }

  showEditForm(item: IMediumItem) {
    this._dialogConfig.header = 'Izmena medija';
    this._dialogConfig.data = item;
    const ref = this._dialogService.open(
      MediumFormComponent,
      this._dialogConfig,
    );
    this._subs.add(
      ref.onClose.subscribe((res) => {
        if (res) {
          this._load();
        }
      }),
    );
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  private _load() {
    this._fetchAll().then((res) => {
      this.paginatedData = res.result;
    });
  }

  private async _fetchAll() {
    return await firstValueFrom(
      this._client.getMediums(
        new GetMediumsQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize,
        }),
      ),
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
