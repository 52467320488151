<atlas-scrollable-page
  [pageTitle]="vehicle?.vehicle"
  [showToggle]="true"
  [showBadge]="true"
  [badgeText]="vehicle?.type"
  (detailsVisibleChange)="updateDetailsVisibility($event)"
  (onSubmitAction)="routeToEditVehicle()"
  (onCancelAction)="routeBack()"
  cancelBtnIcon="back"
  submitBtnLabel="Izmeni"
  submitBtnIcon="edit"
>
  <ng-template #content>
    <div *ngIf="vehicle">
      <div *ngIf="isDetailsVisible">
        <div class="page-subtitle mt-5 mb-2">Detalji vozila</div>
        <div class="details-container">
          <div class="details-item">
            <img ngSrc="assets/images/vehicle-plate.svg" width="40" height="40" alt="plates-img" />
            <div>
              <span class="label">Tablice</span>
              <span class="value">{{ vehicle.plates || "N/A" }}</span>
            </div>
          </div>
          <div class="details-item">
            <img ngSrc="assets/images/chassis-icon.svg"  width="40" height="40" alt="Chassis" />
            <div>
              <span class="label">Broj šasije</span>
              <span class="value">{{ vehicle.chassis || "N/A" }}</span>
            </div>
          </div>
          <div class="details-item">
            <img ngSrc="assets/images/calendar.svg"  width="40" height="40" alt="Year" />
            <div>
              <span class="label">Godište</span>
              <span class="value">{{ vehicle.year || "N/A" }}</span>
            </div>
          </div>
          <div class="details-item">
            <img ngSrc="assets/images/template-icon.svg"  width="40" height="40" alt="Color" />
            <div>
              <span class="label">Boja</span>
              <span class="value">{{ vehicle.color || "N/A" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <atlas-vehicle-packages-list></atlas-vehicle-packages-list>
      </div>
    </div>
  </ng-template>
</atlas-scrollable-page>
