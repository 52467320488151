import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageComponent } from '@shared/components/page/page.component';
import { TabViewModule } from 'primeng/tabview';
import { TableComponent } from '@ui/table/table.component';
import {
  ActionMode,
  OnTableInit,
  SelectMode,
  TableConfig,
} from '@ui/table/table-common';
import {
  AssistancesClient,
  AssistanceStatus,
  GetAssistanceTableQuery,
  IAssistanceTableResponse,
  IPaginationResponseOfAssistanceTableResponse,
  PaginationResponseOfAssistanceTableResponse,
  SetStatusInProgressCommand,
  SetStatusResponse,
} from '@shared/model/atlas.api';
import { firstValueFrom, Subscription } from 'rxjs';
import { AlertService } from '@shared/services/alert.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table/table.interface';
import { ButtonSeverity } from '@ui/button/button.component';
import { InputTextModule } from 'primeng/inputtext';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssistanceFormService } from '../assistance-form/services/assistance-form.service';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { CancelAssistanceDialogComponent } from '../cancel-assistance.dialog/cancel-assistance.dialog.component';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService } from '@ui/dialog/dialog.service';
import { BadgeModule } from 'primeng/badge';
import { ActivatedRoute, Router } from '@angular/router';
import { GuaranteeFormComponent } from '@app/sos-agent/assistances/guarantee-form/guarantee-form.component';
import { LoaderService } from '@shared/components/loader/loader.service';
import { DropdownInputButtonComponent } from '@shared/components/dropdown-input-button/dropdown-input-button.component';
import { TableServiceLocal } from '@ui/table/table-service-local.service';
import { CostFormComponent } from '@app/sos-agent/assistances/cost-form/cost-form.component';
import { LocationSosService } from '@shared/services/location-sos.service';
import { AssistanceReviewComponent } from '../assistance-review/assistance-review.component';
import { ActiveFiltersComponent } from '@app/features/active-filters/active-filters/active-filters.component';
import { ActiveFiltersService } from '@app/features/active-filters/active-filters-service';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { AssistanceSidebarComponent } from '../assistance-sidebar/assistance-sidebar.component';

@Component({
  selector: 'atlas-assistance-table',
  standalone: true,
  imports: [
    PageComponent,
    TabViewModule,
    TableComponent,
    InputTextModule,
    ReactiveFormsModule,
    FormsModule,
    TableComponent,
    ContextMenuModule,
    PageComponent,
    TabViewModule,
    ConfirmDialogModule,
    InputTextModule,
    BadgeModule,
    DropdownInputButtonComponent,
    ActiveFiltersComponent,
    AssistanceSidebarComponent,
  ],
  templateUrl: './assistance-table.component.html',
  styleUrl: './assistance-table.component.scss',
})
export class AssistanceTableComponent
  implements OnInit, OnDestroy, OnTableInit
{
  private _subs: Subscription;
  searchOptionCtrl: FormControl;
  searchCtrl: FormControl;
  searchOptions: SelectItem[];
  statusSearch: AssistanceStatus;
  private initSearch: boolean = false;
  totalRequested: number | undefined;
  tableConfig: TableConfig;
  paginatedData: IPaginationResponseOfAssistanceTableResponse;
  activeIndex: number | undefined;
  resetPagination: boolean | undefined;

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);

  @Input() clientId: string;

  constructor(
    private client: AssistancesClient,
    private alert: AlertService,
    private _locationSosService: LocationSosService,
    private confirmationService: ConfirmationService,
    private _assistanceFormService: AssistanceFormService,
    private _dialogService: DialogService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private _tableService: TableServiceLocal,
    private _activeFiltersService: ActiveFiltersService,
    private service: ClientPackageTableService,
  ) {
    this.totalRequested = 0;
    this._subs = new Subscription();
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
    this.statusSearch = AssistanceStatus.EMPTY;
    this.searchOptionCtrl = new FormControl<SearchOptions>(
      SearchOptions.CLIENT,
    );
    this.searchCtrl = new FormControl<string>('');
    this.searchOptions = [
      {
        label: SearchOptions.CLIENT,
        value: SearchOptions.CLIENT,
      },
      {
        label: SearchOptions.CLIENT_PHONE,
        value: SearchOptions.CLIENT_PHONE,
      },
      {
        label: SearchOptions.LICENCE_PLATE,
        value: SearchOptions.LICENCE_PLATE,
      },
      {
        label: SearchOptions.PARTNER,
        value: SearchOptions.PARTNER,
      },
    ];
  }

  ngOnInit() {
    this.countRequested();

    this.alertService.requestCountChanged$.subscribe(() => {
      this.countRequested();
    });

    this.clientId ? this.clientTableConfig() : this.setTableConfig();

    this.route.queryParams.subscribe((params) => {
      const tab = params['tab']?.toLowerCase();
      const tabLabels = [
        'sve',
        'u_toku',
        'zavrsene',
        'otkazane',
        'zatrazene',
        'nove',
      ];
      if (tab && tabLabels.includes(tab)) {
        this.activeIndex = tabLabels.indexOf(tab);
      } else {
        this.activeIndex = 0;
        this.router
          .navigate([], {
            relativeTo: this.route,
            queryParams: { tab: 'sve' },
            queryParamsHandling: 'merge',
          })
          .then();
      }
      this._setTabByIdx(this.activeIndex);
    });

    this.searchCtrl.valueChanges.subscribe((value) => {
      if (!value) {
        this._activeFiltersService.removeFilter('search');
      }
    });

    this._subs = this._activeFiltersService.activeFilters$.subscribe((filters) => {
      const hasSearchFilter = filters.some((f) => f.key === 'search');
      if (this.initSearch && !hasSearchFilter) {
        this.searchCtrl.setValue('', { emitEvent: false });
        this.load();
      }
      this.initSearch = hasSearchFilter;
    });
  }

  countRequested() {
    this._subs.add(
      this.client.getRequestedCount().subscribe((res) => {
        this.totalRequested = res.result;
      }),
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;

        this.load();
      },
      selectMode: SelectMode.MULTI,
      columns: [
        {
          header: 'Status',
          field: 'statusBadge',
          type: 'badge',
          badgeClass: 'assistance-',
          isFrozen: true,
          styleClass: 'w-7rem',
        },
        {
          header: 'Broj slučaja',
          field: 'caseNumber',
          type: 'text',
          styleClass: 'w-10rem',
        },
        {
          header: 'Klijent',
          field: 'client',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold w-10rem',
          columns: [
            {
              header: '',
              field: 'clientPhone',
              type: 'text',
            },
          ],
        },
        {
          header: 'Vozilo',
          field: 'vehicle',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
          columns: [
            {
              header: '',
              field: 'licencePlate',
              type: 'text',
            },
          ],
        },
        {
          header: 'Relacija',
          field: 'from',
          type: 'text',
          columns: [
            {
              header: '',
              field: 'to',
              type: 'text',
            },
          ],
        },
        {
          header: 'Saradnik',
          field: 'partner',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold w-10rem',
          columns: [
            {
              header: '',
              field: 'partnerPhone',
              type: 'text',
            },
          ],
        },
        {
          header: 'Vozač',
          field: 'driver',
          type: 'text',
          columns: [
            {
              header: '',
              field: 'driverPhone',
              type: 'text',
            },
          ],
        },
        {
          header: 'Agent',
          field: 'agent',
          type: 'text',
        },
        {
          header: 'Poslednja izmena',
          field: 'modifiedDate',
          styleClass: 'text-black-alpha-90 font-semibold',
          type: 'text',
          columns: [
            {
              header: '',
              field: 'modifiedTime',
              type: 'text',
            },
          ],
        },
        {
          header: 'Ocena agenta',
          field: 'agentRating',
          type: 'rating',
          isVisible: this.activeIndex === 2,
        },
        {
          header: 'Ocena partnera',
          field: 'partnerRating',
          type: 'rating',
          isVisible: this.activeIndex === 2,
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Izmeni asistenciju',
          icon: 'pi pi-pencil',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            return (
              rowData.status == AssistanceStatus.NEW ||
              rowData.status == AssistanceStatus.IN_PROGRTESS ||
              rowData.status == AssistanceStatus.DRAFT
            );
          },

          callback: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            this._assistanceFormService.setStatus(rowData.status);
            this._locationSosService.routeToEditAssitanceForm(rowData.id);
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Pregled asistencije',
          icon: 'pi pi-eye',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            return (
              rowData.status == AssistanceStatus.NEW ||
              rowData.status == AssistanceStatus.IN_PROGRTESS ||
              rowData.status == AssistanceStatus.CANCELED ||
              rowData.status == AssistanceStatus.FINISHED
            );
          },
          callback: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            this._locationSosService.routeToPreviewAssistence(rowData.id);
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Obradi zahtev',
          icon: 'pi pi-verified',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            return rowData.status == AssistanceStatus.REQUEST;
          },

          callback: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            this._assistanceFormService.setStatus(rowData.status);
            this._locationSosService.routeToEditAssitanceForm(rowData.id);
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Pošalji garanciju',
          icon: 'pi pi-envelope',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            return (
              rowData.status === AssistanceStatus.IN_PROGRTESS ||
              rowData.status === AssistanceStatus.FINISHED
            );
          },
          callback: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);

            this.dialogConfig.header = 'Slanje garancije';
            //this.dialogConfig.headerDescription = 'Potvrdite slanje';
            this.dialogConfig.customSubmitButton = {
              label: 'Potvrdi slanje',
              icon: '',
              style: 'max-width: 100px',
            };
            this.dialogConfig.customCancelButton = {
              label: 'Otkaži',
              icon: '',
              style: 'max-width: 100px',
            };
            this.dialogConfig.data = {
              id: rowData.id,
            };
            this.dialogConfig.closable = true;

            this.openGuaranteeDialog();
          },
        },
        {
          mode: ActionMode.MULTI,
          label: 'Započni asistenciju',
          icon: 'pi pi-play',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            return rowData.status == AssistanceStatus.NEW;
          },
          callback: (rowIdx: number) => {
            this.confirmationService.confirm({
              header: 'Izmena statusa asistencije',
              message: 'Da li želiš da započneš asistenciju?',
              acceptButtonStyleClass: ButtonSeverity.DANGER,
              rejectLabel: 'Odustani',
              acceptLabel: 'Započni',
              icon: 'pi pi-play',
              accept: () => {
                this.loader.setShowLoader = true;
                const rowData: IAssistanceTableResponse =
                  this._getPaginatedItem(rowIdx);
                this._setInProgress(rowData.id).then((res) => {
                  this._updateTableItems(
                    rowIdx,
                    res.result.value.statusBadge,
                    AssistanceStatus.IN_PROGRTESS,
                  );
                  this.loader.reset();
                  this.alert.addSuccessMsg(res.result.message);
                });
              },
              reject: () => {},
            });
          },
        },
        {
          mode: ActionMode.MULTI,
          label: 'Završi asistenciju',
          icon: 'pi pi-stop',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            return rowData.status == AssistanceStatus.IN_PROGRTESS;
          },
          callback: (rowIdx: number) => {
            this.confirmationService.confirm({
              header: 'Izmena statusa asistencije',
              message: 'Da li želiš da završiš asistenciju?',
              acceptButtonStyleClass: ButtonSeverity.DANGER,
              rejectLabel: 'Odustani',
              acceptLabel: 'Završi',
              icon: 'pi pi-stop',
              accept: () => {
                this.loader.setShowLoader = true;
                const rowData: IAssistanceTableResponse =
                  this._getPaginatedItem(rowIdx);
                this._setFinished(rowData.id).then((res) => {
                  this._updateTableItems(
                    rowIdx,
                    res.result.value.statusBadge,
                    AssistanceStatus.FINISHED,
                  );
                  this.loader.reset();
                  this.alert.addSuccessMsg(res.result.message);
                });
              },
              reject: () => {},
            });
          },
        },
        {
          mode: ActionMode.MULTI,
          label: 'Otkaži asistenciju',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            return (
              rowData.status == AssistanceStatus.NEW ||
              rowData.status == AssistanceStatus.IN_PROGRTESS ||
              rowData.status == AssistanceStatus.REQUEST
            );
          },
          icon: 'pi pi-times',
          callback: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);

            this.dialogConfig.header = 'Otkazivanje';
            this.dialogConfig.headerDescription =
              'Potvrdite otkazivanje asistencije';
            this.dialogConfig.customSubmitButton = {
              label: 'Potvrdi otkazivanje',
              icon: 'pi pi-times',
              style: 'max-width: 100px',
            };
            this.dialogConfig.customCancelButton = {
              label: 'Otkaži',
              icon: '',
              style: 'max-width: 100px',
            };
            this.dialogConfig.data = {
              id: rowData.id,
            };
            this.dialogConfig.closable = true;
            this.openCancelDialog(rowIdx);
          },
        },
        {
          mode: ActionMode.MULTI,
          label: 'Upiši cenu',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            return rowData.status == AssistanceStatus.FINISHED;
          },
          icon: 'pi pi-dollar',
          callback: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);

            this.dialogConfig.header = 'Unos cene';
            this.dialogConfig.headerDescription = 'Unesite cenu asistencije';
            this.dialogConfig.customSubmitButton = {
              label: 'Potvrdi izmenu',
              icon: 'pi pi-check',
              style: 'max-width: 100px',
            };
            this.dialogConfig.customCancelButton = {
              label: 'Otkaži',
              icon: '',
              style: 'max-width: 100px',
            };
            this.dialogConfig.data = {
              id: rowData.id,
            };
            this.dialogConfig.closable = true;
            this.openCostDialog();
          },
        },
        {
          mode: ActionMode.MULTI,
          label: 'Zadovoljstvo korisnika',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);

            return (
              rowData.status === AssistanceStatus.FINISHED &&
              (!rowData.agentRating || !rowData.partnerRating)
            );
          },
          callback: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);

            this.dialogConfig.header = 'Zadovoljstvo korisnika';
            this.dialogConfig.headerDescription =
              'Ostavite ocenu zadovoljstva kosrisnika';
            this.dialogConfig.customSubmitButton = {
              label: 'Potvrdi ocenu',
              icon: 'pi pi-check',
              style: 'max-width: 100px',
            };
            this.dialogConfig.customCancelButton = {
              label: 'Otkaži',
              icon: '',
              style: 'max-width: 100px',
            };
            this.dialogConfig.closable = true;
            this.dialogConfig.data = {
              assistanceId: rowData.id,
            };
            this.openReviewDialog();
          },
          icon: 'pi pi-star-fill',
        },
      ],
      emptyTableInfo: {
        header: 'Bez asistencija',
        description: 'Ovde će biti prikazana tabela asistencija',
        btnLabel: 'Dodaj asistenciju',
        action: () => this.handleAdd(),
      },
    });
  }

  clientTableConfig() {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;

        this.load();
      },
      selectMode: SelectMode.MULTI,
      columns: [
        {
          header: 'Vozilo',
          field: 'vehicle',
          type: 'text',
        },
        {
          header: 'Paket',
          field: 'packageName',
          type: 'text',
        },
        {
          header: 'Status',
          field: 'statusBadge',
          type: 'badge',
          badgeClass: 'assistance-',
          isFrozen: true,
          styleClass: 'w-7rem',
        },
        {
          header: 'Agent',
          field: 'agent',
          type: 'text',
        },
        {
          header: 'Datum kreiranja',
          field: 'createdOn',
          type: 'text',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Pogledaj detaljnije',
          icon: 'pi pi-eye',
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this.service.showPreviewSidebar(rowData.id);
          },
          shouldDisplayByCondition: () => true,
        },
      ],
      emptyTableInfo: {
        header: 'Bez asistencija',
        description: 'Ovde će biti prikazana tabela asistencija',
        btnLabel: 'Dodaj asistenciju',
        action: () => this.handleAdd(),
      },
    });
  }

  async openReviewDialog() {
    let ref = this._dialogService.open(
      AssistanceReviewComponent,
      this.dialogConfig,
    );

    ref.onSubmit.subscribe(async () => {
      await this.load();
    });
  }

  openCancelDialog(rowIdx: number): void {
    const ref = this._dialogService.open(
      CancelAssistanceDialogComponent,
      this.dialogConfig,
    );
    this._subs.add(
      ref.onClose.subscribe((res: SetStatusResponse) => {
        if (res) {
          if (
            this.paginatedData.data.at(rowIdx).status ===
            AssistanceStatus.REQUEST
          ) {
            this.alertService.notifyRequestCountChanged();
          }
          if (this.statusSearch === AssistanceStatus.EMPTY) {
            this._updateTableItems(
              rowIdx,
              res.statusBadge,
              AssistanceStatus.CANCELED,
            );
          } else {
            this.load();
          }
        }
      }),
    );
  }

  openGuaranteeDialog(): void {
    const ref = this._dialogService.open(
      GuaranteeFormComponent,
      this.dialogConfig,
    );
  }

  openCostDialog(): void {
    const ref = this._dialogService.open(CostFormComponent, this.dialogConfig);
  }

  handleTabChange(idx: any) {
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { tab: this._setTabByIdx(idx) },
        queryParamsHandling: 'merge',
      })
      .then(() => {
        this.resetPagination = !this.resetPagination;
        this.paginatedData = { currentPage: 0, pageSize: 10 };
        this.load().then();
      });
  }

  async load() {
    this._tableService.setIsLoading();
    if (this.searchCtrl.value) {
      this._activeFiltersService.setFilter(
        'search',
        `Filter: ${this.searchCtrl.value}`,
      );
    }
    this._getList().then((res) => {
      this.paginatedData = res.result;
      this._tableService.resetIsLoading();
    });
  }

  private _setTabByIdx(idx: number) {
    this.activeIndex = idx;

    this.clientId ? this.clientTableConfig() : this.setTableConfig();

    switch (idx) {
      case 1:
        this.statusSearch = AssistanceStatus.IN_PROGRTESS;
        return 'u_toku';
      case 2:
        this.statusSearch = AssistanceStatus.FINISHED;
        return 'zavrsene';
      case 3:
        this.statusSearch = AssistanceStatus.CANCELED;
        return 'otkazane';
      case 4:
        this.statusSearch = AssistanceStatus.REQUEST;
        return 'zatrazene';
      case 5:
        this.statusSearch = AssistanceStatus.NEW;
        return 'nove';
      default:
        this.statusSearch = AssistanceStatus.EMPTY;
        return 'sve';
    }
  }

  private async _getList() {
    return await firstValueFrom(
      this.client.getTable(
        new GetAssistanceTableQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize,
          status: this.statusSearch,
          clientName:
            this.searchOptionCtrl.value === SearchOptions.CLIENT
              ? this.searchCtrl.value
              : undefined,
          clientId: this.clientId,
          clientPhone:
            this.searchOptionCtrl.value === SearchOptions.CLIENT_PHONE
              ? this.searchCtrl.value
              : undefined,
          licencePlate:
            this.searchOptionCtrl.value === SearchOptions.LICENCE_PLATE
              ? this.searchCtrl.value
              : undefined,
          partnerName:
            this.searchOptionCtrl.value === SearchOptions.PARTNER
              ? this.searchCtrl.value
              : undefined,
        }),
      ),
    );
  }

  private async _setInProgress(id: string) {
    return await firstValueFrom(
      this.client.setStatusInProgress(
        new SetStatusInProgressCommand({
          id: id,
        }),
      ),
    );
  }

  private async _setFinished(id: string) {
    return await firstValueFrom(
      this.client.setStatusFinished(
        new SetStatusInProgressCommand({
          id: id,
        }),
      ),
    );
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  private _updateTableItems(
    rowIdx: number,
    statusBadge: any,
    status: AssistanceStatus,
  ) {
    const pagData = this._getPaginatedItem(rowIdx);
    pagData.statusBadge = statusBadge;
    pagData.status = status;
    this.paginatedData.data[this._getPaginatedIndex(rowIdx)] = pagData;

    this.paginatedData = new PaginationResponseOfAssistanceTableResponse({
      data: this.paginatedData.data,
      ...this.paginatedData,
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  handleAdd() {
    this._locationSosService.routeToCreateAssitanceForm();
  }
}

enum SearchOptions {
  CLIENT = 'Klijent',
  CLIENT_PHONE = 'Telefon',
  LICENCE_PLATE = 'Tablice',
  PARTNER = 'Saradnik',
}
