<span class="max-w-full p-input-icon-left block">
  <i class="pi pi-search search-icon"></i>
  <p-autoComplete
    pAutoFocus
    (completeMethod)="handleSearch($event)"
    (onKeyUp)="handleEnterClick($event)"
    (onSelect)="onSelectChange($event)"
    [(ngModel)]="model"
    [optionLabel]="fieldName"
    [placeholder]="ctrlPlaceholder"
    [suggestions]="suggestions"
    [inputStyleClass]="'w-full pl-5 text-sm ' + formCtrlClass"
    [styleClass]="'w-full'"
    [disabled]="isDisabled"
    [autofocus]="shouldFocus"
    [multiple]="multiple"
    [forceSelection]="forceSelection"
  >

    <ng-template let-outerContext pTemplate="selectedItem" *ngIf="selectedItem">
      <ng-container
        *ngTemplateOutlet="selectedItem; context: { $implicit: outerContext }"
      >
      </ng-container>
    </ng-template>

    <ng-template let-outerContext pTemplate="item" *ngIf="item">
      <ng-container
        *ngTemplateOutlet="item; context: { $implicit: outerContext }"
      >
      </ng-container>
    </ng-template>


  </p-autoComplete>
</span>
