<atlas-page
  pageTitle="Moje preporuke"
  btnIcon="pi pi-plus"
  actionLabel="Dodaj preporuku"
  (onHandleAction)="handleAddNewRecommendation()"
>
  <div class="header-container flex justify-content-between w-full gap-2">
    <div class="flex">
      <div class="mr-2 font-semibold text-lg">
        {{ suggestCount }}
      </div>
      <div class="font-semibold text-lg text-gray-600">preoporuka</div>
    </div>
    <div class="header-container flex justify-content-end w-full gap-2">
      <div>
        <div class="search-bar gap-3"></div>
        <atlas-input-text
          ctrlPlaceholder="Pretraži"
          [hideErrors]="true"
          [formCtrl]="searchCtrl"
          formCtrlIcon="pi pi-search"
          (onEnterClicked)="load()"
        ></atlas-input-text>
      </div>
      <div class="filters-button">
        <atlas-button
          btnIcon="pi pi-sliders-h"
          class="filters-button"
          [btnStyle]="ButtonStyle.BASIC"
          [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
          btnLabel="Filteri"
        ></atlas-button>
      </div>
      <div class="flex align-items-center gap-2">
        <p-dropdown
          [options]="rowsOptions"
          [(ngModel)]="selectedRows"
          placeholder="Select rows"
          class="dropdown-number"
        ></p-dropdown>
      </div>
    </div>
  </div>

  <atlas-table
    [paginatedData]="paginatedData"
    [tableConfig]="tableConfig"
  ></atlas-table>
</atlas-page>
