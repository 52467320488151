<div class="container-main ml-1">
  <div class="form-container">
    <div class="step-title">Korak 3</div>
    <div class="text-3xl font-semibold mt-1">Plaćanje</div>

    <div class="mt-6 title-text">Način isporuke</div>
    <div class="mt-3">
      <atlas-dropdown
        ctrlLabel="Način isporuke"
        [options]="deliveryType"
        [optionLabel]="orderForm.controls.deliveryType.label"
        [formCtrl]="orderForm.controls.deliveryType"
        ctrlStyleClass="w-full lg:w-19rem"
        ctrlPlaceholder="Način isporuke"
      ></atlas-dropdown>
    </div>

    <div class="mt-6 title-text">Način plaćanja</div>
    <div class="mt-5">
      <atlas-dropdown
        ctrlLabel="Način plaćanja"
        [optionLabel]="orderForm.controls.paymentType.label"
        [options]="paymentType"
        [formCtrl]="orderForm.controls.paymentType"
        ctrlStyleClass="w-full lg:w-19rem"
        ctrlPlaceholder="Način placanja"
      ></atlas-dropdown>
    </div>

    <div class="mt-4 title-text">Od kada želite da vam se aktivira paket?</div>
    <div class="mt-3 mb-3" *ngFor="let type of partnerTypes">
      <p-radioButton
        [formControl]="orderForm.controls.isUrgent | formControl"
        [value]="type.value"
      ></p-radioButton>
      <label class="ml-3" [for]="type.name">{{ type.name }}</label>
    </div>
    <small *ngIf="!orderForm.controls.isUrgent.valid" class="text-red-500">Obavezno polje</small>
  </div>

  <div class="summary-box">
    <div class="package-text mb-3">Kupovina</div>

    @for (p of packages?.value; let idx = $index; track p.Id) {
      <div class="item mt-3">
        <span *ngIf="packagesSum > 0" class="sub-text"
          >{{ idx + 1 + "." }} {{ p.packageName }}</span
        >
        <span class="sub-texte">{{
          p.packagePrice | currencyNumber
        }}</span>
      </div>
    }

    <div class="total mt-3">
      <span class="mt-3 package-text">Ukupno</span>
      <span class="mt-3 package-text">{{
        packagesSum ?? 0 | currencyNumber
      }}</span>
    </div>
  </div>
</div>
