import {Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DividerModule } from 'primeng/divider';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import {NgClass, NgIf, NgTemplateOutlet} from '@angular/common';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import {StepsModule} from "primeng/steps";
import {
    AddCampaignContractComponent
} from "@app/cc-leader/campaigns/add-campaign/add-campaign-contract/add-campaign-contract.component";
import {
    AddCampaignLeadsComponent
} from "@app/cc-leader/campaigns/add-campaign/add-campaign-leads/add-campaign-leads.component";

@Component({
  selector: 'atlas-scrollable-page',
  standalone: true,
    imports: [
        AddCampaignContractComponent,
        AddCampaignLeadsComponent,
        ButtonComponent,
        DividerModule,
        IconCheckboxComponent,
        InlineWrapperComponent,
        NgIf,
        ScrollPanelModule,
        NgTemplateOutlet,
        // PrimeTemplate,
        NgClass,
        StepsModule,
    ],
  templateUrl: './scrollable-page.component.html',
  styleUrl: './scrollable-page.component.scss',
})
export class ScrollablePageComponent {
  @Input() submitBtnLabel: string;
  @Input() submitBtnIcon: 'add' | 'edit' | string = 'add';
  @Input() pageTitle: string;
  @Input() isGray: boolean;
  @Output() onSubmitAction = new EventEmitter();
  @Output() onCancelAction = new EventEmitter();

    @ContentChild('content') content!: TemplateRef<ElementRef>;

  protected readonly ButtonSeverity = ButtonSeverity;

  protected readonly ButtonStyle = ButtonStyle;

  handleCancelForm() {
    this.onCancelAction.emit();
  }

  handleSubmitForm() {
    this.onSubmitAction.emit();
  }
}
