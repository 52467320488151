import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {firstValueFrom, Subscription} from "rxjs";
import {
  BrandModelItem,
  IBrandModelItem,
  VehiclesClient
} from "@shared/model/atlas.api";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {NewVehicleService} from "@app/features/services/new-vehicle-service";
import {DropdownModule} from "primeng/dropdown";

@Component({
  selector: 'atlas-model-dropdown',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropdownModule
  ],
  templateUrl: './model-dropdown.component.html',
  styleUrl: './model-dropdown.component.scss'
})
export class ModelDropdownComponent
    implements OnInit, OnChanges, OnDestroy {
  private _subs: Subscription;
  models: IBrandModelItem[] = [];
  shouldResetInput: boolean = false;
  selectedModelCtrl: FormControl<IBrandModelItem> = new FormControl();

  @Input() brandId: string | undefined = undefined;
  @Input() isDisabled: boolean = true;
  @Input() modelNameCtrl: FormControl<string>;

  @Output() public onModelChanged = new EventEmitter<IBrandModelItem>();

  constructor(
      private _client: VehiclesClient,
      public newVehicleService: NewVehicleService,
  ) {
    this._subs = new Subscription();
  }

  ngOnInit() {
    this._subs.add(
        this.newVehicleService.shouldResetModelInput$.subscribe((res) => {
          this.selectedModelCtrl.reset();
        }),
    );
    this._subs.add(this.selectedModelCtrl.valueChanges.subscribe((value: IBrandModelItem | string) => {
      if (typeof value === 'string' || value instanceof String) {
        this.modelNameCtrl.patchValue(value as string);
        this.newVehicleService.setModelNameCtrl(value as string);
        this.onModelChanged.emit(new BrandModelItem({
          name: value as string,
          modelId: undefined
        }));
      } else {
        this.modelNameCtrl.patchValue(value.name);
        this.newVehicleService.setModelNameCtrl(value.name);
        this.onModelChanged.emit(value);
      }
    }))
    this.newVehicleService.setModelNameCtrl(this.modelNameCtrl.value);
    this.newVehicleService.setModelNameRequired();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['brandId']?.currentValue !== changes['brandId']?.previousValue) {
      this.models = [];
      this._load();
    }
    if (
        changes['modelNameCtrl']?.currentValue !== changes['modelNameCtrl']?.previousValue
    ) {
      this.newVehicleService.setModelNameCtrl(this.modelNameCtrl.value);
    }
  }

  private _load(keyword?: string) {
    this._getModels(keyword).then((res) => {
      this.models = [...res.result.responseList];
    });
  }

  private async _getModels(keyword: string) {
    return await firstValueFrom(
        this._client.getBrandModels(this.brandId),
    );
  }

  ngOnDestroy() {
    this.newVehicleService.resetCtrl();
    this.shouldResetInput = true;
    this._subs.unsubscribe();
  }
}
