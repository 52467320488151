import { formatDate, NgForOf, NgOptimizedImage } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CampaignFormService } from '@app/cc-leader/services/campaign-form.service';
import { PageComponent } from '@shared/components/page/page.component';
import { CampaignsClient, GetLeadsTableQuery, IPaginationResponseOfLeadsTableResponse } from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { LocationService } from '@shared/services/location.service';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { PageInfoCardComponent } from '@ui/page-info-card/page-info-card.component';
import { ActionMode, SelectMode, TableConfig } from '@ui/table/table-common';
import { TableComponent } from '@ui/table/table.component';
import {TableLazyLoadEvent} from "primeng/table/table.interface";
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { firstValueFrom, Subscription } from 'rxjs';
import { AddFileEditComponent } from '../add-file-edit/add-file-edit.component';
import {TableServiceLocal} from "@ui/table/table-service-local.service";

@Component({
  selector: 'atlas-edit-leads',
  standalone: true,
  imports: [ButtonModule,
    NgForOf,
    PageComponent,
    PageInfoCardComponent,
    TabViewModule,
    TableComponent,
    NgOptimizedImage,
    ButtonComponent,
    AddFileEditComponent],
  templateUrl: './edit-leads.component.html',
  styleUrl: './edit-leads.component.scss'
})
export class EditLeadsComponent implements OnInit {

  private _subs: Subscription = new Subscription();
  protected tableConfig: TableConfig

  campaignId: string;
  totalLeads: number;
  paginatedData: any;

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  constructor(
    private _client: CampaignsClient,
    private service: CampaignFormService,
    private _alertService: AlertService,
    private location: LocationService,
    private _dialogRef: DialogRef,
    private _tableService: TableServiceLocal
  ) {
    this._subs = new Subscription();
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.tableConfig = {
      selectMode: SelectMode.SINGLE,
      showHeader: true,
      columns: [],
      rowsPerPage: 10,
    };
    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
  }
  ngOnInit(): void {
    this.campaignId = this.service.getCampaignId();
    this.setTableConfig().then();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        this.load();
      },
      columns: [
        {
          field: 'count',
          header: 'Broj leadova',
          type: 'text'
        },
        {
          field: 'createdAtFormatted',
          header: 'Dodato',
          type: 'text'
        },
        {
          field: 'agentName',
          header: 'Dodao agent',
          type: 'text'
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Vidi listu',
          label: 'Vidi listu',
          icon: 'pi pi-eye font-semibold',
          actionClass: 'p-2 ml-5 gap-2 font-semibold text-sm',
          actionSeverity: ButtonSeverity.SECONDARY_GREY,
          actionStyle: ButtonStyle.OUTLINED,
          shouldDisplayByCondition: () => true,
          callback: (rowIdx: number) => {
            const rowData = this.paginatedData.data[rowIdx];
            this.location.routeToDetailsLeads(this.campaignId, rowData);
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Obrisi',
          icon: 'pi pi-trash',
          shouldDisplayByCondition: () => true,
          callback: (rowIdx: number) => {
            const rowData = this.paginatedData.data[rowIdx];
            this.deleteLead(rowData);
          },
        },
      ]
    });
  }


  deleteLead(leads: any) {
    this._subs.add(
      this._client
        .deleteLeadGroup(this.campaignId, leads.createdAt)
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this._dialogRef.close()
          this.location.routeToCampaignTable();
        })
    );
  }

  load() {
    this._tableService.setIsLoading();
    this._getList().then((res) => {
      this.totalLeads = res.result.leadCount;
      this.paginatedData = {
        data: res.result.groups.map(group => ({
          count: group.count,
          createdAtFormatted: formatDate(group.createdAt, 'dd MMM yyyy • HH:mm', 'en-US'),
          createdAt: group.createdAt,
          agentName: group.createdBy.name,
        }))
      };
      this._tableService.resetIsLoading();
    })
  }

  private async _getList() {
    return await firstValueFrom(
      this._client.getLeadGroups(this.campaignId
      )
    )
  }

}
