import { NgOptimizedImage } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CampaignFormService } from '@app/cc-leader/services/campaign-form.service';
import { LeadCampaignFormService } from '@app/cc-leader/services/lead-campaign-form.service';
import { formatBytes } from '@shared/functions/functions';
import { CampaignsClient } from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'atlas-add-file-edit',
  standalone: true,
  imports: [ButtonComponent, NgOptimizedImage],
  templateUrl: './add-file-edit.component.html',
  styleUrl: './add-file-edit.component.scss'
})
export class AddFileEditComponent implements OnInit {
  campaignId: string;
  selectedFiles: { name: string; size: string }[] = [];
  constructor(private service: LeadCampaignFormService,
    private _client: CampaignsClient,
    private campaignService: CampaignFormService,
    private _alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.campaignId = this.campaignService.getCampaignId();
    console.log(this.campaignId)
  }

  onFileSelected($event: any) {
    /*if (file) {
      this.fileName = file.name;
      const formData = new FormData();
      formData.append("thumbnail", file);

      const upload$ = this.http.post("/api/thumbnail-upload", formData, {
        reportProgress: true,
        observe: 'events'
      })
          .pipe(
              finalize(() => this.reset())
          );

      this.uploadSub = upload$.subscribe(event => {
        if (event.type == HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(100 * (event.loaded / event.total));
        }
      })
    }*/

    for (let i = 0; i < $event.target.files.length; i++) {
      const file = $event.target.files[i];
      this.selectedFiles.push({
        name: file.name,
        size: formatBytes(file.size),
      });
      const formData = new FormData();
      formData.append('leadfile', file);
      this.service.addFile = {
        fileName: 'test',
        data: file,
      };
    }    
        this._submitLeadFiles(this.campaignId);
        this._alertService.addSuccessMsg('Uspesno dodat fajl');
        localStorage.setItem('reopenDialog', 'true');
        window.location.reload()
  }

  private async _submitLeadFiles(campaignId: string) {
    return await firstValueFrom(
      this._client.createLeads(
        campaignId,
        undefined,
        this.service.getFiles, 
      )
    );
  }

  handleFileRemove(file: string) {
    this.selectedFiles = this.selectedFiles.filter((x) => x.name !== file);
    this.service.removeFile(file);
  }

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

}
