<div>
    <div class="mb-2">
        <div class="mb-4">
            <div class="font-semibold mb-2">Ocena za agenta</div>
            <p-rating
                [(ngModel)]="agentReviewValue"
            ></p-rating> 
        </div>
        <div>
            <div class="font-semibold mb-2">Ocena za partnera</div>
            <p-rating
                [(ngModel)]="partnerReviewValue"
            ></p-rating> 
        </div>
    </div>
    <p-divider></p-divider>
    <div>
        <div class="font-semibold mb-2">Komentar (opciono) </div>
        <textarea
            rows="7"
            cols="53"
            pInputTextarea
            [(ngModel)]="reviewComment"
            placeholder="Unesite tekst komentara..."
        ></textarea>
    </div>
</div>
