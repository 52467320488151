import { CommonModule, NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampaignFormService } from '@app/cc-leader/services/campaign-form.service';
import { RecommendationFormService } from '@app/features/recommendations/add-recommendation-form/recommendation-form.service';
import {
  CampaignsClient,
    IPaginationResponseOfLeadCommentResponse,
    RecommendationsClient
} from '@shared/model/atlas.api';
import { AtlasDatePipe } from '@shared/pipes/date.pipe';
import { TableServiceLocal } from '@ui/table/table-service-local.service';
import { BadgeModule } from 'primeng/badge';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'atlas-comments-preview',
  standalone: true,
  imports: [
    BadgeModule,
    NgFor,
    NgIf,
    ScrollPanelModule,
    CommonModule,
    NgOptimizedImage,
  ],
  providers: [AtlasDatePipe],
  templateUrl: './comments-preview.component.html',
  styleUrl: './comments-preview.component.scss',
})
export class CommentsPreviewComponent implements OnInit, OnChanges {
  id: string;
  paginatedData: IPaginationResponseOfLeadCommentResponse;
  currentPage: number = 1;
  pageSize: number = 6;

  @Input() shouldReloadComments: boolean = false;
  @Input() isRecommendation: boolean = false;
  @Input() sourceRecommendationId: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private _camapaignClient: CampaignsClient,
    private _recommendationClient: RecommendationsClient,
    private _tableService: TableServiceLocal,
    private _datePipe: AtlasDatePipe,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['shouldReloadComments']?.currentValue) {
      this.load();
      this.shouldReloadComments = false;
    }

    if (changes['sourceRecommendationId']) {
      this.id = changes['sourceRecommendationId'].currentValue;
      this.load()
    }
  }

  ngOnInit(): void {
    this.load();
  }

  formatDate(date: string | Date): string {
    return this._datePipe.transformDateWithTime(date);
  }

  async load() {
    this._tableService.setIsLoading();
    await this._getList().then((res) => {
      this.paginatedData = res.result;
      this._tableService.resetIsLoading();
    });
  }

  private async _getList() {
    if (this.isRecommendation) {
      return await firstValueFrom(
        this._recommendationClient.getRecommendationStates(
          this.id,
          this.paginatedData?.currentPage ?? 1,
          this.paginatedData?.pageSize
        )
      )
    } else {
      return await firstValueFrom(
        this._camapaignClient.getLeadComments(
          this.id,
          this.paginatedData?.currentPage ?? 1,
          this.paginatedData?.pageSize
        )
      )
    }
  }

  
}
