import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { DeliveryType, PaymentType } from '@shared/model/atlas.api';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { SelectItem } from 'primeng/api';
import { SelectButtonModule } from 'primeng/selectbutton';

@Component({
  selector: 'atlas-payment-type-options',
  standalone: true,
  imports: [
    SelectButtonModule,
    DropdownComponent,
    ReactiveFormsModule,
    FormsModule,
    NgIf
  ],
  templateUrl: './payment-type-options.component.html',
  styleUrl: './payment-type-options.component.scss'
})
export class PaymentTypeOptionsComponent {

  stateOptions: SelectItem[] = [
    {
      label: 'Završi kupovinu preko mobilne aplikacije',
      value: 'draft',
      title:
        'Klijent treba da dovriši plaćanje online, kroz mobilnu aplikaciju.',
    },
    {
      label: 'Završi kupovinu na standardan način',
      value: 'new',
      title: 'Klijent treba da plati kuriru ili putem uplatnice',
    },
  ];

  stateValue: 'new' | 'draft' = 'draft';

  deliveryType: any = [
    { label: 'eUgovor', value: DeliveryType.E_CONTRACT },
    { label: 'Lično preuzimanje', value: DeliveryType.PICK_UP },
    { label: 'Bex', value: DeliveryType.BEX },
    { label: 'Bex besplatna', value: DeliveryType.BEX_FREE },
    { label: 'Bex bez otkupa', value: DeliveryType.BEX_WITHOUT_DEPOSIT },
    { label: 'Kurir NS', value: DeliveryType.COURIER_NS },
    { label: 'Promo-sezonac', value: DeliveryType.PROMO_SEASONAC },
  ];

  draftPaymentTypeOptions: any = [
      { label: 'Corvus Pay', value: PaymentType.CORVUS_PAY },
      { label: 'Gotovina', value: PaymentType.CASH },
      { label: 'Uplatnica', value: PaymentType.PAYMENT_SLIP },
    ];
  newPaymentTypeOptions: SelectItem<PaymentType>[] = [
      { label: 'Gotovina', value: PaymentType.CASH },
      { label: 'Uplatnica', value: PaymentType.PAYMENT_SLIP },
    ];

  @Input() deliveryTypeCtrl: FormControl = new FormControl(DeliveryType.E_CONTRACT);
  @Input() paymentTypeCtrl: FormControl = new FormControl(PaymentType.CORVUS_PAY);

  handleStateChange($event: SelectItem) {
    if ($event.value === 'draft') {
     this._handleDraft();
    } else {
      this._handleNew();
    }
  }

  private _handleDraft() {
    this.deliveryTypeCtrl.patchValue(DeliveryType.E_CONTRACT);
    this.paymentTypeCtrl.patchValue(PaymentType.CORVUS_PAY);
    this.deliveryTypeCtrl.disable();
    this.paymentTypeCtrl.disable();
  }

  private _handleNew() {
    this.deliveryTypeCtrl.enable();
    this.paymentTypeCtrl.enable();
    this.paymentTypeCtrl.patchValue(PaymentType.PAYMENT_SLIP);
  }

}
