import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataViewModule } from 'primeng/dataview';
import { PageInfoCardComponent } from "../../../../../infrastructure/ui/page-info-card/page-info-card.component";
import { firstValueFrom, Subscription } from 'rxjs';
import { ClientClient, GetClientPackagesTableStatsQuery } from '@shared/model/atlas.api';
import { ActivatedRoute } from '@angular/router';
import { CurrencyNumberPipe } from '@shared/pipes/currency-number.pipe';

@Component({
  selector: 'atlas-statistics-tab-client',
  standalone: true,
  imports: [
    DataViewModule,
    PageInfoCardComponent,
    CurrencyNumberPipe
],
  templateUrl: './statistics-tab-client.component.html',
  styleUrl: './statistics-tab-client.component.scss'
})
export class StatisticsTabClientComponent implements OnInit, OnDestroy{
  private _subs: Subscription = new Subscription();

  activeContracts: string;
  inactiveContracts: string;
  companyCost: string;
  clientId: string;

  constructor(
    private client: ClientClient,
    private _route: ActivatedRoute
  ) { 
    this.clientId = this._route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.geClientStats();
  }

  public async geClientStats() {
    const res = await this._loadClientStats();
    this.activeContracts = res.result.active;
    this.inactiveContracts = res.result.inactive;
    this.companyCost = res.result.companyCost;
  }

  private async _loadClientStats() {
    return await firstValueFrom(this.client.getClientStatsTab(this.clientId));
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
