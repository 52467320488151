import { UpperCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'licencePlate',
  standalone: true,
})
export class licencePlatePipe implements PipeTransform {
  constructor(private upperCasePipe: UpperCasePipe) {}

  transform(value: string | null | undefined, ...args: any[]): any {
    if (!value) {
      return value;
    }
    return this.upperCasePipe.transform(value.replace('/(sss*)/g', ''));
  }
}
