<atlas-page pageTitle="Agenti">
  <!-- Overview cards -->
  <div class="flex flex-column md:flex-row gap-3">
    <atlas-page-info-card
      [cardTitle]="'Online agenti'"
      cardIcon="pi-users"
      [cardNumber]="onlineAgents"
      class="flex-1"
    ></atlas-page-info-card>
    <atlas-page-info-card
      [cardTitle]="'Prodaja ovaj mesec'"
      [cardNumber]="salesThisMonth"
      cardImageSrc="assets/images/sales-icon.svg"
      class="flex-1"
    ></atlas-page-info-card>
    <atlas-page-info-card
      [cardTitle]="'Top agent ovog meseca'"
      [cardNumber]="topAgent"
      cardIcon="pi-user"
      class="flex-1"
      [cardImageSrc]="topAgentImg"
    ></atlas-page-info-card>
  </div>

  <div class="text-2xl font-semibold mt-5">Lista agenata</div>
  <div class="text-base text-gray-600 mt-2">
    Lista svih, aktivnih i neaktivnih agenata
  </div>

  <div class="flex flex-column mt-5 gap-3 table-filter">
    <div class="flex align-items-center gap-3">
      <p-tabView
        class="tabView flex-1"
        [(activeIndex)]="activeIndex!"
        (activeIndexChange)="handleTabChange($event)"
      >
        <p-tabPanel header="Svi agenti"></p-tabPanel>
        <p-tabPanel header="Aktivni"></p-tabPanel>
        <p-tabPanel header="Neaktivni"></p-tabPanel>
      </p-tabView>
      <div class="search-bar">
        <atlas-dropdown-input-button
          [searchOptions]="searchOptions"
          [searchOptionCtrl]="searchOptionCtrl"
          [searchCtrl]="searchCtrl"
          (onAction)="loadTable()"
        ></atlas-dropdown-input-button>
      </div>
    </div>
  </div>
  <div class="mt-1 mb-2 w-full">
    <atlas-active-filters></atlas-active-filters>
  </div>
  <atlas-table
    [paginatedData]="paginatedData"
    [tableConfig]="tableConfig"
  ></atlas-table>
</atlas-page>
