import { Component } from '@angular/core';
import { ButtonComponent, ButtonStyle } from '@ui/button/button.component';
import { NgOptimizedImage } from '@angular/common';
import { formatBytes } from '@shared/functions/functions';
import { LeadCampaignFormService } from '@app/cc-leader/services/lead-campaign-form.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { finalize, Subscription } from 'rxjs';
import { FileParameter } from '@shared/model/atlas.api';

@Component({
  selector: 'atlas-lead-file-upload',
  standalone: true,
  imports: [ButtonComponent, NgOptimizedImage],
  templateUrl: './lead-file-upload.component.html',
  styleUrl: './lead-file-upload.component.scss',
})
export class LeadFileUploadComponent {
  selectedFiles: { name: string; size: string }[] = [];
  constructor(private service: LeadCampaignFormService) {}

  onFileSelected($event: any) {
    /*if (file) {
      this.fileName = file.name;
      const formData = new FormData();
      formData.append("thumbnail", file);

      const upload$ = this.http.post("/api/thumbnail-upload", formData, {
        reportProgress: true,
        observe: 'events'
      })
          .pipe(
              finalize(() => this.reset())
          );

      this.uploadSub = upload$.subscribe(event => {
        if (event.type == HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(100 * (event.loaded / event.total));
        }
      })
    }*/

    for (let i = 0; i < $event.target.files.length; i++) {
      const file = $event.target.files[i];
      this.selectedFiles.push({
        name: file.name,
        size: formatBytes(file.size),
      });
      const formData = new FormData();
      formData.append('leadfile', file);
      this.service.addFile = {
        fileName: 'test',
        data: file,
      };
    }
  }

  handleFileRemove(file: string) {
    this.selectedFiles = this.selectedFiles.filter((x) => x.name !== file);
    this.service.removeFile(file);
  }

  protected readonly ButtonStyle = ButtonStyle;
}
