import {
  DatePipe,
  NgClass,
  NgComponentOutlet,
  NgFor,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet,
} from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AddCommentForm,
  ClientPackageTableService,
} from '@app/sos-agent/packages/services/client-package-table.service';
import { AddCommentCommand, ClientClient } from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { Subscription } from 'rxjs';
import { InputDescriptionComponent } from '@ui/input-description/input-description/input-description.component';
import { SkeletonModule } from 'primeng/skeleton';
import {markFormGroupTouched} from "@shared/forms/forms-common";

@Component({
  selector: 'atlas-client-comments',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgComponentOutlet,
    NgFor,
    NgForOf,
    InputTextComponent,
    InputDescriptionComponent,
    NgTemplateOutlet,
    DatePipe,
    SkeletonModule,
    NgOptimizedImage,
  ],
  templateUrl: './client-comments.component.html',
  styleUrl: './client-comments.component.scss',
})
export class ClientCommentsComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  clientId: string;
  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);
  comments: any[] = [];
  totalNumberOfComments: number;
  form: AddCommentForm;
  isLoading: boolean = true;

  constructor(
    private client: ClientClient,
    private service: ClientPackageTableService,
    private _dialogRef: DialogRef,
    private _alertService: AlertService
  ) {
    this.form = this.service.getAddCommentForm();
  }

  ngOnInit(): void {
    this.clientId = this.service.clientId;

    this.loadCommentsNumber();
    this.loadComments();

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleSave();
      }),
    );
  }

  loadCommentsNumber() {
    this._subs.add(
      this.client.getCommentsCount(this.clientId).subscribe(
        (response) => {
          this.totalNumberOfComments = response.result;
        },
        (error) => {
          console.error('Error prilikom prikupljanja komentara:', error);
        },
      ),
    );
  }

  loadComments() {
    this._subs.add(
      this.client.getComments(this.clientId).subscribe((response) => {
        this.comments = response.result.commentItems;
        this.isLoading = false;
      }),
    );
  }

  _handleSave() {
    if (!this.form?.valid) {
      markFormGroupTouched(this.form);
      this._alertService.addWarnMsg('Potrebno je uneti sva polja!');
      return;
    }
    
    const commentText = this.form.value.text;
    this._subs.add(
      this.client
        .addComment(
          new AddCommentCommand({
            comment: commentText,
            clientId: this.clientId,
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result.message);
          this._dialogRef.close(res.result.commentCount);
        }),
    );
  }

  ngOnDestroy(): void {
    this.form?.reset();
    this._subs.unsubscribe();
  }
}
