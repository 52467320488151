<p-sidebar [(visible)]="showSidebar" position="right" styleClass="w-27rem left-rounded">
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <i *ngIf="showBackArrow" class="pi pi-arrow-left mr-2 cursor-pointer" (click)="goBack()"></i>
        <div class="text-xl font-semibold">Asistencasdedasdija</div>
    </div>
      </ng-template>
      <ng-container *ngIf="previewData">
        <div class="flex justify-content-between">
          <div class="table-badge" [ngClass]="'packages-' + previewData.details.statusBadge.value">
            <span class="dot"></span>
            <span> {{ previewData.details.statusBadge.label }}</span>
          </div>
          <div class="subtitle-sidebar">
            <i class="pi pi-calendar"></i>
            <span class="ml-1 sidebar-info">{{previewData.details.createdOn}}</span>
          </div>
        </div>
        <div class="mt-3 main-title-sidebar">{{previewData.details.userVehicle}}</div>
        <div class="mt-1 subtitle-info">{{previewData.details.userVehicleType}} • {{previewData.details.userVehicleLicensePlate}}</div>
        <div class="flex flex-column mt-5 gap-2 flex-1">
        <p-tabView class="tabView" [(activeIndex)]="activeIndex!">
          <p-tabPanel header="Detalji">
            <atlas-details-tab-sidebar [previewData]="previewData"></atlas-details-tab-sidebar>
          </p-tabPanel>
          <p-tabPanel header="Opis">
            <atlas-description-tab-sidebar [previewData]="previewData"></atlas-description-tab-sidebar>
          </p-tabPanel>
          <p-tabPanel header="Putanja">
            <atlas-path-tab-sidebar [previewData]="previewData"></atlas-path-tab-sidebar>
          </p-tabPanel>
        </p-tabView>
      </div>
      </ng-container>
</p-sidebar>
