import {Component, OnDestroy, OnInit} from '@angular/core';
import {firstValueFrom, Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {AssistancesClient, IFailureTypeDropdownItem,} from '@shared/model/atlas.api';
import {DropdownComponent} from '@ui/dropdown/dropdown.component';
import {AssistanceFormService} from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';

@Component({
  selector: 'atlas-failure-desc-dropdown',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './failure-desc-dropdown.component.html',
  styleUrl: './failure-desc-dropdown.component.scss',
})
export class FailureDescDropdownComponent implements OnInit, OnDestroy {
  searchOptions: IFailureTypeDropdownItem[] = [];
  failureNames: string[] = [];
  private _subs: Subscription;

  failureTypeCtrl: FormControl<string>;
  //  vehicles: IFailureTypeDropdownItem[] | undefined;
  // clientId: string | undefined = undefined;

  constructor(
    private _client: AssistancesClient,
    private formService: AssistanceFormService,
  ) {
    //this.failureTypeCtrl = new FormControl<string>('');

    this.failureTypeCtrl = formService.getForm.controls.failureDescription;
    this._subs = new Subscription();
  }

  ngOnInit() {
    this._load();

    this._subs.add(
      this.failureTypeCtrl.valueChanges.subscribe((value: string) => {
        if (!value) {
          this.failureNames = [...this.searchOptions.map((x) => x.label)];
        } else {
          this.failureNames = [...this.searchOptions.filter(x => x.label.toLowerCase().includes(value.toLowerCase())).map(x => x.label)]
        }
      }),
    );
  }

  private _load() {
    this._getFailures().then((res) => {
      this.searchOptions = res.result.responseList;
      this.failureNames = res.result.responseList.map((x) => x.label);
    });
  }

  private async _getFailures() {
    return await firstValueFrom(this._client.getFailureTypeDropdown());
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
