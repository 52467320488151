import {Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {ButtonComponent, ButtonSeverity, ButtonStyle} from '@ui/button/button.component';
import {MenuItem} from "primeng/api";
import {StepsModule} from "primeng/steps";
import {NgClass, NgIf, NgTemplateOutlet} from "@angular/common";
import {ScrollPanelModule} from "primeng/scrollpanel";

@Component({
  selector: 'atlas-scrollable-page-with-steps',
  standalone: true,
  imports: [
    StepsModule,
    ButtonComponent,
    NgClass,
    ScrollPanelModule,
    NgTemplateOutlet,
    NgIf
  ],
  templateUrl: './scrollable-page-with-steps.component.html',
  styleUrl: './scrollable-page-with-steps.component.scss'
})
export class ScrollablePageWithStepsComponent {
  @Input() shouldDisplayBack: boolean;
  @Input() shouldDisplayFinish: boolean;

  @Input() submitBtnLabel: string;
  @Input() pageTitle: string;
  @Input() isGray: boolean;
  @Input() stepItems: MenuItem[] | undefined;
  @Output() onSubmitAction = new EventEmitter();
  @Output() onCancelAction = new EventEmitter();
  @Output() onNextAction = new EventEmitter<number>();
  @Output() onBackAction = new EventEmitter<number>();

  @ContentChild('content') content!: TemplateRef<ElementRef>;

  protected readonly ButtonSeverity = ButtonSeverity;

  protected readonly ButtonStyle = ButtonStyle;

  handleCancelForm() {
    this.onCancelAction.emit();
  }

  handleSubmitForm() {
    this.onSubmitAction.emit();
  }

  handleNext() {
    this.onNextAction.emit()
  }

  handleBack() {
      this.onBackAction.emit();
  }
}
