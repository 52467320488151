<div class="flex flex-column gap-3">
  <div class="flex flex-column gap-2">
    <label>Status uplate</label>
    <atlas-payment-status-dropdown
      [statusCtrl]="statusCtrl | formControl"
    ></atlas-payment-status-dropdown>
  </div>

  <atlas-calendar
    ctrlLabel="Datum aktivacije"
    [formCtrl]="activationDateCtrl"
  ></atlas-calendar>

    <!-- Note: ipak su rekli agenti da im ne treba ova info na formi  @see AT-589 -->
<!--  <div *ngFor="let type of activationType">-->
<!--    <p-radioButton-->
<!--      [formControl]="isurgentCtrl"-->
<!--      [value]="type.value"-->
<!--    ></p-radioButton>-->
<!--    <label class="ml-3" [for]="type.label">{{ type.label }}</label>-->
<!--  </div>-->
</div>
