import { filter, pairwise } from 'rxjs/operators';
import { NavigationExtras, Router, RoutesRecognized } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  public previousUrl = '';
  private directionsService: any;

  constructor(
    private _router: Router,
    private _location: Location,
    // @Vukasin ToDo: ne zelimo dependency na neki servis
  ) {
    _router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise(),
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
      });
  }

  private _routeToUrl(url: string, navigationData?: NavigationExtras) {
    this._router.navigate([url], navigationData).then();
  }

  routeBack() {
    this._location.back();
  }

  route(
    request: google.maps.DirectionsRequest,
  ): Observable<google.maps.DirectionsResult> {
    return new Observable((observer) => {
      this.directionsService.route(request, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          observer.next(result);
        } else {
          observer.error(status);
        }
        observer.complete();
      });
    });
  }

  routeToSettings() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', 'settings']),
    );
    return this._routeToUrl(url);
  }

  //#region Authentication routes

  routeToLogin(returnUrl?: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/authentication', 'login'], {
        queryParams: { returnUrl: returnUrl },
      }),
    );
    this._routeToUrl(url);
  }

  routeToRegister() {
    this._routeToUrl('/authentication/register');
  }

  routeToForgotPass() {
    this._routeToUrl('/authentication/forgot-password');
  }

  //#endregion Authentication routes

  //#region Partner routes

  routeToAddPartnerForm(rolePrefix: string, navigationData?: NavigationExtras) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', rolePrefix, 'partners', 'add-partner']),
    );
    return this._routeToUrl(url, navigationData);
  }

  routeToEditPartnerForm(
    id: string,
    rolePrefix: string,
    navigationData?: NavigationExtras,
  ) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        rolePrefix,
        'partners',
        'edit-partner',
        id,
      ]),
    );
    return this._routeToUrl(url, navigationData);
  }

  routeToPartnerInfoPage(id: string, rolePrefix: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        rolePrefix,
        'partners',
        'partner-info',
        id,
      ]),
    );
    return this._routeToUrl(url);
  }

  routeToPartnerTable(rolePrefix: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', rolePrefix, 'partners']),
    );
    return this._routeToUrl(url);
  }

  //#endregion Partner

  //#region client packages

  routeToRenewPackageForm(rolePrefix: string, cpId?: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        rolePrefix,
        'clients',
        'renew-package',
        cpId,
      ]),
    );
    return this._routeToUrl(url);
  }

  routeToBuyPackageForVehicle(
    vehicleId: string,
    vehicleName: string,
    clientId: string,
    rolePrefix: string,
  ) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        rolePrefix,
        'clients',
        'buy-package-vehicle',
        vehicleId,
        vehicleName,
        clientId,
      ]),
    );
    return this._routeToUrl(url);
  }

  routeToClientsTable(rolePrefix: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', rolePrefix, 'clients']),
    );
    return this._routeToUrl(url);
  }

  routeToClientInfo(clientId: string, clientName: string, rolePrefix: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', rolePrefix, 'clients', 'client-info', clientId, clientName]),
    );
    return this._routeToUrl(url);
  }

  routeToCreateClient(rolePrefix: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        rolePrefix,
        'clients',
        'create',
        'step-1',
      ]),
    );

    return this._router.navigateByUrl(url);
  }

  routeToAddVehicleToClient(clientId: string, rolePrefix: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', rolePrefix, 'clients', 'add-vehicle', clientId]),
    );
    return this._routeToUrl(url);
  }

  //#endregion
}
