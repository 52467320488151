import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DropdownModule} from "primeng/dropdown";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {firstValueFrom, Subscription} from "rxjs";
import {
  IVehicleBrandItem, VehicleBrandItem,
  VehiclesClient
} from "@shared/model/atlas.api";
import {NewVehicleService} from "@app/features/services/new-vehicle-service";

@Component({
  selector: 'atlas-brand-dropdown',
  standalone: true,
  imports: [
    DropdownModule,
    ReactiveFormsModule
  ],
  templateUrl: './brand-dropdown.component.html',
  styleUrl: './brand-dropdown.component.scss'
})
export class BrandDropdownComponent implements OnInit, OnChanges, OnDestroy
{
  private _subs: Subscription;
  brands: IVehicleBrandItem[] = [];
  shouldResetInput: boolean = false;
  selectedBrandCtrl: FormControl<IVehicleBrandItem> = new FormControl();

  @Input() typeId: string | undefined = undefined;
  @Input() isDisabled: boolean = true;
  @Input() brandNameCtrl: FormControl<string>;

  @Output() public onBrandChanged = new EventEmitter<IVehicleBrandItem>();

  constructor(
      private _client: VehiclesClient,
      public newVehicleService: NewVehicleService,
  ) {
    this._subs = new Subscription();
  }

  ngOnInit() {
    this._subs.add(
        this.newVehicleService.shouldResetBrandInput$.subscribe((shouldReset) => {
          if (shouldReset) {
            this.selectedBrandCtrl.reset(); 
          }
        }),
    );
    this._subs.add(this.selectedBrandCtrl.valueChanges.subscribe((value: IVehicleBrandItem | string) => {
      if (typeof value === 'string' || value instanceof String) {
        this.brandNameCtrl.patchValue(value as string);
        this.newVehicleService.setBrandNameCtrl(value as string);
        this.onBrandChanged.emit(new VehicleBrandItem({
          name: value as string,
          brandId: undefined
        }));
      } else {
        this.brandNameCtrl.patchValue(value.name);
        this.newVehicleService.setBrandNameCtrl(value.name);
        this.onBrandChanged.emit(value);
      }
    }));
    this.newVehicleService.setBrandNameCtrl(this.brandNameCtrl.value);
    this.newVehicleService.setBrandNameRequired();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['typeId']?.currentValue !== changes['typeId']?.previousValue) {
      this.brands = [];
      this._load();
    }
    if (
        changes['brandNameCtrl']?.currentValue !== changes['brandNameCtrl']?.previousValue
    ) {
      this.newVehicleService.setBrandNameCtrl(this.brandNameCtrl.value);
    }
  }

  private _load() {
    this._getBrands().then((res) => {
      this.brands = [...res.result.responseList];
    });
  }

  private async _getBrands() {
    return await firstValueFrom(
        this._client.getVehicleBrands(this.typeId),
    );
  }

  ngOnDestroy() {
    this.newVehicleService.resetCtrl();
    this.shouldResetInput = true;
    this._subs.unsubscribe();
  }
}