<div class="vehicle-card">
  <div class="flex justify-content-between">
    <div class="flex">
      <p class="vehicle-name mt-0 mb-2 mr-2">
        {{ vehicle.brand }}
        {{ vehicle.model }} &#8226;
      </p>
      <p class="licence-plate mt-0 mb-2">
        {{ vehicle.licencePlate | licencePlate }}
      </p>
    </div>

    <div class="mt-1">
      <i
        class="pi pi-ellipsis-v cursor-pointer"
        (click)="vehicleCardPop($event)"
      ></i>

      <p-overlayPanel #cardActionPanel>
        <ng-template pTemplate>
          <div class="flex flex-column gap-2 action-wrapper">
            <atlas-button
              [btnLabel]="'Izmeni'"
              [btnIcon]="'pi pi-pencil'"
              (btnClicked)="handleEditVehicle()"
            ></atlas-button>
            <atlas-button
              [btnLabel]="'Obrisi'"
              [btnIcon]="'pi pi-trash'"
              (btnClicked)="handleRemoveVehicle()"
            ></atlas-button>
          </div>
        </ng-template>
      </p-overlayPanel>
    </div>
  </div>
  <p class="vehicle-address mt-0 mb-0">
    {{ vehicle.place }}
  </p>
  <div class="flex">
    <p class="pi pi-wallet wallet-icon"></p>
    <p class="card-price ml-2">{{ vehicle.pricePerKm }} rsd/km</p>
  </div>
</div>
