import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientClient, OrdersClient } from '@shared/model/atlas.api';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-agent-autocomplete',
  standalone: true,
  imports: [InputSearchComponent],
  templateUrl: './agent-autocomplete.component.html',
  styleUrl: './agent-autocomplete.component.scss',
})
export class AgentAutocompleteComponent {
  private _subs: Subscription = new Subscription();
  agents: { id: string; name: string }[] = [];
  agentNames: string[] = [];
  @Output() agentSelected = new EventEmitter<{ id: string; name: string }>();
  resetInput: boolean = false;
  selectedId: string;

  @ViewChild('inputSearch', { static: false })
  inputSearch: InputSearchComponent;

  constructor(private _client: OrdersClient) {}

  handleSearch(item: any) {
    this.loadServices(item);
  }

  private loadServices(searchValue: string = '') {
    this._subs.add(
      this._client.getAgentsAutocomplete().subscribe((res) => {
        if (res?.result) {
          this.agents = res.result
            .filter((agent: any) =>
              agent.name.toLowerCase().includes(searchValue.toLowerCase()),
            )
            .map((agent: any) => ({
              id: agent.id,
              name: agent.name,
            }));

          this.agentNames = this.agents.map((agent) => agent.name);
        } else {
          this.agents = [];
          this.agentNames = [];
        }
      }),
    );
  }

  onSelectChange(item: any) {
    if (!item || !item.value) {
      return;
    }
    const selectedAgent = this.agents.find(
      (agent) => agent.name === item.value,
    );
    if (selectedAgent) {
      this.agentSelected.emit({
        id: selectedAgent.id,
        name: selectedAgent.name,
      });
    }
  }
}
