import {
  CommonModule,
  NgClass,
  NgFor,
  NgForOf,
  NgIf,
  NgOptimizedImage,
} from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {
  AddPackageForm,
  OrderFormService,
} from '@app/cc-leader/services/order-form.service';
import {PackageCardListService} from '@app/cc-leader/services/package-card-list.service';
import {PackageCardListComponent} from '@app/features/packages/package-card-list/package-card-list.component';
import {MediumsDropdownComponent} from '@shared/components/mediums-dropdown/mediums-dropdown.component';
import {ScrollablePageComponent} from '@shared/components/scrollable-page/scrollable-page.component';
import {SourcesDropdownComponent} from '@shared/components/sources-dropdown/sources-dropdown.component';
import {ControlsOf, markFormGroupTouched} from '@shared/forms/forms-common';
import {
  AddPackageToVehicleCommand,
  IPackageCardItem,
  OrdersClient,
  VehiclesClient,
  VehicleTypeItem,
} from '@shared/model/atlas.api';
import {CurrencyNumberPipe} from '@shared/pipes/currency-number.pipe';
import {FormControlPipe} from '@shared/pipes/form-control.pipe';
import {AlertService} from '@shared/services/alert.service';
import {LocationService} from '@shared/services/location.service';
import {CheckboxModule} from 'primeng/checkbox';
import {DividerModule} from 'primeng/divider';
import {RadioButtonModule} from 'primeng/radiobutton';
import {SelectButtonModule} from 'primeng/selectbutton';
import {firstValueFrom, Subscription} from 'rxjs';
import {SkeletonModule} from 'primeng/skeleton';
import {PaymentTypeOptionsComponent} from '../payment-type-options/payment-type-options.component';
import {AgentCcRoleStr, B2BAgentRoleStr, LeaderCcRoleStr, UserService} from '@shared/services/user.service';
import {InputTextComponent} from '@ui/input-text/input-text.component';

@Component({
  selector: 'atlas-buy-package-vehicle',
  standalone: true,
  imports: [
    ScrollablePageComponent,
    DividerModule,
    NgIf,
    NgOptimizedImage,
    NgForOf,
    NgFor,
    NgClass,
    RadioButtonModule,
    CommonModule,
    ReactiveFormsModule,
    CheckboxModule,
    FormsModule,
    FormControlPipe,
    SelectButtonModule,
    PackageCardListComponent,
    CurrencyNumberPipe,
    MediumsDropdownComponent,
    SourcesDropdownComponent,
    SkeletonModule,
    PaymentTypeOptionsComponent,
    InputTextComponent
  ],
  templateUrl: './buy-package-vehicle.component.html',
  styleUrl: './buy-package-vehicle.component.scss',
})
export class BuyPackageVehicleComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  clientPackageId: string;
  addPackageForm: AddPackageForm;
  packagesSum: number = 0;
  packages: FormArray<FormGroup<ControlsOf<IPackageCardItem>>>;
  vehicleTypes: VehicleTypeItem[] = [];

  vehicleName: string;
  vehicleId: string;
  clientId: string;
  selectedPackagesCount: number = 0;
  activePackagesCount: number = 0;
  vehicleType: string;
  vehicleTypeId: string;
  isMojPaket: boolean = false;

  activationOptions: any = [
    {
      name: 'Aktivacija odmah (paket se aktivira odmah nakon evidentiranja uplate)',
      value: true,
    },
    {
      name: 'Standardna aktivacija (7 dana od evidentiranja uplate)',
      value: false,
    },
  ];

  constructor(
    private location: LocationService,
    private _ordersClient: OrdersClient,
    private route: ActivatedRoute,
    private _orderFormService: OrderFormService,
    private _packageCardService: PackageCardListService,
    private _userService: UserService,
    private _alertService: AlertService,
    private _vehiclesClient: VehiclesClient
  ) {
    this.vehicleName = decodeURIComponent(this.route.snapshot.paramMap.get('name') || '');
    this.vehicleType = decodeURIComponent(this.route.snapshot.paramMap.get('type') || '');

    this.isMojPaket = this.route.snapshot.routeConfig.path.includes('buy-own-package');
    this.addPackageForm = this._orderFormService.initAddPackageForm();

    if (this.isMojPaket) {
      this.addPackageForm.controls.jmbg.setValidators([Validators.required]);
    }

    this.addPackageForm.controls.clientId.setValue(
      this.route.snapshot.paramMap.get('clientId'),
    );
    this.addPackageForm.controls.vehicleId.setValue(
      this.route.snapshot.paramMap.get('id'),
    );
  }

  ngOnInit(): void {
    this._subs.add(
      this._packageCardService.packages$.subscribe((x) => {
        this.packages = x;
      }),
    );

    this.addPackageForm.controls.deliveryDate.disable();
    this.addPackageForm.controls.deliveryType.disable();
    this.addPackageForm.controls.paymentType.disable();

    this.packages.valueChanges.subscribe((x) => {
      this.packagesSum = x.reduce((a, b) => a + b.price, 0);
    });

    this._setVehicleType(this.vehicleType)
    this.getActivePackagesCount();
  }

  get validPackages() {
    return this.packages.value.filter((pkg) => pkg.id !== null);
  }

  private async getActivePackagesCount() {
    await this._loadActivePackages().then((res) => {
      this.selectedPackagesCount = res.result.totalPackages;
      this.activePackagesCount = res.result.activePackages;
    })
  }

  handleCancelForm() {
    this.location.routeToClientsTable(this._userService.getRoleRoutePrefix(
      AgentCcRoleStr,
      B2BAgentRoleStr,
      LeaderCcRoleStr));
  }

  private async _setVehicleType(typeStr: string) {
    await this._loadTypes().then((res) => {
      this.vehicleTypes = res.result.responseList;
    });

    let selectedType = this.vehicleTypes.find((type) => type.name === typeStr);
    this.vehicleTypeId = selectedType?.typeId
  }

  private async _loadTypes() {
    return await firstValueFrom(this._vehiclesClient.getTypes());
  }

  private async _loadActivePackages() {
    return await firstValueFrom(this._vehiclesClient.getActivePackagesCount(this.route.snapshot.paramMap.get('id')));
  }

  async handleSubmitForm() {
    if (!await this._prepareFormPackages()) {
      return;
    }

    if (this.addPackageForm.value.packageItems.length === 0) {
      return this._alertService.addWarnMsg('Nije odabran nijedan paket');
    }

    if (!this.addPackageForm.valid) {
      markFormGroupTouched(this.addPackageForm);
      return this._alertService.addWarnMsg('Morate popuniti sva polja');
    }

    this.addPackageForm.controls.deliveryDate.enable();
    this.addPackageForm.controls.deliveryType.enable();
    this.addPackageForm.controls.paymentType.enable();

    this._subs.add(
      this._ordersClient
        .addPackageToVehicle(
          this.addPackageForm.value as AddPackageToVehicleCommand,
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this.location.routeToClientsTable(
            this._userService.getRoleRoutePrefix(
              AgentCcRoleStr,
              B2BAgentRoleStr,
              LeaderCcRoleStr),
          );
        }),
    );
  }

  private async _prepareFormPackages(): Promise<boolean> {
    if (this.isMojPaket && !this.addPackageForm.value.jmbg) {
      this.addPackageForm.controls.jmbg.markAsTouched();
      this._alertService.addWarnMsg('Morate uneti JMBG');
      return false;
    }

    this._packageCardService.getPackages().value.forEach((pkg) => {
      this.addPackageForm.controls.packageItems.push(
        this._packageCardService.initPackageCardForm({
          name: pkg.name,
          id: pkg.id,
          price: pkg.price,
          clientPackageId: this.clientPackageId,
        }),
      );
    });

    return true;
  }

  ngOnDestroy(): void {
    this._packageCardService.clearAllPackages();
    this._subs.unsubscribe();
  }
}
