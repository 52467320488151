import { Component, Input } from '@angular/core';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { AtlasDatePipe } from '@shared/pipes/date.pipe';
import { AssistanceSidebarComponent } from '@app/sos-agent/assistances/assistance-sidebar/assistance-sidebar.component';
import { LocationSosService } from '@shared/services/location-sos.service';
import { ClientPackageTableService } from '../../services/client-package-table.service';

@Component({
  selector: 'atlas-service-availability',
  standalone: true,
  imports: [NgOptimizedImage, NgIf, NgClass, AtlasDatePipe, AssistanceSidebarComponent],
  templateUrl: './service-availability.component.html',
  styleUrl: './service-availability.component.scss',
})
export class ServiceAvailabilityComponent {
  @Input() isAvailable!: boolean;
  @Input() serviceName!: string;
  @Input() usedDate?: Date;
  @Input() assistanceId?: string;
  showSidebar: boolean = true;
  
  constructor(private service: ClientPackageTableService){

  }

  formatDate(dateString: string): string {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  }

  openSidebar() {
    if (this.assistanceId) {
      this.service.showPreviewSidebar(this.assistanceId, true);
    }
  }
}
