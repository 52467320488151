<ng-container *ngIf="previewData">
  <div class="flex flex-column gap-3 package-box">
    <div class="flex align-items-center">
      <div>
        <img
          *ngIf="previewData.imageUrl"
          [ngSrc]="previewData.imageUrl"
          width="65"
          height="55"
          alt="package-image"
        />
      </div>
      <div *ngIf="!previewData.imageUrl">
        <i class="pi pi-image icon"></i>
      </div>
      <div class="flex-grow-1 ml-2">
        <div class="package-title">{{ previewData?.name }}</div>
        <div class="flex align-items-center" style="margin-top: 5px">
          <i class="pi pi-tag text-small"></i>
          <div class="text-small">{{ previewData?.price }}</div>
        </div>
      </div>
      <div>
        <p-inputSwitch
          [(ngModel)]="previewData.isActive"
          (click)="changePackageStatus()"
        ></p-inputSwitch>
      </div>
    </div>
  </div>
  <div class="flex flex-column mt-5 gap-2 flex-1 package-content"
  >
    <p-tabView class="tabView" [(activeIndex)]="activeIndex!">
      <p-tabPanel header="Detalji paketa">
        <div class="flex align-items-center">
          <span class="text-md font-semibod">Detalji</span>
        </div>
        <div class="flex align-items-center">
          <span class="text-semiTitle"> Opis </span>
        </div>
        <div class="flex align-items-center detail-description">
          <span class="text-small desc mt-2">
            {{ previewData?.description || "--" }}
          </span>
        </div>
        <div class="flex align-items-center">
          <span class="text-small mt-3">Paket je omogućen vozilima</span>
        </div>
        <div class="type-services-container mt-2">
          <div
            *ngFor="let type of previewData?.vehicleTypes"
            class="type-badge mt-2"
          >
            <span class="mr-2 text-sm">{{ type }}</span>
          </div>
        </div>
        <div class="flex align-items-center">
          <span class="text-small mt-3">Glavne usluge paketa</span>
        </div>
        <div class="type-services-container mt-2">
          <div
            *ngFor="let type of previewData?.services"
            class="type-services mt-2"
          >
            <i class="pi pi-check"></i>
            <span class="mr-2 text-sm">{{ type }}</span>
          </div>
        </div>
        <div class="flex align-items-center">
          <span class="text-small mt-3">Dodatne usluge</span>
        </div>
        <div class="type-services-container mt-2">
          <div
            *ngFor="let type of previewData?.additionalServices"
            class="type-services mt-2"
          >
            <i class="pi pi-check"></i>
            <span class="mr-2 text-sm">{{ type }}</span>
          </div>
        </div>
        <div class="flex align-items-center">
          <span class="text-small mt-3">Obavezan broj šasije</span>
        </div>
        <div class="flex align-items-center">
          <span class="text-sm mt-2">{{ previewData?.isChassisRequired }}</span>
        </div>
        <div class="flex align-items-center">
          <span class="text-small mt-3">Obavezan JMBG broj</span>
        </div>
        <div class="flex align-items-center">
          <span class="text-sm mt-2">{{
            previewData?.isNationalNumberRequired
          }}</span>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Akcijske cene">
        <div class="actions mt-2">
          <span class="allActions"> Sve akcije </span>
          <span class="addAction">
            <i class="pi pi-plus"></i> Dodaj akciju
          </span>
        </div>
        <div *ngFor="let actionPrice of previewData?.actionPrices">
          <div *ngIf="actionPrice?.isActive" class="action-box mt-3 mb-2">
            <div class="action-content">
              <div class="details">
                <div class="text-medium">
                  {{ actionPrice?.name || "No action name" }}
                </div>
                <div class="desc">{{ actionPrice?.price || "--" }}</div>
              </div>
              <div style="transform: scale(0.7)">
                <p-inputSwitch
                  [(ngModel)]="actionPrice.isActive"
                  (click)="changePriceStatus(actionPrice.id)"
                ></p-inputSwitch>
              </div>
              <span
                *ngIf="actionPrice.isActive"
                (click)="confirmDelete($event, actionPrice.id)"
                class="delete-package"
              >
                <i class="pi pi-trash"></i>
              </span>
              <p-confirmPopup #cp></p-confirmPopup>
              <span
                *ngIf="!actionPrice.isActive"
                class="delete-package-placeholder"
              ></span>
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</ng-container>