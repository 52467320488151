import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonComponent, ButtonSeverity, ButtonStyle} from "@ui/button/button.component";
import {DropdownComponent} from "@ui/dropdown/dropdown.component";
import {InputTextComponent} from "@ui/input-text/input-text.component";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'atlas-input-button',
  standalone: true,
    imports: [
        ButtonComponent,
        DropdownComponent,
        InputTextComponent
    ],
  templateUrl: './input-button.component.html',
  styleUrl: './input-button.component.scss'
})
export class InputButtonComponent {
    @Input() searchCtrl: FormControl;
    @Output() onAction = new EventEmitter();


    handleAction() {
        this.onAction.emit();
    }

    protected readonly ButtonSeverity = ButtonSeverity;
    protected readonly ButtonStyle = ButtonStyle;
}
