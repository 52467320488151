import {Component, Input} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {NgIf} from '@angular/common';

@Component({
  selector: 'atlas-error-min',
  standalone: true,
  imports: [NgIf],
  templateUrl: './error-min.component.html',
  styleUrl: './error-min.component.scss',
})
export class ErrorMinComponent {
  /** Props */
  errorMsg = 'Obavezno polje';

  /** I/O */
  @Input() formCtrl!: FormControl<any>;
  protected readonly Validators = Validators;
}
