import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root',
  })
  export class ActiveFiltersService {
    private _activeFilters = new BehaviorSubject<{ key: string; label: string }[]>([]);
    activeFilters$ = this._activeFilters.asObservable();
  
    constructor() {}
  
    setFilter(key: string, label: string) {
      const currentFilters = this._activeFilters.value;
      const existingFilterIndex = currentFilters.findIndex((filter) => filter.key === key);
  
      if (existingFilterIndex !== -1) {
        currentFilters[existingFilterIndex] = { key, label };
      } else {
        currentFilters.push({ key, label });
      }
  
      this._activeFilters.next([...currentFilters]);
    }
  
    removeFilter(key: string) {
      const updatedFilters = this._activeFilters.value.filter((filter) => filter.key !== key);
      this._activeFilters.next(updatedFilters);
    }
  
    clearFilters() {
      this._activeFilters.next([]);
    }
  }