import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from '@shared/components/loader/loader.service';
import { ControlsOf, markFormGroupTouched } from '@shared/forms/forms-common';
import { CreateSourceCommand, EditSourceCommand, ICreateSourceCommand, SourcesClient } from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { SkeletonModule } from 'primeng/skeleton';
import { finalize, Subscription } from 'rxjs';

@Component({
  selector: 'atlas-source-form',
  standalone: true,
  imports: [
    SkeletonModule,
    InputTextComponent
  ],
  templateUrl: './source-form.component.html',
  styleUrl: './source-form.component.scss'
})
export class SourceFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  
  sourceForm: FormGroup<ControlsOf<ICreateSourceCommand>>;

  constructor(
    private _dialogConfig: DialogConfig,
    private _dialogRef: DialogRef,
    private _client: SourcesClient,
    private _loaderService: LoaderService,
    private _alertService: AlertService,
    private _fb: FormBuilder
  ) {
    this.sourceForm = undefined;
    this.sourceForm = this._initForm(this._dialogConfig.data);
  }

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleSubmit();
      }),
    );
  }

  private _handleSubmit() {
      if (this.sourceForm.invalid) {
        markFormGroupTouched(this.sourceForm);
        return;
      }
  
      this._loaderService.setShowLoader = true;
      this._dialogConfig.styleClass = 'display: hidden';
  
      if (this.sourceForm.value.id) {
        this._handleEdit();
      } else {
        this._handleCreate();
      }
    }

    private _handleCreate() {
        this._subs.add(
          this._client
            .createSource(this.sourceForm.value as CreateSourceCommand)
            .pipe(
              finalize(() => {
                this._loaderService.reset();
                this._dialogConfig.styleClass = 'display: block';
              }),
            )
            .subscribe((res) => {
              this._alertService.addSuccessMsg(res.result);
              this._dialogRef.close(true);
            }),
        );
      }

    private _handleEdit() {
        this._subs.add(
          this._client
            .editSource(this.sourceForm.value as EditSourceCommand)
            .pipe(
              finalize(() => {
                this._loaderService.reset();
                this._dialogConfig.styleClass = 'display: block';
              }),
            )
            .subscribe((res) => {
              this._alertService.addSuccessMsg(res.result);
              this._dialogRef.close(true);
            }),
        );
      }

  private _initForm(model?: ICreateSourceCommand) {
    return this._fb.group<ControlsOf<ICreateSourceCommand>>({
        id: this._fb.control(model?.id),
        name: this._fb.control(model?.name, {
          validators: [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(32),
          ],
        })
      });
  }

  ngOnDestroy() {
    this.sourceForm = undefined;
    this._dialogConfig.data = undefined;
    this._subs.unsubscribe();
  }

}
