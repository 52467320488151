import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageComponent } from '@shared/components/page/page.component';
import { PageInfoCardComponent } from '@ui/page-info-card/page-info-card.component';
import { TabViewModule } from 'primeng/tabview';
import { Subscription } from 'rxjs';
import {
  PackagesClient,
  VehiclesClient,
  VehicleTypeItem,
} from '@shared/model/atlas.api';
import { CommonModule } from '@angular/common';
import { PackageCardComponent } from '@ui/package-card/package-card.component';
import { LocationService } from '@shared/services/location.service';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { PackagePreviewComponent } from '../package-preview/package-preview.component';
import { TableComponent } from '@ui/table/table.component';
import { TableConfig } from '@ui/table/table-common';
import { ButtonModule } from 'primeng/button';
import { PackageService } from '@app/administrator/services/package-service';
import { DialogService } from '@ui/dialog/dialog.service';
import { SkeletonModule } from 'primeng/skeleton';
import { LocationAdminService } from '@shared/services/location-admin.service';
import { AlertService } from '@shared/services/alert.service';
import { LoaderService } from '@shared/components/loader/loader.service';

@Component({
  selector: 'atlas-package-table',
  standalone: true,
  imports: [
    PageComponent,
    PageInfoCardComponent,
    TabViewModule,
    CommonModule,
    PackageCardComponent,
    TableComponent,
    ButtonModule,
    SkeletonModule,
  ],
  templateUrl: './package-table.component.html',
  styleUrl: './package-table.component.scss',
})
export class PackageTableComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  allPackages: any[] = [];
  activePackagesCount: number | undefined;
  protected tableConfig: TableConfig;
  vehicleTypes: VehicleTypeItem[] = [];
  selectedVehicleType: string | undefined;
  skeletonItems: any[] = [];
  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL_MEDIUM);

  protected active: boolean | undefined;
  public activeIndex: number | undefined;

  constructor(
    private _packageClient: PackagesClient,
    private _vehiclesClient: VehiclesClient,
    private _locationAdminService: LocationAdminService,
    private _dialogService: DialogService,
    private _packageService: PackageService,
    private _alertService: AlertService,
    private _loaderService: LoaderService,
  ) {
    this._subs = new Subscription();
    this.allPackages = [];
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.skeletonItems = Array.from({ length: 9 }).map((_, i) => i);
  }

  ngOnInit(): void {
    this._loadPackages();
    this._loadVehicleTypes();
  }

  handleTabChange(tabIndex: number) {
    switch (tabIndex) {
      case 0:
        this.selectedVehicleType = undefined;
        break;
      case 1:
        this.selectedVehicleType = this.vehicleTypes.find(
          (v) => v.name === 'Putnička vozila',
        )?.typeId;
        break;
      case 2:
        this.selectedVehicleType = this.vehicleTypes.find(
          (v) => v.name === 'Kombi',
        )?.typeId;
        break;
      case 3:
        this.selectedVehicleType = this.vehicleTypes.find(
          (v) => v.name === 'Motori',
        )?.typeId;
        break;
    }
    this._loadPackages();
  }

  handleAddPackage() {
    this._locationAdminService.routeToPackageForm();
  }

  previewPackage(id: any) {
    this._packageService.setPackageId(id);
    this.dialogConfig.header = 'Detalji paketa';
    this.dialogConfig.customSubmitButton = {
      label: 'Izmeni',
      icon: '',
    };
    this.dialogConfig.customButton = {
      label: 'Obriši',
      icon: 'pi pi-trash',
      class: 'p-button-text max-w-5rem',
    };
    this.dialogConfig.hideCancel = true;
    this.dialogConfig.closable = true;
    const dialogRef = this._dialogService.open(
      PackagePreviewComponent,
      this.dialogConfig,
    );

    this._subs.add(
      dialogRef.onClose.subscribe((shouldReload: boolean) => {
        if (shouldReload) {
          this._loadVehicleTypes();
          this._loadPackages();
        }
      }),
    );
  }

  private _loadVehicleTypes() {
    this._subs.add(
      this._vehiclesClient.getVehicleTypes().subscribe((res) => {
        this.vehicleTypes = res.result.responseList!;
      }),
    );
  }

  private _loadPackages() {
    this._subs.add(
      this._packageClient
        .getAllPackages(this.selectedVehicleType)
        .subscribe((res) => {
          this.allPackages = res.result.responseList!;
          this.activePackagesCount = this.allPackages.filter(
            (x) => x.isActive,
          ).length;
        }),
    );
  }

  handleSyncPackages() {
    this._loaderService.setLoadingText = 'Sinhronizacija paketa...';
    this._loaderService.setShowLoader = true;

    this._subs.add(
      this._packageClient.syncArticles().subscribe( res => {
        this._alertService.addSuccessMsg(res.result);
        this._loadPackages();
        this._loaderService.reset();
      })
    )
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
