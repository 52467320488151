<atlas-multiselect
  [formGroup]="formGroup"
  formCtrlName="mediums"
  [options]="mediumOptions"
  optionLabel="name"
  ctrlLabel="Mediji"
  ctrlDataKey="id"
  ctrlPlaceholder="Odaberi medije"
  ctrlStyleClass="w-full"
  [canFilter]="true"
/>
