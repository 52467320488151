import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { DialogRef } from '@ui/dialog/dialog-ref';
import {
  AssistancesClient,
  SendGuaranteeCommand,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { LoaderService } from '@shared/components/loader/loader.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import {
  GuaranteeForm,
  GuaranteeFormService,
} from '@app/sos-agent/assistances/guarantee-form/guarantee-form.service';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { DividerModule } from 'primeng/divider';
import { NgClass, NgIf } from '@angular/common';
import { BlockUIModule } from 'primeng/blockui';
import { markFormGroupTouched } from '@shared/forms/forms-common';

@Component({
  selector: 'atlas-guarantee-form',
  standalone: true,
  imports: [
    InputTextComponent,
    SelectButtonModule,
    FormControlPipe,
    ReactiveFormsModule,
    DividerModule,
    NgIf,
    BlockUIModule,
    NgClass,
  ],
  templateUrl: './guarantee-form.component.html',
  styleUrl: './guarantee-form.component.scss',
})
export class GuaranteeFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  form: GuaranteeForm;

  stateOptions: any[] = [
    { label: 'Srpskom', value: false },
    { label: 'Engleskom', value: true },
  ];

  constructor(
    private _dialogConfig: DialogConfig,
    private _dialogRef: DialogRef,
    private client: AssistancesClient,
    private alert: AlertService,
    private _loaderService: LoaderService,
    private _formService: GuaranteeFormService,
  ) {
    this.form = this._formService.getForm(this._dialogConfig.data['id']);
  }

  ngOnInit() {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleSend();
      }),
    );
  }

  private handleSend() {
    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      return;
    }

    this._dialogConfig.styleClass = 'display: hidden';

    this._loaderService.setLoadingText = 'Garancija se šalje...';
    this._loaderService.setShowLoader = true;

    this._sendGuarantee().then((res) => {
      this._loaderService.reset();
      this.alert.addSuccessMsg(res.result.value);
      this._dialogRef.close();
      this._dialogConfig.styleClass = 'display: block';
    });
  }

  private async _sendGuarantee() {
    return await firstValueFrom(
      this.client.sendGuarantee(this.form.value as SendGuaranteeCommand),
    );
  }

  ngOnDestroy(): void {
    this._formService.resetForm();
    this._subs.unsubscribe();
  }
}
