<atlas-scrollable-page
  pageTitle="Detalji kampanje"
  cancelBtnIcon="back"
  (onCancelAction)="routeToTable()"
>
  <ng-template #content>
    <ng-container *ngIf="campaignData; else skeleton">
      <div class="campaign-header">
        <div class="title-with-icon">
          <h1>{{ campaignData.title }}</h1>
          <i
            (click)="toggleDetails()"
            class="pi"
            [ngClass]="showDetails ? 'pi-chevron-up' : 'pi-chevron-down'"
            aria-label="Toggle Details"
          ></i>
        </div>
        <div class="actions cursor-pointer">
          <p-inputSwitch
            [(ngModel)]="campaignData.isActive"
            (click)="deactivateCamapign(campaignId, campaignData.isActive)"
            [disabled]="true"
          ></p-inputSwitch>
          <i
            *ngIf="campaignData.type === 1"
            (click)="onInfoEllipsis($event, overlayPanelInfo)"
            [ngClass]="'pi pi-ellipsis-v'"
          ></i>
          <i
            *ngIf="campaignData.type === 0"
            (click)="onInfoEllipsis($event, overlayPanelInfoLeads)"
            [ngClass]="'pi pi-ellipsis-v'"
          ></i>
        </div>
      </div>

      <div class="campaign-info-box mt-5" *ngIf="showDetails">
        <div class="info-column" style="flex-basis: 40%">
          <div class="info-item">
            <i class="pi pi-check-circle"></i>
            <span class="info-title">Aktivna</span>
            <span class="info-value">{{
              campaignData.status === 0 ? "Da" : "Ne"
            }}</span>
          </div>
          <div class="info-item">
            <i class="pi pi-info-circle"></i>
            <span class="info-title">Status</span>
            <span
              class="status-badge"
              [ngClass]="getCampaignClass(campaignData.status)"
            >
              <span class="status-dot"></span>
              {{ getCampaignStatusText(campaignData.status) }}
            </span>
          </div>
          <div *ngIf="campaignData.type === 1 || campaignData.type === 2" class="info-item">
            <i class="pi pi-users"></i>
            <span class="info-title">Ukupna baza</span>
            <span class="info-value">{{ campaignData.contractCount }}</span>
          </div>
          <div *ngIf="campaignData.type === 0" class="info-item">
            <i class="pi pi-flag"></i>
            <span class="info-title">Prioritet</span>
            <div class="info-content info-value">
              <i
                class="pi pi-flag-fill"
                [ngStyle]="{ color: getFlagColor(campaignData.priority) }"
              ></i>
              <span class="">{{ getPriorityText(campaignData.priority) }}</span>
            </div>
          </div>
          <div class="info-item">
            <i class="pi pi-pencil"></i>
            <span class="info-title">Opis</span>
            <span
              class="info-value description"
              pTooltip="{{ campaignData.description }}"
              tooltipPosition="top"
            >
              {{ campaignData.description || "+ Dodaj opis" }}
            </span>
          </div>
        </div>

        <div class="info-column" style="flex-basis: 60%">
          <div *ngIf="campaignData.type === 0" class="info-item">
            <i class="pi pi-users"></i>
            <span class="info-title">Ukupna baza</span>
            <span class="info-value">{{ campaignData.leadsCount }}</span>
          </div>
          <div class="info-item">
            <i class="pi pi-calendar"></i>
            <span class="info-title">Datum kreiranja</span>
            <span class="info-value">{{
              campaignData.createdOn | date: "dd MMMM yyyy • HH:mm"
            }}</span>
          </div>
          <div *ngIf="campaignData.type === 1" class="info-item">
            <i class="pi pi-clock"></i>
            <span class="info-title">Trajanje kampanje</span>
            <span class="info-value">{{
              campaignData.endsOn | date: "dd MMMM yyyy • HH:mm"
            }}</span>
          </div>
          <div class="info-item">
            <i class="pi pi-user"></i>
            <span class="info-title">Kreirao/la</span>
            <div class="info-content info-value">
              <img
                [ngSrc]="
                  campaignData.createdBy.imageUrl ||
                  'assets/images/client-icon.svg'
                "
                alt="Creator Image"
                width="24"
                height="24"
                class="creator-img border-1 border-round-2xl border-gray-300"
              />
              <span class="">{{ campaignData.createdBy.name }}</span>
            </div>
          </div>
          <div *ngIf="campaignData.type === 2" class="info-item">
            <i class="pi pi-clock"></i>
            <span class="info-title">Uslov kampanje</span>
            <span class="info-value">{{ campaignData.selfRenewingCondition }}</span>
          </div>
          <div class="info-item">
            <i class="pi pi-users"></i>
            <span class="info-title">{{campaignData.type === 0 ? 'Aktivni agenti' : 'Obuhvata agente'}} </span>
            <span class="info-value">
              <atlas-agent-list-images
                [agents]="campaignData.agents"
              ></atlas-agent-list-images>
            </span>
          </div>
        </div>
      </div>

      <div
        class="mt-5"
        *ngIf="campaignData.type === 0"
        style="display: flex; gap: 20px; flex-wrap: wrap"
      >
        <div class="main-box">
          <img
            [ngSrc]="'assets/images/percentage.svg'"
            width="48"
            height="48"
            alt="perc"
          />
          <div class="text-wrap">
            <div class="title h-2rem">Obrađeno</div>
            <div class="count">{{ campaignStats?.processedPercent }}</div>
          </div>
        </div>
        <div class="main-box">
          <img
            [ngSrc]="'assets/images/user-back.svg'"
            width="48"
            height="48"
            alt="user"
          />
          <div class="text-wrap">
            <div class="title h-2rem">Aktivno</div>
            <div class="count">{{ campaignStats?.active }}</div>
          </div>
        </div>
        <div class="main-box">
          <img
            [ngSrc]="'assets/images/signal.svg'"
            width="48"
            height="48"
            alt="signal"
          />
          <div class="text-wrap">
            <div class="title h-2rem">Neto CR</div>
            <div class="count">{{ campaignStats?.netoCr }}</div>
          </div>
        </div>
        <div class="main-box">
          <img
            [ngSrc]="'assets/images/signal.svg'"
            width="48"
            height="48"
            alt="signal"
          />
          <div class="text-wrap">
            <div class="title h-2rem">Bruto CR</div>
            <div class="count">{{ campaignStats?.brutoCr }}</div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="campaignData.type === 0" class="mt-5">
        <atlas-lead-campaign-details-table [campaignId]="campaignId">
        </atlas-lead-campaign-details-table>
      </ng-container>

      <ng-container *ngIf="campaignData.type === 1">
        <div class="mt-6">
          <atlas-client-with-packages-table
            [showSearchBar]="true"
            [canRenewPackage]="false"
            pageTitle="Ugovori"
            pageDescription="Definisani ugovori"
            [campaignId]="campaignId"
          ></atlas-client-with-packages-table>
        </div>
      </ng-container>

      <ng-container *ngIf="campaignData.type === 2">
        <div class="mt-6">
          <atlas-client-with-packages-table
            [showSearchBar]="true"
            [canRenewPackage]="false"
            pageTitle="Ugovori"
            pageDescription="Ugovori koji spadaju pod uslov kampanje"
            [campaignId]="campaignId"
          ></atlas-client-with-packages-table>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</atlas-scrollable-page>

<ng-template #skeleton>
  <div class="flex flex-column gap-5">
    <div class="campaign-header">
      <div class="title-with-icon">
        <div class="w-15rem">
          <p-skeleton width="100%" height="40px" />
        </div>
      </div>
      <div class="actions cursor-pointer">
        <div class="w-5rem">
          <p-skeleton width="100%" height="30x" />
        </div>
        <i [ngClass]="'pi pi-ellipsis-v'"></i>
      </div>
    </div>

    <div class="campaign-info-box">
      <div class="info-column" style="flex-basis: 40%">
        <div class="info-item">
          <div class="w-20rem">
            <p-skeleton width="100%" height="30px" />
          </div>
        </div>
        <div class="info-item">
          <div class="w-20rem">
            <p-skeleton width="100%" height="30px" />
          </div>
        </div>
        <div class="info-item">
          <div class="w-20rem">
            <p-skeleton width="100%" height="30px" />
          </div>
        </div>
        <div class="info-item">
          <div class="w-20rem">
            <p-skeleton width="100%" height="30px" />
          </div>
        </div>
      </div>

      <div class="info-column" style="flex-basis: 60%">
        <div class="info-item">
          <div class="w-20rem">
            <p-skeleton width="100%" height="30px" />
          </div>
        </div>
        <div class="info-item">
          <div class="w-20rem">
            <p-skeleton width="100%" height="30px" />
          </div>
        </div>
        <div class="info-item">
          <div class="w-20rem">
            <p-skeleton width="100%" height="30px" />
          </div>
        </div>
        <div class="info-item">
          <div class="w-20rem">
            <p-skeleton width="100%" height="30px" />
          </div>
        </div>
      </div>
    </div>

    <div class="w-full">
      <p-skeleton width="100%" height="20vh" />
    </div>

    <div class="w-full">
      <p-skeleton width="100%" height="60vh" />
    </div>
  </div>
</ng-template>

<p-overlayPanel #overlayPanelInfoLeads>
  <div class="overlay-content">
    <div (click)="editCampaign(this.campaignId)">
      <i class="pi pi-pencil icon"></i> Izmeni kampanju
    </div>
    <div (click)="changeLeads(this.campaignId)">
      <img
        [ngSrc]="'assets/images/box-icon.svg'"
        width="16"
        height="16"
        alt="box"
      />
      <span class="ml-1">Izmeni leadove</span>
    </div>
    <div
      (click)="changeAgents(this.campaignId)"
      style="border-bottom: 1px solid var(--surface-300)"
    >
      <i class="pi pi-users icon"></i> Izmeni agente
    </div>
    <div (click)="deleteCampaign(this.campaignId)">
      <i class="pi pi-trash icon"></i>Izbriši
    </div>
  </div>
</p-overlayPanel>
<p-overlayPanel #overlayPanelInfo>
  <div class="overlay-content">
    <div (click)="editCampaign(this.campaignId)">
      <i class="pi pi-pencil icon"></i> Izmeni kampanju
    </div>
    <div (click)="editPackages(this.campaignId)"><i class="pi pi-file-edit icon"></i> Izmeni ugovore</div>
    <div (click)="deleteCampaign(this.campaignId)">
      <i class="pi pi-trash icon"></i>Izbriši
    </div>
  </div>
</p-overlayPanel>
