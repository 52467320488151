import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { AgentListImagesComponent } from '@app/cc-leader/campaigns/agents/agent-list-images/agent-list-images.component';
import { CampaignCardComponent } from '@app/cc-leader/campaigns/campaign-card/campaign-card.component';
import { InputButtonComponent } from '@shared/components/input-button/input-button.component';
import { PageComponent } from '@shared/components/page/page.component';
import {
  CampaignsClient,
  CampaignStatus,
  CampaignType,
  GetAgentRecommendationsResponse,
  ICampaignResponse,
  LeadCallResponse,
} from '@shared/model/atlas.api';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { firstValueFrom } from 'rxjs';
import { UserService } from '@shared/services/user.service';
import { LocationCcService } from '@shared/services/location-cc.service';

@Component({
  selector: 'atlas-campaign-table',
  standalone: true,
  imports: [
    PageComponent,
    NgClass,
    NgFor,
    AgentListImagesComponent,
    DataViewModule,
    CampaignCardComponent,
    DatePipe,
    ButtonComponent,
    FormsModule,
    DropdownModule,
    InputButtonComponent,
    NgIf,
  ],
  templateUrl: './campaign-table.component.html',
  styleUrl: './campaign-table.component.scss',
})
export class CampaignTableComponent implements OnInit {
  leads = [];
  scheduledCalls: LeadCallResponse[] = [];
  nextCall: any;
  selectedRows: number;
  countLeads: number;
  campaignTable = new FormControl('');
  preporukeCardItem: GetAgentRecommendationsResponse;

  rowsOptions = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '15', value: 15 },
    { label: '20', value: 20 },
  ];

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  constructor(
    private _camapaignClient: CampaignsClient,
    private _location: LocationCcService,
    private _userService: UserService,
  ) {
    this.selectedRows = 5;
  }

  ngOnInit(): void {
    this.loadLeads().then(() => {
      this.loadNextCall().then(() => {
        this.loadRecommendationCard().then();
      });
    });
  }

  handleMoreDetails() {
    this._location.routeToScheduledCalls().then();
  }

  getCampaignStatus(status: number): string {
    switch (status) {
      case CampaignStatus.IN_PROGRESS:
        return 'U toku';
      case CampaignStatus.DONE:
        return 'Završena';
      case CampaignStatus.CANCELLED:
        return 'Prekinuta';
      default:
        return 'Nepoznato';
    }
  }

  private getFlagColor(priority: number): string {
    switch (priority) {
      case 4:
        return '#F04438';
      case 3:
        return '#EB9229';
      case 2:
        return '#1E7EC3';
      case 1:
        return '#1EC360';
      case 0:
        return '#999999';
      default:
        return '#999999';
    }
  }

  async loadLeads() {
    await this._fetchLeads().then((res) => {
      this.leads = res.result.data.map((item: ICampaignResponse) => ({
        id: item.id,
        title: item.title,
        user: item.createdBy.name,
        status: this.getCampaignStatus(item.status),
        description: item.description || 'Bez opisa',
        date: item.endsOn,
        flagColor: this.getFlagColor(item.priority),
        info: item.type,
        totalUsers: item.leadsCount,
        agents: [...item.sellers, ...item.tempSellers],
      }));
      this.countLeads = this.leads.length;
    });
  }

  async loadNextCall() {
    await this._fetchNextCall().then((res) => {
      this.nextCall = res.result;
    });
  }

  async loadRecommendationCard() {
    await this._fetchAgentRecommendations().then((res) => {
      this.preporukeCardItem = res.result;
    });
  }

  private async _fetchLeads() {
    return await firstValueFrom(
      this._camapaignClient.getCampaigns(CampaignType.LEADS),
    );
  }

  private async _fetchNextCall() {
    return await firstValueFrom(this._camapaignClient.getNextCall());
  }

  private async _fetchAgentRecommendations() {
    return await firstValueFrom(
      this._camapaignClient.getAgentRecommendations(),
    );
  }

  protected readonly alert = alert;
}
