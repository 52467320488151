<atlas-page
  pageTitle="Paketi"
  btnIcon="pi pi-plus"
  pageDescription="Uređivanje i dodavanje paketa za vozila"
  actionLabel="Dodaj paket"
  (onHandleAction)="handleAddPackage()"
>
  <div class="flex flex-column md:flex-row gap-3">
    <atlas-page-info-card
      [cardTitle]="'Za putnička vozila'"
      cardIcon="pi-car"
      [cardNumber]="'ToDo'"
      class="flex-1"
    ></atlas-page-info-card>
    <atlas-page-info-card
      [cardTitle]="'Za kombi vozila'"
      [cardNumber]="'ToDo'"
      cardIcon="pi-truck"
      class="flex-1"
    ></atlas-page-info-card>
    <atlas-page-info-card
      [cardTitle]="'Za motocikle'"
      [cardNumber]="'ToDo'"
      [cardImageSrc]="'assets/images/moto.svg'"
      class="flex-1"
    ></atlas-page-info-card>
  </div>

  <div class="text-2xl font-semibold mt-5">Lista paketa</div>
  <div class="text-base text-gray-600 mt-2">
    Lista aktivnih paketa za sva vozila
  </div>

  <div class="flex align-items-center">
    <div class="flex flex-column mt-5 gap-2 flex-1">
      <p-tabView
        class="tabView"
        [(activeIndex)]="activeIndex!"
        (activeIndexChange)="handleTabChange($event)"
      >
        <p-tabPanel header="Sva vozila"></p-tabPanel>
        <p-tabPanel header="Putnička"></p-tabPanel>
        <p-tabPanel header="Kombi"></p-tabPanel>
        <p-tabPanel header="Motocikli"></p-tabPanel>
      </p-tabView>
    </div>

    <div class="ml-4 mt-4">
      <span class="activeAcc">Aktivni paketi: </span>
      <span> {{ activePackagesCount }} od {{ allPackages.length }} </span>
    </div>
  </div>

  <div class="flex gap-3 flex-wrap">
    <div *ngFor="let p of allPackages; let i = index" (click)="previewPackage(p.id)" class="package-grid-item">
      <atlas-package-card [package]="p"></atlas-package-card>
    </div>
  </div>
  <div *ngIf="allPackages.length === 0" class="flex gap-3 flex-wrap">
    <div *ngFor="let item of skeletonItems" class="skeleton-package-item">
      <p-skeleton class="skeleton-city-item"></p-skeleton>
    </div>
  </div>

</atlas-page>