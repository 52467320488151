import { Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { AssistanceFormService } from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';

@Component({
  selector: 'atlas-num-of-passengers-dropdown',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './num-of-passengers-dropdown.component.html',
  styleUrl: './num-of-passengers-dropdown.component.scss',
})
export class NumOfPassengersDropdownComponent implements OnInit {
  searchOptions: number[];

  @Input() numOfPassCtrl: any;
  @Input() ctrlStyleClass: string = 'w-full';

  constructor(private formService: AssistanceFormService) {
    this.numOfPassCtrl = this.formService.getForm.controls.numOfPassengers;
    this.searchOptions = [0, 1, 2, 3, 4, 5, 6, 7];
  }

  ngOnInit() {}
}
