import { Component, Input } from '@angular/core';
import { HoverClassDirective } from '@shared/directives/hover-class.directive';
import { LocationAdminService } from '@shared/services/location-admin.service';

@Component({
  selector: 'atlas-reports-card',
  standalone: true,
  imports: [HoverClassDirective],
  templateUrl: './reports-card.component.html',
  styleUrl: './reports-card.component.scss',
})
export class ReportsCardComponent {
  @Input() name: string = '';
  @Input() icon: string = '';
  @Input() desc: string = '';
  @Input() route: string = '';

  constructor(private _locationAdminService: LocationAdminService) {}

  handleOnClick() {
    this._locationAdminService.routeToReport(this.route);
  }

  protected readonly onmouseover = onmouseover;
}
