import { Component } from '@angular/core';
import { PageComponent } from '@shared/components/page/page.component';
import { TabViewModule } from 'primeng/tabview';
import { BrandWithModelTableComponent } from '../brand-with-model-table/brand-with-model-table/brand-with-model-table.component';
import { NgIf } from '@angular/common';
import { VehicleChangeRequestTableComponent } from '../vehicle-change-request-table/vehicle-change-request-table.component';

@Component({
  selector: 'atlas-vehicles-page',
  standalone: true,
  imports: [
    TabViewModule,
    PageComponent,
    BrandWithModelTableComponent,
    VehicleChangeRequestTableComponent,
    NgIf,
  ],
  templateUrl: './vehicles-page.component.html',
  styleUrl: './vehicles-page.component.scss',
})
export class VehiclesPageComponent {
  public activeIndex: number | undefined;
  protected active: boolean | undefined;

  constructor() {
    this.activeIndex = 1;
  }

  handleTabChange(event: number) {
    if (this.activeIndex === 0) {
    } else this.active = event == 1;
  }
}
