import {
  DecimalPipe,
  NgClass,
  NgFor,
  NgForOf,
  NgIf,
  NgOptimizedImage,
} from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { CheckboxModule } from 'primeng/checkbox';
import { Subscription } from 'rxjs';
import {
  PackageCardListService,
  PackageRenewData,
} from '@app/cc-leader/services/package-card-list.service';
import {
  IInternalPromoCode,
  IPackageCardItem,
  PackageCardItem,
  PackagesClient,
} from '@shared/model/atlas.api';
import { DropdownModule } from 'primeng/dropdown';
import { CurrencyNumberPipe } from '@shared/pipes/currency-number.pipe';
import { ControlsOf } from '@shared/forms/forms-common';
import { ButtonComponent } from '@ui/button/button.component';
import { OrderFormService } from '@app/cc-leader/services/order-form.service';
import { HoverClassDirective } from '@shared/directives/hover-class.directive';
import { InternalPromoCodeDropdownComponent } from '@app/features/packages/package-card-list/internal-promo-code-dropdown/internal-promo-code-dropdown.component';
import { applyDiscount } from '@shared/functions/functions';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'atlas-package-card-list',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgClass,
    NgForOf,
    CheckboxModule,
    InputAddonComponent,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    CurrencyNumberPipe,
    ButtonComponent,
    HoverClassDirective,
    InternalPromoCodeDropdownComponent,
  ],
  templateUrl: './package-card-list.component.html',
  styleUrl: './package-card-list.component.scss',
})
export class PackageCardListComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  @Input() isRenew: boolean;

  selectedIdx: number;
  allPackages: PackageCardItem[] = [];
  licniPaketItem: PackageCardItem;
  showAddNewPackageBtn: boolean = true;
  selectedPackageId: string = null;
  showPriceInput: boolean = false;
  vehicleType: string;
  dropdownDisabled: boolean = true;
  packages: FormArray<FormGroup<ControlsOf<IPackageCardItem>>>;
  isForVehicle: boolean;

  constructor(
    private  _packagesService: PackageCardListService,
    private _packagesClent: PackagesClient,
    public decimalPipe: DecimalPipe,
    private _orderFormService: OrderFormService,
    private route: ActivatedRoute
  ) {}

   ngOnInit(): void {
    
    // const containsBuyPackageVehicle = this.route.snapshot.url.some(segment => segment.path.includes('buy-package-vehicle'));
    // if (containsBuyPackageVehicle){
    //     this.vehicleType = 'b08d086a-4f88-47a3-b9ea-c868c7200ced';
    //     this.dropdownDisabled = false;
    //     this._loadPackages(this.vehicleType);
    //     this.allPackages = this.allPackages.filter(pkg => pkg.name !== 'Moj Paket');
      
    // }
    this._subs.add(
      this._packagesService.getVehicleTypeId().subscribe(async (x) => {
        if (x) { 
          this.vehicleType = x;
          this.dropdownDisabled = false;
          await this._loadPackages(x);
          this.allPackages = this.allPackages.filter(pkg => pkg.name !== 'Moj Paket');
        }
      }),
    );

    // Single renew
    this._subs.add(
      this._packagesService.getSelectedPackageId().subscribe(async (x) => {
        if (x && this.isRenew) {
          this.showAddNewPackageBtn = false;
          await this._loadPackages();
          this.selectedPackageId = x;
          if (this.isRenew) {
            const packageData = this.allPackages.find((p) => p.id === x);
            if (packageData) {
              //const actionPrices = packageData.actionPrices;
              //this._setPackageActionPrices(0, actionPrices);
            }
          }
        }
      }),
    );

    // Multiple renew
    this._subs.add(
      this._packagesService
        .getSelectedPackagesForRenew()
        .subscribe(async (x) => {
          if (x.length > 0) {
            this.showAddNewPackageBtn = false;
            await this._loadPackages();
            x.forEach((element: PackageRenewData, idx) => {
              const packageData = this.allPackages.find(
                (p) => p.id === element.packageId,
              );
              if (packageData) {
                // const actionPrices = packageData.actionPrices;
                //  this._setPackageActionPrices(idx, actionPrices);
              }
            });
          }
        }),
    );

    this._subs.add(
      this._packagesService.packages$.subscribe((packages) => {
        this.packages = packages;
        if ((!this.packages || this.packages.length === 0) && !this.isRenew) {
          this.addEmptyPackageCard();
        }
      }),
    );

    this._subs.add(
      this._packagesService.licniPaketSelected.subscribe((x) => {
        if (x) {
          this.addLicniPaket().then();
        } else {
          if (this.vehicleType) {
            this.dropdownDisabled = false;
          }
        }
      }),
    );
  }

  addEmptyPackageCard() {
    const newEmptyPackage = this._packagesService.initPackageCardForm();
    this._packagesService.addPackage(newEmptyPackage.value as IPackageCardItem);
    this.packages = this._packagesService.getPackages();
  }

  async addLicniPaket() {
    this._packagesService.clearAllPackages();
    await this._loadPackages();
    this.licniPaketItem = this.allPackages.find((x) => x.name === 'Moj Paket');
    this._packagesService.setSelectedPackageId(this.licniPaketItem.id, 0);
    this._packagesService.setSelectedPackageName(this.licniPaketItem.name, 0);
    this._packagesService.setSelectedPackagePrice(this.licniPaketItem.price, 0);
    this._orderFormService.getOrderForm.controls.packages.push(
      this._packagesService.initPackageCardForm(this.licniPaketItem)),
    this.showAddNewPackageBtn = false;
    this.dropdownDisabled = true;
    this._packagesService.setLicniPaketSelected();
  }

  togglePackageDropdown(idx: number) {
    if (this.selectedIdx === idx) {
      this.selectedIdx = null;
    } else {
      this.selectedIdx = idx;
    }
  }

  removePackage(event: MouseEvent, idx: number) {
    event.stopPropagation();
    this._packagesService.clearPackageById(idx);
    this._orderFormService.getOrderForm.controls.packages.removeAt(idx);
    this._setOrderFormValidation();
  }

  private _loadPackages(vehicleTypeId?: string): Promise<void> {
    return new Promise((resolve) => {
      this._subs.add(
        this._packagesClent
          .getAllForPackageCard(vehicleTypeId)
          .subscribe((x) => {
            this.allPackages = x.result.cardPackages || [];
            resolve();
          }),
      );
    });
  }

  selectPackage(pkg: PackageCardItem, idx: number) {
    this.selectedIdx = null;
  
    this._packagesService.setSelectedPackageName(pkg.name, idx);
    this._packagesService.setSelectedPackagePrice(pkg.price, idx);
    this._packagesService.setSelectedPackageId(pkg.id, idx);
  
    const packageControl = this._orderFormService.getOrderForm.controls.packages.at(idx);
    if (packageControl) {
      packageControl.patchValue({
        id: pkg.id,
        name: pkg.name,
        price: pkg.price,
      });
    } else {
      this._orderFormService.getOrderForm.controls.packages.setControl(
        idx,
        this._packagesService.initPackageCardForm(pkg)
      );
    }
  
    this._setOrderFormValidation();
  }

  private _setOrderFormValidation() {
    if (this.isRenew) {
      return;
    }
    // chassis
    if (
      this._orderFormService.getOrderForm.controls.packages.value.some(
        (p) => p.isChassisRequired,
      )
    ) {
      this._orderFormService.getOrderForm.controls.vehicle.controls.chassis.addValidators(
        [Validators.required],
      );
    } else {
      this._orderFormService.getOrderForm.controls.vehicle.controls.chassis.removeValidators(
        [Validators.required],
      );
    }
    // jmbg
    if (
      this._orderFormService.getOrderForm.controls.packages.value.some(
        (p) => p.isJmbgRequired,
      )
    ) {
      this._orderFormService.getOrderForm.controls.client.controls.nationalNumber.addValidators(
        [Validators.required],
      );
    } else {
      this._orderFormService.getOrderForm.controls.client.controls.nationalNumber.removeValidators(
        [Validators.required],
      );
    }

    this._orderFormService.getOrderForm.controls.vehicle.controls.chassis.updateValueAndValidity();
    this._orderFormService.getOrderForm.controls.client.controls.nationalNumber.updateValueAndValidity();
  }

  handleInternalDiscountChanged(request: IInternalPromoCode, idx: number) {
    const currentPackageCard = this.packages.at(idx);
    // Apply discount value
    if (request && request.id) {
      const discount = applyDiscount(
        request.valueType,
        request.value,
        currentPackageCard.value.price,
      );
      currentPackageCard.controls.promoCodeId.patchValue(request.id);
      currentPackageCard.controls.price.patchValue(discount.total);
      // Promene u servisu
      this._packagesService.setPackagePrice(idx, discount.total);
      if (!this.isRenew) {
        // Promene u formi
        this._orderFormService.getOrderForm.controls.packages
          .at(idx)
          .controls.promoCodeId.patchValue(request.id);
        this._orderFormService.getOrderForm.controls.packages
          .at(idx)
          .controls.price.patchValue(discount.total);
      }
    } 
    // Remove discount value
    else {
      currentPackageCard.controls.promoCodeId.patchValue(null);
      currentPackageCard.controls.price.patchValue(
        currentPackageCard.value.originalPrice,
      );
      this._packagesService.setPackagePrice(idx, currentPackageCard.value.originalPrice);
      if (!this.isRenew) {
        this._orderFormService.getOrderForm.controls.packages
          .at(idx)
          .controls.promoCodeId.patchValue(null);
        this._orderFormService.getOrderForm.controls.packages
          .at(idx)
          .controls.price.patchValue(currentPackageCard.value.originalPrice);
      }
    }
  }

   ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
