import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GoogleMap, MapDirectionsRenderer } from '@angular/google-maps';
import {
  AsyncPipe,
  JsonPipe,
  NgClass,
  NgIf,
  NgOptimizedImage,
} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { AutoFocusModule } from 'primeng/autofocus';
import { ErrorEmailComponent } from '@ui/errors/error-email/error-email.component';
import {
  ErrorRequiredComponent,
  shouldDisplayRequiredError,
} from '@ui/errors/error-required/error-required.component';
import { InputTextModule } from 'primeng/inputtext';
import { DividerModule } from 'primeng/divider';
import { AssistanceFormService } from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import { AssistanceService } from '@app/sos-agent//assistances/assistance-form/services/assistance.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { VehiclesClient } from '@shared/model/atlas.api';
import { formatCityAndCountry } from '@shared/forms/forms-common';

@Component({
  selector: 'atlas-location-map',
  standalone: true,
  imports: [
    GoogleMap,
    MapDirectionsRenderer,
    AsyncPipe,
    FormsModule,
    InputTextComponent,
    JsonPipe,
    AutoFocusModule,
    ErrorEmailComponent,
    ErrorRequiredComponent,
    InputTextModule,
    ReactiveFormsModule,
    DividerModule,
    NgIf,
    NgOptimizedImage,
    NgClass,
  ],
  templateUrl: './location-map.component.html',
  styleUrl: './location-map.component.scss',
})
export class LocationMapComponent implements OnInit, OnDestroy, AfterViewInit {
  clientId: number | undefined;
  vehicleDestinationAddress: string | undefined;
  clientDestinationAddress: string | undefined;

  mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: true,
    center: {
      // Belgrade
      lat: 44.80401,
      lng: 20.46513,
    },
    zoom: 12,
    styles: [
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  };

  directionOptions: google.maps.DirectionsRendererOptions = {
    polylineOptions: {
      strokeColor: '#C21A1A',
      strokeWeight: 4,
    },
    markerOptions: {},
  };

  private _subs: Subscription;

  @ViewChild('gmap') mapElement: google.maps.Map;
  distanceText: string = '';
  @ViewChild('placeStartRef') placeStartRef: any;
  @ViewChild('placeEndRef') placeEndRef: any;

  constructor(
    public formService: AssistanceFormService,
    public service: AssistanceService,
    private client: VehiclesClient,
  ) {
    this._subs = new Subscription();
    this.distanceText = this.formService.getForm.value.durationText;
    if (this.service.isUpdate) {
      this.service.setDirection();

      this.mapOptions = {
        ...this.mapOptions,
        center: {
          lat: this.formService.getForm.value.from.latitude,
          lng: this.formService.getForm.value.from.longitude,
        },
      };
    }
  }

  ngOnInit() {
    this._subs.add(
      this.formService.getForm.controls.durationText.valueChanges.subscribe(
        (res) => {
          this.distanceText = res;
        },
      ),
    );
    this._subs.add(
      this.formService.getVehicleForm.controls.vehicleId.valueChanges.subscribe(
        (id) => {
          if (id) {
            this._getDestinationText(id).then((res) => {
              this.vehicleDestinationAddress = res.result;
            });
          } else {
            this.vehicleDestinationAddress = undefined;
          }
        },
      ),
    );
    this._subs.add(
      this.formService.getClientForm.controls.destinationAddress.valueChanges.subscribe(
        (destination) => {
          this.clientDestinationAddress = destination;
        },
      ),
    );
  }

  ngAfterViewInit() {
    this.getPlaceStartAutocomplete();
    this.getPlaceEndAutocomplete();
  }

  private getPlaceStartAutocomplete() {
    if (this.formService.getForm.value.from.place) {
      this.placeStartRef.nativeElement.value =
        this.formService.getForm.value.from.place;
    }
    const autocomplete = new google.maps.places.Autocomplete(
      this.placeStartRef.nativeElement,
      {
        types: ['geocode'], // 'establishment' / 'address' / 'geocode'
      },
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.showLoader = true;
      const place = autocomplete.getPlace();
      console.log(place);
      this.formService.getForm.controls.from.patchValue({
        place: formatCityAndCountry(place),
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      });
      this.service.setDirection();
    });
  }

  private getPlaceEndAutocomplete() {
    if (this.formService.getForm.value.to.place) {
      this.distanceText = this.formService.getForm.value.durationText;
      this.placeEndRef.nativeElement.value =
        this.formService.getForm.value.to.place;
    }
    const autocomplete = new google.maps.places.Autocomplete(
      this.placeEndRef.nativeElement,
      {
        types: ['geocode'],
      },
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.showLoader = true;

      const place = autocomplete.getPlace();
      console.log(place);
      this.formService.getForm.controls.to.patchValue({
        place: formatCityAndCountry(place),
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      });
      this.service.setDirection();
    });
  }

  showLoader: boolean = false;

  ngOnDestroy() {
    this.distanceText = '0km (0min)';
    this._subs.unsubscribe();
  }

  private async _getDestinationText(id: string) {
    return await firstValueFrom(this.client.getVehicleDestinationAddress(id));
  }

  protected readonly shouldDisplayRequiredError = shouldDisplayRequiredError;
}
