<div class="pb-5">
  <div>
    <atlas-inline-wrapper formCtrlId="name" ctrlLabel="Naziv kampanje">
      <atlas-input-text
        [formCtrl]="form.controls.name"
        formCtrlId="name"
        formCtrlClass="w-full"
        ctrlPlaceholder="Naziv kampanje"
        [showLabel]="false"
      ></atlas-input-text>
    </atlas-inline-wrapper>
    <p-divider></p-divider>
    <atlas-inline-wrapper formCtrlId="name" ctrlLabel="Opis kampanje">
      <div class="comment-input">
        <atlas-input-description
          [formCtrl]="form.controls.description"
          formCtrlId="Komentar"
          ctrlPlaceholder="Tvoj komentar"
          formCtrlClass="w-full"
          [showInfo]="true"
        ></atlas-input-description>
      </div>
    </atlas-inline-wrapper>
    <p-divider></p-divider>
    <!--    Kraj -->
    <atlas-inline-wrapper formCtrlId="endsOnUtc" ctrlLabel="Trajanje kampanje">
      <div class="flex-column">
        <div class="mb-3">
          <p-radioButton
            name="endsOn"
            [value]="null"
            [formControl]="durationLimitCtrl"
            (onClick)="setNoLimitDuration()"
            inputId="end-on-utc"
          ></p-radioButton>
          <label for="end-on-utc" class="ml-3">Neograničeno</label>
        </div>

        <div>
          <p-radioButton
            name="endsOn"
            [value]="1"
            [formControl]="durationLimitCtrl"
            inputId="ends-select-date"
          ></p-radioButton>
          <label for="ends-select-date" class="ml-3">Izaberi datum</label>

          <div *ngIf="durationLimitCtrl.value === 1" class="mt-2 flex">
            <atlas-calendar [formCtrl]="form.controls.endsOnUtc | formControl">
            </atlas-calendar>
          </div>
        </div>
      </div>
    </atlas-inline-wrapper>
    <p-divider></p-divider>
    <div class="header mt-7 mb-5">
      Ugovori
      <div class="header-desc font-normal mt-1">
        Primeni filtere koji ce definisati ugovore
      </div>
    </div>
    <atlas-client-with-packages-table
      [displayStatistics]="true"
      [canRenewPackage]="false"
      (contractIds)="handleSelectedContracts($event)"
    ></atlas-client-with-packages-table>
  </div>
</div>
