import { Routes } from '@angular/router';
import { ClientPackageTableComponent } from '@app/sos-agent/packages/client-package-table/client-package-table.component';
import { SosSettingsComponent } from './sos-settings/sos-settings.component';
import { AssistanceTableComponent } from '@app/sos-agent/assistances/assistance-table/assistance-table.component';
import { AssistanceFormComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form.component';
import { AssistanceFormStep1Component } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/assistance-form-step-1.component';
import { AssistanceFormStep2Component } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-2/assistance-form-step-2.component';
import { AuthGuard } from '@shared/services/auth.guard';
import { AssistancePreviewComponent } from './assistances/assistance-preview/assistance-preview/assistance-preview.component';

export const sosAgentRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'assistances',
        pathMatch: 'full',
      },
      {
        path: 'assistances',
        children: [
          {
            path: '',
            canActivate: [AuthGuard],
            component: AssistanceTableComponent,
          },
          {
            path: 'assistance-preview',
            component: AssistancePreviewComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create',
            canActivate: [AuthGuard],
            component: AssistanceFormComponent,
            children: [
              {
                path: 'step-1',
                canActivate: [AuthGuard],
                component: AssistanceFormStep1Component,
              },
              {
                path: 'step-2',
                canActivate: [AuthGuard],
                component: AssistanceFormStep2Component,
              },
            ],
          },
          {
            path: 'edit/:id',
            canActivate: [AuthGuard],
            component: AssistanceFormComponent,
            children: [
              {
                path: 'step-1',
                canActivate: [AuthGuard],
                component: AssistanceFormStep1Component,
              },
              {
                path: 'step-2',
                canActivate: [AuthGuard],
                component: AssistanceFormStep2Component,
              },
            ],
          },
        ],
      },
      {
        path: 'contracts',
        canActivate: [AuthGuard],
        component: ClientPackageTableComponent,
        pathMatch: 'full',
      },
      // {
      //   path: 'clients',
      //   children: [
      //     {
      //       path: '',
      //       canActivate: [AuthGuard],
      //       component: ClientsTableComponent,
      //     },
      //     {
      //       path: 'create',
      //       canActivate: [AuthGuard],
      //       component: CreateOrderFormComponent,
      //       children: [
      //         {
      //           path: 'step-1',
      //           canActivate: [AuthGuard],
      //           component: CreateOrderFormStep1Component,
      //         },
      //         {
      //           path: 'step-2',
      //           canActivate: [AuthGuard],
      //           component: CreateOrderFormStep2Component,
      //         },
      //         {
      //           path: 'step-3',
      //           canActivate: [AuthGuard],
      //           component: CreateOrderFormStep3Component,
      //         },
      //       ],
      //     },
      //   ]
      // },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        component: SosSettingsComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'assistances',
  },
];
