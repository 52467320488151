import {Component, Input} from '@angular/core';
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {IAgentAutocompleteResponse} from "@shared/model/atlas.api";

@Component({
  selector: 'atlas-agent-list-images',
  standalone: true,
    imports: [
        NgForOf,
        NgOptimizedImage,
        NgIf
    ],
  templateUrl: './agent-list-images.component.html',
  styleUrl: './agent-list-images.component.scss'
})
export class AgentListImagesComponent {
    @Input() agents: IAgentAutocompleteResponse[] = [];
}
