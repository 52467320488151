import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import {
  ICreatePackageCommand,
  IPackageServiceItem,
  PackageServiceItem,
} from '@shared/model/atlas.api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PackageService {
  constructor(private _fb: FormBuilder) {}

  packageForm: PackageForm;

  initPackageForm(model?: ICreatePackageCommand) {
    if (!this.packageForm) {
      this.packageForm = this._fb.group<ControlsOf<ICreatePackageCommand>>({
        name: this._fb.control(model?.name, {
          nonNullable: true,
          validators: [Validators.maxLength(64), Validators.required],
        }),
        description: this._fb.control(model?.description, {
          nonNullable: true,
          validators: [Validators.maxLength(128), Validators.required],
        }),
        nameEnglish: this._fb.control(model?.nameEnglish, {
          validators: [Validators.maxLength(64), Validators.required],
        }),
        descriptionEnglish: this._fb.control(model?.descriptionEnglish, {
          nonNullable: true,
          validators: [Validators.maxLength(128), Validators.required],
        }),
        isVehicleRequired: this._fb.control(model?.isVehicleRequired ?? false, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        isClientIdRequired: this._fb.control(
          model?.isClientIdRequired ?? false,
          {
            nonNullable: true,
            validators: [Validators.required],
          },
        ),
        vehicleTypes: this._fb.array<FormControl<string>>([]),
        packageServices: this._getPackageServiceArray(
          model?.packageServices ?? [],
        ),
        packageDetails: this._fb.array<FormControl<string>>([], {
          validators: [Validators.required],
        }),
        packageDetailsEnglish: this._fb.array<FormControl<string>>([], {
          validators: [Validators.required],
        }),
        price: this._fb.control(model?.price ?? 0, {
          nonNullable: true,
          validators: [Validators.required, Validators.min(1)],
        }),
        oldPrice: this._fb.control(model?.oldPrice ?? 0, {
          nonNullable: true,
          validators: [Validators.maxLength(64)],
        }),
        moreDetailsFileName: this._fb.control(model?.moreDetailsFileName),
        coverTeritory: this._fb.control(model?.coverTeritory),
        termsAndConditionsUri: this._fb.control(model?.termsAndConditionsUri),
        termsOfUseFileName: this._fb.control(model?.termsOfUseFileName),
        totalServicesLimit: this._fb.control(model?.totalServicesLimit ?? 0, {
          nonNullable: true,
          validators: [Validators.required],
        }),
      });
    }
    return this.packageForm;
  }

  private _getPackageServiceGroup(
    model?: IPackageServiceItem,
  ): PackageServiceFrom {
    return this._fb.group<ControlsOf<IPackageServiceItem>>({
      serviceId: this._fb.control(model?.serviceId),
      serviceLimit: this._fb.control(model?.serviceLimit),
    }) as any;
  }

  private _getPackageServiceArray(
    model?: IPackageServiceItem[],
  ): PackageServiceArray {
    if (model?.length === 0) {
      return this._fb.array<PackageServiceFrom>([]);
    }

    const res = this._fb.array<PackageServiceFrom>([]);
    model.forEach((p: IPackageServiceItem) => {
      res.push(this._getPackageServiceGroup(p));
    });
    return res;
  }

  public addPackageService(model?: IPackageServiceItem) {
    this.packageForm.controls.packageServices.push(
      this._getPackageServiceGroup(model),
    );
  }

  reset() {
    this.packageForm = undefined;
  }

  setPackageId(id: string) {
    this.selectedPackageId$.next(id);
  }

  selectedPackageId$ = new BehaviorSubject<string>(undefined);

  set setSelectedPackageId(value: string) {
    this.selectedPackageId$.next(value);
  }

  get getSelectedPackageId() {
    return this.selectedPackageId$.asObservable();
  }

  uploadedPackagePicture$ = new BehaviorSubject<any>(undefined);

  set setUploadedPackagePicture(value: any) {
    this.uploadedPackagePicture$.next(value);
  }

  get getUploadedPackagePicture() {
    return this.uploadedPackagePicture$.asObservable();
  }
}

export declare type PackageForm = FormGroup<ControlsOf<ICreatePackageCommand>>;
export declare type PackageServiceFrom = FormGroup<
  ControlsOf<PackageServiceItem>
>;
export declare type PackageServiceArray = FormArray<PackageServiceFrom>;
