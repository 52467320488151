<atlas-page
        [pageTitle]="'Sve preporuke'"
        btnIcon="pi pi-plus"
        actionLabel="Dodaj preporuku"
        (onHandleAction)="handleAction()"
>
    <!-- Header -->
    <div class="header-container p-5 mb-3">
        <!-- Header-title / Action -->
        <div class="flex justify-content-between mb-5 header-actions">
            <div class="txt-container">
                <span class="header-txt">Moje preporuke</span>
            </div>
        </div>

        <div class="flex justify-content-between stats-container">
            <!-- Broj preporuka -->
            <div class="flex">
                <div
                        class="m-2 p-1 border-round-lg flex justify-content-center align-items-center card-icon"
                >
                    <i class="pi pi-users icon" [ngClass]="'text-3xl m-2'"></i>
                </div>
                <div class="m-2 p-1">
                    <div class="stats-title">Ukupno preporuka</div>
                    <div class="stats-num">{{ agentStats?.totalCount }}</div>
                </div>
            </div>

            <!-- Tekuci mesec -->
            <div class="flex">
                <div
                        class="m-2 p-1 border-round-lg flex justify-content-center align-items-center card-icon"
                >
                    <i class="pi pi-calendar icon" [ngClass]="'text-3xl m-2'"></i>
                </div>
                <div class="m-2 p-1">
                    <div class="stats-title">Tekući mesec</div>
                    <div class="flex">
                        <div class="stats-num">{{ agentStats?.thisMonth }}</div>
                        <ng-container *ngIf="agentStats?.lastMonthPrecent > 0">
                            <div
                                    class="pi pi-arrow-up text-sm font-semibold align-content-center ml-2 mt-2 arrow"
                            ></div>
                            <div
                                    class="align-content-center text-sm font-semibold ml-1 mt-2 arrow"
                            >
                                {{ agentStats?.lastMonthPrecent }}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <!-- Poslednja dodata -->
            <div class="flex">
                <div
                        class="m-2 p-1 border-round-lg flex justify-content-center align-items-center card-icon"
                >
                    <i class="pi pi-user-plus icon" [ngClass]="'text-3xl m-2'"></i>
                </div>
                <div class="m-2 p-1">
                    <div class="stats-title">Poslednje dodata</div>
                    <div class="stats-num">{{ agentStats?.lastAdded }}</div>
                </div>
            </div>
        </div>
    </div>

    <atlas-recommendation-table [showOnlyOwnded]="true"></atlas-recommendation-table>
</atlas-page>
