import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageComponent } from '@shared/components/page/page.component';
import { TableComponent } from '@ui/table/table.component';
import { ActionMode, OnTableInit, TableConfig } from '@ui/table/table-common';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  DeactivatePartnerCommand,
  GetPartnerTableQuery,
  IPaginationResponseOfGetPartnerTableResponse,
  PartnersClient,
} from '@shared/model/atlas.api';
import { PageInfoCardComponent } from '@ui/page-info-card/page-info-card.component';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';
import { FormControl, FormsModule } from '@angular/forms';
import { LocationService } from '@shared/services/location.service';
import { AlertService } from '@shared/services/alert.service';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { SelectItem } from 'primeng/api';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import {TableLazyLoadEvent} from "primeng/table/table.interface";
import {InputButtonComponent} from "@shared/components/input-button/input-button.component";
import {TableServiceLocal} from "@ui/table/table-service-local.service";

@Component({
  selector: 'atlas-partner-table',
  standalone: true,
    imports: [
        PageComponent,
        TableComponent,
        PageInfoCardComponent,
        TabViewModule,
        InputTextModule,
        FormsModule,
        InputTextComponent,
        InlineWrapperComponent,
        DropdownComponent,
        InputButtonComponent,
    ],
  templateUrl: './partner-table.component.html',
  styleUrl: './partner-table.component.scss',
})
export class PartnerTableComponent implements OnInit, OnDestroy, OnTableInit {
  tableConfig: TableConfig;
  paginatedData: IPaginationResponseOfGetPartnerTableResponse;
  private _subs: Subscription;

  searchCtrl: FormControl;
  searchOptions: SelectItem[];
  

  activeNum: string = '0';
  inactiveNum: string = '0';

  public activeIndex: number | undefined;
  protected active: boolean | undefined;

  constructor(
    private _client: PartnersClient,
    private _locationService: LocationService,
    private _tableService: TableServiceLocal,
    private _alertService: AlertService,
  ) {
    this._subs = new Subscription();
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.paginatedData = {
      data: [],
      currentPage: 0,
    };

    this.searchCtrl = new FormControl<string>('');
  }

  ngOnInit() {
    this.setTableConfig().then();
    this._getActiveInactiveCount();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez saradnika',
        description: 'Ovde će biti prikazana tabela saradnika',
        btnLabel: 'Dodaj saradnika',
        action: () => this.handleAddNewPartner(),
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows + 1;
        }
        this.paginatedData.pageSize = event.rows;
        this._load();
      },
      columns: [
        {
          header: 'Naziv',
          field: 'name',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
          columns: [
            {
              header: '',
              field: 'alias',
              type: 'text',
            },
          ],
        },
        // {
        //   header: 'Status',
        //   field: 'statusBadge',
        //   type: 'badge',
        //   styleClass: 'font-bold',
        // },
        {
          header: 'Kontakt osoba',
          field: 'contactPerson',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
          columns: [
            {
              header: '',
              field: 'contactPhone',
              type: 'text',
              styleClass: 'font-gray-600',
            },
          ],
        },
        {
          header: 'Imejl',
          field: 'email',
          type: 'text',
        },
        {
          header: 'Broj vozila',
          field: 'numOfVehicles',
          type: 'number',
        },
        {
          header: 'Broj vozača',
          field: 'numOfDrivers',
          type: 'number',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Izmeni podatke saradnika',
          icon: 'pi pi-pencil',
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this.handleEditPartner(rowData.id);
          },
          shouldDisplayByCondition: () => true,
        },
        {
          mode: ActionMode.MULTI,
          label: 'Deaktiviraj',
          icon: 'pi pi-power-off',
          callback: (rowIdx: number) => this.handleDeactivatePartner(rowIdx),
          shouldDisplayByCondition: (rowId: number) => {
            const rowData: any = this._getPaginatedItem(rowId);
            return rowData.isActive;
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Pogledaj detaljnije',
          icon: 'pi pi-info-circle',
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this.handleInfoClick(rowData.id);
          },
          shouldDisplayByCondition: () => true,
        },
      ],
    });
  }

  handleAddNewPartner() {
    this._locationService.routeToAddPartnerForm();
  }

  handleInfoClick(rowId: number) {
    this._locationService.routeToPartnerInfoPage(rowId.toString());
  }

  handleTabChange(event: any) {
    if (event === 0) {
      this.active = undefined;
    } else if (event == 1) {
      this.active = true;
    } else {
      this.active = false;
    }

    this._load();
  }

  handleEditPartner(rowId: number) {
    this._locationService.routeToEditPartnerForm(rowId.toString());
  }

  handleDeactivatePartner(rowNum: number) {
    const id = this.paginatedData.data[rowNum].id;

    this._subs.add(
      this._client
        .deactivate(
          new DeactivatePartnerCommand({
            partnerId: id,
          }),
        )
        .subscribe((res) => {
          this._load();
          this._alertService.addSuccessMsg('Saradnik uspešno izmenjen');
        }),
    );
  }

  _load() {
    this._tableService.setIsLoading();
    this._getList().then((res) => {
      this.paginatedData = res.result;
      this._tableService.resetIsLoading();
    });
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  private _getActiveInactiveCount() {
    this._client.getActiveInactivePartnersCount().subscribe((res) => {
      this.activeNum = res.result.value.activeCount.toString();
      this.inactiveNum = res.result.value.inactiveCount.toString();
    });
  }

  private async _getList() {
    return await firstValueFrom(
      this._client.getTable(
        new GetPartnerTableQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize,
          isActive: this.active,
          partnerName: this.searchCtrl.value,
        }),
      ),
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
