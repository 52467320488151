import { NgFor, NgForOf, NgIf, NgClass } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageComponent } from '@shared/components/page/page.component';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { TableComponent } from '@ui/table/table.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabViewModule } from 'primeng/tabview';
import { PackageTabClientComponent } from '../package-tab-client.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ClientClient } from '@shared/model/atlas.api';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'atlas-own-package-client-info',
  standalone: true,
  imports: [PageComponent,
    OverlayPanelModule,
    TabViewModule,
    ButtonComponent,
    TableComponent, PackageTabClientComponent, NgFor, NgForOf, NgIf, NgClass, SkeletonModule],
  templateUrl: './own-package-client-info.component.html',
  styleUrl: './own-package-client-info.component.scss'
})
export class OwnPackageClientInfoComponent implements OnInit, OnDestroy {

  private _subs: Subscription = new Subscription();

  clientId: string;
  ownPackage: any;
  showSkeleton: boolean = true;

  protected readonly ButtonStyle = ButtonStyle;
    protected readonly ButtonSeverity = ButtonSeverity;

  constructor(private route: ActivatedRoute,
      private client: ClientClient
    ) {
  
  
      this.clientId = this.route.snapshot.paramMap.get('id');
    }

    ngOnInit(): void {
      this.getClientInfo();
    }

    private getClientInfo() {
      this._subs.add(
        this.client.getProfilePackages(this.clientId).subscribe((res) => {
          this.ownPackage = res.result?.mojPackageCard;
          this.showSkeleton = false;
        })
      )
    }

    ngOnDestroy(): void {
      this._subs.unsubscribe();
    }

}
