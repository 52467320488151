import { NgIf, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ErrorEmailComponent } from '@ui/errors/error-email/error-email.component';
import { ErrorRequiredComponent } from '@ui/errors/error-required/error-required.component';
import { MaxLengthComponent } from '@ui/errors/max-length/max-length.component';
import { AutoFocusModule } from 'primeng/autofocus';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'atlas-input-description',
  standalone: true,
  imports: [ ErrorRequiredComponent,
    ErrorEmailComponent,
    InputTextModule,
    NgIf,
    NgClass,
    ReactiveFormsModule,
    AutoFocusModule,
    MaxLengthComponent,],
  templateUrl: './input-description.component.html',
  styleUrl: './input-description.component.scss'
})
export class InputDescriptionComponent {
   /** I/O */
   @Input() formCtrl!: FormControl;
   @Input() ctrlPlaceholder: string = '';
   @Input() ctrlLabel: string = '';
   @Input() ctrlInfo: string = '';
   @Input() formCtrlId: string = 'text-input';
   @Input() formCtrlType: string = 'text';
   @Input() formCtrlClass: string = '';
   @Input() formCtrlIcon?: string;
   @Input() isDisabled: boolean;
   @Input() showLabel: boolean = true;
   @Input() showInfo: boolean = true;
   @Input() shouldFocus: boolean = false;
   @Input() rows: string = '';
   @Output() keyReleased = new EventEmitter<boolean>();
   @Output() inputLeft: EventEmitter<string | number> | null = new EventEmitter<
     string | number
   >();
   @Output() onEnterClicked = new EventEmitter<string>();
   @Input() hideErrors: boolean = false;
 
   constructor() {
     this.isDisabled = false;
   }
 
   handleKeyUp(value: any) {
     this.keyReleased.emit(value);
     if (value.keyCode === 13) {
       this.onEnterClicked.emit();
     }
   }

   get remainingCharacters(): string {
    const maxCharacters = 100;
    const descriptionLength = this.formCtrl?.value?.length || 0;
    const remaining = Math.max(maxCharacters - descriptionLength, 0);
    return `${remaining} karaktera`;
}
 
   handleBlur(event: any) {
     if (this.inputLeft) {
       this.inputLeft.emit(event.target.value);
     }
   }
}
