import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { FormControl, Validators } from '@angular/forms';
import { PhoneItem } from '@shared/model/atlas.api';
import { JsonPipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import { ContactFormService } from '@app/features/partners/services/contact-form.service';

@Component({
  selector: 'atlas-contact-phone-input',
  standalone: true,
  imports: [FormControlPipe, InputTextComponent, JsonPipe, InputPhoneComponent],
  templateUrl: './contact-phone-input.component.html',
  styleUrl: './contact-phone-input.component.scss',
})
export class ContactPhoneInputComponent implements OnInit, OnDestroy {
  @Input() phone: string;
  @Input() id: string;
  @Input() shouldFocus: boolean = false;

  formCtrl: FormControl;

  private _subs: Subscription = new Subscription();

  constructor(private _phonesService: ContactFormService) {
    this.formCtrl = new FormControl('', {
      validators: [Validators.required],
    });
  }

  ngOnInit() {
    this._subs.add(
      this.formCtrl.valueChanges.subscribe((value) => {
        this._phonesService.handleNewInput(
          new PhoneItem({
            label: value,
            id: this.id,
          }),
        );
      }),
    );

    this._subs.add(
      this._phonesService.isSubmitted().subscribe((x) => {
        if (x) {
          this.formCtrl.markAsTouched();
        }
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['phone'].currentValue != changes['phone'].previousValue) {
      this.formCtrl.patchValue(changes['phone'].currentValue);
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
