import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SourcesClient } from '@shared/model/atlas.api';
import { DropdownComponent, DropdownOptions } from '@ui/dropdown/dropdown.component';
import { Subscription } from 'rxjs';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'atlas-sources-dropdown',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './sources-dropdown.component.html',
  styleUrl: './sources-dropdown.component.scss',
})
export class SourcesDropdownComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  sourceOptions: DropdownOptions[] = [];

  @Input() sourceCtrl: FormControl;
  @Input() ctrlId: string = 'sources-dropdown-id';
  @Input() ctrlStyleClass: string;
  @Input() ctrlLabel: string;


  constructor(
    private _sourcesClient: SourcesClient
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._sourcesClient.getSources().subscribe(({ result: { responseList } }) => {
            this.sourceOptions = responseList.map(({id, name}) => ({
              label: name,
              value: id,
            }));
            this.sourceCtrl.patchValue(this.sourceOptions.find(x => x.label.includes('Nepoznato')).value);
          },
      ),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
