import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ISetAssistanceCostCommand } from '@shared/model/atlas.api';
import { ControlsOf } from '@shared/forms/forms-common';

@Injectable({
  providedIn: 'root',
})
export class CostFormService {
  constructor(private _fb: FormBuilder) {}

  private _form: CostForm | undefined;

  getForm(assistanceId: string): CostForm {
    if (!this._form) {
      this.setForm({
        assistanceId: assistanceId,
        cost: undefined,
      });
    }
    return this._form;
  }

  setForm(model: ISetAssistanceCostCommand) {
    this._form = this._fb.group<ControlsOf<ISetAssistanceCostCommand>>({
      assistanceId: this._fb.control(model.assistanceId),
      cost: this._fb.control(model?.cost, {
        validators: [Validators.required],
      }),
    });
  }

  resetForm() {
    this._form = undefined;
  }
}

export declare type CostForm = FormGroup<ControlsOf<ISetAssistanceCostCommand>>;
