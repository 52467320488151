import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  ClientClient,
  ClientPackageStatus,
  ClientPackageTableFilter,
  GetClientPackagesTableQuery,
  GetClientPackagesTableStatsQuery,
  IClientPackagesTableResponse,
  IPaginationResponseOfClientPackagesTableResponse,
} from '@shared/model/atlas.api';
import {PageComponent} from '@shared/components/page/page.component';
import {PageInfoCardComponent} from '@ui/page-info-card/page-info-card.component';
import {InputSearchComponent} from '@ui/input-search/input-search.component';
import {TabViewModule} from 'primeng/tabview';
import {TableComponent} from '@ui/table/table.component';
import {ActionMode, TableConfig} from '@ui/table/table-common';
import {firstValueFrom, Subscription} from 'rxjs';
import {FormControl, FormsModule} from '@angular/forms';
import {InputTextComponent} from '@ui/input-text/input-text.component';
import {DropdownComponent} from '@ui/dropdown/dropdown.component';
import {SelectItem} from 'primeng/api';
import {ButtonSeverity, ButtonStyle} from '@ui/button/button.component';
import {InlineWrapperComponent} from '@ui/inline-wrapper/inline-wrapper.component';
import {ClientPackageTableService} from '@app/sos-agent/packages/services/client-package-table.service';
import {
  ClientPackageSidebarComponent
} from '@app/sos-agent/packages/client-package-sidebar/client-package-sidebar.component';
import {LocationService} from '@shared/services/location.service';
import {ActivatedRoute} from '@angular/router';
import {DropdownInputButtonComponent} from '@shared/components/dropdown-input-button/dropdown-input-button.component';
import {TableServiceLocal} from '@ui/table/table-service-local.service';
import {TableLazyLoadEvent} from 'primeng/table/table.interface';
import {LocationSosService} from '@shared/services/location-sos.service';
import { ActiveFiltersComponent } from '@app/features/active-filters/active-filters/active-filters.component';
import { ActiveFiltersService } from '@app/features/active-filters/active-filters-service';

@Component({
  selector: 'atlas-client-package-table',
  standalone: true,
  imports: [
    PageComponent,
    PageInfoCardComponent,
    TabViewModule,
    TableComponent,
    FormsModule,
    ClientPackageSidebarComponent,
    DropdownInputButtonComponent,
    ActiveFiltersComponent
  ],
  templateUrl: './client-package-table.component.html',
  styleUrl: './client-package-table.component.scss',
})
export class ClientPackageTableComponent implements OnInit, OnDestroy {
  private _subs: Subscription;
  searchOptionCtrl: FormControl;
  searchCtrl: FormControl;
  searchOptions: SelectItem[];
  statusSearch: boolean | undefined;
  activeIndex: number | undefined;
  paginatedData: IPaginationResponseOfClientPackagesTableResponse;
  public countClients: string = '-1';
  public countActivePackages: string = '-1';
  protected tableConfig: TableConfig;
  isReadonly: boolean = false;
  private initSearch: boolean = false;

  constructor(
    private _client: ClientClient,
    private locationService: LocationService,
    private _locationSosService: LocationSosService,
    private _tableService: TableServiceLocal,
    private route: ActivatedRoute,
    private service: ClientPackageTableService,
    private _activeFiltersService: ActiveFiltersService
  ) {
    this.isReadonly = route.snapshot.data['readonly'];

    this._subs = new Subscription();
    this.tableConfig = new TableConfig({
      columns: [],
      emptyTableInfo: {
        header: 'Bez ugovora',
        description: 'Ovde će biti prikazana tabela ugovora',
        btnLabel: 'Dodaj ugovor',
      },
    });
    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
    this.searchOptionCtrl = new FormControl<SearchOptions>(
      SearchOptions.LICENCE_PLATE,
    );
    this.searchCtrl = new FormControl<string>('');
    this.searchOptions = [
      {
        label: SearchOptions.CLIENT,
        value: SearchOptions.CLIENT,
      },
      {
        label: SearchOptions.CLIENT_PHONE,
        value: SearchOptions.CLIENT_PHONE,
      },
      {
        label: SearchOptions.LICENCE_PLATE,
        value: SearchOptions.LICENCE_PLATE,
      },
      {
        label: SearchOptions.CONTRACT_NUM,
        value: SearchOptions.CONTRACT_NUM,
      },
    ];
  }

  ngOnInit(): void {
    this.loadActivePackagesCount();
    this.setTableConfig().then();

    this.searchCtrl.valueChanges.subscribe((value) => {
      if (!value) {
        this._activeFiltersService.removeFilter("search");
      }
    });
  
    this._subs = this._activeFiltersService.activeFilters$.subscribe((filters) => {
      const hasSearchFilter = filters.some((f) => f.key === "search");
      if (this.initSearch && !hasSearchFilter) {
        this.searchCtrl.setValue('', { emitEvent: false }); 
        this.load()
      }
      this.initSearch = hasSearchFilter;
    });
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.load();
      },
      columns: [
        {
          field: 'statusBadge',
          header: 'Status paketa',
          type: 'badge',
          isFrozen: true,
          badgeClass: 'packages-',
          // columns: [
          //   {
          //     header: '',
          //     field: 'endDate',
          //     type: 'text',
          //   },
          // ],
        },
        {
          field: 'number',
          header: 'Broj ugovora',
          type: 'text',
          isFrozen: true,
        },
        {
          field: 'activatedOn',
          header: 'Datum aktivacije',
          type: 'text',
        },
        {
          header: 'Klijent',
          field: 'client',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
          columns: [
            {
              header: '',
              field: 'clientPhone',
              type: 'text',
            },
          ],
        },
        {
          field: 'package',
          header: 'Vrsta Paketa',
          type: 'text',
        },
        {
          field: 'vehicle',
          header: 'Vozilo',
          type: 'text',
        },
        {
          field: 'licencePlate',
          header: 'Reg. oznake',
          type: 'text',
        },
        {
          field: 'availableServices',
          header: 'Dostupno',
          type: 'text',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Započni asistenciju preko paketa',
          svgName: 'assistance',
          actionClass: 'p-2',
          isVisible: !this.isReadonly,
          actionSeverity: ButtonSeverity.PRIMARY,
          actionStyle: ButtonStyle.BASIC,
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IClientPackagesTableResponse =
              this._getPaginatedItem(rowIdx);
            return rowData.statusBadge.value === ClientPackageStatus.ACTIVE;
          },
          callback: (rowIdx: number) => {
            const rowData: IClientPackagesTableResponse =
              this._getPaginatedItem(rowIdx);
            this._locationSosService.routeToCreateAssitanceForm(rowData.id);
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Započni asistenciju za keš',
          svgName: 'euro',
          actionClass: 'p-2',
          isVisible: !this.isReadonly,
          actionSeverity: ButtonSeverity.SECONDARY_GREY,
          actionStyle: ButtonStyle.OUTLINED,
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IClientPackagesTableResponse =
              this._getPaginatedItem(rowIdx);
            return rowData.statusBadge.value !== ClientPackageStatus.ACTIVE;
          },
          callback: (rowIdx: number) => {
            const rowData: IClientPackagesTableResponse =
              this._getPaginatedItem(rowIdx);
            this._locationSosService.routeToCreateAssitanceForm(rowData.id);
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Pregledaj',
          icon: 'pi pi-eye',
          shouldDisplayByCondition: (rowIdx: number) => {
            // const rowData: IClientPackagesTableResponse =
            //   this._getPaginatedItem(rowIdx);
            return true;
          },
          callback: (rowIdx: number) => {
            const rowData: IClientPackagesTableResponse =
              this._getPaginatedItem(rowIdx);
            this.service.showPreviewSidebar(rowData.id);
          },
        },
      ],
      emptyTableInfo: {
        header: 'Bez ugovora',
        description: 'Ovde će biti prikazana tabela ugovora',
        btnLabel: 'Dodaj ugovor',
        action: () => alert('ToDo'),
      },
    });
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  load() {
    this._tableService.setIsLoading();

    if (this.searchCtrl.value) {
      this._activeFiltersService.setFilter('search', `Filter: ${this.searchCtrl.value}`);
    }
    this._getList().then((res) => {
      this.paginatedData = res.result;
      this._tableService.resetIsLoading();
    });

    this._getStats().then((res) => {
      this.countClients = res.result.numOfClients.toString();
    });
  }

  loadActivePackagesCount() {
    this._getActivePackagesCount().then((res) => {
      this.countActivePackages = res.result.numOfPackages.toString();
    });
  }

  private async _getList() {
    return await firstValueFrom(
      this._client.getClientPackagesTable(
        new GetClientPackagesTableQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize,
          filter: new ClientPackageTableFilter({
            isActive: this.statusSearch,
            client:
              this.searchOptionCtrl.value === SearchOptions.CLIENT
                ? this.searchCtrl.value
                : undefined,
            phone:
              this.searchOptionCtrl.value === SearchOptions.CLIENT_PHONE
                ? this.searchCtrl.value
                : undefined,
            licencePlate:
              this.searchOptionCtrl.value === SearchOptions.LICENCE_PLATE
                ? this.searchCtrl.value
                : undefined,
            contractNumber:
              this.searchOptionCtrl.value === SearchOptions.CONTRACT_NUM
                ? this.searchCtrl.value
                : undefined,
            paymentTypes: [],
            paymentStatuses: [],
          }),
        }),
      ),
    );
  }

  private async _getActivePackagesCount() {
    return await firstValueFrom(
      this._client.getClientPackagesTableStats(
        new GetClientPackagesTableStatsQuery({
          filter: new ClientPackageTableFilter({
            isActive: true,
            client:
              this.searchOptionCtrl.value === SearchOptions.CLIENT
                ? this.searchCtrl.value
                : undefined,
            phone:
              this.searchOptionCtrl.value === SearchOptions.CLIENT_PHONE
                ? this.searchCtrl.value
                : undefined,
            licencePlate:
              this.searchOptionCtrl.value === SearchOptions.LICENCE_PLATE
                ? this.searchCtrl.value
                : undefined,
            contractNumber:
              this.searchOptionCtrl.value === SearchOptions.CONTRACT_NUM
                ? this.searchCtrl.value
                : undefined,
            paymentTypes: [],
            paymentStatuses: [],
          }),
        }),
      ),
    );
  }

  private async _getStats() {
    return await firstValueFrom(
      this._client.getClientPackagesTableStats(
        new GetClientPackagesTableStatsQuery({
          filter: new ClientPackageTableFilter({
            isActive: this.statusSearch,
            client:
              this.searchOptionCtrl.value === SearchOptions.CLIENT
                ? this.searchCtrl.value
                : undefined,
            phone:
              this.searchOptionCtrl.value === SearchOptions.CLIENT_PHONE
                ? this.searchCtrl.value
                : undefined,
            licencePlate:
              this.searchOptionCtrl.value === SearchOptions.LICENCE_PLATE
                ? this.searchCtrl.value
                : undefined,
            contractNumber:
              this.searchOptionCtrl.value === SearchOptions.CONTRACT_NUM
                ? this.searchCtrl.value
                : undefined,
            paymentTypes: [],
            paymentStatuses: [],
          }),
        }),
      ),
    );
  }

  handleTabChange(idx: number) {
    switch (idx) {
      case 1:
        this.statusSearch = true;
        break;
      case 2:
        this.statusSearch = false;
        break;
      default:
        this.statusSearch = undefined;
    }
    this.load();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}

enum SearchOptions {
  CLIENT = 'Klijent',
  CLIENT_PHONE = 'Telefon',
  LICENCE_PLATE = 'Tablice',
  CONTRACT_NUM = 'Broj Ugovora',
}
