<atlas-page>
  <div class="header-container">
    <div class="title-container">
      <span class="text-3xl font-semibold mt-1"> {{clientName}} </span>
    </div>
    <div class="actions">
      <span (click)="onEllipsisClick($event, overlayPanel)">
        <i class="pi pi-ellipsis-v cursor-pointer"></i>
      </span>
    </div>
  </div>
  <div class="mt-4">
    <p-tabView
      class="tabView mt-5"
      [(activeIndex)]="activeIndex!"
      (activeIndexChange)="handleTabChange($event)"
    >
      <p-tabPanel header="Paketi">
        <atlas-package-tab-client></atlas-package-tab-client>
      </p-tabPanel>
      <p-tabPanel header="Asistencije"></p-tabPanel>
      <p-tabPanel header="Detalji"></p-tabPanel>
    </p-tabView>
  </div>
</atlas-page>

<p-overlayPanel #overlayPanel>
  <div class="overlay-content">
    <div><i class="pi pi-pencil mr-1 gap-1"></i> Izmeni klijenta</div>
    <div><i class="pi pi-ban mr-1 gap-1"></i>Blokiraj klijenta</div>
  </div>
</p-overlayPanel>
