import { CommonModule, NgClass, NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientProfileService } from '@app/cc-leader/services/client-profile.service';
import { ScrollablePageComponent } from '@shared/components/scrollable-page/scrollable-page.component';
import { ClientClient, GetVehicleQueryResponse, VehiclesClient } from '@shared/model/atlas.api';
import { LocationService } from '@shared/services/location.service';
import { AgentCcRoleStr, B2BAgentRoleStr, LeaderCcRoleStr, UserService } from '@shared/services/user.service';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Subscription } from 'rxjs';
import { VehiclePackagesListComponent } from './vehicle-packages-list/vehicle-packages-list.component';

@Component({
  selector: 'atlas-vehicle-details',
  standalone: true,
  imports: [ScrollablePageComponent,
    OverlayPanelModule,
    NgIf,
    CommonModule,
    NgOptimizedImage,
    VehiclePackagesListComponent
  ],
  templateUrl: './vehicle-details.component.html',
  styleUrl: './vehicle-details.component.scss'
})
export class VehicleDetailsComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  clientId: string;
  vehicleId: string;
  isDetailsVisible: boolean = true;
  vehicle: GetVehicleQueryResponse;

  constructor(private locationService: LocationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private clientProfileService: ClientProfileService,
    private vehicleClient: VehiclesClient
  ){
    this.clientId = this.route.snapshot.paramMap.get('id');
    this.vehicleId = this.route.snapshot.paramMap.get('vehicleId');
  }

  ngOnInit(): void {
      this.getVehicleInfo()
  }

  updateDetailsVisibility(isVisible: boolean) {
  this.isDetailsVisible = isVisible;
}

  onEllipsisClick(event: Event, overlayPanel: any) {
    overlayPanel.toggle(event);
  }

  routeToEditVehicle(){
    this.locationService.routeToEditClientVehicle(this.clientId, this.userService.getRoleRoutePrefix(
      AgentCcRoleStr,
      B2BAgentRoleStr,
      LeaderCcRoleStr));
    this.clientProfileService.setVehicleQueryResponse(this.vehicle);
  }

    getVehicleInfo(){
      this._subs.add(
        this.vehicleClient.getVehicle(this.vehicleId).subscribe((res) => {
          this.vehicle = res.result;
        })
      )
    }

    ngOnDestroy(): void {
      this._subs.unsubscribe();
    }

  routeBack() {
    this.locationService.routeBack();
  }
}
