import { Routes } from '@angular/router';
import { AuthGuard } from '@shared/services/auth.guard';
import { CampaignTableComponent } from '@app/cc-agent/campaigns/campaign-table/campaign-table.component';
import { RecommendationTableComponent } from '@app/cc-agent/recommendations/recommendation-table/recommendation-table.component';
import { RenewPackageFormComponent } from '@app/features/call-center/renew-package-form/renew-package-form.component';
import {
  ClientWithPackagesPageComponent
} from "@app/features/call-center/client-with-packages-page/client-with-packages-page.component";
import {CreateOrderFormComponent} from "@app/features/call-center/create-order-form/create-order-form.component";
import {
  CreateOrderFormStep1Component
} from "@app/features/call-center/create-order-form/create-order-form-step-1/create-order-form-step-1.component";
import {
  CreateOrderFormStep2Component
} from "@app/features/call-center/create-order-form/create-order-form-step-2/create-order-form-step-2.component";
import {
  CreateOrderFormStep3Component
} from "@app/features/call-center/create-order-form/create-order-form-step-3/create-order-form-step-3.component";

export const ccAgentRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'clients',
        pathMatch: 'full',
      },
      {
        path: 'clients',
        children: [
          {
            path: '',
            canActivate: [AuthGuard],
            component: ClientWithPackagesPageComponent,
          },
          {
            path: 'create',
            canActivate: [AuthGuard],
            component: CreateOrderFormComponent,
            children: [
              {
                path: 'step-1',
                canActivate: [AuthGuard],
                component: CreateOrderFormStep1Component,
              },
              {
                path: 'step-2',
                canActivate: [AuthGuard],
                component: CreateOrderFormStep2Component,
              },
              {
                path: 'step-3',
                canActivate: [AuthGuard],
                component: CreateOrderFormStep3Component,
              },
            ],
          },
          {
            path: 'renew-package',
            canActivate: [AuthGuard],
            component: RenewPackageFormComponent,
          },
          {
            path: 'renew-package/:id',
            canActivate: [AuthGuard],
            component: RenewPackageFormComponent,
          },
        ],
      },
      {
        path: 'campaigns',
        children: [
          {
            path: '',
            component: CampaignTableComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'recommendations',
        canActivate: [AuthGuard],
        component: RecommendationTableComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'contracts',
  },
];
