<div class="flex flex-column gap-2">
  <label class="text-sm font-medium mb-1">Izvor preporuke</label>
  <atlas-input-search
    (onChange)="onSelectChange($event)"
    (onSearch)="searchClients($event)"
    [suggestions]="clients"
    [shouldReset]="false"
    [isDisabled]="false"
    [ctrlPlaceholder]="'Izvor preporuke'"
    fieldName="name"
    [formCtrlClass]="
      formCtrl.invalid && formCtrl.touched
        ? 'w-full border-error'
        : 'w-full'
    "
    class="mb-2"
  ></atlas-input-search>
</div>

<atlas-error-required
        *ngIf="shouldDisplayRequiredError(formCtrl)"
        [formCtrl]="formCtrl"></atlas-error-required>
