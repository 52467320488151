import { JsonPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { FileUploadComponent } from '@ui/file-upload/file-upload.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { InputTextareaComponent } from '@ui/input-textarea/input-textarea.component';
import { VehicleTypeCardComponent } from '@ui/vehicle-type-card/vehicle-type-card.component';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ActionPriceCardComponent } from '../../packages/action-price-card/action-price-card.component';
import { PackageDetailAutocompleteComponent } from '../../packages/package-detail-autocomplete/package-detail-autocomplete.component';
import { finalize, Subscription } from 'rxjs';
import {
  CreatePromoCodeCommand,
  ICreatePromoCodeCommand,
  IPackageItem,
  PackageItem,
  PackagesClient,
  PriceValueType,
  PromoCodeCountType,
  PromoCodeFromType,
  PromoCodesClient,
  PromoCodeToType,
  UpdatePromoCodeCommand,
} from '@shared/model/atlas.api';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { LocationService } from '@shared/services/location.service';
import { markFormGroupTouched } from '@shared/forms/forms-common';
import { AlertService } from '@shared/services/alert.service';
import { ErrorRequiredComponent } from '@ui/errors/error-required/error-required.component';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import { InputFilterComponent } from '@ui/input-filter/input-filter/input-filter.component';
import {
  PromoCodeForm,
  PromoCodeService,
} from '@app/administrator/promo-codes/promo-code-service';
import { LoaderService } from '@shared/components/loader/loader.service';
import {ScrollablePageComponent} from "@shared/components/scrollable-page/scrollable-page.component";

export enum Mode {
  NEW = 1,
  EDIT = 2,
}

@Component({
  selector: 'atlas-promo-code-form',
  standalone: true,
    imports: [
        DividerModule,
        ScrollPanelModule,
        FormsModule,
        NgClass,
        InlineWrapperComponent,
        InputTextComponent,
        InputTextareaComponent,
        RadioButtonModule,
        NgFor,
        NgIf,
        ReactiveFormsModule,
        FormControlPipe,
        ButtonComponent,
        VehicleTypeCardComponent,
        CheckboxModule,
        InputAddonComponent,
        FileUploadComponent,
        PackageDetailAutocompleteComponent,
        JsonPipe,
        ActionPriceCardComponent,
        CalendarModule,
        DropdownModule,
        DropdownComponent,
        ErrorRequiredComponent,
        InputPhoneComponent,
        InputFilterComponent,
        ScrollablePageComponent,
    ],
  templateUrl: './promo-code-form.component.html',
  styleUrl: './promo-code-form.component.scss',
})
export class PromoCodeFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  allPackages: IPackageItem[] = [];
  form: PromoCodeForm;
  PromoCodeCountType = PromoCodeCountType;
  public isSviSelected: boolean = false;
  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;
  mode: Mode;
  submitBtnLabel: string = 'Dodaj promo kod';
  pageLabel: string = 'Dodavanje promo koda';
  currentPromoCodeId: string;
  selectedActivityTypes: string[] = [];
  addonOptions: { label: string; value: PriceValueType }[];

  constructor(
    private _packageClient: PackagesClient,
    private _service: PromoCodeService,
    private _locationService: LocationService,
    private _alertService: AlertService,
    private _promoCodeClient: PromoCodesClient,
    private _loaderService: LoaderService,
  ) {}
  ngOnInit(): void {
    this.form = this._service.initCodeForm();

    this._subs.add(
      this._service.getSelectedPromoCodeId.subscribe((res) => {
        this.currentPromoCodeId = res;
      }),
    );

    if (this.currentPromoCodeId) {
      this.mode = Mode.EDIT;
      this.submitBtnLabel = 'Izmeni promo kod';
      this.pageLabel = 'Izmena promo koda';
      this._getPromoCodeById();
    } else {
      this.mode = Mode.NEW;
    }

    this.addonOptions = [
      { label: 'RSD', value: PriceValueType.CASH },
      { label: '%', value: PriceValueType.PERCENT },
    ];

    this._loadPackages();
  }

  private _getPromoCodeById() {
    this._subs.add(
      this._promoCodeClient
        .getPromoCode(this.currentPromoCodeId)
        .subscribe((res) => {
          const promoCode = res.result as ICreatePromoCodeCommand;
          this.form.patchValue(promoCode);

          this.selectedActivityTypes = promoCode.priceIds || [];

          const packageIdsArray = this.form.controls.priceIds as FormArray;
          packageIdsArray.clear();

          this.selectedActivityTypes.forEach((pkgId: string) => {
            packageIdsArray.push(new FormControl<string>(pkgId));
          });

          this.form.controls.priceIds.updateValueAndValidity();

          this.isSviSelected = this.selectedActivityTypes.includes('svi');
        }),
    );
  }

  private _loadPackages() {
    this._subs.add(
      this._packageClient.getPublicPackages().subscribe((res) => {
        this.allPackages = res.result.responseList.filter(
          (pkg) => pkg.isActive,
        );
        this.allPackages.unshift(
          new PackageItem({
            priceId: 'svi',
            name: 'Svi',
          }),
        );
      }),
    );
  }

  handlePackageServiceAdd(event: any) {
    const selectedPackages = event.checked;
    this.isSviSelected = selectedPackages.includes('svi');
    const packageIdsArray = this.form.controls.priceIds as FormArray;
    packageIdsArray.clear();

    if (this.isSviSelected) {
      this.allPackages.forEach((pkg: IPackageItem) => {
        if (pkg.priceId !== 'svi') {
          packageIdsArray.push(new FormControl<string>(pkg.priceId));
        }
      });
      this.selectedActivityTypes = ['svi'];
    } else {
      selectedPackages.forEach((pkgId: string) => {
        packageIdsArray.push(new FormControl<string>(pkgId));
      });
      this.selectedActivityTypes = selectedPackages;
    }

    this.form.controls.priceIds.updateValueAndValidity();
  }

  handleOnWebChange(event: any) {
    this.form.controls.isOnWeb.setValue(event.checked);
  }

  handleOnMobileChange(event: any) {
    this.form.controls.isOnMobile.setValue(event.checked);
  }

  onStartRadioButtonChange(value: PromoCodeFromType) {
    const fromControl = this.form.controls.from;
    this.form.controls.fromType.setValue(value);

    if (value === PromoCodeFromType.NOW) {
      fromControl.reset();
      fromControl.disable();
    } else {
      fromControl.enable();
    }
  }

  onRadioButtonChange(value: PromoCodeCountType) {
    const countTypeControl = this.form.controls.countType;
    const countControl = this.form.controls.count;
    const toTypeControl = this.form.controls.toType;

    if (value === PromoCodeCountType.UNLIMITED) {
      countTypeControl.setValue(PromoCodeCountType.UNLIMITED);
      countControl.reset();
      countControl.disable();

      if (toTypeControl.value === PromoCodeToType.TOTAL_COUNT) {
        toTypeControl.setValue(PromoCodeToType.UNLIMITED);
      }
    } else {
      countTypeControl.setValue(PromoCodeCountType.TOTAL_NUMBER);
      countControl.enable();
    }
  }

  onEndRadioButtonChange(value: PromoCodeToType) {
    const toControl = this.form.controls.to;
    this.form.controls.toType.setValue(value);

    if (
      value === PromoCodeToType.UNLIMITED ||
      value === PromoCodeToType.TOTAL_COUNT
    ) {
      toControl.reset();
      toControl.disable();
    } else {
      toControl.enable();
    }
  }

  routeToTable() {
    this._locationService.routeToPromoCodes().then();
  }

  handleCancelForm() {
    this.form.reset();
    this.routeToTable();
  }

  handleSubmitForm() {
    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      this._alertService.addWarnMsg('Morate uneti sva obavezna polja');
      return;
    }

    this._loaderService.setShowLoader = true;

    if (this.mode == Mode.NEW) {
      this._subs.add(
        this._promoCodeClient
          .createPromoCode(this.form.value as CreatePromoCodeCommand)
          .pipe(
            finalize(() => {
              this._loaderService.reset();
            }),
          )
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.result);
            this.routeToTable();
          }),
      );
    } else {
      this.form.controls.id?.patchValue(this.currentPromoCodeId);
      this._subs.add(
        this._promoCodeClient
          .updatePromoCode(this.form.value as UpdatePromoCodeCommand)
          .pipe(
            finalize(() => {
              this._loaderService.reset();
            }),
          )
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.result);
            this.routeToTable();
          }),
      );
    }
  }

  private clearForm() {
    this.form = undefined;
    this._service.reset();
    this._service.setSelectedPromoCodeId = undefined;
  }

  ngOnDestroy(): void {
    this.clearForm();
    this._subs.unsubscribe();
  }
}
