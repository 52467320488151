import { NgIf, NgOptimizedImage } from '@angular/common';
import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  IterableDiffers,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VehicleTypeItem } from '@shared/model/atlas.api';
import { CheckboxComponent } from '@ui/checkbox/checkbox.component';
import { CheckboxModule } from 'primeng/checkbox';
import { Subscription } from 'rxjs';
import { getVehicleTypeIcon } from '@shared/functions/functions';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';

@Component({
  selector: 'atlas-vehicle-type-card',
  standalone: true,
  imports: [
    CheckboxComponent,
    CheckboxModule,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    NgOptimizedImage,
    IconCheckboxComponent,
  ],
  templateUrl: './vehicle-type-card.component.html',
  styleUrl: './vehicle-type-card.component.scss',
})
export class VehicleTypeCardComponent implements OnInit, DoCheck, OnChanges {
  typeImageUrl: string;
  isSelected: boolean = false;
  iterableDiffer: any;

  @Input() type: VehicleTypeItem;
  @Input() selectedTypes: string[] = [];
  @Output() typeId = new EventEmitter<string>();

  constructor(private iterableDiffers: IterableDiffers) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  ngOnInit() {
    this.typeImageUrl = getVehicleTypeIcon(this.type.name);
  }

  handleCheckboxAdd() {
    this.typeId.emit(this.type.typeId);
  }

  handleWrapperClick() {
    const isSelected = this.selectedTypes.includes(this.type.typeId);
    if (isSelected) {
      this.selectedTypes = this.selectedTypes.filter(
        (id) => id !== this.type.typeId,
      );
    } else {
      this.selectedTypes = [...this.selectedTypes, this.type.typeId];
    }
    this.typeId.emit(this.type.typeId);
  }

  ngDoCheck() {}

  ngOnChanges(changes: SimpleChanges): void {
    let c = this.iterableDiffer.diff(this.selectedTypes);
    if (c) {
      this.isSelected = this.selectedTypes.includes(this.type.typeId);
    }
  }
}
