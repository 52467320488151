import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploadModule, UploadEvent } from 'primeng/fileupload';

@Component({
  selector: 'atlas-file-upload',
  standalone: true,
  imports: [FileUploadModule, NgIf],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
})
export class FileUploadComponent {
  @Input() isDisabled: boolean;
  @Input() imageUri: string;
  @Output() onUpload = new EventEmitter<File>();
  @Output() onRemove = new EventEmitter<File>();

  handleUpload(event: any, fileUpload: any) {
    this.onUpload.emit(event.files[0]);
    this.imageUri = URL.createObjectURL(event.files[0]);

    fileUpload.clear();
  }

  removeImage(event: any) {
    console.log(event)
    this.imageUri = '';
    this.onRemove.emit(event?.files[0]);
  }
}
