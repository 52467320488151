<div>
    <div class="mb-4">
        <p-selectButton
          [options]="stateOptions"
          [(ngModel)]="stateValue"
          optionLabel="label"
          optionValue="value"
          [allowEmpty]="false"
          (onChange)="handleStateChange($event)"
        />
    </div>

    <div class="mb-4">
        <div class="mb-5 font-semibold text-base">Način isporuke</div>
        <atlas-dropdown
          [ctrlLabel]="'Način isporuke'"
          [options]="deliveryType"
          [formCtrl]="deliveryTypeCtrl"
          ctrlStyleClass="w-17rem"
        ></atlas-dropdown>
    </div>

    <div class="mt-4 mb-4">
        <div class="mb-5 font-semibold text-base">Način plaćanja</div>
            <atlas-dropdown
              *ngIf="stateValue === 'draft'"
              [ctrlLabel]="'Način plaćanja'"
              [options]="draftPaymentTypeOptions"
              [formCtrl]="paymentTypeCtrl"
              ctrlStyleClass="w-17rem"
            ></atlas-dropdown>
            <atlas-dropdown
              *ngIf="stateValue === 'new'"
              [ctrlLabel]="'Način plaćanja'"
              [options]="newPaymentTypeOptions"
              [formCtrl]="paymentTypeCtrl"
              ctrlStyleClass="w-17rem"
            ></atlas-dropdown>
    </div>
</div>
