import { Component } from '@angular/core';
import { PageComponent } from '@shared/components/page/page.component';
import { RecommendationTableComponent } from '@app/features/recommendations/recommendation-table/recommendation-table.component';
import { RecommendationFormService } from '@app/features/recommendations/add-recommendation-form/recommendation-form.service';

@Component({
  selector: 'atlas-recommendation-page',
  standalone: true,
  imports: [PageComponent, RecommendationTableComponent],
  templateUrl: './recommendation-page.component.html',
  styleUrl: './recommendation-page.component.scss',
})
export class RecommendationPageComponent {
  constructor(private _formService: RecommendationFormService) {}

  handleAction() {
    this._formService.setShowAddDialog = true;
  }
}
