import { CommonModule, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ImagePreviewComponent } from '@shared/components/image-preview/image-preview/image-preview.component';
import { DialogModule } from 'primeng/dialog';
import { GalleriaModule } from 'primeng/galleria';

@Component({
  selector: 'atlas-path-tab-sidebar',
  standalone: true,
  imports: [ImagePreviewComponent, NgIf, NgOptimizedImage, CommonModule, GalleriaModule, DialogModule],
  templateUrl: './path-tab-sidebar.component.html',
  styleUrl: './path-tab-sidebar.component.scss'
})
export class PathTabSidebarComponent implements OnInit {

  displayComment: boolean = false;
  @Input() previewData: any;

  ngOnInit(): void {
   console.log(this.previewData)
  }

  showComment() {
    this.displayComment = true;
  }
}
