import { DatePipe, NgFor, NgIf, NgOptimizedImage} from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { InputDescriptionComponent } from '@ui/input-description/input-description/input-description.component';
import { Subscription } from 'rxjs';
import { DividerModule } from "primeng/divider";
import { AddCommentCommand, ClientClient } from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { AddCommentForm, ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { markFormGroupTouched } from '@shared/forms/forms-common';

@Component({
  selector: 'atlas-comments-client-info',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    InputDescriptionComponent,
    ButtonComponent,
    NgOptimizedImage,
    DatePipe,
    DividerModule,
  ],
  templateUrl: './comments-client-info.component.html',
  styleUrl: './comments-client-info.component.scss',
})
export class CommentsClientInfoComponent implements OnDestroy {
  @Input() clientId: string;
  @Input() agentImgUrl: string;
  @Input() isLoading: boolean;
  @Input() comments: any[] = [];

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  private _subs: Subscription = new Subscription();
  form: AddCommentForm;

  constructor(
    private client: ClientClient,
    private service: ClientPackageTableService,
    private _alertService: AlertService
  ) {
    this.form = this.service.getAddCommentForm();
  }
  
  addComment() {
    if (!this.form?.valid) {
          markFormGroupTouched(this.form);
          this._alertService.addWarnMsg('Potrebno je uneti tekst komenetara!');
          return;
        }

    this._subs.add(
      this.client
        .addComment(
          new AddCommentCommand({
            comment: this.form.value.text,
            clientId: this.clientId,
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result.message);
          this.form.reset();
          this.loadComments();
        }),
    );
  }

  loadComments() {
    this._subs.add(
      this.client.getComments(this.clientId).subscribe((response) => {
        this.comments = response.result.commentItems;
        this.isLoading = false;
      }),
    );
  }

  ngOnDestroy(): void {
    this.form?.reset();
    this._subs.unsubscribe();
  }
}
