<ng-container>
  <div *ngIf="displayStatistics" class="table-with-stats">
    <div class="header-text">
      <div class="icon-wrapper">
        <div class="icon-text-container">
          <div class="icon-wrap">
            <i class="pi pi-box icon"></i>
          </div>
          <div class="text-wrap">
            <div class="title h-2rem">Paketi</div>
            <div class="count">{{ stats.numOfPackages }}</div>
          </div>
        </div>

        <div class="icon-text-container left-spacing">
          <div class="icon-wrap">
            <i class="pi pi-users icon"></i>
          </div>
          <div class="text-wrap">
            <div class="title h-2rem">Klijenti</div>
            <div class="count">{{ stats.numOfClients }}</div>
          </div>
        </div>

        <div class="icon-text-container left-spacing">
          <div class="icon-wrap">
            <i class="pi pi-users icon"></i>
          </div>
          <div class="text-wrap">
            <div class="title h-2rem">Agenti</div>
            <div class="count">{{ stats.numOfAgents }}</div>
          </div>
        </div>
      </div>
      <div class="view-contracts">
        <a (click)="toggleTable()">
          Vidi ugovore
          <i
            [ngClass]="showTable ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
            class="small-icon"
          ></i>
        </a>
      </div>
    </div>
    <ng-container *ngIf="showTable">
      <ng-container *ngTemplateOutlet="tableWithSearch"></ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="!displayStatistics">
    <ng-container *ngTemplateOutlet="tableWithSearch"></ng-container>
  </ng-container>
</ng-container>

<ng-template #tableWithSearch>
  <div class="page-title" *ngIf="pageTitle">
    {{ pageTitle }}
    <div class="page-title-desc">{{ pageDescription }}</div>
  </div>
  <div [ngClass]="'flex justify-content-end'">
    <div class="search-bar">
      <atlas-dropdown-input-button
        [searchOptionCtrl]="filterService.mainDropdownCtrl"
        [searchCtrl]="filterService.mainSearchCtrl"
        [searchOptions]="filterService.mainDropdownOptions"
        (onAction)="loadLazy()"
      >
      </atlas-dropdown-input-button>
    </div>
    <div class="filters-button h-full ml-2">
      <atlas-button
        btnIcon="pi pi-sliders-h"
        class="filters-button"
        [btnStyle]="ButtonStyle.BASIC"
        [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
        btnLabel="Filteri"
        (btnClicked)="service.showFilterSidebar('')"
      ></atlas-button>
    </div>
  </div>
  <div class="mt-3 mb-3 w-full">
    <atlas-active-filters-client></atlas-active-filters-client>
  </div>

  <p-table
    *ngIf="isLoading || paginatedData.totalCount > 0; else emptyTable"
    dataKey="id"
    [value]="isLoading ? skeletonItems : paginatedData.data"
    [tableStyle]="{ 'min-width': '60rem' }"
    [expandedRowKeys]="expandedRows"
    (onRowExpand)="onRowExpand($event)"
    [reorderableColumns]="true"
    [resizableColumns]="false"
    [rowsPerPageOptions]="[5, 10, 25, 50]"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Prikazano {first} - {last} od {totalRecords} entiteta"
    (onRowCollapse)="onRowCollapse($event)"
    [paginator]="true"
    [rows]="paginatedData.pageSize"
    [totalRecords]="paginatedData.totalCount"
    [lazy]="true"
    (onLazyLoad)="loadLazy($event)"
    [lazyLoadOnInit]="false"
    [scrollable]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th
          class="table-header cursor-pointer"
          style="width: 3rem; padding: 16px"
          (click)="isAllExpanded ? collapseAll() : expandAll()"
        >
          <i
            [ngClass]="{
              'pi-chevron-right': !isAllExpanded,
              'pi-chevron-down': isAllExpanded
            }"
            class="pi icon-color"
          >
          </i>
        </th>
        <th class="table-header" style="width: 12rem">KLIJENT</th>
        <th class="table-header" style="width: 20rem">BROJ PAKETA</th>
        <th class="table-header" style="width: 10rem">PRODAVAC</th>
        <th class="table-header" style="width: 12rem; text-align: start">
          PRIVREMENI PRODAVAC
        </th>
        <th class="table-header" style="width: 5rem; text-align: center"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-client let-expanded="expanded">
      <tr *ngIf="!isLoading" class="fadein">
        <td (click)="toggleRow(client)" class="cursor-pointer">
          <i
            [ngClass]="
              expanded
                ? 'pi pi-chevron-down icon-color'
                : 'pi pi-chevron-right icon-color'
            "
            class="expand-icon"
          >
          </i>
        </td>
        <td>
          <div
            style="display: flex; align-items: center"
            (mouseenter)="onHover(client)"
            (mouseleave)="onLeave(client)"
          >
            <ng-container
              *ngIf="!client.hovered || !canMultiselect; else showCheckbox"
            >
              <img
                ngSrc="../../../../assets/images/client-icon.svg"
                class="agent-image"
                alt="Client Icon"
                width="40"
                height="40"
              />
            </ng-container>

            <ng-template #showCheckbox>
              <p-checkbox
                class="hover-td"
                [binary]="true"
                [ngModel]="client.selected"
                (onChange)="selectAllPackages(client)"
                [ngClass]="{ 'hovered-checkbox': expandedRows[client.id] }"
              ></p-checkbox>
            </ng-template>

            <!-- Client Info -->
            <div>
              <div class="client-name">{{ client.name }}</div>
              <small class="light-name">{{ client.phone }}</small>
            </div>
          </div>
        </td>
        <td class="padding-cell">{{ client.numOfPackages }} paketa</td>
        <td class="agent-cell">
          <img
            [ngSrc]="
              client.sellerImage
                ? client.sellerImage
                : 'assets/images/client-icon.svg'
            "
            [alt]="client.seller"
            class="agent-image"
            width="40"
            height="40"
          />
          {{ client.seller }}
        </td>
        <td class="padding-cell">
          <div class="flex align-items-center gap-2" *ngIf="client.tempSeller">
            <img
              [ngSrc]="
                client.tempSellerImage
                  ? client.tempSellerImage
                  : 'assets/images/client-icon.svg'
              "
              [alt]="client.tempSeller"
              class="agent-image"
              width="40"
              height="40"
            />
            <span>
              {{ client.tempSeller }}
            </span>
          </div>
        </td>
        <!-- Komentari -->
        <td class="comment-cell">
          <atlas-button
            (click)="showComments(client.id)"
            [btnTooltip]="client.commentsNum + ' komentara'"
          >
            <img
              ngSrc="../../../../assets/images/comment-Icon.svg"
              alt="Comments"
              width="18"
              height="18"
            />
          </atlas-button>
        </td>
      </tr>

      <tr *ngIf="isLoading" class="">
        <td (click)="toggleRow(client)" class="cursor-pointer">
          <p-skeleton height="70px"></p-skeleton>
        </td>
        <td>
          <p-skeleton height="70px"></p-skeleton>
        </td>
        <td class="padding-cell"><p-skeleton height="70px"></p-skeleton></td>
        <td class="padding-cell">
          <p-skeleton height="70px"></p-skeleton>
        </td>
        <td class="padding-cell">
          <p-skeleton height="70px"></p-skeleton>
        </td>
        <!-- Komentari -->
        <td class="comment-cell">
          <p-skeleton height="70px"></p-skeleton>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-client>
      <tr *ngIf="!isLoading">
        <td colspan="6">
          <div class="expanded-content">
            <div
              class="packages-list"
              [ngClass]="{ 'hide-more-options': hideMoreOptions }"
            >
              <div *ngFor="let p of client.packages" class="package-row">
                <div class="empty-box"></div>

                <div class="package-column client-name">
                  <p-checkbox
                    [(ngModel)]="p.selected"
                    [label]="p.packageName"
                    [value]="p.packageName"
                    (onChange)="handleCheckboxChange($event, p, client)"
                    [binary]="true"
                    *ngIf="canMultiselect"
                  >
                  </p-checkbox>
                </div>

                <div class="vehicle-column">
                  <span class="client-name">{{ p.vehicle || "/" }}</span>
                  <span class="light-name">{{
                    p.licencePlate | licencePlate
                  }}</span>
                </div>

                <div class="status-column">
                  <span
                    class="custom-badge"
                    [ngClass]="{
                      'badge-success': p.activeBadge.value,
                      'badge-danger': !p.activeBadge.value
                    }"
                  >
                    <span class="status-dot"></span>
                    {{ p.activeBadge.label }}
                  </span>
                </div>

                <div class="expiration-column light-name">
                  <i class="pi pi-calendar"></i> {{ p.end }}
                </div>

                <div class="renew-column">
                  <p-button
                    label="Obnovi paket"
                    icon="pi pi-refresh"
                    class="custom-renew-button"
                    (click)="handleRenewPackage(p)"
                    *ngIf="canRenewPackage"
                  ></p-button>
                </div>

                <div class="more-options-column" *ngIf="!hideMoreOptions">
                  <i
                    (click)="openOptionsDialog(p.clientPackageId)"
                    class="pi pi-ellipsis-v cursor-pointer"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>

      <tr *ngIf="isLoading">
        <td colspan="6">
          <div class="expanded-content">
            <div class="packages-list">
              <p-skeleton></p-skeleton>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>

<atlas-checkbox-action-dialog
  [canRenewPackage]="canRenewPackage"
  [distinctVehicleCount]="distinctVehicleCount"
  (sellerChanged)="loadLazy()"
></atlas-checkbox-action-dialog>

<atlas-package-detail-sidebar></atlas-package-detail-sidebar>
<atlas-client-with-packages-table-filter></atlas-client-with-packages-table-filter>

<ng-template #emptyTable>
  <div class="table-empty-box">
    <div class="title">Bez klijenata</div>
    <div class="desc">
      Ovde će biti prikazana lista klijenata sa njihovim paketima
    </div>
  </div>
</ng-template>
