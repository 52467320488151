import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import { AddVehicleCommand, GetVehicleQueryResponse, IAddVehicleCommand } from '@shared/model/atlas.api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientProfileService {

  constructor(
    private _fb: FormBuilder
  ) { }

  private vehicleQueryResponse = new BehaviorSubject<GetVehicleQueryResponse>(null);

  setVehicleQueryResponse(vehicle: GetVehicleQueryResponse) {
    this.vehicleQueryResponse.next(vehicle);
  }

  getVehicleQueryResponse() {
    return this.vehicleQueryResponse.asObservable();
  }

  getClientVehicleForm(): ClientVehicleForm {
    return this._fb.group<ControlsOf<IAddVehicleCommand>>({
      clientId: this._fb.control(null),
      vehicleId: this._fb.control(null),
      vehicleType: this._fb.control(null, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(32)]
      }),
      vehicleBrand: this._fb.control(null, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(32)]
      }),
      vehicleModel: this._fb.control(null, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(32)]
      }),
      productionYear: this._fb.control(null),
      color: this._fb.control(null),
      licencePlate: this._fb.control(null, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(16)]
      }),
      chassisNumber: this._fb.control(null),
      registrationTo: this._fb.control(null),
      registrationCertTo: this._fb.control(null)
    })
  }


}

export declare type ClientVehicleForm = FormGroup<ControlsOf<IAddVehicleCommand>>;
