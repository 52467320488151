import {
  NgFor,
  NgIf,
  NgClass,
  NgForOf,
  JsonPipe,
  CommonModule,
  CurrencyPipe,
} from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrandAutocompleteComponent } from '@app/features/autocompletes/brand-autocomplete/brand-autocomplete.component';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { CheckboxComponent } from '@ui/checkbox/checkbox.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { CurrencyNumberPipe } from '@shared/pipes/currency-number.pipe';
import {DeliveryType, IPackageCard, PaymentType} from '@shared/model/atlas.api';
import {
  OrderForm,
  OrderFormService,
} from '@app/cc-leader/services/order-form.service';
import { PackageCardListService } from '@app/cc-leader/services/package-card-list.service';
import {
  SelectButtonChangeEvent,
  SelectButtonModule,
} from 'primeng/selectbutton';
import { SelectItem } from 'primeng/api';
import {Subscription} from "rxjs";
import {ControlsOf} from "@shared/forms/forms-common";

registerLocaleData(localeDe);

@Component({
  selector: 'atlas-create-order-form-step-3',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgClass,
    NgForOf,
    CheckboxModule,
    InputTextComponent,
    InputPhoneComponent,
    InputMailComponent,
    InputAddonComponent,
    DropdownComponent,
    IconCheckboxComponent,
    BrandAutocompleteComponent,
    FormsModule,
    FormControlPipe,
    JsonPipe,
    RadioButtonModule,
    CheckboxComponent,
    ReactiveFormsModule,
    CommonModule,
    CurrencyPipe,
    CurrencyNumberPipe,
    SelectButtonModule,
  ],
  templateUrl: './create-order-form-step-3.component.html',
  styleUrl: './create-order-form-step-3.component.scss',
})
export class CreateOrderFormStep3Component implements OnInit, OnDestroy {
    private _subs: Subscription = new Subscription();
  orderForm: OrderForm;
  packages: FormArray<FormGroup<ControlsOf<IPackageCard>>>;
  packagesSum: number = 0;
  stateOptions: SelectItem[] = [
    {
      label: 'Želim da kupovinu završim preko mobilne aplikacije',
      value: 'draft',
    },
    {
      label: 'Ne želim da kupovinu završim preko mobilne aplikacije',
      value: 'new',
    },
  ];
  stateValue: 'new' | 'draft' = 'draft';

    paymentTypeCtrl: FormControl<SelectItem | null>;
    paymentTypeOptions: SelectItem<PaymentType>[] = [
    { label: 'Corvus Pay', value: PaymentType.CORVUS_PAY },
    { label: 'Gotovina', value: PaymentType.CASH },
    { label: 'Uplatnica', value: PaymentType.PAYMENT_SLIP },
  ];

  deliveryTypeCtrl: FormControl<SelectItem | null>;
  deliveryTypeOptions: SelectItem<DeliveryType>[] = [
    { label: 'eUgovor', value: DeliveryType.E_CONTRACT },
    { label: 'Lično preuzimanje', value: DeliveryType.PICK_UP },
    { label: 'Bex', value: DeliveryType.BEX },
    { label: 'Bex besplatna', value: DeliveryType.BEX_FREE },
    { label: 'Bex bez otkupa', value: DeliveryType.BEX_WITHOUT_DEPOSIT },
    { label: 'Kurir NS', value: DeliveryType.COURIER_NS },
    { label: 'Promo-sezonac', value: DeliveryType.PROMO_SEASONAC },
  ];

  activationOptions: any = [
    {
      name: 'Aktivacija odmah (paket se aktivira odmah nakon evidentiranja uplate)',
      value: true,
    },
    {
      name: 'Standardna aktivacija (7 dana od evidentiranja uplate)',
      value: false,
    },
  ];

  constructor(
    private _orderFormService: OrderFormService,
    private _packageListService: PackageCardListService,
  ) {
    this.orderForm = this._orderFormService.getOrderForm;
    this.packages = this._packageListService.getPackages();
    this.packagesSum = this.packages?.value.reduce(
      (a, b) => a + b.packagePrice,
      0,
    );
    this.deliveryTypeCtrl = new FormControl<SelectItem>(this.deliveryTypeOptions.find((opt) => DeliveryType.E_CONTRACT === opt.value));
    this.paymentTypeCtrl = new FormControl<SelectItem>(this.paymentTypeOptions.find((opt) => PaymentType.CORVUS_PAY === opt.value));
  }

  ngOnInit(): void {
      this.handleStateChange({
          value: 'draft'
      })
      this._subs.add(this.deliveryTypeCtrl.valueChanges.subscribe((x) => {
          if (this.orderForm.controls.deliveryType.value != x.value) {
              this.orderForm.controls.deliveryType.patchValue(x.value);
          }
      }));
      this._subs.add(this.orderForm.controls.deliveryType.valueChanges.subscribe((x) => {
          if (x !== this.deliveryTypeCtrl.value.value) {
              this.deliveryTypeCtrl.patchValue(this.deliveryTypeOptions.find((opt) => x === opt.value));
          }
      }))
      this._subs.add(this.paymentTypeCtrl.valueChanges.subscribe((x: SelectItem<PaymentType>) => {
          if (this.orderForm.controls.paymentType.value != x.value) {
              this.orderForm.controls.paymentType.patchValue(x.value);
          }
      }));
      this._subs.add(this.orderForm.controls.paymentType.valueChanges.subscribe((x) => {
          if (x !== this.paymentTypeCtrl.value.value) {
              this.paymentTypeCtrl.patchValue(this.paymentTypeOptions.find((opt) => x === opt.value));
          }
      }));
  }

  handleStateChange($event: SelectButtonChangeEvent) {
    if ($event.value === 'draft') {
      this._orderFormService.setToDraft();
      this.deliveryTypeCtrl.disable();
      this.paymentTypeCtrl.disable();
    } else {
        this.deliveryTypeCtrl.enable();
        this.paymentTypeCtrl.enable();
        this._orderFormService.setToNew();
    }
  }

  ngOnDestroy() {
      this._subs.unsubscribe();
  }
}
