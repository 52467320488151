<div class="flex wrapper" [class.selected]="isSelected" (click)="onSelect()">
  <!-- Conditionally render either an img or an i element without wrapping the img in icon-wrap -->
  <ng-container *ngIf="imageUrl; else iconTemplate">
    <img [src]="imageUrl" alt="Icon" class="custom-icon">
  </ng-container>
  <ng-template #iconTemplate>
    <div class="icon-wrap" [class.icon-selected]="isSelected">
      <i [ngClass]="[iconClass, 'icon']"></i>
    </div>
  </ng-template>
  
  <div class="container-checkbox">
    <div class="label ml-3">{{ name }}</div>
  </div>
  
  <div class="checkbox-div">
    <div class="checkbox ml-3">
      <p-checkbox [binary]="true" [ngModel]="isSelected"></p-checkbox>
    </div>
  </div>
</div>