import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent, ButtonStyle } from '@ui/button/button.component';
import { NgIf } from '@angular/common';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
  selector: 'atlas-page',
  standalone: true,
  imports: [ButtonComponent, NgIf, ScrollPanelModule],
  templateUrl: './page.component.html',
  styleUrl: './page.component.scss',
})
export class PageComponent {
  @Input() pageTitle: string = '';
  @Input() pageDescription: string = '';
  @Input() actionLabel: string = '';
  @Input() btnIcon: string = '';
  @Output() onHandleAction = new EventEmitter();

  protected readonly ButtonStyle = ButtonStyle;

  handleAction() {
    this.onHandleAction.emit();
  }
}
