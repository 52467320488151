import { NgIf } from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputButtonComponent } from '@shared/components/input-button/input-button.component';
import { CampaignsClient, GetCampaignAgentsQuery, GetLeadsTableQuery, IPaginationResponseOfAgentResponse, IPaginationResponseOfLeadsTableResponse } from '@shared/model/atlas.api';
import { ButtonSeverity } from '@ui/button/button.component';
import { ActionMode, SelectMode, TableConfig } from '@ui/table/table-common';
import { TableServiceLocal } from '@ui/table/table-service-local.service';
import { TableComponent } from '@ui/table/table.component';
import {TableLazyLoadEvent} from "primeng/table/table.interface";
import { TabViewModule } from 'primeng/tabview';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'atlas-lead-campaign-details-table',
  standalone: true,
  imports: [TableComponent,
    TabViewModule,
    InputButtonComponent,
    NgIf
  ],
  templateUrl: './lead-campaign-details-table.component.html',
  styleUrl: './lead-campaign-details-table.component.scss'
})
export class LeadCampaignDetailsTableComponent implements OnInit {

  protected tableConfig: TableConfig;
  protected tableConfigAgents: TableConfig;
  @Input() campaignId: string | undefined;
  protected active: boolean | undefined;
  public activeIndex: number | undefined;
  paginatedData: IPaginationResponseOfLeadsTableResponse;
  paginatedDataAgents: IPaginationResponseOfAgentResponse;
  searchInputValue: FormControl;

  constructor(
    private _client: CampaignsClient,
    private tableService: TableServiceLocal
  ) {
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.tableConfigAgents = new TableConfig({
      columns: [],
    })
    this.tableConfigAgents = {
      selectMode: SelectMode.SINGLE,
      showHeader: true, 
      columns: [],
      rowsPerPage: 10,
    }
    this.tableConfig = {
      selectMode: SelectMode.SINGLE,
      showHeader: true,
      columns: [],
      rowsPerPage: 10,
    };
    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
    this.paginatedDataAgents = {
      data: [],
      currentPage: 0,
    };
    this.searchInputValue = new FormControl<string>('');
  }
  ngOnInit(): void {
    this.setTableConfig().then();
    this.setTableConfigAgents().then();
    
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.load();
      },
      columns: [
        {
          header: 'Klijent/Lead',
          field: 'leadName',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
        },
        {
          header: 'Kontakt',
          field: 'leadPhone',
          type: 'text',
        },
        {
          header: 'Trenutni status',
          field: 'statusBadge',
          type: 'badge',
          badgeClass: 'lead-',
        },
        
        {
          header: 'Poslednja promena',
          field: 'lastChange',
          type: 'text',
        },
        {
          header: 'Agent',
          field: 'agentName',
          type: 'text',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: '',
          svgName: 'comment-Icon',
          callback: (rowIdx: number) => {
          },
          shouldDisplayByCondition: () => true,
        },
        {
          mode: ActionMode.MULTI,
          icon: 'pi pi-pencil',
          label: 'Detaljnije',
          actionSeverity: ButtonSeverity.SECONDARY_GREY,
          callback: (rowIdx: number) => {
            alert('ToDo')
          },
          shouldDisplayByCondition: () => true
        }
      ],
    });
  }

  async setTableConfigAgents(): Promise<void> {
    this.tableConfigAgents = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedDataAgents.currentPage = 0;
        } else {
          this.paginatedDataAgents.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedDataAgents.pageSize = event.rows!;
        this.loadAgents();
      },
      columns: [
        {
          header: 'Kontakt',
          field: 'name',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.MULTI,
          icon: 'pi pi-pencil',
          label: 'Detaljnije',
          actionSeverity: ButtonSeverity.SECONDARY_GREY,
          callback: (rowIdx: number) => {
            alert('ToDo')
          },
          shouldDisplayByCondition: () => true
        }
      ],
    });
  }

  private async loadTable() {
    return await firstValueFrom(
      this._client.getLeadsTable(
        new GetLeadsTableQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize ?? 10,
          campaignId: this.campaignId,
          name: this.searchInputValue.value
          ? this.searchInputValue.value
          : undefined,
          phone: this.searchInputValue.value
          ? this.searchInputValue.value
          : undefined,
        }),
      ),
    );
  }

  private async loadTableAgents() {
    return await firstValueFrom(
      this._client.getAllCampaignAgents(
        new GetCampaignAgentsQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize ?? 10,
          campaignId: this.campaignId,
          clientName: this.searchInputValue.value
          ? this.searchInputValue.value
          : undefined,
          clientPhone: this.searchInputValue.value
          ? this.searchInputValue.value
          : undefined,
        }),
      ),
    );
  }

  loadAgents() {
    this.tableService.setIsLoading();
    this.loadTableAgents().then((res) => {
      this.paginatedDataAgents = res.result;
      this.tableService.resetIsLoading()
    });
  }

  load() {
    this.tableService.setIsLoading();
    this.loadTable().then((res) => {
      this.paginatedData = res.result;
      this.tableService.resetIsLoading();
    });
  }

  handleTabChange(event: number) {
    this.activeIndex = event;
    if (event === 0) {
      this.setTableConfig(); 
      this.load();        
    } else if (event === 1) {
      this.setTableConfigAgents();
      this.loadAgents();          
    }
  }

}
