import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  EmployeesClient,
  IAgentBaseInfoResponse,
} from '@shared/model/atlas.api';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-agent-autocomplete',
  standalone: true,
  imports: [InputSearchComponent],
  templateUrl: './agent-autocomplete.component.html',
  styleUrl: './agent-autocomplete.component.scss',
})
export class AgentAutocompleteComponent implements OnDestroy {
  private _subs: Subscription = new Subscription();
  agents: { id: string; name: string }[] = [];
  agentNames: string[] = [];
  @Output() agentSelected = new EventEmitter<{ id: string; name: string }>();
  resetInput: boolean = false;
  selectedId: string;

  @ViewChild('inputSearch', { static: false })
  inputSearch: InputSearchComponent;

  constructor(private _client: EmployeesClient) {}

  handleSearch(item: any) {
    this.loadServices(item);
  }

  private loadServices(searchValue: string = '') {
    this._subs.add(
      this._client.getAgentAutocomplete(searchValue).subscribe((res) => {
        this.agents = res.result.map((agent: IAgentBaseInfoResponse) => ({
          id: agent.id,
          name: agent.name,
        }));

        this.agentNames = this.agents.map((agent) => agent.name);
      }),
    );
  }

  onSelectChange(item: any) {
    if (!item || !item.value) {
      return;
    }
    const selectedAgent = this.agents.find(
      (agent) => agent.name === item.value,
    );
    if (selectedAgent) {
      this.agentSelected.emit({
        id: selectedAgent.id,
        name: selectedAgent.name,
      });
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
