<atlas-page
  [pageTitle]="pageTitle"
  [pageDescription]="pageDescription"
  btnIcon="pi pi-plus"
  actionLabel="Dodaj klijenta"
  (onHandleAction)="handleCreateClient()"
>
  <atlas-client-with-packages-table></atlas-client-with-packages-table>

</atlas-page>
