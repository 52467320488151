<div class="flex align-items-center">
  <div *ngIf="!imageUri" class="image-wraper mr-4">
    <i class="pi pi-image icon"></i>
  </div>
  <div *ngIf="imageUri">
    <img src="{{ imageUri }}" alt="package-img" class="image mr-4" />
  </div>

  <div>
    <p-fileUpload
    mode="basic"
    name="files[]"
    chooseLabel="Otpremi sliku"
    chooseIcon="pi pi-upload"
    url="https://www.primefaces.org/cdn/api/upload.php"
    accept="image/*"
    maxFileSize="10000000"
    [disabled]="isDisabled"
    [multiple]="false"
    (onSelect)="handleUpload($event)"
  ></p-fileUpload>
  </div>
</div>
