import { JsonPipe, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'atlas-min-length',
  standalone: true,
  imports: [NgIf, JsonPipe],
  templateUrl: './min-length.component.html',
  styleUrl: './min-length.component.scss',
})
export class MinLengthComponent implements OnInit {
  /** I/O */
  @Input() formCtrl!: FormControl<any>;

  errorMsg: string = '';
  errorValue: string = '';

  ngOnInit() {
    this.updateErrorMsg();
    this.formCtrl.valueChanges.subscribe(() => {
      this.updateErrorMsg();
    });
  }

  private updateErrorMsg() {
    const minLengthError = this.formCtrl.errors?.['minlength'];
    const minLengthValue = this.formCtrl.errors?.['min'];
    this.errorMsg = minLengthError
      ? `Minimalan broj karaktera je ${minLengthError.requiredLength}`
      : '';
    this.errorValue = minLengthValue
      ? `Minimalan broj je ${minLengthValue.max}`
      : '';
  }

  shouldDisplay(): boolean {
    return shouldDisplayMinLengthError(this.formCtrl);
  }
}

export function shouldDisplayMinLengthError(
  formCtrl: FormControl<any>,
): boolean {
  return (
    (formCtrl.errors?.['minlength'] || formCtrl.errors?.['min']) &&
    (formCtrl.dirty || formCtrl.touched)
  );
}
