import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

export const AdminRoleStr: string = 'Admin';
export const GuestRoleStr: string = 'Guest';
export const AgentSosRoleStr: string = 'AgentSos';
export const AgentCcRoleStr: string = 'AgentCc';
export const LeaderCcRoleStr: string = 'LeaderCc';
export const FinanceAdminRoleStr: string = 'FinanceAdmin';
export const B2BAgentRoleStr: string = 'AgentB2B';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private _authService: AuthService) {}

  get getUserData(): {
    id: string;
    email: string;
    fullName: string;
    imageUrl: string;
    roles: string | string[];
    friendlyRoles: string | string[];
    ipAddress: string;
  } {
    const decodedJWT = this._authService.getDecodedToken;
    return {
      id: decodedJWT?.[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
      ],
      email:
        decodedJWT?.[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
        ],
      fullName: decodedJWT?.fullName,
      imageUrl:
        decodedJWT?.image_url?.length > 0 ? decodedJWT?.image_url : null,
      ipAddress: decodedJWT?.ipAddress,
      roles:
        decodedJWT?.[
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
        ],
      friendlyRoles: decodedJWT?.['friendlyRoles'],
    };
  }

  get getFriendlyRoles(): string {
    if (this.hasMultipleRoles) {
      return (this.getUserData.friendlyRoles as string[]).join(', ');
    }
    return this.getUserData.friendlyRoles as string;
  }

  isAdmin(): boolean {
    if (this.hasMultipleRoles) {
      return this.getUserData.roles.includes(AdminRoleStr);
    }
    return this.getUserData.roles === AdminRoleStr;
  }

  isSosAgent(): boolean {
    if (this.hasMultipleRoles) {
      return this.getUserData.roles.includes(AgentSosRoleStr);
    }
    return this.getUserData.roles === AgentSosRoleStr;
  }

  isFinanceAdmin(): boolean {
    if (this.hasMultipleRoles) {
      return this.getUserData.roles.includes(FinanceAdminRoleStr);
    }
    return this.getUserData.roles === FinanceAdminRoleStr;
  }

  isCcAgent(): boolean {
    if (this.hasMultipleRoles) {
      return this.getUserData.roles.includes(AgentCcRoleStr);
    }
    return this.getUserData.roles === AgentCcRoleStr;
  }

  isB2bAgent(): boolean {
    if (this.hasMultipleRoles) {
      return this.getUserData.roles.includes(B2BAgentRoleStr);
    }
    return this.getUserData.roles === B2BAgentRoleStr;
  }

  isCcLeader(): boolean {
    if (this.hasMultipleRoles) {
      return this.getUserData.roles.includes(LeaderCcRoleStr);
    }
    return this.getUserData.roles === LeaderCcRoleStr;
  }

  isGuest(): boolean {
    if (this.hasMultipleRoles) {
      return this.getUserData.roles.includes(GuestRoleStr);
    }
    return this.getUserData.roles === GuestRoleStr;
  }

  get hasMultipleRoles() {
    return this.getUserData.roles instanceof Array;
  }

  getRoleRoutePrefix(...validRoles: string[]) {
    if (this.isCcLeader() && validRoles.includes(LeaderCcRoleStr)) {
      return 'leader';
    }
    if (this.isAdmin() && validRoles.includes(AdminRoleStr)) {
      return 'admin';
    }
    if (this.isSosAgent() && validRoles.includes(AgentSosRoleStr)) {
      return 'sos';
    }
    if (this.isCcAgent() && validRoles.includes(AgentCcRoleStr)) {
      return 'cc';
    }
    if (this.isB2bAgent() && validRoles.includes(B2BAgentRoleStr)) {
      return 'b2b';
    }
    alert('route prefix not defined!');
    return 'common';
  }
}
