<div class="flex flex-column gap-2">
  <label class="text-sm font-medium">Pretraga vozača</label>
  <atlas-input-search
    (onChange)="onSelectChange($event)"
    (onSearch)="searchDrivers($event)"
    [suggestions]="drivers"
    [shouldReset]="false"
    [isDisabled]="false"
    [formCtrlClass]="
      sourceIdCtrl.invalid && sourceIdCtrl.touched
        ? 'w-full border-error'
        : 'w-full'
    "
     [ctrlPlaceholder]="driverName || 'Vozači'"
    fieldName="name"
    class="mb-2"
  ></atlas-input-search>
</div>

<atlas-error-required
        *ngIf="shouldDisplayRequiredError(sourceIdCtrl)"
        [formCtrl]="sourceIdCtrl"></atlas-error-required>
