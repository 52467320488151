import { Component, OnDestroy } from '@angular/core';
import { ButtonComponent, ButtonStyle } from '@ui/button/button.component';
import { NgOptimizedImage } from '@angular/common';
import { formatBytes } from '@shared/functions/functions';
import { LeadCampaignFormService } from '@app/cc-leader/services/lead-campaign-form.service';
import { Subscription } from 'rxjs';
import { CampaignsClient, FileParameter } from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';

@Component({
  selector: 'atlas-lead-file-upload',
  standalone: true,
  imports: [ButtonComponent, NgOptimizedImage],
  templateUrl: './lead-file-upload.component.html',
  styleUrl: './lead-file-upload.component.scss',
})
export class LeadFileUploadComponent implements OnDestroy {
  private _subs: Subscription = new Subscription();

  selectedFiles: { name: string; size: string }[] = [];

  constructor(
    private service: LeadCampaignFormService,
    private client: CampaignsClient,
    private _alertService: AlertService,
  ) {}

  onFileSelected($event: any) {
    /*if (file) {
      this.fileName = file.name;
      const formData = new FormData();
      formData.append("thumbnail", file);

      const upload$ = this.http.post("/api/thumbnail-upload", formData, {
        reportProgress: true,
        observe: 'events'
      })
          .pipe(
              finalize(() => this.reset())
          );

      this.uploadSub = upload$.subscribe(event => {
        if (event.type == HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(100 * (event.loaded / event.total));
        }
      })
    }*/

    for (let i = 0; i < $event.target.files.length; i++) {
      const file: FileParameter = {
        fileName: $event.target.files[i].name,
        data: $event.target.files[i],
      };

      this._subs.add(
        this.client
          .validateLeadsFile(undefined, [file])
          .subscribe((response) => {
            this.selectedFiles.push({
              name: file.fileName,
              size: formatBytes($event.target.files[i].size),
            });
            this.service.addFile = file;
          }),
      );
      this.resetFileInput($event.target);
    }
  }

  private resetFileInput(input: HTMLInputElement) {
    input.value = ''; 
  }

  handleFileRemove(file: string) {
    this.selectedFiles = this.selectedFiles.filter((x) => x.name !== file);
    this.service.removeFile(file);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  protected readonly ButtonStyle = ButtonStyle;
}
