import { NgClass, NgFor, NgForOf, NgIf } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageComponent } from '@shared/components/page/page.component';
import { ClientClient, IPaginationResponseOfOrderTableItem, PaginationResponseOfOrderTableItem } from '@shared/model/atlas.api';
import { ButtonStyle, ButtonSeverity, ButtonComponent } from '@ui/button/button.component';
import { ActionMode, SelectMode, TableConfig } from '@ui/table/table-common';
import { TableComponent } from '@ui/table/table.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableLazyLoadEvent, TableService } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { firstValueFrom, Subscription } from 'rxjs';
import { VehicleListClientInfoComponent } from './vehicle-list-client-info/vehicle-list-client-info.component';
import { OwnPackageClientInfoComponent } from "./own-package-client-info/own-package-client-info.component";
import { TableServiceLocal } from '@ui/table/table-service-local.service';

@Component({
  selector: 'atlas-package-tab-client',
  standalone: true,
  imports: [
    OverlayPanelModule,
    TabViewModule,
    TableComponent,
    VehicleListClientInfoComponent,
    OwnPackageClientInfoComponent],
  templateUrl: './package-tab-client.component.html',
  styleUrl: './package-tab-client.component.scss'
})
export class PackageTabClientComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  clientName: string;
  clientId: string;

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  protected tableConfig: TableConfig;
  public paginatedData: IPaginationResponseOfOrderTableItem;

  constructor(private route: ActivatedRoute,
    private client: ClientClient,
    private _tableService: TableServiceLocal,
  ) {
    this.tableConfig = {
      selectMode: SelectMode.SINGLE,
      showHeader: true,
      columns: [],
      rowsPerPage: 10,
    };

    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
    this.clientName = decodeURIComponent(this.route.snapshot.paramMap.get('name') || '');

    this.clientId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.setTableConfig().then();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      emptyTableInfo: {
        header: 'Bez kupljenog paketa',
        description: 'Ovde će biti prikazana lista istorije kupovine paketa',
      },
      isLazy: true,
      lazyCallbackFunction: (event: TableLazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 1;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
    this.load()

      },
      columns: [
        {
          header: 'Ugovor',
          field: 'orderNum',
          type: 'text',
        },
        {
          header: 'Paket',
          field: 'orderpackages',
          type: 'text',
        },
        {
          header: 'Vozilo',
          field: 'vehicleName',
          type: 'text',
        },
        {
          header: 'Prodavac',
          field: 'sellerName',
          type: 'text',
        },
        {
          header: 'Datum',
          field: 'createdOn',
          type: 'text',
        },
        {
          header: 'Napomena',
          field: 'note',
          type: 'text'
        }
      ],
      // rowActions: [
      //   {
      //     mode: ActionMode.MULTI,
      //     label: 'Izmeni',
      //     icon: 'pi pi-pencil',
      //     callback: (rowIdx: number) => {
      //       const rowData: any = this._getPaginatedItem(rowIdx);
      //     },
      //     shouldDisplayByCondition: () => true,
      //   },
      // ],
    });
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  private load(){
    this._tableService.setIsLoading();
    this._loadTable().then((res) => {
      this.paginatedData = res.result;
      this._tableService.resetIsLoading();
    });
  }

  private async _loadTable() {
      return await firstValueFrom(
        this.client.getClientProfileOrders(this.clientId),
      );
    }

  getVehicleIcon(type: string): string {
    return type === 'Putnička vozila' ? 'pi pi-car' : type === 'Kombi' ? 'pi pi-truck' : 'pi pi-question';
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
