import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ActionMode,
  IPaginatedResultTableDto,
  TableAction,
  TableConfig,
} from './table-common';
import { TableRowDataComponent } from './table-row-data/table-row-data.component';
import {
  JsonPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgStyle,
} from '@angular/common';
import { TableModule } from 'primeng/table';
import { TableHeaderComponent } from './table-header/table-header.component';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { LazyLoadEvent } from 'primeng/api';
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'atlas-table',
  standalone: true,
  imports: [
    TableRowDataComponent,
    NgForOf,
    TableModule,
    NgStyle,
    NgClass,
    TableHeaderComponent,
    NgIf,
    ButtonComponent,
    OverlayPanelModule,
    ButtonModule,
    JsonPipe,
    NgOptimizedImage,
    TooltipModule,
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent implements OnInit, OnChanges {
  totalCount: number;
  selectedItem: IPaginatedResultTableDto | any;
  rowActions: TableAction[];
  singleActions: TableAction[];
  multiActions: TableAction[];
  actionRowIndex: number = -1;
  loading: boolean = false;
  first = 0;

  @Input() public tableConfig: TableConfig;
  @Input() public paginatedData: IPaginatedResultTableDto[] | any;
  @Input() public items: any[];
  @Input() public resetPagination: boolean | undefined;

  @ViewChild('rowActionPanel') rowActionPanel!: OverlayPanel;

  pageChange(event: any) {
    this.first = event.first;
  }

  constructor() {
    this.rowActions = [];
    this.singleActions = [];
    this.multiActions = [];
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.totalCount = 0;
    this.paginatedData = {
      data: [],
    };
    this.items = [];
  }

  ngOnInit() {
    this.loading = true;
    this.tableConfig.rowActions?.forEach((action) => {
      this.rowActions.push(action);
      this.singleActions = this.rowActions.filter(
        (x) => x.mode === ActionMode.SINGLE && (x.isVisible == undefined || x.isVisible),
      );
      this.multiActions = this.rowActions.filter(
        (x) => x.mode === ActionMode.MULTI && (x.isVisible == undefined || x.isVisible),
      );
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loading = true;
    if (changes['resetPagination'] &&
        !changes['resetPagination'].isFirstChange() &&
        changes['resetPagination'].currentValue != changes['resetPagination'].previousValue) {
      this.first = 0;
      this.loading = true;
    }
    this._handleItemsUpdated();
  }

  private _handleItemsUpdated() {
    if (!this.paginatedData) {
      this.loading = false;
      return;
    }
    this.items = [...(this.paginatedData.data ?? [])];
    this.totalCount = this.paginatedData.totalCount ?? 0;
    this.loading = false;
  }

  rowPopup(event: any, rowIndex: number) {
    this.actionRowIndex = rowIndex;
    this.rowActionPanel.toggle(event);
  }

  handleRowAction(action: TableAction) {
    action.callback(this.actionRowIndex);
    this.rowActionPanel.hide();
  }

  rowContainsActions(rowIdx: number) {
    return this.rowActions.some(
      (r) => r.shouldDisplayByCondition && r.shouldDisplayByCondition(rowIdx),
    );
  }

  rowContainsMultiActions(rowIdx: number) {
    return this.multiActions.some(
      (x) => x.shouldDisplayByCondition && x.shouldDisplayByCondition(rowIdx),
    );
  }

  protected readonly ButtonStyle = ButtonStyle;

  handleLazy($event: any) {
    this.tableConfig.lazyCallbackFunction($event);
  }

  protected readonly ButtonSeverity = ButtonSeverity;
}
