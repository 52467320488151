<div *ngIf="previewData">
  <atlas-page pageTitle="{{previewData?.campaignName}}">
    <div class="campaign-card">
      <div class="card-content">
        <div class="left-section">
          <img
            [ngSrc]="'assets/images/user-colored.svg'"
            alt="User"
            width="64"
            height="64"
          />
          <div class="user-info">
            <span class="badge" [ngStyle]="{'background-color': getStatusColor(previewData?.statusBadge?.value)}">
              {{ previewData?.statusBadge?.label }}
            </span>
            <div class="title-card">{{ previewData?.client }}</div>
          </div>
        </div>

        <div class="right-section">
          <div class="actions">
            <atlas-button
              class="create-deal"
              btnIcon="pi pi-file-o"
              [btnStyle]="ButtonStyle.BASIC"
              [btnSeverity]="ButtonSeverity.DANGER"
              btnLabel="Napravi ugovor"
              [isBtnDisabled]="previewData?.statusBadge?.value === 1"
              (btnClicked)="handleAddOrder()"
            ></atlas-button>
            <atlas-button
              class="create-status"
              [btnStyle]="ButtonStyle.BASIC"
              [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
              [btnLabel]="btnStatusLabel"
              (btnClicked)="changeStatus()"
              [isBtnDisabled]="isStatusChanged"
            >
              <ng-container *ngIf="!isStatusChanged; else changedIcon">
                <img
                  [ngSrc]="'assets/images/status-icon.svg'"
                  alt="Status"
                  width="17"
                  height="17"
                />
              </ng-container>
              <ng-template #changedIcon>
                <i class="pi pi-check"></i>
              </ng-template>
            </atlas-button>
            <!--            <i class="pi pi-ellipsis-v margin-icon"></i>-->
          </div>
        </div>
      </div>

      <hr class="divider"/>

      <div class="bottom-section">
        <div class="contact-info">
          <span><i class="pi pi-phone"></i>{{ previewData?.mobileNumber || '/' }} </span>
          <span *ngIf="previewData?.secondaryMobileNumber"><i
            class="pi pi-phone"></i> {{ previewData?.secondaryMobileNumber || '/' }}</span>
        </div>
        <a class="details-toggle" (click)="toggleDetails()">
          Svi detalji <i [class]="isExpanded ? 'pi pi-angle-up' : 'pi pi-angle-down'"></i>
        </a>
      </div>
      <div *ngIf="isExpanded" class="expanded-section">
        <p-tabView>
          <p-tabPanel header="Lični">
            <div class="tab-content">
              <div class="info-item">
                <i class="pi pi-calendar"></i>
                <div class="info-details">
                  <div class="info-title">Datum rođenja:</div>
                  <div class="info-value">{{ previewData?.dateOfBirth }}</div>
                </div>
              </div>
              <div class="info-item">
                <i class="pi pi-id-card"></i>
                <div class="info-details">
                  <div class="info-title">JMBG/PIB:</div>
                  <div class="info-value">{{ previewData?.identifier || '/' }}</div>
                </div>
              </div>
              <div class="info-item">
                <i class="pi pi-envelope"></i>
                <div class="info-details">
                  <div class="info-title">Email:</div>
                  <div class="info-value">{{ previewData?.email || '/' }}</div>
                </div>
              </div>
              <div class="info-item">
                <i class="pi pi-phone"></i>
                <div class="info-details">
                  <div class="info-title">Fiksni telefon:</div>
                  <div class="info-value">{{ previewData?.telephoneNumber || '/' }}</div>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Adresa">
            <div class="tab-content">
              <div class="info-item">
                <i class="pi pi-map-marker"></i>
                <div class="info-details">
                  <div class="info-title">Adresa</div>
                  <div class="info-value">{{ previewData?.address || '/' }}</div>
                </div>
              </div>
              <div class="info-item">
                <i class="pi pi-map-marker"></i>
                <div class="info-details">
                  <div class="info-title">Broj</div>
                  <div class="info-value">24</div>
                </div>
              </div>
              <div class="info-item">
                <i class="pi pi-map-marker"></i>
                <div class="info-details">
                  <div class="info-title">PTT</div>
                  <div class="info-value">21000</div>
                </div>
              </div>
              <div class="info-item">
                <i class="pi pi-map-marker"></i>
                <div class="info-details">
                  <div class="info-title">Mesto</div>
                  <div class="info-value">{{ previewData?.city || '/' }}</div>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Vozilo">
            <div class="tab-content">
              <div class="info-item">
                <i class="pi pi-car"></i>
                <div class="info-details">
                  <div class="info-title">Reg. broj</div>
                  <div class="info-value">{{ previewData?.registrationNumber || '/' }}</div>
                </div>
              </div>
              <div class="info-item">
                <i class="pi pi-car"></i>
                <div class="info-details">
                  <div class="info-title">Auto</div>
                  <div class="info-value">{{ previewData?.carMake || '/' }}</div>
                </div>
              </div>
              <div class="info-item">
                <i class="pi pi-hashtag"></i>
                <div class="info-details">
                  <div class="info-title">Broj šasije</div>
                  <div class="info-value">{{ previewData?.carVim || '/' }}</div>
                </div>
              </div>
              <div class="info-item">
                <i class="pi pi-car"></i>
                <div class="info-details">
                  <div class="info-title">Model</div>
                  <div class="info-value">{{ previewData?.carModel || '/' }}</div>
                </div>
              </div>
              <div class="info-item">
                <i class="pi pi-calendar"></i>
                <div class="info-details">
                  <div class="info-title">Godište</div>
                  <div class="info-value">{{ previewData?.carYear || '/' }}</div>
                </div>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
        <div *ngIf="isStatusChanged" class="status-changed-bar mt-2">
          <img [ngSrc]="'assets/images/vector.svg'" width="20"
               height="20"
               alt="Success Icon" class="status-icon"/>
          <span class="status-text ml-2">Status je uspešno promenjen!</span>
        </div>
      </div>
    </div>

    <div class="recommendations-comments mt-5">
      <div class="recommendations">
        <div class="header">
          <div class="title-info">Preporuke</div>
          <atlas-button
            class="add-thing"
            btnIcon="pi pi-plus"
            [btnStyle]="ButtonStyle.BASIC"
            [btnSeverity]="ButtonSeverity.PRIMARY"
            btnLabel="Dodaj preporuku"
            (btnClicked)="addRecommendation()"
          ></atlas-button>
        </div>
        <div class="info-box mt-1">
          <atlas-table
            [paginatedData]="paginatedData"
            [tableConfig]="tableConfig"
          ></atlas-table>
        </div>
      </div>

      <div class="comments">
        <atlas-comments-preview [shouldReloadComments]="shouldReloadComments"></atlas-comments-preview>
      </div>
    </div>
  </atlas-page>
</div>
