import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  GetMediumsQuery,
  MediumItem,
  MediumsClient,
} from '@shared/model/atlas.api';
import {
  DropdownComponent,
  DropdownOptions,
} from '@ui/dropdown/dropdown.component';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'atlas-mediums-dropdown',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './mediums-dropdown.component.html',
  styleUrl: './mediums-dropdown.component.scss',
})
export class MediumsDropdownComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  mediumOptions: DropdownOptions[] = [];

  @Input() mediumCtrl: FormControl;
  @Input() ctrlId: string = 'mediums-dropdown-id';
  @Input() ctrlStyleClass: string;
  @Input() ctrlLabel: string;

  constructor(private _mediumsClient: MediumsClient) {}

  ngOnInit(): void {
    this._subs.add(
      this._mediumsClient
        .getMediums(
          new GetMediumsQuery({
            pageSize: 100,
            pageNumber: 0,
          }),
        )
        .subscribe((result) => {
          this.mediumOptions = result.result.data.map((m) => ({
            label: m.name,
            value: m.id,
          }));

          this.mediumCtrl.patchValue(
            this.mediumOptions.find((x) => x.label.includes('Novi')).value,
          );
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
