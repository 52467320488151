<div>
  <div class="mb-4">
    <p-selectButton
      [options]="stateOptions"
      [(ngModel)]="stateValue"
      optionLabel="label"
      optionValue="value"
      [allowEmpty]="false"
      (onChange)="handleStateChange($event)"
    />
  </div>

  <div class="mb-4">
    <atlas-dropdown
      [ctrlLabel]="'Način isporuke'"
      [options]="deliveryType"
      [formCtrl]="deliveryTypeCtrl"
      ctrlStyleClass="w-17rem"
    ></atlas-dropdown>
  </div>

  <div *ngIf="shouldDisplayDeliveryDate" class="mt-4 mb-4">
    <div class="w-17rem">
      <atlas-calendar
        [ctrlLabel]="deliveryTypeCtrl.value === DeliveryType.PICK_UP ? 'Datum preuzimanja' : 'Datum dostave'"
        [formCtrl]="deliveryDateCtrl"
      ></atlas-calendar>
    </div>
  </div>

  <div class="mt-4 mb-4">
    <atlas-dropdown
      *ngIf="stateValue === 'draft'"
      [ctrlLabel]="'Način plaćanja'"
      [options]="draftPaymentTypeOptions"
      [formCtrl]="paymentTypeCtrl"
      ctrlStyleClass="w-17rem"
    ></atlas-dropdown>
    <atlas-dropdown
      *ngIf="stateValue === 'new'"
      [ctrlLabel]="'Način plaćanja'"
      [options]="newPaymentTypeOptions"
      [formCtrl]="paymentTypeCtrl"
      ctrlStyleClass="w-17rem"
    ></atlas-dropdown>
  </div>
</div>
