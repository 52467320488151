<div *ngIf="previewData">
  <atlas-page pageTitle="{{ previewData?.campaignName }}">
    <div *ngIf="isRecommendation" class="flex flex-row gap-2 justify-content-end">
      <atlas-button
          btnIcon="pi pi-angle-left"
          [btnStyle]="ButtonStyle.OUTLINED"
          [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
          btnLabel="Prethodni"
          [isBtnDisabled]="!previousId"
          (btnClicked)="handlePrevious()"
        ></atlas-button>
        <atlas-button
          btnIcon="pi pi-angle-right"
          [btnIconPosition]="'right'"
          [btnStyle]="ButtonStyle.OUTLINED"
          [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
          btnLabel="Sledeći"
          [isBtnDisabled]="!nextId"
          (btnClicked)="handleNext()"
          ></atlas-button>
    </div>
    <div class="campaign-card">
      <div class="card-content">
        <div class="left-section">
          <img
            [ngSrc]="'assets/images/user-colored.svg'"
            alt="User"
            width="64"
            height="64"
          />
          <div class="user-info">
            <span
            class="badge"
            [ngClass]="'lead-status-' + previewData?.statusBadge?.value"
            >
              {{ previewData?.statusBadge?.label }}
            </span>
            <div class="title-card">{{ previewData?.client }}</div>
          </div>
        </div>

        <div class="right-section">
          <div class="actions">
            <atlas-button
              class="create-deal"
              btnIcon="pi pi-file-o"
              [btnStyle]="ButtonStyle.BASIC"
              [btnSeverity]="ButtonSeverity.DANGER"
              btnLabel="Napravi ugovor"
              [isBtnDisabled]="previewData?.statusBadge?.value === 1"
              (btnClicked)="handleAddOrder()"
            ></atlas-button>
            <atlas-button
              class="create-status"
              [btnStyle]="ButtonStyle.BASIC"
              [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
              [btnLabel]="btnStatusLabel"
              (btnClicked)="changeStatus()"
              [isBtnDisabled]="isStatusChanged"
            >
              <ng-container *ngIf="!isStatusChanged; else changedIcon">
                <img
                  [ngSrc]="'assets/images/status-icon.svg'"
                  alt="Status"
                  width="17"
                  height="17"
                />
              </ng-container>
              <ng-template #changedIcon>
                <i class="pi pi-check"></i>
              </ng-template>
            </atlas-button>
          </div>
        </div>
      </div>

      <hr class="divider" />

      <div class="bottom-section">
        <div class="bottom-info">
          <div class="contact-info">
            <span
              ><i class="pi pi-phone"></i>{{ previewData?.mobileNumber || "/" }}
            </span>
            <span *ngIf="previewData?.secondaryMobileNumber"
              ><i class="pi pi-phone"></i>
              {{ previewData?.secondaryMobileNumber || "/" }}</span
            >
          </div>
          <div class="contact-info" *ngIf="isRecommendation">
            <span
              ><i class="pi pi-map-marker"></i>{{ previewData?.city || "/" }}
            </span>
          </div>
          <div class="contact-info" *ngIf="isRecommendation">
            <span class="icon-text">
              <img
                [ngSrc]="'assets/images/user-checked-icon.svg'"
                width="16"
                height="16"
                alt="user-checked"
                class="icon"
              />{{ previewData?.source || "/" }}
            </span>
          </div>
        </div>
        <a class="details-toggle" (click)="toggleDetails()">
          {{ isRecommendation ? "Komentari" : "Svi detalji" }}
          <p-badge *ngIf="isRecommendation" [value]="recommendationComments.length" badgeSize="small" class="mr-2"></p-badge>
          <i [class]="isExpanded ? 'pi pi-angle-up' : 'pi pi-angle-down'"></i>
        </a>

      </div>

      <div *ngIf="isExpanded" class="expanded-section" [ngClass]="{ 'expand-section-comments': isRecommendation }">
        <ng-container *ngIf="!isRecommendation; else commentsSection">
          <p-tabView>
            <p-tabPanel header="Lični">
              <div class="tab-content">
                <atlas-info-item [icon]="'pi pi-calendar'" [title]="'Datum rođenja:'" [value]="previewData?.dateOfBirth"></atlas-info-item>
                <atlas-info-item [icon]="'pi pi-id-card'" [title]="'JMBG/PIB:'" [value]="previewData?.identifier"></atlas-info-item>
                <atlas-info-item [icon]="'pi pi-envelope'" [title]="'Email:'" [value]="previewData?.email "></atlas-info-item>
                <atlas-info-item [icon]="'pi pi-phone'" [title]="'Fiksni telefon:'" [value]="previewData?.telephoneNumber"></atlas-info-item>
              </div>
            </p-tabPanel>
            <p-tabPanel header="Adresa">
              <div class="tab-content">
                <atlas-info-item [icon]="'pi pi-map-marker'" [title]="'Adresa'" [value]="previewData?.address"></atlas-info-item>
                <atlas-info-item [icon]="'pi pi-map-marker'" [title]="'Broj'" [value]=""></atlas-info-item>
                <atlas-info-item [icon]="'pi pi-map-marker'" [title]="'PTT'" [value]=""></atlas-info-item>
                <atlas-info-item [icon]="'pi pi-map-marker'" [title]="'Mesto'" [value]="previewData?.city"></atlas-info-item>
              </div>
            </p-tabPanel>
            <p-tabPanel header="Vozilo">
              <div class="tab-content">
                <atlas-info-item [icon]="'pi pi-car'" [title]="'Reg. broj'" [value]="previewData?.registrationNumber "></atlas-info-item>
                <atlas-info-item [icon]="'pi pi-car'" [title]="'Auto'" [value]="previewData?.carMake "></atlas-info-item>
                <atlas-info-item [icon]="'pi pi-hashtag'" [title]="'Broj šasije'" [value]="previewData?.carVim "></atlas-info-item>
                <atlas-info-item [icon]="'pi pi-car'" [title]="'Model'" [value]="previewData?.carModel "></atlas-info-item>
                <atlas-info-item [icon]="'pi pi-calendar'" [title]="'Godište'" [value]="previewData?.carYear"></atlas-info-item>
              </div>
            </p-tabPanel>
          </p-tabView>

          <div *ngIf="isStatusChanged" class="status-changed-bar mt-2">
            <img [ngSrc]="'assets/images/vector.svg'" width="20" height="20" alt="Success Icon" class="status-icon" />
            <span class="status-text ml-2">Status je uspešno promenjen!</span>
          </div>
        </ng-container>

        <ng-template #commentsSection>
          <ng-container *ngFor="let comment of recommendationComments">
            <atlas-comment-item [comment]="comment"></atlas-comment-item>
          </ng-container>
        </ng-template>
      </div>
    </div>

    <div class="recommendations-comments mt-5">
      <div class="recommendations">
        <div class="header">
          <div class="title-info">Preporuke</div>
          <atlas-button
            class="add-thing"
            btnIcon="pi pi-plus"
            [btnStyle]="ButtonStyle.BASIC"
            [btnSeverity]="ButtonSeverity.PRIMARY"
            btnLabel="Dodaj preporuku"
            (btnClicked)="addRecommendation()"
          ></atlas-button>
        </div>
        <div class="info-box mt-1">
          <atlas-recommendation-table
          [leadSourceId]="!isRecommendation ? previewData.id : null"
          [sourceRecommendationId]="isRecommendation ? previewData.id : null"
          [showFilters]="false">
        </atlas-recommendation-table>        
        </div>
      </div>

      <div class="comments">
        <atlas-comments-preview
          [isRecommendation]="isRecommendation"
          [shouldReloadComments]="shouldReloadComments"
          [sourceRecommendationId]="isRecommendation ? previewData.id : null"
        ></atlas-comments-preview>
      </div>
    </div>
  </atlas-page>
</div>
