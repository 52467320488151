import {Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {ErrorRequiredComponent} from "@ui/errors/error-required/error-required.component";
import {JsonPipe, NgIf, NgTemplateOutlet} from "@angular/common";
import {PrimeTemplate} from "primeng/api";
import {FormArray, FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MultiSelectModule} from "primeng/multiselect";

@Component({
  selector: 'atlas-multiselect',
  standalone: true,
    imports: [
        ErrorRequiredComponent,
        NgIf,
        NgTemplateOutlet,
        // PrimeTemplate,
        MultiSelectModule,
        ReactiveFormsModule,
        JsonPipe
    ],
  templateUrl: './multiselect.component.html',
  styleUrl: './multiselect.component.scss'
})
export class MultiselectComponent {
    /** I/O */
    @Input() formGroup!: FormGroup;
    @Input() formArray!: FormArray;
    @Input() formCtrlName: string | number | null;
    @Input() optionLabel!: string;
    @Input() options: any[] = [];
    @Input() ctrlDataKey: string | undefined = undefined;
    @Input() formCtrlId!: string;
    @Input() ctrlTooltip!: string;
    @Input() ctrlLabel: string = '';
    @Input() showLabel: boolean = true;
    @Input() ctrlPlaceholder!: string;
    @Input() ctrlTooltipPosition!: string; // Dodati u .html
    @Input() canFilter!: boolean;
    @Input() ctrlFilterPlaceholder!: string;
    @Input() ctrlFilterBy!: string;
    @Input() ctrlStyleClass!: string;
    @Input() ctrlItemStyleClass!: string;
    @Input() isGrouped!: boolean;
    @Input() canClear!: boolean;
    @Input() ctrlDisabled!: boolean;
    @Input() hideErrors: boolean = false;
    @Input() ctrlAppendTo: string = 'body';

    @Output() optionChanged = new EventEmitter();

    /**
     * Pass a template for the autocomplete in this component
     *
     * @usage
     * Can be passed inside the body of this component as:
     *  <app-my-wrapper ...>
     *      <ng-template ... #item let-obj>...</ng-template>
     *  </app-my-wrapper>
     */
    @ContentChild('item') item!: TemplateRef<ElementRef>;
    @ContentChild('selectedItem') selectedItem!: TemplateRef<ElementRef>;

    constructor() {}

    handleOnItemChange(event: any) {
        this.optionChanged.emit(event);
    }
}