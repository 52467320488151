@for (recc of recomendations?.controls; let idx = $index; track recc.contact) {
  <div>
    <!-- Header -->
    <div class="card-header mt-2">
      <span class="text-base font-bold mb-3">{{ idx + 1 }}.</span>
      <i
        *ngIf="idx !== 0"
        (click)="clearSelectedRecc($event, idx)"
        class="pi pi-trash pointer"
      ></i>
    </div>

    <!-- Inputs -->
    <div class="flex flex-column gap-2">
      <div class="flex gap-2 flex-wrap multiple-inputs">
        <div class="flex-1">
          <atlas-input-text
            [formCtrl]="recc.controls.fullName"
            ctrlLabel="Ime i prezime"
            ctrlPlaceholder="Ime i prezime"
            [shouldFocus]="false"
          ></atlas-input-text>
        </div>
        <div class="flex-1">
          <atlas-input-phone
            [formCtrl]="recc.controls.contact"
            ctrlLabel="Kontakt telefon"
            ctrlPlaceholder="Kontakt telefon"
          ></atlas-input-phone>
        </div>
        <div class="flex-1">
          <atlas-input-text
            [formCtrl]="recc.controls.city"
            ctrlLabel="Grad"
            ctrlPlaceholder="Grad"
          ></atlas-input-text>
        </div>
        <div class="flex-1">
          <atlas-clients-autocomplete
            [sourceIdCtrl]="recc.controls.sourceId"
            (onClientChanged)="handleClientChanged($event, idx)"
          ></atlas-clients-autocomplete>
        </div>
      </div>
      <div class="1-input">
        <atlas-input-text
          [formCtrl]="recc.controls.comment"
          ctrlPlaceholder="Komentar"
        ></atlas-input-text>
      </div>
    </div>
    <p-divider></p-divider>
  </div>
}

<atlas-button
  btnIcon="pi pi-plus"
  btnLabel="Dodatni"
  btnClass="pl-0"
  (btnClicked)="handleAddReccCard()"
></atlas-button>
