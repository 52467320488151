<div class="gap-3">
    <!-- Unos grada -->
    <div class="city-input">
      <atlas-input-text
        [formCtrl]="form?.controls?.name"
        ctrlLabel="Naziv kampanje"
        formCtrlId="Kampanja"
        ctrlPlaceholder="Naziv kampanje"
      ></atlas-input-text>      
    </div>
  </div>
  <div class="gap-3 mt-3">
    <div class="city-input">
        <atlas-input-description
        [formCtrl]="form?.controls?.description"
        formCtrlId="Komentar"
        ctrlLabel="Opis"
        ctrlPlaceholder="Opis"
        formCtrlClass="w-full lg:w-30rem"
        [showInfo]="true"
      ></atlas-input-description>     
    </div>
  </div>