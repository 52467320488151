import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LeadsStatusDropdownComponent } from "../../../features/dropdowns/leads-status-dropdown/leads-status-dropdown/leads-status-dropdown.component";
import { InputDescriptionComponent } from '@ui/input-description/input-description/input-description.component';
import { FormControl } from '@angular/forms';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { CampaignLeadStatus, CampaignsClient, RecommendationsClient, UpdateLeadStatusCommandBody, UpdateStatusCommandBody } from '@shared/model/atlas.api';
import { Subscription } from 'rxjs';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { CampaignFormService } from '@app/cc-leader/services/campaign-form.service';
import { AlertService } from '@shared/services/alert.service';
import { JsonPipe, NgIf } from '@angular/common';
import { CalendarComponent } from '@ui/calendar/calendar.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { AgentAutocompleteComponent } from '@app/cc-leader/clients/agent-autocomplete/agent-autocomplete.component';

@Component({
  selector: 'atlas-change-status',
  standalone: true,
  imports: [LeadsStatusDropdownComponent,
    InputDescriptionComponent,
    NgIf,
    CalendarComponent,
    JsonPipe,
    FormControlPipe,
    AgentAutocompleteComponent
  ],
  templateUrl: './change-status.component.html',
  styleUrl: './change-status.component.scss'
})
export class ChangeStatusComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('newStatusDropdown') newStatusDropdown: any;
  form: any;
  private _subs: Subscription = new Subscription();
  defaultControl = new FormControl('');
  campaignId: string;
  recommendationId: string;
  previewData: any;
  selectedAgentId: string;
  currentStatus: number;
  isCallAgainStatus: boolean = false;

  constructor(private _dialogConfig: DialogConfig,
    private _client: CampaignsClient,
    private _dialogRef: DialogRef,
    private _service: CampaignFormService,
    private _alertService: AlertService,
    private _recommendationClient: RecommendationsClient
  ) {
    this._subs = new Subscription();
    this.form = this._service.getStatusForm();
  }


  ngOnInit(): void {
    this.campaignId = this._dialogConfig.data?.id;
    this.recommendationId = this._dialogConfig.data?.recommendationId;
    this.currentStatus = this._dialogConfig.data?.currentStatus;

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleSubmit();
      }),
    );
  }

  onAgentSelected(agentId: any) {
    this.selectedAgentId = agentId.id;
  }

  ngAfterViewInit(): void {
    if (this.newStatusDropdown?.statusCtrl) {
      this._subs.add(
        this.newStatusDropdown?.statusCtrl?.valueChanges.subscribe((selectedStatus) => {
          this.checkIfCallAgainStatus(selectedStatus?.status);
        })
      );
    }
  }

  checkIfCallAgainStatus(status: number) {
    const callAgainStatuses = [
      CampaignLeadStatus.CALL_AGAIN_1,
      CampaignLeadStatus.CALL_AGAIN_2,
      CampaignLeadStatus.CALL_AGAIN_3,
    ];
    this.isCallAgainStatus = callAgainStatuses.includes(status);
  }

  

  handleSubmit() {
    if (this.campaignId) {
      this._handleLeadStatus();
    }
    else {
      this._handleRecommendationStatus()
    }
  }

  _handleLeadStatus() {
    const statusObject = this.newStatusDropdown?.statusCtrl.value;
    const newStatus = this.newStatusDropdown?.statusCtrl.value.status;
    const comment = this.form?.value.comment ?? null;
    const agentId = this.selectedAgentId ?? null;
    const currentDate = this.form?.value.dateTime ?? null;

    const request = new UpdateLeadStatusCommandBody({
      status: newStatus,
      agentId: agentId,
      dateTime: currentDate,
      comment: comment
    });

    this._subs.add(
      this._client.updateLeadStatus(this.campaignId, request).subscribe(
        (res) => {
          this._alertService.addSuccessMsg(res.result);
          this._dialogRef.close({
            success: true,
            newStatus: {
              value: statusObject.status,
              label: statusObject.label,
            },
          });
        }
      )
    );
  }

  _handleRecommendationStatus() {
    const statusObject = this.newStatusDropdown?.statusCtrl.value;
    const newStatus = this.newStatusDropdown?.statusCtrl.value.status;
    const comment = this.form?.value.comment ?? null;
    const agentId = this.selectedAgentId ?? null;
    const currentDate = this.form?.value.dateTime ?? null;

    const request = new UpdateStatusCommandBody({
      status: newStatus,
      agentId: agentId,
      dateTime: currentDate,
      comment: comment
    });
    this._subs.add(
      this._recommendationClient.updateStatus(this.recommendationId, request).subscribe(
        (res) => {
          this._alertService.addSuccessMsg(res.result);
          this._dialogRef.close({
            success: true,
            newStatus: {
              value: statusObject.status,
              label: statusObject.label,
            },
          });
        }
      )
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    this.form?.reset();
  }
}
