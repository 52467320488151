import { JsonPipe, NgFor, NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { IClientWithPackagesFilter } from '@shared/model/atlas.api';
import { SidebarModule } from 'primeng/sidebar';
import { Subscription } from 'rxjs';
import { ClientFilterForm, ClientWithPackageTableFilterService } from '../../services/client-with-package-table-filter.service';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { CalendarComponent } from '@ui/calendar/calendar.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { DividerModule } from 'primeng/divider';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MediumMultiselectComponent } from '@app/features/multiselects/medium-multiselect/medium-multiselect.component';
import { SourcesMultiselectComponent } from '@app/features/multiselects/sources-multiselect/sources-multiselect.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule, NgModel } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';

@Component({
  selector: 'atlas-client-with-packages-table-filter',
  standalone: true,
  imports: [SidebarModule,
    NgTemplateOutlet,
    NgIf,
    NgOptimizedImage,
    DividerModule,
    ButtonComponent,
    InputTextComponent,
    CalendarComponent,
    FormControlPipe,
    JsonPipe,
    ScrollPanelModule,
    MediumMultiselectComponent,
    SourcesMultiselectComponent,
    NgFor, 
    CheckboxModule,
    FormsModule,
    RadioButtonModule
  ],
  templateUrl: './client-with-packages-table-filter.component.html',
  styleUrl: './client-with-packages-table-filter.component.scss'
})
export class ClientWithPackagesTableFilterComponent implements OnInit, OnDestroy {

  private _subs: Subscription;
  showSidebar = false;
  clientPackageId: string;
  filterData: IClientWithPackagesFilter;
  form: ClientFilterForm;
  selectedActive: boolean | null = null;
  selectedInactive: boolean | null = null;

  constructor(
    private _service: ClientPackageTableService,
    private _filterService: ClientWithPackageTableFilterService
  ) {
    this._subs = new Subscription();
    this.showSidebar = false;
    this.clientPackageId = '';
    this.filterData = {};
    this.form = _filterService.getForm();
  }

  ngOnInit(): void {
     this._subs.add(
      this._service.getShowFiltersSidebar.subscribe((show) => {
        this.showSidebar = show;
      }),
    );
    this._subs.add(
      this._filterService.isActive$.subscribe((isActive) => {
        if (isActive === null) {
          this.selectedActive = null;
          this.selectedInactive = null;
        } else if (isActive) {
          this.selectedActive = true;
          this.selectedInactive = false;
        } else {
          this.selectedActive = false;
          this.selectedInactive = true;
        }
      })
    );
  }

  handleStatusChange(): void {
    let statusFilter: boolean | null = null;
  
    if (this.selectedActive && !this.selectedInactive) {
      statusFilter = true;
    } else if (!this.selectedActive && this.selectedInactive) {
      statusFilter = false;
    }
  
    this.form.controls['isActive'].patchValue(statusFilter);
  }

  handleResetFilters() {
    this._filterService.reset();
    this._filterService.reloadTable();
    this._service.hideFilterSidebar();
    this.selectedActive = null;
    this.selectedInactive = null;
  }

  handleApplyFilters() {
    this._filterService.apply();
    this._filterService.reloadTable();
    this._service.hideFilterSidebar();
  }

  ngOnDestroy(): void {
    this._filterService.reset();
    this._service.hideFilterSidebar();
    this._subs.unsubscribe();
  }


  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

}
