<div class="dialog-container">
    <div style="pointer-events: none;">
    <p class="label-txt">Iz statusa</p>
    
    <atlas-leads-status-dropdown [currentStatus]="currentStatus"></atlas-leads-status-dropdown>
    </div>
    <div class="separator-container">
      <hr class="separator-line" />
      <div class="separator-circle">
        <i class="pi pi-chevron-down"></i>
      </div>
    </div>
  
    <p class="label-txt">U status</p>
    <atlas-leads-status-dropdown #newStatusDropdown></atlas-leads-status-dropdown>
  </div>

  <div *ngIf="isCallAgainStatus" class="form-row">
    <div class="form-column">
      <atlas-agent-autocomplete (agentSelected)="onAgentSelected($event)">
      </atlas-agent-autocomplete>
    </div>
    <div class="form-column">
      <atlas-calendar 
        [ctrlPlaceholder]="'Izaberi datum'" 
        [formCtrl]="form.controls.dateTime">
      </atlas-calendar>
    </div>
  </div>

  <div class="comment-input">
    <atlas-input-description
      [formCtrl]="form?.controls.comment ?? defaultControl"
      formCtrlId="Komentar"
      ctrlPlaceholder="Tvoj komentar"
      formCtrlClass="w-full"
      [showInfo]="true"
      [hideErrors]="true"
    ></atlas-input-description>
  </div>