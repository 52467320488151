<div class="flex flex-column gap-3 justify-content-between full-height">
  <div *ngIf="titleNeeded" class="page-header">
    <div class="title-container">
      <div class="title-row">
        <div class="flex flex-column gap-2">
          <div class="badge-design" *ngIf="showBadge">
            {{ badgeText }}
          </div>
          <div class="text-3xl font-semibold">{{ pageTitle }}</div>
        </div>
        <i
          *ngIf="showToggle"
          class="pi toggle-icon"
          [ngClass]="isDetailsVisible ? 'pi-chevron-up' : 'pi-chevron-down'"
          (click)="toggleDetails()"
        ></i>
      </div>
      <div *ngIf="pageDescription" class="text-base text-gray-600">
        {{ pageDescription }}
      </div>
    </div>
    <atlas-button
      [btnLabel]="cancelBtnIcon == 'cancel' ? 'Odustani' : 'Idi nazad'"
      (btnClicked)="handleCancelForm()"
      [btnIcon]="cancelBtnIcon == 'cancel' ? 'pi pi-times' : 'pi pi-arrow-left'"
      [btnStyle]="ButtonStyle.TEXT"
    ></atlas-button>
  </div>
  <div class="px-5 py-3">
    <p-scrollPanel [style]="{ width: '100%', height: 'calc(100dvh - 270px)' }">
      <ng-container *ngTemplateOutlet="content"> </ng-container>
    </p-scrollPanel>
  </div>
  <div
    class="flex gap-3 justify-content-end align-items-center page-footer h-full bg-white"
  >
    <ng-container *ngIf="submitBtnLabel">
      <div>
        <atlas-button
          *ngIf="submitBtnSvgUrl; else simpleIconBtn"
          [btnLabel]="submitBtnLabel"
          [btnStyle]="ButtonStyle.BASIC"
          [btnSeverity]="ButtonSeverity.SECONDARY"
          (btnClicked)="handleSubmitForm()"
        >
          <img
            [ngSrc]="submitBtnSvgUrl"
            width="16"
            height="16"
            alt="btn-icon"
          />
        </atlas-button>
      </div>
    </ng-container>

    <ng-container *ngIf="showExtraBtn">
        <atlas-button
          [btnLabel]="extraBtnLabel"
          [btnIcon]="extraBtnIcon"
          [btnStyle]="extraBtnStyle"
          [btnSeverity]="extraBtnSeverity"
          (btnClicked)="handleExtraBtnClick()"
        ></atlas-button>
    </ng-container>
  </div>
</div>

<ng-template #simpleIconBtn>
  <atlas-button
    [btnIcon]="
      submitBtnIcon == 'add'
        ? 'pi pi-plus'
        : submitBtnIcon == 'edit'
          ? 'pi pi-pencil'
          : submitBtnIcon
    "
    [btnLabel]="submitBtnLabel"
    [btnStyle]="ButtonStyle.BASIC"
    [btnSeverity]="ButtonSeverity.SECONDARY"
    (btnClicked)="handleSubmitForm()"
  ></atlas-button>
</ng-template>
