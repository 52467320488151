<div class="flex gap-4">
  <div class="flex-70 right-border">
    <div class="flex flex-column gap-5">
      <atlas-details-client-info
      [nationalNumber]="clientDetails?.nationalNumber"
      [address]="clientDetails?.address"
      [isLoading]="isLoading">
    </atlas-details-client-info>
      <div class="flex flex-column gap-4 mt-5">
        <div class="flex justify-content-between">
          <div class="subtitle-text">Preporuke</div>
          <atlas-button
            btnLabel="Dodaj preporuku"
            btnIcon="pi pi-plus"
            [btnSeverity]="ButtonSeverity.PRIMARY"
            [btnStyle]="ButtonStyle.BASIC"
            (btnClicked)="handleShowDialog()"
            ></atlas-button>
        </div>
      <atlas-recommendation-table [sourceId]="clientId" [showFilters]="false"></atlas-recommendation-table>
      </div>
    </div>
  </div>
  <div class="flex-30">
    <atlas-button
        btnLabel="Izmeni mejl klijenta"
        btnIcon="pi pi-key"
        [btnSeverity]="ButtonSeverity.SECONDARY"
        [btnStyle]="ButtonStyle.BASIC"
        btnClass="mb-3"
        (btnClicked)="handleChangeEmail()"
        ></atlas-button>
    <atlas-comments-client-info
      [clientId]="clientId"
      [isLoading]="isLoading"
      [comments]="clientDetails?.comments">
    </atlas-comments-client-info>
  </div>
</div>
