import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IGetOrderTableFilter } from '@shared/model/atlas.api';
import { OrderTableService } from '@app/finance-administrator/services/order-table.service';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { SidebarModule } from 'primeng/sidebar';
import { JsonPipe, NgIf, NgOptimizedImage } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { CalendarComponent } from '@ui/calendar/calendar.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { PaymentStatusMultiselectComponent } from '@app/features/multiselects/payment-status-multiselect/payment-status-multiselect.component';
import { PaymentTypeMultiselectComponent } from '@app/features/multiselects/payment-type-multiselect/payment-type-multiselect.component';
import { MediumMultiselectComponent } from '@app/features/multiselects/medium-multiselect/medium-multiselect.component';
import { SourcesMultiselectComponent } from '@app/features/multiselects/sources-multiselect/sources-multiselect.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import {
  FilterForm,
  OrderTableFilterService,
} from '@app/finance-administrator/services/order-table-filter.service';

@Component({
  selector: 'atlas-order-table-filter',
  standalone: true,
  imports: [
    SidebarModule,
    NgIf,
    NgOptimizedImage,
    DividerModule,
    ButtonComponent,
    InputTextComponent,
    CalendarComponent,
    FormControlPipe,
    PaymentStatusMultiselectComponent,
    JsonPipe,
    PaymentTypeMultiselectComponent,
    MediumMultiselectComponent,
    SourcesMultiselectComponent,
    ScrollPanelModule,
  ],
  templateUrl: './order-table-filter.component.html',
  styleUrl: './order-table-filter.component.scss',
})
export class OrderTableFilterComponent implements OnInit, OnDestroy {
  private _subs: Subscription;
  clientPackageId: string;
  filterData: IGetOrderTableFilter;
  form: FilterForm;
  showSidebar = false;

  constructor(
    private _service: OrderTableService,
    private _filterService: OrderTableFilterService,
  ) {
    this._subs = new Subscription();
    this.showSidebar = false;
    this.clientPackageId = '';
    this.filterData = {};
    this.form = _filterService.getForm();
  }

  ngOnInit() {
    this._subs.add(
      this._service.getShowFiltersSidebar.subscribe((show) => {
        this.showSidebar = show;
      }),
    );
  }

  ngOnDestroy() {
    this._filterService.reset();
    this._service.hideFilterSidebar();
    this._subs.unsubscribe();
  }

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  handleResetFilters() {
    this._filterService.reset();
    this._filterService.reloadTable();
    this._service.hideFilterSidebar();
  }

  handleApplyFilters() {
    this._filterService.apply();
    this._filterService.reloadTable();
    this._service.hideFilterSidebar();
  }
}
