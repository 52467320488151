<atlas-agents-autocomplete
  (agentSelected)="handleAgentSelected($event)"
  [exceptAgents]="selectedAgents"
></atlas-agents-autocomplete>
<div class="mt-3"></div>
<div class="flex flex-column gap-3">
  <atlas-assigned-agents-list
    [agents]="selectedAgents"
    (agentRemoved)="handleAgentUnselect($event)"
    [ngClass]="
      selectedAgents.length === 0
        ? 'invalid-card info-page-width'
        : 'info-page-width'
    "
  ></atlas-assigned-agents-list>
  <small *ngIf="selectedAgents.length === 0" class="error-info text-red-500">
    Obavezan je barem jedan agent
  </small>
</div>
