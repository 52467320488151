<atlas-scrollable-page
  pageTitle="Dodaj vozilo"
  submitBtnLabel="Sačuvaj vozilo"
  submitBtnIcon="pi pi-bookmark"
  (onCancelAction)="handleCancelForm()"
  (onSubmitAction)="handleSubmitForm()"
>
<ng-template #content>
    <div style="max-width: 700px;">
<div class="mt-2 title-text">Vrsta paketa</div>
<div *ngIf="!vehicleTypes" class="flex flex-column mt-5">
    <div class="w-full flex gap-3">
      <div class="w-full">
        <p-skeleton width="100%" height="55px"/>
      </div>
      <div class="w-full">
        <p-skeleton width="100%" height="55px"/>
      </div>
      <div class="w-full">
        <p-skeleton width="100%" height="55px"/>
      </div>
    </div>
  </div>

<div class="flex gap-4 mt-5">
    <atlas-icon-checkbox
      *ngFor="let vehicleType of vehicleTypes"
      [name]="vehicleType?.name"
      [isSelected]="selectedVehicleType === vehicleType?.name"
      [imageUrl]="vehicleType?.name | vehicleTypeIcon"
      (handleSelect)="handleTypeChange(vehicleType)"
      class="flex-1"
    ></atlas-icon-checkbox>
  </div>
  <div class="mt-6 title-text">Detalji vozila</div>
  <ng-container *ngIf="selectedVehicleType">
  <div class="mt-3 flex gap-3">
    <!-- brand -->
    <div class="w-full">
      <atlas-brand-dropdown
        *ngIf="!isKombiSelected"
        [type]="selectedVehicleType"
        (onBrandChanged)="handleBrandChange($event)"
        [brandNameCtrl]="orderForm.controls.vehicle.controls.brand"
        [validateControl]="
          orderForm.controls.vehicle.controls.brand.touched
        "
      ></atlas-brand-dropdown>

      <atlas-brand-autocomplete
        *ngIf="isKombiSelected"
        [type]="selectedVehicleType"
        (onBrandChanged)="handleBrandChange($event)"
        [brandName]="orderForm.value.vehicle.brand"
      ></atlas-brand-autocomplete>
    </div>
    <!-- model -->
    <div class="w-full">
      <atlas-model-dropdown
        *ngIf="!isKombiSelected"
        [brandId]="selectedBrandId"
        [brandName]="orderForm.value.vehicle.brand"
        [modelNameCtrl]="orderForm.controls.vehicle.controls.model"
        [isDisabled]="!orderForm.value.vehicle.brand"
        [validateControl]="
          orderForm.controls.vehicle.controls.model.touched
        "
      ></atlas-model-dropdown>

      <atlas-model-autocomplete
        *ngIf="isKombiSelected"
        [brandId]="selectedBrandId"
        [modelName]="orderForm.value.vehicle.model"
        [isDisabled]="!selectedBrandId"
        (onModelChanged)="handleModelChanged($event)"
      ></atlas-model-autocomplete>
    </div>
  </div>
  <div class="mt-3 flex gap-3">
    <!-- Registracija -->
    <div style="width: 340px;">
      <atlas-input-text
        ctrlPlaceholder="XX000XX"
        [formCtrl]="orderForm.controls.vehicle.controls.licencePlate"
        class="w-full"
        ctrlLabel="Registracione oznake"
        formCtrlClass="w-full uppercase"
        [ctrlKeyFilter]="LicencePlateRegExp"
      ></atlas-input-text>
    </div>
  </div>
  <div class="mt-2 flex gap-3">
    <!-- Broj sasije -->
    <div class="w-full">
        <atlas-input-text
          [formCtrl]="
            orderForm.controls.vehicle.controls.chassis | formControl
          "
          ctrlLabel="Broj šasije"
          formCtrlId="broj"
          [tooltip]="'Broj šasije vozila nedostaje za ovaj paket'"
          ctrlPlaceholder="Broj šasije"
          [formCtrlIconRight]="'pi pi-info-circle'"
          formCtrlClass="w-full"
        ></atlas-input-text>
      </div>
    <!-- Godiste -->
    <div class="w-full">
        <atlas-input-text
          [formCtrl]="orderForm.controls.vehicle.controls.productionYear"
          ctrlLabel="Godište (opciono)"
          ctrlPlaceholder="Godište"
          hideErrors="true"
          formCtrlClass="w-full"
        ></atlas-input-text>
      </div>
  </div>
  <div class="mt-2 flex gap-3">
    <!-- Boja -->
    <div style="width: 340px;">
        <atlas-input-text
          [formCtrl]="orderForm.controls.vehicle.controls.color"
          ctrlLabel="Boja vozila (opciona)"
          ctrlPlaceholder="Boja vozila"
          hideErrors="true"
          formCtrlClass="w-full"
        ></atlas-input-text>
      </div>
  </div>
</ng-container>
</div>
</ng-template>
</atlas-scrollable-page>
