<atlas-scrollable-page
  [pageTitle]="'Izmeni klijenta'"
  submitBtnSvgUrl="./assets/images/buttons/save-white.svg"
  submitBtnLabel="Sačuvaj izmene"
  (onCancelAction)="handleCancelForm()"
  (onSubmitAction)="handleSubmitForm()"
>
  <ng-template #content>
    <div *ngIf="form" class="ml-1" style="max-width: 700px">
      <div class="mt-3 title-text">Detalji klijenta</div>
      <div class="mt-3 flex gap-3">
        <div class="w-full">
          <!-- Ime / Prezime -->
          <atlas-input-text
            formCtrlId="ime"
            [formCtrl]="form.controls.name"
            ctrlLabel="Ime"
            ctrlPlaceholder="Ime"
            formCtrlClass="w-full"
          ></atlas-input-text>
        </div>
        <div class="w-full">
          <atlas-input-text
            formCtrlId="prezime"
            [formCtrl]="form.controls.lastName"
            ctrlLabel="Prezime"
            ctrlPlaceholder="Prezime"
            formCtrlClass="w-full"
          ></atlas-input-text>
        </div>
      </div>
      <div class="mt-3 flex gap-3">
        <div style="width: 342px !important">
          <div>
            <label class="mb-2">Broj telefona</label>
            <atlas-contact-phone-list></atlas-contact-phone-list>
          </div>
        </div>
      </div>
      <div class="mt-3 flex gap-3">
        <div class="w-full">
          <label [for]="'towing-address'">Adresa šlepanja</label>
          <div class="flex flex-column gap-2 mt-2">
            <span class="p-input-icon-left google-autocomplete">
              <i class="pi pi-map-marker"></i>
              <input
                #towingLocationRef
                id="start-id"
                [placeholder]="'Adresa šlepanja'"
                [ngClass]="
                  form.controls.towingLocation.controls.place.invalid &&
                  (form.controls.towingLocation.controls.place.dirty ||
                    form.controls.towingLocation.controls.place.touched)
                    ? 'p-inputtext p-component invalid'
                    : 'p-inputtext p-component'
                "
              />
            </span>
            <atlas-error-required
                    *ngIf="shouldDisplayRequiredError(form.controls.towingLocation.controls.place)"
                    [formCtrl]="form.controls.towingLocation.controls.place"
            ></atlas-error-required>
          </div>
        </div>
      </div>
      <div *ngIf="showLastValidAdress" class="flex">
        <div class="mr-2 font-semibold">Poslednja validna adresa:</div>
        <div>{{ lastValidAdress }}</div>
      </div>
      <div class="mt-2 flex">
        <atlas-input-switch [formGroup]="form"></atlas-input-switch>
        <p class="m-1 ml-2">Aktivan</p>
      </div>
      <div *ngIf="clientType !== 'Fizičko lice'">
        <div class="mt-3 title-text">Detalji firme</div>
        <div class="mt-3 flex gap-3">
          <div class="w-full">
            <!-- Ime / Prezime -->
            <atlas-input-text
              [formCtrl]="form.controls.companyName"
              formCtrlClass="w-full"
              ctrlLabel="Naziv firme"
              formCtrlId="ime-kompanije"
              ctrlPlaceholder="Naziv firme"
            ></atlas-input-text>
          </div>
          <div class="w-full">
            <atlas-input-text
              formCtrlId="adresa-kompanije"
              [formCtrl]="form.controls.companyAddress"
              ctrlLabel="Adresa firme"
              ctrlPlaceholder="Adresa firme"
              formCtrlClass="w-full"
            ></atlas-input-text>
          </div>
        </div>
        <div class="mt-3 flex gap-3">
          <div class="w-full">
            <atlas-input-text
              formCtrlId="pib"
              [formCtrl]="form.controls.nationalNumber"
              ctrlLabel="PIB"
              ctrlPlaceholder="PIB"
            ></atlas-input-text>
          </div>
          <div class="w-full">
            <atlas-input-text
              [formCtrl]="form.controls.taxId"
              ctrlLabel="MB"
              ctrlPlaceholder="Matični broj"
              hideErrors="true"
              formCtrlClass="w-full"
            ></atlas-input-text>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</atlas-scrollable-page>
