<div *ngIf="isLoading">
  <p-skeleton class="skeleton-box" width="100%" height="60px"/>
</div>

<div class="header-container" *ngIf="!isLoading">
  <div class="subtitle-text">Detalji</div>
  <div class="details">
    <div class="detail-item">
      <div class="icon-box">
      <img
        [ngSrc]="'assets/images/phone.svg'"
        width="20"
        height="20"
        alt="phone-icon"
      />
      </div>
      <div class="m-2 p-1">
        <div class="deatil-label">Broj telefona</div>
        <div class="detail-value">
          <ul *ngIf="phones.length > 0; else noPhones">
            <li *ngFor="let phone of phones">{{ phone }}</li>
          </ul>
          <ng-template #noPhones>
            <div>0656159341</div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="detail-item">
      <div class="icon-box">
      <img
        [ngSrc]="'assets/images/jmbg-icon.svg'"
        width="20"
        height="20"
        alt="jmbg-icon"
      />
      </div>
      <div class="m-2 p-1">
        <div class="deatil-label">JMBG</div>
        <div class="detail-value">{{ nationalNumber ?? "01039948810287" }}</div>
      </div>
    </div>
    <div class="detail-item">
      <div class="icon-box">
      <img
        [ngSrc]="'assets/images/address-icon.svg'"
        width="20"
        height="20"
        alt="address-icon"
      />
      </div>
      <div class="m-2 p-1">
        <div class="deatil-label">Adresa</div>
        <div class="detail-value">{{ address ?? "Bulevar Evrope 23, Novi Sad" }}</div>
      </div>
    </div>
  </div>
</div>
