import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AssistancesClient, ReviewAssistanceCommand } from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { DividerModule } from 'primeng/divider';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RatingModule } from 'primeng/rating';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-assistance-review',
  standalone: true,
  imports: [
    RatingModule,
    FormsModule,
    InputTextareaModule,
    DividerModule
  ],
  templateUrl: './assistance-review.component.html',
  styleUrl: './assistance-review.component.scss'
})
export class AssistanceReviewComponent implements OnInit {
  private _subs: Subscription = new Subscription();

  agentReviewValue: number;
  partnerReviewValue: number;
  reviewComment: string;
  assistanceId: string;

  constructor (
    private assistanceClient: AssistancesClient,
    private dialogConfig: DialogConfig,
    private dialogRef: DialogRef,
    private alertService: AlertService
  ) {
    this.assistanceId = this.dialogConfig.data['assistanceId'];
  }

  ngOnInit(): void {
    this._subs.add(
      this.dialogRef.onSubmit.subscribe(() => {
        this.submitReview()
      })
    )
  }

  submitReview() {
    this._subs.add(
      this.assistanceClient.reviewAssistance(new ReviewAssistanceCommand(
      {
        assistanceId: this.assistanceId,
        agentRating: this.agentReviewValue,
        partnerRating: this.partnerReviewValue,
        comment: this.reviewComment
      } 
      )).subscribe((res) => {
        this.alertService.addSuccessMsg(res.result.value)
        this.dialogRef.close()
      })
    )
  }

}
