import {Injectable} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IAssistanceClient, IPhoneItem} from '@shared/model/atlas.api';
import {ControlsOf} from '@shared/forms/forms-common';
import {BehaviorSubject} from 'rxjs';
import {Guid} from 'guid-typescript';
import {
  AssistanceVehilceForm
} from '@app/sos-agent/assistances/assistance-form/services/assistance-vehilce-form.service';
import {ClientForm, PhonesForm,} from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';

@Injectable({
  providedIn: 'root',
})
export class AssistanceClientForm {
  constructor(
    private _fb: FormBuilder,
    private vehicleForm: AssistanceVehilceForm,
  ) {
  }

  validatePhones$ = new BehaviorSubject(false);

  set setValidatePhones(value: boolean) {
    this.validatePhones$.next(value);
  }

  get getValidatePhones() {
    return this.validatePhones$.asObservable();
  }

  getForm(model?: IAssistanceClient): ClientForm {
    return this._fb.group<ControlsOf<IAssistanceClient>>({
      clientId: this._fb.control(model?.clientId),
      clientName: this._fb.control(model?.clientName, {
        validators: [Validators.maxLength(256), Validators.required],
      }),
      email: this._fb.control(model?.email, {
        validators: [
          Validators.email,
          //strictEmailValidator(),
          Validators.maxLength(64),
        ],
      }),
      phones: this.getPhonesArray(model?.phones) as FormArray,
      vehicles: this.vehicleForm.getArray(model?.vehicles) as FormArray,
      destinationAddress: this._fb.control(model?.destinationAddress),
    });
  }

  getPhonesArray(model?: IPhoneItem[]): FormArray<PhonesForm> {
    const result = this._fb.array<PhonesForm>(
      model?.length > 0 ? [] : [this.getPhoneForm()],
    );

    if (!model) {
      return result;
    }

    model.forEach((s) => {
      result.push(this.getPhoneForm(s));
    });
    return result;
  }

  getPhoneForm(model?: IPhoneItem): FormGroup<ControlsOf<IPhoneItem>> {
    return this._fb.group<ControlsOf<IPhoneItem>>({
      label: this._fb.control(model?.label ?? '', {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(64)],
      }),
      id: this._fb.control(model?.id ?? Guid.create().toString(), {
        nonNullable: true,
      }),
    });
  }
}
