<atlas-page
  pageTitle="Moje preporuke"
  btnIcon="pi pi-plus"
  actionLabel="Dodaj preporuku"
  (onHandleAction)="handleAddNewRecommendation()"
>
  <div class="header-container flex justify-content-between w-full gap-2">
    <div class="flex">
      <div class="mr-2 font-semibold text-lg">
        {{ suggestCount }}
      </div>
      <div class="font-semibold text-lg text-gray-600">preporuka</div>
    </div>
    <div class="header-container flex justify-content-end w-full gap-2">
      <div>
        <div class="search-bar gap-3"></div>
        <atlas-dropdown-input-button
          [searchOptions]="searchOptions"
          [searchOptionCtrl]="searchOptionCtrl"
          [searchCtrl]="searchCtrl"
          (onAction)="load()"
        ></atlas-dropdown-input-button>
      </div>
    </div>
  </div>

  <atlas-table
    [paginatedData]="paginatedData"
    [tableConfig]="tableConfig"
  ></atlas-table>
</atlas-page>
