<div class="flex flex-column justify-content-between">
  <div>
    <div class="title-comments mb-3">
      Ukupno komentara
      <span class="comment-count">{{ totalNumberOfComments }}</span>
    </div>

    <div class="fadein animation-duration-500" *ngIf="comments.length === 0 && !isLoading">Nema komentara</div>

<!--    <div class="flex flex-column gap-3 fadein animation-duration-1000 animation-iteration-infinite" *ngIf="isLoading">-->
<!--      <div class="flex gap-2 align-items-center w-full">-->
<!--        <img-->
<!--          ngSrc="assets/images/client-icon.svg"-->
<!--          alt="Client Icon"-->
<!--          class="client-icon"-->
<!--          width="40"-->
<!--          height="40"-->
<!--        />-->
<!--        <div class="w-full">-->
<!--          <p-skeleton styleClass="mb-2" height="1rem" />-->
<!--          <p-skeleton width="10rem" styleClass="mb-2" height="1rem" />-->
<!--          <p-skeleton width="5rem" styleClass="mb-2" height="1rem" />-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="flex gap-2 align-items-center w-full">-->
<!--        <img-->
<!--          ngSrc="assets/images/client-icon.svg"-->
<!--          alt="Client Icon"-->
<!--          class="client-icon"-->
<!--          width="40"-->
<!--          height="40"-->
<!--        />-->
<!--        <div class="w-full">-->
<!--          <p-skeleton styleClass="mb-2" height="1rem" />-->
<!--          <p-skeleton width="10rem" styleClass="mb-2" height="1rem" />-->
<!--          <p-skeleton width="5rem" styleClass="mb-2" height="1rem" />-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div
      *ngFor="let comment of comments"
      class="comment-item mt-3 fadein animation-linear animation-duration-500"
    >
      <img
        *ngIf="comment.imgUrl; else defaultImg"
        [ngSrc]="comment.imgUrl"
        [alt]="comment.name + ' icon'"
        class="client-icon"
        width="40"
        height="40"
      />
      <ng-template #defaultImg>
        <img
          ngSrc="../../../../assets/images/client-icon.svg"
          alt="Client Icon"
          class="client-icon"
          width="40"
          height="40"
        />
      </ng-template>
      <div class="comment-content">
        <div class="comment-meta">
          <span class="comment-name">{{ comment.name }}</span>
          <span class="comment-date">{{
            comment.created | date: "dd MMM yyyy, HH:mm"
          }}</span>
        </div>
        <div class="comment-text">{{ comment.text }}</div>
      </div>
    </div>
  </div>

  <div class="form-container mt-5" style="display: grid">
    <div class="gap-3">
      <!-- Unos komentara -->
      <div class="comment-input">
        <atlas-input-description
          [formCtrl]="form.controls?.text!"
          formCtrlId="Komentar"
          ctrlPlaceholder="Tvoj komentar"
        ></atlas-input-description>
      </div>
    </div>
  </div>
</div>
