<atlas-multiselect 
    [formGroup]="formGroup"
    formCtrlName="agents"
    [options]="agentOptions"
    optionLabel="name"
    ctrlDataKey="id"
    ctrlLabel="Agenti"
    ctrlPlaceholder="Odaberi agente"
    ctrlStyleClass="w-full"
    [canFilter]="true"
/>
