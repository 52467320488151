<p-dropdown
  [options]="brands"
  [formControl]="selectedBrandCtrl"
  placeholder="Odaberite marku"
  [editable]="true"
  optionLabel="name"
  [filter]="true"
  filterBy="name"
  [showClear]="true"
  styleClass="w-full"
/>
