import {Component} from '@angular/core';
import {DropdownComponent} from '@ui/dropdown/dropdown.component';
import {FormControl} from '@angular/forms';
import {AssistanceFailureType} from '@shared/model/atlas.api';
import {AssistanceFormService} from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import {SelectItem} from "primeng/api";

@Component({
  selector: 'atlas-failure-type-dropdown',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './failure-type-dropdown.component.html',
  styleUrl: './failure-type-dropdown.component.scss'
})
export class FailureTypeDropdownComponent {
  failureTypeOptions: SelectItem<AssistanceFailureType>[];
  failureTypeCtrl: FormControl<AssistanceFailureType>;

  constructor(private formService: AssistanceFormService) {
    this.failureTypeCtrl = this.formService.getForm.controls.failureType;
    this.failureTypeOptions = [
      {label: "Popravka na licu mesta", value: AssistanceFailureType.ON_SITE_REPAIR},
      {label: "Saobraćajna nezgoda", value: AssistanceFailureType.TRAFFIC_ACCIDENT},
      {label: "Kvar", value: AssistanceFailureType.FAILURE},
      {label: "Odustajanje", value: AssistanceFailureType.WITHDRAWAL},
      {label: "Prevoz", value: AssistanceFailureType.TRANSPORT},
      {label: "Potencijalna prevara", value: AssistanceFailureType.POTENTIAL_FRAUD}
    ];
  }
}

