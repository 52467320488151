import { NavigationExtras, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationLeaderService {
  private readonly _rolePrefix = 'leader';

  constructor(private _router: Router) {}

  private _routeToUrl(url: string, navigationData?: NavigationExtras) {
    this._router.navigate([url], navigationData).then();
  }

  routeToHomePage() {
    this._routeToUrl(`/${this._rolePrefix}/clients`);
  }

  routeToAgentDetailPage(agentId: string) {
    this._router
      .navigate(['/', this._rolePrefix, 'agents', 'agent-details', agentId])
      .then();
  }

  routeToAgentTable() {
    this._router.navigate(['/', this._rolePrefix, 'agents']).then();
  }

  routeToCampaignTable() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', this._rolePrefix, 'campaigns']),
    );
    return this._routeToUrl(url);
  }

  routeToDetailsCampaign(id: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        this._rolePrefix,
        'campaigns',
        'details-campaign',
        id,
      ]),
    );
    return this._routeToUrl(url);
  }

  routeToDetailsLeads(id: string, rowData: any) {
    sessionStorage.setItem('rowData', JSON.stringify(rowData));
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        this._rolePrefix,
        'campaigns',
        'view-leads',
        id,
      ]),
    );
    window.open(url, '_blank');
  }

  routeToCreateCampaign() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        this._rolePrefix,
        'campaigns',
        'create-campaign',
      ]),
    );

    return this._router.navigateByUrl(url);
  }
}
