import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabViewModule } from 'primeng/tabview';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ClientClient, ClientPackageStatus, MojPackageCard } from '@shared/model/atlas.api';
import { SkeletonModule } from 'primeng/skeleton';
import { LocationService } from '@shared/services/location.service';
import { AgentCcRoleStr, B2BAgentRoleStr, LeaderCcRoleStr, UserService } from '@shared/services/user.service';

@Component({
  selector: 'atlas-own-package-client-info',
  standalone: true,
  imports: [
    OverlayPanelModule,
    TabViewModule,
    ButtonComponent,
    NgIf,
    SkeletonModule],
  templateUrl: './own-package-client-info.component.html',
  styleUrl: './own-package-client-info.component.scss'
})
export class OwnPackageClientInfoComponent implements OnInit, OnDestroy {

  private _subs: Subscription = new Subscription();

  clientId: string;
  ownPackage: MojPackageCard;
  showSkeleton: boolean = true;
  showPackage: boolean;

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ClientPacakgeStatus = ClientPackageStatus;

  constructor(
      private route: ActivatedRoute,
      private client: ClientClient,
      private locationService: LocationService,
      private userService: UserService
    ) {
      this.clientId = this.route.snapshot.paramMap.get('id');
    }

    ngOnInit(): void {
      this.getClientInfo();
    }

    buyOwnPackage() {
      this.locationService.routeToBuyMojPaketForClient(
        this.clientId,
        this.userService.getRoleRoutePrefix(
          AgentCcRoleStr,
          B2BAgentRoleStr,
          LeaderCcRoleStr
        )
      )
    }

    private getClientInfo() {
      this._subs.add(
        this.client.getProfilePackages(this.clientId).subscribe((res) => {
          this.ownPackage = res.result?.mojPackageCard;
          this.showPackage = this.ownPackage?.status !== ClientPackageStatus.DRAFT 
            && this.ownPackage?.status !== ClientPackageStatus.ACTIVE;
          this.showSkeleton = false;
        })
      )
    }

    ngOnDestroy(): void {
      this._subs.unsubscribe();
    }
}
