import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PageComponent } from '@shared/components/page/page.component';
import { AgentResponse, AgentsClient } from '@shared/model/atlas.api';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { SelectItem } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { Subscription } from 'rxjs';
import { ClientWithPackagesTableComponent } from '@app/features/orders/client-with-packages-table/client-with-packages-table.component';
import { ScrollablePageComponent } from '../../../../infrastructure/shared/components/scrollable-page/scrollable-page.component';
import { LocationLeaderService } from '@shared/services/location-leader.service';

@Component({
  selector: 'atlas-agent-detail-page',
  standalone: true,
  imports: [
    ButtonComponent,
    DividerModule,
    NgIf,
    DropdownComponent,
    InputTextComponent,
    ClientWithPackagesTableComponent,
    PageComponent,
    ScrollPanelModule,
    NgClass,
    NgOptimizedImage,
    ScrollablePageComponent,
  ],
  templateUrl: './agent-detail-page.component.html',
  styleUrl: './agent-detail-page.component.scss',
})
export class AgentDetailPageComponent implements OnDestroy {
  private _subs: Subscription = new Subscription();

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  searchCtrl: FormControl;
  searchOptions: SelectItem[];
  searchOptionCtrl: FormControl;

  employeeId: string = '';
  selectedAgent: AgentResponse;
  agentActive: boolean;
  title: string;

  constructor(
    private acivatedRoute: ActivatedRoute,
    private agentClient: AgentsClient,
    private locationService: LocationLeaderService,
  ) {
    this.acivatedRoute.params.subscribe((params) => {
      this.employeeId = params['id'];
    });

    this.load();
  }

  handleAgentOptions() {
    alert('Agent options');
  }

  handleCancelForm() {
    this.locationService.routeToAgentTable();
  }

  load() {
    this._subs.add(
      this.agentClient.getAgentById(this.employeeId).subscribe((response) => {
        this.selectedAgent = response.result;
        this.title = this.selectedAgent?.name;
        this.agentActive = response.result.isActive;
      }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
