import { Component, OnDestroy, OnInit } from '@angular/core';
import { DividerModule } from 'primeng/divider';
import { FieldsetModule } from 'primeng/fieldset';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { PanelModule } from 'primeng/panel';
import { Subscription } from 'rxjs';
import { ButtonStyle } from '@ui/button/button.component';
import { NgClass, NgIf } from '@angular/common';
import {
  AssistanceService,
  ClientDetails,
} from '@app/sos-agent/assistances/assistance-form/services/assistance.service';
import { AssistanceForm, AssistanceFormService } from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import {LicencePlateRegExp} from "@shared/extensions-common";

@Component({
  selector: 'atlas-assistance-details',
  standalone: true,
  imports: [
    DividerModule,
    FieldsetModule,
    AccordionModule,
    AvatarModule,
    ScrollPanelModule,
    PanelModule,
    NgIf,
    NgClass,
    InputTextComponent
  ],
  templateUrl: './assistance-details.component.html',
  styleUrl: './assistance-details.component.scss',
})
export class AssistanceDetailsComponent implements OnInit, OnDestroy {
  clientDetails: ClientDetails;
  private _subs: Subscription;

  constructor(
    public formService: AssistanceFormService,
    public service: AssistanceService,
  ) {
    this._subs = new Subscription();
    this.clientDetails = service.getClientDetails;
  }

  ngOnInit() {
    this._subs.add(
      this.formService.getForm.controls.partnerVehicleId.valueChanges.subscribe(
        (partnerVehicleId) => {
          if (partnerVehicleId) {
            const selectedPartner = this.service.getPartners.find(
              (partner) => partner.id === partnerVehicleId
            );
            if (selectedPartner) {
              this.formService.getForm.patchValue({
                partnerVehicleLicencePlate: selectedPartner.licencePlate,
                partnerVehicleId: partnerVehicleId
              })
            }
          }
        },
      ),
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  handleCancelPartner() {
    this.formService.setPartnerVehicle = undefined;
    this.service.resetPartner();
  }

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly LicencePlateRegExp = LicencePlateRegExp;
}
