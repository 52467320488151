import {
  JsonPipe,
  NgIf,
  NgClass,
  NgTemplateOutlet,
  NgForOf,
  NgOptimizedImage,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { PackageDetailSidebarComponent } from '@app/features/call-center/package-detail-sidebar/package-detail-sidebar.component';
import { PackageCardListService } from '@app/cc-leader/services/package-card-list.service';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { PageComponent } from '@shared/components/page/page.component';
import {
  CampaignsClient,
  ClientClient,
  ClientWithPackagesFilter,
  ClientWithPackagesItem,
  GetCampaigPackagesTableQuery,
  GetClientWithPackagesStatsQuery,
  GetClientWithPackagesTableQuery,
  IClientWithPackagesItem,
  IClientWithPackagesStatsResponse,
  IClientWithPackagesTableResponse,
  IPaginationResponseOfClientWithPackagesTableResponse,
} from '@shared/model/atlas.api';
import { LocationService } from '@shared/services/location.service';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { TableConfig } from '@ui/table/table-common';
import { TableComponent } from '@ui/table/table.component';
import { SelectItem } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SkeletonModule } from 'primeng/skeleton';
import {
  TableModule,
  TableRowCollapseEvent,
  TableRowExpandEvent,
} from 'primeng/table';
import { firstValueFrom, Subscription } from 'rxjs';
import { CheckboxActionDialogComponent } from '@app/features/call-center/checkbox-action-dialog/checkbox-action-dialog.component';
import { licencePlatePipe } from '@shared/pipes/licence-plate.pipe';
import { ToastMessageComponent } from '@app/cc-leader/toast-message/toast-message/toast-message.component';
import { ClientCommentsComponent } from '@app/features/call-center/client-comments/client-comments.component';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { DialogService } from '@ui/dialog/dialog.service';
import { OrderFormService } from '@app/cc-leader/services/order-form.service';
import { DropdownInputButtonComponent } from '@shared/components/dropdown-input-button/dropdown-input-button.component';
import { ClientWithPackagesTableFilterComponent } from './client-with-packages-table-filter/client-with-packages-table-filter.component';
import { ActiveFiltersComponent } from '@app/finance-administrator/order-purchase-table/active-filters/active-filters.component';
import { ActiveFiltersClientComponent } from '../active-filters-client/active-filters-client.component';
import { ClientWithPackageTableFilterService } from '../services/client-with-package-table-filter.service';
import { TableServiceLocal } from '@ui/table/table-service-local.service';

@Component({
  selector: 'atlas-client-with-packages-table',
  standalone: true,
  imports: [
    PageComponent,
    TableComponent,
    TableModule,
    ButtonComponent,
    ScrollPanelModule,
    JsonPipe,
    NgIf,
    NgClass,
    SkeletonModule,
    ButtonModule,
    NgForOf,
    BadgeModule,
    ButtonModule,
    InlineWrapperComponent,
    DropdownComponent,
    InputAddonComponent,
    InputTextComponent,
    PackageDetailSidebarComponent,
    CheckboxModule,
    FormsModule,
    CheckboxActionDialogComponent,
    ToastMessageComponent,
    NgTemplateOutlet,
    licencePlatePipe,
    NgOptimizedImage,
    DropdownInputButtonComponent,
    ClientWithPackagesTableFilterComponent,
    ActiveFiltersClientComponent,
  ],
  templateUrl: './client-with-packages-table.component.html',
  styleUrl: './client-with-packages-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientWithPackagesTableComponent implements OnInit, OnDestroy {
  paginatedData: IPaginationResponseOfClientWithPackagesTableResponse;
  stats: IClientWithPackagesStatsResponse;
  private _subs: Subscription = new Subscription();
  searchOptions: SelectItem[];
  searchOptionCtrl: FormControl;
  searchCtrl: FormControl;
  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);
  showTable: boolean = true;
  isAllExpanded = false;
  selectedVehicles: Set<string> = new Set();
  selectedCount: number = 0;
  expandedRows: { [key: string]: boolean } = {};
  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  @Input() showSearchBar: boolean = true;
  @Input() showHeader: boolean = false;
  @Input() pageTitle: string | undefined;
  @Input() pageDescription: string | undefined;
  @Input() actionLabel: string = '';
  @Input() userId: string;
  @Input() displayStatistics: boolean = false;
  @Input() campaignId: string | undefined;
  @Input() canRenewPackage: boolean = true;
  @Input() canMultiselect: boolean = true;
  @Input() hideMoreOptions: boolean = false;

  @Output() clientSelected = new EventEmitter<any>();
  @Output() contractIds = new EventEmitter<string[]>();
  skeletonItems: any[] = [];
  isLoading: boolean = true;

  constructor(
    private client: ClientClient,
    private changeDetectorRef: ChangeDetectorRef,
    private clientCampaign: CampaignsClient,
    private _locationService: LocationService,
    public service: ClientPackageTableService,
    private _packageCardSerice: PackageCardListService,
    private _dialogService: DialogService,
    private _orderFormService: OrderFormService,
    public filterService: ClientWithPackageTableFilterService,
  ) {
    this._subs = new Subscription();
    this.paginatedData = {
      data: [],
      currentPage: 0,
      pageSize: 10,
    };
    this.stats = {
      numOfPackages: 0,
      numOfClients: 0,
      numOfAgents: 0,
    };
    this.searchCtrl = new FormControl<string>('');
    this.skeletonItems = Array.from({ length: 9 }).map((_, i) => i);
  }
  ngOnInit(): void {
    this.loadLazy();

    this._subs.add(
      this.filterService.getReloadTable.subscribe((reloadTable) => {
        if (reloadTable) {
          this.loadLazy();
        }
      }),
    );
  }

  toggleTable() {
    this.showTable = !this.showTable;
  }

  handleCreateClient() {
    this._locationService.routeToCreateClientForm().then();
  }

  onRowExpand(event: TableRowExpandEvent) {
    this.expandedRows[event.data.id] = true;
  }

  onRowCollapse(event: TableRowCollapseEvent) {
    this.expandedRows[event.data.id] = false;
  }

  toggleRow(client: any): void {
    if (this.expandedRows[client.id]) {
      delete this.expandedRows[client.id];
    } else {
      this.expandedRows[client.id] = true;
    }
  }

  expandAll(): void {
    this.paginatedData.data.forEach((item) => {
      this.expandedRows[item.id] = true;
    });
    this.isAllExpanded = true;
  }

  collapseAll() {
    this.expandedRows = {};
    this.isAllExpanded = false;
  }

  openOptionsDialog(clientPackageId: string) {
    this._packageCardSerice.setSelectedPackageIdForRenew(clientPackageId);
    this._packageCardSerice.setSelectedClientPackageId(clientPackageId);
    this.service.showPreviewSidebar(clientPackageId);
  }

  handleCheckboxChange(
    event: any,
    packageData: IClientWithPackagesItem,
    client: any,
  ) {
    if (event.checked) {
      this.service.addSelectedPackage(packageData);
      this.service.addClient(client);
      this.service.clientId = client.id;
      this._orderFormService.setRenewClientId(client.id);
      if (packageData.vehicle) {
        this.selectedVehicles.add(packageData.vehicle);
      }
    } else {
      this.service.removeSelectedPackage(packageData);
      this.service.removeClient(client);
      this.selectedVehicles.delete(packageData.vehicle);
    }
    this.selectedCount = this.service.getSelectedPackagesCount();
  }

  showComments(clientId: string) {
    this.service.clientId = clientId;
    this.dialogConfig.header = 'Komentari';
    this.dialogConfig.customSubmitButton = {
      label: 'Dodaj komentar',
      icon: '',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.hideCancel = true;
    this.dialogConfig.closable = true;

    this.dialogConfig.data = { clientId };
    const dialogRef = this._dialogService.open(
      ClientCommentsComponent,
      this.dialogConfig,
    );

    dialogRef.onClose.subscribe((commentNum: string | undefined) => {
      if (commentNum) {
        const idx = this.paginatedData.data.findIndex((x) => x.id === clientId);
        this.paginatedData.data[idx].commentsNum = commentNum;
      }
    });
  }

  onHover(client: any): void {
    if (this.expandedRows[client.id]) {
      client.hovered = true;
    }
  }

  onLeave(client: any): void {
    client.hovered = false;
  }

  selectAllPackages(client: any) {
    const newSelectedState = !client.selected;

    client.selected = newSelectedState;

    if (!newSelectedState) {
      // unselect all
      this.service.removeClient(client);
      client.packages.forEach((pkg: any) => {
        pkg.selected = false;
        this.service.removeSelectedPackage(pkg);
        if (pkg.vehicle) {
          this.selectedVehicles.delete(pkg.vehicle);
        }
      });
    } else {
      this.service.addClient(client);
      // select all
      client.packages.forEach((pkg: any) => {
        pkg.selected = true;
        this.service.addSelectedPackage(pkg);
        if (pkg.vehicle) {
          this.selectedVehicles.add(pkg.vehicle);
        }
      });
    }

    this.selectedCount = this.service.getSelectedPackagesCount();
  }

  get distinctVehicleCount(): number {
    return this.selectedVehicles.size;
  }

  loadLazy(event: any = { first: 0, rows: 10 }) {
    if (event.first === 0) {
      this.paginatedData.currentPage = 1;
    } else {
      this.paginatedData.currentPage = event.first! / event.rows! + 1;
    }
    this.paginatedData.pageSize = event.rows!;
    this.load();
    if (this.displayStatistics) {
      this.loadStats();
    }
  }

  private load() {
    this.isLoading = true;
    this._loadTable().then((res) => {
      this.paginatedData = res.result;
      this.contractIds.emit(
        res.result.data
          .flatMap((x) => x.packages)
          .map((x) => x.clientPackageId),
      );
      this.expandAll();
      setTimeout(() => {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      }, 300);
    });
  }

  loadStats() {
    this._loadStats().then((res) => {
      this.stats = res.result.value;
    });
  }

  private async _loadTable() {
    const filters = this.filterService.getClientTableFilter(this.userId);
    if (this.campaignId) {
      return await firstValueFrom(
        this.clientCampaign.getCampaigPackagesTable(
          new GetCampaigPackagesTableQuery({
            pageNumber: this.paginatedData.currentPage,
            pageSize: this.paginatedData.pageSize ?? 10,
            campaignId: this.campaignId,
            filters: filters,
          }),
        ),
      );
    }
    return await firstValueFrom(
      this.client.getClientWithPackagesTable(
        new GetClientWithPackagesTableQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize ?? 10,
          filters: filters,
        }),
      ),
    );
  }

  private async _loadStats() {
    const filters = this.filterService.getClientTableFilter(this.userId);
    return await firstValueFrom(
      this.client.getClientWithPackagesStats(
        new GetClientWithPackagesStatsQuery({
          filters: filters,
        }),
      ),
    );
  }

  handleRenewPackage(packageInfo: ClientWithPackagesItem) {
    this._packageCardSerice.setSelectedPackageIdForRenew(packageInfo.packageId);
    this._orderFormService.setRenewClientId(packageInfo.clientId);
    this._packageCardSerice.setSelectedClientPackageId(
      packageInfo.clientPackageId,
    );

    this._locationService.routeToRenewPackageForm(packageInfo.clientPackageId);
  }

  ngOnDestroy() {
    this.service.reset();
    this._subs.unsubscribe();
  }
}
