import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageComponent } from '@shared/components/page/page.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabViewModule } from 'primeng/tabview';
import { firstValueFrom, Subscription } from 'rxjs';
import { PackageTabClientComponent } from './package-tab-client/package-tab-client.component';
import { DetailsTabClientComponent } from './details-tab-client/details-tab-client.component';
import { LocationService } from '@shared/services/location.service';
import {
  AgentCcRoleStr,
  B2BAgentRoleStr,
  LeaderCcRoleStr,
  UserService,
} from '@shared/services/user.service';
import { ScrollablePageComponent } from '@shared/components/scrollable-page/scrollable-page.component';
import { ClientClient } from '@shared/model/atlas.api';
import { AssistanceTableComponent } from '@app/sos-agent/assistances/assistance-table/assistance-table.component';
import { StatisticsTabClientComponent } from "./statistics-tab-client/statistics-tab-client.component";

@Component({
  selector: 'atlas-client-info',
  standalone: true,
  imports: [
    PageComponent,
    OverlayPanelModule,
    TabViewModule,
    PackageTabClientComponent,
    DetailsTabClientComponent,
    PackageTabClientComponent,
    ScrollablePageComponent,
    AssistanceTableComponent,
    StatisticsTabClientComponent
],
  templateUrl: './client-info.component.html',
  styleUrl: './client-info.component.scss',
})
export class ClientInfoComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  clientName: string;
  clientId: string;
  clientType: string;

  public activeIndex: number | undefined;
  protected active: boolean | undefined;

  constructor(
    private route: ActivatedRoute,
    private location: LocationService,
    private userService: UserService,
    private clientClient: ClientClient
  ) {
    this.clientName = decodeURIComponent(
      this.route.snapshot.paramMap.get('name') || '',
    );
    this.clientId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this._loadClient(this.clientId)
  }

  onEllipsisClick(event: Event, overlayPanel: any) {
    overlayPanel.toggle(event);
  }

  routeToEditClient() {
    this.location.routeToEditClient(
      this.clientId,
      this.userService.getRoleRoutePrefix(
        AgentCcRoleStr,
        B2BAgentRoleStr,
        LeaderCcRoleStr,
      ),
    );
  }

  handleTabChange(event: number) {
    if (event === 0) {
      this.active = undefined;
    } else this.active = event == 1;
  }

  private async _loadClient(id: string): Promise<void> {
    const res = await this._getClient(id);
    this.clientType = res.result.clientType;
  }

  private async _getClient(id: string) {
      return await firstValueFrom(this.clientClient.getClientProfile(id));
    }

  routeBack() {
    this.location.routeToClientsTable( this.userService.getRoleRoutePrefix(
      AgentCcRoleStr,
      B2BAgentRoleStr,
      LeaderCcRoleStr,
    ))
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
