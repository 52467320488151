import {SelectItem} from 'primeng/api';
import {BehaviorSubject} from 'rxjs';
import {ButtonSeverity, ButtonStyle} from '@ui/button/button.component';
import {TableLazyLoadEvent} from "primeng/table/table.interface";

export interface ITableConfig {
  /** props */
  headerDescription?: string;
  //  Determine whether table is grouped or classic.
  type?: TableType;
  //  Determine whether create button is single button or dropdown button.
  createMode?: CreateMode;
  //  Determine whether selection should be single or multiple.
  selectMode?: SelectMode | null;
  isSelectableRowDisabled?: (rowData: any) => boolean;
  //  Message shown in confirm dialog when one row is being deleted.
  deleteMessage?: string;
  //  Message shown in confirm dialog when multiple rows are being deleted.
  multiDeleteMessage?: string;
  //  Parameter representing row name or other information
  //  that will be displayed in delete message.
  deleteMessageParams?: string;
  // Po kojim parametetrima mozemo da filtriramo tabelu
  tableFilterFields?: string[];
  // lista kolona
  columns: TableColumn[];
  // u slucaju kada header treba da bude neki template
  isCustomHeader?: boolean;
  refreshCallbackFunction?: () => void;
  // akcije
  headerActions?: TableAction[];
  rowActions?: TableAction[];
  // napredni filter
  // ? proveriti da li nam ovi ispod i dalje trebaju
  // templateItems?: SelectItem[];
  //multiCreateAction?: Promise<MultiAction>;
  moreActionsItemIndex?: number;
  rowsPerPage?: number;
  updateActions$?: BehaviorSubject<number>;
  isLazy?: boolean;
  lazyCallbackFunction?: (event: TableLazyLoadEvent) => void;
  showHeader?: boolean;
  emptyTableInfo?: IEmptyTableInfo;
}

export declare interface OnTableInit {
  tableConfig: TableConfig;

  setTableConfig(): Promise<void>;

  //getRowMultiActions?(rowIndex: number): Promise<MenuItem[]>;
  /**
   * Radi ponovni fetch ka API-ju i osvežava tabelu
   */
  _onTableRefresh?(data: any): void;
}

export enum ActionType {
  CREATE = 0,
  EDIT = 1,
  DELETE = 2,
  CUSTOM = 3,
  NO_ACTIONS = 4,
  MULTI_DELETE = 5,
  DROPDOWN = 6,
  DROPDOWN_ITEM = 7,
  MULTI_CUSTOM = 8,
  ARCHIVE = 9,
}

export enum ActionMode {
  SINGLE = 1,
  MULTI = 2,
}

export enum TableType {
  CLASSIC = 0,
  GROUPED = 1,
}

export enum CreateMode {
  NONE = 0,
  SINGLE = 1,
  MULTI = 2,
}

export enum SelectMode {
  NONE = 'undefined',
  SINGLE = 'single',
  MULTI = 'multiple',
}

export enum ActionClass {
  PRIMARY = 'p-button-primary',
  SECONDARY = 'p-button-secondary',
  TEXT = 'p-button-text',
  EDIT = 'p-button-success',
  DELETE = 'p-button-danger',
  OUTLINED = 'p-button-outlined',
  LINK = 'p-button-link',
}

export interface TableColumn {
  field: string;
  imageField?: string;
  subField?: string;
  emptyCellField?: number;
  header?: string;
  seconHeader?: string;
  isFrozen?: boolean;
  type:
    | 'text'
    | 'number'
    | 'badge'
    | 'currency'
    | 'list'
    | 'link'
    | 'initials'
    | 'icon'
    | 'bool'
    | 'image'
    | 'rating';
  styleClass?: string;
  styleClassField?: string;
  tooltipField?: string;
  helperText?: string;
  isVisible?: boolean;
  shouldDisplayByCondition?: (fieldValue: any) => boolean;
  useColorsForCurrency?: boolean;
  dropdownItems?: SelectItem[];
  dropdownCallback?: (rowIndex: number, value: any) => void;
  linkCallbackFunction?: (rowIndex: number, subIndex?: number) => any;
  columns?: TableColumn[];
  currencyAlphaCharField?: string;
  badgeClass?: string;
}

/** NOVO */
export class TableConfig implements ITableConfig {
  headerDescription?: string;
  type?: TableType;
  createMode?: CreateMode;
  selectMode: SelectMode;
  deleteMessage?: string;
  multiDeleteMessage?: string;
  deleteMessageParams?: string;
  tableFilterFields?: string[];
  columns: TableColumn[];
  showHeader: boolean;
  isCustomHeader?: boolean;
  refreshCallbackFunction?: () => void;
  isSelectableRowDisabled?: (rowData: any) => boolean;
  headerActions?: TableAction[];
  rowActions?: TableAction[];
  moreActionsItemIndex?: number;
  rowsPerPage: number;
  updateActions$?: BehaviorSubject<number>;
  isLazy?: boolean;
  lazyCallbackFunction?: (event: TableLazyLoadEvent) => void;
  emptyTableInfo?: IEmptyTableInfo;

  /**
   * Postavljamo default vrednosti
   */
  constructor(data: ITableConfig) {
    this.headerDescription = data.headerDescription;
    this.type = data.type ?? TableType.CLASSIC;
    this.createMode = data.createMode ?? CreateMode.SINGLE;
    this.selectMode = data.selectMode ?? SelectMode.NONE;
    this.rowsPerPage = data.rowsPerPage ?? 10;
    this.deleteMessage = data.deleteMessage;
    this.multiDeleteMessage = data.multiDeleteMessage;
    this.deleteMessageParams = data.deleteMessageParams;
    this.columns = data.columns;
    this.headerActions = data.headerActions;
    this.rowActions = data.rowActions;
    this.updateActions$ = data.updateActions$;
    this.isSelectableRowDisabled = data.isSelectableRowDisabled;
    this.isLazy = data.isLazy ?? false;
    this.lazyCallbackFunction = data.lazyCallbackFunction;
    this.showHeader = data.showHeader ?? true;
    this.emptyTableInfo = data.emptyTableInfo;
  }
}

export interface TableAction {
  mode: ActionMode;
  //type: ActionType;
  label?: string;
  svgName?: string;
  title?: string;
  icon?: string;
  //tipPristupa?: TipPristupa;
  hasAccessTooltip?: string;
  noAccessTooltip?: string;
  mutliActions?: TableAction[];
  actionClass?: string;
  actionStyle?: ButtonStyle;
  actionSeverity?: ButtonSeverity;
  isVisible?: boolean;
  shouldDisplayByCondition?: (rowIdx: number) => boolean;
  callback: (rowIdx: number) => void;
  onHover?: (rowIdx: number) => string;
}

export interface IPaginatedResultTableDto {
  data: any[] | undefined;
  currentPage?: number;
  totalPages?: number;
  totalCount?: number;
  pageSize?: number;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface IEmptyTableInfo {
  header: string;
  description: string;
  btnIcon?: string | undefined;
  btnLabel?: string | undefined;
  action?: () => void;
}
