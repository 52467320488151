<div class="flex flex-column gap-5 page-header">
  <div class="main-page-header">
    <div class="text-3xl font-semibold">{{ pageTitle }}</div>
    <div class="flex justify-content-between align-items-center">
      <div class="text-base text-gray-600">{{ pageDescription }}</div>
        <ng-container *ngIf="btnIcon; else buttonWithSvg">
            <atlas-button
                    *ngIf="actionLabel"
                    (btnClicked)="handleAction()"
                    [btnStyle]="ButtonStyle.BASIC"
                    btnClass="w-full"
                    [btnIcon]="btnIcon"
                    [btnLabel]="actionLabel"
            >
            </atlas-button>
        </ng-container>

    </div>
  </div>
  <div>
    <p-scrollPanel [style]="{ width: '100%', height: 'calc(100dvh - 200px)'}">
      <ng-content></ng-content>
    </p-scrollPanel>
  </div>
</div>

<ng-template #buttonWithSvg>
    <atlas-button
            *ngIf="actionLabel"
            (btnClicked)="handleAction()"
            [btnStyle]="ButtonStyle.BASIC"
            btnClass="w-full"
            [btnLabel]="actionLabel"
    >
        <img [ngSrc]="btnSvgUrl" width="16" height="16" alt="add-client" />
    </atlas-button>
</ng-template>
 