<div class="flex align-items-end input-button">
  <atlas-input-text
    [hideErrors]="true"
    formCtrlClass="group-input w-10rem lg:w-20rem"
    ctrlPlaceholder="Pretraži"
    [formCtrl]="searchCtrl"
    (onEnterClicked)="handleAction()"
  ></atlas-input-text>
  <atlas-button
    [btnStyle]="ButtonStyle.OUTLINED"
    [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
    btnIcon="pi pi-search"
    (btnClicked)="handleAction()"
    btnClass="group-button"
  >
  </atlas-button>
</div>
