<atlas-multiselect
  [formGroup]="formGroup"
  formCtrlName="paymentTypes"
  [options]="typeOptions"
  optionLabel="label"
  ctrlLabel="Tipovi uplate"
  ctrlDataKey="type"
  ctrlPlaceholder="Odaberi tipove uplate"
  ctrlStyleClass="w-full"
/>
