<div class="flex flex-column justify-content-between">
  <div class="title-comments mb-3">
    Komentari
    <span class="comment-count">{{ this.comments?.length }}</span>
  </div>

  <div class="form-container" style="display: grid">
    <div class="flex flex-row align-start gap-3">
      <div class="mt-2">
        <img
          *ngIf="agentImgUrl; else defaultImg"
          [ngSrc]="agentImgUrl"
          alt="Profile Icon"
          class="client-icon"
          width="40"
          height="40"
        />
        <ng-template #defaultImg>
          <img
            ngSrc="assets/images/client-icon.svg"
            alt="Profile Icon 2"
            class="client-icon"
            width="40"
            height="40"
          />
        </ng-template>
      </div>

      <!-- Unos komentara -->
      <div class="comment-input flex-grow-1">
        <atlas-input-description
          [formCtrl]="form.controls.text"
          formCtrlId="Komentar"
          ctrlPlaceholder="Dodaj komentar"
          rows="2"
          [hideErrors]="true"
        ></atlas-input-description>
      </div>
    </div>
    <div class="flex justify-content-end" *ngIf="form.value.text">
      <atlas-button
        [btnStyle]="ButtonStyle.BASIC"
        [btnSeverity]="ButtonSeverity.SECONDARY"
        btnLabel="Dodaj komentar"
        (btnClicked)="addComment()"
      ></atlas-button>
    </div>
  </div>

  <p-divider></p-divider>

  <div
    class="fadein animation-duration-500 text-center"
    *ngIf="comments?.length === 0 && !isLoading"
  >
    Nema komentara
  </div>

  <div
    *ngFor="let comment of comments"
    class="comment-item mt-3 fadein animation-linear animation-duration-500"
  >
    <img
      *ngIf="comment.imgUrl; else defaultImg"
      [ngSrc]="comment.imgUrl"
      [alt]="comment.name + ' icon'"
      class="client-icon"
      width="40"
      height="40"
    />
    <ng-template #defaultImg>
      <img
        ngSrc="assets/images/client-icon.svg"
        alt="Client Icon"
        class="client-icon"
        width="40"
        height="40"
      />
    </ng-template>
    <div class="comment-content">
      <div class="comment-meta">
        <span class="comment-name">{{ comment.name }}</span>
        <span class="comment-date">{{
          comment.created | date: "dd MMM yyyy, HH:mm"
        }}</span>
      </div>
      <div class="comment-text">{{ comment.text }}</div>
    </div>
  </div>
</div>
