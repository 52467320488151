import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';
import {GetMediumsQuery, IMediumItem, MediumsClient} from '@shared/model/atlas.api';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MultiselectComponent } from '@ui/multiselect/multiselect.component';

@Component({
  selector: 'atlas-medium-multiselect',
  standalone: true,
  imports: [MultiselectComponent],
  templateUrl: './medium-multiselect.component.html',
  styleUrl: './medium-multiselect.component.scss',
})
export class MediumMultiselectComponent implements OnInit, OnDestroy {
  private _subs: Subscription;

  mediumOptions: IMediumItem[] = [];
  formGroup: FormGroup<MediumForm>;

  @Input() mediumsCtrl: FormArray;

  constructor(private _client: MediumsClient) {
    this.formGroup = new FormGroup({
      mediums: new FormControl([]),
    });
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this._load();

    this._subs.add(
      this.formGroup.valueChanges.subscribe((value) => {
        this.mediumsCtrl.clear();
        value.mediums.forEach((x) => {
          this.mediumsCtrl.push(new FormControl(x.id));
        });
      }),
    );

    this._subs.add(
      this.mediumsCtrl.valueChanges.subscribe((value) => {
        this.formGroup.controls.mediums.setValue(value.map((id) =>
          this.mediumOptions.find((opt) => opt.id === id) || null
        ), { emitEvent: false });
      }),
    );
  }

  private _load() {
    this._getMediums().then((res) => {
      this.mediumOptions = res.result.data;
    });
  }

  private async _getMediums() {
    return await firstValueFrom(this._client.getMediums(new GetMediumsQuery({
      pageNumber: 0,
      pageSize: 100
    })));
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}

export type MediumForm = {
  mediums: FormControl<IMediumItem[]>;
};
