import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PageComponent } from '@shared/components/page/page.component';
import { ButtonComponent } from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { TableComponent } from '@ui/table/table.component';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'atlas-recommendation-table',
  standalone: true,
  imports: [
    PageComponent,
    InlineWrapperComponent,
    DropdownComponent,
    InputTextComponent,
    ButtonModule,
    ButtonComponent,
    DropdownModule,
    FormsModule,
    TableComponent,
    
  ],
  templateUrl: './recommendation-table.component.html',
  styleUrl: './recommendation-table.component.scss'
})
export class RecommendationTableComponent implements OnInit {
  ngOnInit() {
  }
}
